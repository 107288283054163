import { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar } from 'components/Calendar';
import { Typography } from 'components/Typography';
import { Container, SelectContainer, SelectOpenContainer } from './styles';
import { useClickOutside } from 'hooks/useClickOutside';
import { translateDate } from 'utils/dateUtils';
import { inactiveDropdown } from 'assets/images/icons';
interface WhenCallSelectProps {
  onChange(dates: Date[]): void;
  getMonth(month: string): void;
  values?: Date[];
  disabled?: boolean;
}

const WhenCallSelect = ({
  onChange,
  getMonth,
  values = [],
  disabled = false,
}: WhenCallSelectProps) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDays, setSelectedDays] = useState<Date[]>(values);
  const selectRef = useRef<HTMLDivElement>(null);
  const [display, setDisplay] = useState('Selecione');

  useClickOutside({
    action: () => setOpenCalendar(false),
    ref: selectRef,
  });

  const formatDisplay = useCallback(() => {
    if (selectedDays.length > 0) {
      const { day, month, week } = translateDate(selectedDays[0].toUTCString());
      const lastDay = translateDate(
        selectedDays[selectedDays.length - 1].toUTCString()
      );
      setDisplay(
        `${week}, ${day} ${month} - ${lastDay.week}, ${lastDay.day} ${lastDay.month}`
      );
      getMonth(month);
    } else {
      setDisplay('Selecione');
    }
  }, [selectedDays, getMonth]);

  const onSelectDays = useCallback(
    (days: Date[]) => {
      setSelectedDays(days);
      onChange(days);
    },
    [onChange]
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      setOpenCalendar(!openCalendar);
    }
  }, [disabled, openCalendar]);

  useEffect(() => {
    formatDisplay();
  }, [formatDisplay]);

  useEffect(() => {
    if (values.length > 0) {
      setSelectedDays(values);
    }
  }, [values]);

  return (
    <Container ref={selectRef}>
      <SelectContainer
        isOpen={openCalendar}
        onClick={() => handleClick()}
        disabled={disabled}
        data-testid="when-select-container"
      >
        <Typography variant="button" color="darkerGray">
          {display}
        </Typography>
        <img src={inactiveDropdown} />
      </SelectContainer>
      <SelectOpenContainer isOpen={openCalendar}>
        <Calendar
          onChange={onSelectDays}
          disableBefore
          defaultSelectedDays={selectedDays}
        />
      </SelectOpenContainer>
    </Container>
  );
};

export { WhenCallSelect };
