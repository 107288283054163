import { ContentCard } from 'components/ContentCard';
import { FieldReadOnlyInfo } from 'components/FieldReadOnlyInfo';
import { IconButton } from 'components/IconButton';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ContractTypes, SlaTypes } from 'features/Company/types';
import { formatCurrency } from 'utils/formatCurrency';
import { dollarSign, edit } from 'assets/images/icons';

interface ViewContractInformationProps {
  contract: ContractTypes;
  currentSla: SlaTypes;
  onClick: () => void;
}

const ViewContractInformation = ({
  contract,
  currentSla,
  onClick,
}: ViewContractInformationProps) => {
  return (
    <div className="flex flex-col flex-1 px-16 py-6 space-y-8">
      <ContentCard
        title="Informações base e cobrança"
        icon={dollarSign}
        renderAction={
          <IconButton
            icon={edit}
            size="small"
            filled
            type="button"
            onClick={onClick}
          >
            Editar
          </IconButton>
        }
      >
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row space-x-2">
            <div className="w-1/2">
              <Typography variant="button" color="darkestGray">
                Informações base do contrato
              </Typography>
              <div className="flex mt-2 flex-row space-x-2 flex-wrap">
                <div className="w-46 xl:w-52">
                  <FieldReadOnlyInfo
                    label="Valor total contratado"
                    value={formatCurrency(contract.amount)}
                  />
                </div>
                <div className="w-46 xl:w-50">
                  <FieldReadOnlyInfo
                    label="Início do contrato"
                    value={format(
                      new Date(contract.start),
                      "dd 'de' MMMM 'de' yyyy",
                      {
                        locale: ptBR,
                      }
                    )}
                  />
                </div>
              </div>

              <div className="w-44 mt-4 xl:w-52">
                <FieldReadOnlyInfo
                  label="Fim do contrato"
                  value={format(
                    new Date(contract.end),
                    "dd 'de' MMMM 'de' yyyy",
                    {
                      locale: ptBR,
                    }
                  )}
                />
              </div>
            </div>

            <div className="w-1/2">
              <Typography variant="button" color="darkestGray">
                Cobrança
              </Typography>
              <div className="flex mt-2 flex-row space-x-2 flex-wrap w-full">
                <div className="xl:w-50">
                  <FieldReadOnlyInfo
                    label="Quilometragem inclusa"
                    value={`Até ${currentSla?.kmIncluded} km`}
                  />
                </div>
                <div className="w-1/2 xl:w-52">
                  <FieldReadOnlyInfo
                    label="Custo por km extra"
                    value={`${formatCurrency(currentSla?.extraCostPerKM)} / km`}
                  />
                </div>
              </div>
              <div className="w-52 mt-4">
                <FieldReadOnlyInfo
                  label="Custo de uso da plataforma"
                  value={`${formatCurrency(
                    currentSla?.monthlyPlatformCost
                  )} / mês`}
                />
              </div>
            </div>
          </div>
          <div>
            <Typography variant="button" color="darkestGray">
              SLA&apos;s do Cliente
            </Typography>

            <div className="flex mt-2 flex-row flex-wrap">
              <div className="w-40 mr-2 mb-4">
                <FieldReadOnlyInfo
                  label="Prazo para início do chamado"
                  value={`${currentSla?.startCalls} horas`}
                />
              </div>
              <div className="w-44 mr-2">
                <FieldReadOnlyInfo
                  label="Cancelamento mais que 24h antes"
                  value={`${currentSla?.cancellationMoreThen24h}% do valor total`}
                />
              </div>

              <div className="w-44 mr-2">
                <FieldReadOnlyInfo
                  label="Cancelamento até 24h antes"
                  value={`${currentSla?.cancellationFeeUpTo24h}% do valor total`}
                />
              </div>
              <div className="w-44 mr-2 xl:mr-2">
                <FieldReadOnlyInfo
                  label="Cancelamento menos que 6h antes"
                  value={`${currentSla?.cancellationFeeUpTo6h}% do valor total`}
                />
              </div>
              <div className="mr-2 md:mt-0 xl:mt-0 flex items-center">
                <FieldReadOnlyInfo
                  label="Custo por chamado improdutivo"
                  value={
                    currentSla?.unproductiveCostPerCall ===
                    'TOTAL_EXPECTED_COST'
                      ? 'Cobrar do total previsto'
                      : 'Cobrar custo técnico do chamado'
                  }
                />
              </div>
              <div className="w-44">
                <FieldReadOnlyInfo
                  label="Custo por chamados urgentes"
                  value={`Valor total + ${currentSla?.urgentCostPerCall}%`}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentCard>
    </div>
  );
};

export { ViewContractInformation };
