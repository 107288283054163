import { plusSign } from 'assets/images/icons';
import { IconButton } from 'components/IconButton';
import { SearchInput } from 'components/SearchInput';
import { Typography } from 'components/Typography';
import VerticalRule from 'components/VerticalRule';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { useState } from 'react';
import { TableCompanyAddresses } from '../TableCompanyAddresses';

type AddressesList = {
  address: Array<{
    address: string;
    addressBookCompanyId: string;
    alias: string;
    cep: string;
    city: string;
    complement: string;
    createdAt: string;
    distance: number;
    district: string;
    duration: number;
    id: string;
    lat: number;
    lng: number;
    number: string;
    state: string;
  }>;
};

interface CompanyAddressesProps {
  companyId?: string;
  addressesList?: AddressesList;
}

const CompanyAddresses = ({
  addressesList,
  companyId,
}: CompanyAddressesProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const UI = useUI();

  async function handleOpenSidesheet(data) {
    await UI.operations.sidesheet.openSidesheet({
      type: SidesheetTypes.SidesheetCreateCompanyAddress,
      sidesheetProps: {
        width: 'normal',
        companyId: companyId,
        addressId: data.id,
        isEdit: true,
      },
    });
  }

  return (
    <div className="flex flex-row w-full">
      <div className="w-full">
        <div className="flex flex-col gap-6">
          <Typography variant="h2" color="darkBlue">
            Endereços
          </Typography>

          <div className="flex flex-row gap-5 mb-6 w-full">
            <div className="w-1/2 ">
              <SearchInput
                setSearchText={setSearchText}
                size="large"
                placeholder="Buscar endereço"
              />
            </div>
            <VerticalRule />
            <IconButton
              icon={plusSign}
              size="large"
              onClick={() =>
                UI.operations.sidesheet.openSidesheet({
                  type: SidesheetTypes.SidesheetCreateCompanyAddress,
                  sidesheetProps: {
                    width: 'normal',
                    companyId: companyId,
                  },
                })
              }
            >
              Cadastrar novo endereço
            </IconButton>
          </div>
        </div>
        <TableCompanyAddresses
          searchText={searchText}
          handleOpenSidesheet={handleOpenSidesheet}
          listAddress={addressesList.address}
        />
      </div>
    </div>
  );
};

export { CompanyAddresses };
