import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { Subtitle } from './styles';
import { useCallback } from 'react';
import { confirm } from 'assets/images/illustrations';

export interface ConfirmCreateFormsOfServiceModalProps {
  close: () => void;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
}

const ConfirmCreateFormsOfService = ({
  close,
  onConfirm,
  isLoading,
}: ConfirmCreateFormsOfServiceModalProps) => {
  const handleClick = useCallback(async () => {
    await onConfirm();
    close();
  }, []);

  return (
    <div className="flex flex-col items-center max-w-xl text-center">
      <img src={confirm} alt="icon confirm" />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Confirmar cadastro de novo tipo de serviço
      </Typography>
      <Subtitle variant="h4" color="darkBlue" className="mb-8">
        Você está confirmando o cadastro de um novo tipo de serviço. Após
        confirmação, ele estará disponível para vincular ao contrato de um
        cliente.
      </Subtitle>
      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={close}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={() => handleClick()}
          disabled={isLoading}
        >
          Confirmar Cadastro
        </Button>
      </div>
    </div>
  );
};

export { ConfirmCreateFormsOfService };
