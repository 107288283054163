import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';

interface StorePartsAvailabilityProps {
  storeParts: boolean;
}
const StorePartsAvailability = ({
  storeParts,
}: StorePartsAvailabilityProps) => {
  return (
    <div className="space-y-4">
      <Typography variant="p1" color="darkerGray">
        Possui disponibilidade para armazenamento de peças no local?
      </Typography>

      <div className="flex flex-row items-center space-x-2">
        <RadioButton
          label="Sim"
          value="true"
          defaultChecked={storeParts === true}
          disabled
        />
        <RadioButton
          label="Não"
          value="false"
          defaultChecked={storeParts === false}
          disabled
        />
      </div>
    </div>
  );
};

export { StorePartsAvailability };
