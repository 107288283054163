import { ProgressBar } from 'components/ProgressBar';
import { Typography } from 'components/Typography';
import { ContractTypes } from 'features/Company/types';

interface CompanyBalanceProps {
  contract: ContractTypes;
}

const CompanyBalance = ({ contract }: CompanyBalanceProps) => {
  const progress = Number(
    ((contract?.balanceAvailable * 100) / contract?.amount).toFixed(0)
  );
  return (
    <div className="flex flex-col items-center justify-between p-6 space-x-4 border-2 rounded-xl border-gray">
      <div className="w-full">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="button" color="darkestGray">
            Saldo atual
          </Typography>
          <p>
            <span className="text-md text-darkGray mr-2">R$</span>
            <span className="text-2xl font-bold text-darkestGray">
              {contract?.balanceAvailable}
            </span>
            <span role="span" className="text-md text-darkGray">
              {' '}
              / {contract?.amount}
            </span>
          </p>
        </div>
      </div>

      <ProgressBar progress={progress} showPercentage />
    </div>
  );
};

export { CompanyBalance };
