import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { Typography } from 'components/Typography';
import AttachmentItem from 'components/AttachmentItem';
import {
  Container,
  AddNewItemContainer,
  AddNewItemIcon,
  FileInput,
  FilesContainer,
} from './styles';
import { toAdd } from 'assets/images/icons';

interface IAttachmentItem {
  id: string;
  file: File;
}

interface IDefaultValues extends IAttachmentItem {
  name: string;
  url: string;
  id: string;
}

interface AttachmentsProps {
  onChange(files: { id: string; file: File }[]): void;
  onDelete(id: string): void;
  defaultValues?: IDefaultValues[];
}

const Attachments = ({
  onChange,
  onDelete,
  defaultValues = [],
}: AttachmentsProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<IAttachmentItem[]>(defaultValues);

  const handleAddNewFiles = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const onChangeFiles = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        const id = v4();
        setFiles((oldFiles) => {
          onChange([...oldFiles, { id, file }]);
          return [...oldFiles, { id, file }];
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    },
    [onChange]
  );

  const loadFiles = useCallback(async () => {
    const filesFormatted = await Promise.all(
      defaultValues.map(async (item) => {
        const fileUrl = item?.url ? item.url : URL.createObjectURL(item.file);
        const downloadFile = await fetch(fileUrl);
        const data = await downloadFile.blob();
        const filename = item.name ?? item.file.name;
        const file = new File([data], filename, { type: data.type });

        return {
          id: item?.id,
          file,
        };
      })
    );
    setFiles(filesFormatted);
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues.length > 0) {
      loadFiles();
    }
  }, [defaultValues, loadFiles]);

  return (
    <Container>
      <Typography variant="h3" color="darkestGray">
        Anexos
        <span className="ml-1 text-sm font-medium text-darkGray">
          (opcional)
        </span>
      </Typography>
      <FilesContainer>
        {files.map((file) => (
          <div key={file.id} className="flex items-center w-full space-x-4">
            <AttachmentItem
              key={v4()}
              id={file.id}
              file={file.file}
              onDelete={() => {
                setFiles((files) =>
                  files.filter((_file) => _file.id !== file.id)
                );
                onDelete(file.id);
              }}
            />
          </div>
        ))}
      </FilesContainer>
      <AddNewItemContainer type="button" onClick={() => handleAddNewFiles()}>
        <AddNewItemIcon>
          <img src={toAdd} alt="Add new file" />
        </AddNewItemIcon>
        <Typography variant="button" color="darkerGray">
          Adicionar novo anexo
        </Typography>
      </AddNewItemContainer>
      <FileInput
        ref={fileInputRef}
        type="file"
        accept="application/pdf,image/png,image/jpg,image/jpeg"
        onChange={onChangeFiles}
      />
    </Container>
  );
};

export { Attachments };
