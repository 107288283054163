import styled, { css, keyframes } from 'styled-components';

interface MinutePickerItemProps {
  active: boolean;
}

const rotateAnimation = keyframes`
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
`;

interface StylesProps {
  isOpen: boolean;
}

export const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const MinutePickerContainer = styled.div<StylesProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 16px;
  height: 100%;
  img {
    ${({ isOpen }) =>
      isOpen &&
      css`
        animation: ${rotateAnimation} 0.5s ease;
        transform: rotate(180deg);
      `}

    margin-left: 20px;
  }
`;

export const MinutePickerListContainer = styled.ul<StylesProps>`
  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  position: absolute;
  z-index: 1;
  max-height: 198px;

  list-style: none;
  padding: 16px;
  top: 44px;

  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.lighterGray};
  }
  ::-webkit-scrollbar {
    width: 3px;
    background: ${({ theme }) => theme.colors.lighterGray};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const MinutePickerItem = styled.li<MinutePickerItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  padding: 16px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};

  &:first-of-type {
    padding: 0 0 16px 0;
  }

  &:last-of-type {
    padding: 16px 0 0 0;
    border-bottom: none;
  }

  ${({ active }) =>
    active &&
    css`
      background: rgba(128, 107, 255, 0.05);
    `}

  &:hover {
    background: rgba(128, 107, 255, 0.05);
  }
`;
