import { SearchClient } from 'algoliasearch/lite';
import AlgoliaFilterConfiguration from 'components/AlgoliaFilterConfiguration';
import { ReactNode } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';

interface AlgoliaTotalCountProps {
  indexName: string;
  searchClient: SearchClient;
  filters: string;
  children: ReactNode;
}

const AlgoliaTotalCount = ({
  indexName,
  searchClient,
  filters,
  children,
}: AlgoliaTotalCountProps) => {
  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <AlgoliaFilterConfiguration filters={filters} />
      {children}
    </InstantSearch>
  );
};

export { AlgoliaTotalCount };
