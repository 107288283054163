import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 603px;
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Header = styled.div`
  padding: 2rem 1.5rem;
  background: ${({ theme }) => theme.colors.lightGray};
`;

export const TextInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;

  input, textarea {
    width: 100%;
  }
`;

export const Footer = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2.5rem;
  background: ${({ theme }) => theme.colors.lightGray};

  @media (max-width: 1440px) {
    padding: 1rem 1.5rem;
  }

  .submit-button {
    width: 274px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;