import { useState, useCallback } from 'react';

export interface IAlgolia {
  filters: string;
  handleFilter(filter: string): void;
}

interface IAlgoliaHook {
  defaultFilterValue: string;
}

export default function useAlgolia({ defaultFilterValue }: IAlgoliaHook): IAlgolia {
  const [filters, setFilters] = useState(defaultFilterValue);
  
  const handleFilter = useCallback((filter: string) => {
    setFilters(filter);
  }, []);

  return {
    filters,
    handleFilter
  }
}
