import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

import { Subtitle, TextContainer } from './styles';

import { useCallback } from 'react';
import { confirm } from 'assets/images/illustrations';

export interface ConfirmChangeRegistrationDataModalProps {
  close: () => void;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
  newCollaboratorEmail?: string;
}

const ConfirmChangeRegistrationData = ({
  close,
  onConfirm,
  isLoading,
  newCollaboratorEmail,
}: ConfirmChangeRegistrationDataModalProps) => {
  const handleClick = useCallback(async () => {
    await onConfirm();
    close();
  }, []);

  return (
    <div className="flex flex-col items-center max-w-xl text-center">
      <img src={confirm} />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Alteração de dados cadastrais
      </Typography>
      {newCollaboratorEmail ? (
        <TextContainer className="mb-8">
          <Subtitle variant="h4" color="darkBlue">
            Você está alterando os dados cadastrais. Após a confirmação, será
            enviado um convite para o novo Responsável:
          </Subtitle>
          <Subtitle variant="h4" color="darkBlue">
            <strong>{newCollaboratorEmail}</strong>
          </Subtitle>
          <Subtitle variant="h4" color="darkBlue">
            com o convite para acessar a plataforma.
          </Subtitle>
        </TextContainer>
      ) : (
        <Subtitle variant="h4" color="darkBlue" className="mb-8">
          Você está alterando os dados cadastrais. Após a confirmação, as
          informações serão atualizadas no sistema.
        </Subtitle>
      )}

      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={close}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={() => handleClick()}
          disabled={isLoading}
        >
          Confirmar
        </Button>
      </div>
    </div>
  );
};

export { ConfirmChangeRegistrationData };
