import { pdf } from 'assets/images/icons';
import { Typography } from 'components/Typography';

interface FileDropProps {
  onChange: (e) => void;
}

const FileDrop = ({ onChange }: FileDropProps) => {
  return (
    <label className="flex flex-col items-center w-full p-4 space-y-2 border-2 cursor-pointer border-gray rounded-xl">
      <input
        data-testid="input"
        type="file"
        className="hidden"
        onChange={onChange}
      />
      <img src={pdf} className="h-12" alt="Ícone de PDF" />
      <Typography variant="h3" color="darkerGray">
        Clique aqui para fazer upload de documentos
      </Typography>
      <Typography variant="button" color="darkGray">
        Formatos suportados: .PDF e .JPG até 5MB
      </Typography>
    </label>
  );
};

export { FileDrop };
