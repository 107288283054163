import { endOfMonth, startOfMonth } from 'date-fns';
import sub from 'date-fns/sub';

export const THIRTY_DAYS_AGO = sub(new Date(), {
  days: 31,
});

export const LAST_MONTH = THIRTY_DAYS_AGO.getMonth();
export const YEAR_THIRTY_DAYS_AGO = THIRTY_DAYS_AGO.getFullYear();

export const CURRENT_DATE = new Date();
export const CURRENT_MONTH = CURRENT_DATE.getMonth();
export const CURRENT_YEAR = CURRENT_DATE.getFullYear();

export const INDEX_MONTHS = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

export function ExtractMonthAndYear(valueDate) {
  const month = valueDate?.getMonth();
  const year = valueDate?.getFullYear();

  return {
    month,
    year,
  };
}

export function FirstAndLastDayMonth(dateSelected) {
  if (dateSelected) {
    const beginningOfSelectedMonth = startOfMonth(dateSelected).toISOString();
    const endOfSelectedMonth = endOfMonth(dateSelected).toISOString();

    return {
      beginningOfSelectedMonth,
      endOfSelectedMonth,
    };
  }
}
