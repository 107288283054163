import { combineReducers } from '@reduxjs/toolkit';
import { uiSlice } from 'ducks/ui';
import { callSummarySlice } from 'ducks/callSummary';
import { authSlice } from 'ducks/auth';

const rootReducer = combineReducers({
  ui: uiSlice.reducer,
  callSummary: callSummarySlice.reducer,
  authSlice: authSlice.reducer,
});

export default rootReducer;
