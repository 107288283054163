import { Pagination, usePaginationData } from 'components/Pagination';
import { Typography } from 'components/Typography';
import { useMemo, useState } from 'react';
import { IProfessional, ProfessionalCard } from '../../ProfessionalCard';
import { ProfessionalsContainer } from '../styles';

interface ListTechniciansProps {
  dataTechnicians: IProfessional[];
  searchText: string;
  professionalsSelected: IProfessional[];
  onAddProfessional: (professional: IProfessional) => void;
  onRemoveProfessional: (id: string) => void;
  isDisabled?: boolean;
}

const ListTechnicians = ({
  dataTechnicians,
  onAddProfessional,
  onRemoveProfessional,
  professionalsSelected,
  searchText,
  isDisabled = false,
}: ListTechniciansProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const filterByName = useMemo(() => {
    return dataTechnicians?.filter((professional) =>
      professional.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [dataTechnicians, searchText]);

  const dataPagination = usePaginationData({
    objectData: filterByName,
    currentPage,
  });

  return (
    <div>
      {dataPagination?.length > 0 ? (
        <ProfessionalsContainer>
          {dataPagination?.map((professional) => (
            <ProfessionalCard
              key={professional.id}
              checked={
                !isDisabled &&
                professionalsSelected.map((p) => p.id).includes(professional.id)
              }
              professional={professional}
              onAddProfessional={onAddProfessional}
              onRemoveProfessional={onRemoveProfessional}
              disabled={isDisabled}
            />
          ))}
        </ProfessionalsContainer>
      ) : (
        <Typography variant="h4" color="darkestGray">
          Nenhum profissional encontrado
        </Typography>
      )}

      <Pagination
        currentPage={currentPage}
        totalCount={filterByName?.length}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export { ListTechnicians };
