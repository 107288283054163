import clsx from 'clsx';
import { useState } from 'react';
import { HideButton } from './styles';
import { getIcon } from 'utils/getIcons';

type AlertTypes = 'danger' | 'success' | 'info' | 'warning';
type AlertVariants = 'normal' | 'colored';

interface AlertProps {
  title?: string;
  type: AlertTypes;
  variant?: AlertVariants;
  noIcon?: boolean;
  children?: React.ReactNode;
}

const Alert = ({
  title = null,
  type,
  children,
  variant = 'normal',
  noIcon = false,
}: AlertProps) => {
  function getTitle() {
    switch (type) {
      case 'danger':
        return 'Atenção!';
    }
  }

  const [hiddenAlert, setHiddenAlert] = useState(false);

  const renderBackgroundByType = () => {
    const bgVariant = {
      danger: 'bg-orange',
      success: 'bg-green',
      info: 'bg-purple',
      warning: 'bg-transparent',
    };

    if (!bgVariant[type]) {
      return 'bg-transparent';
    }

    return bgVariant[type];
  };

  return (
    <div
      data-testid="container"
      className={clsx('flex flex-col p-4 space-y-2 rounded-xl', {
        'bg-lightGray': variant === 'normal',
        'bg-orange bg-opacity-10': variant === 'colored' && type === 'danger',
        'bg-green bg-opacity-10': variant === 'colored' && type === 'success',
        hidden: hiddenAlert,
      })}
    >
      {(title || getTitle()) && (
        <div className="flex justify-between pb-2">
          <p className="flex items-center space-x-2 text-sm font-bold text-darkestGray">
            {!noIcon && (
              <span
                className={clsx(
                  'w-4 h-4 rounded items-center flex justify-center',
                  renderBackgroundByType()
                )}
              >
                <img src={getIcon(type)} alt="icon" />
              </span>
            )}
            <span>{title || getTitle()}</span>
          </p>
          {type === 'warning' && (
            <HideButton variant="outlined" onClick={() => setHiddenAlert(true)}>
              Não mostrar
            </HideButton>
          )}
        </div>
      )}

      {children && <p className="text-sm text-darkestGray">{children}</p>}
    </div>
  );
};

export { Alert };
