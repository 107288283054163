import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  loading: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
      state.loading = false;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.loading = false;
    },
  },
  extraReducers: {},
});

export default {
  authSlice,
};
