import styled from 'styled-components';
import { Typography } from 'components/Typography';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  strong {
    text-decoration: underline;
  }
`;

export const Subtitle = styled(Typography)`
  max-width: 530px;
  text-align: center;
`;
