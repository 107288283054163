import { Typography } from 'components/Typography';
import React, { memo } from 'react';

import { Container } from './styles';

export interface IOptionItem {
  id?: string;
  value: string;
  label: string;
}

interface OptionProps {
  option: IOptionItem;
  active?: boolean;
  onSelectItem: (option: IOptionItem) => void;
}

const Option = ({ option, onSelectItem, active = false }: OptionProps) => {
  return (
    <Container
      data-testid="list"
      role="button"
      onClick={() => onSelectItem(option)}
      active={active}
    >
      <Typography variant="button" color="darkerGray">
        {option.label}
      </Typography>
    </Container>
  );
};

export default memo(Option);
