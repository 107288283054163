import React from 'react';
import { Typography } from 'components/Typography';
import { Info } from '../styles';
import { TextField } from 'components/Inputs/TextField';
import { Field, useFormikContext } from 'formik';
import { cepMask } from 'utils/maskUtils';
import { getAddress } from 'services/cep';
import { DropDownState } from 'components/DropDownState';

interface IAddressInfoFormContext {
  address: {
    state: string;
  };
}

const AddressInfoForm = () => {
  const { setFieldValue, values } = useFormikContext<IAddressInfoFormContext>();

  async function handleSearchCep(cep) {
    const address = await getAddress(cep);
    setFieldValue('address.cep', address.cep);
    setFieldValue('address.complement', address.complemento);
    setFieldValue('address.city', address.localidade);
    setFieldValue('address.address', address.logradouro);
    setFieldValue('address.state', address.uf);
    setFieldValue('address.district', address.bairro);
  }

  return (
    <Info data-testid="address-info-form">
      <Typography variant="h3" color="darkBLue">
        Endereço residencial
      </Typography>
      <div className="flex flex-row w-full space-x-4">
        <div className="w-1/2">
          <Field
            component={TextField}
            label="CEP"
            placeholder="Insira o CEP"
            name="address.cep"
            onChange={(e) => {
              const value = cepMask(e.target.value);
              setFieldValue('address.cep', value);
              if (value.length === 9) {
                handleSearchCep(value);
              }
            }}
          />
        </div>
        <div className="w-1/2">
          <Field
            component={TextField}
            label="Bairro"
            placeholder="Insira o Bairro"
            name="address.district"
          />
        </div>
      </div>

      <div className="flex flex-row w-full space-x-4">
        <div className="w-full">
          <Field
            component={TextField}
            label="Endereço"
            placeholder="Insira o endereço"
            width="100%"
            name="address.address"
          />
        </div>
        <div className="w-1/3">
          <Field
            component={TextField}
            label="Número"
            placeholder="Insira o número"
            width="100%"
            name="address.number"
          />
        </div>
      </div>

      <div className="flex flex-row w-full space-x-4">
        <DropDownState
          value={values.address.state}
          onChange={(e) => setFieldValue('address.state', e)}
        />

        <div className="w-full">
          <Field
            component={TextField}
            label="Cidade"
            placeholder="Insira a cidade"
            width="100%"
            name="address.city"
          />
        </div>
      </div>

      <div className="w-full">
        <Field
          component={TextField}
          label="Complemento"
          placeholder="Insira o complemento"
          showOptionalLabel
          width="100%"
          name="address.complement"
        />
      </div>
    </Info>
  );
};

export { AddressInfoForm };
