import styled from 'styled-components';

const outerBackgroundColorMapping = {
  greenClick: 'rgba(153, 227, 178, 0.3)',
  purple: 'rgba(128, 107, 255, 0.3)',
};

const getInnerBackgroundColor = ({ color, theme: { colors } }) => {
  return colors[color];
};

const getOuterBackgroundColor = ({ color }) => {
  return outerBackgroundColorMapping[color];
};

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.lighterGray};
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  min-height: 128px;
  height: auto;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  border-radius: 0 16px;
  background-color: ${getOuterBackgroundColor};
  width: 65px;
  height: 60px;
`;

export const Icon = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 44px;
  height: 40px;
  background-color: ${getInnerBackgroundColor};
  border-radius: 0 8px 0 16px;
  object-fit: none;
`;
