import styled, { css } from 'styled-components';

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74%;
  width: auto;

  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 16px;

  transition: border-color 0.2s;
  &:hover,
  &:active {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;

      &:hover {
        border-color: ${({ theme }) => theme.colors.lightGray};
      }
    `}
`;

export const Divider = styled.div`
  width: 2px;
  height: 53px;
  background: ${({ theme }) => theme.colors.lightGray};
`;
