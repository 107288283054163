import { defaultAvatar } from 'assets/images/icons';
import { Button } from 'components/Button';
import { LinearProgress } from 'components/LinearProgress';
import { Typography } from 'components/Typography';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';

const SearchingCard = ({ id, companyId, status }) => {
  const UI = useUI();
  return (
    <div className="flex items-center justify-between p-4 border-2 rounded-xl border-lightGray">
      <div className="flex items-center space-x-4">
        <div className="flex items-center justify-center w-12 h-12 border-2 rounded-xl border-lightGray">
          {status === 'SUPPORT_CANCELED' ? (
            <img src={defaultAvatar} />
          ) : (
            <LinearProgress />
          )}
        </div>
        <div className="flex flex-col">
          <Typography variant="h3" color="darkestGray">
            {status === 'SUPPORT_CANCELED'
              ? 'Não atribuído'
              : 'Buscando profissional'}
          </Typography>
        </div>
      </div>
      {status !== 'SUPPORT_CANCELED' && (
        <div className="flex flex-col float-right">
          <Button
            className="flex whitespace-nowrap"
            variant="outlined"
            onClick={() => {
              UI.operations.sidesheet.openSidesheet({
                type: SidesheetTypes.SidesheetLinkTechnician,
                sidesheetProps: {
                  callId: id,
                  companyId: companyId,
                },
              });
            }}
          >
            Adicionar manualmente
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchingCard;
