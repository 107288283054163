import styled, { css } from 'styled-components';
import type { ButtonProps } from '.';

export const AnchorButton = styled.button`
    text-decoration: underline;
    ${({ theme }) => css`
      font-size: ${theme.typography.button.fontSize};
      font-weight: ${theme.typography.button.fontWeight};
      color: ${theme.colors.darkerGray}; 
  `}
`;

const getWidth = ({ block, width }: ButtonProps) => {
  return block ? '100%' : `${width}px`;
};

export const Button = styled.button<ButtonProps>`
  background-color: ${({ variant, theme, color }) =>
    variant == 'outlined'
      ? theme.colors.white
      : theme.colors[color]};

  ${({ isLoading }) => isLoading && `opacity: 0.5;`}

  border: 2px solid
    ${({ variant, disabled, theme, color }) => {
    if (variant == 'outlined' && disabled) {
      return theme.colors.gray;
    }
    if (variant == 'contained') {
      return theme.colors[color];
    } else if (variant == 'outlined') {
      if (disabled) {
        return theme.colors.lighterGray;
      } else {
        return theme.colors[color];
      }
    }
  }};
  border-radius: ${({ size }) => {
    switch (size) {
      case 'small':
        return '8px';
      case 'medium':
        return '12px';
      case 'large':
        return '16px';
    }
  }};
  box-sizing: border-box;
  color: ${({ variant, theme, disabled }) => {
    if (variant == 'contained') {
      return theme.colors.white;
    } else if (variant == 'outlined') {
      if (disabled) {
        return theme.colors.darkGray;
      } else {
        return theme.colors.darkerGray;
      }
    }
  }};
  ${({ theme }) => css`
    font-size: ${theme.typography.button.fontSize};
    font-weight: ${theme.typography.button.fontWeight};
  `};
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '32px';
      case 'medium':
        return '40px';
      case 'large':
        return '48px';
    }
  }};
  opacity: ${({ variant, disabled }) => {
    if (variant == 'contained' && disabled) {
      return 0.3;
    } else {
      return 1.0;
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '4px';
      case 'medium':
        return '8px';
      case 'large':
        return '12px';
    }
  }};
  width: ${(props) => getWidth(props)};
  ${({ disabled, disableHover, theme, color }) =>
    !disabled
      ? css`transition: 0.2s;
      ${!disableHover &&
        css`&:hover {
        background-color: ${theme.colors.white};
        color: ${theme.colors[color]};
      }`
        }
        &:active {
          transform: scale(0.9);
        }
        &:active, &:focus {
          outline: 0;
        }`
      : css`cursor: not-allowed`};
`;
