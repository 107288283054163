import axios from 'axios';
import { getToken, getRefreshToken, setToken } from './auth';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === '/auth/refresh'
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      const apiWithRefreshToken = axios.create({
        baseURL: process.env.NEXT_PUBLIC_API_URL,
        headers: { Authorization: `Bearer ${refreshToken}` },
      });

      return apiWithRefreshToken
        .get('/auth/refresh')
        .then((res) => {
          if (res.status === 200) {
            setToken(
              res.data.tokens.access_token,
              res.data.tokens.refresh_token
            );
            originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
            return api(originalRequest);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    return Promise.reject(error);
  }
);

export const fetcher = (url) => api.get(url).then((res) => res.data);

export default api;

const apiNotToken = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

export { apiNotToken };
