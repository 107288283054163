/* eslint-disable */
import DayPicker from 'react-day-picker';
import format from 'date-fns/format';
import { differenceInHours, formatDistanceToNow } from 'date-fns';
import 'react-day-picker/lib/style.css';

import { useCall } from 'queries/call';
import { formatCurrency } from 'utils/formatCurrency';

import ClientCard from './ClientCard';
import ReviewItem from './ReviewItem';
import StatusCard from './StatusCard';
import TechnicianCard from './TechnicianCard';
import { AttachmentCard } from 'components/AttachmentCard';
import { Typography } from 'components/Typography';
import CancelSection from './CancelSection';
import ptBR from 'date-fns/locale/pt-BR';
import SearchingCard from './SearchingCard';
import { TechnicianButtonContainer } from './styles';
import { useUI } from 'hooks/useUI';
import { SidesheetTypes } from 'ducks/ui/types';
import { formatUTCHour } from 'utils/formatUtils';
import {
  alertWhite,
  changeActive,
  clock,
  dollarSign,
  id,
  toolsPurple,
} from 'assets/images/icons';

const ServiceTab = ({ callId, close, isFinished = false }) => {
  const { call, isLoading, refetch } = useCall(callId);
  const UI = useUI();

  if (isLoading) {
    return null;
  }

  const canCancel = [
    'LOOKING_FOR_PROFESSIONAL',
    'PROFESSIONAL_NOT_FOUND',
    'SCHEDULED_CALL',
    'WAITING_TECHNICIANS_ACCEPTANCE',
    'DELAYED_CALL',
    'REQUESTED_CANCELLATION',
    'ON_THE_WAY',
    'SUPPORT_STARTED',
    'CALL_REFUSED_BY_TECHNICIAN',
  ].includes(call?.status);

  const canUnbind = [
    'PROFESSIONAL_NOT_FOUND',
    'SCHEDULED_CALL',
    'WAITING_TECHNICIANS_ACCEPTANCE',
    'DELAYED_CALL',
    'CALL_REFUSED_BY_TECHNICIAN',
  ].includes(call?.status);

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col px-6 py-8 pb-8 space-y-4">
        <Typography variant="h2" color="darkestGray">
          Resumo
        </Typography>
        <div className="flex flex-col divide-y-2 divide-lightGray">
          <ReviewItem label={`${call?.id}`} icon={id} />
          {call?.type === 'Urgent' && (
            <ReviewItem label="Chamado Urgente" icon={alertWhite} danger />
          )}
          <ReviewItem label={call?.service?.name} icon={toolsPurple} />
          <ReviewItem
            label={`${formatCurrency(call?.value)}`}
            icon={dollarSign}
          />
          {call?.serviceType === 'HOUR' ? (
            <ReviewItem
              label="Chamado por hora"
              icon={clock}
              description={`${format(
                formatUTCHour(call?.startTime),
                "H'h'mm"
              )} à ${format(
                formatUTCHour(call?.finalTime),
                "H'h'mm"
              )} (${differenceInHours(
                formatUTCHour(call?.finalTime),
                formatUTCHour(call?.startTime)
              )} horas de trabalho)`}
            />
          ) : (
            <ReviewItem label="Cobrança por chamado" icon={clock} />
          )}
          <DayPicker
            weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
            months={[
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ]}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            classNames={{
              weekday: '!no-underline ',
              disabled: 'cursor-default',
              month: 'flex flex-col space-y-2 font-bold text-darkestGray py-2',
              weekdaysRow:
                'bg-gray py-1 rounded-md text-darkerGray text-sm font-bold flex justify-around space-x-2',
              weekNumber: 'flex !no-underline',
              day: 'flex font-medium text-darkGray w-[80px] justify-center text-sm cursor-pointer py-1',
              week: 'flex justify-around space-x-2',
              selected: 'bg-purple !text-white rounded-md',
            }}
            initialMonth={new Date(call?.startTime)}
            disabledDays={[
              {
                before: new Date(call?.startTime),
                after: new Date(call?.finalTime),
              },
            ]}
            selectedDays={[
              {
                from: new Date(call?.startTime),
                to: new Date(call?.finalTime),
              },
            ]}
          />
        </div>
        <Typography variant="h2" color="darkestGray">
          Cliente
        </Typography>
        <ClientCard id={call?.companyId} callId={call?.id} />
        <Typography variant="h2" color="darkestGray">
          Profissional
        </Typography>

        {call?.callToTechnician?.length > 0 ? (
          <>
            <TechnicianCard
              id={call?.callToTechnician[0]?.technicianId}
              callId={call?.id}
            />
            {call?.callToTechnician[0] && canUnbind && (
              <TechnicianButtonContainer>
                <button
                  className="flex items-center justify-center w-full px-4 py-2 space-x-2 bg-opacity-50 rounded-md hover:bg-opacity-80 focus:outline-none bg-lightGray"
                  onClick={() => {
                    UI.operations.sidesheet.openSidesheet({
                      type: SidesheetTypes.SidesheetLinkTechnician,
                      sidesheetProps: {
                        callId: call?.id,
                        companyId: call?.companyId,
                        onAfterClose: () => {
                          refetch();
                        },
                      },
                    });
                  }}
                >
                  <div className="p-2 rounded-md bg-lightGray">
                    <img src={changeActive} />
                  </div>
                  <span className="font-bold text-darkerGray">
                    Desvincular do chamado
                  </span>
                </button>
              </TechnicianButtonContainer>
            )}
          </>
        ) : (
          <SearchingCard {...call} />
        )}
        <Typography variant="h2" color="darkestGray">
          Status
        </Typography>
        <StatusCard
          status={call?.status}
          time={formatDistanceToNow(
            new Date(
              call?.callLog?.[call?.callLog?.length - 1]?.createdAt || null
            ),
            { locale: ptBR, addSuffix: true }
          )}
        />
        {/* <Typography variant="h2" color="darkestGray">
          Serviço
        </Typography>
        <div className="flex flex-col p-4 space-y-4 border-2 rounded-xl border-lightGray">
          <Typography variant="h4" color="darkestGray">
            Requisitos
          </Typography>
          <Typography variant="p3" color="darkerGray">
            Equipamentos
          </Typography>
          <ul>
            {call?.equipment?.map((e) => (
              <li key={e}>
                <Typography variant="p1" color="darkerGray">
                  - {e}
                </Typography>
              </li>
            ))}
          </ul>
          <Typography variant="h4" color="darkestGray">
            Descrição
          </Typography>
          <section className="flex flex-col space-y-4">
            <Typography variant="p1" color="darkerGray">
              {call?.serviceDetail}
            </Typography>
          </section>
        </div> */}
        <Typography variant="h2" color="darkestGray">
          Descrição
        </Typography>
        <div className="flex flex-col p-4 space-y-4 border-2 rounded-xl border-lightGray">
          <Typography variant="p1" color="darkerGray">
            {call?.serviceDetail}
          </Typography>
        </div>

        {Boolean(call?.attachments?.length) && (
          <>
            <Typography variant="h2" color="darkestGray">
              Anexos
            </Typography>
            {call?.attachments?.map((a) => {
              return (
                <AttachmentCard
                  key={a.id}
                  name={a.name}
                  description={a.description}
                  handleDownload={() => window.open(a.url)}
                />
              );
            })}
          </>
        )}
      </div>
      {!isFinished && canCancel && (
        <CancelSection callId={callId} close={close} />
      )}
    </div>
  );
};

export default ServiceTab;
