import { magnifier } from 'assets/images/icons';
import clsx from 'clsx';

interface SearchInputProps {
  disabled?: boolean;
  placeholder?: string;
  setSearchText: (string) => void;
  size?: 'large' | 'small';
  searchText?: string;
}

const SearchInput = ({
  size = 'small',
  disabled = false,
  placeholder = 'Buscar',
  setSearchText,
  searchText,
}: SearchInputProps) => {
  return (
    <div
      className={clsx(
        'items-center border-2 px-2 border-lightGray focus:border-purple hover:border-purple',
        {
          'cursor-not-allowed': disabled,
          'py-0 rounded-md': size === 'small',
          'py-6 rounded-xl': size === 'large',
        }
      )}
    >
      <div
        className={clsx('flex items-center', {
          'space-x-6 px-4': size === 'large',
          'space-x-6 px-2': size === 'small',
        })}
      >
        <div
          className={clsx('flex rounded-md pointer-events-none bg-purple', {
            'p-2': size === 'large',
            'p-[2px]': size === 'small',
          })}
        >
          <img src={magnifier} alt="Ícone lupa" width="16" height="16" />
        </div>
        <input
          type="search"
          className={clsx('w-full text-darkerGray text-sm', {
            'p-4': size === 'large',
            'p-1 text-sm': size === 'small',
          })}
          placeholder={placeholder}
          disabled={disabled}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
    </div>
  );
};

export { SearchInput };
