import { formatCurrency } from 'utils/formatCurrency';
import { Typography } from 'components/Typography';
import { SummaryCard } from 'components/SummaryCard';
import { dollarSign } from 'assets/images/icons';

interface RevenueSumaryCardProps {
  valueRevenue: number;
}

const RevenueSumaryCard = ({ valueRevenue = 0 }: RevenueSumaryCardProps) => {
  return (
    <div className="flex flex-row w-full">
      <SummaryCard title="Renda" icon={dollarSign} color="greenClick">
        <div className="flex flex-col">
          <Typography variant="tag" color="darkerGray">
            No último mês
          </Typography>
          <div className="flex flex-row items-end gap-1">
            <Typography variant="tag" color="darkGray" className="mb-2.5">
              R$
            </Typography>
            <Typography variant="h1" color="darkBlue">
              {formatCurrency(valueRevenue).replace('R$', '')}
            </Typography>
          </div>
        </div>
      </SummaryCard>
    </div>
  );
};

export { RevenueSumaryCard };
