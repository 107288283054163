import { Header, Main, Info, Footer } from './styles';
import { Typography } from 'components/Typography';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { RegionList } from './RegionList';
import { ServiceList } from './ServiceList';
import { Button } from 'components/Button';
import { Technicians } from 'services/technician';
import yn from 'yn';
import {
  validateBirthDate,
  validateCNPJ,
  validateCpf,
} from 'utils/validateUtils';
import React, { useState } from 'react';
import { RadioButton } from 'components/RadioButton';
import { Media } from 'services/media';
import { useUI } from 'hooks/useUI';
import { Displacement } from './Displacement';
import { EnglishLevel } from './EnglishLevel';
import { PeriodsChecklist } from './PeriodsChecklist';
import { PersonalInfoForm } from './PersonalInfoForm';
import { AddressInfoForm } from './AddressInfoForm';
import { CnpjCard } from './CnpjCard';
import uploadAttachments from 'services/attachments';
import { FormCanBuyParts } from './FormCanBuyParts';

const initialValues = {
  name: '',
  birthDate: '',
  cpf: '',
  rg: '',
  cnpj: '',
  email: '',
  telephone: '',
  storeParts: null,
  urgentCall: 'true',
  cnpjCard: '',
  address: {
    cep: '',
    address: '',
    state: '',
    city: '',
  },
  periods: [
    { weekDay: 1, morning: false, night: false, afternoon: false },
    { weekDay: 2, morning: false, night: false, afternoon: false },
    { weekDay: 3, morning: false, night: false, afternoon: false },
    { weekDay: 4, morning: false, night: false, afternoon: false },
    { weekDay: 5, morning: false, night: false, afternoon: false },
    { weekDay: 6, morning: false, night: false, afternoon: false },
    { weekDay: 0, morning: false, night: false, afternoon: false },
  ],
  services: [],
  extraServices: [],
  regions: [],
  acceptTermsOfUse: true,
  transportation: [],
  canBuyParts: false,
};

const NewTechnician = ({ close, onAfterClose }) => {
  const UI = useUI();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const services = await Promise.all(
        values.services.map(async (s) => {
          const c = s.certifications.map(async (c) => await Media.upload(c));
          const result = {
            id: s.id,
            name: s.name,
            comment: s.comment,
            certifications: await Promise.all(c),
          };

          return result;
        })
      );

      const regions = values.regions.map((region) => ({
        ibge_cod: region.ibge_cod,
      }));

      const data = {
        ...values,
        regions,
      };

      if (values.cnpjCardFile) {
        const cnpjCardUploads = await uploadAttachments([values.cnpjCardFile]);

        if (cnpjCardUploads.length > 0) {
          data.cnpjCard = cnpjCardUploads[0].url;
        }
      }

      await Technicians.create({
        ...data,
        services: services,
        storeParts: yn(data.storeParts),
        urgentCall: yn(data.urgentCall),
      });

      UI.operations.showToast('Técnico cadastrado com sucesso.');
      close();
      onAfterClose && onAfterClose();
    } catch {
      UI.operations.showToast('Falha ao cadastrar técnico.', { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Campo obrigatório'),
    birthDate: Yup.string()
      .test(
        'validate-birthDate',
        'Insira uma Data de nascimento válida',
        (value) => {
          if (!value) {
            return true;
          }
          return validateBirthDate(value);
        }
      )
      .required('Campo obrigatório')
      .nullable(),
    cpf: Yup.string()
      .test('cpf', 'Insira um CPF válido', (cpf) => validateCpf(cpf))
      .required('Campo obrigatório'),
    rg: Yup.string(),
    cnpj: Yup.string()
      .test('cnpj', 'Insira um CNPJ válido', (cnpj) => validateCNPJ(cnpj))
      .required(),
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required('Campo obrigatório'),
    storeParts: Yup.boolean().required('Campo obrigatório'),
    telephone: Yup.string()
      .matches(
        /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
        'Insira um telefone válido'
      )
      .required('Campo obrigatório'),
    address: Yup.object({
      cep: Yup.string()
        .matches(/^\d{5}-\d{3}$/, 'Insira um CEP válido')
        .required('Campo obrigatório'),
      address: Yup.string().required('Campo obrigatório'),
      state: Yup.string().required('Campo obrigatório'),
      city: Yup.string().required('Campo obrigatório'),
      district: Yup.string().required('Campo obrigatório'),
      number: Yup.string().required('Campo obrigatório'),
      complement: Yup.string(),
    }),
    regions: Yup.array()
      .of(
        Yup.object().shape({
          regionName: Yup.string(),
          id: Yup.string(),
        })
      )
      .min(1),
    services: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          comment: Yup.string(),
          name: Yup.string(),
          certifications: Yup.array(),
        })
      )
      .min(1),
    periods: Yup.array()
      .of(
        Yup.object().shape({
          morning: Yup.boolean(),
          night: Yup.boolean(),
          afternoon: Yup.boolean(),
          weekDay: Yup.number(),
        })
      )
      .compact((check) => {
        if (check.morning || check.night || check.afternoon) {
          return false;
        }
        return true;
      })
      .min(1),
    transportation: Yup.array().min(1),
    canBuyParts: Yup.boolean().required('Campo obrigatório'),
  });

  return (
    <div className="flex flex-col" data-testid="new-technician">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ isSubmitting, isValid, values, setFieldValue }) => (
          <Form>
            <Header>
              <Typography variant="h1" color="darkBLue">
                Cadastro de novo técnico
              </Typography>
            </Header>
            <Main>
              <PersonalInfoForm />
              <AddressInfoForm />
              <Info>
                <Typography variant="p1" color="darkerGray">
                  Possui disponibilidade para armazenamento de peças no local?
                </Typography>
                <div className="flex flex-row space-x-32">
                  <div className="flex flex-row items-center space-x-2">
                    <RadioButton
                      checked={values.storeParts === 'true'}
                      onChange={() => setFieldValue('storeParts', 'true')}
                      value="true"
                      label="Sim"
                    />
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <RadioButton
                      checked={values.storeParts === 'false'}
                      onChange={() => setFieldValue('storeParts', 'false')}
                      value="false"
                      label="Usar outro endereço"
                    />
                  </div>
                </div>
              </Info>
              <CnpjCard />
              <Displacement />
              <RegionList regions={values.regions} />
              <EnglishLevel />
              <ServiceList services={values.services} />
              <PeriodsChecklist />
              <Typography variant="p1" color="darkerGray">
                Receber chamados urgentes?
              </Typography>
              <div className="flex flex-row space-x-32">
                <div className="flex flex-row items-center space-x-2">
                  <RadioButton
                    checked={values.urgentCall === 'true'}
                    onChange={() => setFieldValue('urgentCall', 'true')}
                    value="true"
                    label="Sim"
                  />
                </div>
                <div className="flex flex-row items-center space-x-2">
                  <RadioButton
                    checked={values.urgentCall === 'false'}
                    onChange={() => setFieldValue('urgentCall', 'false')}
                    value="false"
                    label="Não"
                  />
                </div>
              </div>
              <FormCanBuyParts
                checked={values.canBuyParts === true}
                onChange={() => setFieldValue('canBuyParts', true)}
                checked2={values.canBuyParts === false}
                onChange2={() => setFieldValue('canBuyParts', false)}
              />
            </Main>
            <Footer className="justify-self-end">
              <Button
                onClick={() => null}
                type="submit"
                disabled={isSubmitting || !isValid}
                loading={loading}
              >
                Finalizar cadastro
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { NewTechnician };
