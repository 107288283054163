
import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => lighten(0.029, theme.colors.lightGray)};

  img {
    margin-right: 1.5rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;