import React from 'react';
import { Typography } from 'components/Typography';
import { Container } from './styles';
import { downloadPurple } from 'assets/images/icons';

interface DownloadButtonProps {
  children: React.ReactNode;
}

const DownloadButton = ({ children }: DownloadButtonProps) => {
  return (
    <Container>
      <div className="flex flex-row justify-between">
        <Typography variant="button" color="darkerGray">
          {children}
        </Typography>
        <div>
          <img src={downloadPurple} alt="Ícone de download da cor roxa" />
        </div>
      </div>
    </Container>
  );
};

export { DownloadButton };
