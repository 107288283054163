import theme from 'themes';

type ColorType = keyof typeof theme.colors;

interface StatusColorIndicatorProps {
  color: ColorType;
  mini?: boolean;
}

const StatusColorIndicator: React.FC<StatusColorIndicatorProps> = ({
  color,
  mini = false,
}) => {
  return (
    <div
      className={` rounded-full ${mini ? 'w-2 h-2' : 'w-3 h-3'}`}
      style={{ backgroundColor: theme.colors[color] }}
    ></div>
  );
};

export default StatusColorIndicator;
