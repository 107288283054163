/* eslint-disable @next/next/no-img-element */
import { useCall } from 'queries/call';
import { mapIcon } from 'assets/images/illustrations';
import { Typography } from 'components/Typography';
import {
  ViewChecklistCard,
  StatusTimeline,
  CallLog,
} from 'features/Call/components';
import { callStatus } from 'lib/call';
import { LoadingSidesheet } from 'components/LoadingSidesheet';

interface ChecklistTabProps {
  callId: number;
}

const ChecklistTab = ({ callId }: ChecklistTabProps) => {
  const { call, isLoading } = useCall(callId);

  if (isLoading) {
    return <LoadingSidesheet />;
  }

  const getStatus = (callLog) => {
    if (!callLog) {
      return null;
    }
    const callStatusList = callLog?.map((l) => l?.callStatus);
    if (
      callStatusList.includes('CALL_SUCCESSFULLY_ENDED') ||
      call?.status === 'SUPPORT_CANCELED' ||
      call?.status === 'UNDER_ANALYSIS'
    ) {
      return 'CALL_SUCCESSFULLY_ENDED';
    } else if (
      callStatusList.includes('SUPPORT_STARTED') ||
      call?.status === 'SUPPORT_STARTED'
    ) {
      return 'SUPPORT_STARTED';
    } else if (
      callStatusList.includes('ON_THE_WAY') ||
      call?.status === 'ON_THE_WAY'
    ) {
      return 'ON_THE_WAY';
    } else {
      return null;
    }
  };

  const lastItem = call?.callLog ? call?.callLog[call?.callLog?.length - 1] : []

  return (
    <div className="px-6 py-4 space-y-8">
      <div className="space-y-4">
        <ViewChecklistCard checklist={call?.checklistItems} />
      </div>

      <div className="space-y-4">
        <div className="flex flex-row items-center space-x-4">
          <img src={mapIcon} alt="Imagem ilustrativa de um mapa" />
          <Typography variant="h2" color="darkBlue">
            {callStatus[lastItem?.callStatus]?.displayText}
          </Typography>
        </div>

        <StatusTimeline status={getStatus(call?.callLog)} />
        {call?.callLog?.length > 0 && <CallLog callId={callId} />}
      </div>
    </div>
  );
};

export { ChecklistTab };
