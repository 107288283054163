const adminClientRolePermission = [
    {
        title: 'Acesso a Gestão de:',
        data: [
            {
                title: 'Chamado',
                type: 'available',
            },
            {
                title: 'Financeira',
                type: 'available',
            },
            {
                title: 'Plataforma',
                type: 'available',
            },
        ],
    },
];

const operationClientRolePermission = [
    {
        title: 'Acesso a Gestão de:',
        data: [
            {
                title: 'Chamado',
                type: 'available',
            },
            {
                title: 'Financeira',
                type: 'available',
            },
            {
                title: 'Plataforma',
                type: 'unavailable',
            },
        ],
    },
];

const financialClientRolePermission = [
    {
        title: 'Acesso a Gestão de:',
        data: [
            {
                title: 'Chamado',
                type: 'unavailable',
            },
            {
                title: 'Financeira',
                type: 'available',
            },
            {
                title: 'Plataforma',
                type: 'unavailable',
            },
        ],
    },
];

export {
    adminClientRolePermission,
    financialClientRolePermission,
    operationClientRolePermission,
};
