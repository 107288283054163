/* eslint-disable @typescript-eslint/no-unused-vars */

import { Typography } from 'components/Typography';
import { Field } from 'formik';

const EuNerdReason = ({ callId }) => {
  return (
    <div className="flex flex-col space-y-4">
      <Typography variant="h3" color="darkerGray">
        Resumo da cobrança
      </Typography>
      <div className="flex justify-between w-full p-6 space-x-4 border-2 rounded-xl border-lightGray">
        <div className="flex flex-col w-full">
          <Typography variant="button" color="darkGray">
            Multa por cancelamento
          </Typography>
          <Typography variant="h2" color="darkerGray">
            Não será cobrado
          </Typography>
        </div>
      </div>
      <Typography variant="h3" color="darkestGray">
        Adicione uma descrição para o cancelamento
      </Typography>
      <Field
        name="description"
        as="textarea"
        rows={6}
        className="w-full p-2 text-sm border-2 bg-lighterGray rounded-xl border-lightGray focus:outline-none text-darkerGray focus:bg-white"
      />
    </div>
  );
};

export { EuNerdReason };
