import { useState } from 'react';
import { Tabs } from 'components/Tabs';
import { IconTab } from 'components/Tabs/IconTab';
import CallSidesheetWrapper from './CallSidesheetWrapper';
import ServiceTab from 'components/Calls/ServiceTab';
import AlertsTab from 'components/Calls/Alerts/AlertsTab';
import ChecklistTab from 'components/Calls/Checklist/ChecklistTab';
import {
  activeServiceOrderGray,
  documentGray,
  scheduleSelected,
  scheduleUnselected,
} from 'assets/images/icons';

const CallsMapSideSheet = ({ callId, isOpen, close }) => {
  const [currentTab, setCurrentTab] = useState(0);
  function renderTab() {
    switch (currentTab) {
      case 0: {
        return <ServiceTab close={close} callId={callId} />;
      }
      case 1: {
        return <AlertsTab callId={callId} />;
      }
      case 2: {
        return <ChecklistTab callId={callId} />;
      }
      default: {
        return <ServiceTab close={close} callId={callId} />;
      }
    }
  }
  return (
    <CallSidesheetWrapper isOpen={isOpen} close={close}>
      <div className="flex flex-col w-full max-h-screen">
        <div className="bg-lighterGray w-full">
          <Tabs
            data={[
              {
                title: 'Ordem de serviço',
                iconSelected: activeServiceOrderGray,
                iconUnselected: documentGray,
              },
              {
                title: 'Alertas',
                iconSelected: scheduleSelected,
                iconUnselected: scheduleUnselected,
              },
              {
                title: 'Acompanhamento',
                iconSelected: scheduleSelected,
                iconUnselected: scheduleUnselected,
              },
            ]}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            renderItem={IconTab}
          />
        </div>
        <div className="w-full overflow-y-auto max-h-[90vh]">{renderTab()}</div>
      </div>
    </CallSidesheetWrapper>
  );
};

export default CallsMapSideSheet;
