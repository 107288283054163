import { Avatar } from 'components/Avatar';
import { Typography } from 'components/Typography';
import Spinner from 'components/Spinner';
import { Company } from 'services/company';

interface CompanyIconLabelProps {
  companyId: string;
}

const CompanyIconLabel = ({ companyId }: CompanyIconLabelProps) => {
  const { data } = Company.getById(companyId);

  if (!data) {
    return <Spinner />;
  }

  return (
    <div
      className="flex items-center justify-start gap-2 truncate"
      style={{ maxWidth: '250px' }}
    >
      <Avatar name={data.company.name} photoURL={data.company.avatar} />
      <Typography variant="p1" color="darkerGray" truncate>
        {data.company.fantasyName}
      </Typography>
    </div>
  );
};

export { CompanyIconLabel };
