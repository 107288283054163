import {
  ClientActions,
  FinancialActions,
  PlatformActions,
} from '../modulesActions';
import { ModulePermissionsType } from '../root';

export const FinancialPermissions: ModulePermissionsType = {
  CALL: {
    actions: [],
  },
  TECHNICIAN: {
    actions: [],
  },
  FINANCIAL: {
    actions: [
      FinancialActions.READ,
      FinancialActions.ADD_BALANCE,
      FinancialActions.UPDATE_REGISTRATION_DATA,
    ],
  },
  CLIENT: {
    actions: [
      ClientActions.READ,
      ClientActions.CREATE,
      ClientActions.UPDATE,
      ClientActions.REPORT,
    ],
  },
  PLATFORM: {
    actions: [PlatformActions.READ],
  },
};
