import styled, { css } from 'styled-components';

interface ContainerProps {
  focused: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme, focused }) => ( focused ? theme.colors.purple : theme.colors.lightGray) };
  border-radius: 1rem;
`;

export const InputContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem 0.5rem;

  ${({ focused }) => focused ? css`
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
  ` : css`
    border-bottom: 0;
  `};
`;

export const SearchIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

export const Input = styled.input`
  flex: 1;
  font-size: ${({ theme }) => theme.typography.p1.fontSize};
  font-weight: ${({ theme }) => theme.typography.p1.fontWeight};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const Content = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
  transition: 0.2s;

  ${({ focused }) => focused ? css`
    opacity: 1;
    height: 400px;
    padding: 0.5rem 1rem;
    margin: 0 4px 8px 0;
  ` : css`
    opacity: 0;
    height: 0;
    padding: 0;
  `};

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 3px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.darkGray};
  }
`;