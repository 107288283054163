import { TextField } from 'components/Inputs/TextField';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import type { InfoPersonalType } from 'features/Technician/types';
import { cnpjMask, cpfMask, phoneMask } from 'utils/maskUtils';

interface PersonalDocumentationProps {
  infoPersonal: InfoPersonalType;
}

const PersonalDocumentation = ({
  infoPersonal: { name, birthDate, cnpj, cpf, rg, email, telephone, id },
}: PersonalDocumentationProps) => {
  return (
    <>
      <Typography variant="h3" color="darkBLue">
        Dados Pessoais
      </Typography>
      <div key={id} className="flex flex-col gap-5">
        <div className="flex flex-row space-x-4">
          <div className="w-2/3">
            <TextField
              label="Nome Completo"
              value={name}
              width="100%"
              disabled
              errorMessage={undefined}
              name={undefined}
              placeholder={undefined}
              onChange={undefined}
              height={undefined}
              field={undefined}
              form={undefined}
            />
          </div>
          <div className="w-1/3">
            <TextField
              label="Data de nascimento"
              width="100%"
              value={
                birthDate
                  ? format(new Date(birthDate), 'dd/MM/yyyy')
                  : 'Não informada'
              }
              disabled
              errorMessage={undefined}
              name={undefined}
              placeholder={undefined}
              onChange={undefined}
              height={undefined}
              field={undefined}
              form={undefined}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between w-full">
          <TextField
            disabled
            label="CPF"
            value={cpfMask(cpf)}
            errorMessage={undefined}
            name={undefined}
            placeholder={undefined}
            onChange={undefined}
            width={undefined}
            height={undefined}
            field={undefined}
            form={undefined}
          />
          <TextField
            disabled
            label="CNPJ"
            value={cnpjMask(cnpj)}
            errorMessage={undefined}
            name={undefined}
            placeholder={undefined}
            onChange={undefined}
            width={undefined}
            height={undefined}
            field={undefined}
            form={undefined}
          />
        </div>

        <div className="flex flex-row justify-between w-full">
          <TextField
            disabled
            label="RG"
            value={rg}
            errorMessage={undefined}
            name={undefined}
            placeholder={undefined}
            onChange={undefined}
            width={undefined}
            height={undefined}
            field={undefined}
            form={undefined}
          />
        </div>

        <div className="flex flex-row justify-between w-full">
          <TextField
            disabled
            label="E-mail"
            value={email}
            errorMessage={undefined}
            name={undefined}
            placeholder={undefined}
            onChange={undefined}
            width={undefined}
            height={undefined}
            field={undefined}
            form={undefined}
          />
          <TextField
            disabled
            label="Telefone"
            value={phoneMask(telephone)}
            errorMessage={undefined}
            name={undefined}
            placeholder={undefined}
            onChange={undefined}
            width={undefined}
            height={undefined}
            field={undefined}
            form={undefined}
          />
        </div>
      </div>
    </>
  );
};

export { PersonalDocumentation };
