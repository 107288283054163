import React, { useCallback } from 'react';
import { Typography } from 'components/Typography';

import { Container, Subtitle } from './styles';
import { noImage } from 'assets/images/illustrations';

export interface ReviewFileData {
  image: string;
  subtitle: string;
}

interface ReviewFileItemProps {
  file: ReviewFileData;
}

export const ReviewFileItem: React.FC<ReviewFileItemProps> = ({ file }) => {
  const openImage = useCallback(() => {
    if (file.image) {
      window.open(file.image, '_blank');
    }
  }, []);

  return (
    <Container>
      <img
        src={file.image || noImage}
        alt={file.subtitle}
        onClick={() => openImage()}
      />
      <Subtitle>
        <Typography variant="button" color="darkerGray">
          {file.subtitle || 'Sem legenda'}
        </Typography>
      </Subtitle>
    </Container>
  );
};
