import {
  Container,
  InfoContent,
  Divider,
  Timeline,
  DaysTagContainer,
  DayTag,
} from './styles';
import { Typography } from 'components/Typography';
import { groupByMonthCalendar } from 'utils/queryUtils';
import { months } from 'utils/dateUtils';
import { time } from 'assets/images/icons';
interface WhenCallSummaryProps {
  days: Date[];
  month?: string;
  timeDiff: string;
  formsOfService: string[];
}

const WhenCallSummary = ({
  days,
  timeDiff,
  formsOfService,
}: WhenCallSummaryProps) => {
  const dataArr = groupByMonthCalendar(days).filter((n) => n) || [];
  return (
    <Container>
      <Timeline>
        <img src={time} alt="icon time" />
        <Divider />
      </Timeline>
      <InfoContent>
        <div>
          <Typography variant="h3" color="darkestGray">
            {formsOfService.includes('PER_CALL')
              ? `Chamado por ${days.length} ${
                  days.length > 1 ? 'diárias' : 'diária'
                }`
              : 'Chamado por hora'}
          </Typography>
          <Typography variant="p1" color="darkestGray">
            {timeDiff}
          </Typography>
        </div>
        {dataArr?.map((dataTmp, idx) => (
          <div key={idx}>
            <Typography variant="p1" color="darkerGray">
              {months[dataTmp[0].getMonth()]}
            </Typography>
            <DaysTagContainer>
              {dataTmp.map((day) => (
                <DayTag key={day.getUTCDate()}>
                  <Typography variant="button" color="lighterGray">
                    {day.getDate()}
                  </Typography>
                </DayTag>
              ))}
            </DaysTagContainer>
          </div>
        ))}
      </InfoContent>
    </Container>
  );
};

export { WhenCallSummary };
