import { SelectOption } from 'components/SelectOption';
import { Typography } from 'components/Typography';

const FinishAlertCallForm = ({
  finishReason,
  setFinishReason,
  companyName,
}) => {
  return (
    <>
      <Typography variant="h3" color="darkestGray">
        Adicionar revisão de chamado
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Como o chamado foi marcado como improdutivo, é preciso adicionar uma
        revisão para o status final.
        <br />
        <br />
        Com base nas justificativas apresentadas pela empresa e profissional,
        adicione o veredito para o chamado:
      </Typography>
      <SelectOption
        value="success"
        title="Finalizar como Chamado com Sucesso"
        description={`Selecione esta opção caso o chamado tenha sido finalizado em conformidade com a Ordem de Serviço. O valor total do chamado será faturado para ${companyName}, e o profissional responsável receberá normalmente.`}
        onSelect={() => {
          setFinishReason('success');
        }}
        checked={finishReason === 'success'}
      />
      <SelectOption
        value="unproductive_company"
        title="Chamado improdutivo, erro do Cliente"
        description={`Selecione esta opção caso o cliente seja o responsável pela não finalização deste chamado. O valor total do chamado será faturado para ${companyName}.`}
        onSelect={() => {
          setFinishReason('unproductive_company');
        }}
        checked={finishReason === 'unproductive_company'}
      />
      <SelectOption
        value="unproductive_professional"
        title="Chamado improdutivo, erro do Profissional"
        description="Selecione esta opção caso o cliente seja o responsável pela não finalização deste chamado. O valor total do chamado não será cobrado do cliente."
        onSelect={() => {
          setFinishReason('unproductive_professional');
        }}
        checked={finishReason === 'unproductive_professional'}
      />
    </>
  );
};

export { FinishAlertCallForm };
