import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { OpenConfirmationPayload } from 'ducks/ui/types';

export interface ConfirmCancelModalProps extends OpenConfirmationPayload {
  close: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({
  close,
  onConfirm,
  title,
  description,
  confirmText,
  cancelText,
  danger,
  cancelRed,
  icon = null,
}: ConfirmCancelModalProps) => {
  function handleConfirm() {
    onConfirm();
    close();
  }
  return (
    <div className="flex flex-col items-center w-full text-center">
      {icon}
      <Typography variant="h2" color="darkBlue" className="mb-8">
        {title}
      </Typography>
      <Typography variant="h4" color="darkBlue" className="mb-8">
        {description}
      </Typography>
      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color={cancelRed ? 'orange' : 'gray'}
          width="200"
          size="large"
          onClick={close}
        >
          {cancelText || 'Cancelar'}
        </Button>
        <Button
          variant="outlined"
          color={danger ? 'orange' : 'purple'}
          width="200"
          size="large"
          onClick={handleConfirm}
        >
          {confirmText || 'Confirmar'}
        </Button>
      </div>
    </div>
  );
};

export { ConfirmationModal };
