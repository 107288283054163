import clsx from 'clsx';

interface ProgressBarProps {
  progress: number;
  size?: 'large' | 'small';
  showPercentage?: boolean;
}

const ProgressBar = ({
  progress,
  size = 'large',
  showPercentage = false,
}: ProgressBarProps) => {
  const percentage =
    progress <= 1
      ? progress * 100 + '%'
      : progress > 100
      ? 100 + '%'
      : progress + '%';

  const width = progress < 0 ? '0%' : progress > 100 ? '100%' : progress + '%';
  const color = progress < 50 ? 'text-darkerGray' : 'text-white';

  return (
    <div className="w-full">
      <div
        className={clsx('w-full overflow-hidden  bg-gray relative', {
          'h-10 rounded-xl': size == 'large',
          'h-6 rounded': size == 'small',
        })}
      >
        <div
          className={clsx(
            `flex items-center justify-end h-full px-2 text-white ${
              progress <= 0 ? 'bg-gray' : 'bg-purple'
            }`,
            {
              'rounded-xl': size == 'large',
              rounded: size == 'small',
            }
          )}
          style={{
            width,
          }}
          data-testid="progress"
        >
          {showPercentage && (
            <p
              className={`text-xs font-bold ${color} absolute -left-[-50%] translate-x-[-50%]`}
            >
              {percentage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export { ProgressBar };
