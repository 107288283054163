import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { useUI } from 'hooks/useUI';
import { Technicians } from 'services/technician';
import { ConfirmCnpjModalProps } from '../types';
import { confirm } from 'assets/images/illustrations';

const ConfirmCnpjModal = ({ cnpj, id, close }: ConfirmCnpjModalProps) => {
  const UI = useUI();

  const handleSubmit = (e) => {
    e?.preventDefault();
    Technicians.update({ cnpj }, id)
      .then((res) => {
        if (res.status === 200) {
          close();
          UI.operations.showToast('CNPJ atualizado com sucesso.');
        }
      })
      .catch((error) => {
        UI.operations.showToast('Erro ao atualizar CNPJ.', {
          type: 'error',
          message: error.response?.data?.error?.message,
        });
      });
  };

  return (
    <div className="flex flex-col items-center w-full text-center">
      <img src={confirm} className="h-[160px] w-[160px] mb-10" />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Confirmar cadastro de CNPJ
      </Typography>
      <Typography variant="h4" color="darkBlue" className="">
        Você está confirmando o cadastro de CNPJ de um técnico.
        <br /> Após confirmação, ela estará disponível para ser aprovado na
        plataforma.
        <br />
      </Typography>
      <Typography variant="h3" color="darkBlue" className="mb-6">
        {cnpj}
      </Typography>
      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={close}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={(e) => handleSubmit(e)}
        >
          Confirmar
        </Button>
      </div>
    </div>
  );
};

export { ConfirmCnpjModal };
