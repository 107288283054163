import styled from 'styled-components';
import theme from 'themes';
import { Tooltip as MaterialTooltip, withStyles } from '@material-ui/core';

export const Tooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: theme.colors.darkestGray,
    color: theme.colors.lighterGray,
    boxShadow: theme.colors.darkerGray,
    font: theme.typography.tag,
    width: 99,
    height: 35,
    borderRadius: 4,
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    left: 2,
  },
  arrow: {
    color: theme.colors.darkestGray,
  },
}))(MaterialTooltip);

export const InformationContainer = styled.div`
  font: ${({ theme }) => theme.typography.overline};
  color: ${({ theme }) => theme.colors.lighterGray};
  width: 99px;
  height: 40px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const Divisor = styled.div`
  width: 2px;
  height: 16px;
  background: ${({ theme }) => theme.colors.darkGray};
  border-radius: 1px;
`;

export const Image = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;
