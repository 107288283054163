import { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useTechnicianStatus } from 'queries/technician';
import { SearchInput } from 'components/Inputs/SearchInput';
import { Typography } from 'components/Typography';
import { BigNumberContainer } from 'components/BigNumberContainer';
import { TechniciansScreeningTable } from '../TechniciansScreeningTable';

const RefuseTechnician = () => {
  const initialFilters = {
    'status[]': ['REFUSED'],
    'services[]': [],
    'regions[]': [],
    'technicians[]': [],
  };

  const [filters, setFilters] = useState(initialFilters);
  const { technicians, isError, isLoading } = useTechnicianStatus({
    filters: filters,
  });
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState({
    techniciansRefusedTotal: false,
  });

  return (
    <div className="flex flex-col">
      <Typography className="mb-4" variant="tag" color="darkBlue">
        Filtros
      </Typography>
      <div className="flex gap-2 mb-6">
        <div className="w-1/4">
          <SearchInput setSearchText={setSearchText} />
        </div>
      </div>
      <div className="flex gap-2 mb-6">
        <BigNumberContainer
          color="orange"
          label="Recusados"
          isHighlighted={technicians?.techniciansRefusedTotal}
          selected={selected.techniciansRefusedTotal}
          isError={isError}
          isLoading={isLoading}
          onClick={() => {
            setFilters({
              ...filters,
              'status[]': isEqual(filters['status[]'], ['REFUSED'])
                ? initialFilters['status[]']
                : ['REFUSED'],
            });
            setSelected({
              techniciansRefusedTotal: !selected.techniciansRefusedTotal,
            });
          }}
        />
      </div>
      <TechniciansScreeningTable
        searchText={searchText}
        techniciansData={technicians}
        isError={isError}
        isLoading={isLoading}
      />
    </div>
  );
};

export { RefuseTechnician };
