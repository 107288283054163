import { statusCallDetails } from 'lib/call';
import { useTechnicianCalls } from 'queries/technician';
import { TimelineSchedule } from '../../TimelineSchedule';
import { FilterSheduleProps } from '../SheduleFinishedCalls';

const ShedulePendingCalls = ({
  idTechnician,
  selectedMonthNumber,
}: FilterSheduleProps) => {
  const technicianCalls = useTechnicianCalls(idTechnician, {
    status: [
      statusCallDetails.PENDING_COMPLETION.value,
      statusCallDetails.UNDER_ANALYSIS.value,
    ],
    month: selectedMonthNumber,
  });

  return <TimelineSchedule technicianCalls={technicianCalls} />;
};

export { ShedulePendingCalls };
