import { useCallback, useState } from 'react';
import { months, WEEKDAYS_SHORT_CALENDAR } from 'utils/dateUtils';
import { Container } from './styles';
import { CalendarNavbar } from './CalendarNavbar';
import DayPicker, { DayModifiers, DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

interface CalendarProps {
  onChange(days: Date[]): void;
  disableBefore?: boolean;
  defaultSelectedDays?: Date[];
}

const Calendar = ({
  onChange,
  disableBefore = false,
  defaultSelectedDays = [],
}: CalendarProps) => {
  const [selectedDays, setSelectedDays] = useState<Date[]>(defaultSelectedDays);
  const onSelectDay = useCallback(
    (day: Date, { selected }: DayModifiers) => {
      if (disableBefore && day < new Date(new Date().setHours(0))) {
        return;
      }
      const daysSelected = selectedDays.concat();

      if (selected) {
        const selectedIndex = daysSelected.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        daysSelected.splice(selectedIndex, 1);
      } else {
        daysSelected.push(day);
      }
      setSelectedDays(daysSelected);
      onChange(daysSelected);
    },
    [selectedDays, onChange]
  );

  return (
    <Container data-testid="container-calendar">
      <DayPicker
        data-testid=""
        weekdaysShort={WEEKDAYS_SHORT_CALENDAR}
        months={months}
        fixedWeeks
        disabledDays={
          disableBefore
            ? {
                before: new Date(),
              }
            : null
        }
        onDayClick={onSelectDay}
        selectedDays={selectedDays}
        navbarElement={(props) => <CalendarNavbar {...props} />}
      />
    </Container>
  );
};

export { Calendar };
