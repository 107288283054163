import React from 'react';
import { ServiceItem } from 'components/ServicesSearchBarAutocomplete';
import { Typography } from 'components/Typography';
import { Container, Checkbox, Content } from './styles';
import { checkedPurple } from 'assets/images/icons';

interface ServiceItemProps {
  item: ServiceItem;
  selected: boolean;
  handleSelect(id: string): void;
}

const ServiceSearchItem = ({
  item,
  selected,
  handleSelect,
}: ServiceItemProps) => {
  return (
    <Container onClick={() => handleSelect(item.id)} role="button">
      <Checkbox selected={selected}>
        {selected && (
          <img src={checkedPurple} alt="Ícone de seleção da cor roxa" />
        )}
      </Checkbox>
      <Content>
        <Typography variant="button" color="darkerGray">
          {item.name}
        </Typography>
        <Typography variant="overline" color="darkGray">
          {item.description}
        </Typography>
      </Content>
    </Container>
  );
};

export { ServiceSearchItem };
