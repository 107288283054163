import clsx from 'clsx';
import { Typography } from 'components/Typography';

type checklistItemsTypes = {
  id: string;
  description: string;
  checked: boolean;
  checkedAt?: string;
  createdAt?: string;
};
interface ViewChecklistCardProps {
  checklist: checklistItemsTypes[];
}

const ViewChecklistCard = ({ checklist }: ViewChecklistCardProps) => {
  return (
    <>
      <Typography variant="h2" color="darkestGray">
        Checklist do Chamado
      </Typography>
      <div className="p-6 border-2 border-lightGray rounded-xl">
        {checklist?.map((c, idx) => {
          return (
            <div className="flex flex-col" key={c?.id}>
              <label className="inline-flex items-start space-x-4">
                <div className="flex flex-col h-full">
                  <input
                    disabled
                    type="checkbox"
                    className={clsx(
                      'w-5 h-5 text-purpleHover bg-center rounded-md bg-contain form-checkbox cursor-not-allowed ',
                      {
                        'border-lightGray rounded-md border-2 bg-lightGray':
                          !c?.checked,
                      }
                    )}
                    defaultChecked={c?.checked || false}
                  />

                  {idx !== checklist.length - 1 && (
                    <div className="h-[2rem] m-2">
                      <div className="w-[2px] h-full bg-lightGray self-center" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <Typography variant="button" color="darkBlue">
                    {c?.description}
                  </Typography>
                  <div className="w-full h-[2px] bg-lightGray mt-2" />
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

export { ViewChecklistCard };
