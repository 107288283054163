import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import {
  table as StyledTable,
  thead as StyledTableHead,
  th as StyledTh,
  td as StyledTd,
  tr as StyledTr,
} from './styles';
import { useTable } from 'react-table';
import { useUI } from 'hooks/useUI';
import { SidesheetTypes } from 'ducks/ui/types';
import { IClientsData } from 'pages/clients';
import { v4 } from 'uuid';

interface TableProps {
  data: IClientsData[];
}

const TableTh = ({ children }) => {
  return (
    <StyledTh scope="col">
      <Typography variant="p2" color="darkerGray">
        {children}
      </Typography>
    </StyledTh>
  );
};

export const Table = ({
  columns,
  data,
  onRowClick = (data: TableProps) => {
    return data;
  },
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      data,
      columns,
    });

  const [selectedRow, setSelectedRow] = useState(null);
  const UI = useUI();

  return (
    <div className="border-2 border-[#f2f2f2] rounded-xl overflow-x-auto">
      <StyledTable {...getTableProps()}>
        <StyledTableHead>
          {headerGroups.map((headerGroup) => (
            <tr key={v4()} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableTh key={v4()} scope="col" {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableTh>
              ))}
            </tr>
          ))}
        </StyledTableHead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <StyledTr
                key={v4()}
                {...row.getRowProps()}
                onClick={() => {
                  if (row.values?.status?.value === 'PENDING_COMPLETION')
                    return UI.operations.sidesheet.openSidesheet({
                      type: SidesheetTypes.SidesheetFinishedCalls,
                      sidesheetProps: {
                        id: row.values.id,
                        width: 'wide',
                        statusCall: row.values.status.value,
                      },
                    });
                  setSelectedRow(row.id), onRowClick(row.original);
                }}
                isActive={selectedRow === row.id}
              >
                {row.cells.map((cell) => {
                  return (
                    <StyledTd key={v4()} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </StyledTd>
                  );
                })}
              </StyledTr>
            );
          })}
        </tbody>
      </StyledTable>
    </div>
  );
};
