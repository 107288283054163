/* eslint-disable @typescript-eslint/no-unused-vars */
import { sidesheetTypes } from './types';
import { useDispatch } from 'react-redux';
import { uiSlice } from 'ducks/ui';
import { useTypedSelector } from 'hooks/useTypedSelector';
import SidesheetBase from '.';
import { useUI } from 'hooks/useUI';

const SidesheetRoot: React.FC<{ level: number }> = ({ children, level }) => {
  const { sidesheetIsOpen, modalIsOpen, componentList } = useTypedSelector(
    (state) => state.ui
  );
  const dispatch = useDispatch();
  const UI = useUI();

  const currentSidesheet = componentList[level];

  const SidesheetComponent = sidesheetTypes[
    currentSidesheet?.type
  ] as React.ComponentType;

  return (
    <SidesheetBase
      onAfterClose={currentSidesheet?.sidesheetProps?.onAfterClose}
      isOpen={currentSidesheet?.open}
      close={(bypass) => {
        if (currentSidesheet.sidesheetProps?.closeConfirmation && !bypass) {
          UI.operations.confirm(
            currentSidesheet.sidesheetProps.closeConfirmation.title,
            currentSidesheet.sidesheetProps.closeConfirmation.description,
            () => {
              setTimeout(UI.operations.popComponentList, 500);
              dispatch(uiSlice.actions.closeUI(true));
            },
            {
              danger: true,
              confirmText:
                currentSidesheet.sidesheetProps.closeConfirmation.confirmText,
              cancelText:
                currentSidesheet.sidesheetProps.closeConfirmation.cancelText,
              icon: currentSidesheet.sidesheetProps.closeConfirmation.icon,
            }
          );
        } else {
          setTimeout(UI.operations.popComponentList, 500);
          dispatch(uiSlice.actions.closeUI());
        }
      }}
      width={currentSidesheet?.sidesheetProps?.width}
    >
      {SidesheetComponent ? (
        <>
          <SidesheetComponent
            {...currentSidesheet.sidesheetProps}
            close={(bypass) => {
              if (
                currentSidesheet.sidesheetProps?.closeConfirmation &&
                !bypass
              ) {
                UI.operations.confirm(
                  currentSidesheet.sidesheetProps.closeConfirmation.title,
                  currentSidesheet.sidesheetProps.closeConfirmation.description,
                  () => {
                    setTimeout(UI.operations.popComponentList, 500);
                    dispatch(uiSlice.actions.closeUI(true));
                  },
                  {
                    danger: true,
                    confirmText:
                      currentSidesheet.sidesheetProps.closeConfirmation
                        .confirmText,
                    cancelText:
                      currentSidesheet.sidesheetProps.closeConfirmation
                        .cancelText,
                    icon: currentSidesheet.sidesheetProps.closeConfirmation
                      .icon,
                  }
                );
              } else {
                setTimeout(UI.operations.popComponentList, 500);
                dispatch(uiSlice.actions.closeUI());
              }
            }}
          />
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </SidesheetBase>
  );
};

export { SidesheetRoot };
