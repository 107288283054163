import clsx from 'clsx';

interface BooleanSelectButtonProps {
  value: unknown;
  onChange: (e) => void;
}

const BooleanSelectButton = ({
  value = null,
  onChange,
}: BooleanSelectButtonProps) => {
  return (
    <div className="flex items-center w-full space-x-4">
      <div className="w-1/2">
        <button
          type="button"
          onClick={() => onChange(true)}
          className={clsx(
            'w-full rounded-xl font-bold p-3 focus:outline-none',
            {
              'border-2 border-gray text-darkestGray': value !== true,
              'border-2 border-purple bg-purple text-white ': value === true,
            }
          )}
        >
          Sim
        </button>
      </div>
      <div className="w-1/2">
        <button
          type="button"
          onClick={() => onChange(false)}
          className={clsx(
            'w-full rounded-xl font-bold p-3 focus:outline-none',
            {
              'border-2 border-gray text-darkestGray': value !== false,
              'border-2 border-purple bg-purple text-white ': value === false,
            }
          )}
        >
          Não
        </button>
      </div>
    </div>
  );
};

export { BooleanSelectButton };
