import { Typography } from 'components/Typography';
import { Container } from './styles';
import { closeLabel } from 'assets/images/icons';
import { ReactNode } from 'react';

interface LabelProps {
  children: ReactNode;
  onClose: () => void;
}

const Label = ({ children, onClose }: LabelProps) => {
  return (
    <Container>
      <div className="flex flex-row">
        <button onClick={onClose}>
          <img src={closeLabel} alt='close'/>
        </button>
        <Typography variant="p3" color="purple">
          {children}
        </Typography>
      </div>
    </Container>
  );
};

export { Label };
