import { Typography } from 'components/Typography';
import { address as addressIcon } from 'assets/images/icons';
import type { AddressType } from 'features/Technician/types';

interface AddressInfoProps {
  address: AddressType;
}

const AddressInfo = ({ address }: AddressInfoProps) => {
  return (
    <div className="space-y-4">
      <Typography variant="h3" color="darkBLue">
        Endereço Residencial
      </Typography>

      <div className="flex flex-row items-start space-x-2">
        <img alt="Icon" src={addressIcon} />
        <div className="flex flex-col">
          <Typography variant="p1" color="darkerGray" className="max-w-[239px]">
            {address?.address} {address?.number}
            {', '}{' '}
            {address?.complement !== null ? address?.complement + ' - ' : ''}
            {address?.district}
          </Typography>
          <Typography
            variant="p1"
            color="darkerGray"
            className="max-w-[239px]"
          ></Typography>
          <Typography variant="p1" color="darkerGray" className="max-w-[239px]">
            {address?.cep} {', '}
            {address?.city}
            {' - '} {address?.state}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { AddressInfo };
