/* eslint-disable @next/next/no-img-element */
import { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { yupValidations } from 'utils/validations/yup';
import { TemplateCallService } from 'services/templateCall';
import { useUI } from 'hooks/useUI';
import { ModalTypes } from 'ducks/ui/types';
import { Call } from 'services/call';
import { Typography } from 'components/Typography';
import { Button } from 'components/Button';
import uploadAttachments from 'services/attachments';
import useUser from 'hooks/useUser';
import { confirm, newcallbg } from 'assets/images/illustrations';
import {
  AboutCall,
  ClientInformation,
  LinkProfessional,
  ServiceDetail,
  SummaryNewCall,
} from 'features/Call/components';
import { add } from 'date-fns';
import { useCompany } from 'queries/clients';
import { NeedParts } from 'features/Call/components/NeedParts';
export interface SidesheetAddNewCallProps {
  id?: string;
  close(arg: boolean): void;
  onAfterSave(): Promise<void>;
}

const SidesheetAddNewCall = ({
  close,
  onAfterSave,
}: SidesheetAddNewCallProps) => {
  const user = useUser();
  const UI = useUI();
  const queryClient = useQueryClient();
  const [loadingButton, setLoadingButton] = useState(false);
  const { company } = useCompany(user.companyId);

  const trainingsExist = company?.trainings && company.trainings.length > 0;

  const initialValues = {
    clientType: 'Company',
    companyId: user.type !== 'EuNerd' ? user.companyId : null,
    companyName: user.type !== 'EuNerd' ? user.companyName : null,

    responsibleEmail: '',
    responsibleName: '',
    responsibleTelephone: '',

    address: {
      cep: '',
      city: '',
      state: '',
      address: '',
      number: '',
      complement: '',
      district: '',
    },
    registeredAddress: null,

    attachments: [],
    checklist: [],
    serviceDetail: '',

    serviceId: '',
    serviceType: '',
    type: '',

    technicianId: [],
    technicianType: 'EuNerd',
    searchType: user.type !== 'EuNerd' ? 'Automatic' : null,

    startTime: [],
    time: null,
    necessaryProfessionals: 1,
    equipment: [],
    covid19: [],
    epi: [],
    other: [],
    needsParts: false,
    partsDescription: '',
  };

  const schemaValidation = Yup.object().shape({
    clientType: yupValidations.requiredString,
    companyId: yupValidations.requiredString,
    companyName: yupValidations.requiredString,

    responsibleEmail: yupValidations.requiredString,
    responsibleName: yupValidations.requiredString,
    responsibleTelephone: yupValidations.requiredString,

    address: Yup.object().shape({
      cep: Yup.string().required('Cep inválido'),
      city: yupValidations.requiredString,
      state: yupValidations.requiredString,
      address: yupValidations.requiredString,
      number: yupValidations.requiredString,
      district: yupValidations.requiredString,
      complement: yupValidations.notRequiredString,
    }),
    registeredAddress: Yup.boolean().required('Campo obrigatório'),

    attachments: Yup.array().nullable(),
    checklist: Yup.array(
      Yup.object()
        .shape({
          id: Yup.number().required('Campo obrigatório'),
          value: Yup.string().required('Campo obrigatório'),
        })
        .required('Campo obrigatório')
    ),
    serviceDetail: yupValidations.requiredString,

    serviceId: yupValidations.requiredString,
    serviceType: yupValidations.requiredString,
    type: yupValidations.requiredString,

    technicianType: yupValidations.requiredString,
    searchType: yupValidations.requiredString,
    startTime: Yup.array(Yup.string().required())
      .required('Campo obrigatório')
      .min(1),
    time: Yup.object()
      .shape({
        firstTime: Yup.number().required(),
        firstTimeFormatted: Yup.string().required(),
        secondTime: Yup.number().required(),
        secondTimeFormatted: Yup.string().required(),
      })
      .required('Campo obrigatório'),
    needsParts: Yup.boolean(),
    partsDescription: Yup.string().when('needsParts', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
    }),
  });

  const onSubmit = useCallback(
    async (values) => {
      setLoadingButton(true);
      const filesToUpload = values.attachments?.filter(
        (attachment) => !attachment.callTemplateId
      );
      const filesTemplate = values.attachments?.filter(
        (attachment) => attachment.callTemplateId
      );

      const attachments = await uploadAttachments(
        filesToUpload?.map((file) => file.file)
      );

      filesTemplate?.forEach((filesTemplate) => {
        attachments.push({
          name: filesTemplate?.name,
          url: filesTemplate?.url,
        });
      });

      const startTime = values?.startTime?.map((s: string | number | Date) => {
        s = new Date(s).setUTCHours(values?.time?.firstTime);
        const dateAddMinutes = add(s, { minutes: values?.time?.secondTime });
        return new Date(dateAddMinutes)?.toISOString();
      });

      if (user.role !== 'Eunerd' && trainingsExist) {
        const trainingIds = company?.trainings.map((t) => t.training?.id);
        values.trainingsId = trainingIds;
      }

      try {
        const checklist = values.checklist.map((i) => i.value);

        await Call.create({
          ...values,
          address: {
            ...values.address,
          },
          startTime,
          necessaryProfessionals: Number(values.necessaryProfessionals),
          attachments,
          checklist,
        });
        await queryClient.refetchQueries('calls');
        setLoadingButton(false);
        UI.operations.showToast('Chamado criado com sucesso');
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao criar o chamado';

        if (err.response?.status === 400) {
          erroMessage = err.response.data.message;
        }
        setLoadingButton(false);

        UI.operations.showToast(erroMessage, {
          type: 'error',
        });
        throw err;
      }
    },
    [UI.operations, company?.trainings, queryClient, trainingsExist, user.role]
  );

  const createTemplateCall = useCallback(
    async (data) => {
      const attachments = await uploadAttachments(
        data.attachments?.map((file) => file.file)
      );

      const checklist = data.checklist.map((i) => i.value);

      const dataCreateTemplate = {
        name: data.name,
        type: data.type,
        companyId: data.companyId,
        serviceId: data.serviceId,
        serviceType: data.serviceType,
        necessaryProfessionals: data.necessaryProfessionals,
        serviceDetail: data.serviceDetail,
        attachments: attachments,
        checklist,
      };

      try {
        await TemplateCallService.create(dataCreateTemplate);
        UI.operations.showToast('Template de chamado criado com sucesso');
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao criar o template de chamado';

        if (err.response?.status === 400) {
          erroMessage = err.response.data.message;
        }
        UI.operations.showToast(erroMessage, {
          type: 'error',
        });
      } finally {
        close(true);
      }
    },
    [close, UI]
  );

  const handleModalConfirmCreateTemplate = useCallback(
    async (dataCall) => {
      UI.operations.modal.openModal({
        type: ModalTypes.ConfirmCreateCallTemplate,
        modalProps: {
          onConfirm: async (values) => {
            await createTemplateCall({
              name: values?.name,
              ...dataCall,
            });
          },
          onAfterClose: () => {
            close(true);
          },
        },
      });
    },
    [UI, close, createTemplateCall]
  );

  const handleSubmit = useCallback(
    async (values) => {
      UI.operations.modal.openModal({
        type: ModalTypes.ModalGenericConfirmation,
        modalProps: {
          icon: <img src={confirm} alt="ícone de confirmação" />,
          title: ' Confirmar novo chamado',
          description: (
            <>
              Você está confirmando a abertura de um novo chamado.
              <br />
              Após confirmação, não será possível editar as informações deste
              chamado.
            </>
          ),
          cancelText: 'Cancelar',
          cancelRed: true,
          confirmText: 'Confirmar',
          onConfirm: async () => {
            try {
              await onSubmit(values);
              await handleModalConfirmCreateTemplate(values);
              await onAfterSave();
            } catch (err) {
              return err;
            }
          },
        },
      });
    },
    [onAfterSave, handleModalConfirmCreateTemplate, UI, onSubmit]
  );

  const verifyButtonEnable = useCallback(
    (
      isValid: boolean,
      isSubmitting: boolean,
      searchType: string,
      technicianId: string[]
    ) => {
      const condition = !isValid || isSubmitting;

      if (searchType === 'Manual') {
        return condition || technicianId.length === 0;
      }
      return condition;
    },
    []
  );

  return (
    <>
      <div className="sticky bg-white">
        <img src={newcallbg} alt="" className="absolute w-full h-auto" />
        <div className="relative p-6">
          <Typography variant="h1" color="darkBLue">
            Adicionar novo chamado
          </Typography>
        </div>
      </div>

      <div className="flex p-6 mt-2">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schemaValidation}
          style={{ minWidth: '70rem', maxWidth: '87.5rem' }}
        >
          {({ isValid, isSubmitting, values }) => (
            <Form className="flex flex-row space-x-6">
              <div className="flex flex-col w-[70%] space-y-4">
                <ClientInformation />
                <ServiceDetail />
                <AboutCall />
                {user.type === 'EuNerd' && <LinkProfessional />}
                <NeedParts />
                <div className="flex items-center justify-end">
                  <Button
                    disabled={verifyButtonEnable(
                      isValid,
                      isSubmitting,
                      values.searchType,
                      values.technicianId
                    )}
                    loading={loadingButton}
                    type="submit"
                    className="w-44"
                  >
                    Concluir
                  </Button>
                </div>
              </div>
              <div className="w-[30%]">
                <SummaryNewCall />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { SidesheetAddNewCall };
