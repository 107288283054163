import { Typography } from 'components/Typography';
import { inactiveInfo } from 'assets/images/icons';

interface CallValueProps {
  valueCall: string;
}

const CallValue = ({ valueCall }: CallValueProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex flex-row items-center gap-2">
        <Typography variant="p1" color="darkerGray">
          Valor
        </Typography>
        <img
          src={inactiveInfo}
          title="Valor total do chamado + custo de deslocamento"
          className="cursor-help"
        />
      </div>
      <div className="px-6 py-5 rounded-lg bg-lightGray w-80">
        <Typography variant="button" color="darkestGray">
          {valueCall}
        </Typography>
      </div>
    </div>
  );
};

export { CallValue };
