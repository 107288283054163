import { Typography } from 'components/Typography';
import { ReactNode } from 'react';

interface ContentCardProps {
  title: string;
  icon: string;
  renderAction?: ReactNode;
  children: React.ReactNode;
}

const ContentCard = ({
  title,
  children,
  icon,
  renderAction,
}: ContentCardProps) => {
  return (
    <div className="flex flex-col w-full border-2 border-gray rounded-xl">
      <div className="flex items-center w-full p-4 space-x-4 border-b-2 border-gray">
        <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-full bg-opacity-10 bg-purple text-purpleClick">
          <img src={icon} alt="Ícone customizado" />
        </div>
        <Typography variant="h2" color="darkBlue">
          {title}
        </Typography>
        {renderAction}
      </div>
      <div className="w-full p-4">{children}</div>
    </div>
  );
};

export { ContentCard };
