import styled from 'styled-components';

export const Icon = styled.img``;

interface StyledCheckboxProps {
  checked: boolean;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${({ checked }) =>
    checked ? 'rgba(128, 107, 255, 0.1)' : '#ffffff'};
  border-radius: 3px;
  transition: all 150ms;
  border: 2px solid;
  border-color: ${({ checked }) => (checked ? '#806BFF' : '#DCDCDC')};

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
