import { inactiveAlert } from 'assets/images/icons';
import { Typography } from 'components/Typography';

const LinkProfessionalAutomatic = () => {
  return (
    <div className="w-2/3 flex bg-beigeClick rounded-lg p-4 gap-2">
      <img src={inactiveAlert} alt="Ícone exclamação" />
      <Typography variant="h3">
        Após concluir abertura do chamado, a plataforma fará uma busca
        inteligente na base de profissionais Eunerd para encontrar a melhor
        opção para este atendimento.
      </Typography>
    </div>
  );
};

export { LinkProfessionalAutomatic };
