import {
  alertWhite,
  checked,
  crossed,
  inactiveInfo,
} from 'assets/images/icons';

const getIcon = (type) => {
  const iconVariant = {
    danger: alertWhite,
    success: checked,
    info: crossed,
    warning: inactiveInfo,
  };

  if (!iconVariant[type]) {
    return alertWhite;
  }

  return iconVariant[type];
};

export { getIcon };
