import { Typography } from 'components/Typography';
import { ServiceItem } from '../ServiceItem';

const ServiceCertificate = ({ services }) => {
  return (
    <div className="space-y-6">
      <Typography variant="h3" color="darkestGray">
        Capacitações profissionais
      </Typography>
      <div className="flex flex-col space-y-6">
        {services?.map((s, i) => (
          <ServiceItem service={s.service} key={i} />
        ))}
      </div>
    </div>
  );
};

export { ServiceCertificate };
