/* eslint-disable */

import { SidesheetHeader } from 'components/SidesheetHeader';
import { Typography } from 'components/Typography';
import { Button } from 'components/Button';
import { Alert } from 'components/AlertComponents/Alert';
import BonusCard from './components/BonusCard';
import RatingBonusForm from './components/forms/RatingBonusForm';
import CallsBonusForm from './components/forms/CallsBonusForm';
import { ModalTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Financial } from 'services/financial';
import { useRewards } from 'queries/financial';
import {
  callBonus,
  confirmRewards,
  ratingBonus,
} from 'assets/images/illustrations';

interface MyFormValues {
  ratingRewardAverageRatingNeeded: number;
  ratingRewardBonus: number;
  callRewardBonus: number;
  callRewardNumberOfCalls: number;
  referReward: number;
}

export const ManageBonusSidesheet = ({ close }) => {
  const UI = useUI();
  const rewards = useRewards();

  const formik = useFormik<MyFormValues>({
    validationSchema: yup.object().shape({
      ratingRewardAverageRatingNeeded: yup
        .number()
        .max(5, 'Deve ser menor ou igual a 5'),
      ratingRewardBonus: yup.number().max(100, 'Não pode ser maior que 100%'),
      callRewardNumberOfCalls: yup.number().min(1, 'Deve ser maior que 0'),
      callRewardBonus: yup.number().max(100, 'Não pode ser maior que 100%'),
      referReward: yup.number(),
    }),

    initialValues: {
      ratingRewardAverageRatingNeeded:
        rewards.rewards?.ratingRewardAverageRatingNeeded || 0,
      ratingRewardBonus: rewards.rewards?.ratingRewardBonus * 100 || 0,
      callRewardNumberOfCalls: rewards.rewards?.callRewardNumberOfCalls || 0,
      callRewardBonus: rewards.rewards?.callRewardBonus * 100 || 0,
      referReward: rewards.rewards?.referReward || 0,
    },

    enableReinitialize: true,

    onSubmit: async (values) => {
      const payload = {
        ratingRewardAverageRatingNeeded: values.ratingRewardAverageRatingNeeded,
        ratingRewardBonus: values.ratingRewardBonus / 100,
        callRewardNumberOfCalls: values.callRewardNumberOfCalls,
        callRewardBonus: values.callRewardBonus / 100,
        referReward: values.referReward,
      };

      try {
        await Financial.configRewards(payload);
        UI.operations.showToast('Bonificações atualizadas com sucesso');
        close(true);
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao atualizar bonificação';
        UI.operations.showToast(erroMessage, {
          type: 'error',
        });
      }
    },
  });

  function submitRewardsPayload() {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        icon: <img src={confirmRewards} />,
        title: 'Confirmar bonificações',
        description: (
          <>
            Você está definindo as regras de bonificação do sistema.
            <br />
            As alterações serão aplicadas para todos os técnicos a partir do
            próximo mês.
          </>
        ),
        cancelText: 'Cancelar',
        cancelRed: true,
        confirmText: 'Confirmar',
        onConfirm: () => {
          formik.submitForm();
        },
      },
    });
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col flex-1 min-h-screen max-w-[603px]">
        <SidesheetHeader>Gerenciar Bonificações</SidesheetHeader>
        <div className="flex flex-col p-6 space-y-4">
          <Alert title="Atenção" type="danger">
            As alterações serão aplicadas para todos os técnicos a partir do
            próximo mês.
          </Alert>
          <BonusCard
            title="Bonificação por avaliações"
            icon={ratingBonus}
            condition={() => {
              return (
                <Typography variant="h4" color="darkestGray">
                  Se a média das avaliações no mês for no mínimo{' '}
                  <b>{rewards.rewards?.ratingRewardAverageRatingNeeded || 0}</b>
                  , o técnico receberá{' '}
                  <b>{(rewards.rewards?.ratingRewardBonus * 100 || 0) + '%'}</b>{' '}
                  a mais sobre seu faturamento do mês.
                </Typography>
              );
            }}
          >
            {(close: any) => {
              return <RatingBonusForm close={close} formik={formik} />;
            }}
          </BonusCard>
          <BonusCard
            title="Bonificação por quantidade de chamados"
            icon={callBonus}
            condition={() => {
              return (
                <Typography variant="h4" color="darkestGray">
                  Se a quantidade de chamados no mês for no mínimo{' '}
                  <b>{rewards.rewards?.callRewardNumberOfCalls || 0}</b>, o
                  técnico receberá{' '}
                  <b>{(rewards.rewards?.callRewardBonus * 100 || 0) + '%'}</b> a
                  mais sobre seu faturamento do mês.
                </Typography>
              );
            }}
          >
            {(close) => {
              return <CallsBonusForm close={close} formik={formik} />;
            }}
          </BonusCard>

          {/* Indicação não disponível no momento */}

          {/* <BonusCard
          title="Bonificação por indicação de profissionais"
          icon={ReferIcon}
          condition={() => {
            return (
              <Typography variant="h4" color="darkestGray">
                Se o profissional indicado finalizar o primeiro atendimento com
                sucesso, o técnico responsável pela indicação receberá{' '}
                <b>R$100</b>
              </Typography>
            );
          }}
        >
          {(close) => {
            return <ReferBonusForm close={close} />;
          }}
        </BonusCard> */}
        </div>
        <div className="flex justify-end w-full px-6 py-4 space-x-4 bg-lighterGray">
          <Button
            variant="outlined"
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button type="button" onClick={() => submitRewardsPayload()}>
            Confirmar Bonificações
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ManageBonusSidesheet;
