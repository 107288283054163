import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, SelectContainer, SelectOpenList } from './styles';
import { Typography } from 'components/Typography';
import Option, { IOptionItem } from './Option';
import { useClickOutside } from 'hooks/useClickOutside';
import { inactiveDropdown } from 'assets/images/icons';

interface SelectProps {
  placeholder?: string;
  options: IOptionItem[];
  getOptionValue(option: IOptionItem): void;
  disabled?: boolean;
  value?: string;
  serviceTypeSelected?: string;
}

const Select = ({
  options,
  placeholder = 'Selecione',
  getOptionValue,
  disabled = false,
  value,
  serviceTypeSelected,
}: SelectProps) => {
  const [openSelect, setOpenSelect] = useState(false);
  const [itemSelected, setItemSelected] = useState<IOptionItem>(
    options.find((f) => f.value === value)
  );
  const selectRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    action: () => setOpenSelect(false),
    ref: selectRef,
  });

  const handleSelectItem = useCallback(
    (item: IOptionItem) => {
      setItemSelected(item);
      getOptionValue(item);
      setOpenSelect(false);
    },
    [getOptionValue]
  );

  useEffect(() => {
    if (value) {
      setItemSelected(options.find((f) => f.value === value));
    }
  }, [value]);

  useEffect(() => {
    if (serviceTypeSelected === '') {
      setItemSelected(undefined);
    }
  }, [serviceTypeSelected]);
  return (
    <Container ref={selectRef}>
      <SelectContainer
        isOpen={openSelect}
        onClick={() => !disabled && setOpenSelect(!openSelect)}
        disabled={disabled}
        role="onClick"
      >
        <Typography
          variant="button"
          color="darkerGray"
          data-testid={`${itemSelected ? itemSelected.label : placeholder}`}
        >
          {itemSelected ? itemSelected.label : placeholder}
        </Typography>
        <img src={inactiveDropdown} alt="Ícone chamado de inactive dropDown" />
      </SelectContainer>
      <SelectOpenList isOpen={openSelect} data-testid="selectOpenList">
        {options.map((option) => (
          <Option
            data-testid="option"
            key={option.value}
            onSelectItem={handleSelectItem}
            option={option}
            active={itemSelected?.value === option.value}
          />
        ))}
      </SelectOpenList>
    </Container>
  );
};

export { Select };
