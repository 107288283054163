import { inactiveAlertHover } from 'assets/images/icons';
import { AlertLabel } from 'components/AlertComponents/AlertLabel';
import { AttachmentCard } from 'components/AttachmentCard';
import ClientCard from 'components/Calls/ServiceTab/ClientCard';
import TechnicianCard from 'components/Calls/ServiceTab/TechnicianCard';
import { LoadingSidesheet } from 'components/LoadingSidesheet';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { useCall } from 'queries/call';
import { ResumeService } from '../ResumeService';
import { Container } from './styles';

export const PaymentServiceTab = ({ callId }) => {
  const { call, isLoading } = useCall(callId);

  if (isLoading) {
    return <LoadingSidesheet />;
  }

  return (
    <Container>
      <div className="px-6">
        {call.chit?.antecipatedPayment && (
          <AlertLabel
            title={`Pagamento antecipado solicitado no dia ${format(
              new Date(call.chit.paidOutAt),
              "dd/MM/yyyy 'às' HH:mm"
            )} `}
            icon={inactiveAlertHover}
          >
            <Typography variant="p1" color="darkerGray">
              O técnico solicitou antecipar o pagamento deste chamado
            </Typography>
          </AlertLabel>
        )}
        <div className="space-y-6">
          <ResumeService call={call} withCalendar="true" />
          <div>
            <Typography variant="h2" color="darkestGray">
              Cliente
            </Typography>
            <ClientCard id={call?.companyId} callId={call?.id} />
          </div>
          <div>
            <Typography variant="h2" color="darkestGray">
              Profissional
            </Typography>
            {call?.callToTechnician && (
              <TechnicianCard
                id={call?.callToTechnician[0]?.technicianId}
                callId={call?.id}
              />
            )}
          </div>
          <div>
            <Typography variant="h2" color="darkestGray">
              Serviço
            </Typography>
            <div className="flex flex-col p-4 space-y-4 border-2 rounded-xl border-lightGray">
              <Typography variant="h4" color="darkestGray">
                Descrição
              </Typography>
              <section className="flex flex-col space-y-4">
                <Typography variant="p1" color="darkerGray">
                  {call?.serviceDetail}
                </Typography>
              </section>
            </div>
          </div>

          <div>
            <Typography variant="h2" color="darkestGray">
              Anexos
            </Typography>

            {call?.attachments?.map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                name={attachment.name}
                description={attachment.description}
                handleDownload={() => {
                  window.open(attachment.url);
                }}
              />
            ))}

            {call?.attachments?.length <= 0 && (
              <Typography variant="p1" color="darkerGray">
                Nenhum anexo
              </Typography>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
