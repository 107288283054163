import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
`;

export const Content = styled.div`
  margin-top: 40px;
  padding-left: 16px;
  border-top-left-radius: 30px;
`;

export const Image = styled.img`
  margin-left: 32px;
`;

export const Footer = styled.div`
  margin-left: 0px !important;
`;
