import { Typography } from 'components/Typography';
import { Info } from '../styles';
import { Container } from './styles';
import { useFormikContext } from 'formik';
import { Checkbox } from 'components/Checkbox';
import { PeriodsChecklistType } from 'features/Technician/types';

interface IPeriodsChecklistForm {
  periods: PeriodsChecklistType[];
}

interface PeriodsChecklistProps {
  elevate?: boolean;
}

const PeriodsChecklist = ({ elevate }: PeriodsChecklistProps) => {
  const { values, setFieldValue } = useFormikContext<IPeriodsChecklistForm>();

  return (
    <Info data-testid="periods-checklist">
      <Typography variant="h3" color="darkBLue">
        Disponibilidade
      </Typography>
      <Container elevate={elevate}>
        <table>
          <thead>
            <tr className="border-b-2 border-solid border-[#f2f2f2]">
              <th className="border-r-2 border-solid border-[#f2f2f2] w-[86px] h-[78px]" />
              <th scope="col" className="text-center">
                <Typography variant="p3" color="darkestGray">
                  Segunda
                </Typography>
              </th>
              <th scope="col" className="text-center">
                <Typography variant="p3" color="darkestGray">
                  Terça
                </Typography>
              </th>
              <th scope="col" className="text-center">
                <Typography variant="p3" color="darkestGray">
                  Quarta
                </Typography>
              </th>
              <th scope="col" className="text-center">
                <Typography variant="p3" color="darkestGray">
                  Quinta
                </Typography>
              </th>
              <th scope="col" className="text-center">
                <Typography variant="p3" color="darkestGray">
                  Sexta
                </Typography>
              </th>
              <th scope="col" className="text-center">
                <Typography variant="p3" color="darkestGray">
                  Sábado
                </Typography>
              </th>
              <th scope="col" className="text-center">
                <Typography variant="p3" color="darkestGray">
                  Domingo
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="border-r-2 border-solid border-[#f2f2f2] w-[86px] h-[78px]">
                <Typography variant="p3" color="darkestGray">
                  Manhã
                </Typography>
              </th>
              {values.periods.map((p, i) => (
                <th scope="col" key={i}>
                  <div className="flex items-center justify-center w-full">
                    <Checkbox
                      value={p.morning}
                      setState={() =>
                        setFieldValue(`periods[${i}].morning`, !p.morning)
                      }
                    />
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <th className="border-r-2 border-solid border-[#f2f2f2] w-[86px] h-[78px]">
                <Typography variant="p3" color="darkestGray">
                  Tarde
                </Typography>
              </th>
              {values.periods.map((p, i) => (
                <th scope="col" key={i}>
                  <div className="flex items-center justify-center w-full">
                    <Checkbox
                      value={p.afternoon}
                      setState={() =>
                        setFieldValue(`periods[${i}].afternoon`, !p.afternoon)
                      }
                    />
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <th className="border-r-2 border-solid border-[#f2f2f2] w-[86px] h-[78px]">
                <Typography variant="p3" color="darkestGray">
                  Noite
                </Typography>
              </th>
              {values.periods.map((p, i) => (
                <th scope="col" key={i}>
                  <div className="flex items-center justify-center w-full">
                    <Checkbox
                      value={p.night}
                      setState={() =>
                        setFieldValue(`periods[${i}].night`, !p.night)
                      }
                    />
                  </div>
                </th>
              ))}
            </tr>
          </tbody>
        </table>
      </Container>
      <div className="flex flex-col">
        <div className="relative flex flex-row items-center">
          <p className="text-purple absolute">*</p>
          <Typography variant="p3" color="darkestGray" className="ml-3">
            Turnos
          </Typography>
        </div>
        <Typography variant="p2" color="darkGray" className="ml-3">
          Manhã: 6:00 às 11:59
        </Typography>
        <Typography variant="p2" color="darkGray" className="ml-3">
          Tarde: 12:00 às 17:59
        </Typography>
        <Typography variant="p2" color="darkGray" className="ml-3">
          Noite: 18:00 às 23:59
        </Typography>
      </div>
    </Info>
  );
};

export { PeriodsChecklist };
