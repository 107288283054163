import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { AlertLabel } from 'components/AlertComponents/AlertLabel';
import { Button } from 'components/Button';
import { StatusButton } from 'components/StatusButton';
import { Typography } from 'components/Typography';
import { Container } from './styles';
import { yupValidations } from 'utils/validations/yup';
import { Call } from 'services/call';
import { useUI } from 'hooks/useUI';
import { inactiveAlertSearch } from 'assets/images/icons';
import { TabProfessional } from 'features/Call/components/LinkProfessional/TabProfessional';
import { useQueryClient } from 'react-query';

export interface SidesheetLinkTechnicianProps {
  callId: string;
  companyId: string;
  close(): void;
}

const SidesheetLinkTechnician = ({
  callId,
  companyId,
  close,
}: SidesheetLinkTechnicianProps) => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const UI = useUI();
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const payload = {
          technicianId: values.technicianId,
          searchType: values.searchType,
        };

        await Call.updateTechnicians(callId, payload);
        UI.operations.showToast('Técnico vinculado com sucesso!');
        close();
        await queryClient.refetchQueries(['call']);
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao vincular novo técnico';

        if (err.response?.status === 400) {
          erroMessage = err.response.data.message;
        }

        UI.operations.showToast(erroMessage, {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [callId]
  );

  const getSchemaValidation = useCallback(() => {
    return Yup.object().shape({
      searchType: yupValidations.requiredString,
    });
  }, []);

  return (
    <Formik
      initialValues={{
        callId: callId,
        companyId: companyId,
        searchType: '',
        technicianId: [],
      }}
      onSubmit={handleSubmit}
      validationSchema={getSchemaValidation()}
    >
      {({ setFieldValue, values }) => (
        <Container>
          <Typography variant="h2" color="darkBLue">
            Vincular profissional
          </Typography>
          <Typography variant="p1" color="darkerGray">
            Selecionando uma das opções abaixo, você pode deixar a plataforma
            atribuir automaticamente a melhor opção para este chamado ou definir
            manualmente quem responderá.
          </Typography>
          <div className="flex gap-6">
            <StatusButton
              selected={status === 'Automatic'}
              onClick={() => {
                setStatus('Automatic');
                setFieldValue('searchType', 'Automatic');
              }}
            >
              Automaticamente
            </StatusButton>
            <StatusButton
              selected={status === 'Manual'}
              onClick={() => {
                setStatus('Manual');
                setFieldValue('searchType', 'Manual');
              }}
            >
              Manualmente
            </StatusButton>
          </div>
          <Form>
            {status === 'Automatic' && (
              <div className="my-1">
                <AlertLabel icon={inactiveAlertSearch}>
                  Após concluir abertura do chamado a plataforma fará uma busca
                  inteligente na base de profissionais Eunerd para encontrar a
                  melhor opção para este atendimento.
                </AlertLabel>
              </div>
            )}
            {status === 'Manual' && <TabProfessional callId={callId} />}
            <Button
              loading={loading}
              block
              type="submit"
              className="mt-2"
              disabled={
                values?.technicianId.length === 0 &&
                values?.searchType === 'Manual'
              }
            >
              Vincular
            </Button>
          </Form>
        </Container>
      )}
    </Formik>
  );
};
export { SidesheetLinkTechnician };
