import { months } from 'utils/dateUtils';

export const buildSearchParams = (data) => {
  const params = new URLSearchParams();

  Object.keys(data).map((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((term) => params.append(key, term));
    } else {
      if (Boolean(data[key])) {
        params.append(key, data[key]);
      }
    }
  });

  return params;
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const sortByDate = (arr, key) => {
  arr.sort(function (a, b) {
    return Number(new Date(a[key])) - Number(new Date(b[key]));
  });

  return arr;
};

export const groupAlertsByDay = (xs, key) => {
  const data = sortByDate(xs, key).map((item) => ({
    ...item,
    dateTime: `${item[key].substr(0, 10)}T00:00:00`,
  }));
  return data?.reduce((rv, x) => {
    (rv[x['dateTime']] = rv[x['dateTime']] || []).push(x);
    return rv;
  }, {});
};

export const groupByCalls = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x.call[key]] = rv[x.call[key]] || []).push(x);
    return rv;
  }, {});
};

export const groupByMonth = (data) => {
  return data?.reduce((acc, item) => {
    const month = new Date(item.datetime).getMonth();
    (acc[month] = acc[month] || []).push(item);
    return acc;
  }, {});
};

export const groupByMonthCalendar = (data) => {
  const result = [];
  data.map((dataTemp) => {
    const date = dataTemp instanceof Date ? dataTemp : new Date(dataTemp);
    result.push({
      month: date.getMonth(),
      date,
    });
  });

  return data?.reduce((acc, item) => {
    const month = new Date(item).getMonth();
    const date = item instanceof Date ? item : new Date(item);
    (acc[month] = acc[month] || []).push(date);
    return acc;
  }, []);
};

export const groupByMonthCall = (data) => {
  return data?.reduce((acc, item) => {
    const month = new Date(item.call.startTime).getMonth();
    (acc[month] = acc[month] || []).push(item);
    return acc;
  }, {});
};

export const groupByYear = (data) => {
  return data?.reduce((acc, item) => {
    const month = new Date(item.datetime).getFullYear();
    (acc[month] = acc[month] || []).push(item);
    return acc;
  }, {});
};

export const groupByYearCall = (data) => {
  return data?.reduce((acc, item) => {
    if (item.call) {
      const month = new Date(item.call.startTime).getFullYear();
      (acc[month] = acc[month] || []).push(item);
      return acc;
    }
    const month = new Date(item.datetime).getFullYear();
    (acc[month] = acc[month] || []).push(item);
    return acc;
  }, {});
};
