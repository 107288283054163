import styled from 'styled-components';

import { Pagination } from 'react-instantsearch-dom';

export const PaginationContainer = styled(Pagination)`
  float: right;

  .ais-Pagination-list {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.darkGray};

    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--firstPage,
    .ais-Pagination-item--nextPage {
      font-size: 1.5rem;
      text-align: center;
      cursor: pointer;
    }

    li {
      padding: 0.5rem 1rem;

      .ais-Pagination-link--selected {
        color: ${({ theme }) => theme.colors.purpleHover};
      }
    }
  }
`;

// px-4 py-2 text-sm font-medium
