import { alertWhite } from 'assets/images/icons';

const AlertIcon = () => {
  return (
    <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-md bg-orange">
      <img src={alertWhite} className="flex-shrink-0" />
    </div>
  );
};

export default AlertIcon;
