import { useUI } from 'hooks/useUI';
import { SidesheetTypes } from 'ducks/ui/types';
import { IconButton } from 'components/IconButton';
import { Typography } from 'components/Typography';
import { BigNumberContainer } from 'components/BigNumberContainer';
import AlgoliaFilterConfiguration from 'components/AlgoliaFilterConfiguration';
import useAlgolia from 'hooks/useAlgolia';
import { SearchCardAlgolia } from 'components/SearchCardAlgolia';
import VerticalRule from 'components/VerticalRule';
import { useTechnicianCounter } from 'queries/technician';
import { plusSign } from 'assets/images/icons';
import {
  BigNumbersContainer,
  FiltersContainer,
  InstantSearchContainer,
} from 'features/Technician/styles';
import TechniciansTable from '../TechniciansTable';
import { ScreeningTechniciansProps } from 'features/Technician/types';
import { useCallback, useState } from 'react';
import type { AlgoliaSearchStateType } from 'types/algolia';
import { Toggle } from 'components/Toggle';
import { Button } from 'components/Button';
import { AlgoliaTotalCountTechnicians } from '../AlgoliaTotalCountTechnicians';

const ScreeningTechnicians = ({ searchClient }: ScreeningTechniciansProps) => {
  const UI = useUI();
  const defaultFilterValue =
    'status:WAITING_FOR_ACTIVATION OR status:WAITING_FOR_APPROVAL';
  const { filters: algoliaFilters, handleFilter } = useAlgolia({
    defaultFilterValue,
  });
  const [searchState, setSearchState] = useState<AlgoliaSearchStateType>({
    page: 1,
    configure: { analytics: false, filters: algoliaFilters, hitsPerPage: 10 },
  });
  const [refreshAlgolia, setRefreshAlgolia] = useState(false);
  const { counters, isError, isLoading } = useTechnicianCounter();
  const [filterByCnpj, setFilterByCnpj] = useState(true);
  const [showCnpjFilter, setShowCnpjFilter] = useState(false);

  const handleUpdateSearchStatPage = useCallback(() => {
    setSearchState({ ...searchState, page: 1 });
  }, [searchState]);

  const handleFilterTechnician = useCallback(
    (status: string) => {
      if (algoliaFilters === `status:${status}`) {
        handleFilter(defaultFilterValue);
      } else {
        handleFilter(`status:${status}`);
      }
      handleUpdateSearchStatPage();
    },
    [
      handleUpdateSearchStatPage,
      algoliaFilters,
      defaultFilterValue,
      handleFilter,
    ]
  );

  const handleFilterTechnicianByCnpj = useCallback(
    (showCnpj: boolean) => {
      if (showCnpj) {
        const filter = algoliaFilters.replace('cnpj:unknown AND', '');
        handleFilter(`NOT cnpj:unknown AND (${filter})`);
      } else {
        const filter = algoliaFilters.replace('NOT cnpj:unknown AND ', '');
        handleFilter(`cnpj:unknown AND (${filter})`);
      }
      handleUpdateSearchStatPage();
      setFilterByCnpj(showCnpj);
    },
    [handleUpdateSearchStatPage, algoliaFilters, handleFilter]
  );

  const closeCnpjFilter = useCallback(() => {
    handleFilter(defaultFilterValue);
    setShowCnpjFilter(false);
  }, [handleFilter]);

  const openCnpjFilter = useCallback(() => {
    setShowCnpjFilter(true);
    handleFilterTechnicianByCnpj(filterByCnpj);
  }, [filterByCnpj, handleFilterTechnicianByCnpj]);

  return (
    <InstantSearchContainer
      indexName="technicians"
      searchClient={searchClient}
      onSearchStateChange={(value) => setSearchState(value)}
      searchState={searchState}
      refresh={refreshAlgolia}
    >
      <AlgoliaFilterConfiguration filters={algoliaFilters} />
      <Typography className="mb-4" variant="tag" color="darkBlue">
        Filtros
      </Typography>
      <FiltersContainer>
        <SearchCardAlgolia />
        <VerticalRule />
        <BigNumbersContainer>
          <BigNumberContainer
            color="purple"
            label="Aguardando ativação"
            isHighlighted={counters?.techniciansWaitingForActivationTotal}
            selected={algoliaFilters === 'status:WAITING_FOR_ACTIVATION'}
            isError={isError}
            isLoading={isLoading}
            onClick={() => handleFilterTechnician('WAITING_FOR_ACTIVATION')}
          />
          <BigNumberContainer
            color="yellowClick"
            label="Aguardando aprovação"
            isHighlighted={counters?.techniciansWaitingForApprovalTotal}
            selected={algoliaFilters === 'status:WAITING_FOR_APPROVAL'}
            isError={isError}
            isLoading={isLoading}
            onClick={() => handleFilterTechnician('WAITING_FOR_APPROVAL')}
          />
        </BigNumbersContainer>
        <VerticalRule />
        <IconButton
          icon={plusSign}
          size="large"
          onClick={() =>
            UI.operations.sidesheet.openSidesheet({
              type: SidesheetTypes.NewTechnician,
            })
          }
        >
          Cadastrar novo técnico
        </IconButton>
      </FiltersContainer>
      <div className="flex items-center pb-4 gap-4">
        <div className="flex items-center gap-32">
          <AlgoliaTotalCountTechnicians
            filters={algoliaFilters}
            searchClient={searchClient}
          />
          {showCnpjFilter ? (
            <Toggle
              label="Com CNPJ?"
              checked={filterByCnpj}
              onClick={handleFilterTechnicianByCnpj}
            />
          ) : null}
        </div>
        {showCnpjFilter ? (
          <Button variant="anchor" onClick={closeCnpjFilter}>
            Limpar filtro
          </Button>
        ) : (
          <Button variant="anchor" onClick={openCnpjFilter}>
            Filtrar por CNPJ
          </Button>
        )}
      </div>
      <TechniciansTable
        handleRefresh={() => setRefreshAlgolia(true)}
        resetRefresh={() => setRefreshAlgolia(false)}
      />
    </InstantSearchContainer>
  );
};

export { ScreeningTechnicians };
