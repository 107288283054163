import { TextField } from 'components/Inputs/TextField';
import { Typography } from 'components/Typography';
import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FieldInformationItem } from '../FieldInformationItem';
import { CallTemplateData } from '../types';

import { Container, Content } from './styles';

interface InformationAboutTemplateProps {
  data: CallTemplateData;
}

const InformationAboutTemplate = ({ data }: InformationAboutTemplateProps) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const {
        name,
        serviceId,
        type,
        checklistItems,
        attachments,
        necessaryProfessionals,
        serviceDetail,
        serviceType,
        startTime,
      } = data;
      const startTimeFormatted = startTime?.map((item) => new Date(item));

      const checklist = checklistItems?.map((item) => ({
        id: item?.id,
        description: item?.description,
      }));

      setFieldValue('name', name);
      setFieldValue('serviceId', serviceId);
      setFieldValue('type', type);
      setFieldValue(
        'checklistItems',
        checklist?.map((item) => {
          return { id: item.id, value: item.description };
        })
      );
      setFieldValue(
        'checklist',
        checklist?.map((item) => {
          return { id: item.id, value: item.description };
        })
      );
      setFieldValue('attachmentsItems', attachments);
      setFieldValue('necessaryProfessionals', necessaryProfessionals);
      setFieldValue('serviceDetail', serviceDetail);
      setFieldValue('serviceType', serviceType);
      setFieldValue('startTime', startTimeFormatted);
    }
  }, [data, setFieldValue]);

  return (
    <Container data-testid="information-about-template">
      <Typography variant="h2" color="darkestGray">
        Informações sobre o template
      </Typography>
      <Typography variant="p1" color="darkerGray" className="subtitle">
        Indique o nome do template, defina o endereço de atendimento e o
        responsável no local por validar o chamado.
      </Typography>

      <Content>
        <Field component={TextField} label="Nome do template" name="name" />
        <FieldInformationItem
          label="Endereço"
          content="O endereço será definido na abertura do chamado"
        />
        <FieldInformationItem
          label="Responsável da empresa para validar o serviço do técnico"
          content="A pessoa responsável pela validação do serviço será definida na aberturado chamado"
        />
      </Content>
    </Container>
  );
};

export { InformationAboutTemplate };
