import styled, { css } from 'styled-components';

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;

  p {
    margin-left: 32px;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

export const RadioButtonInput = styled.input.attrs({
  type: 'radio',
  role: 'radio',
})`
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;
`;

export const RadioButtonContainer = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${RadioButtonInput}:checked + &::after {
    display: block;
    border-radius: 50%;
    border-color: ${(props) => props.theme.colors.purple};
    background: ${(props) => props.theme.colors.purple};
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
