import { Typography } from 'components/Typography';
import { TextField } from 'components/Inputs/TextField';

const AddressDataForm = ({ address }) => {
  return (
    <>
      <Typography variant="h3" color="darkBLue">
        Endereço residencial
      </Typography>

      <div key={address?.id} className="flex flex-col gap-5">
        <div className="flex flex-row w-full gap-5">
          <TextField disabled label="CEP" width="152px" value={address?.cep} />

          <TextField
            disabled
            label="Endereço"
            width="352px"
            value={address?.address}
          />
        </div>

        <div className="flex flex-row w-full gap-5">
          <TextField
            disabled
            label="Estado"
            width="152px"
            value={address?.state}
          />

          <TextField
            disabled
            label="Cidade"
            width="352px"
            value={address?.city}
          />
        </div>

        <div className="flex flex-row w-full gap-5">
          <TextField
            disabled
            label="Bairro"
            width="252px"
            value={address?.district}
          />

          <TextField
            disabled
            label="Número"
            width="252px"
            value={address?.number}
          />
        </div>

        <TextField
          disabled
          label="Complemento"
          width="100%"
          value={address?.complement || ''}
        />
      </div>
    </>
  );
};

export { AddressDataForm };
