import { useMemo } from 'react';
import { TextField } from 'components/Inputs/TextField';
import { Typography } from 'components/Typography';
import { useBanklist, useTechnician } from 'queries/technician';
import { BankDataType, BanksTypes } from 'features/Technician/types';

type _embeddedTypes = {
  _embedded: {
    banks: BanksTypes[];
  };
};
interface IBanks {
  banks: _embeddedTypes;
}

interface IUseBanklist {
  data: {
    data: IBanks;
  };
}

interface TechnicianBankDataProps {
  id: string;
}

const TechnicianBankData = ({ id }: TechnicianBankDataProps) => {
  const technician = useTechnician(id);
  const banks = useBanklist() as IUseBanklist;

  const bankData = useMemo<BankDataType>(() => {
    return (
      {
        id: technician.technician?.bank?.id,
        bank: technician.technician?.bank?.bank,
        agency: technician.technician?.bank?.agency,
        account: technician.technician?.bank?.account,
        accountType: technician.technician?.bank?.accountType,
        operationType: technician.technician?.bank?.operationType,
        pix: technician.technician?.bank?.pix,
        junoAccount: technician.technician?.junoAccount,
        banksList: banks.data?.data?.banks?._embedded?.banks,
      } || {}
    );
  }, [technician, banks]);

  const renderAccountTypeFormated = (accountType: string) => {
    return accountType === 'CHECKING' ? 'Conta corrente' : 'Conta poupança';
  };

  const renderBankFormated = (id) => {
    const bank = bankData?.banksList?.filter((e) => e.number == id);

    if (!id || !bank) return;

    return bank[0]?.name ? `${id} - ${bank[0]?.name}` : id;
  };

  return (
    <div className="py-6 space-y-6 ">
      <Typography variant="h3" color="darkBLue">
        Dados bancários
      </Typography>
      <TextField
        label="Banco"
        value={renderBankFormated(bankData?.bank) || ''}
        width="100%"
        disabled
      />
      <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-6">
        <TextField
          label="Tipo da conta"
          value={
            renderAccountTypeFormated(
              technician?.technician?.bank?.accountType
            ) || '-'
          }
          width="100%"
          disabled
        />
        <TextField
          label="Operação"
          value={technician?.technician?.bank?.operationType || '-'}
          data-testid="operation"
          width="100%"
          disabled
          dataTestId="operation"
        />
        <TextField
          label="Agência"
          value={technician?.technician?.bank?.agency || '-'}
          width="100%"
          disabled
        />
        <TextField
          label="Conta"
          value={technician?.technician?.bank?.account || '-'}
          width="100%"
          disabled
        />
      </div>
      <TextField
        label="Esta conta possui uma chave PIX?"
        value={bankData?.pix || 'Não'}
        width="100%"
        disabled
      />

      <Typography variant="h3" color="darkBLue">
        Dados da conta do ténico na platatorma Juno
      </Typography>
      <div className="grid grid-flow-col grid-cols-2 gap-6">
        <TextField
          label="Número da conta"
          value={bankData?.junoAccount?.account || '-'}
          width="100%"
          disabled
        />
        <TextField
          label="Chave pix da conta gerada pela Juno"
          value={bankData?.junoAccount?.pix || '-'}
          width="100%"
          disabled
        />
      </div>
    </div>
  );
};

export { TechnicianBankData };
