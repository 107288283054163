import { statusCallDetails } from 'lib/call';
import { useTechnicianCalls } from 'queries/technician';
import { TimelineSchedule } from '../../TimelineSchedule';

export interface FilterSheduleProps {
  idTechnician: string;
  selectedMonthNumber: number;
}

const SheduleFinishedCalls = ({
  idTechnician,
  selectedMonthNumber,
}: FilterSheduleProps) => {
  const technicianCalls = useTechnicianCalls(idTechnician, {
    status: [
      statusCallDetails.CALL_SUCCESSFULLY_ENDED.value,
      statusCallDetails.UNPRODUCTIVE_CALL.value,
    ],
    month: selectedMonthNumber,
  });

  return <TimelineSchedule technicianCalls={technicianCalls} />;
};

export { SheduleFinishedCalls };
