import { Typography } from 'components/Typography';
import React from 'react';

import { Container, Check, Content } from './styles';

interface CheckReviewProps {
  title: string;
  desceription: string;
  active: boolean;
  onChange(): void;
}

export const CheckReview: React.FC<CheckReviewProps> = ({
  active,
  desceription,
  onChange,
  title
}) => {
  return (
    <Container active={active} onClick={() => onChange()}>
      <Check active={active} />
      <Content>
        <Typography variant="button" color="darkestGray">
          {title}
        </Typography>
        <Typography variant="p1" color="darkerGray">
          {desceription}
        </Typography>
      </Content>
    </Container>
  );
}
