import { Typography } from 'components/Typography';

interface UrgentCallsProps {
  urgentCalls: boolean;
}

const UrgentCalls = ({ urgentCalls }: UrgentCallsProps) => {
  return (
    <div className="space-y-2">
      <Typography variant="h3" color="darkBLue">
        Disponibilidade para chamados urgentes
      </Typography>
      <Typography variant="p1" color="darkerGray">
        {urgentCalls ? 'Sim' : 'Não'}
      </Typography>
    </div>
  );
};

export { UrgentCalls };
