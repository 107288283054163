import styled from 'styled-components';

interface InputProps {
  invalid: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.orangeClick};
  font-family: Asap;
  font-weight: 500;
  font-size: 12px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-family: Asap;
  font-size: 14px;
  font-weight: 500;
`;

export const OptionalLabel = styled.span`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: Asap;
  font-size: 12px;
  font-weight: 500;
`;

export const Input = styled.input<InputProps>`
  color: ${({ theme, readOnly }) =>
    readOnly ? theme.colors.darkerGray : theme.colors.darkBlue};
  border: 2px solid
    ${({ theme, invalid }) =>
      invalid ? theme.colors.orangeClick : theme.colors.gray};
  border-radius: 16px;
  font-family: Asap;
  font-size: 14px;
  font-weight: 500;
  height: ${({ height }) => (height ? height : '48px')};
  padding-left: 16px;
  width: ${({ width }) => (width ? width : '292px')};

  :focus {
    border: 2px solid
      ${({ theme, invalid }) =>
        invalid ? theme.colors.orangeClick : theme.colors.purple};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`;
