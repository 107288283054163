import { Typography } from 'components/Typography';
interface SummaryItemProps {
  icon: string;
  title?: string;
  description?: string;
  showTitle?: boolean;
}

const SummaryItem = ({
  icon,
  title,
  description,
  showTitle = false,
}: SummaryItemProps) => {
  return (
    <div className="flex flex-row items-center gap-4 py-2 border-b-2 border-lightGray">
      <img src={icon} alt="icon" />
      <div className="flex flex-col">
        {!description && (
          <Typography variant="h3" color="darkestGray">
            {title}
          </Typography>
        )}
        {description && showTitle && (
          <Typography variant="h3" color="darkestGray">
            {title}
          </Typography>
        )}

        {description ? (
          <Typography variant={!showTitle ? 'h3' : 'p1'} color="darkestGray">
            {description}
          </Typography>
        ) : (
          <Typography
            variant="p1"
            color="darkestGray"
            className="w-auto h-2 rounded-md animate-pulse bg-lightGray"
          />
        )}
      </div>
    </div>
  );
};

export { SummaryItem };
