import { Typography } from 'components/Typography';
import { ListProfessionals } from '../ListProfessionals';

const LinkProfessionalManually = () => {
  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-6">
        <Typography variant="h3" color="darkestGray">
          Vincular profissional manualmente
        </Typography>
        <Typography variant="p1" color="darkerGray">
          Os técnicos apresentados passaram por um filtro de localidade e
          capacitação para que todos sejam compatíveis e aptos para atender o
          chamado. Caso deseje um técnico específico que está indisponível, é
          possível atualizar a data do atendimento de acordo com a agenda do
          profissional.
        </Typography>
      </div>

      <div className="flex flex-row justify-between items-baseline">
        <ListProfessionals />
      </div>
    </div>
  );
};

export { LinkProfessionalManually };
