import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopictTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const TopictNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  background: ${({ theme }) => lighten(0.25, theme.colors.purple)};
`;

export const Body = styled.div`
  padding: 1.5rem 0;
`;
