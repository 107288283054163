import { Typography } from 'components/Typography';
import { useFormikContext } from 'formik';
import { CreateCallProps, IRegisteredItems } from 'types/call';
import { ChecklistComponent } from '../ChecklistComponent';

interface CallCheckListContext extends Omit<CreateCallProps, 'checklist'> {
  checklist: IRegisteredItems[];
}

const CallChecklist = () => {
  const { setFieldValue, values } = useFormikContext<CallCheckListContext>();
  return (
    <div className="space-y-4">
      <div>
        <Typography variant="h3" color="darkestGray">
          Checklist técnico
        </Typography>
        <Typography variant="p1" color="darkerGray">
          Cadastre um checklist com etapas que o profissional deve realizar
          dentro do chamado. O checklist cadastrado servirá para validar o
          término do serviço.
        </Typography>
      </div>
      <ChecklistComponent
        onChangeRemoveItem={(_, index) => {
          setFieldValue(
            'checklist',
            values.checklist.filter((_, i) => i !== index)
          );
        }}
        onChangeSaveItem={(items) => setFieldValue('checklist', items)}
        checklistValues={values?.checklist}
      />
    </div>
  );
};

export { CallChecklist };
