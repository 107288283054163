import { useState } from 'react';
import { useTechnician } from 'queries/technician';
import { Tabs } from 'components/Tabs';
import { IconTab } from 'components/Tabs/IconTab';

import {
  activeCreditCard,
  financesSelected,
  financesUnselected,
  inactiveCreditCard,
  profileSelected,
  profileUnselected,
} from 'assets/images/icons';
import { RevenuesAndCallsTechnicians } from 'features/Technician/components/RevenuesAndCallsTechnicians';
import { TechnicianPersonalData } from 'features/Technician/components/TechnicianPersonalData';
import { TechnicianBankData } from 'features/Technician/components/TechnicianBankData';
import { FooterSidesheetTechnician } from 'features/Technician/components/FooterSidesheetTechnician';
import { HeaderTechnician } from 'features/Technician/components/HeaderTechnician';

export interface SidesheetActiveTechnicianProps {
  id: string;
  onAfterUpdate?: () => Promise<void>;
}

const SidesheetActiveTechnician = ({
  id,
  onAfterUpdate,
}: SidesheetActiveTechnicianProps) => {
  const technician = useTechnician(id);
  const [currentTab, setCurrentTab] = useState(0);

  function renderTabSidesheet() {
    const tabs = {
      0: <RevenuesAndCallsTechnicians id={id} />,
      // 1: <TechnicianSchedule id={id} />,
      1: <TechnicianPersonalData id={id} />,
      2: <TechnicianBankData id={id} />,
    };
    return tabs[currentTab] || tabs[0];
  }

  return (
    <div
      className="flex flex-col max-h-screen min-h-screen w-[670px]"
      style={{ width: '670px' }}
    >
      <HeaderTechnician infoPersonalData={technician?.technician || {}} />
      <div className="px-6">
        <Tabs
          data={[
            {
              title: 'Renda e chamados',
              iconSelected: financesSelected,
              iconUnselected: financesUnselected,
            },
            // {
            //   title: 'Agenda',
            //   iconSelected: scheduleSelected,
            //   iconUnselected: scheduleUnselected,
            // },
            {
              title: 'Dados pessoais',
              iconSelected: profileSelected,
              iconUnselected: profileUnselected,
            },
            {
              title: 'Dados bancários',
              iconSelected: activeCreditCard,
              iconUnselected: inactiveCreditCard,
            },
          ]}
          renderItem={IconTab}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </div>
      <div className="h-full px-6 overflow-y-auto">{renderTabSidesheet()}</div>
      <FooterSidesheetTechnician id={id} onAfterUpdate={onAfterUpdate} />
    </div>
  );
};

export { SidesheetActiveTechnician };
