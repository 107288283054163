/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Marker, Avatar, Container } from './styles';
import {
  blue,
  gray,
  green,
  orange,
  positivo as PngAvatar,
} from 'assets/images/icons';

import Card from '../Card';

import theme from 'themes';

const ClientMarker = ({
  onClick,
  inProgress = false,
  status = ' ',
  className = '',
  profileURL = PngAvatar,
  time = null,
}) => {
  const [statusBackground, setStatusBackground] = useState();
  const [statusColor, setStatusColor] = useState();
  const [effect, setEffect] = useState(true);

  function setTransition() {
    return setTimeout(() => setEffect(false), 3000);
  }

  useEffect(() => {
    switch (status) {
      case 'CALL_SUCCESSFULLY_ENDED':
      case 'ON_THE_WAY':
      case 'SUPPORT_STARTED':
        setStatusColor(theme.colors.greenClick);
        setStatusBackground(`url(${green})`);
        break;
      case 'LOOKING_FOR_PROFESSIONAL':
      case 'OPERATIONS_LOOKING_FOR_PROFESSIONAL':
      case 'SCHEDULED_CALL':
      case 'UNDER_ANALYSIS':
        setStatusColor(theme.colors.lighterBlue);
        setStatusBackground(`url(${blue})`);
        break;
      case 'PROFESSIONAL_NOT_FOUND':
      case 'CALL_REFUSED_BY_TECHNICIAN':
      case 'DELAYED_CALL':
      case 'REQUESTED_CANCELLATION':
      case 'PENDING_COMPLETION':
      case 'UNPRODUCTIVE_CALL':
        setStatusColor(theme.colors.orange);
        setStatusBackground(`url(${orange})`);
        break;
      case 'SUPPORT_CANCELED':
      case 'WAITING_TECHNICIANS_ACCEPTANCE':
      default:
        setStatusColor(theme.colors.gray);
        setStatusBackground(`url(${gray})`);
        break;
    }
  }, []);

  // if (inProgress) {
  switch (status) {
    case 'LOOKING_FOR_PROFESSIONAL':
      return (
        <Container>
          <Card status={status} />
          <Marker
            onClick={onClick}
            className={className}
            style={{ background: statusBackground }}
          >
            <Avatar
              src={PngAvatar}
              style={{
                backgroundImage: `url(${profileURL})`,
              }}
            />
          </Marker>
        </Container>
      );

    case 'SCHEDULED_CALL':
      return (
        <Container>
          <Card status={status} time={time} />
          <Marker
            onClick={onClick}
            className={className}
            style={{ background: statusBackground }}
          >
            <Avatar
              src={PngAvatar}
              style={{
                backgroundImage: `url(${profileURL})`,
              }}
            />
          </Marker>
        </Container>
      );

    case 'SUPPORT_STARTED':
    case 'ON_THE_WAY':
      return (
        <Container>
          <Card status={status} time={time} />
          <Marker
            onClick={onClick}
            className={className}
            style={{ background: statusBackground }}
          >
            <Avatar
              src={PngAvatar}
              style={{
                backgroundImage: `url(${profileURL})`,
              }}
            />
          </Marker>
        </Container>
      );

    case 'WAITING_TECHNICIANS_ACCEPTANCE':
      setTransition();
      return (
        <Container>
          <Marker
            onClick={onClick}
            className={className}
            style={{ background: statusBackground }}
          >
            <Avatar
              src={PngAvatar}
              style={{
                backgroundImage: `url(${profileURL})`,
              }}
            />
          </Marker>
          {/* {effect ? (
            <Card
              status="PROFESSIONAL_NOT_FOUND"
              style={{ top: 46, backgroundColor: theme.colors.orange }}
              time={time}
            />
          ) : (
            <ContainerEffect
              show={!effect}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            > */}
          <Card
            status="WAITING_TECHNICIANS_ACCEPTANCE"
            style={{ top: 46 }}
            time={time}
          />
          {/* </ContainerEffect>
          )} */}
        </Container>
      );

    case 'CALL_REFUSED_BY_TECHNICIAN':
      return (
        <Container>
          <Marker
            onClick={onClick}
            className={className}
            style={{ background: statusBackground }}
          >
            <Avatar
              src={PngAvatar}
              style={{
                backgroundImage: `url(${profileURL})`,
              }}
            />
          </Marker>
          <Card
            status="PROFESSIONAL_NOT_FOUND"
            style={{ top: 46, backgroundColor: theme.colors.orange }}
            time={time}
          />
        </Container>
      );

    case 'CALL_SUCCESSFULLY_ENDED':
      return (
        <Container>
          <Marker
            onClick={onClick}
            className={className}
            style={{ background: statusBackground }}
          >
            <Avatar
              src={PngAvatar}
              style={{
                backgroundImage: `url(${profileURL})`,
              }}
            />
          </Marker>
          <Card
            status={status}
            style={{ top: 46, backgroundColor: theme.colors.greenClick }}
          />
        </Container>
      );

    default:
      return null;
  }
  // } else {
  //   return (
  //     <Container
  //       onMouseEnter={() => setHoverEffect(true)}
  //       onMouseLeave={() => setHoverEffect(false)}
  //     >
  //       <Marker
  //         onClick={onClick}
  //         className={className}
  //         style={{ background: statusBackground }}
  //       >
  //         {status === 'UNASSIGNED' ? (
  //           <Avatar
  //             src={PngAvatar}
  //             style={{
  //               backgroundImage: `url(${PngBlank})`,
  //             }}
  //           >
  //             <AvatarText>5+</AvatarText>
  //           </Avatar>
  //         ) : (
  //           <Avatar
  //             src={PngAvatar}
  //             style={{
  //               backgroundImage: `url(${profileURL})`,
  //             }}
  //           />
  //         )}
  //         <LookButton
  //           show={hoverEffect}
  //           enter="transition-opacity duration-75"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="transition-opacity duration-150"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //         >
  //           <Image src={SvgSearch} alt="" />
  //         </LookButton>
  //       </Marker>
  //     </Container>
  //   );
  // }
};

export default React.memo(ClientMarker);
