import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import { ContentCard } from 'components/ContentCard';
import { FieldReadOnlyInfo } from 'components/FieldReadOnlyInfo';
import { formatCurrency } from 'utils/formatCurrency';
import { currencyToFloat } from 'utils/formatUtils';
import { ptBR } from 'date-fns/locale';
import { documentPurple, finances, suitcaseDark } from 'assets/images/icons';
import { ServiceAddedList } from '../ServiceAddedList';
import { CreateCompanyTypes } from 'features/Company/types';
import { Typography } from 'components/Typography';

const FormReviewCompany = () => {
  const { values } = useFormikContext<CreateCompanyTypes>();

  return (
    <div
      className="bg-white flex flex-col max-h-[min-content] mb-auto overflow-y-auto px-16 py-4 space-y-6"
      data-testid="form-review-company"
    >
      <ContentCard title="Dados Cadastrais" icon={documentPurple}>
        <div className="flex w-full space-x-4">
          <div className="flex flex-col w-full space-y-4">
            <p className="text-sm font-bold">Dados cliente</p>
            <div className="flex flex-row space-x-4">
              <div className="flex w-full space-x-4">
                <FieldReadOnlyInfo
                  label="Nome Fantasia"
                  value={values.fantasyName}
                />
                <FieldReadOnlyInfo label="CNPJ" value={values.cnpj} />
                <FieldReadOnlyInfo
                  label="Inscrição Estadual"
                  value={values.stateRegistration || 'Não informado'}
                />
              </div>
              <div className="flex w-full space-x-4">
                <FieldReadOnlyInfo label="Razão Social" value={values.name} />
                <FieldReadOnlyInfo
                  label="Inscrição Municipal"
                  value={values.municipalRegistration || 'Não informado'}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentCard>
      <ContentCard title="Informações base e cobrança" icon={finances}>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row space-x-2">
            <div className="w-1/2">
              <p className="text-sm font-bold">Informações base do contrato</p>
              <div className="flex mt-2 flex-row space-x-2 flex-wrap">
                <div className="w-46 xl:w-52">
                  <FieldReadOnlyInfo
                    label="Valor total contratado"
                    value={formatCurrency(
                      currencyToFloat(values.contract?.amount)
                    )}
                  />{' '}
                </div>
                <div className="w-46 xl:w-50">
                  <FieldReadOnlyInfo
                    label="Início do contrato"
                    value={format(
                      new Date(values.contract?.start || null),
                      "dd 'de' MMMM 'de' yyyy",
                      {
                        locale: ptBR,
                      }
                    )}
                  />
                </div>
              </div>

              <div className="w-44 mt-4 xl:w-52">
                <FieldReadOnlyInfo
                  label="Fim do contrato"
                  value={format(
                    new Date(values.contract?.end || null),
                    "dd 'de' MMMM 'de' yyyy",
                    {
                      locale: ptBR,
                    }
                  )}
                />
              </div>
            </div>

            <div className="w-1/2">
              <Typography variant="button" color="darkestGray">
                Cobrança
              </Typography>
              <div className="flex mt-2 flex-row space-x-2 flex-wrap w-full">
                <div className="xl:w-50">
                  <FieldReadOnlyInfo
                    label="Quilometragem inclusa"
                    value={values.sla?.kmIncluded + ' KM'}
                  />
                </div>
                <div className="w-1/2 xl:w-52">
                  <FieldReadOnlyInfo
                    label="Custo por km extra"
                    value={'R$ ' + values.sla?.extraCostPerKM}
                  />
                </div>
              </div>
              <div className="w-52 mt-4">
                <FieldReadOnlyInfo
                  label="Custo de uso da plataforma"
                  value={'R$ ' + values.sla?.monthlyPlatformCost}
                />
              </div>
            </div>
          </div>
          <div>
            <Typography variant="button" color="darkestGray">
              SLA&apos;s do Cliente
            </Typography>

            <div className="flex mt-2 flex-row flex-wrap">
              <div className="w-40 mr-2 mb-4">
                <FieldReadOnlyInfo
                  label="Prazo para início do chamado"
                  value={
                    values.sla?.startCalls +
                    ` hora${values.sla?.startCalls > 1 ? 's' : ''}`
                  }
                />
              </div>
              <div className="w-44 mr-2">
                <FieldReadOnlyInfo
                  label="Cancelamento mais que 24h antes"
                  value={`${values.sla?.cancellationMoreThen24h}% do valor total`}
                />
              </div>

              <div className="w-44 mr-2">
                <FieldReadOnlyInfo
                  label="Cancelamento até 24h antes"
                  value={`${values.sla?.cancellationFeeUpTo24h}% do valor total`}
                />
              </div>
              <div className="w-44 mr-2 xl:mr-2">
                <FieldReadOnlyInfo
                  label="Cancelamento menos que 6h antes"
                  value={`${values.sla?.cancellationFeeUpTo6h}% do valor total`}
                />
              </div>
              <div className="mr-2 md:mt-0 xl:mt-0 flex items-center">
                <FieldReadOnlyInfo
                  label="Custo por chamado improdutivo"
                  value={
                    values.sla?.unproductiveCostPerCall ===
                    'TOTAL_EXPECTED_COST'
                      ? 'Cobrar do total previsto'
                      : 'Cobrar custo técnico do chamado'
                  }
                />
              </div>
              <div className="w-44">
                <FieldReadOnlyInfo
                  label="Custo por chamados urgentes"
                  value={`Valor total + ${values.sla?.urgentCostPerCall}%`}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentCard>

      <ContentCard title="Tipos de serviços" icon={suitcaseDark}>
        <p className="mb-4 text-sm font-bold">
          Tipos de serviços disponíveis para o cliente
        </p>

        {values.serviceType?.length > 0 ? (
          <ServiceAddedList services={values.serviceType} />
        ) : (
          <div className="w-full flex flex-col h-[500px] items-center justify-center">
            <p className="text-2xl font-bold">Nenhum serviço cadastrado</p>
          </div>
        )}
      </ContentCard>
    </div>
  );
};

export { FormReviewCompany };
