import { FieldReadOnlyInfo } from 'components/FieldReadOnlyInfo';
import { IconButton } from 'components/IconButton';
import { SidesheetTypes } from 'ducks/ui/types';
import { useFormikContext } from 'formik';
import { useUI } from 'hooks/useUI';
import { CompaniesTypes } from 'features/Company/types';
import { edit } from 'assets/images/icons';
import { cnpjMask, contactMask } from 'utils/maskUtils';

const ViewFormCompanyData = () => {
  const UI = useUI();
  const { values } = useFormikContext<CompaniesTypes>();

  function handleOpenEdit() {
    UI.operations.sidesheet.openSidesheet({
      type: SidesheetTypes.SidesheetEdiCompany,
      sidesheetProps: {
        id: values.id,
        width: 'wide',
      },
    });
  }

  return (
    <div className="bg-white flex flex-col max-h-[min-content] mb-auto overflow-y-auto">
      <div className="flex flex-col py-4">
        <div className="flex flex-col px-16 py-4">
          <div className="flex items-center space-x-4">
            <h2 className="font-bold text-md text-darkestGray">
              Dados do cliente
            </h2>
            <IconButton
              icon={edit}
              size="small"
              filled
              type="button"
              onClick={handleOpenEdit}
            >
              Editar Dados
            </IconButton>
          </div>
          <div className="flex flex-row w-full mt-4 space-x-4">
            <div className="flex flex-col w-full space-y-4">
              <div className="flex flex-col w-full space-y-4">
                <div className="flex w-full space-x-4">
                  <div className="w-1/2">
                    <FieldReadOnlyInfo
                      label="Nome Fantasia"
                      value={values.fantasyName}
                    />
                  </div>
                  <div className="w-1/2">
                    <FieldReadOnlyInfo
                      label="Razão Social"
                      value={values.name}
                    />
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="w-1/2">
                    <FieldReadOnlyInfo
                      label="CNPJ"
                      value={cnpjMask(values.cnpj)}
                    />
                  </div>
                  <div className="w-1/2">
                    <FieldReadOnlyInfo
                      label="Inscrição Estadual"
                      value={values.stateRegistration}
                      optional
                    />
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="w-1/2">
                    <FieldReadOnlyInfo
                      label="Inscrição Municipal"
                      value={values.municipalRegistration}
                      optional
                    />
                  </div>
                  <div className="w-1/2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col w-full px-16 py-6 space-y-4 border-t-2 border-gray">
          <h2 className="mb-4 font-semibold text-md text-darkBlue">
            Responsável
          </h2>
          <FieldReadOnlyInfo
            label="Nome Responsável"
            value={values.responsible?.name}
          />
          <FieldReadOnlyInfo label="E-mail" value={values.responsible?.email} />
          <div className="flex flex-row mt-4 space-x-4">
            <div className="w-1/2 space-y-4">
              <FieldReadOnlyInfo
                label="Cargo"
                value={values.responsible?.adjutancy}
              />
              <FieldReadOnlyInfo
                label="Telefone"
                value={contactMask(values.responsible?.phone[0].phone)}
              />
              <FieldReadOnlyInfo
                label="Telefone"
                value={
                  contactMask(values.responsible?.phone[1]?.phone) || undefined
                }
                optional
              />
            </div>

            <div className="w-1/2 space-y-4">
              <FieldReadOnlyInfo
                label="Ramal"
                value={values.responsible?.phone[0].branchLine}
                optional
              />
              <FieldReadOnlyInfo
                label="Ramal"
                value={values.responsible?.phone[1]?.branchLine}
                optional
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewFormCompanyData };
