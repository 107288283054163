import * as Yup from 'yup';
import { yupValidations } from 'utils/validations/yup';

const FormCompanyDataSchema = Yup.object().shape({
  fantasyName: yupValidations.requiredString,
  name: yupValidations.requiredString,
  cnpj: yupValidations.cnpj,

  responsible: Yup.object().shape({
    name: yupValidations.requiredString,
    adjutancy: yupValidations.requiredString,
    email: yupValidations.requiredEmail,
  }),
});

const FormCompanyPaymentSchema = Yup.object().shape({
  contract: Yup.object().shape({
    amount: Yup.string().required('Campo obrigatório'),
    start: Yup.date().required('Campo obrigatório'),
    end: Yup.date()
      .min(
        Yup.ref('start'),
        'A data final não pode ser antes da data de início'
      )
      .required('Campo obrigatório'),
  }),
  sla: Yup.object().shape({
    cancellationFeeUpTo6h: yupValidations.requiredNumber.min(
      0,
      'O valor não pode ser negativo'
    ),
    cancellationFeeUpTo24h: yupValidations.requiredNumber.min(
      0,
      'O valor não pode ser negativo'
    ),
    cancellationMoreThen24h: yupValidations.requiredNumber.min(
      0,
      'O valor não pode ser negativo'
    ),
    extraCostPerKM: yupValidations.requiredString,
    kmIncluded: yupValidations.requiredNumber,
    monthlyPlatformCost: yupValidations.requiredString,
    obs: yupValidations.notRequiredString,
    recurrence: yupValidations.requiredString,
    startCalls: yupValidations.requiredNumber.min(
      0,
      'O valor não pode ser negativo'
    ),
    urgentCostPerCall: yupValidations.requiredNumber
      .min(0, 'O valor não pode ser negativo')
      .max(100, 'Esse valor não pode ser maior que 100'),
    unproductiveCostPerCall: yupValidations.unproductiveCostPerCall,
  }),
});

const FormTypesOfServiceCompanySchema = Yup.object({
  serviceType: Yup.array().min(1),
});

export {
  FormCompanyDataSchema,
  FormCompanyPaymentSchema,
  FormTypesOfServiceCompanySchema,
};
