import styled from 'styled-components';

export const StatusButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.5rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ItemDetailText = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;