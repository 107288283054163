import { Typography } from 'components/Typography';
import { AssigneeItem } from './AssigneeItem';
import { contactGray, emailGray, phoneGray } from 'assets/images/icons';

interface AssigneeCardProps {
  assignee: {
    name: string;
    phone: string;
    email: string;
  };
}

const AssigneeCard = ({
  assignee: { name, email, phone },
}: AssigneeCardProps) => {
  return (
    <div>
      <Typography variant="p1" color="darkerGray">
        Responsável
      </Typography>
      <div className="p-2 rounded-lg bg-lightGray">
        <AssigneeItem sourceIcon={contactGray} title={name} altText="contato" />
        <AssigneeItem sourceIcon={phoneGray} title={phone} altText="telefone" />
        <AssigneeItem sourceIcon={emailGray} title={email} altText="email" />
      </div>
    </div>
  );
};

export { AssigneeCard };
