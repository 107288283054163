import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 46px;
  flex: 1;
  min-width: 222px;
  white-space: nowrap;
  z-index: 999;
`;

export const Top = styled.section`
  height: 56px;
  background-color: ${({ theme }) => theme.colors.darkerGray};

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 16px;
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.h3};
  color: ${({ theme }) => theme.colors.lighterGray};
  padding: 4px 0;
`;

export const Footer = styled.div`
  min-height: 36px;
  height: auto;
  padding: 16px;

  background-color: ${({ theme }) => theme.colors.darkestGray};

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const Content = styled.div``;

export const Space = styled.div`
  height: 8px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
