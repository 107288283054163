import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

import { Subtitle } from './styles';

import { useCallback } from 'react';
import { disableCollaborator } from 'assets/images/illustrations';
export interface DisableCollaboratorModalProps {
  close: () => void;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
}

const DisableCollaborator = ({
  close,
  onConfirm,
  isLoading,
}: DisableCollaboratorModalProps) => {
  const handleClick = useCallback(async () => {
    await onConfirm();
    close();
  }, []);

  return (
    <div className="flex flex-col items-center max-w-xl text-center">
      <img src={disableCollaborator} alt="icon disable" />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Desativar colaborador
      </Typography>
      <Subtitle variant="h4" color="darkBlue" className="mb-8">
        Você está desativando o perfil de um colaborador do sistema. Após
        confirmar, o perfil será desativado e não terá mais acesso à plataforma.
      </Subtitle>
      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={close}
        >
          Não, Voltar
        </Button>
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={() => handleClick()}
          disabled={isLoading}
        >
          Desativar
        </Button>
      </div>
    </div>
  );
};

export { DisableCollaborator };
