/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import { DownloadButton } from 'components/DownloadButton';
import { Typography } from 'components/Typography';
import {
  Card,
  Document,
  ButtonDownload,
  Container,
  ContainerSkeleton,
} from './style';
import { cnpjUpload } from 'assets/images/illustrations';

type DocumentType = {
  id: string;
  name: string;
  url: string;
};

interface DocumentListProps {
  documents: DocumentType[];
}

const DocumentsList = ({ documents }: DocumentListProps) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isLoading = setTimeout(() => {
      setIsLoading(false);
    }, 1200);
    return () => clearTimeout(isLoading);
  }, []);

  const renderURl = (url, name) => {
    if (url.endsWith('.pdf')) {
      return (
        <Document>
          <img src={cnpjUpload} alt={name} />
        </Document>
      );
    } else {
      return (
        <Document>
          <img src={url} alt={name} />
        </Document>
      );
    }
  };

  return (
    <>
      <Typography variant="h3" color="darkBLue">
        Documentação
      </Typography>

      {isLoading ? (
        <div className="flex flex-row gap-6">
          <ContainerSkeleton /> <ContainerSkeleton />
        </div>
      ) : (
        <Container>
          {documents === null || documents?.length <= 0 ? (
            <Typography variant="p1" color="darkestGray">
              Nenhum documento cadastrado
            </Typography>
          ) : (
            documents?.map(({ name, url, id }) => (
              <Card key={id}>
                {renderURl(url, name)}
                <ButtonDownload>
                  <a href={url} target="_blank" rel="noreferrer">
                    <DownloadButton>Download</DownloadButton>
                  </a>
                </ButtonDownload>
              </Card>
            ))
          )}
        </Container>
      )}
    </>
  );
};

export { DocumentsList };
