import styled, { css } from 'styled-components';
import type { IconButtonProps } from '.';

export const Button = styled.button<IconButtonProps>`
  background-color: ${({ filled, theme }) =>
    filled ? theme.colors.lightGray : theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: ${({ size }) => {
    switch (size) {
      case 'small':
        return '12px';
      case 'medium':
        return '16px';
      case 'large':
        return '16px';
    }
  }};
  box-sizing: border-box;
  color: ${({ theme }) => theme.typography.darkBlue};
  font-size: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return theme.typography.button.fontSize;
      case 'medium':
        return theme.typography.button.fontSize;
      case 'large':
        return theme.typography.tag.fontSize;
    }
  }};
  font-weight: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return theme.typography.button.fontWeight;
      case 'medium':
        return theme.typography.button.fontWeight;
      case 'large':
        return theme.typography.tag.fontWeight;
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '40px';
      case 'small':
        return '48px';
      case 'large':
        return '108px';
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '8px';
      case 'medium':
        return '8px';
      case 'large':
        return '24px';
    }
  }};
  width: ${({ width }) => (width ? width + 'px' : 'auto')};
  display: inline-flex;
  align-items: center;
  ${({ disabled, theme, color }) =>
    !disabled
      ? css`transition: 0.2s;
      &:hover {
        color: ${theme.colors[color]};
        border: 2px solid ${theme.colors[color]};
      }
      &:active {
        transform: scale(0.9);
      }
      &:active, &:focus {
        outline: 0;
      }`
      : `cursor: not-allowed`};
`;

export const IconContainer = styled.div<IconButtonProps>`
  background-color: ${({ theme }) => theme.colors.purple};
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '24px';
      case 'medium':
        return '32px';
      case 'large':
        return '32px';
    }
  }};
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '24px';
      case 'medium':
        return '32px';
      case 'large':
        return '32px';
    }
  }};
  margin-right: ${({ size }) => {
    switch (size) {
      case 'small':
        return '8px';
      case 'medium':
        return '8px';
      case 'large':
        return '24px';
    }
  }};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const Icon = styled.img`
  height: 12px;
  width: 12px;
`;
