import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.orangeClick};
  font-family: Asap;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.colors.darkerGray};
  font-family: Asap;
  font-size: 14px;
  font-weight: 500;
`;

export const Input = styled.textarea<{
  invalid: boolean;
  height: string;
  width: string;
  readOnly: boolean;
}>`
  resize: none;
  padding: 16px;
  outline: none !important;
  color: ${(props) =>
    props.readOnly
      ? props.theme.colors.darkerGray
      : props.theme.colors.darkBlue};
  border: 2px solid
    ${(props) =>
      props.invalid
        ? props.theme.colors.orangeClick
        : props.theme.colors.lightGray};
  border-radius: 16px;
  font-family: Asap;
  font-size: 14px;
  font-weight: 500;
  height: ${(props) => (props.height ? props.height : '104px')};
  padding-left: 16px;
  width: ${(props) => (props.width ? props.width : '292px')};

  :focus {
    border: 2px solid
      ${(props) => {
        if (props.readOnly) {
          return props.theme.colors.lightGray;
        } else {
          return props.invalid ? props.theme.colors.orangeClick : props.theme.colors.lightGray;
        }
      }
    };
  };

  ::placeholder {
    color: ${(props) => props.theme.colors.darkGray};
  }
`;
