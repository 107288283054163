import React from 'react';
import { Typography } from 'components/Typography';
import { ReviewFileData, ReviewFileItem } from './ReviewFileItem';

import { Container, Content } from './styles';

interface ReviewFilesProps {
  files: ReviewFileData[];
}

export const ReviewFiles: React.FC<ReviewFilesProps> = ({ files }) => {
  return (
    <Container>
      <Typography variant="p2" color="darkestGray">
        Anexos:
      </Typography>
      <Content>
        {
          files.map(file => (
            <ReviewFileItem
              key={file.image}
              file={file}
            />
          ))
        }
      </Content>
    </Container>
  );
}
