import { Typography } from 'components/Typography';

interface ViewCanBuyPartsProps {
  canBuyParts: boolean;
}

const ViewCanBuyParts = ({ canBuyParts }: ViewCanBuyPartsProps) => {
  return (
    <div className="space-y-2">
      <Typography variant="h3" color="darkBLue">
        Disponibilidade para compras de peças
      </Typography>
      <Typography variant="p1" color="darkerGray">
        {canBuyParts ? 'Sim' : 'Não'}
      </Typography>
    </div>
  );
};

export { ViewCanBuyParts };
