import styled, { css } from 'styled-components';

interface ContainerProps {
  active: boolean;
}

export const Container = styled.li<ContainerProps>`
  cursor: pointer;
  border-bottom: 2px solid ${props => props.theme.colors.lightGray};

  padding: 16px 0;

  &:first-of-type {
    padding: 0 0 16px 0;
  }

  &:last-of-type {
    padding: 16px 0 0 0;
    border-bottom: none;
  }

  ${props => props.active && css`
    background: rgba(128, 107, 255, 0.05);
  `}

  &:hover {
    background: rgba(128, 107, 255, 0.05);
  }
`;
