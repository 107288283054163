import { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from 'components/Typography';
import {
  Container,
  MinutePickerContainer,
  MinutePickerItem,
  MinutePickerListContainer,
} from './styles';
import { useClickOutside } from 'hooks/useClickOutside';
import { inactiveDropdown } from 'assets/images/icons';

interface MinutePickerProps {
  value?: number;
  onChange: (minute: number, MinuteFormatted: string) => void;
  disabled?: boolean;
}

const MinutePicker = ({
  onChange,
  disabled = false,
  value,
}: MinutePickerProps) => {
  const [open, setOpen] = useState(false);
  const [timeSelected, setTimeSelected] = useState<number>(value);
  const selectRef = useRef<HTMLDivElement>(null);
  const minutesList = Array.from({ length: 12 }, (_, i) => i * 5);

  useClickOutside({
    action: () => setOpen(false),
    ref: selectRef,
  });

  const formatTime = (time: number) => {
    return `${String(time).padStart(2, '0')}min`;
  };

  const onSelectItem = useCallback(
    (time: number) => {
      setTimeSelected(time);
      onChange(time, formatTime(time));
    },
    [onChange, formatTime]
  );

  const handleClick = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (value) {
      setTimeSelected(value);
    }
  }, [value]);

  return (
    <Container>
      <MinutePickerContainer isOpen={open} onClick={handleClick}>
        <Typography
          variant="button"
          color="darkerGray"
          data-testid="minutepicker"
        >
          {timeSelected !== undefined ? `${formatTime(timeSelected)}` : `xxxx`}
        </Typography>
        <img src={inactiveDropdown} alt="inactive" />
      </MinutePickerContainer>
      <MinutePickerListContainer isOpen={open}>
        {minutesList.map((time) => (
          <MinutePickerItem
            active={time === timeSelected}
            key={time}
            onClick={() => onSelectItem(time)}
          >
            {formatTime(time)}
          </MinutePickerItem>
        ))}
      </MinutePickerListContainer>
    </Container>
  );
};

export { MinutePicker };
