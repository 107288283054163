import { inactiveLink } from 'assets/images/icons';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { whatsAppMessageToTechnician } from 'utils/whatsAppUtils';

const NewMessageCard = ({ modelId, callId }) => {
  return (
    <div className="flex flex-col p-4 space-y-2 border-2 border-lightGray rounded-xl">
      <Typography variant="h3" color="darkerGray">
        Conversar com profissional
      </Typography>
      <Typography variant="p2" color="darkerGray">
        Utilizamos uma plataforma parceira para gerenciar toda a comunicação com
        nossos profissionais. Você será redirecionado ao clicar no botão abaixo.
      </Typography>
      <div className="flex justify-end space-x-4">
        <Button
          onClick={() => whatsAppMessageToTechnician(modelId, callId)}
          type="button"
          variant="outlined"
          className="flex self-end w-[213px] gap-2 items-center"
        >
          Mensagem
          <img className="flex" src={inactiveLink} />
        </Button>
      </div>
    </div>
  );
};

export default NewMessageCard;
