import styled from 'styled-components';

export const Main = styled.div`
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  min-height: 761px;
  max-height: min-content;
  gap: 28px;
`;
export const Border = styled.div`
  width: 100%;
  height: 145px;
  border-radius: 6px;
  border: 2px solid ${(props) => props.theme.colors.lighterGray};

  img {
    width: 100%;
    height: 145px;
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Label = styled.div`
  position: absolute;
  right: 3%;
  bottom: 2%;
`;

export const Container = styled.div`
  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.section`
  display: flex;
  flex-direction: column;
  > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
`;
