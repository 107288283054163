import { Typography } from 'components/Typography';
import { useState } from 'react';
import { ButtonTypeNewCall } from './ButtonTypeNewCall';
import { FormAboutTheCall } from './FormAboutTheCall';

interface FormAboutTheCallProps {
  isNewTemplate?: boolean;
}

const AboutCall = ({ isNewTemplate }: FormAboutTheCallProps) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <div className="flex flex-col p-6 space-y-6 border-2 rounded-lg border-lightGray">
      <div>
        <Typography variant="h2" color="darkestGray">
          Solicitação de agendamento
        </Typography>
        <Typography variant="p1" color="darkerGray">
          Chamados urgentes são aqueles que devem ser atendidos de forma prioritária e seguirão as regras de SLA de atendimento e taxa de cobrança adicional. Chamados agendados são aqueles que possuem maior flexibilidade de tempo para atendimento, onde você pode escolher a melhor data e hora para o agendamento.
        </Typography>
      </div>

      <ButtonTypeNewCall disabled={disabled} />
      <FormAboutTheCall
        setDisabled={setDisabled}
        isNewTemplate={isNewTemplate}
      />
    </div>
  );
};

export { AboutCall };
