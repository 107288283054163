export const ENGLISH_LEVEL = [
  {
    value: 'BASIC',
    label: 'Básico',
    description:
      'Consigo ouvir, ler e escrever com a ajuda de um tradutor ou dicionário',
  },
  {
    value: 'INTERMEDIARY',
    label: 'Intermediário',
    description: 'Consigo ler e escrever, mas tenho dificuldades em conversar',
  },
  {
    value: 'ADVANCED',
    label: 'Avançado',
    description: 'Compreendo, me comunico e escrevo bem',
  },
  {
    value: 'NEUTRAL',
    label: 'Neutro',
    description: 'Não possuo conhecimento em inglês',
  },
];

export enum ENGLISH_LEVEL_TRANSLATE {
  BASIC = 'Básico',
  INTERMEDIARY = 'Intermediário',
  ADVANCED = 'Avançado',
  NEUTRAL = 'Neutro',
}

export const translateEnglishLevel = (englishLevel: string) => {
  if (!englishLevel) {
    return 'Não informado!';
  }

  const englishLevelFormatted =
    ENGLISH_LEVEL_TRANSLATE[englishLevel.toUpperCase()];
  if (!englishLevelFormatted) {
    return 'Nível de inglês não encontrado!';
  }

  return englishLevelFormatted;
};
