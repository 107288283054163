import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { call } from 'assets/images/illustrations';
export interface ConfirmCompanyModalProps {
  onConfirm: () => void;
  close: () => void;
  email: string;
}

const ConfirmCompanyModal = ({
  onConfirm,
  email,
  close,
}: ConfirmCompanyModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    close();
  };

  return (
    <div className="flex flex-col items-center w-full text-center">
      <img src={call} className="h-[160px] w-[160px] mb-10" alt="icon call" />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Confirmar cadastro de novo cliente
      </Typography>
      <Typography variant="h4" color="darkBlue" className="">
        Você está confirmando o cadastro deste cliente.
        <br /> Após a confirmação, será enviado um convite para:
      </Typography>
      <Typography variant="h4" color="darkBlue" className="">
        {email}
      </Typography>
      <Typography variant="h4" color="darkBlue" className="">
        com o convite para acessar a plataforma.
      </Typography>
      <div className="flex flex-row gap-6 mt-10">
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={close}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </div>
    </div>
  );
};

export { ConfirmCompanyModal };
