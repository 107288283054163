/* eslint-disable */
import { Tabs } from 'components/Tabs';
import { useState, useEffect, useRef } from 'react';
import { Container } from './styles';
import { IconTab } from 'components/Tabs/IconTab';
import { CallReviewTab } from './CallReviewTab';
import { TimeLine } from './TimeLine';
import { CallAlertsTab } from './CallAlertsTab';
import CallsMap from 'components/Dashboards/CallsMap';
import { useCall } from 'queries/call';
import {
  activeReviewCall,
  inactiveServiceOrder,
  sidebarActiveNotification as activeNotification,
} from 'assets/images/icons';

export interface AlertCallSidesheetProps {
  id?: string;
  callId: string;
  close: () => void;
}

export const AlertCallSidesheet = ({ callId }: AlertCallSidesheetProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [cityFilterId, setCityFilterId] = useState();
  const [callSheet, setCallSheet] = useState({ open: false, id: null });

  const { call } = useCall(callId);

  const mapRef = useRef<any>();

  const INITIAL_TAB = 0;

  useEffect(() => {
    setCurrentTab(INITIAL_TAB);
  }, [INITIAL_TAB]);

  const center = {
    lat: -22.9527979,
    lng: -42.037023917,
  };

  function setMapCenter(lat: number | string, lng: number | string) {
    if (!mapRef.current) return;

    mapRef.current?.setZoom(12);
    mapRef.current?.panTo(
      // @ts-ignore
      new window.google.maps.LatLng(lat, lng)
    );
    // when centering the map in the marker,
    // move the center to the side a bit so the marker won't be behind the sheet
    mapRef.current?.panBy(-250, 0);
  }
  async function handleOpenCall(data) {
    setMapCenter(
      data.address?.lat || data.city?.lat,
      data.address?.lng || data.city?.lng
    );
  }

  useEffect(() => {
    handleOpenCall(call);
  }, [callId]);

  function handleCloseCallSidesheet() {
    setCallSheet((e) => ({ ...e, open: false }));
  }

  function openCallSidesheet(id: string) {
    setCallSheet({ id, open: true });
  }

  return (
    <div className="flex w-full">
      <Container className="flex flex-col w-1/2 min-w-[720px] max-h-screen space-y-4 overflow-y-auto">
        <div className="w-full px-4 bg-lighterGray">
          <Tabs
            data={[
              {
                title: 'Revisão de Chamado',
                iconSelected: activeReviewCall,
                iconUnselected: inactiveServiceOrder,
              },
              {
                title: 'Alertas',
                iconSelected: activeNotification,
                iconUnselected: inactiveServiceOrder,
              },
            ]}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            renderItem={IconTab}
          />
        </div>
        {currentTab === 0 ? (
          <CallReviewTab callId={callId} />
        ) : (
          <CallAlertsTab callId={callId} />
        )}
      </Container>
      <div className="relative w-1/2 h-full">
        <CallsMap
          onMapLoaded={({ map }) => {
            mapRef.current = map;
          }}
          setCityFilter={(city) => {
            setCityFilterId(city?.ibge_cod || null);
          }}
          cityFilterId={cityFilterId}
          center={center}
          callSheet={callSheet}
          openCallSidesheet={openCallSidesheet}
          closeSidesheet={handleCloseCallSidesheet}
        />
        <div className="absolute top-0 left-0 flex flex-col px-6 py-4 m-4 space-y-2 bg-white border-2 rounded-xl border-gray">
          <TimeLine callId={callId} />
        </div>
      </div>
    </div>
  );
};
