import { useMemo, useState } from 'react';
import { DropdownMonths } from 'components/DropdownMonths';
import { SheduleCanceledCalls } from './SheduleCanceledCalls';
import { SheduleFinishedCalls } from './SheduleFinishedCalls';
import { ShedulePendingCalls } from './ShedulePendingCalls';
import { TabsByStatusTechnicianCallHistory } from '../TabsByStatusTechnicianCallHistory';

type selectedMonthType = {
  id: number;
  value: number;
  name: string;
};
interface CardTechnicianCallHistoryProps {
  idTechnician: string;
  selectedMonth: selectedMonthType;
  setSelectedMonth: (e: selectedMonthType) => void;
}

const CardTechnicianCallHistory = ({
  idTechnician,
  selectedMonth,
  setSelectedMonth,
}: CardTechnicianCallHistoryProps) => {
  const [numTab, setNumTab] = useState(0);

  const renderTabSelected = useMemo(() => {
    const tabs = {
      0: (
        <SheduleFinishedCalls
          idTechnician={idTechnician}
          selectedMonthNumber={selectedMonth?.value}
        />
      ),
      1: (
        <ShedulePendingCalls
          idTechnician={idTechnician}
          selectedMonthNumber={selectedMonth?.value}
        />
      ),
      2: (
        <SheduleCanceledCalls
          idTechnician={idTechnician}
          selectedMonthNumber={selectedMonth?.value}
        />
      ),
    };

    return tabs[numTab] || tabs[0];
  }, [idTechnician, numTab, selectedMonth?.value]);

  return (
    <div>
      <TabsByStatusTechnicianCallHistory onClick={(tab) => setNumTab(tab)}>
        <DropdownMonths
          onChange={(e) => setSelectedMonth(e)}
          selected={selectedMonth}
        />
        {renderTabSelected}
      </TabsByStatusTechnicianCallHistory>
    </div>
  );
};
export { CardTechnicianCallHistory };
