import { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from 'components/Typography';
import { useClickOutside } from 'hooks/useClickOutside';
import { inactiveDropdown } from 'assets/images/icons';
import { Label } from 'components/Inputs/TextField/styles';
import axios from 'axios';

interface DropDownStateProps {
  value?: string;
  onChange?: (state: string) => void;
  disabled?: boolean;
}

interface IStatesList {
  id: number;
  sigla: string;
}

const DropDownState = ({ onChange, value }: DropDownStateProps) => {
  const [open, setOpen] = useState(false);
  const [stateSelected, setStateSelected] = useState<string>(value);
  const selectRef = useRef<HTMLDivElement>(null);
  const [statesList, setStatesList] = useState<IStatesList[]>([]);

  useClickOutside({
    action: () => setOpen(false),
    ref: selectRef,
  });

  const listStates = async () => {
    const response = await axios.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
    );
    const sortingByInitials = response.data.sort((a, b) => {
      const fa = a.sigla;
      const fb = b.sigla;
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setStatesList(sortingByInitials);
  };

  useEffect(() => {
    listStates();
  }, []);

  const onSelectItem = useCallback(
    (state: string) => {
      setStateSelected(state);
      onChange(state);
    },
    [onChange]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (value) {
      setStateSelected(value);
    }
  }, [value]);

  return (
    <section className="flex flex-col relative h-full gap-1">
      <Label>Estado</Label>
      <button
        type="button"
        onClick={handleOpen}
        className="flex justify-center items-center cursor-pointer px-4 border-2 h-12 border-gray rounded-2xl"
      >
        <Typography variant="button" color="darkerGray">
          {stateSelected ? stateSelected : `UF`}
        </Typography>
        <img
          className={`ml-2.5 transition-all duration-500 ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
          src={inactiveDropdown}
          alt="inactive"
        />
      </button>
      <ul
        className={`${
          open ? 'block' : 'hidden'
        } scrollbar absolute z-10 max-h-48 list-none p-4 top-20 overflow-y-auto
         bg-white  w-full border-2 rounded-lg border-gray `}
      >
        {statesList.map((state) => (
          <li
            onClick={() => onSelectItem(state.sigla)}
            key={state.id}
            className={`flex items-center justify-center cursor-pointer py-4 border-b-2 border-lightGray hover:bg-[#f9f7ff] ${
              state.sigla === stateSelected && 'bg-[#f9f7ff]'
            }`}
          >
            {state.sigla}
          </li>
        ))}
      </ul>
    </section>
  );
};

export { DropDownState };
