import useUser, { IUserToken } from 'hooks/useUser';
import { rolesPermissionsModules } from 'utils/security/rolesPermission';
import { RolesType } from 'utils/security/root';

export function usePermissions(contextUser: IUserToken = null) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = contextUser ? contextUser : useUser();

  function getMyPermissions() {
    if (!user?.role) {
      return null;
    }

    const role: RolesType = String(user?.role).toUpperCase() as RolesType;
    const { permissions } = rolesPermissionsModules[role];

    return permissions;
  }

  function getUserPagesAvailable() {
    const permissions = getMyPermissions();
    const userType = user?.type;

    const pathsAvailable = [];

    if (permissions?.CALL.actions.length > 0) {
      pathsAvailable.push('/calls');
    }

    if (permissions?.FINANCIAL.actions.length > 0 ) {
      pathsAvailable.push('/finances');
    }

    if (permissions?.CLIENT.actions.length > 0 && userType === 'EuNerd') {
      pathsAvailable.push('/clients');
    }

    if (permissions?.TECHNICIAN.actions.length > 0 && userType === 'EuNerd') {
      pathsAvailable.push('/technicians');
    }

    if (permissions?.PLATFORM.actions.length > 0) {
      pathsAvailable.push('/preferences');
    }  

    return pathsAvailable;
  }

  return {
    role: user?.role,
    permissions: getMyPermissions(),
    pathsAvailable: getUserPagesAvailable(),
  };
}
