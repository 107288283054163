import { Container, DatesContainer, DayItem, DaysContainer } from './styles';
import { eachDayOfInterval, format, lastDayOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { Typography } from 'components/Typography';
import { groupBy } from 'utils/queryUtils';
import { INDEX_MONTHS } from 'utils/periodsQuery';

interface ServiceOrderDaysProps {
  startTime: Date;
  finalTime: Date;
}

type ServiceDate = {
  year: number;
  month: number;
  date: Date;
  selected: boolean;
};

const ServiceOrderDays = ({ finalTime, startTime }: ServiceOrderDaysProps) => {
  const data: Record<string, ServiceDate[]> = useMemo(() => {
    const days = [];
    const startDate = new Date(startTime);
    const endDate = new Date(finalTime);

    const initialDaysNotSelected = eachDayOfInterval({
      start: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end: new Date(startTime),
    }).map((date: Date) => ({ selected: false, date: date }));

    const selectedDays = eachDayOfInterval({
      start: new Date(startTime),
      end: new Date(finalTime),
    }).map((date: Date) => ({ selected: true, date: date }));

    const lastDay = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      lastDayOfMonth(endDate).getDate()
    );

    const finalDaysNotSelected = eachDayOfInterval({
      start: new Date(finalTime),
      end:
        lastDay.getDate() === endDate.getDate() ? new Date(endDate) : lastDay,
    }).map((date: Date) => ({ selected: false, date: date }));

    initialDaysNotSelected.pop();
    finalDaysNotSelected.shift();

    days.push(...initialDaysNotSelected);
    days.push(...selectedDays);
    days.push(...finalDaysNotSelected);

    const dateObject = days.map((day) => ({
      year: day.date.getFullYear(),
      month: day.date.getMonth(),
      date: day.date,
      selected: day.selected,
    }));

    return groupBy(dateObject, 'month');
  }, [finalTime, startTime]);

  return (
    <Container>
      <Typography variant="h2" color="darkestGray">
        Dias do chamado
      </Typography>
      <DatesContainer>
        {Object.keys(data).length > 0 ? (
          Object.keys(data).map((month) => (
            <div key={month}>
              <Typography variant="h3" color="darkestGray">
                {`${INDEX_MONTHS[month]} ${data[month][0].year}`}:
              </Typography>
              <DaysContainer>
                {data[month].map(({ date, selected }) => (
                  <DayItem key={date.toISOString()} selected={selected}>
                    <p>{format(date, 'dd')}</p>
                  </DayItem>
                ))}
              </DaysContainer>
            </div>
          ))
        ) : (
          <Typography variant="h3" color="darkestGray">
            Nenhuma data disponível
          </Typography>
        )}
      </DatesContainer>
    </Container>
  );
};

export { ServiceOrderDays };
