import { Typography } from 'components/Typography';
import { useState } from 'react';
import { Container, Image, Status } from './styles';

type SizeType = 'small' | 'medium' | 'large';

export interface AvatarProps {
  name?: string;
  photoURL: string;
  size?: SizeType;
  status?: string;
  longName?: string;
}

const Avatar = ({
  name = '',
  photoURL,
  size = 'small',
  status = 'none',
  longName,
}: AvatarProps) => {
  const [error, setError] = useState(false);
  return (
    <>
      <Container size={size}>
        {photoURL && !error ? (
          <Image
            src={photoURL}
            onError={() => setError(true)}
            data-testid="avatar-image"
            alt="Imagem de perfil"
          />
        ) : (
          <Typography variant="p2" color="darkerGray">
            {name
              .match(/(\b\S)?/g)
              .join('')
              .match(/(^\S|\S$)?/g)
              .join('')
              .toUpperCase()}
          </Typography>
        )}
        {status !== 'none' && <Status photoURL={photoURL} status={status} />}
      </Container>

      {longName && (
        <div className="w-full capitalize">
          <Typography variant="h2" color="darkestGray">
            {longName}
          </Typography>
        </div>
      )}
    </>
  );
};

export { Avatar };
