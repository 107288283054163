import styled from 'styled-components';

export const ButtonNewItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 32px;
  outline: none !important;
  width: fit-content;
  margin-left: 40px;
  background: #f8f8f8;
  border: 2px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.lightGray : theme.colors.lighterGray};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  transform: border-color 0.2s;
  &:hover,
  &:active {
    border: 2px solid
      ${({ theme, disabled }) =>
        disabled ? theme.colors.lighterGray : theme.colors.purple};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const IconPlusNewItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.lightGray};
`;
