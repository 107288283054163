import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCall } from 'queries/call';
import { AttachmentCard } from 'components/AttachmentCard';
import { Typography } from 'components/Typography';

import {
  ServiceOrderSummary,
  CompanyCard,
  TechnicianCard,
  ButtonLinkTechnician,
  SearchingCard,
  StatusCard,
  CancelSection,
} from 'features/Call/components';
import { LoadingSidesheet } from 'components/LoadingSidesheet';
import useUser from 'hooks/useUser';

interface ServiceOrderTabProps {
  callId: number;
  close?: () => void;
}

const ServiceOrderTab = ({ callId, close }: ServiceOrderTabProps) => {
  const { call, refetch, isLoading } = useCall(callId);
  const user = useUser();

  const canCancel = [
    'LOOKING_FOR_PROFESSIONAL',
    'OPERATIONS_LOOKING_FOR_PROFESSIONAL',
    'PROFESSIONAL_NOT_FOUND',
    'SCHEDULED_CALL',
    'WAITING_TECHNICIANS_ACCEPTANCE',
    'DELAYED_CALL',
    'REQUESTED_CANCELLATION',
    'ON_THE_WAY',
    'SUPPORT_STARTED',
    'CALL_REFUSED_BY_TECHNICIAN',
  ].includes(call?.status);

  const canUnbind = [
    'PROFESSIONAL_NOT_FOUND',
    'SCHEDULED_CALL',
    'WAITING_TECHNICIANS_ACCEPTANCE',
    'DELAYED_CALL',
    'CALL_REFUSED_BY_TECHNICIAN',
  ].includes(call?.status);

  if (isLoading) {
    return <LoadingSidesheet />;
  }

  return (
    <>
      <div className="px-6 py-4 space-y-4">
        <ServiceOrderSummary callId={callId} withCalendar />

        <Typography variant="h2" color="darkestGray">
          Cliente
        </Typography>
        <CompanyCard id={call?.companyId} callId={call?.id} />

        <Typography variant="h2" color="darkestGray">
          Profissional
        </Typography>

        {call?.callToTechnician?.length > 0 ? (
          <>
            <TechnicianCard
              id={call?.callToTechnician[0]?.technicianId}
              callId={call?.id}
            />
            {call?.callToTechnician[0]?.technicianId &&
              canUnbind &&
              user.type === 'EuNerd' && (
                <ButtonLinkTechnician
                  callId={call?.id}
                  companyId={call?.companyId}
                  refetch={refetch}
                />
              )}
          </>
        ) : (
          <SearchingCard {...call} />
        )}

        <Typography variant="h2" color="darkestGray">
          Status
        </Typography>
        <StatusCard
          status={call?.status}
          time={formatDistanceToNow(
            new Date(
              call?.callLog?.[call?.callLog?.length - 1]?.createdAt || null
            ),
            { locale: ptBR, addSuffix: true }
          )}
        />

        <Typography variant="h2" color="darkestGray">
          Descrição
        </Typography>
        <div className="flex flex-col p-4 space-y-4 border-2 rounded-xl border-lightGray">
          <Typography variant="p1" color="darkerGray">
            {call?.serviceDetail}
          </Typography>
        </div>
        {Boolean(call?.attachments?.length) && (
          <>
            <Typography variant="h2" color="darkestGray">
              Anexos
            </Typography>
            {call?.attachments?.map((a) => {
              return (
                <AttachmentCard
                  key={a.id}
                  name={a.name}
                  description={a.description}
                  handleDownload={() => window.open(a.url)}
                />
              );
            })}
          </>
        )}
      </div>
      {canCancel && <CancelSection callId={callId} close={close} />}
    </>
  );
};

export { ServiceOrderTab };
