import {
  HTMLAttributes,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Typography } from 'components/Typography';
import { Container, SelectContainer, Display } from './styles';
import { SearchContainer } from './SearchContainer';
import { useClickOutside } from 'hooks/useClickOutside';
import { SearchOptionItem } from './SearchContainer/SearchOption';
import { cancelSearch, inactiveDropdown } from 'assets/images/icons';

interface SelectSearchProps extends HTMLAttributes<HTMLDivElement> {
  data: SearchOptionItem[];
  placeholder?: string;
  getItemSelected?: (item: SearchOptionItem) => void;
  disabled?: boolean;
  value?: string;
  serviceIdSelected?: string;
  onRemove?: () => void;
  nameSelected?: string;
}

const SelectSearch = ({
  data,
  placeholder,
  getItemSelected,
  disabled,
  value,
  serviceIdSelected,
  onRemove,
  nameSelected,
  ...rest
}: SelectSearchProps) => {
  const [openSelect, setOpenSelect] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const [itemSelected, setItemSelected] = useState<SearchOptionItem>();

  useClickOutside({
    action: () => setOpenSelect(false),
    ref: selectRef,
  });

  const onGetItemSelected = useCallback(
    (item: SearchOptionItem) => {
      setItemSelected(item);
      getItemSelected && getItemSelected(item);
      setOpenSelect(false);
    },
    [getItemSelected]
  );

  const setSelectedItemByCompany = useCallback((data) => {
    if (data?.company?.name) {
      return setItemSelected(data.company);
    }
  }, []);

  useEffect(() => setSelectedItemByCompany(data), [data]);

  useEffect(() => {
    if (value) {
      const findItem = data.find((item) => item.id === String(value));
      if (findItem) {
        onGetItemSelected(findItem);
      }
    }
  }, [value, onGetItemSelected]);

  useEffect(() => {
    if (serviceIdSelected === '') {
      setItemSelected(undefined);
    }
  }, [serviceIdSelected]);

  return (
    <Container ref={selectRef}>
      <SelectContainer
        isOpen={openSelect}
        {...rest}
        onClick={() =>
          disabled ? setOpenSelect(false) : setOpenSelect(!openSelect)
        }
        disabled={disabled}
        role="button"
      >
        <Display data-testid="display">
          {itemSelected && !disabled && (
            <img
              src={cancelSearch}
              alt="cancel search"
              data-testid="cancel-search"
              onClick={
                onRemove
                  ? () => {
                      onRemove();
                      setItemSelected(undefined);
                    }
                  : () => setItemSelected(undefined)
              }
            />
          )}
          {nameSelected ? (
            <Typography variant="button" color="darkerGray">
              {nameSelected}
            </Typography>
          ) : (
            <Typography variant="button" color="darkerGray">
              {itemSelected ? itemSelected.name : placeholder}
            </Typography>
          )}
        </Display>

        <img src={inactiveDropdown} />
      </SelectContainer>
      <SearchContainer
        isOpen={openSelect}
        data={data}
        getItemSelected={onGetItemSelected}
        itemSelected={itemSelected}
      />
    </Container>
  );
};

export { SelectSearch };
