/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatCurrency } from './formatCurrency';

export const groupItems = (arr: any, groupBy: string) =>
  arr.reduce((result: any, item: any) => {
    (result[item[groupBy]] = result[item[groupBy]] || []).push(item);
    return result;
  }, {});

export const formatCPF = (cpf: string) => {
  return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(
    6,
    9
  )}-${cpf.substring(9, 11)}`;
};

export const currencyMask = (value: string) => {
  let v = value.toString().replace(/\D/g, '');

  v = `${(+v / 100).toFixed(2)}`;

  return formatCurrency(currencyToFloat(v)).slice(3);
};

export const currencyConvert = (value: string) => {
  if (value === '') {
    return '';
  }
  let v = value.replace(/\D/g, '');
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  v = `${(+v / 100).toFixed(3)}`;
  return v;
};

export const formatHour = (hour: string) => {
  try {
    const formattedHour = new Date(hour)
      .toLocaleString('pt-BR', {
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      })
      .split(' ');
    const hora = formattedHour[5];

    return `${hora}h`;
  } catch (e) {
    return '00:00h';
  }
};

export const formatDate = (date: string) => {
  try {
    const formattedDate = date.split('-');
    const dia = formattedDate[2].substring(0, 2);
    const mes = formattedDate[1];
    const ano = formattedDate[0];
    return `${dia}/${mes}/${ano}`;
  } catch (e) {
    return '00/00/0000';
  }
};

export const formatHourAndDate = (date: string) => {
  try {
    const formattedDate = new Date(date)
      .toLocaleString('pt-BR', {
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      })
      .split(' ');
    const dia = formattedDate[0];
    const mes = formattedDate[2];
    const ano = formattedDate[4];
    const hora = formattedDate[5];

    return `${dia}/${mes}/${ano} - ${hora}h`;
  } catch (e) {
    return '00/00/0000';
  }
};

export const formatDateAndHour = (date: string) => {
  try {
    const formattedDate = new Date(date)
      .toLocaleString('pt-BR', {
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      })
      .split(' ');
    const dia = formattedDate[0];
    const mes = formattedDate[2];
    const ano = formattedDate[4];
    const hora = formattedDate[5];

    return `${hora}h - ${dia}/${mes}/${ano}`;
  } catch (e) {
    return '00/00/0000';
  }
};

export const formatBirthDate = (birthDate: string) => {
  return `${birthDate.substring(0, 2)}/${birthDate.substring(
    2,
    4
  )}/${birthDate.substring(4, 8)}`;
};

export const formatDateInText = (date: string) => {
  try {
    const formattedDate = date.split('-');

    const dia = formattedDate[2].substring(0, 2);
    const mes = formattedDate[1];
    const ano = formattedDate[0];

    let mesText;

    switch (mes) {
      case '01':
        mesText = 'Janeiro';
        break;
      case '02':
        mesText = 'Fevereiro';
        break;
      case '03':
        mesText = 'Março';
        break;
      case '04':
        mesText = 'Abril';
        break;
      case '05':
        mesText = 'Maio';
        break;
      case '06':
        mesText = 'Junho';
        break;
      case '07':
        mesText = 'Julho';
        break;
      case '08':
        mesText = 'Agosto';
        break;
      case '09':
        mesText = 'Setembro';
        break;
      case '10':
        mesText = 'Outubro';
        break;
      case '11':
        mesText = 'Novembro';
        break;
      case '12':
        mesText = 'Dezembro';
        break;
      default:
        break;
    }

    return `${dia} de ${mesText} de ${ano}`;
  } catch (e) {
    return '00/00/0000';
  }
};

export const currencyToFloat = (value) => {
  return parseFloat(
    String(value)
      .replace(/(,|\.)([0-9]{3})/g, '$2')
      .replace(/(,|\.)/, '.')
  );
};

export const formatUTCHour = (time) =>
  new Date(time || null).setHours(new Date(time || null).getUTCHours());

// TODO: HOMOLOG TÁ COM GETHOURS E NÃO GETUTCHOURS
