import { Typography } from 'components/Typography';
import {
  ChecklistCounter,
  CloseButton,
  Container,
  InputContainer,
  TimelineContainer,
  VerticalItemDivider,
} from './style';
import { closeHover } from 'assets/images/icons';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

const ChecklistItem = ({
  counterDisplays,
  placeholder = 'Descrição do item',
  value,
  onChange,
  removeItem,
  addNewItem,
  disabled = false,
  ...props
}) => {
  function onChangeKeyPress(e) {
    if (e.key === 'Enter' && e.target.value !== '') {
      if (addNewItem) {
        addNewItem();
      }
    }
  }

  const { attributes, listeners, setNodeRef, transition, transform } =
    useSortable({ id: props.id, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Container ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <TimelineContainer>
        <ChecklistCounter>
          <Typography variant="tag" color="purple">
            {counterDisplays}
          </Typography>
        </ChecklistCounter>
        <VerticalItemDivider />
      </TimelineContainer>

      <InputContainer>
        <input
          className="flex-1 mr-4"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyPress={onChangeKeyPress}
          autoFocus
        />

        <CloseButton type="button" onClick={() => removeItem(value)}>
          <img
            src={closeHover}
            alt="Icone de um x para deletar descrição adicionada"
          />
        </CloseButton>
      </InputContainer>
    </Container>
  );
};

export { ChecklistItem };
