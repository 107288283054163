import { CheckboxContainer, StyledCheckbox, Icon } from './styles';
import { Typography } from 'components/Typography';
import { checkedPurple } from 'assets/images/icons';

interface CheckboxProps {
  className?: string;
  state?: string[];
  setState: (value) => void;
  label?: string;
  multipleValues?: boolean;
  value: string | boolean;
}

const Checkbox = ({
  className,
  state,
  setState,
  label,
  multipleValues = false,
  value,
}: CheckboxProps) => {
  const checked = multipleValues
    ? state.includes(value as string)
    : value === true;

  const handleClick = () => {
    if (multipleValues) {
      if (checked) {
        setState(
          state.filter((s: string) => {
            return s !== value;
          })
        );
      } else {
        setState([value, ...state]);
      }
    } else {
      setState && setState(value);
    }
  };

  return (
    <CheckboxContainer className={className}>
      <StyledCheckbox
        data-testid="styledCheckbox"
        checked={checked}
        onClick={handleClick}
      >
        <Icon src={checkedPurple} alt="Ícone de verificar da cor roxa" />
      </StyledCheckbox>
      {label && (
        <Typography variant="p1" color="darkerGray" className="ml-3">
          {label}
        </Typography>
      )}
    </CheckboxContainer>
  );
};
export { Checkbox };
