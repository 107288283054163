import React from 'react';
import { Typography } from 'components/Typography';
import { Icon } from 'components/Icon';
import { greenTec } from 'assets/images/icons';

interface IconLabelProps {
  children: React.ReactNode;
  icon?: string;
  color?: string;
  outline?: boolean;
  selected?: boolean;
}

const IconLabel = ({
  children,
  icon = greenTec,
  color,
  outline = false,
  selected = false,
}: IconLabelProps) => {
  return (
    <div className="flex gap-2">
      <Icon name={icon} color={color} outline={outline} selected={selected} />
      <Typography variant="h3" color="darkerGray">
        {children}
      </Typography>
    </div>
  );
};

export { IconLabel };
