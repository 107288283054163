import { Typography } from 'components/Typography';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { ICollaborator } from 'types/collaborator';
import { AccessButtonItem } from './AccessButtonItem';

import { Container, Header, Content } from './styles';

interface AccessPermissionsProps {
  isVisualization?: boolean;
  data?: ICollaborator;
  isEdit?: boolean;
  onChange?: (role: string) => void;
}

const AccessPermissions = ({
  isVisualization = false,
  data,
  isEdit = false,
  onChange,
}: AccessPermissionsProps) => {
  const { setFieldValue } = useFormikContext();
  const [accessPermissionType, setAccessPermissionType] = useState('');

  const handleChangeAccessType = useCallback(
    (type: string) => {
      const role = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;

      setFieldValue('role', role);
      setAccessPermissionType(type);

      if (isEdit && onChange) {
        onChange(role);
      }
    },
    [isEdit, onChange, setFieldValue]
  );

  useEffect(() => {
    if (data) {
      setAccessPermissionType(
        data?.role === 'Other' ? 'operation' : data?.role.toLowerCase()
      );
    }
  }, [data]);

  return (
    <Container data-testid="access-permissions">
      <Header>
        <Typography variant="h2" color="darkBlue">
          Permissões de acesso
        </Typography>
        <Typography variant="p1" color="darkestGray" className="textSubtitle">
          Cada perfil possui um acesso diferente, de forma que você possa
          limitar o acesso de determinados usuários. Defina qual o perfil do seu
          colaborador.
        </Typography>
      </Header>
      <Content>
        <AccessButtonItem
          type="admin"
          title="Administrador"
          description="Acesso total a todas as funcionalidades da plataforma"
          selected={accessPermissionType === 'admin'}
          onClick={() => handleChangeAccessType('admin')}
          isVisualization={isVisualization}
          items={[
            {
              title: 'Incluindo alterações e configurações da ferramenta',
              type: 'available',
            },
          ]}
        />
        <AccessButtonItem
          type="operation"
          title="Operação"
          description="Acesso limitado a Gestão da Plataforma"
          selected={accessPermissionType === 'operation'}
          onClick={() => handleChangeAccessType('operation')}
          isVisualization={isVisualization}
          items={[
            {
              title: 'Visualiza',
              type: 'available',
            },
            {
              title: 'Gestão e cadastro de novos usuários',
              type: 'unavailable',
            },
            {
              title: 'Editar padrão visual da Plataforma',
              type: 'unavailable',
            },
          ]}
        />
        <AccessButtonItem
          type="financial"
          title="Financeiro"
          description="Acesso limitado a Gestão da Plataforma e Gestão de Técnicos"
          selected={accessPermissionType === 'financial'}
          onClick={() => handleChangeAccessType('financial')}
          isVisualization={isVisualization}
          items={[
            {
              title: 'Visualiza',
              type: 'available',
            },
            {
              title: 'Gestão e cadastro de novos usuários',
              type: 'unavailable',
            },
            {
              title: 'Editar padrão visual da Plataforma',
              type: 'unavailable',
            },
            {
              title: 'Cadastra / Aceitar / Recusar novos Técnicos',
              type: 'unavailable',
            },
          ]}
        />
      </Content>
    </Container>
  );
};

export { AccessPermissions };
