import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Container } from './styles';
import TechnicianMarker from 'components/Markers/Technician';
import ClientMarker from 'components/Markers/Client';
import { CallMarker } from 'components/Markers/CallMarker';
import { coordsCompare } from 'utils/calcLine';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const styles = require('./maps.json');

const Map = ({
  defaultCenter = { lat: 100, lng: 100 },
  defaultZoom = 11,
  markers,
  showTarget = false,
  currentOpen = null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onMapLoaded = ({ map, maps }) => {},
}) => {
  const [markersState, setMarkers] = useState(markers);
  const [currentZoom, setCurrentZoom] = useState(defaultZoom);

  // Update dos marcadores após a renderização do mapa
  // não funciona sem os marcadores estarem em um state
  useEffect(() => {
    setMarkers(markers);
  }, [markers]);

  // const Marker = ({ children }) => children;
  const markersLength = markersState.length;

  if (showTarget) {
    const coordsStatus = {
      latDestinationGreaterThan:
        markers[0].lat <= markers[markersLength - 1].lat,
      lngDestinationGreaterThan:
        markers[0].lng <= markers[markersLength - 1].lng,
      latDestinationLessThan: markers[0].lat >= markers[markersLength - 1].lat,
      lngDestinationLessThan: markers[0].lng >= markers[markersLength - 1].lng,
    };
    const targetMarkers = [];

    coordsCompare(coordsStatus, [
      markersState[0],
      markersState[markersLength - 1],
    ]).map((coord) =>
      targetMarkers.push({ lat: coord[0], lng: coord[1], variant: 'path' })
    );

    return (
      <Container>
        <GoogleMapReact
          onChange={({ zoom }) => {
            setCurrentZoom(zoom);
          }}
          bootstrapURLKeys={{
            key: 'AIzaSyDM98rexd1s2nSIMgR6gAtyH0GI8V-BNIM',
          }}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          options={{
            disableDefaultUI: true,
            draggable: true,
            keyboardShortcuts: false,
            scaleControl: true,
            scrollwheel: true,
            styles: styles,
            noClear: false,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={onMapLoaded}
        >
          {markersState &&
            markersState.map((marker, index) => markerSwitch(marker, index))}
          {targetMarkers &&
            targetMarkers
              .filter((f) => (currentOpen ? f.id === currentOpen : true))
              .map((marker, index) => <CallMarker key={index} {...marker} />)}
        </GoogleMapReact>
      </Container>
    );
  }

  function markerSwitch(marker, key) {
    switch (marker.type) {
      case 'call':
      case 'micro':
      case 'path':
        return <CallMarker key={key} {...marker} />;
      case 'client':
        return <ClientMarker key={key} {...marker} />;
      case 'technician':
        return <TechnicianMarker key={key} {...marker} />;
      default:
        break;
    }
  }

  return (
    <Container>
      <GoogleMapReact
        onChange={({ zoom }) => {
          setCurrentZoom(zoom);
        }}
        bootstrapURLKeys={{ key: 'AIzaSyAbvPTgtBP-TzFfHv6fJvNUFYMEx3I21CA' }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        options={{
          disableDefaultUI: true,
          draggable: true,
          keyboardShortcuts: false,
          scaleControl: true,
          scrollwheel: true,
          styles: styles,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapLoaded}
      >
        {markersState &&
          markersState
            .filter((f) => (currentOpen ? f.id === currentOpen : true))
            .filter((m) =>
              currentZoom < 10 ? m.type === 'call' : m.type !== 'call'
            )
            .map((marker, index) => markerSwitch(marker, index))}
      </GoogleMapReact>
    </Container>
  );
};

export default Map;
