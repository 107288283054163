import * as Yup from 'yup';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { Typography } from 'components/Typography';
import { Button } from 'components/Button';
import { Stepper } from 'components/Stepper';
import { Company } from 'services/company';
import { yupValidations } from 'utils/validations/yup';
import { useUI } from 'hooks/useUI';
import { ModalTypes } from 'ducks/ui/types';
import { useCompany } from 'queries/clients';
import { cnpjMask } from 'utils/maskUtils';
import { currencyToFloat } from 'utils/formatUtils';
import { formatCurrency } from 'utils/formatCurrency';
import {
  documentDark,
  documentWhite,
  dollarSign,
  dollarSignWhite,
  suitcaseDark,
  suitcaseWhite,
} from 'assets/images/icons';

import { CompanyEdit } from '../../services';
import {
  FormCompanyData,
  FormCompanyPayment,
  FormTypesOfServiceCompany,
} from 'features/Company/components';
import { useQueryClient } from 'react-query';

export enum EditClientSections {
  Details = 1,
  Contract,
  Services,
}

export interface SidesheetEditCompanyProps {
  id: string;
  section?: EditClientSections;
  onCreate?: () => void;
  close: () => void;
}

const SidesheetEditCompany = ({
  id,
  onCreate,
  close,
  section = EditClientSections.Details,
}: SidesheetEditCompanyProps) => {
  const [currentStep, setCurrentStep] = useState<EditClientSections>(section);
  const [loading, setLoading] = useState(false);
  const UI = useUI();
  const company = useCompany(id);
  const queryClient = useQueryClient();

  function renderStepSelected() {
    const step = {
      1: <FormCompanyData />,
      2: <FormCompanyPayment isEdit />,
      3: <FormTypesOfServiceCompany isEdit />,
    };

    return step[currentStep] || step[1];
  }

  function getValidationSchema() {
    switch (currentStep) {
      case 1: {
        return Yup.object().shape({
          fantasyName: yupValidations.requiredString,
          name: yupValidations.requiredString,
          cnpj: yupValidations.cnpj,

          collaborator: Yup.object().shape({
            name: yupValidations.requiredString,
            adjutancy: yupValidations.requiredString,
            email: yupValidations.requiredEmail,
            phone: yupValidations.phoneAndBranchLineArray.min(1),
          }),
        });
      }
      case 2: {
        return Yup.object().shape({
          contract: Yup.object().shape({
            amount: yupValidations.requiredString,
            end: yupValidations.requiredString,
            start: yupValidations.requiredString,
          }),
          sla: Yup.object().shape({
            cancellationFeeUpTo6h: yupValidations.requiredNumber,
            cancellationFeeUpTo24h: yupValidations.requiredNumber,
            cancellationMoreThen24h: yupValidations.requiredNumber,
            extraCostPerKM: yupValidations.requiredString,
            kmIncluded: yupValidations.requiredNumber,
            monthlyPlatformCost: yupValidations.requiredString,
            obs: yupValidations.notRequiredString,
            recurrence: yupValidations.requiredString,
            startCalls: yupValidations.requiredNumber,
            urgentCostPerCall: yupValidations.requiredNumber,
            unproductiveCostPerCall: yupValidations.unproductiveCostPerCall,
          }),
        });
      }
      case 3: {
        return Yup.object().shape({
          serviceType: Yup.array().min(1),
        });
      }
      default: {
        return null;
      }
    }
  }

  async function handleConfirmEdit(values) {
    const payload = values;
    try {
      setLoading(true);
      if (currentStep === 1) {
        await CompanyEdit(values.id, {
          fantasyName: payload.fantasyName,
          name: payload.name,
          cnpj: payload.cnpj,
          stateRegistration: payload.stateRegistration,
          municipalRegistration: payload.municipalRegistration,
          avatar: payload.avatar,

          collaborator: {
            name: payload.responsible.name,
            adjutancy: payload.responsible.adjutancy,
            email: payload.responsible.email,
            phone: payload.responsible.phone,
          },
        });
        close();
        UI.operations.showToast(
          `Cliente ${payload.fantasyName} atualizado com sucesso!`,
          {
            type: 'success',
          }
        );
      } else if (currentStep === 2) {
        payload.contract.end = new Date(payload.contract.end).toISOString();
        payload.contract.start = new Date(payload.contract.start).toISOString();
        await Company.editContractAndSla(payload.id, {
          contract: {
            amount: currencyToFloat(payload.contract.amount),
            end: payload.contract.end,
            start: payload.contract.start,
          },
          sla: {
            cancellationFeeUpTo6h: payload.sla.cancellationFeeUpTo6h,
            cancellationFeeUpTo24h: payload.sla.cancellationFeeUpTo24h,
            cancellationMoreThen24h: payload.sla.cancellationMoreThen24h,
            extraCostPerKM: currencyToFloat(payload.sla.extraCostPerKM),
            kmIncluded: payload.sla.kmIncluded,
            monthlyPlatformCost: currencyToFloat(
              payload.sla.monthlyPlatformCost
            ),
            obs: payload.sla.obs,
            recurrence: payload.sla.recurrence,
            startCalls: payload.sla.startCalls,
            urgentCostPerCall: payload.sla.urgentCostPerCall,
            unproductiveCostPerCall: payload.sla.unproductiveCostPerCall,
          },
        });
      } else if (currentStep === 3) {
        await Promise.all(
          payload?.serviceType?.map(async (service) => {
            if (!service.id) {
              await Company.createServiceType(values.id, {
                ...service,
                serviceId: service.service.id,
              });
            } else {
              await Company.editServiceType(service.id, {
                ...service,
                serviceId: service.service.id,
              });
            }
          })
        );
      } else {
        return;
      }

      company.refetch();

      await queryClient.refetchQueries(['company']);

      if (onCreate) {
        onCreate();
      }
      close();
    } catch (err) {
      UI.operations.showToast('Erro ao atualizar dados', {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    function getConfirmationModalType() {
      if (currentStep === 1) {
        if (
          JSON.stringify(values.responsible) !==
          JSON.stringify(
            company.company.responsible || company.company.responsible
          )
        ) {
          return 'company-email';
        }
        return 'company';
      } else if (currentStep === 2) {
        return 'contract';
      } else {
        return 'services';
      }
    }

    const confirmationModalType = getConfirmationModalType();

    if (confirmationModalType) {
      UI.operations.modal.openModal({
        type: ModalTypes.ConfirmEditCompany,
        modalProps: {
          type: confirmationModalType,
          email: values.responsible.email,
          onConfirm: async () => await handleConfirmEdit(values),
        },
      });
    } else {
      if (onCreate) {
        onCreate();
      }
      close();
    }
  };

  if (company.isLoading) {
    return null;
  }

  return (
    <div
      className="flex flex-col max-h-screen min-h-screen"
      data-testid="sidesheet-edit-company"
    >
      <Formik
        initialValues={{
          ...company.company,
          cnpj: cnpjMask(company.company.cnpj),
          contract: {
            ...company.company.contract,
            amount: company.company?.contract.amount
              ? formatCurrency(company.company?.contract.amount).slice(3)
              : 0,
          },
          collaborator:
            company.company.responsible || company.company.responsible,
          sla: {
            ...company.company.currentSla,
            monthlyPlatformCost: company.company?.currentSla.monthlyPlatformCost
              ? formatCurrency(
                  company.company.currentSla.monthlyPlatformCost
                ).slice(3)
              : 0,
            extraCostPerKM: company.company?.currentSla.extraCostPerKM
              ? formatCurrency(company.company.currentSla.extraCostPerKM).slice(
                  3
                )
              : 0,
          },
        }}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="flex flex-col justify-between h-full">
            <div className="flex flex-col w-full pt-6 bg-lighterGray">
              <Typography variant="h1" color="darkBLue" className="px-4">
                Editar Cadastro
              </Typography>
              <Stepper
                isTabs
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                <Stepper.Step
                  title="Dados cadastrais"
                  icon={(active) => (active ? documentWhite : documentDark)}
                />
                <Stepper.Step
                  title="Informações de cobrança"
                  icon={(active) => (active ? dollarSignWhite : dollarSign)}
                />
                <Stepper.Step
                  title="Tipos de serviços"
                  icon={(active) => (active ? suitcaseWhite : suitcaseDark)}
                />
              </Stepper>
            </div>
            <div className="bg-white flex flex-col max-h-[min-content] mb-auto overflow-y-auto">
              {renderStepSelected()}
            </div>
            <div className="flex justify-between py-5 border-t-2 justify-self-end bg-lighterGray border-gray px-14">
              <div className="flex justify-end w-full">
                <div className="flex items-center justify-center space-x-4">
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    loading={loading}
                    onClick={null}
                  >
                    Salvar alterações
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export { SidesheetEditCompany };
