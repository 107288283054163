/* eslint-disable */
export const cnpjMask = (value) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const cpfMask = (value) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const cepMask = (value) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const phoneMask = (value = '') => {
  if (!value) {
    return '';
  }

  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{0})(\d)/, '$1($2')
    .replace(/(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{5})(\d+?$)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const contactMask = (value = '') => {
     if (!value) {
      return '';
    }
  
    const onlyNums = value.replace(/\D/g, '');
  
    if (onlyNums.length === 11) {
      return onlyNums
        .replace(/(\d{0})(\d)/, '$1($2')
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{5})(\d{4}$)/, '$1-$2');
    } else {
      return onlyNums
        .replace(/(\d{0})(\d)/, '$1($2')
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4})(\d{4}$)/, '$1-$2');
    }
  };
  

const formatNumber = (n) => {
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const currencyMask = (value) => {
  let input_val = input.val();

  if (input_val === '') {
    return;
  }

  let original_len = input_val.length;

  let caret_pos = input.prop('selectionStart');

  if (input_val.indexOf('.') >= 0) {
    let decimal_pos = input_val.indexOf('.');

    let left_side = input_val.substring(0, decimal_pos);
    let right_side = input_val.substring(decimal_pos);

    left_side = formatNumber(left_side);

    right_side = formatNumber(right_side);

    if (blur === 'blur') {
      right_side += '00';
    }

    right_side = right_side.substring(0, 2);

    input_val = '$' + left_side + '.' + right_side;
  } else {
    input_val = formatNumber(input_val);
    input_val = '$' + input_val;

    if (blur === 'blur') {
      input_val += '.00';
    }
  }

  input.val(input_val);

  let updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0].setSelectionRange(caret_pos, caret_pos);
};

export const onlyNumbers = (value) => {
  if (!value) {
    return '';
  }

  return value.match(/\d+/g).join('');
};
