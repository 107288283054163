import { changeActive } from 'assets/images/icons';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';

const ButtonLinkTechnician = ({ callId, companyId, refetch }) => {
  const UI = useUI();
  return (
    <div className="flex flex-row justify-end">
      <button
        className="flex items-center justify-center w-64 px-4 py-2 space-x-2 bg-opacity-50 rounded-md hover:bg-opacity-80 focus:outline-none bg-lightGray"
        onClick={() => {
          UI.operations.sidesheet.openSidesheet({
            type: SidesheetTypes.SidesheetLinkTechnician,
            sidesheetProps: {
              callId: callId,
              companyId: companyId,
              onAfterClose: () => {
                refetch();
              },
            },
          });
        }}
      >
        <div className="p-2 rounded-md bg-lightGray">
          <img src={changeActive} />
        </div>
        <span className="font-bold text-darkerGray">
          Desvincular do chamado
        </span>
      </button>
    </div>
  );
};

export { ButtonLinkTechnician };
