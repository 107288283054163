import ModalBase from './ModalBase';
import { useDispatch } from 'react-redux';
import { uiSlice } from 'ducks/ui';
import { modalTypes } from './types';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useUI } from 'hooks/useUI';

const ModalRoot: React.FC<{ level: number }> = ({ children }) => {
  const { currentModal, modalIsOpen } = useTypedSelector((state) => state.ui);
  const dispatch = useDispatch();
  const UI = useUI();

  const ModalComponent = modalTypes[currentModal?.type];

  return (
    <ModalBase
      isOpen={modalIsOpen}
      close={() => {
        dispatch(uiSlice.actions.closeModal());
        setTimeout(UI.operations.popComponentList, 500);
      }}
      onAfterClose={currentModal?.props?.onAfterClose}
    >
      {ModalComponent ? (
        <>
          <ModalComponent
            {...currentModal?.props}
            close={() => {
              dispatch(uiSlice.actions.closeModal());
              setTimeout(UI.operations.popComponentList, 500);
            }}
          />
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </ModalBase>
  );
};

export { ModalRoot };
