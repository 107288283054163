import useSWR from 'swr';
import { fetcher } from 'services/api';
import { buildSearchParams } from 'utils/queryUtils';
import api from './api';

export const Company = {
  getAll: (filters) =>
    useSWR(`/company?${buildSearchParams(filters)}`, fetcher),
  getById: (id) => useSWR(id ? `/company/${id}` : null, fetcher),
  create: (payload) => api.post(`/company`, payload),
  edit: (id, payload) =>
    api.patch(`/company/` + id + '/registration-data', payload),
  deactivate: (id) =>
    api.patch(`/company/` + id + '/registration-data', { status: false }),
  activate: (id) =>
    api.patch(`/company/` + id + '/registration-data', { status: true }),
  editContractAndSla: (id, payload) =>
    api.patch(`/company/` + id + '/contract-sla', payload),
  createServiceType: async (companyId, payload) => {
    await api.post('/service-type', { ...payload, companyId });
  },
  editServiceType: async (id, payload) => {
    await api.patch('/service-type/' + id, payload);
  },
  deleteServiceType: async (id) => {
    await api.delete('/service-type/' + id);
  },
  delete: async (id) => {
    await api.delete('/company/' + id);
  },
  getTrainings: async (id) => {
    const { data } = await api.get(`/company/${id}/trainings`);
    return data;
  }
};
