import { crossed } from 'assets/images/icons';
import { Accordion } from 'components/Accordion';

const ServiceItem = ({
  id,
  name,
  description,
  handleRemove,
  preventRemove,
}) => {
  return (
    <div className="flex items-center w-full space-x-4">
      <Accordion title={name}>{description}</Accordion>
      {!preventRemove && (
        <button
          type="button"
          className="flex items-center justify-center text-white rounded focus:outline-none bg-orange focus:bg-orangeClick hover:bg-orangeHover text-xs h-[16px] w-[16px]"
          onClick={() => handleRemove(id)}
        >
          <img src={crossed} />
        </button>
      )}
    </div>
  );
};

export default ServiceItem;
