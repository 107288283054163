import { AdminPermissions } from './permissions/admin';
import { FinancialPermissions } from './permissions/financial';
import { OperationPermissions } from './permissions/operation';
import { RolesPermissionType } from './root';

export const rolesPermissionsModules: RolesPermissionType = {
  ADMIN: {
    permissions: AdminPermissions
  },
  OPERATION: {
    permissions: OperationPermissions
  },
  FINANCIAL: {
    permissions: FinancialPermissions
  }
}