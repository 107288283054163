import React from 'react';
import { usePagination, DOTS } from '../usePagination';
import { PaginationButton } from '../PaginationButton';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

interface PaginationProps {
  currentPage: number;
  totalCount: number;
  onPageChange: (pageNumber: number) => void;
  siblingCount?: number;
  limitPage?: number;
}

export const LIMIT_PAGE = 10;

const Pagination = ({
  currentPage,
  totalCount,
  onPageChange,
  siblingCount,
  limitPage = LIMIT_PAGE,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize: limitPage,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  function onNext() {
    onPageChange(currentPage + 1);
  }

  function onPrevious() {
    onPageChange(currentPage - 1);
  }

  const lastPage = paginationRange?.slice(-1)[0];

  return (
    <div className="flex items-center justify-end mt-4">
      <PaginationButton
        Icon={FaChevronLeft}
        disabled={currentPage === 1}
        onClick={onPrevious}
      />
      {paginationRange?.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <div className="mt-2" key={i}>
              <PaginationButton Icon={BiDotsHorizontalRounded} disabled />
            </div>
          );
        }
        return (
          <PaginationButton
            key={i}
            pageNumber={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            active={pageNumber === currentPage}
          />
        );
      })}
      <PaginationButton
        Icon={FaChevronRight}
        onClick={onNext}
        disabled={currentPage === lastPage}
      />
    </div>
  );
};

export { Pagination };
