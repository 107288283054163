import { uiSlice } from 'ducks/ui';
import {
  ModalTypes,
  OpenModalPayload,
  OpenSidesheetPayload,
} from 'ducks/ui/types';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { AppearanceTypes } from 'react-toast-notifications';

export function useUI() {
  const dispatch = useDispatch();

  function openModal(modal: OpenModalPayload) {
    dispatch(uiSlice.actions.openModal(modal));
  }

  function openSidesheet(sidesheet: OpenSidesheetPayload) {
    dispatch(uiSlice.actions.openSidesheet(sidesheet));
  }

  function popComponentList() {
    dispatch(uiSlice.actions.popComponentList());
  }

  function showToast(
    title: string,
    {
      type = 'success',
      message,
      icon,
    }: { message?: string; type?: AppearanceTypes; icon?: string } = {}
  ) {
    dispatch(uiSlice.actions.notify({ type, message, icon, title }));
  }

  function confirm(
    title: string,
    description: string | JSX.Element,
    onConfirm: () => void,
    options: {
      confirmText?: string;
      cancelText?: string;
      danger?: boolean;
      icon?: ReactNode;
    } = {}
  ) {
    const { confirmText, cancelText, danger, icon } = options;
    dispatch(
      uiSlice.actions.openModal({
        type: ModalTypes.ModalGenericConfirmation,
        modalProps: {
          title,
          description,
          onConfirm,
          confirmText,
          cancelText,
          danger,
          icon,
        },
      })
    );
  }

  return {
    operations: {
      confirm,
      modal: { openModal },
      sidesheet: { openSidesheet },
      popComponentList: popComponentList,
      showToast,
    },
  };
}
