import { useState } from 'react';
import { CardSendPayment } from './CardSendPayment/CardSendPayment';
import { PaymentStatusUpdate } from './PaymentStatusUpdate';

export const StepsReviewPayment = ({ callStatus }) => {
  const [currentStatus, setCurrentStatus] = useState(callStatus);

  function renderStepReview() {
    const stepReview = {
      PAID_OUT: <PaymentStatusUpdate setCurrentStatus={setCurrentStatus} />,
      SCHEDULED_PAYMENT: (
        <CardSendPayment
          callStatus={callStatus}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
        />
      ),
      ANTECIPATED_PAYMENT: (
        <CardSendPayment
          callStatus={callStatus}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
        />
      ),
      PAYMENT_DECLINED: (
        <CardSendPayment
          callStatus={callStatus}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
        />
      ),
    };
    return stepReview[currentStatus];
  }

  return <div>{renderStepReview()}</div>;
};
