/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { Typography } from 'components/Typography';
import { formatCurrency } from 'utils/formatCurrency';
import { PaymentInfoIcon, PaymentInfoItem } from './styles';
import { dollarGreen, dollarSign } from 'assets/images/icons';
import { TypeCallTypes } from 'types/call';

type CallTypes = {
  technicianValue: number;
  type: TypeCallTypes;
};

interface CardPaymentInfoProps {
  call: CallTypes;
  urgentCostPerCall: number;
}

export const CardPaymentInfo = ({
  call,
  urgentCostPerCall,
}: CardPaymentInfoProps) => {
  const urgentPercentage = (value: number) => {
    return (value / 100) * urgentCostPerCall + value;
  };
  return (
    <div className="space-y-2">
      <Typography variant="h2" color="darkestGray">
        Pagamento
      </Typography>
      <div className="space-y-4">
        <Typography variant="p1" color="darkerGray">
          Conforme os nossos termos de uso, o técnico poderá antecipar o
          pagamento do chamado e recebê-lo em até 24 horas úteis.
        </Typography>
        <Typography variant="p1" color="darkerGray">
          Porém, há um desconto de 5% (cinco por cento) referente para adiantar
          o pagamento e o mesmo deve ser solicitado até o dia 22 do mês vigente.
        </Typography>
        <Typography variant="p1" color="darkerGray">
          Segue abaixo o valor do pagamento no final do mês e antecipado:
        </Typography>
      </div>
      <PaymentInfoItem>
        <PaymentInfoIcon color="purple">
          <img src={dollarSign} />
        </PaymentInfoIcon>
        <Typography variant="h4" color="darkestGray">
          {call.type === 'Urgent'
            ? formatCurrency(urgentPercentage(call.technicianValue))
            : formatCurrency(call.technicianValue)}
        </Typography>
      </PaymentInfoItem>
      <PaymentInfoItem>
        <PaymentInfoIcon color="greenClick">
          <img src={dollarGreen} />
        </PaymentInfoIcon>
        <Typography
          variant="h4"
          color="darkestGray"
          className="flex items-end gap-1"
        >
          {call.type === 'Urgent'
            ? formatCurrency(
                urgentPercentage(
                  call.technicianValue - (call.technicianValue * 5) / 100
                )
              )
            : formatCurrency(
                call.technicianValue - (call.technicianValue * 5) / 100
              )}

          <Typography variant="p1" color="darkestGray">
            em até 24 horas úteis
          </Typography>
        </Typography>
      </PaymentInfoItem>
    </div>
  );
};
