import { Avatar } from 'components/Avatar';
import { Typography } from 'components/Typography';
import React from 'react';
import { ICollaborator } from 'types/collaborator';

import { Container, Content } from './styles';

interface HeaderProps {
  data?: ICollaborator;
}

export const Header: React.FC<HeaderProps> = ({ data }) => {
  return (
    <Container>
      <Avatar
        photoURL={null}
        name={data?.name}
        size='large'
      />
      <Content>
        <Typography variant="h1" color="darkestGray">
          {data?.name}
        </Typography>
        <Typography variant="h4" color="darkestGray">
          {data?.adjutancy}
        </Typography>
        <Typography variant="p2" color="darkerGray">
          ID #{data?.id}
        </Typography>
      </Content>
    </Container>
  );
}
