import styled, { css } from 'styled-components';
import type { BigNumberProps } from '.';

export const BigNumber = styled.button<BigNumberProps>`
  border: 2px solid
    ${({ isHighlighted, theme }) =>
    isHighlighted ? theme.colors.purple : theme.colors.lightGray};
  border-radius: 16px;
  text-align: left;
  box-sizing: border-box;
  padding: 24px;
  width: 164px;
  cursor: pointer;
  height: 108px;
  color: ${({ theme }) => theme.colors.darkBlue};
  line-height: 40px;
  transition: 0.2s;

  :focus {
    outline: 0;
  }
  :hover {
    ${({ theme }) => css`
      color: ${theme.colors.purple};
      border-color: ${theme.colors.purple};
      p {
        color: ${theme.colors.purple};
      }
    `};
  }
  ${({ disabled, theme }) =>
    disabled
      ? css`
          cursor: not-allowed;
          background-color: ${theme.colors.lightGray} !important;
          border-color: ${theme.colors.gray} !important;
        `
      : css`
          :hover {
            color: ${({ theme }) => theme.colors.purple};
            border-color: ${({ theme }) => theme.colors.purple};
            p {
              color: ${({ theme }) => theme.colors.purple};
            }
          }
        `}

  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: ${theme.colors.lightPurple};
          border-color: ${theme.colors.purple};
        `
      : css`
          background-color: transparent;
          border-color: ${theme.colors.lightGray};
        `}
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  line-height: 12px;
  display: flex;
  align-items: center;
`;
