import clsx from 'clsx';
import { Typography } from 'components/Typography';

interface SelectOptionProps {
  value: string;
  title: string;
  description: string;
  checked?: boolean;
  onSelect: (value) => void;
}

const SelectOption = ({
  value,
  title,
  description,
  checked = false,
  onSelect,
}: SelectOptionProps) => {
  return (
    <div
      role="button"
      onClick={() => {
        onSelect(value);
      }}
      className={clsx(
        'w-full rounded-xl border-2 p-4 flex space-x-4 items-center cursor-pointer',
        {
          'border-purple bg-purple bg-opacity-5': checked,
          'border-opacity-0 border-lightGray bg-lightGray bg-opacity-50':
            !checked,
        }
      )}
    >
      <div className="w-7 h-7 p-[2px] border-2 rounded-lg border-gray">
        {checked && (
          <div role="checked" className="w-full h-full rounded bg-purple" />
        )}
      </div>
      <div className="flex flex-col w-full space-y-4">
        <Typography variant="button" color="darkestGray">
          {title}
        </Typography>
        <Typography variant="p1" color="darkerGray">
          {description}
        </Typography>
      </div>
    </div>
  );
};

export { SelectOption };
