import React from 'react';
import { Typography } from 'components/Typography';
import { Container, IconContainer, Icon } from './styles';

export interface SummaryCardProps {
  title: string;
  color?: string;
  icon: string;
  children?: React.ReactNode;
}

const SummaryCard = ({
  title,
  color = 'purple',
  icon,
  children,
}: SummaryCardProps) => {
  return (
    <Container>
      <IconContainer color={color}>
        <Icon src={icon} color={color} alt="Ícone personalizável" />
      </IconContainer>
      <Typography variant="h3" color="darkBlue">
        {title}
      </Typography>
      {children}
    </Container>
  );
};

export { SummaryCard };
