import Spinner from 'components/Spinner';
import { Table } from './Table';

const TableContainer = ({
  data,
  error,
  loading,
  columns,
  ErrorComponent,
  NotFoundComponent,
  onRowClick,
  ...otherProps
}) => {
  if (error) {
    return <ErrorComponent />;
  }

  if (loading) {
    return <Spinner />;
  }

  if (data && !data.length) {
    return <NotFoundComponent />;
  }

  return (
    <Table
      data={data}
      columns={columns}
      onRowClick={onRowClick}
      {...otherProps}
    />
  );
};

export { TableContainer };
