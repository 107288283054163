/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-spread */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { Field, Form, Formik } from 'formik';

import { Button } from 'components/Button';
import { InputField } from 'components/InputField';
import ServiceItem from './ServiceItem';

import { useServices } from 'queries/clients';
import { yupValidations } from 'utils/validations/yup';
import { currencyMask, currencyToFloat } from 'utils/formatUtils';
import { MultiValueSelect } from 'components/Inputs/MultiValueSelect';
import {
  ServiceItem as ServiceItemData,
  ServicesSearchBarAutocomplete,
} from 'components/ServicesSearchBarAutocomplete';
import { specialChars } from 'utils/specialChars';
import { Alert } from 'components/AlertComponents/Alert';
import { clock, real } from 'assets/images/icons';

export interface NewServiceSidesheetProps {
  onCreate?: (values: any) => void;
  serviceId?: string;
  usedServiceIds: string[];
  data?: any;
  isEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  services: Yup.array().min(1),
  formsOfService: yupValidations.formsOfService.min(1),
  expectedTime: Yup.number().notRequired(),
  // necessaryProfessionals: yupValidations.requiredNumber
  //   .min(1, 'O valor não pode ser negativo')
  //   .max(1),
  costPerHour: Yup.string().notRequired(),
  costPerExtraHour: Yup.string().notRequired(),
  // costPerTechnicianHour: yupValidations.requiredString,
  costPerTechnicianHour: Yup.string().notRequired(),
  costPerTechnicianExtraHour: Yup.string().notRequired(),
  costPerCall: Yup.string().notRequired(),
  costPerTechnicianCall: Yup.string().notRequired(),
});

const options = [
  { value: 'PER_CALL', label: 'Por chamado' },
  { value: 'HOUR', label: 'Por Hora' },
];

const NewServiceSidesheet = ({
  onCreate,
  close,

  data,
  isEdit,
}) => {
  const [searchText, setSearchText] = useState('');
  const services = useServices();
  const [serviceList, setServiceList] = useState();
  const [itemsSelected, setItemsSelected] = useState<ServiceItemData[]>([]);
  const [categoriesSelecteds, setCategoriesSelecteds] = useState<string[]>([]);

  function handleSubmit(values, actions) {
    const payload = values.services.map((s) => {
      return {
        ..._.omit(values, 'services'),
        costPerHour: currencyToFloat(values.costPerHour) || 0,
        costPerExtraHour: currencyToFloat(values.costPerExtraHour) || 0,
        costPerTechnicianHour:
          currencyToFloat(values.costPerTechnicianHour) || 0,
        costPerTechnicianExtraHour:
          currencyToFloat(values.costPerTechnicianExtraHour) || 0,
        costPerCall: currencyToFloat(values.costPerCall) || 0,
        costPerTechnicianCall:
          currencyToFloat(values.costPerTechnicianCall) || 0,
        id: data?.id || values.id,
        service: {
          id: s.id || s.serviceId,
          name: s.name,
          description: s.description,
        },
        expectedTime: +values.expectedTime,
      };
    });

    onCreate(payload);
    actions.resetForm();
    close();
  }

  const handleSearchText = useCallback(() => {
    const filterItems = services?.services.map((service) => {
      const servicesFilter = service.services.filter((item) =>
        specialChars(item.name).match(specialChars(searchText))
      );
      if (servicesFilter.length > 0) {
        return {
          ...service,
          services: servicesFilter,
        };
      }
    });

    if (filterItems) {
      setServiceList(filterItems.filter((item) => item !== undefined));
    }
  }, [services, searchText]);

  const formatServicesArray = useCallback(
    (items) => {
      const serviceItems = services?.services
        ?.map((service) => {
          const newitems = service.services.filter((item) =>
            items.includes(item.id)
          );
          if (newitems.length > 0) {
            return newitems;
          }
        })
        .filter((item) => item !== undefined);
      return [].concat.apply([], serviceItems);
    },
    [services]
  );

  const handleSelect = useCallback(
    (id: string) => {
      const findItem = itemsSelected.find((item) => item.id === id);
      if (findItem) {
        const items = itemsSelected.filter((item) => item.id !== id);
        setItemsSelected(items);
      } else {
        const servicesIds = itemsSelected.map((s) => s.id);
        const items = [...servicesIds, id];
        const servicesSelecteds = formatServicesArray(items);
        setItemsSelected(servicesSelecteds);
      }
    },
    [services, formatServicesArray, itemsSelected]
  );

  const handleCheckAllServicesOfCategory = useCallback(
    (categoryId: string) => {
      const service = services?.services.find(
        (serviceObj) => serviceObj.id === categoryId
      );
      if (service) {
        const servicesPush = Array.isArray(data?.service)
          ? [...data?.service, ...itemsSelected]
          : itemsSelected;

        service.services.forEach((item) => {
          const findItem = servicesPush.findIndex(
            (serviceItem) => serviceItem.id === item.id
          );
          if (findItem >= 0) {
            servicesPush.splice(findItem, 1);
          } else {
            servicesPush.push({
              id: item.id,
              name: item.name,
              comment: '',
              certifications: '',
            });
          }
        });
        setItemsSelected(servicesPush);
      }
    },
    [data, services, itemsSelected]
  );

  const handleSelectCategory = useCallback(
    (categoryId: string) => {
      const findCategorySelected = categoriesSelecteds.find(
        (item) => item === categoryId
      );
      if (findCategorySelected) {
        const newCategoriesSelecteds = categoriesSelecteds.filter(
          (item) => item !== categoryId
        );
        setCategoriesSelecteds(newCategoriesSelecteds);
      } else {
        setCategoriesSelecteds((oldValue) => [...oldValue, categoryId]);
      }
      handleCheckAllServicesOfCategory(categoryId);
    },
    [categoriesSelecteds, handleCheckAllServicesOfCategory]
  );

  useEffect(() => {
    if (!services.isError && services.services) {
      setServiceList(services?.services);

      if (data) {
        if (data.service) {
          return setItemsSelected([data.service]);
        }
        setItemsSelected((oldValues) => [...oldValues, data?.service]);
      }
    }
  }, [data, services?.services]);

  useEffect(() => {
    handleSearchText();
  }, [searchText]);

  return (
    <Formik
      initialValues={
        data
          ? {
              ...data,
              services: [data.service],
              costPerExtraHour: currencyMask(
                (data?.costPerExtraHour * 100).toString() || null
              ),
              costPerHour: currencyMask(
                (data?.costPerHour * 100).toString() || null
              ),
              costPerTechnicianExtraHour: currencyMask(
                (data?.costPerTechnicianExtraHour * 100).toString() || null
              ),
              costPerCall: currencyMask(
                (data?.costPerCall * 100).toString() || null
              ),
              costPerTechnicianHour: currencyMask(
                (data?.costPerTechnicianHour * 100).toString() || null
              ),
              costPerTechnicianCall: currencyMask(
                (data?.costPerTechnicianCall * 100).toString() || null
              ),
              expectedTime: data?.expectedTime || null,
            }
          : { services: [], formsOfService: [], necessaryProfessionals: 1 }
      }
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form className="flex flex-col flex-1 min-h-screen">
          <div className="flex flex-col flex-1 px-8 py-5 space-y-8">
            <h1 className="text-2xl font-bold text-darkBlue">
              {!isEdit ? 'Adicionar' : 'Salvar'} tipo de serviço
            </h1>
            {isEdit && (
              <Alert type="danger">
                Ao clicar em <b>Editar forma de atendimento</b>, as alterações
                serão aplicadas a partir do próximo mês
                <b> {'01/' + (new Date().getMonth() + 2)}</b>.
              </Alert>
            )}
            <div className="flex flex-col flex-1 space-y-4">
              <p className="text-sm text-darkerGray">
                Adicione tipos de serviços que estarão disponíveis para este
                cliente.
              </p>
              <ServicesSearchBarAutocomplete
                data={serviceList}
                searchText={searchText}
                setSearchText={setSearchText}
                onChange={() => {
                  setFieldValue('services', itemsSelected);
                }}
                handleSelect={handleSelect}
                itemsSelected={itemsSelected.map((item) => item.id)}
                categorySelected={categoriesSelecteds}
                handleSelectCategory={handleSelectCategory}
              />

              <p className="text-sm text-darkerGray">
                Você pode adicionar mais de uma forma de atendimento por vez.
              </p>

              <div className="flex flex-col space-y-4">
                {itemsSelected.map((s) => (
                  <ServiceItem
                    preventRemove={isEdit}
                    key={s.id}
                    id={s.id}
                    name={s.name}
                    description={s.description}
                    handleRemove={handleSelect}
                  />
                ))}
              </div>

              {itemsSelected.length > 1 && (
                <div className="flex flex-col w-full p-4 space-y-2 bg-lighterGray rounded-xl">
                  <p className="text-sm font-bold">Atenção!</p>
                  <p className="text-sm text-darkerGray">
                    Ao adicionar mais de uma forma de atendimento, todas serão
                    geridas pelas regras de atendimento cadastradas abaixo.
                  </p>
                </div>
              )}

              {itemsSelected.length > 0 && (
                <>
                  <div className="flex flex-col space-y-4">
                    <p className="font-bold text-h4">
                      Regras para este atendimento
                    </p>
                    <p className="text-sm text-darkerGray">
                      Adicione as SLA&apos;s que servirão como regra para este
                      atendimento:
                    </p>
                    <div className="w-1/2">
                      <label className="flex flex-col w-full text-sm font-medium text-darkerGray">
                        <span className="pb-1">Forma de atendimento</span>
                        <MultiValueSelect
                          name="formsOfService"
                          defaultValue={
                            data?.formsOfService?.map((v) =>
                              options.find((i) => i.value === v)
                            ) || []
                          }
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          options={options}
                        />
                      </label>
                    </div>
                    <div className="flex w-full space-x-4">
                      {values.formsOfService.includes('HOUR') && (
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="expectedTime"
                            label="Tempo previsto"
                            icon={clock}
                            placeholder="0"
                            suffix="horas"
                            type="number"
                          />
                        </div>
                      )}
                    </div>

                    {values.formsOfService.includes('HOUR') && (
                      <div className="flex w-full space-x-4">
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerHour"
                            label="Valor por hora"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerExtraHour"
                            label="Valor por hora extra"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerExtraHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex w-full space-x-4">
                      <div className="w-1/2">
                        {values.formsOfService.includes('PER_CALL') && (
                          <Field
                            component={InputField}
                            name="costPerCall"
                            label="Valor por chamado"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerCall',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-4">
                    <p className="font-bold text-h4">
                      Custo técnico do atendimento
                    </p>
                    <p className="text-sm text-darkerGray">
                      Regras sugeridas com base no cadastro da forma de
                      atendimento. Pode ser alterado conforme negociado com cada
                      cliente.
                    </p>

                    {values.formsOfService.includes('HOUR') && (
                      <div className="flex w-full space-x-4">
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerTechnicianHour"
                            label="Valor a ser pago por hora"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerTechnicianHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerTechnicianExtraHour"
                            label="Valor a ser pago por hora extra"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerTechnicianExtraHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {values.formsOfService.includes('PER_CALL') && (
                      <div className="flex w-full space-x-4">
                        <Field
                          component={InputField}
                          name="costPerTechnicianCall"
                          label="Valor a ser pago por chamado"
                          icon={real}
                          placeholder="0,00"
                          onChange={(e) => {
                            setFieldValue(
                              'costPerTechnicianCall',
                              currencyMask(e.target.value)
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex self-end justify-end w-full px-8 py-5 space-x-8 border-t-2 bg-lighterGray border-gray">
            <button
              type="button"
              onClick={() => close()}
              className="text-sm font-medium text-darkerGray focus:outline-none"
            >
              Cancelar
            </button>
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              onClick={null}
            >
              {!isEdit ? 'Adicionar' : 'Editar'} forma de atendimento
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewServiceSidesheet;
