/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, useFormikContext } from 'formik';
import { Alert } from 'components/AlertComponents/Alert';
import { BooleanSelectButton } from 'components/BooleanSelectButton';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { StarRating } from 'components/StarRating';
import { FileDrop } from 'components/FileDrop';
import { useTechnician } from 'queries/technician';
import AttachmentItem from 'components/AttachmentItem';

interface FinishCallClientFormProps {
  disableButton?: boolean;
  technicianId: string;
}

const FinishCallClientForm = ({
  technicianId,
  disableButton = false,
}: FinishCallClientFormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form = useFormikContext<any>();
  const technician = useTechnician(technicianId);

  function handleAddFile(e) {
    if (e.target.files.length > 0)
      form.setFieldValue('files', [...form.values.files, ...e.target.files]);
  }

  return (
    <Form className="flex flex-col px-4 space-y-4 mb-4">
      <Typography variant="h2" color="darkestGray">
        Confirmar finalização do chamado
      </Typography>
      <div className="flex flex-col w-full space-y-4">
        <Typography variant="h3" color="darkestGray">
          Atendimento foi finalizado com sucesso?
        </Typography>
        <BooleanSelectButton
          value={form.values.success}
          onChange={(value) => form.setFieldValue('success', value)}
        />
      </div>
      {form.values.success === false && (
        <Alert type="danger">
          Chamados finalizados como improdutivos serão revisados pela equipe
          Eunerd.
        </Alert>
      )}
      {form.values.success !== null && (
        <>
          <div className="flex flex-col w-full space-y-4">
            <Typography variant="h3" color="darkestGray">
              Adicionar prova de atendimento
            </Typography>

            {form.values.files.map((f, idx) => (
              <AttachmentItem
                key={idx}
                id={f?.id}
                file={f}
                onDelete={() => {
                  const newFiles = form.values.files.filter(
                    (_, i) => i !== idx
                  );
                  form.setFieldValue('files', newFiles);
                }}
              />
            ))}
            <FileDrop onChange={handleAddFile} />
          </div>
          <div className="flex flex-col w-full space-y-4">
            <Typography variant="h3" color="darkestGray">
              {/* Gostaria de adicionar alguma observação? */}
              Utilize o campo para adicionar observações sobre o atendimento.
            </Typography>
            {/* <p className="text-darkestGray">
              Utilize o campo para adicionar observações sobre o atendimento.
            </p> */}
            <div>
              {form.errors.description && form.touched.description && (
                <div className="text-xs font-medium text-orangeClick">
                  {form.errors.description}
                </div>
              )}

              <Field
                className="w-full p-3 border-2 focus:outline-none focus:border-purple border-gray rounded-xl"
                name="description"
                rows={5}
                label="Observações"
                as="textarea"
                touched={form.touched.description}
              />
            </div>
          </div>
          <div className="flex flex-col w-full space-y-4">
            <Typography variant="h2" color="darkestGray">
              Avaliar profissional
            </Typography>
            <div className="flex flex-col space-y-2">
              <Typography variant="h3" color="darkestGray">
                Como foi o atendimento de {technician.technician?.name} ?
              </Typography>
              <div className="w-full rounded-xl bg-lightGray">
                <StarRating
                  rating={form.values.rating}
                  onChange={(rating) => {
                    form.setFieldValue('rating', rating);
                  }}
                />
              </div>
            </div>
          </div>
          {form.values.rating >= 4 ? (
            <div className="flex flex-col w-full space-y-4">
              <Typography variant="h3" color="darkestGray">
                Gostaria de favoritar este profissional?
              </Typography>
              <p className="text-darkestGray">
                Profissionais marcados como favoritos serão priorizados em
                atendimentos futuros.
              </p>
              <BooleanSelectButton
                value={form.values.favorite}
                onChange={(value) => form.setFieldValue('favorite', value)}
              />
            </div>
          ) : (
            <div className="flex flex-col w-full space-y-4">
              <Typography variant="h3" color="darkestGray">
                Qual o motivo da sua nota?
              </Typography>
              <p className="text-darkestGray">
                Nos conte um pouco mais sobre a sua avaliação. Seu feedback é
                muito importante e ajuda a melhorar a plataforma e serviços
                prestados!
              </p>
              <Field
                className="w-full p-3 border-2 focus:outline-none focus:border-purple border-gray rounded-xl"
                name="reason"
                rows={5}
                label="Observações"
                as="textarea"
              />
            </div>
          )}

          <Button type="submit" size="large" disabled={disableButton}>
            Finalizar chamado
          </Button>
        </>
      )}
    </Form>
  );
};

export { FinishCallClientForm };
