import { Circle } from 'components/styles';
import { BigNumber as StyledBigNumber, Description } from './styles';
import { Typography } from 'components/Typography';

export interface BigNumberProps {
  value?: string | number;
  label: string;
  color?: string;
  onClick?: () => void;
  isHighlighted?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

const BigNumber = ({
  value = 0,
  label,
  color = 'orange',
  onClick = () => null,
  isHighlighted = false,
  selected = false,
  disabled = false,
}: BigNumberProps) => {
  return (
    <StyledBigNumber
      label=""
      onClick={onClick}
      isHighlighted={isHighlighted}
      selected={selected}
      disabled={disabled}
    >
      <Typography variant="h1">{value}</Typography>
      <Description>
        <Circle color={color} />
        <Typography variant="tag">{label}</Typography>
      </Description>
    </StyledBigNumber>
  );
};

export { BigNumber };
