import { Typography } from "components/Typography"
import { v4 } from "uuid"

const TrainingsTech = ({ trainings }) => {
  return (
    <div className="space-y-4">
      <Typography variant="h3" color="darkBLue">
        Treinamentos
      </Typography>

      {trainings?.map((t) => (
        <div key={v4()} className="flex flex-col w-full border-2 border-gray rounded-xl">
          <div className="flex items-center w-full p-4 space-x-4">
            <div className="flex flex-col space-y-1">
              <Typography variant="h3">
                {t?.training?.name}
              </Typography>
              <Typography variant="p1">
                {t?.training?.description}
              </Typography>
            </div>
          </div>
        </div>
      ))
      }
    </div>
  )
}

export { TrainingsTech }