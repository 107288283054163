import {
  useMonthlyCostEvolution,
  useSpendSixMonths,
  useUnproductiveSpend,
} from 'queries/financial';
import { INDEX_MONTHS } from 'utils/periodsQuery';
import { ChartCard } from '../ChartCard';

const CompanyChartsView = ({ dataId }) => {
  const { spendSixMonths } = useSpendSixMonths(dataId);
  const { monthlyCostEvolution } = useMonthlyCostEvolution(dataId);
  const { unproductiveSpend } = useUnproductiveSpend(dataId);

  return (
    <div className="flex flex-col space-y-4">
      <ChartCard
        title="Gasto total"
        value={spendSixMonths?.data
          .filter((t) => t.totalSpend > 0)
          .reduce((acc, curr) => acc + curr.totalSpend, 0)}
        data={spendSixMonths?.data
          ?.map((t, i) => ({
            key: { i },
            month: INDEX_MONTHS[t.month],
            value: t.totalSpend,
          }))
          .reverse()}
      />

      <ChartCard
        title="Evolução gasto mensal"
        value={monthlyCostEvolution?.data.totalLounchies}
        data={monthlyCostEvolution?.data.launchies
          .map((t, i) => ({
            key: { i },
            month: INDEX_MONTHS[t.month],
            value: t.totalInMonth,
          }))
          .reverse()}
      />
      <ChartCard
        title="Gasto improdutivo"
        value={unproductiveSpend?.data
          .filter((t) => t.totalSpend)
          .reduce((acc, curr) => acc + curr.totalSpend, 0)}
        danger
        data={unproductiveSpend?.data
          .map((t, i) => ({
            key: { i },
            month: INDEX_MONTHS[t.month],
            value: t.totalSpend,
          }))
          .reverse()}
      />
    </div>
  );
};

export { CompanyChartsView };
