import { useQuery } from 'react-query';
import { CollaboratorService } from 'services/collaborator';

export function useCollaborator(status: boolean, companyId?: string) {
  const { data, error, isLoading, refetch } = useQuery(
    ['collaborator', status, companyId],
    () => CollaboratorService.getAll(status, companyId)
  );

  return {
    data: data?.data || {},
    isLoading,
    isError: error,
    refetch,
  };
}

export function useFindCollaborator(id: string) {
  const { data, error, isLoading } = useQuery(
    ['findCollaborator', id],
    () => CollaboratorService.get(id)
  );

  return {
    data: data?.data || undefined,
    isLoading,
    isError: error,
  };
}
