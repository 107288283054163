export enum CallActions {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL',
}

export enum TechnicianActions {
  READ = 'READ',
  CREATE = 'CREATE',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum FinancialActions {
  READ = 'READ',
  UPDATE_REGISTRATION_DATA = 'UPDATE_REGISTRATION_DATA',
  ADD_BALANCE = 'ADD_BALANCE',
}

export enum ClientActions {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  REPORT = 'REPORT',
}

export enum PlatformActions {
  READ = 'READ',
  MANAGEMENT_USER = 'MANAGEMENT_USER',
  MANAGEMENT_SERVICES = 'MANAGEMENT_SERVICES',
  REGISTRATION_DATA = 'REGISTRATION_DATA',
}
