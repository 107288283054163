import { useEffect, useRef } from 'react';
import { Container, Input } from './styles';
import { searchBlack } from 'assets/images/icons';

interface SearchInputProps {
  isOpen: boolean;
  onChange(value: string): void;
}

const SearchInput = ({ isOpen, onChange }: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }

    if (!isOpen) {
      inputRef.current.value = '';
    }
  }, [isOpen]);

  return (
    <Container>
      <img src={searchBlack} alt="lupa" />
      <Input
        data-testid="search-input"
        ref={inputRef}
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  );
};

export { SearchInput };
