/* eslint-disable @typescript-eslint/ban-ts-comment */
import DayPicker from 'react-day-picker';
import { differenceInHours, format } from 'date-fns';
import ReviewItem from 'components/Calls/ServiceTab/ReviewItem';
import { Typography } from 'components/Typography';
import { formatCurrency } from 'utils/formatCurrency';
import { formatUTCHour } from 'utils/formatUtils';
import {
  alertWhite,
  clock,
  dollarSign,
  toolsPurple,
  id,
} from 'assets/images/icons';

export const ResumeService = ({ call, withCalendar = 'false' }) => {
  return (
    <div>
      <Typography variant="h2" color="darkestGray">
        Resumo
      </Typography>
      <div className="flex flex-col divide-y-2 divide-lightGray">
        <ReviewItem label={`${call?.id}`} icon={id} />
        {call.type === 'Urgent' && (
          <ReviewItem label="Chamado Urgente" icon={alertWhite} danger />
        )}
        <ReviewItem label={call.service?.name} icon={toolsPurple} />
        <ReviewItem
          label={`${formatCurrency(call?.value)}`}
          icon={dollarSign}
        />
        <ReviewItem
          label={
            call.serviceType === 'PER_CALL'
              ? 'Cobrança por chamado'
              : 'Chamado por hora'
          }
          icon={clock}
          description={
            call.serviceType !== 'PER_CALL' &&
            `${format(formatUTCHour(call?.startTime), "H'h'mm")} à ${format(
              formatUTCHour(call?.finalTime),
              "H'h'mm"
            )} (${differenceInHours(
              formatUTCHour(call?.finalTime),
              formatUTCHour(call?.startTime)
            )} horas de trabalho)`
          }
        />
      </div>

      {withCalendar === 'true' && (
        <DayPicker
          weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
          months={[
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ]}
          // @ts-ignore
          classNames={{
            weekday: '!no-underline ',
            disabled: 'cursor-default',
            month: 'flex flex-col space-y-2 font-bold text-darkestGray py-2',
            weekdaysRow:
              'bg-gray py-1 rounded-md text-darkerGray text-sm font-bold flex justify-around space-x-2',
            weekNumber: 'flex !no-underline',
            day: 'flex font-medium text-darkGray w-[80px] justify-center text-sm cursor-pointer py-1',
            week: 'flex justify-around space-x-2',
            selected: 'bg-purple !text-white rounded-md',
          }}
          initialMonth={new Date(call?.startTime)}
          disabledDays={[
            {
              before: new Date(call?.startTime),
              after: new Date(call?.finalTime),
            },
          ]}
          selectedDays={[
            {
              from: new Date(call?.startTime),
              to: new Date(call?.finalTime),
            },
          ]}
        />
      )}
    </div>
  );
};
