import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

interface ConfirmCancelModalProps {
  close: () => void;
  onConfirm: () => void;
}

const ConfirmCancelModal = ({ close, onConfirm }: ConfirmCancelModalProps) => {
  return (
    <div className="flex flex-col items-center w-full text-center">
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Cancelar chamado
      </Typography>
      <Typography variant="h4" color="darkBlue" className="mb-8">
        Deseja realmente cancelar este chamado?
      </Typography>
      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color="gray"
          width="200"
          size="large"
          onClick={close}
        >
          Não
        </Button>
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={() => {
            onConfirm();
            close();
          }}
        >
          Sim
        </Button>
      </div>
    </div>
  );
};

export { ConfirmCancelModal };
