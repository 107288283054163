import { Typography } from 'components/Typography';
import { DISPLACEMENTS } from 'data/displacements';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { Checkbox } from './Checkbox';

import { Container, Content } from './styles';

interface DisplacementProps {
  viewOnly?: boolean;
}

const Displacement = ({ viewOnly = false }: DisplacementProps) => {
  const { setFieldValue, values } = useFormikContext<{
    transportation: string[];
  }>();
  const displacementsList = useMemo(() => {
    return DISPLACEMENTS;
  }, []);

  const handleChange = (item: string) => {
    if (values.transportation.includes(item)) {
      const removeItem = values.transportation.filter((e) => e !== item);
      setFieldValue('transportation', removeItem);
    } else {
      setFieldValue('transportation', [...values.transportation, item]);
    }
  };

  const checkTheSelected = (value: string) => {
    return values.transportation.includes(value);
  };

  return (
    <Container data-testid="displacement">
      <Typography variant="h3" color="darkBLue">
        Deslocamento
      </Typography>
      <Content>
        {displacementsList.map((displacement) => (
          <Checkbox
            disabled={viewOnly}
            key={displacement.value}
            checked={checkTheSelected(displacement.value)}
            onChange={() => handleChange(displacement.value)}
            value={displacement?.value}
            label={displacement?.label}
          />
        ))}
      </Content>
    </Container>
  );
};

export { Displacement };
