import { Button, Icon } from './styles';
export interface StatusButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
  selected?: boolean;
  block?: boolean;
  container?: boolean;
  color?: string;
  disabled?: boolean;
  onChange?: () => void;
}

const StatusButton = ({
  className = '',
  children,
  onClick = () => void 0,
  selected = false,
  block = false,
  container = false,
  color = 'purple',
  disabled = false,
  onChange = () => void 0,
}: StatusButtonProps) => {
  return (
    <Button
      color={color}
      type="button"
      className={className}
      onClick={onClick}
      selected={selected}
      block={block}
      container={container}
      disabled={disabled}
      onChange={onChange}
    >
      <Icon onClick={null} selected={selected} color={color} />
      {children}
    </Button>
  );
};

export { StatusButton };
