export const getDate = (dateISOString) => {
  const date = new Date(dateISOString);
  return date.getDate().toString();
};

export const getDayOfWeek = (dateISOString) => {
  const date = new Date(dateISOString);
  return daysOfWeek[date.getDay()];
};

export const translateDate = (UTCString) => {
  const splitDate = UTCString.split(' ');
  const dayOfWeek = splitDate[0].replace(',', '');

  const indexDayOfWeek = daysOfWeekEUA.findIndex((element) => {
    return element === dayOfWeek
  });
  const dayOfWeekTranslated = WEEKDAYS_SHORT_CALENDAR[indexDayOfWeek];

  const indexMonth = shortMonthsEUA.findIndex((element) => {
    return element === splitDate[2]
  });
  const monthTranslated = months[indexMonth];

  // return `${dayOfWeekTranslated}, ${splitDate[1]} ${monthTranslated}`;
  return {
    day: splitDate[1], 
    week: dayOfWeekTranslated,
    month: monthTranslated
  }
}

const daysOfWeek = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

export const WEEKDAYS_SHORT_CALENDAR = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb'
];

export const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const daysOfWeekEUA = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
];

const shortMonthsEUA = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
