import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'components/Button';
import { StatusButton } from 'components/StatusButton';
import { Typography } from 'components/Typography';
import useUser from 'hooks/useUser';
import { Call } from 'services/call';
import { ModalTypes } from 'ducks/ui/types';
import { useQueryClient } from 'react-query';
import { useUI } from 'hooks/useUI';
import { CardActions } from './styles';
import { ClientReason } from '../ClientReason';
import { EuNerdReason } from '../EuNerdReason';
import { useState } from 'react';

interface CancelSectionProps {
  callId: number;
  close: () => void;
}

const CancelSection = ({ callId, close }: CancelSectionProps) => {
  const user = useUser();
  const UI = useUI();
  const queryClient = useQueryClient();
  const [loadingButton, setLoadingButton] = useState(false);

  async function handleCancelCall(values) {
    UI.operations.modal.openModal({
      type: ModalTypes.CancelCallConfirmation,
      modalProps: {
        onConfirm: async () => {
          try {
            setLoadingButton(true);
            await Call.cancel(callId, values);
            await queryClient.refetchQueries('calls');
            UI.operations.showToast('Chamado cancelado com sucesso');
            close();
            setLoadingButton(false);
          } catch (err) {
            UI.operations.showToast('Erro ao cancelar o chamado.', {
              type: 'error',
            });
          }
        },
      },
    });
  }

  return (
    <Formik
      onSubmit={handleCancelCall}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required().oneOf(['Client', 'EuNerd']),
        description: Yup.string().required(),
      })}
      initialValues={{
        reason: user.type === 'Client' ? 'Client' : null,
        description: '',
      }}
    >
      {({ values, setFieldValue, isSubmitting, isValid, dirty }) => (
        <Form className="flex flex-col px-6 py-8 pb-8 space-y-4 bg-lighterGray">
          <Typography variant="h2" color="darkestGray">
            Preciso cancelar este chamado
          </Typography>
          {user.type === 'EuNerd' && (
            <>
              <Typography variant="h3" color="darkestGray">
                Motivo do cancelamento
              </Typography>

              <CardActions className="flex flex-col w-full space-y-4">
                <StatusButton
                  selected={values.reason === 'Client'}
                  onClick={() => {
                    setFieldValue('reason', 'Client');
                    setFieldValue('description', '');
                  }}
                  data-testid="client-reason"
                  container
                  className="gap-4 p-4"
                >
                  <div className="flex flex-col w-full text-left">
                    <Typography variant="h3" color="darkerGray">
                      A pedido do cliente
                    </Typography>
                    <Typography variant="p1" color="darkerGray">
                      Multas de cancelamento serão aplicadas normalmente.
                    </Typography>
                  </div>
                </StatusButton>
                <StatusButton
                  selected={values.reason === 'EuNerd'}
                  onClick={() => {
                    setFieldValue('reason', 'EuNerd');
                    setFieldValue('description', '');
                  }}
                  container
                  data-testid="eu-nerd-reason"
                  className="gap-4 p-4"
                >
                  <div className="flex flex-col w-full text-left">
                    <Typography variant="h3" color="darkerGray">
                      Necessidade Eunerd
                    </Typography>
                    <Typography variant="p1" color="darkerGray">
                      Não cobraremos multas pelo cancelamento deste chamado.
                    </Typography>
                  </div>
                </StatusButton>
              </CardActions>
            </>
          )}

          {values.reason &&
            (values.reason === 'Client' ? (
              <ClientReason callId={callId} />
            ) : (
              <EuNerdReason callId={callId} />
            ))}
          <Button
            type="submit"
            disabled={isSubmitting || !dirty || !isValid}
            color="orangeClick"
            variant="outlined"
            size="large"
            block={false}
            onClick={null}
            loading={loadingButton}
          >
            Cancelar este chamado
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export { CancelSection };
