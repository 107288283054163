import { alertWhite } from 'assets/images/icons';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

const AboutNewTechnician = () => {
  return (
    <div className="flex flex-col p-4 space-y-4 rounded-xl bg-lighterGray">
      <div className="flex items-center w-full space-x-2">
        <div className="w-4 h-4 rounded-full bg-darkGray">
          <img src={alertWhite} className="flex-shrink-0" />
        </div>
        <Typography color="darkestGray" variant="h3">
          Sobre vincular novos profissionais
        </Typography>
        <Button
          variant="outlined"
          className="self-end"
          color="white"
          onClick={null}
        >
          Não mostrar
        </Button>
      </div>
      <div className="flex flex-col">
        <Typography color="darkerGray" variant="h3">
          Vínculo automático
        </Typography>
        <Typography color="darkerGray" variant="p1">
          A plataforma fará uma busca inteligente na base de profissionais
          Eunerd para encontrar a melhor opção para este chamado.
        </Typography>
      </div>
      <div className="flex flex-col">
        <Typography color="darkerGray" variant="h3">
          Vínculo manual
        </Typography>
        <Typography color="darkerGray" variant="p1">
          Quando sua organização possui profissionais próprios cadastrados na
          plataforma, você poderá definir manualmente quem responderá ao
          chamado.
        </Typography>
      </div>
    </div>
  );
};

export default AboutNewTechnician;
