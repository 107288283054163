import { TabByStatusTechnicianCallHistory } from './TabByStatusTechnicianCallHistory';

interface TabsByStatusTechnicianCallHistoryProps {
  children: React.ReactNode;
  onClick: (tab: number) => void;
}

const TabsByStatusTechnicianCallHistory = ({
  children,
  onClick,
}: TabsByStatusTechnicianCallHistoryProps) => {
  const statusTabs = [
    {
      tabId: 0,
      title: 'Finalizados',
    },
    {
      tabId: 1,
      title: 'Com pendências',
    },
    {
      tabId: 2,
      title: 'Cancelados',
    },
  ];
  return (
    <>
      <div className="flex flex-col py-4">
        <TabByStatusTechnicianCallHistory data={statusTabs} onClick={onClick} />
      </div>
      {children}
    </>
  );
};

export { TabsByStatusTechnicianCallHistory };
