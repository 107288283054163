/* eslint-disable */
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { useFormikContext } from 'formik';
import { Container } from './styles';

export const CardSendPayment = ({
  currentStatus,
  setCurrentStatus,
  callStatus,
}) => {
  const { values } = useFormikContext<any>();

  const STATUS_PAYMENT = {
    SCHEDULED_PAYMENT: {
      title: 'Pagamento agendado',
      message:
        'O pagamento do chamado será realizado no fechamento do mês corrente.',
      textButton: 'Realizar Pagamento',
    },
    ANTECIPATED_PAYMENT: {
      title: 'Pagamento antecipado',
      message: 'O pagamento será realizado em até 24h úteis.',
      textButton: 'Realizar Pagamento',
    },
    PAYMENT_DECLINED: {
      title: 'Pagamento recusado',
      message:
        values.paymentRefusedJustification ||
        'O pagamento do chamado só poderá ser realizado após o envio da nota fiscal.',
      textButton: 'Enviar Feeedback',
    },
  };

  return (
    <Container>
      <div className="space-y-4">
        <Typography variant="h2" color="darkestGray">
          Status do pagamento
        </Typography>

        <div className="space-y-2">
          <Typography variant="button" color="darkestGray">
            {STATUS_PAYMENT[currentStatus].title}
          </Typography>

          <Typography variant="p1" color="darkerGray">
            {STATUS_PAYMENT[currentStatus].message}
          </Typography>
        </div>
        {callStatus === 'PAID_OUT' && (
          <div className="flex items-center justify-end py-4 space-x-8">
            <button
              onClick={() => setCurrentStatus('PAID_OUT')}
              type="button"
              className="font-bold text-darkerGray hover:bg-purple bg-none"
              disabled={callStatus !== 'PAID_OUT'}
            >
              Voltar
            </button>

            <Button size="large" type="submit" onClick={null}>
              {STATUS_PAYMENT[currentStatus].textButton}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};
