import { useAddressCompanyById } from 'features/Company/hooks';
import { CompanyAddresses } from '..';

const CompanyAddressesTab = ({ companyId }) => {
  const address = useAddressCompanyById(companyId);

  return (
    <div className="py-8 w-ful px-16">
      <CompanyAddresses companyId={companyId} addressesList={address} />
    </div>
  );
};

export { CompanyAddressesTab };
