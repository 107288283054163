import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
  }
  padding: 0 16px 16px 16px;

  border-bottom: 2px solid ${props => props.theme.colors.lightGray};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
`;