export const Media = {
  upload: async (media) => {
    var formdata = new FormData();
    var headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    formdata.append('media', media);

    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    const response = await fetch(
      process.env.NEXT_PUBLIC_API_URL + '/media/upload',
      requestOptions
    );

    const data = await response.json();

    return { url: data.imageUrl, name: media.name };
  },
};
