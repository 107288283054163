import { useRef, useState } from 'react';
import {
  Container,
  InputContainer,
  SearchIcon,
  Input,
  Content,
} from './styles';
import { ServicesSearchBarAutocompleteItem } from './ServicesSearchBarAutocompleteItem';
import { useClickOutside } from 'hooks/useClickOutside';
import { serviceCategoryType } from 'lib/service';
import { searchBlack } from 'assets/images/icons';

export interface ServiceItem {
  id: string;
  name: string;
  description: string;
}

export interface ServicesData {
  [key: string]: ServiceItem[];
}

export interface NewServicesData {
  id: string;
  serviceCategory: string;
  services: ServiceItem[];
}

interface ServicesSearchBarAutocompleteProps {
  data: NewServicesData[];
  disabled?: boolean;
  searchText?: string;
  itemsSelected: string[];
  categorySelected: string[];
  handleSelect: (id: string) => void;
  setSearchText?: (string) => void;
  onChange?: (itemsSeletced: string[]) => void;
  handleSelectCategory: (categoryId: string) => void;
}

const ServicesSearchBarAutocomplete = ({
  data,
  disabled = false,
  searchText,
  setSearchText,
  onChange,
  itemsSelected,
  handleSelect,
  handleSelectCategory,
  categorySelected,
}: ServicesSearchBarAutocompleteProps) => {
  const [focused, setFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFocus = (event) => {
    switch (event.type) {
      case 'focus':
        setFocused(true);
        setIsOpen(true);
        break;
      case 'blur':
        setFocused(false);
        break;
    }
  };

  useClickOutside({
    ref: containerRef,
    action: () => {
      if (onChange) {
        onChange(itemsSelected);
      }
      setIsOpen(false);
    },
  });

  return (
    <Container focused={focused} ref={containerRef}>
      <InputContainer focused={focused}>
        <SearchIcon src={searchBlack} />
        <Input
          placeholder="Digite e selecione serviço(s) que está capacitado a realizar"
          onChange={handleChange}
          value={searchText}
          onFocus={handleFocus}
          onBlur={handleFocus}
          disabled={disabled}
        />
      </InputContainer>
      <Content focused={isOpen}>
        {data &&
          data?.map((item) => (
            <ServicesSearchBarAutocompleteItem
              key={item?.id}
              label={serviceCategoryType[item?.serviceCategory]?.label}
              items={item?.services}
              handleSelectItem={handleSelect}
              itemsSelected={itemsSelected}
              categorySelected={categorySelected.includes(item?.id)}
              handleSelectCategory={() => handleSelectCategory(item?.id)}
            />
          ))}
      </Content>
    </Container>
  );
};

export { ServicesSearchBarAutocomplete };
