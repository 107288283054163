import { fetcher } from 'services/api';
import { buildSearchParams } from 'utils/queryUtils';
import useUser from 'hooks/useUser';
import { useQuery, useQueryClient } from 'react-query';
import { Company } from 'services/company';

interface IQueryCompanies {
  name?: string;
  status?: boolean;
}

export function useCompanies({ name, status }: IQueryCompanies) {
  const queryClient = useQueryClient();
  const { data, error, refetch } = useQuery(
    ['companies', { name, status }],
    () =>
      fetcher(
        `/company?${buildSearchParams({
          name,
          status: status ? 'true' : 'false',
        })}`
      )
  );

  async function prefetchCompany(id) {
    await queryClient.prefetchQuery(['company', id], () =>
      fetcher(`/company/` + id)
    );
  }

  return {
    companies: data?.companies || [],
    isLoading: !error && !data,
    isError: error,
    prefetchCompany,
    refetch,
  };
}

export function useServices() {
  const user = useUser();

  const { data, error } = useQuery('services', () => fetcher('/services'), {
    enabled: Boolean(user),
  });

  return {
    services: data?.services || [],
    isLoading: !error && !data,
    isError: error,
  };
}

export function useCompany(id: string) {
  const { data, error, refetch } = useQuery(
    ['company', id],
    () => fetcher('/company/' + id),
    { enabled: Boolean(id) }
  );
  return {
    company: data?.company || {},
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}

export function useAllCompanies({ status }: { status?: string }) {
  const { data, error } = useQuery(['/company', status], () => {
    return fetcher(
      `/company?${buildSearchParams({ status: status ? 'true' : 'false' })}`
    );
  });
  return {
    companies: data?.companies || [],
    isLoading: !error && !data,
    isError: error,
  };
}

function useTrainings(id) {
  const { data, error, isLoading } = useQuery(['trainings', id], () => Company.getTrainings(id));
  return {
    trainings: data?.result,
    isLoading,
    isError: error
  }
}

export { useTrainings }