import styled, { css } from 'styled-components';

interface ContainerProps {
  active: boolean;
}

export const Container = styled.li<ContainerProps>`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${props => props.theme.colors.lightGray};
  padding: 8px 0;
  cursor: pointer;

  span, p {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 12px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: rgba(128, 107, 255, 0.05);
  }

  ${props => props.active && css`
    background: rgba(128, 107, 255, 0.05);
  `}
`;
