import styled from 'styled-components';

export const SecondFormRow = styled.div`
  display: grid;
  grid-template-columns: 76px repeat(2, minmax(100px, 220px));
  column-gap: 1.5rem;

  input {
    width: 100%;
  }
`;
