import { Typography } from "components/Typography"
import { Button } from "components/Button"
import { FormAdditionalService } from "./FormAdditionalService";
import { useState } from "react";
import { useAdditionalServiceByCallId } from "queries/additionalService";
import { v4 } from "uuid";
import { LoadingSidesheet } from "components/LoadingSidesheet";

interface AdditionalServiceProps {
  callId: number;
  isFinishedCall?: boolean;
}

const AdditionalService = ({ callId, isFinishedCall = false }: AdditionalServiceProps) => {
  const [show, setShow] = useState(false)
  const { listServices, isLoading } = useAdditionalServiceByCallId(callId)

  if (isLoading) {
    return <LoadingSidesheet />;
  }

  return (
    <div className="px-6 py-4 space-y-8">
      <Typography variant="h2" color="darkestGray">
        Serviço adicional
      </Typography>
      {!isFinishedCall && (
        <div className="space-y-4">
          <Button
            size='large'
            type="button"
            width={135}
            onClick={() => { setShow(!show) }}>
            {show ? 'Fechar' : 'Adicionar serviço'}
          </Button>

          {show &&
            <FormAdditionalService
              callId={callId}
              close={() => { setShow(!show) }} />}
        </div>
      )}

      {listServices?.call?.length > 0 ? listServices?.call?.map((item) => (
        <FormAdditionalService callId={callId} listServices={item} key={v4()} isEdit />
      ))
        : <Typography variant="h3" color="darkestGray">
          Nenhum serviço adicional cadastrado.
        </Typography>
      }
    </div>
  )
}


export { AdditionalService }