import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.lightGray};

  width: 390px;

  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Divider = styled.div`
  margin-top: 8px;
  width: 3px;
  height: 68px;
  background: ${({ theme }) => theme.colors.purple};
  opacity: 0.1;

  border-radius: 4px;
`;

export const InfoContent = styled.div`
  min-height: 108px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const DaysTagContainer = styled.div`
  display: flex;
  align-items: center;

  div + div {
    margin-left: 8px;
  }
`;

export const DayTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 32px;

  border-radius: 4px;
  background: ${({ theme }) => theme.colors.purple};
`;
