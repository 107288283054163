import styled from 'styled-components';
import { InstantSearch } from 'react-instantsearch-dom';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InstantSearchContainer = styled(InstantSearch)`
  display: flex;
  flex-direction: column;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

export const BigNumbersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;
