import { Button } from 'components/Button';
import { TextArea } from 'components/Inputs/TextArea';
import { TextField } from 'components/Inputs/TextField';
import { Select } from 'components/Select';
import { Typography } from 'components/Typography';
import { categories } from 'data/formsOfServicesCategories';
import { ModalTypes } from 'ducks/ui/types';
import { Field, Form, Formik } from 'formik';
import { useUI } from 'hooks/useUI';
import React, { useCallback, useState } from 'react';
import { Service } from 'services/service';
import { yupValidations } from 'utils/validations/yup';
import * as Yup from 'yup';

import {
  Container,
  Header,
  Content,
  TextInfoContainer,
  Footer,
  FormGroup,
} from './styles';

export interface NewFormOfServiceProps {
  close(): void;
  onAfterClose(): void;
}

const NewFormOfService = ({ close, onAfterClose }: NewFormOfServiceProps) => {
  const { operations } = useUI();
  const [loading, setLoading] = useState(false);

  const onSave = useCallback(
    async (data) => {
      try {
        setLoading(true);
        await Service.create(data);
        operations.showToast('Tipo de serviço cadastrada com sucesso!');
        close();
        onAfterClose();
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao cadastrar o tipo de serviço';

        if (err.response?.status === 400) {
          erroMessage = err.response.data.message;
        }
        operations.showToast(erroMessage, {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [onAfterClose, operations, close]
  );

  const handleSubmit = useCallback(
    async (data) => {
      operations.modal.openModal({
        type: ModalTypes.ConfirmCreateFormsOfService,
        modalProps: {
          isLoading: loading,
          onConfirm: async () => {
            await onSave(data);
          },
        },
      });
    },
    [onSave, operations, loading]
  );

  return (
    <Formik
      initialValues={{
        name: '',
        category: '',
        description: '',
      }}
      onSubmit={handleSubmit}
      isInitialValid={false}
      validationSchema={Yup.object().shape({
        name: yupValidations.requiredString,
        category: yupValidations.requiredString,
        description: yupValidations.requiredString,
      })}
    >
      {({ setFieldValue, isValid }) => (
        <Form style={{ height: '100%' }} data-testid="new-form-of-service">
          <Container>
            <Header>
              <Typography variant="h1" color="darkBlue">
                Cadastrar tipo de serviço
              </Typography>
            </Header>
            <Content>
              <TextInfoContainer>
                <Typography variant="h2" color="darkBlue">
                  Novo tipo de serviço
                </Typography>
                <Typography variant="p1" color="darkBlue">
                  Adicione novos tipos de serviço que estarão disponíveis para
                  os clientes, eles poderão ser vinculadas em um contrato.
                </Typography>
              </TextInfoContainer>
              <Typography variant="h3" color="darkBlue">
                Dados para cadastro
              </Typography>
              <Field
                component={TextField}
                label="Nome do tipo de serviço"
                name="name"
              />
              <FormGroup>
                <Typography variant="p1" color="darkerGray">
                  Categoria
                </Typography>
                <Select
                  options={categories}
                  getOptionValue={(option) => {
                    if (option) {
                      setFieldValue('category', option.value);
                    }
                  }}
                />
              </FormGroup>
              <Typography variant="h3" color="darkBlue">
                Descrição do novo tipo de serviço
              </Typography>
              <Typography variant="p1" color="darkBlue">
                Será a partir desta descrição que os profissionais e clientes
                farão a identificação do que compete este tipo de serviço.
              </Typography>
              <Field
                component={TextArea}
                label=""
                name="description"
                placeholder="Descrição do serviço"
                onChange={(e) => setFieldValue('description', e.target.value)}
              />
            </Content>
            <Footer>
              <Button variant="anchor" type="button" onClick={() => close()}>
                Cancelar
              </Button>
              <Button
                className="submit-button"
                type="submit"
                loading={loading}
                disabled={loading || !isValid}
              >
                Adicionar tipo de serviço
              </Button>
            </Footer>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export { NewFormOfService };
