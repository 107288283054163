import { Typography } from 'components/Typography';
import { format, formatDistance } from 'date-fns';
import AboutNewTechnician from './components/AboutNewTechnician';
import AlertIcon from './components/AlertIcon';
import NewMessageCard from './components/NewMessageCard';
import NewTechnicianCard from './components/NewTechnicianCard';
import { ptBR } from 'date-fns/locale';
import ContactGuioCard from './components/ContactGuio';
import StatusReviewCard from './components/StatusReviewCard';
import HoursReviewCard from './components/HoursReviewCard';
import { useTechnician } from 'queries/technician';
import { v4 } from 'uuid';

const Notification = ({
  title,
  description,
  actions,
  time,
  callId,
  modelId,
  refetch,
  companyId,
}) => {
  const { technician } = useTechnician(modelId);

  return (
    <div className="flex flex-col py-4 space-y-4">
      <Typography color="darkestGray" variant="overline">
        {formatDistance(new Date(time), new Date(), {
          addSuffix: true,
          locale: ptBR,
        })}{' '}
        -{' '}
        {format(new Date(time), 'HH:mm', {
          locale: ptBR,
        })}
      </Typography>
      <div className="flex space-x-2">
        <AlertIcon />
        <div className="flex flex-col space-y-4">
          <Typography color="darkestBlue" variant="h3">
            {title}
          </Typography>
          <Typography color="darkestGray" variant="p1">
            {description}
          </Typography>
          <div className="flex flex-col space-y-2">
            <Typography color="darkerGray" variant="overline">
              Ações
            </Typography>
            {actions.map((a) => {
              if (a === 'LINK_A_NEW_PROFESSIONAL') {
                return (
                  <div className="flex flex-col space-y-2" key={v4()}>
                    <NewTechnicianCard
                      callId={callId}
                      refetch={refetch}
                      companyId={companyId}
                    />
                    <AboutNewTechnician />
                  </div>
                );
              } else if (a === 'TALK_TO_THE_PROFESSIONAL') {
                return (
                  <NewMessageCard
                    modelId={technician}
                    callId={callId}
                    key={v4()}
                  />
                );
              } else if (a === 'HOURS_REVIEW') {
                return <HoursReviewCard callId={callId} key={v4()} />;
              } else if (a === 'STATUS_REVIEW') {
                return <StatusReviewCard callId={callId} key={v4()} />;
              } else if (a === 'BALANCE_REVIEW') {
                return null;
              } else if (a === 'CONTACT_GUIO') {
                return <ContactGuioCard key={v4()} />;
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
