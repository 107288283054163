import styled from 'styled-components';
import { headerNewCallTemplate as background } from 'assets/images/illustrations';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  min-height: 7rem;
  height: 7rem;
  padding: 2rem 1.5rem;
  background: url(${background});
  /* background-size: cover; */
`;

export const Section = styled.div`
  padding: 1.5rem;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  width: 100%;
  border-radius: 0.5rem;
`;

export const Content = styled.div`
  max-width: 965px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1.5rem;
`;

export const Footer = styled.div`
  padding: 2.75rem 1.5rem;
  display: flex;
  max-width: 965px;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 180px;
  }
`;
