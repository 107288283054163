import { Typography } from 'components/Typography';
import { Container } from './styles';
import { star } from 'assets/images/icons';

interface ReviewProps {
  rating: number;
}

const Review = ({ rating }: ReviewProps) => {
  return (
    <Container>
      <img src={star} alt="Ícone de estrela" />
      <Typography>{rating}</Typography>
    </Container>
  );
};

export { Review };
