import { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from 'components/Typography';
import {
  Container,
  HourPickerContainer,
  HourPickerListContainer,
  HourPickerItem,
} from './styles';
import { useClickOutside } from 'hooks/useClickOutside';
import { inactiveDropdown } from 'assets/images/icons';

interface HourPickerProps {
  value?: number;
  onChange: (hour: number, hourFormatted: string) => void;
  disabled?: boolean;
}

const HourPicker = ({ onChange, disabled = false, value }: HourPickerProps) => {
  const [open, setOpen] = useState(false);
  const [timeSelected, setTimeSelected] = useState<number>(value);
  const selectRef = useRef<HTMLDivElement>(null);
  const hoursList = Array.from({ length: 24 }, (_, i) => i);

  useClickOutside({
    action: () => setOpen(false),
    ref: selectRef,
  });

  const formatTime = (time: number) => {
    return `${String(time).padStart(2, '0')}h`;
  };

  const onSelectItem = useCallback(
    (time: number) => {
      setTimeSelected(time);
      onChange(time, formatTime(time));
    },
    [onChange, formatTime]
  );

  const handleClick = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (value) {
      setTimeSelected(value);
    }
  }, [value]);

  return (
    <Container>
      <HourPickerContainer isOpen={open} onClick={handleClick}>
        <Typography
          variant="button"
          color="darkerGray"
          data-testid="hourpicker"
        >
          {timeSelected !== undefined ? `${formatTime(timeSelected)}` : `xxxx`}
        </Typography>
        <img src={inactiveDropdown} alt="inactive" />
      </HourPickerContainer>
      <HourPickerListContainer isOpen={open}>
        {hoursList.map((time) => (
          <HourPickerItem
            active={time === timeSelected}
            key={time}
            onClick={() => onSelectItem(time)}
          >
            {formatTime(time)}
          </HourPickerItem>
        ))}
      </HourPickerListContainer>
    </Container>
  );
};

export { HourPicker };
