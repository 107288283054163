import { magnifier } from 'assets/images/icons';

interface SearchInputProps {
  placeholder?: string;
  disabled?: boolean;
  setSearchText: (string) => void;
}

const SearchInput = ({
  placeholder = 'Buscar',
  disabled = false,
  setSearchText,
}: SearchInputProps) => {
  return (
    <div className="relative flex items-center h-8">
      <div className="absolute ml-3 items-center flex rounded-md pointer-events-none bg-purple p-[2px]">
        <img src={magnifier} alt="Ícone lupa" />
      </div>

      <input
        disabled={disabled}
        type="search"
        className="block w-full h-full pl-8 font-bold border-2 rounded-md cursor-pointer sm:text-sm text-darkerGray placeholder-lightGray border-lightGray focus:border-purple hover:border-purple "
        placeholder={placeholder}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};
export { SearchInput };
