/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from 'react';
import { LIMIT_PAGE } from '../PaginationComponent';

interface usePaginationDataProps {
  //@ts-ignore
  objectData?: Array<T>;
  currentPage?: number;
  limitPage?: number;
}

function usePaginationData({
  objectData,
  currentPage,
  limitPage = LIMIT_PAGE,
}: usePaginationDataProps) {
  const data = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * limitPage;
    const lastPageIndex = firstPageIndex + limitPage;
    return objectData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, limitPage, objectData]);

  return data;
}
export { usePaginationData };
