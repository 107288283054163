import { Container } from './styles';
import { Typography } from 'components/Typography';

const Tag = ({ children }) => {
  return (
    <Container>
      <Typography variant="tag" color="darkerGray">
        {children}
      </Typography>
    </Container>
  );
};

export { Tag };
