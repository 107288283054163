import React from 'react';
import { Typography } from 'components/Typography';
import { Container } from './styles';
import { callsError } from 'assets/images/illustrations';

export const CallsError = () => {
  return (
    <Container>
      <img className="mb-10" src={callsError} />
      <Typography className="mb-4" variant="h2" color="darkBlue">
        Ops, tivemos um erro
      </Typography>
      <Typography>Por favor, tente novamente</Typography>
    </Container>
  );
};
