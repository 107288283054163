import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { SearchCard } from 'components/SearchCard';
import { VerticalSeparator } from 'components/VerticalSeparator';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { Company } from 'services/company';
import { useCompany } from 'queries/clients';
import { Alert } from 'components/AlertComponents/Alert';
import { plusSign } from 'assets/images/icons';

import { CreateCompanyTypes, ServiceTypeTypes } from 'features/Company/types';
import { ServiceAddedList } from '../ServiceAddedList';
import { noCustomers } from 'assets/images/illustrations';

const FormTypesOfServiceCompany = ({ isEdit = false }) => {
  const { values, setFieldValue } = useFormikContext<CreateCompanyTypes>();
  const [searchResults, setSearchResults] = useState<ServiceTypeTypes[]>(null);

  const UI = useUI();
  const company = useCompany(values.id);

  const handleAddServiceType = () => {
    UI.operations.sidesheet.openSidesheet({
      type: SidesheetTypes.SidesheetAddServiceCompany,
      sidesheetProps: {
        isEdit: false,
        width: 'normal',
        usedServiceIds: values.serviceType?.map((i) => i.service?.id),
        onCreate: async (services: ServiceTypeTypes[]) => {
          setFieldValue('serviceType', [...values.serviceType, ...services]);
        },
        servicesUser: company.company.serviceType,
        serviceTypeUser: values.serviceType,
      },
    });
    setSearchResults(null);
  };

  const handleEditServiceType = (serviceId: string) => {
    UI.operations.sidesheet.openSidesheet({
      type: SidesheetTypes.SidesheetAddServiceCompany,
      sidesheetProps: {
        servicesUser: company.company.serviceType,
        serviceTypeUser: values.serviceType,
        width: 'normal',
        usedServiceIds: values.serviceType.map((i) => i.service?.id),
        serviceId,
        isEdit: true,
        data: values.serviceType.find((i) => i.service.id === serviceId),
        onCreate: async (services: ServiceTypeTypes[]) => {
          const servicesIds = services.map((item) => {
            return item.service.id;
          });
          setFieldValue('serviceType', [
            ...values.serviceType.filter((e) => {
              return !servicesIds.includes(e.service.id);
            }),
            ...services,
          ]);
        },
      },
    });
    setSearchResults(null);
  };

  const handleDelete = async (serviceID, id) => {
    if (isEdit) {
      const findService = company.company.serviceType.find(
        (item) => item.id == serviceID
      );
      findService && (await Company.deleteServiceType(serviceID));
      findService && (await company.refetch());
    }
    setFieldValue(
      'serviceType',
      values.serviceType.filter((service) => service.service.id !== id)
    );
  };

  const EmptyState = ({ addServiceType }) => {
    return (
      <div className="flex flex-col items-center space-y-4">
        <img src={noCustomers} alt="Icone vazio" width="100" height="100" />
        <p className="text-2xl font-bold">
          Você não possui tipos de serviços cadastrados
        </p>
        <Button onClick={addServiceType}>Adicionar Tipo de serviço</Button>
      </div>
    );
  };

  const searchServices = (name: string) => {
    if (Array.isArray(searchResults)) {
      if (searchResults.length === 0) {
        setSearchResults(null);
      }
    }
    if (name === '') {
      setSearchResults(null);
    } else {
      setSearchResults(
        values.serviceType.filter((item) =>
          item.service.name.toLowerCase().match(name.toLowerCase())
        )
      );
    }
  };

  return (
    <div
      className="bg-white flex flex-col max-h-[min-content] mb-auto overflow-y-auto px-16 py-6 space-y-4"
      data-testid="form-types-of-service-company"
    >
      {isEdit && (
        <Alert type="danger">
          As alterações realizadas em <b>Tipos de serviços</b> somente serão
          aplicadas a partir do próximo mês
          <b> {'01/' + (new Date().getMonth() + 2)}</b>.
        </Alert>
      )}
      <h2 className="mb-4 text-2xl font-bold">Serviços disponíveis</h2>
      <p className="text-sm text-darkerGray">
        Abaixo são apresentados todos os tipos de serviços disponíveis acordado
        previamente por contrato com o cliente
      </p>
      <div className="flex mt-4 space-x-4">
        {values.serviceType.length > 0 && (
          <>
            <SearchCard
              onChange={(value) => searchServices(value)}
              placeholder="Buscar tipo de serviço"
            />
            <VerticalSeparator />
          </>
        )}
        <IconButton icon={plusSign} onClick={handleAddServiceType} size="large">
          Adicionar tipo de serviço
        </IconButton>
      </div>

      <div className="flex flex-col mt-6">
        <h3 className="font-bold text-md">Tipos de serviços cadastrados</h3>
        {values.serviceType?.length > 0 ? (
          <ServiceAddedList
            services={searchResults ?? values.serviceType}
            onEdit={handleEditServiceType}
            onDelete={handleDelete}
          />
        ) : (
          <EmptyState addServiceType={handleAddServiceType} />
        )}
      </div>
    </div>
  );
};

export { FormTypesOfServiceCompany };
