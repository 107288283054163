import { useCallback, useState } from 'react';
import { Typography } from 'components/Typography';
import { Container } from './styles';
import { SelectSearch } from 'components/SelectSearch';
import { SearchOptionItem } from 'components/SelectSearch/SearchContainer/SearchOption';

interface templateDropdownSearchProps {
  templates: SearchOptionItem[];
  onChange?: ({ id, name }) => void;
  disabled: boolean;
  value?: string;
}

const TemplateDropdownSearch = ({
  templates,
  onChange,
  disabled,
  value = '',
}: templateDropdownSearchProps) => {
  const [_, setSearchText] = useState(value);

  const handleClick = useCallback(
    ({ id, name }) => {
      setSearchText(name);
      onChange({ id, name });
    },
    [onChange]
  );

  return (
    <Container>
      <Typography variant="p1" color="darkerGray">
        Template de chamado
      </Typography>
      <SelectSearch
        data={templates?.map((template) => template) || []}
        getItemSelected={handleClick}
        placeholder="Selecione"
        disabled={disabled}
        data-testid="select-call-template"
      />
    </Container>
  );
};

export { TemplateDropdownSearch };
