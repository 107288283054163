import '../styles/tailwind.css';
import '../styles/main.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NextNprogress from 'nextjs-progressbar';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from 'styled-components';
import { GoogleFonts } from 'next-google-fonts';
import { ToastProvider } from '../components/UI/Toast/ToastHandler';
import { Provider } from 'react-redux';
import store from 'redux/store';
import theme from 'themes';
import { QueryClient, QueryClientProvider } from 'react-query';
import Head from 'next/head';

const queryClient = new QueryClient();

import { UIRoot } from 'components/UI/UIRoot';
import Hidden from '@material-ui/core/Hidden';
import { Mobile } from 'components/Mobile';
import { header } from 'assets/images/illustrations';

const Noop = ({ children }) => <>{children}</>;

function MyApp({ Component, pageProps }) {
  const Layout = Component.Layout || Noop;
  const { technicianId } = pageProps;

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114./png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120./png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x/192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <GoogleFonts href="https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap" />
      <DefaultSeo
        // titleTemplate={router.route === '/' ? 'Guio' : '%s | Guio'}
        defaultTitle="Guio"
        description="Guio"
        openGraph={{
          type: 'website',
          locale: 'pt_BR',
          url: process.env.NEXT_PUBLIC_SITE_URL,
          site_name: 'Guio',
        }}
      />
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <Hidden xsDown>
                <Layout>
                  <UIRoot />
                  <NextNprogress
                    color="#806BFF"
                    startPosition={0.3}
                    stopDelayMs={200}
                    height={3}
                    showOnShallow={true}
                  />
                  <Component {...pageProps} />
                </Layout>
              </Hidden>
              <Hidden smUp>
                <div className="flex absolute w-full h-full overflow-hidden bg-purpleHover">
                  <img
                    src={header}
                    className="flex absolute right-0 max-h-screen pointer-events-none z-0"
                  />
                  <Mobile technicianId={technicianId} />
                </div>
              </Hidden>
            </ToastProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
