import { useQuery } from 'react-query';
import { fetcher } from 'services/api';
import { buildSearchParams } from 'utils/queryUtils';
import {
  GetAddressCompany,
  GetAddressCompanyByAddressId,
  GetAddressCompanyById,
} from '../services';

interface useCompaniesTableProps {
  name?: string;
  status: boolean;
}

function useCompaniesTable({ name, status }: useCompaniesTableProps) {
  const { data, error, refetch } = useQuery(
    ['company', 'companies-table', { name, status }],
    () => {
      return fetcher(
        `/company/companies-table?${buildSearchParams({
          name,
          status: status ? 'true' : 'false',
        })}`
      );
    }
  );
  return {
    companies: data?.companies || [],
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}

function useAdressCompany() {
  const { data, refetch } = useQuery(['address', 'address-company'], () => {
    return GetAddressCompany();
  });
  return {
    address: data?.data || [],
    refetch,
  };
}

function useAddressCompanyById(companyId) {
  const { data } = useQuery(
    ['address', 'address-company', companyId],
    () => {
      return GetAddressCompanyById(companyId);
    },
    { enabled: Boolean(companyId) }
  );

  return {
    address: data?.data || [],
  };
}

function useAddressByAddressId(addressId) {
  const { data } = useQuery(
    ['address', 'address-company', addressId],
    () => {
      return GetAddressCompanyByAddressId(addressId);
    },

    { enabled: Boolean(addressId) }
  );
  return {
    address: data?.data || [],
  };
}

export {
  useCompaniesTable,
  useAdressCompany,
  useAddressCompanyById,
  useAddressByAddressId,
};
