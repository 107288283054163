import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ModalTypes,
  NotifyPayload,
  OpenConfirmationPayload,
  OpenModalPayload,
  OpenSidesheetPayload,
  SidesheetTypes,
  State,
} from './types';

const initialState: State = {
  currentModal: null,
  modalIsOpen: false,
  sidesheetIsOpen: false,
  messages: [],
  componentList: [],
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    clearMessages: (state) => {
      state.messages = [];
    },
    notify: (state, action: PayloadAction<NotifyPayload>) => {
      state.messages.push({ type: 'success', ...action.payload });
    },
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      state.currentModal = {
        type: action.payload.type,
        props: action.payload.modalProps,
      };
      state.modalIsOpen = true;
      state.componentList.push({ componentType: 'modal', ...action.payload });
    },
    popComponentList: (state) => {
      state.componentList.pop();
    },
    closeModal: (state) => {
      state.modalIsOpen = false;
    },
    openSidesheet: (state, action: PayloadAction<OpenSidesheetPayload>) => {
      state.sidesheetIsOpen = true;
      state.componentList.push({
        componentType: 'sidesheet',
        open: true,
        ...action.payload,
      });
    },
    closeUI: (state, action: PayloadAction<boolean>) => {
      state.componentList[
        state.componentList.length - (action.payload ? 2 : 1)
      ].open = false;
    },
  },
  extraReducers: {},
});

export default { uiSlice, uiActions: uiSlice.actions };
