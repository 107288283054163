import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Document = styled.div`
  width: 100%;
  height: 145px;
  border-radius: 6px;
  border: 2px solid ${(props) => props.theme.colors.lighterGray};

  img {
    width: 100%;
    height: 145px;
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
  }
`;

export const ButtonDownload = styled.div`
  position: absolute;
  right: 3%;
  bottom: 2%;
`;

const animationPulse = keyframes`
     0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }

`;

export const ContainerSkeleton = styled.div`
  border-radius: 6px;
  width: 100%;
  height: 145px;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: ${animationPulse} 1.2s ease-in-out infinite;
`;
