import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Image from 'next/image';
import { Button } from 'components/Button';
import { ModalTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { useCompany } from 'queries/clients';
import { Company } from 'services/company';
import { useClientPermissions } from 'hooks/permissions/useClientPermissions';
import { call } from 'assets/images/illustrations';

const FooterSidesheetCompany = ({ companyId, close }) => {
  const UI = useUI();
  const { company, refetch } = useCompany(companyId);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [loadingButtonClient, setLoadingButtonClient] = useState(false);
  const { canUpdateClient } = useClientPermissions();
  const queryClient = useQueryClient();

  async function handleDeactivate() {
    UI.operations.confirm(
      'Desativar cliente',
      'Tem certeza que deseja desativar este cliente?',
      async () => {
        try {
          setLoadingButtonClient(true);
          await Company.deactivate(company.id);
          await refetch();
          await queryClient.refetchQueries(['company']);
          close();
          UI.operations.showToast('Cliente desativado com sucesso.');
        } catch (err) {
          UI.operations.showToast('Erro ao desativar este cliente.', {
            type: 'error',
          });
        } finally {
          setLoadingButtonClient(false);
        }
      },
      {
        icon: (
          <Image
            src={call}
            width="100"
            height="100"
            alt="Icone de uma folha de papel"
          />
        ),
        cancelText: 'Não',
        confirmText: 'Sim, desativar cliente',
        danger: true,
      }
    );
  }

  async function handleActivate() {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        title: 'Ativar cliente',
        description: 'Tem certeza que deseja ativar este cliente?',
        cancelText: 'Não',
        confirmText: 'Sim, ativar cliente',
        icon: (
          <Image
            src={call}
            width="100"
            height="100"
            alt="Icone de uma folha de papel"
          />
        ),
        cancelRed: true,
        onConfirm: async () => {
          try {
            setLoadingButtonClient(true);
            await Company.activate(company.id);
            await refetch();
            await queryClient.refetchQueries(['company']);
            close();
            UI.operations.showToast('Cliente ativado com sucesso.');
          } catch (err) {
            UI.operations.showToast('Erro ao ativar este cliente.', {
              type: 'error',
            });
          } finally {
            setLoadingButtonClient(false);
          }
        },
      },
    });
  }

  async function handleDelete() {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        title: 'Excluir cliente',
        description: (
          <>
            Tem certeza que deseja excluir este cliente?
            <br />
            <br />
            Você está excluindo os dados <strong>permanentemente.</strong>
            <br />
            Após a confirmação, as informações serão apagadas no sistema
          </>
        ),
        cancelText: 'Não',
        confirmText: 'Sim, excluir cliente',
        icon: (
          <Image
            src={call}
            width="100"
            height="100"
            alt="Icone de uma folha de papel"
          />
        ),
        danger: true,
        onConfirm: async () => {
          try {
            setLoadingButtonDelete(true);
            await Company.delete(company.id);
            await refetch();
            await queryClient.refetchQueries(['company']);
            close();
            UI.operations.showToast('Cliente excluido com sucesso.');
          } catch (err) {
            UI.operations.showToast('Erro ao excluir cliente.', {
              type: 'error',
            });
          } finally {
            setLoadingButtonDelete(false);
          }
        },
      },
    });
  }

  return (
    <>
      <div className="flex justify-between w-full py-5 border-t-2 justify-self-end bg-lighterGray border-gray px-14">
        <div className="flex justify-end w-full">
          <div className="flex items-center justify-center space-x-4">
            {company.status === false && (
              <Button
                loading={loadingButtonDelete}
                type="button"
                color="orange"
                onClick={handleDelete}
                disabled={loadingButtonClient}
              >
                Excluir cliente
              </Button>
            )}

            <Button
              type="button"
              color={company.status ? 'orange' : 'purple'}
              onClick={company.status ? handleDeactivate : handleActivate}
              loading={loadingButtonClient}
              disabled={!canUpdateClient || loadingButtonDelete}
            >
              {company.status ? 'Desativar' : 'Ativar'} cliente
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export { FooterSidesheetCompany };
