import { plusSign } from 'assets/images/icons';
import { Button as StyledButton, IconContainer, Icon } from './styles';

type SizeType = 'medium' | 'small' | 'large';

type ButtonType = 'button' | 'submit' | 'reset';

export interface IconButtonProps {
  children: React.ReactNode;
  color?: string;
  disabled?: boolean;
  filled?: boolean;
  icon?: string;
  onClick?: () => void;
  size?: SizeType;
  type?: ButtonType;
  width?: string;
}

const IconButton = ({
  children,
  color = 'purple',
  disabled = false,
  filled = false,
  icon = plusSign,
  onClick,
  size = 'medium',
  type = 'button',
  width = 'large',
}: IconButtonProps) => {
  return (
    <StyledButton
      color={color}
      type={type}
      onClick={onClick}
      width={width}
      filled={filled}
      size={size}
      disabled={disabled}
    >
      <IconContainer onClick={null} size={size}>
        <Icon src={icon} />
      </IconContainer>
      {children}
    </StyledButton>
  );
};

export { IconButton };
