import * as Yup from 'yup';
import { Typography } from 'components/Typography';
import { ModalTypes } from 'ducks/ui/types';
import { Form, Formik } from 'formik';
import { useUI } from 'hooks/useUI';
import React, { useCallback, useState } from 'react';
import { CollaboratorService } from 'services/collaborator';
import { AccessPermissions } from './AccessPermissions';
import { RegistrationInformation } from './RegistrationInformation';

import { Container, Header, Content, Footer } from './styles';
import { yupValidations } from 'utils/validations/yup';
import useUser from 'hooks/useUser';
import { Button } from 'components/Button';

interface NewUserProps {
  close(): void;
}

const NewUser = ({ close }: NewUserProps) => {
  const [loading, setLoading] = useState(false);
  const { operations } = useUI();
  const user = useUser();

  const onSave = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const payload = {
          companyId: user.companyId,
          ...data,
          phone: [{ phone: data.phone }],
          status: true,
          type: user?.type
        };

        await CollaboratorService.create(payload);
        operations.showToast('Colaborador cadastrado com sucesso!');
        close();
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao cadastrar o colaborador';
        if (err.response?.status === 400) {
          erroMessage =
            err.response.data.error?.message[0] || err.response.data.message;
        }
        operations.showToast(erroMessage, {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [operations, user, close]
  );

  const handleSubmit = useCallback(
    async (data) => {
      operations.modal.openModal({
        type: ModalTypes.ConfirmCreateCollaborator,
        modalProps: {
          isLoading: loading,
          onConfirm: async () => {
            await onSave(data);
          },
        },
      });
    },
    [onSave, operations, loading]
  );

  const getValidationSchema = useCallback(() => {
    return Yup.object().shape({
      name: yupValidations.requiredString,
      role: yupValidations.requiredString,
      email: yupValidations.requiredEmail,
      adjutancy: yupValidations.requiredString,
      phone: yupValidations.requiredString,
    });
  }, []);

  return (
    <Container data-testid="new-user">
      <Header>
        <Typography variant="h1" color="darkBlue">
          Cadastrar colaborador
        </Typography>
      </Header>
      <Formik
        initialValues={{
          name: '',
          role: '',
          adjutancy: '',
          email: '',
          phone: '',
        }}
        isInitialValid={false}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
      >
        {({ isValid }) => (
          <Form>
            <Content>
              <RegistrationInformation />
              <AccessPermissions />
            </Content>
            <Footer>
              <Button
                type="submit"
                loading={loading}
                disabled={!isValid}
                onClick={null}
              >
                Salvar usuário
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export { NewUser };
