import { useMemo } from 'react';
import { Typography } from 'components/Typography';
import { Container, Content, Summary, Actions } from './styles';
import { ReviewCallHourItem } from './ReviewCallHourItem';
import { Button } from 'components/Button';
import { format, intervalToDuration } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatCurrency } from 'utils/formatCurrency';
import { formatUTCHour } from 'utils/formatUtils';
import { Call } from 'types/call';

type CallTypes = Call & {
  additionalCosts: {
    calculatedOvertime: number;
    callEndTime: Date;
    callStartTime: Date;
    overtimeCost: number;
  };
};
interface ReviewCallHoursProps {
  call: CallTypes;
  onConfirmExtraHours(value: boolean): void;
}

const ReviewCallHours = ({
  call,
  onConfirmExtraHours,
}: ReviewCallHoursProps) => {
  const callHourInterval = useMemo(() => {
    return intervalToDuration({
      start: formatUTCHour(call?.startTime),
      end: formatUTCHour(call?.finalTime),
    });
  }, [call]);

  const extraHourInterval = useMemo(
    () =>
      call?.additionalCosts?.calculatedOvertime
        ? intervalToDuration({
            start: 0,
            end: call?.additionalCosts?.calculatedOvertime * 60 * 1000,
          })
        : {},
    [call?.additionalCosts?.calculatedOvertime]
  );

  const callTimeString = callHourInterval.hours
    ? `${callHourInterval.hours} horas e ${callHourInterval.minutes} minutos`
    : `${callHourInterval.minutes || 0} minutos`;

  const additionalTimeString = extraHourInterval.hours
    ? `${extraHourInterval.hours} horas e ${extraHourInterval.minutes} minutos`
    : `${extraHourInterval.minutes || 0} minutos`;

  return (
    <Container>
      <Typography variant="p1" color="darkestGray">
        Este chamado utilizou horas além do agendado. Utilize a linha do tempo
        ao lado para verificar como as horas foram utilizadas.
      </Typography>
      <Content>
        <ReviewCallHourItem
          time={callTimeString}
          timeInterval={`De ${format(new Date(call?.startTime), "H'h'mm", {
            locale: ptBR,
          })} à 
          ${format(new Date(call?.finalTime), "H'h'mm", { locale: ptBR })}`}
          total={call?.value}
        />
        <ReviewCallHourItem
          time={additionalTimeString}
          timeInterval={`De ${format(
            new Date(call?.additionalCosts?.callStartTime),
            "H'h'mm",
            { locale: ptBR }
          )} à 
          ${format(new Date(call?.additionalCosts?.callEndTime), "H'h'mm", {
            locale: ptBR,
          })}`}
          total={call?.value + (call?.additionalCosts?.overtimeCost || 0)}
          type="extra"
          valueDetail={`${formatCurrency(call?.value)} + ${formatCurrency(
            call?.additionalCosts?.overtimeCost || 0
          )}`}
        />
        {call?.additionalCosts?.calculatedOvertime > 0 && (
          <Summary>
            <Typography variant="p1" color="darkerGray">
              Este chamado utilizou <b>{additionalTimeString}</b> a mais que o
              inicialmente planejado, e por isso terá um custo adicional de{' '}
              <b>{formatCurrency(call?.additionalCosts?.overtimeCost)}</b>.
            </Typography>

            <Typography variant="p1" color="darkerGray">
              Com base na linha do tempo do chamado, você autoriza o uso de
              horas extras?
            </Typography>
            <Actions>
              <Button
                variant="outlined"
                onClick={() => onConfirmExtraHours(true)}
              >
                Confirmar
              </Button>
              <Button
                variant="outlined"
                color="orange"
                onClick={() => onConfirmExtraHours(false)}
              >
                Remover horas extras
              </Button>
            </Actions>
          </Summary>
        )}
      </Content>
    </Container>
  );
};

export { ReviewCallHours };
