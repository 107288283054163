import api from "./api";

export type createAdditionalService = {
    additionalServiceId: string,
    callId: number,
    title: string,
    description: string,
    value: number,
    attachments?: [],
    id?: string
}

const AdditionalService = {
    getAdditionalServiceTypes: async () => {
        const data = await api.get('/call-additional-service/types');
        return data.data;
    },
    create: async (payload: createAdditionalService) => {
        const data = await api.post('/call-additional-service', payload);
        return data.data;
    },
    update: async (id, payload) => {
        const data = await api.patch(`/call-additional-service/${id}`, payload);
        return data.data;
    },
    delete: async (id) => {
        const data = await api.delete(`/call-additional-service/${id}`);
        return data.data;
    },
    getById: async (id) => {
        const data = await api.get(`/call-additional-service/${id}`);
        return data.data;
    },
    getServiceByCallId: async (callId) => {
        const data = await api.get(`/calls/additional-service/${callId}`);
        return data.data;
    }
}

export { AdditionalService }