import { useMemo } from 'react';
import { ProfileProfessionalTypes } from '../ListOfAvailableProfessionals';
import { AccordionProfessional } from '../../AccordionProfessional';

interface ListOtherProfessionalsProps {
  dataTechnicians: ProfileProfessionalTypes[];
  search: string;
}

const ListOtherProfessionals = ({
  dataTechnicians,
  search,
}: ListOtherProfessionalsProps) => {
  const filterByname = useMemo(() => {
    return dataTechnicians?.filter((professional) =>
      professional.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [dataTechnicians, search]);

  return (
    <div className="w-full">
      <AccordionProfessional dataTechnicians={filterByname} />
    </div>
  );
};

export { ListOtherProfessionals };
