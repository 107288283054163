import styled, { css } from 'styled-components';
import { Typography } from 'components/Typography';

interface ToggleProps {
  checked: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  height: 2.5rem;
  width: 4.5rem;
  padding: 0 0.5rem;
  cursor: pointer;
`;

export const ToggleCircle = styled.div<ToggleProps>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: ${({ theme, checked }) =>
    checked ? theme.colors.purple : theme.colors.orange};
  position: absolute;
  left: 0.5rem;
  transition: 0.2s;

  ${({ checked }) =>
    checked
      ? css`
          transform: translateX(30px);
        `
      : null};
`;

export const ToggleText = styled(Typography)<ToggleProps>`
  transition: 0.2s;
  ${({ checked }) =>
    checked
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `};
`;
