import { useEffect, useState } from 'react';
import { HourPicker } from './HourPicker';
import { MinutePicker } from './MinutePicker';

import { Container, Divider } from './styles';

export interface TimePickerRange {
  firstTime: number;
  secondTime: number;
  firstTimeFormatted: string;
  secondTimeFormatted: string;
}

interface TimePickerProps {
  onChange: (time: TimePickerRange) => void;
  dateValue?: Date;
  disabled?: boolean;
}

const TimePicker = ({
  onChange,
  dateValue,
  disabled = false,
}: TimePickerProps) => {
  const [time, setTime] = useState<TimePickerRange>();

  const onChangeFirstTime = (firstTime: number, firstTimeFormatted: string) => {
    setTime({
      ...time,
      firstTime,
      firstTimeFormatted,
    });
    onChange({
      ...time,
      firstTime,
      firstTimeFormatted,
    });
  };

  const onChangeMinutes = (secondTime: number, secondTimeFormatted) => {
    setTime({
      ...time,
      secondTime,
      secondTimeFormatted,
    });
    onChange({
      ...time,
      secondTime,
      secondTimeFormatted,
    });
  };

  useEffect(() => {
    if (dateValue) {
      const dateHours =
        dateValue instanceof Date
          ? dateValue?.getUTCHours()
          : new Date(dateValue).getUTCHours();
      const firstTimeFormatted = `${String(dateHours).padStart(2, '0')}:00`;
      onChangeFirstTime(dateHours, firstTimeFormatted);
    }
  }, [dateValue]);

  return (
    <Container disabled={disabled}>
      <HourPicker
        value={time?.firstTime}
        onChange={onChangeFirstTime}
        disabled={disabled}
      />
      <Divider />
      <MinutePicker
        value={time?.secondTime}
        onChange={onChangeMinutes}
        disabled={disabled}
      />
    </Container>
  );
};

export { TimePicker };
