import clsx from 'clsx';

const placements = {
  'top-left': { top: 0, left: 0 },
  'top-center': { top: 0, left: '50%', transform: 'translateX(-50%)' },
  'top-right': { top: 0, right: 0 },
  'bottom-left': { bottom: 0, left: 0 },
  'bottom-center': { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
  'bottom-right': { bottom: 0, right: 0 },
};

const ToastContainer = ({ hasToasts, placement, ...props }) => (
  <div
    className={clsx(
      'react-toast-notifications__container p-2 fixed z-[12000] overflow-hidden max-w-full max-h-full',
      {
        'pointer-events-none': !hasToasts,
      }
    )}
    style={{
      ...placements[placement],
    }}
    {...props}
  />
);

export default ToastContainer;
