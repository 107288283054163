import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { exitArrow } from 'assets/images/icons';

const CallSidesheetWrapper = ({ isOpen, children, close }) => {
  return (
    <Transition show={isOpen} as={Fragment} appear>
      <div className="absolute inset-0 w-full min-h-full overflow-hidden">
        <div className="absolute left-0 z-10 flex w-2/3 pr-10">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative w-full max-h-screen min-h-full">
              <div className="m-4 overflow-hidden bg-white rounded-md ">
                {children}
              </div>
              <Transition.Child
                as={Fragment}
                enter={`ease-in-out duration-500`}
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave={`ease-in-out duration-500`}
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 flex pt-4 pb-4 pr-2 -mr-14">
                  <button
                    onClick={close}
                    data-testid="exit-button"
                    className="flex items-center justify-center bg-purple rounded-md w-[36px] h-[60px] focus:outline-none"
                  >
                    <img src={exitArrow} className="transform rotate-180" />
                    <span className="sr-only">Close panel</span>
                  </button>
                </div>
              </Transition.Child>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default CallSidesheetWrapper;
