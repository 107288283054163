import { Field, useFormikContext } from 'formik';
import { cnpjMask, contactMask } from 'utils/maskUtils';
import { InputField } from 'components/InputField';
import { UploadLogoButton } from 'components/UploadLogoButton';
import uploadAttachments from 'services/attachments';
import { useCallback } from 'react';
import { useUI } from 'hooks/useUI';
import { Typography } from 'components/Typography';
import { CreateCompanyTypes } from 'features/Company/types';

const FormCompanyData = () => {
  const { values, setFieldValue } = useFormikContext<CreateCompanyTypes>();
  const UI = useUI();

  const handleUpload = useCallback(
    async (file) => {
      try {
        const attachment = await uploadAttachments([file]);
        setFieldValue('avatar', attachment[0].url);
      } catch (err) {
        UI.operations.showToast('Erro ao selecionar item', {
          type: 'error',
        });
      }
    },
    [UI.operations, setFieldValue]
  );

  return (
    <div
      className="bg-white flex flex-col max-h-[min-content] mb-auto overflow-y-auto"
      data-testid="form-company-data"
    >
      <div className="flex flex-col py-4">
        <div className="flex flex-col px-16 py-4">
          <div className="flex items-center space-x-4">
            <Typography variant="h3" color="darkestGray">
              Dados do cliente
            </Typography>
          </div>
          <div className="flex flex-row w-full mt-4 space-x-4">
            <div className="flex flex-col w-full space-y-4">
              <UploadLogoButton
                onChange={handleUpload}
                image={values?.avatar?.url}
              />
              <div className="flex flex-col w-full space-y-4">
                <div className="flex w-full space-x-4">
                  <div className="w-1/2">
                    <Field
                      component={InputField}
                      label="Nome Fantasia"
                      name="fantasyName"
                    />
                  </div>
                  <div className="w-1/2">
                    <Field
                      component={InputField}
                      label="Razão Social"
                      name="name"
                    />
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="w-1/2">
                    <Field
                      component={InputField}
                      label="CNPJ"
                      name="cnpj"
                      onChange={(e) => {
                        const value = cnpjMask(e.target.value);
                        setFieldValue('cnpj', value);
                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <Field
                      component={InputField}
                      label="Inscrição Estadual"
                      name="stateRegistration"
                      optional
                    />
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="w-1/2">
                    <Field
                      component={InputField}
                      label="Inscrição Municipal"
                      name="municipalRegistration"
                      optional
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col w-full px-16 py-6 space-y-4 border-t-2 border-gray">
          <h2 className="mb-4 font-semibold text-md text-darkBlue">
            Responsável
          </h2>
          <Field
            component={InputField}
            label="Nome Responsável"
            name="responsible.name"
          />
          <div className="flex flex-row mt-4 space-x-4">
            <div className="w-1/2 space-y-4">
              <Field
                component={InputField}
                label="Cargo"
                name="responsible.adjutancy"
              />
              <Field
                component={InputField}
                label="Telefone"
                name="responsible.phone.0.phone"
                onChange={(e) => {
                  const value = contactMask(e.target.value);
                  setFieldValue('responsible.phone[0].phone', value);
                }}
                optional
              />
              <Field
                component={InputField}
                label="Telefone"
                name="responsible.phone[1].phone"
                optional
                onChange={(e) => {
                  const value = contactMask(e.target.value);
                  setFieldValue('responsible.phone[1].phone', value);
                }}
                
              />
            </div>
            <div className="w-1/2 space-y-4">
              <Field
                component={InputField}
                label="E-mail"
                name="responsible.email"
              />
              <Field
                component={InputField}
                label="Ramal"
                name="responsible.phone[0].branchLine"
                optional
              />
              <Field
                component={InputField}
                label="Ramal"
                name="responsible.phone[1].branchLine"
                optional
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FormCompanyData };
