import { Typography } from 'components/Typography';
import { Container, InfoSection } from './styles';
import { phoneMask } from 'utils/maskUtils';
import { inactiveEmail, inactivePhone } from 'assets/images/icons';

interface ProfessionalContactProps {
  email: string;
  telephone: string;
}

const ProfessionalContact = ({
  email,
  telephone,
}: ProfessionalContactProps) => {
  return (
    <Container>
      <Typography variant="p1" color="darkestGray">
        Contato
      </Typography>
      <InfoSection>
        <img src={inactivePhone} alt="icon inactive phone" />
        <Typography variant="p2" color="darkerGray">
          {phoneMask(telephone)}
        </Typography>
      </InfoSection>
      <InfoSection>
        <img src={inactiveEmail} alt="icon inactive email" />
        <Typography variant="p2" color="darkerGray">
          {email}
        </Typography>
      </InfoSection>
    </Container>
  );
};

export { ProfessionalContact };
