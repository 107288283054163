import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Content = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.lighterGray};
`;
