import styled from 'styled-components';
import { Transition } from '@headlessui/react';

export const Marker = styled.button`
  position: absolute;

  width: 62px;
  height: 70px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &:active,
  &:focus {
    outline: 0;
  }
`;

export const Avatar = styled.div`
  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 7px;
  width: 58px;
  height: 58px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: -1;
`;

export const Container = styled.div`
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s;
  &:hover,
  &:active {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
`;

export const TechnicianAvatar = styled.div`
  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 7px;
  width: 58px;
  height: 58px;

  border: 6px solid;
  border-radius: 28px;

  bottom: 190px;

  z-index: -1;
`;

export const AvatarText = styled.p`
  ${({ theme }) => theme.typography.h2};
  color: ${({ theme }) => theme.colors.darkestGray};
  margin: auto;
`;

export const Image = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const LookButton = styled(Transition)`
  position: absolute;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.purple};
  border-radius: 8px;
  transition: 0.2s;

  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: auto;
  }
`;

export const ContainerEffect = styled(Transition)`
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s;
  &:hover,
  &:active {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
`;
