import { Typography } from 'components/Typography';
import { LinkProfessionalAutomatic } from 'features/Call/components/LinkProfessional/LinkProfessionalAutomatic';
import { LinkProfessionalManually } from 'features/Call/components/LinkProfessional/LinkProfessionalManually';
import { TabsLinkProfessional } from 'features/Call/components/LinkProfessional/TabsLinkProfessional';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { CreateCallProps } from 'types/call';

const LinkProfessional = () => {
  const [numTab, setNumTab] = useState(null);
  const { setFieldValue } = useFormikContext<CreateCallProps>();

  const renderTabSelected = useMemo(() => {
    const tabs = {
      0: <LinkProfessionalAutomatic />,
      1: <LinkProfessionalManually />,
    };

    return tabs[numTab] || null;
  }, [numTab]);

  useEffect(() => {
    if (numTab === 0) {
      setFieldValue('technicianId', []);
      setFieldValue('searchType', 'Automatic');
    } else if (numTab === 1) {
      setFieldValue('searchType', 'Manual');
    } else {
      setFieldValue('searchType', null);
    }
  }, [numTab, setFieldValue]);

  return (
    <div className="flex flex-col p-6 space-y-6 border-2 rounded-lg border-lightGray">
      <Typography variant="h2" color="darkestGray">
        Vincular profissional
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Selecionando uma das opções abaixo, você pode deixar a plataforma
        atribuir automaticamente a melhor opção para este chamado ou definir
        manualmente quem responderá.
      </Typography>

      <TabsLinkProfessional onChange={(tab) => setNumTab(tab)}>
        {renderTabSelected}
      </TabsLinkProfessional>
    </div>
  );
};

export { LinkProfessional };
