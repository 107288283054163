import { Typography } from 'components/Typography';
import { Container } from './styles';

interface StatusLabelProps {
  children: React.ReactNode;
  icon: string;
  color?: string;
  status?: string;
}

const StatusLabel = ({
  children,
  icon,
  color = 'darkBlue',
  status,
}: StatusLabelProps) => {
  return (
    <Container status={status}>
      <div className="flex gap-2 justify-center">
        <img src={icon} alt="Ícone personalizado" />
        <Typography variant="h3" color={color}>
          {children}
        </Typography>
      </div>
    </Container>
  );
};

export { StatusLabel };
