import { Typography } from 'components/Typography';

interface AssigneeItemProps {
  sourceIcon: string;
  title: string;
  altText: string;
}

const AssigneeItem = ({ sourceIcon, title, altText }: AssigneeItemProps) => {
  return (
    <div className="flex items-center m-2 space-x-2">
      <img src={sourceIcon} alt={`Ícone de ${altText}`} />
      <Typography variant="p1" color="darkerGray">
        {title}
      </Typography>
    </div>
  );
};

export { AssigneeItem };
