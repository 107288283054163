import styled from 'styled-components';

export const Container = styled.div`
  padding: 13px 24px;
  display: flex;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  justify-content: space-between;
  border-radius: 8px;

  > div {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 4px;
`;
