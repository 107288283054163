import styled from 'styled-components';
import { Section } from '../styles';

export const Container = styled(Section)`
  .subtitle {
    max-width: 631px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;

  input {
    width: 423px;
  }
`;

export const SectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
