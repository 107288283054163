import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';

interface FormCanBuyPartsProps {
  checked: boolean;
  onChange: () => void;
  checked2: boolean;
  onChange2: () => void;
}

const FormCanBuyParts = ({
  checked,
  onChange,
  checked2,
  onChange2,
}: FormCanBuyPartsProps) => {
  return (
    <>
      <Typography variant="p1" color="darkerGray">
        Disponibilidade para compra de peças?
      </Typography>
      <div className="flex flex-row space-x-32">
        <div className="flex flex-row items-center space-x-2">
          <RadioButton
            checked={checked}
            onChange={onChange}
            value="true"
            label="Sim"
          />
        </div>
        <div className="flex flex-row items-center space-x-2">
          <RadioButton
            checked={checked2}
            onChange={onChange2}
            value="false"
            label="Não"
          />
        </div>
      </div>
    </>
  );
};

export { FormCanBuyParts };
