import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { checked, dropdownArrowLight } from 'assets/images/icons';
import clsx from 'clsx';

export const months = [
  { id: 1, value: 0, name: 'Janeiro' },
  { id: 2, value: 1, name: 'Fevereiro' },
  { id: 3, value: 2, name: 'Março' },
  { id: 4, value: 3, name: 'Abril' },
  { id: 5, value: 4, name: 'Maio' },
  { id: 6, value: 5, name: 'Junho' },
  { id: 7, value: 6, name: 'Julho' },
  { id: 8, value: 7, name: 'Agosto' },
  { id: 9, value: 8, name: 'Setembro' },
  { id: 10, value: 9, name: 'Outubro' },
  { id: 11, value: 10, name: 'Novembro' },
  { id: 12, value: 11, name: 'Dezembro' },
];

type SelectedMonthType = {
  id: number;
  name: string;
  value: number;
};

interface DropdownMonthsProps {
  selected?: SelectedMonthType;
  onChange: (arg: SelectedMonthType) => void;
}

const DropdownMonths = ({ selected, onChange }: DropdownMonthsProps) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-purple relative w-56 border border-purple rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple focus:border-purple sm:text-sm">
              <span className="block truncate text-white font-semibold">
                {selected?.name || months[new Date().getMonth()].name}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <img
                  className={clsx({ 'transform rotate-180': open })}
                  src={dropdownArrowLight}
                  alt="dropdownArrow"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-56 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-purple ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {months?.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active, selected }) =>
                      clsx(
                        active ? 'text-white bg-purpleHover' : 'text-gray-900',
                        selected ? 'bg-purple text-white' : '',
                        'cursor-default select-none relative py-2 pl-3 pr-9 cursor-pointer'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={clsx(
                            selected
                              ? 'font-semibold text-white'
                              : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {person.name}
                        </span>

                        {selected ? (
                          <span
                            className={clsx(
                              active ? 'text-white ' : 'text-gray-900',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <img className="" src={checked} alt="checked" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
export { DropdownMonths };
