const initialValues = {
  fantasyName: '',
  name: '',
  cnpj: '',
  responsible: {
    name: '',
    adjutancy: '',
    email: '',
  },
  serviceType: [],
  sla: {
    unproductiveCostPerCall: 'TOTAL_EXPECTED_COST',
    recurrence: 'monthly',
    cancellationFeeUpTo6h: 100,
    cancellationFeeUpTo24h: 50,
    cancellationMoreThen24h: 0,
    extraCostPerKM: '',
    kmIncluded: '',
    monthlyPlatformCost: '',
    obs: '',
    startCalls: '',
    urgentCostPerCall: '',
  },
  contract: {
    amount: '',
    start: new Date(),
    end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  },
};
export { initialValues };
