import { Typography } from 'components/Typography';
import { ChangeEvent } from 'react';

import { Container, RadioButtonContainer, RadioButtonInput } from './styles';

interface RadioButtonProps {
  label: string;
  name?: string;
  checked?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: boolean;
  description?: string;
}

const RadioButton = ({
  label,
  name,
  checked,
  value,
  onChange,
  disabled = false,
  defaultChecked,
  description,
}: RadioButtonProps) => {
  return (
    <Container disabled={disabled}>
      <RadioButtonInput
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <RadioButtonContainer />
      <div className="flex flex-col">
        <Typography variant="p1" color="darkestGray">
          {label}
        </Typography>
        {description ? (
          <Typography variant="p1" color="darkGray">
            {description}
          </Typography>
        ) : null}
      </div>
    </Container>
  );
};

export { RadioButton };
