import { Typography } from "components/Typography"
import { TrainingType } from "features/Company/types"

interface CardTrainingProps {
  item: TrainingType
}

const CardTraining = ({ item }: CardTrainingProps) => {
  return (
    <div className="flex flex-col w-full border-2 border-gray rounded-xl">
      <div className="flex items-center w-full p-4 space-x-4">
        <div className="flex flex-col space-y-1">
          <Typography variant="h3">
            {item?.name}
          </Typography>
          <Typography variant="p1">
            {item?.description}
          </Typography>
        </div>
      </div>
      {item?.technicians?.length > 0 ? (
        <>
          <div className="border border-gray w-full" />
          <Typography variant="p1" className="p-4">
            {item.technicians.length > 1 ? `Técnicos associados` : `Técnico associado`}
          </Typography>
          <div className="flex flex-col w-full px-4 py-2 space-y-4">
            {item?.technicians?.map((t) => (
              <div key={t.id} className="flex flex-row items-center justify-between w-full border-b p-2 border-darkGray">
                <div className="flex flex-row items-center space-x-1">
                  <div className="w-2 h-2 bg-green rounded-full" />
                  <Typography variant="p1">
                    {t.name}
                  </Typography>
                </div>
                <Typography variant="p1">
                  {t.email}
                </Typography>
              </div>
            ))}
          </div>
        </>
      ) :
        <Typography variant="p1" className="p-4">
          Nenhum técnico associado
        </Typography>
      }
    </div>
  )
}


export { CardTraining }