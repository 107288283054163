import { Alert } from 'components/AlertComponents/Alert';
import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';
import { differenceInHours } from 'date-fns';
import { useFormikContext } from 'formik';
import { useCall } from 'queries/call';
import { useCompany } from 'queries/clients';
import { useMemo } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { formatUTCHour } from 'utils/formatUtils';

const ClientReason = ({ callId }) => {
  const { call } = useCall(callId);
  const { company } = useCompany(call?.companyId);
  const form = useFormikContext();

  const hoursBeforeStart = useMemo(
    () => differenceInHours(formatUTCHour(call?.startTime), new Date()),
    [call.startTime]
  );

  const fees = {
    upTo6h: company?.currentSla?.cancellationFeeUpTo6h || 0,
    upTo24h: company?.currentSla?.cancellationFeeUpTo24h || 0,
    moreThan24h: company?.currentSla?.cancellationMoreThen24h || 0,
  };

  function calculateFine(startTime) {
    if (!startTime || !company.currentSla) {
      return 0;
    }

    if (hoursBeforeStart <= 6) {
      return fees.upTo6h / 100;
    } else if (hoursBeforeStart > 6 && hoursBeforeStart <= 24) {
      return fees.upTo24h / 100;
    } else {
      return fees.moreThan24h / 100;
    }
  }

  const totalValue = call.value;
  const fine = calculateFine(formatUTCHour(call?.startTime));
  const fineValue = totalValue * fine;

  return (
    <div className="flex flex-col space-y-4">
      <Typography variant="h3" color="darkerGray">
        Resumo da cobrança
      </Typography>
      <div className="flex justify-between w-full p-6 space-x-4 border-2 rounded-xl border-lightGray">
        {fine > 0 ? (
          <>
            <div className="flex flex-col w-1/2">
              <Typography variant="button" color="darkGray">
                Multa por cancelamento
              </Typography>
              <Typography variant="h1" color="darkerGray">
                {fine * 100} %
              </Typography>
              <Typography variant="overline" color="darkGray">
                do custo total do chamado
              </Typography>
            </div>
            <div className="flex flex-col justify-between w-1/2">
              <Typography variant="button" color="darkGray">
                Valor a ser cobrado
              </Typography>
              {totalValue !== fineValue && (
                <Typography variant="overline" color="darkGray" strikethrough>
                  {formatCurrency(totalValue || 0)}
                </Typography>
              )}
              <div className="flex items-baseline space-x-2">
                <Typography variant="tag" color="darkerGray">
                  R$
                </Typography>
                <Typography variant="h1" color="darkerGray">
                  {formatCurrency(fineValue)}
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col w-full">
            <Typography variant="button" color="darkGray">
              Multa por cancelamento
            </Typography>
            <Typography variant="h2" color="darkerGray">
              Não será cobrado
            </Typography>
            {hoursBeforeStart < 4 && (
              <Alert type="danger">
                Atenção! Como faltam menos de 4 horas para o início do chamado,
                existe uma multa a ser cobrada pelo cancelamento.
              </Alert>
            )}
          </div>
        )}
      </div>
      <div>
        <div className="overflow-hidden border-2 border-lightGray rounded-xl">
          <table className="w-full">
            <thead className="w-full border-b-2 border-lightGray">
              <tr>
                <td className="p-4">
                  <Typography variant="button" color="darkGray">
                    Período do cancelamento
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="button" color="darkGray">
                    Multa
                  </Typography>
                </td>
              </tr>
            </thead>
            <tbody className="divide-y-2 divide-lightGray">
              <tr>
                <td className="p-4">
                  <Typography variant="h3" color="darkerGray">
                    Mais que 24h antes
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="h3" color="darkerGray">
                    {fees.moreThan24h > 0
                      ? fees.moreThan24h + '%'
                      : 'Não será cobrado'}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td className="p-4">
                  <Typography variant="h3" color="darkerGray">
                    Até 24h antes
                  </Typography>
                </td>
                <td className="flex items-center p-4 space-x-2">
                  <Typography variant="h3" color="darkerGray">
                    {fees.upTo24h > 0 ? fees.upTo24h + '%' : 'Não será cobrado'}
                  </Typography>
                  <Typography variant="overline" color="darkGray">
                    do custo total do chamado
                  </Typography>
                </td>
              </tr>
              <tr>
                <td className="p-4">
                  <Typography variant="h3" color="darkerGray">
                    Menos que 6h antes
                  </Typography>
                </td>
                <td className="flex items-center p-4 space-x-2">
                  <Typography variant="h3" color="darkerGray">
                    {fees.upTo6h > 0 ? fees.upTo6h + '%' : 'Não será cobrado'}
                  </Typography>
                  <Typography variant="overline" color="darkGray">
                    do custo total do chamado
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Typography variant="h3" color="darkerGray">
        Qual o motivo do cancelamento?
      </Typography>
      <div className="flex flex-col space-y-2">
        <RadioButton
          onChange={() => {
            form.setFieldValue('description', 'Problema já foi resolvido');
          }}
          label="Problema já foi resolvido;"
          name="reason"
        ></RadioButton>
        <RadioButton
          onChange={() => {
            form.setFieldValue(
              'description',
              'Chamado foi aberto indevidamente'
            );
          }}
          label="Chamado foi aberto indevidamente;"
          name="reason"
        ></RadioButton>
        <RadioButton
          onChange={() => {
            form.setFieldValue('description', 'Indisponibilidade de horário');
          }}
          label="Indisponibilidade de horário;"
          name="reason"
        ></RadioButton>
        <RadioButton
          onChange={() => {
            form.setFieldValue('description', 'Outro');
          }}
          label="Outro"
          name="reason"
        ></RadioButton>
      </div>
    </div>
  );
};

export default ClientReason;
