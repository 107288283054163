import { useQuery } from 'react-query';
import { fetcher } from 'services/api';
import { Call } from 'services/call';
import { buildSearchParams } from 'utils/queryUtils';

export function useCall(id) {
  const { data, error, refetch } = useQuery(['call', id], () => Call.get(id), {
    enabled: Boolean(id),
  });
  return {
    call: data?.call || {},
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}

export function useCallNotifications(id: string) {
  const { data, error } = useQuery(['call', 'notifications', id], () =>
    Call.get(id)
  );
  return {
    call: data || {},
    isLoading: !error && !data,
    isError: error,
  };
}

export function useCalls({ filters }) {
  const { data, error } = useQuery(
    ['calls', filters],
    () => {
      return fetcher(`/calls?${buildSearchParams(filters)}`);
    },
    { refetchInterval: 30000 }
  );
  return {
    calls: data || [],
    isLoading: !error && !data,
    isError: error,
  };
}

export function useCallsStatus({ filters }) {
  const { data, error, refetch } = useQuery(
    ['callsTable', filters],
    () => {
      return fetcher(`/calls/calls-table?${buildSearchParams(filters)}`);
    },
    { refetchInterval: 30000 }
  );

  return {
    callsTable: data || [],
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}
