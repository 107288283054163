import { Button } from 'components/Button';
import { ModalTypes } from 'ducks/ui/types';
import { Form, Formik } from 'formik';
import { useUI } from 'hooks/useUI';
import { useFindCollaborator } from 'queries/collaborator';
import React, { useCallback, useState } from 'react';
import { CollaboratorService } from 'services/collaborator';
import { AccessPermissions } from '../NewUser/AccessPermissions';
import { RegistrationInformation } from '../NewUser/RegistrationInformation';
import { Header } from './Header';

import { Container, Content, Footer } from './styles';

export interface UserProfileProps {
  id: string;
  close(): void;
  onAfterClose(): void;
  isInactive?: boolean;
}

const UserProfile = ({
  id,
  close,
  onAfterClose,
  isInactive = false,
}: UserProfileProps) => {
  const { data } = useFindCollaborator(id);
  const { operations } = useUI();
  const [loading, setLoading] = useState(false);

  const activeCollaborator = async () => {
    try {
      await CollaboratorService.update(data?.id, { status: true });
      operations.showToast('Colaborador ativado com sucesso!');
      close();
      onAfterClose();
    } catch (err) {
      let erroMessage = 'Ocorreu um erro ao atualizar o colaborador';

      if (err.response?.status === 400) {
        erroMessage =
          err.response.data.error?.message[0] || err.response.data.message;
      }
      operations.showToast(erroMessage, {
        type: 'error',
      });
    }
  };

  const handleDisable = async () => {
    try {
      setLoading(true);
      await CollaboratorService.disable(id);
      operations.showToast('Colaborador desativado com sucesso!');
      close();
    } catch (err) {
      let erroMessage = 'Ocorreu um erro ao desativar colaborador';

      if (err.response?.status === 400) {
        erroMessage =
          err.response.data.error?.message[0] || err.response.data.message;
      }
      operations.showToast(erroMessage, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = useCallback(() => {
    const type = isInactive
      ? ModalTypes.EnableCollaborator
      : ModalTypes.DisableCollaborator;
    operations.modal.openModal({
      type,
      modalProps: {
        onConfirm: async () => {
          isInactive ? await activeCollaborator() : await handleDisable();
        },
        isLoading: loading,
      },
    });
  }, [loading, activeCollaborator, handleDisable, isInactive]);

  const updateCollaboratorRole = async (role: string) => {
    try {
      await CollaboratorService.update(data?.id, { role });
      operations.showToast(
        'Nível de acesso do colaborador atualizado com sucesso!'
      );
      close();
      onAfterClose();
    } catch (err) {
      let erroMessage = 'Ocorreu um erro ao atualizar o colaborador';

      if (err.response?.status === 400) {
        erroMessage =
          err.response.data.error?.message[0] || err.response.data.message;
      }
      operations.showToast(erroMessage, {
        type: 'error',
      });
    }
  };
  return (
    <Container>
      <Header data={data} />
      <Formik initialValues={{}} onSubmit={() => openModal()}>
        <Form>
          <Content>
            <RegistrationInformation isVisualization data={data} />
            <AccessPermissions
              data={data}
              isEdit
              onChange={(role) => updateCollaboratorRole(role)}
              isVisualization={isInactive}
            />
          </Content>
          <Footer>
            <Button type="submit" loading={loading}>
              {isInactive ? 'Ativar Colaborador' : 'Desativar Colaborador'}
            </Button>
            {/* TODO: remover comentário após incluir endpoint de excluir colaborador  */}
            {/* {
              isInactive && (
                <Button type="button" color='orange' loading={loading}>
                  Excluir Colaborador
                </Button>
              )
            } */}
          </Footer>
        </Form>
      </Formik>
    </Container>
  );
};

export { UserProfile };
