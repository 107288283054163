import React from 'react';
import { Typography } from 'components/Typography';

import { Container, Card, Header } from './styles';
import { ReviewStatus } from './ReviewStatus';
import { ReviewJustification } from './ReviewJustification';
import { ReviewFiles } from './ReviewFiles';
import { ReviewFileData } from './ReviewFiles/ReviewFileItem';
import {
  activeSuccess,
  defaultAvatar,
  inactiveAlertHover,
} from 'assets/images/icons';

interface CallStatusReviewItemProps {
  title: string;
  username: string;
  userLogo?: string;
  files: ReviewFileData[];
  callStatus: 'success' | 'warning';
  callStatusDescription: string;
  justification: string;
}

export const CallStatusReviewItem: React.FC<CallStatusReviewItemProps> = (
  props
) => {
  const {
    files,
    title,
    username,
    userLogo,
    callStatus,
    callStatusDescription,
    justification,
  } = props;

  return (
    <Container>
      <Typography variant="h2" color="darkestGray">
        {title}
      </Typography>
      <Card>
        <Header>
          <img src={userLogo || defaultAvatar} alt="Avatar" />
          <Typography variant="h2" color="darkestGray">
            {username}
          </Typography>
        </Header>
        <ReviewStatus
          description={callStatusDescription}
          icon={callStatus === 'success' ? activeSuccess : inactiveAlertHover}
          status={callStatus}
        />
        <ReviewJustification description={justification} />
        <ReviewFiles files={files} />
      </Card>
    </Container>
  );
};
