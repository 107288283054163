import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 520px;
  border-radius: 16px;
  padding: 16px 24px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
  max-width: 200px;
`;

export const DownloadFileButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
