import styled from 'styled-components';
import { statusCallDetails } from 'lib/call';

const getBackgroundColor = ({ status }) => {
  return statusCallDetails[status]?.backgroundColor || '#fff';
};

export const Container = styled.div<{ status: string }>`
  background-color: ${getBackgroundColor};
  padding: 8px 0;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.darkBlue};
  border-radius: 8px;
  border: 2px;
`;
