import { lighten } from 'polished';
import styled, { css } from 'styled-components';

interface StyleProps {
  active: boolean;
}

export const Container = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  gap: 1.5rem;
  transition: 0.2s;
  border: 2px solid transparent;

  ${({ active, theme }) => active ? css`
    background: ${lighten(0.23, theme.colors.purple)};
    border-color: ${({ theme }) => theme.colors.purple};
  ` : css`
    background: ${lighten(0.015, theme.colors.lightGray)};
  `}


  &:active {
    transform: scale(0.95);
  }
`;

export const Check = styled.div<StyleProps>`
  min-width: 24px;
  width: 24px;
  height: 24px;

  box-sizing: border-box;
  border-radius: 0.5rem;

  ${({ active, theme }) => active ? css`
    border: 2px solid ${({ theme }) => theme.colors.gray};
    background: ${theme.colors.purple};
  ` : css`
    background: transparent;
    border: 2px solid ${({ theme }) => theme.colors.gray};
  `}
`;

export const Content = styled.div`
  gap: 0.5rem;
`;
