import React, { useState, useEffect } from 'react';
import {
  Marker,
  Avatar,
  StatusBar,
  Container,
  StatusBarText,
  Dot,
} from './styles';

import theme from 'themes';
import { avatarIlana } from 'assets/images/illustrations';
import { blue, gray, green, purple } from 'assets/images/icons';

const TechnicianMarker = ({
  onClick,
  status = ' ',
  className = '',
  profileURL = avatarIlana,
  time = '9:22',
}) => {
  const [statusColor, setStatusColor] = useState();
  const [statusBackground, setStatusBackground] = useState();
  const [statusMessage, setStatusMessage] = useState();

  useEffect(() => {
    if (status)
      switch (status) {
        case 'ON_THE_WAY':
          setStatusColor(theme.colors.lighterBlue);
          setStatusBackground(`url(${blue})`);
          setStatusMessage('Profissional à caminho');
          break;
        case 'CALL_SUCCESSFULLY_ENDED':
          setStatusColor(theme.colors.purple);
          setStatusBackground(`url(${purple})`);
          setStatusMessage('Suporte finalizado');
          break;
        case 'SUPPORT_STARTED':
          setStatusColor(theme.colors.greenClick);
          setStatusBackground(`url(${green})`);
          setStatusMessage('Suporte iniciado');
          break;
        default:
          setStatusColor(theme.colors.gray);
          setStatusBackground(`url(${gray})`);
          break;
      }
  }, []);
  return (
    <Container>
      <Marker
        onClick={onClick}
        className={className}
        style={{ background: statusBackground }}
      >
        <Avatar
          src={avatarIlana}
          style={{
            backgroundImage: `url(${profileURL})`,
          }}
        />
      </Marker>
      {statusMessage && (
        <StatusBar>
          <StatusBarText>{time}</StatusBarText>
          <Dot
            style={{
              background: statusColor,
            }}
          />
          <StatusBarText>{statusMessage}</StatusBarText>
        </StatusBar>
      )}
    </Container>
  );
};

export default React.memo(TechnicianMarker);
