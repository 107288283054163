import Spinner from 'components/Spinner';

const LoadingSidesheet = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner />
    </div>
  );
};
export { LoadingSidesheet };
