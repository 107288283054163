import { useCallback, useState } from 'react';
import { StatusButton } from 'components/StatusButton';

type TabArrayData = {
  tabId: number;
  title: string;
};

interface TabButtonStatusProps {
  data: TabArrayData[];
  defaultSelected?: number;
  onClick: (tab: number) => void;
}

const TabButtonStatus = ({
  data,
  defaultSelected,
  onClick,
}: TabButtonStatusProps) => {
  const [tabSelected, setTabSelected] = useState(defaultSelected);

  const handleChange = useCallback(
    (tabId: number) => {
      setTabSelected(tabId);
      if (onClick) {
        onClick(tabId);
      }
    },
    [onClick]
  );

  return (
    <>
      {data?.map((item) => (
        <StatusButton
          key={item.tabId}
          selected={tabSelected === item.tabId}
          onClick={() => handleChange(item.tabId)}
        >
          {item.title}
        </StatusButton>
      ))}
    </>
  );
};

export { TabButtonStatus };
export type { TabArrayData };
