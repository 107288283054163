/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import { Typography } from 'components/Typography';
import { Attachments } from 'features/Call/components/ServiceDetail/Attachments';
import { ChecklistComponent } from 'features/Call/components/ServiceDetail/ChecklistComponent';
import { ErrorMessage, Field, useFormikContext } from 'formik';

import { Container, Content, SectionItem } from './styles';

const ServiceDetail = () => {
  const { setFieldValue, values, errors, touched } = useFormikContext<any>();

  return (
    <Container data-testid="call-template-service-detail">
      <Typography variant="h2" color="darkestGray">
        Detalhe o serviço
      </Typography>
      <Typography variant="p1" color="darkerGray" className="subtitle">
        Descreva o serviço e cadastre o checklist que o técnico deve realizar
        dentro de um chamado, é a partir deste detalhamento que o profissional
        fará a identificação do que precisa ser feito.
      </Typography>

      <Content>
        <SectionItem>
          <Typography variant="h3" color="darkestGray">
            Descrição
          </Typography>
          <Field
            as="textarea"
            rows={6}
            name="serviceDetail"
            type="textarea"
            data-testid="call-template-textarea-description"
            className={clsx(
              'w-full p-3 border-2 rounded-xl focus:outline-none',

              touched.serviceDetail && errors.serviceDetail
                ? 'border-orange focus:border-orange'
                : 'border-gray focus:border-darkGray'
            )}
          />
          <span className="m-0 text-sm text-orangeClick">
            <ErrorMessage name="serviceDetail" />
          </span>
        </SectionItem>

        <SectionItem>
          <Typography variant="h3" color="darkestGray">
            Checklist técnico
          </Typography>
          <Typography variant="p1" color="darkerGray" className="subtitle">
            Cadastre um checklist com etapas que o profissional deve realizar
            dentro do chamado. O checklist cadastrado servirá para validar o
            término do serviço.
          </Typography>
          <ChecklistComponent
            onChangeRemoveItem={(_, index) => {
              setFieldValue(
                'checklist',
                values?.checklist?.filter((_, i) => i !== index)
              );
            }}
            onChangeSaveItem={(items) => setFieldValue('checklist', items)}
            checklistValues={values?.checklist}
          />
          <Attachments
            defaultValues={values?.attachmentsItems || []}
            onChange={(items) => {
              setFieldValue('attachments', items);
            }}
            onDelete={(id) => {
              setFieldValue(
                'attachments',
                values?.attachments?.filter((file) => file?.id !== id)
              );
            }}
          />
        </SectionItem>
      </Content>
    </Container>
  );
};

export { ServiceDetail };
