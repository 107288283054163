import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';

const StatusReviewCard = ({ callId }) => {
  const UI = useUI();

  return (
    <div className="flex flex-col p-4 space-y-2 border-2 border-lightGray rounded-xl">
      <Typography variant="h3" color="darkerGray">
        Chamado finalizado como improdutivo
      </Typography>
      <Typography variant="p2" color="darkerGray">
        É preciso verificar o status do chamado.
      </Typography>
      <div className="flex justify-end space-x-4">
        <Button
          onClick={() => {
            UI.operations.sidesheet.openSidesheet({
              type: SidesheetTypes.CallReviewTab,
              sidesheetProps: {
                callId: callId,
              },
            });
          }}
          type="button"
          variant="outlined"
          className="self-end"
        >
          Revisar status
        </Button>
      </div>
    </div>
  );
};

export default StatusReviewCard;
