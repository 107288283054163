const List = ({ data = [], renderItem }) => {
  return (
    <>
      {data.map((item, index) => (
        <div key={index}>{renderItem(item)}</div>
      ))}
    </>
  );
};

export { List };
