import styled, { css } from 'styled-components';

interface IconTabProps {
  disabled: boolean;
  selected: boolean
}

const getBorderColor = ({
  selected,
  theme: {
    colors: { lightGray, purple },
  },
}) => {
  return selected ? purple : lightGray;
};

export const Container = styled.div<IconTabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 60px;
  border-bottom: 4px solid ${getBorderColor};
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
  `}
  :hover {
    cursor: pointer;
  }
`;
