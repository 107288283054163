import { useState } from 'react';
import { useUI } from 'hooks/useUI';
import { ModalTypes } from 'ducks/ui/types';
import { formatDate } from 'utils/formatUtils';
import { cnpjMask, cpfMask } from 'utils/maskUtils';
import { Typography } from 'components/Typography';
import { IconButton } from 'components/IconButton';
import { TextField } from 'components/Inputs/TextField';
import { Button } from 'components/Button';
import {
  inactiveScheduleGray,
  phone,
  plusSign,
  document,
  email as emailIcon,
} from 'assets/images/icons';
import { InfoPersonalType } from 'features/Technician/types';
import { PersonalDataItem } from 'features/Technician/components';

interface PersonalDocumentationProps {
  infoPersonal: InfoPersonalType;
}

const PersonalDocumentation = ({
  infoPersonal: { birthDate, cnpj, cpf, email, telephone, id, rg },
}: PersonalDocumentationProps) => {
  const UI = useUI();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [cnpjValue, setCnpjValue] = useState('');

  return (
    <div className="space-y-6">
      <Typography variant="h3" color="darkBLue">
        Dados Pessoais
      </Typography>
      <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-6 pr-6">
        <PersonalDataItem title="CPF" value={cpfMask(cpf)} icon={document} />
        <PersonalDataItem
          title="CNPJ"
          value={cnpjMask(cnpj) || 'Não informado'}
          icon={document}
        />
        <PersonalDataItem
          title="Data de nascimento"
          value={birthDate ? formatDate(birthDate) : 'Não informada'}
          icon={inactiveScheduleGray}
        />
        <PersonalDataItem title="RG" value={rg} icon={document} />
        <PersonalDataItem title="E-mail" value={email} icon={emailIcon} />
        <PersonalDataItem title="Telefone" value={telephone} icon={phone} />
      </div>
      {!cnpj && !isFormOpen && (
        <IconButton
          icon={plusSign}
          size="small"
          filled
          onClick={() => setIsFormOpen(true)}
          width="158"
        >
          Adicionar CNPJ
        </IconButton>
      )}
      {isFormOpen && (
        <>
          <Typography variant="h3" color="darkBLue">
            Cadastrar CNPJ
          </Typography>
          <TextField
            name="CNPJ"
            label="CNPJ"
            placeholder="Insira o CNPJ"
            width="100%"
            value={cnpjValue}
            onChange={(e) => setCnpjValue(cnpjMask(e.target.value))}
            errorMessage={undefined}
            height={undefined}
            field={undefined}
            form={undefined}
            disabled={undefined}
          />
          <Button
            color="purple"
            variant="outlined"
            size="medium"
            onClick={() =>
              UI.operations.modal.openModal({
                type: ModalTypes.ConfirmCnpj,
                modalProps: {
                  cnpj: cnpjValue,
                  id: id,
                },
              })
            }
            width="97"
            className="self-end"
          >
            Salvar CNPJ
          </Button>
        </>
      )}
    </div>
  );
};

export { PersonalDocumentation };
