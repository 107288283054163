import React, { useCallback } from 'react';
import { Typography } from 'components/Typography';
import { CheckReview } from './CheckReview';

import { Container } from './styles';
import { Button } from 'components/Button';

interface CallAddStatusReviewProps {
  onChange(valeu: string): void;
  onSubmit(): void;
  callReviewStatus: string;
  company: string;
}

export const CallAddStatusReview: React.FC<CallAddStatusReviewProps> = ({
  onChange,
  onSubmit,
  callReviewStatus,
  company,
}) => {
  const handleStatusReview = useCallback(
    (status: string) => {
      onChange(status);
    },
    [onChange]
  );

  return (
    <Container>
      <Typography variant="h2" color="darkestGray">
        Adicionar revisão de status
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Como o chamado foi marcado como improdutivo, é preciso adicionar uma
        revisão para o status final. Com base nas justificativas apresentadas
        pela empresa e profissional, adicione o veredito para o chamado:
      </Typography>
      <CheckReview
        active={callReviewStatus === 'call'}
        onChange={() => handleStatusReview('call')}
        title="Finalizar como Chamado com Sucesso"
        desceription={`Selecione esta opção caso o chamado tenha sido finalizado em conformidade com a Ordem de Serviço. 
        O valor total do chamado será faturado para ${company}, e o profissional responsável receberá normalmente.`}
      />
      <CheckReview
        active={callReviewStatus === 'client'}
        onChange={() => handleStatusReview('client')}
        title="Chamado improdutivo, erro do Cliente"
        desceription={`Selecione esta opção caso o cliente seja o responsável pela não finalização deste chamado. 
        O valor total do chamado será faturado para ${company}.`}
      />
      <CheckReview
        active={callReviewStatus === 'professional'}
        onChange={() => handleStatusReview('professional')}
        title="Chamado improdutivo, erro do Profissional"
        desceription="Selecione esta opção caso o cliente seja o responsável pela não finalização deste chamado. 
        O valor total do chamado não será cobrado do cliente."
      />
      <Button variant="outlined" width={300} onClick={() => onSubmit()}>
        Salvar
      </Button>
    </Container>
  );
};
