import { useQuery } from 'react-query';
import { UserService } from 'services/user';

export function useGetUser(id: string) {
  const { data, error, refetch, isLoading } = useQuery(['user', id], () => UserService.get(id), {
    enabled: Boolean(id),
  });

  return {
    data: data?.data.user,
    isLoading,
    isError: error,
    refetch
  };
}

