const serviceCategoryType = {
  EXTRAORDINARY_SERVICES: {
    label: 'Serviços Extraordinários',
    value: 'EXTRAORDINARY_SERVICES',
  },
  MICROINFORMATICS: {
    label: 'Microinformática',
    value: 'MICROINFORMATICS',
  },
  NETWORK_AND_PERIPHERAL_INFRASTRUCTURE: {
    label: 'Rede e Infraestrutura Periférica',
    value: 'NETWORK_AND_PERIPHERAL_INFRASTRUCTURE',
  },
  REMOTE: {
    label: 'Controle Remoto',
    value: 'REMOTE',
  },
  SERVERS: {
    label: 'Servidores',
    value: 'SERVERS',
  },
};

export { serviceCategoryType };
