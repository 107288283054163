import { crossed } from 'assets/images/icons';
import { Accordion } from 'components/Accordion';

interface ServiceItemCompany {
  id: string;
  name: string;
  description: string;
  handleRemove: (e) => void;
  preventRemove: unknown;
}

const ServiceItemCompany = ({
  id,
  name,
  description,
  handleRemove,
  preventRemove,
}: ServiceItemCompany) => {
  return (
    <div className="flex items-center w-full space-x-4">
      <Accordion title={name}>{description}</Accordion>
      {!preventRemove && (
        <button
          role="onClick"
          type="button"
          className="flex items-center justify-center text-white rounded focus:outline-none bg-orange focus:bg-orangeClick hover:bg-orangeHover text-xs h-[16px] w-[16px]"
          onClick={() => handleRemove(id)}
        >
          <img src={crossed} alt="Ícone chamado de crossed" />
        </button>
      )}
    </div>
  );
};

export { ServiceItemCompany };
