import { statusCallDetails } from 'lib/call';
import { useTechnicianCalls } from 'queries/technician';
import { TimelineSchedule } from '../../TimelineSchedule';

const SheduleCanceledCalls = ({ idTechnician, selectedMonthNumber }) => {
  const technicianCalls = useTechnicianCalls(idTechnician, {
    status: [statusCallDetails.SUPPORT_CANCELED.value],
    month: selectedMonthNumber,
  });

  return <TimelineSchedule technicianCalls={technicianCalls} />;
};

export { SheduleCanceledCalls };
