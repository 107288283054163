import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { whatsAppMessageToTechnician } from 'utils/whatsAppUtils';
import { inactiveLink } from 'assets/images/icons';

interface RedirectToWhatsappCardProps {
  modelId: string;
  callId: number;
}

const RedirectToWhatsappCard = ({
  modelId,
  callId,
}: RedirectToWhatsappCardProps) => {
  return (
    <div className="flex flex-col p-4 space-y-2 border-2 border-lightGray rounded-xl">
      <Typography variant="h3" color="darkerGray">
        Conversar com profissional
      </Typography>
      <Typography variant="p2" color="darkerGray">
        Utilizamos uma plataforma parceira para gerenciar toda a comunicação com
        nossos profissionais. Você será redirecionado ao clicar no botão abaixo.
      </Typography>
      <div className="flex justify-end">
        <Button
          onClick={() => whatsAppMessageToTechnician(modelId, callId)}
          type="button"
          variant="outlined"
          className="flex flex-row items-center gap-2 text-center"
        >
          Mensagem
          <img src={inactiveLink} />
        </Button>
      </div>
    </div>
  );
};

export { RedirectToWhatsappCard };
