import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ICollaborator } from 'types/collaborator';

interface UploadLogoButtonProps {
  onChange: (file) => void;
  name?: string;
  image: string;
  disabled?: boolean;
  title?: string;
  data?: ICollaborator;
}

const UploadLogoButton = ({
  onChange,
  name = null,
  image,
  disabled = false,
  title = 'Upload de logo',
  data,
}: UploadLogoButtonProps) => {
  const [preview, setImage] = useState(image);

  useEffect(() => {
    if (data?.photo) {
      setImage(data?.photo);
    }
  }, [data]);

  const handleChange = (e) => {
    const file = e.target.files[0];

    setImage(URL.createObjectURL(file));
    if (onChange) onChange(file);
  };

  return (
    <label
      className={clsx(
        'rounded-xl bg-lightGray flex w-full p-4 space-x-4 cursor-pointer',
        { 'cursor-not-allowed': disabled }
      )}
    >
      <div className="flex-shrink-0 h-[100px] w-[100px] p-4 bg-white rounded-full border-gray border-2 items-center justify-center flex">
        {preview ? (
          <img src={preview} alt="Image" />
        ) : name ? (
          <p className="text-darkerGray  text-4xl">
            {name?.substring(0, 1).toUpperCase()}
          </p>
        ) : (
          'LOGO'
        )}
      </div>
      <div className="flex flex-col justify-center">
        <p className="text-darkerGray font-bold text-left underline">{title}</p>
        <p className="text-darkGray text-sm font-light text-left">
          Formatos aceitos: JPG e PNG com resolução máxima de 1000x1000
        </p>
      </div>
      <input
        type="file"
        role={disabled ? 'disabled' : 'input'}
        className={'hidden'}
        onChange={handleChange}
        accept="image/jpg,image/jpeg,image/png"
        disabled={disabled}
      />
    </label>
  );
};

export { UploadLogoButton };
