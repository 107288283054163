import { arrowLeftBlack, arrowRightBlack } from 'assets/images/icons';
import { months } from 'utils/dateUtils';
import { Container } from './styles';
import { Typography } from 'components/Typography';

interface ICalendarNavbarProps {
  month: Date;
  onPreviousClick(callback?: () => void): void;
  onNextClick(callback?: () => void): void;
}

const CalendarNavbar = ({
  month,
  onPreviousClick,
  onNextClick,
}: ICalendarNavbarProps) => {
  return (
    <Container>
      <img
        src={arrowLeftBlack}
        onClick={() => onPreviousClick()}
        alt="arrow-left"
      />

      <Typography variant="button" color="darkBlue">
        {months[month?.getMonth()]}
      </Typography>

      <img
        src={arrowRightBlack}
        onClick={() => onNextClick()}
        alt="arrow-right"
      />
    </Container>
  );
};

export { CalendarNavbar };
