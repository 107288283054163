import { ClientActions } from "utils/security/modulesActions";
import { usePermissions } from "./usePermissions";

export function useClientPermissions() {
  const { permissions } = usePermissions();

  function canCreateClient(): boolean {
    return permissions.CLIENT.actions.includes(ClientActions.CREATE)
  }

  function canUpdateClient(): boolean {
    return permissions.CLIENT.actions.includes(ClientActions.UPDATE)
  }

  function canGenerateReportClient(): boolean {
    return permissions.CLIENT.actions.includes(ClientActions.REPORT)
  }
  function canReadClient(): boolean {
    return permissions.CLIENT.actions.includes(ClientActions.READ)
  }

  return {
    canReadClient: canReadClient(),
    canCreateClient: canCreateClient(),
    canUpdateClient: canUpdateClient(),
    canGenerateReportClient: canGenerateReportClient(),
  }
  
}
