interface TooltipButtonProps {
  description: string;
  children: React.ReactNode;
}

const TooltipButton = ({ description, children }: TooltipButtonProps) => {
  return (
    <span className="group relative">
      <span className="pointer-events-none absolute -top-10 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-white border border-gray px-2  text-purple font-semibold opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-darkGray before:content-[''] group-hover:opacity-100 text-xs">
        {description}
      </span>
      {children}
    </span>
  );
};

export { TooltipButton };
