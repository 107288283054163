import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';
import { ENGLISH_LEVEL } from 'data/englishLevel';

interface EnglishLevelProps {
  englishLevel: string;
}

const EnglishLevel = ({ englishLevel }: EnglishLevelProps) => {
  return (
    <div className="space-y-4">
      <Typography variant="h3" color="darkBLue">
        Nível de inglês
      </Typography>
      <div className="flex flex-row space-x-2">
        {englishLevel ? (
          ENGLISH_LEVEL.map((e) => (
            <RadioButton
              key={e.value}
              defaultChecked={e.value === englishLevel}
              value={e.value}
              label={e.label}
              disabled
            />
          ))
        ) : (
          <Typography variant="p1" color="darkerGray">
            Não informado
          </Typography>
        )}
      </div>
    </div>
  );
};

export { EnglishLevel };
