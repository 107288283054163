import { format } from 'date-fns';
import { Typography } from 'components/Typography';
import { ptBR } from 'date-fns/locale';

interface CallTableScheduleViewProps {
  value: {
    startTime: Date;
    finalTime: Date;
  };

  isFinalTime?: boolean;
}

const CallTableScheduleView = ({
  value,
  isFinalTime,
}: CallTableScheduleViewProps) => {
  return (
    <>
      {isFinalTime ? (
        <Typography variant="p1" color="darkerGray">
          {format(new Date(value?.finalTime), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          })}
        </Typography>
      ) : (
        <Typography variant="p1" color="darkerGray">
          {format(new Date(value?.startTime), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          })}
        </Typography>
      )}
    </>
  );
};

export { CallTableScheduleView };
