import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'components/Button';
import { InputField } from 'components/InputField';
import { Typography } from 'components/Typography';
import { Subtitle, FormContainer } from './styles';
import { yupValidations } from 'utils/validations/yup';
import { confirm } from 'assets/images/illustrations';

export interface ConfirmCreateCallTemplateModalProps {
  close: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (values: any) => void;
  isLoading?: boolean;
  onAfterClose?: () => void;
}

const ConfirmCreateCallTemplateModal = ({
  close,
  onConfirm,
  onAfterClose,
}: ConfirmCreateCallTemplateModalProps) => {
  function closeModal() {
    if (onAfterClose) {
      onAfterClose();
    }
    close();
  }

  function handleSubmit(values) {
    if (onAfterClose) {
      onConfirm(values);
      onAfterClose();
    }
    close();
  }

  const schemaValidation = Yup.object().shape({
    name: yupValidations.requiredString,
  });

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={schemaValidation}
    >
      {({ isValid }) => (
        <Form className="flex flex-col items-center max-w-xl text-center">
          <img src={confirm} alt="icon confirm" />
          <Typography variant="h2" color="darkBlue" className="mb-8">
            Criar novo template de chamado
          </Typography>
          <Subtitle variant="h4" color="darkBlue" className="mb-4">
            Você está confirmando a criação de um novo template de chamado. Após
            confirmação, o mesmo ficará disponível na tela de preferência.
          </Subtitle>
          <FormContainer>
            <Field
              component={InputField}
              label="Nome do template"
              name="name"
            />
          </FormContainer>
          <div className="flex flex-row gap-6">
            <Button
              variant="outlined"
              color="orange"
              width="200"
              size="large"
              type="button"
              onClick={closeModal}
            >
              Não
            </Button>
            <Button
              variant="outlined"
              width="200"
              size="large"
              type="submit"
              disabled={!isValid}
            >
              Confirmar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { ConfirmCreateCallTemplateModal };
