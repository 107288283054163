import { useFormikContext, Field } from 'formik';
import { phoneMask } from 'utils/maskUtils';
import { TextField } from 'components/Inputs/TextField';

const AssigneeForm = () => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Field
        component={TextField}
        label="Responsável"
        name="responsibleName"
        placeholder="Nome e função do responsável"
      />
      <div className="flex flex-row gap-6">
        <Field
          component={TextField}
          label="Telefone"
          name="responsibleTelephone"
          width="220px"
          onChange={(e) => {
            const value = phoneMask(e.target.value);
            setFieldValue('responsibleTelephone', value);
          }}
        />
        <Field
          component={TextField}
          label="E-mail"
          name="responsibleEmail"
          width="220px"
        />
      </div>
    </>
  );
};

export { AssigneeForm };
