import React from 'react';
import { Field, FormikProvider } from 'formik';

import { Button } from 'components/Button';
import { TextField } from 'components/Inputs/TextField';

const CallsBonusForm = ({ close, formik }) => {
  return (
    <FormikProvider value={formik}>
      <div className="flex flex-col w-full space-y-4">
        <div className="flex w-full space-x-4">
          <div className="w-1/3">
            <Field
              component={TextField}
              label="Quantidade de chamados"
              placeholder="Quantidade chamados"
              width="100%"
              type="number"
              name="callRewardNumberOfCalls"
              onChange={formik.callRewardNumberOfCalls}
              value={formik.values.callRewardNumberOfCalls}
              errorMessage={formik.validationSchema?.callRewardNumberOfCalls}
            />
          </div>
          <div className="relative w-1/3">
            <Field
              component={TextField}
              label="Bonificação"
              placeholder="Bonificação"
              width="100%"
              type="number"
              name="callRewardBonus"
              onChange={formik.callRewardBonus}
              value={formik.values.callRewardBonus}
              errorMessage={formik.validationSchema?.callRewardBonus}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <span className="mt-4 text-gray-500">%</span>
            </div>
          </div>
        </div>
        <div className="flex flex-row self-end space-x-4">
          <Button
            color="darkGray"
            size="large"
            variant="outlined"
            type="button"
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button
            color="purple"
            size="large"
            type="button"
            onClick={() => {
              close();
            }}
          >
            Salvar
          </Button>
        </div>
      </div>
    </FormikProvider>
  );
};

export default CallsBonusForm;
