const theme = {
  colors: {
    lighterGray: '#FAFAFA',
    lightGray: '#F2F2F2',
    gray: '#E9E9E9',
    darkGray: '#C2C2C2',
    darkerGray: '#6C6C75',
    darkestGray: '#45444B',
    beige: '#FFFAF4',
    beigeHover: '#FFEFDB',
    beigeClick: '#FFE3C2',
    yellow: '#FFC177',
    yellowHover: '#FFB55C',
    yellowClick: '#FFA942',
    lightPurple: '#F2F0FF',
    purple: '#806BFF',
    purpleHover: '#6A52FF',
    purpleClick: '#5438FF',
    green: '#A7E3D2',
    greenHover: '#93DDC8',
    greenClick: '#7FD6BE',
    orange: '#FF876B',
    orangeHover: '#FF7252',
    orangeClick: '#FF5E38',
    pink: '#FFA8FF',
    pinkHover: '#FF8FFF',
    pinkClick: '#FF75FF',
    blue: '#06B1E5',
    lighterBlue: '#A0BDF5',
    darkBlue: '#1A0F36',
    white: '#FFFFFF',
  },
  typography: {
    fontFamily: 'Asap',
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h4: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    link: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    p1: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    p2: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    p3: {
      fontSize: '0.75rem',
      fontWeight: 700,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    tag: {
      fontSize: '0.6875rem',
      fontWeight: 700,
    },
  },
};

export default theme;
