import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

const ContactGuioCard = () => {
  return (
    <div className="flex flex-col p-4 space-y-2 border-2 border-lightGray rounded-xl">
      <Typography variant="h3" color="darkerGray">
        Entrar em contato
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Fale com a Guio ou com o técnico para saber o motivo do atraso e se o
        mesmo irá comparecer.
      </Typography>
      <div className="flex space-x-4">
        <Button type="button" variant="outlined" block>
          Contato Guio
        </Button>
        <Button type="button" variant="outlined" block>
          Contato Técnico
        </Button>
      </div>
    </div>
  );
};

export { ContactGuioCard };
