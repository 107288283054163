import styled, { css } from 'styled-components';
import { checkedPurple } from 'assets/images/icons';

interface ContainerProps {
  disabled: boolean;
}

interface CheckboxContainer {
  checked?: boolean;
}

const Container = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;

  p {
    margin-left: 28px;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
  role: 'checkbox',
})`
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;
`;

const CheckboxContainer = styled.div<CheckboxContainer>`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid;
  border-color: ${({ checked }) => (checked ? '#806BFF' : '#DCDCDC')};
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${CheckboxInput}:checked + &::after {
    display: block;
    background: rgba(128, 107, 255, 0.1);
    background-image: url('${checkedPurple}');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 8px;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export { Container, CheckboxContainer, CheckboxInput };
