import { inactiveAlertHover, inactiveCancel } from 'assets/images/icons';

export default class Call {
  constructor(data) {
    this.id = data.id;
    this.companyId = data.companyId;
    this.companyName = data.companyName;
    this.datetime = data.datetime;
    this.finalTime = data.finalTime;
    this.clientLogo = data.clientLogo;
    this.address = data.address;
    this.revenue = data.revenue;
    this.service = data.service;
    this.status = callStatus[data.status];
    this.startTime = data.startTime;
    this.technician = data.callToTechnician;
    this.city = data.city;
  }
}

export const callStatus = {
  LOOKING_FOR_PROFESSIONAL: {
    color: 'blue',
    displayText: 'Buscando profissional',
    value: 'LOOKING_FOR_PROFESSIONAL',
  },
  PROFESSIONAL_NOT_FOUND: {
    color: 'orange',
    displayText: 'Profissional não encontrado',
    value: 'PROFESSIONAL_NOT_FOUND',
  },
  SCHEDULED_CALL: {
    color: 'purple',
    displayText: 'Agendado',
    value: 'SCHEDULED_CALL',
  },
  WAITING_TECHNICIANS_ACCEPTANCE: {
    color: 'gray',
    displayText: 'Aguardando',
    value: 'WAITING_TECHNICIANS_ACCEPTANCE',
  },
  CALL_REFUSED_BY_TECHNICIAN: {
    color: 'orange',
    displayText: 'Recusado pelo técnico',
    value: 'CALL_REFUSED_BY_TECHNICIAN',
  },
  DELAYED_CALL: {
    color: 'orange',
    displayText: 'Atrasado',
    value: 'DELAYED_CALL',
  },
  REQUESTED_CANCELLATION: {
    color: 'orange',
    displayText: 'Cancelamento solicitado',
    value: 'REQUESTED_CANCELLATION',
  },
  SUPPORT_CANCELED: {
    color: 'gray',
    displayText: 'Cancelado',
    value: 'SUPPORT_CANCELED',
  },
  ON_THE_WAY: {
    color: 'green',
    displayText: 'Profissional à caminho',
    value: 'ON_THE_WAY',
  },
  SUPPORT_STARTED: {
    color: 'green',
    displayText: 'Iniciado',
    value: 'SUPPORT_STARTED',
  },
  PENDING_COMPLETION: {
    color: 'orange',
    displayText: 'Finalização pendente',
    value: 'PENDING_COMPLETION',
  },
  CALL_SUCCESSFULLY_ENDED: {
    color: 'green',
    displayText: 'Finalizado com sucesso',
    value: 'CALL_SUCCESSFULLY_ENDED',
  },
  UNDER_ANALYSIS: {
    color: 'blue',
    displayText: 'Em análise',
    value: 'UNDER_ANALYSIS',
  },
  UNPRODUCTIVE_CALL: {
    color: 'orange',
    displayText: 'Chamado Improdutivo',
    value: 'UNPRODUCTIVE_CALL',
  },
  OPERATIONS_LOOKING_FOR_PROFESSIONAL: {
    color: 'blue',
    displayText: 'Operações buscando profissional',
    value: 'OPERATIONS_LOOKING_FOR_PROFESSIONAL',
  },
  URGENT: {
    color: 'purple',
    displayText: 'Urgentes',
    value: 'Urgent',
  },
};

const yellowLight = '#ffa94266';
const orangeLight = '#ff876b66';
const greenLight = '#a7e3d266';

const statusCallDetails = {
  UNDER_ANALYSIS: {
    icon: inactiveAlertHover,
    label: 'Em análise',
    backgroundColor: yellowLight,
    value: 'UNDER_ANALYSIS',
  },
  SUPPORT_CANCELED: {
    icon: inactiveCancel,
    label: 'Chamado cancelado',
    backgroundColor: orangeLight,
    value: 'SUPPORT_CANCELED',
  },
  UNPRODUCTIVE_CALL: {
    icon: inactiveCancel,
    label: 'Chamado finalizado como: Improdutivo',
    backgroundColor: orangeLight,
    value: 'UNPRODUCTIVE_CALL',
  },
  WAITING_TECHNICIANS_ACCEPTANCE: {
    icon: inactiveAlertHover,
    label: 'Aguardando aceitação do profissional',
    backgroundColor: yellowLight,
    value: 'WAITING_TECHNICIANS_ACCEPTANCE',
  },
  CALL_REFUSED_BY_TECHNICIAN: {
    icon: inactiveCancel,
    label: 'Chamado recusado pelo profissional',
    backgroundColor: orangeLight,
    value: 'CALL_REFUSED_BY_TECHNICIAN',
  },
  REQUESTED_CANCELLATION: {
    icon: inactiveCancel,
    label: 'Cancelamento solicitado',
    backgroundColor: orangeLight,
    value: 'REQUESTED_CANCELLATION',
  },
  CALL_SUCCESSFULLY_ENDED: {
    icon: inactiveAlertHover,
    label: 'Chamado finalizado com sucesso',
    backgroundColor: greenLight,
    value: 'CALL_SUCCESSFULLY_ENDED',
  },
  ON_THE_WAY: {
    icon: inactiveAlertHover,
    label: 'Profissional à caminho',
    backgroundColor: yellowLight,
    value: 'ON_THE_WAY',
  },
  SUPPORT_STARTED: {
    icon: inactiveAlertHover,
    label: 'Iniciado',
    backgroundColor: yellowLight,
    value: 'SUPPORT_STARTED',
  },
  PENDING_COMPLETION: {
    icon: inactiveAlertHover,
    label: 'Finalização pendente',
    backgroundColor: yellowLight,
    value: 'PENDING_COMPLETION',
  },
  DELAYED_CALL: {
    icon: inactiveAlertHover,
    label: 'Atrasado',
    backgroundColor: yellowLight,
    value: 'DELAYED_CALL',
  },
  SCHEDULED_CALL: {
    icon: inactiveAlertHover,
    label: 'Agendado',
    backgroundColor: yellowLight,
    value: 'SCHEDULED_CALL',
  },
  PROFESSIONAL_NOT_FOUND: {
    icon: inactiveAlertHover,
    label: 'Profissional não encontrado',
    backgroundColor: yellowLight,
    value: 'PROFESSIONAL_NOT_FOUND',
  },
  LOOKING_FOR_PROFESSIONAL: {
    icon: inactiveAlertHover,
    label: 'Buscando profissional',
    backgroundColor: yellowLight,
    value: 'LOOKING_FOR_PROFESSIONAL',
  },
  OPERATIONS_LOOKING_FOR_PROFESSIONAL: {
    icon: inactiveAlertHover,
    label: 'Operação Buscando profissional',
    backgroundColor: yellowLight,
    value: 'OPERATIONS_LOOKING_FOR_PROFESSIONAL',
  },
};

const serviceType = {
  PER_CALL: { label: 'Por chamado' },
  PER_HOUR: { label: 'Por hora' },
  HOUR: { label: 'Por hora' },
};

export { statusCallDetails, serviceType };
