import styled, { css, keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
`;

interface StylesProps {
  isOpen: boolean;
}

interface SelectContainerProps {
  isOpen: boolean;
  disabled: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 310px;
  user-select: none;
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 130px;

  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 16px;
  padding: 16px 12px;

  transition: border-color 0.2s;

  img {
    ${({ isOpen }) =>
      isOpen &&
      css`
        animation: ${rotateAnimation} 0.5s ease;
        transform: rotate(180deg);
      `}

    margin-left: 32px;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.purple};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;

      &:hover {
        border-color: ${({ theme }) => theme.colors.lightGray};
      }
    `}
`;

export const SelectOpenContainer = styled.div<StylesProps>`
  ${(props) =>
    props.isOpen
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  position: absolute;
  z-index: 1;

  align-items: center;
  justify-content: center;

  padding: 8px;
  top: 64px;

  width: 100%;
`;
