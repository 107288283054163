import { SummaryCard } from 'components/SummaryCard';
import { Typography } from 'components/Typography';
import { Checkmark } from 'components/Checkmark';
import { alertWhite, crossed, suitcase } from 'assets/images/icons';
import { StatisticIcon, Image } from './styles';

interface CallsSummaryCardProps {
  finishedCount?: number;
  canceledCount?: number;
  unproductiveCount?: number;
  pendingCount?: number;
}

const CallsSummaryCard = ({
  finishedCount = 0,
  canceledCount = 0,
  unproductiveCount = 0,
  pendingCount = 0,
}: CallsSummaryCardProps) => {
  const countsTextSmall = [
    finishedCount,
    canceledCount,
    unproductiveCount,
    pendingCount,
  ]
    .map(String)
    .some((count) => count.length >= 2);

  return (
    <SummaryCard title="Chamados" icon={suitcase} color="purple">
      <div className="flex gap-5 pt-8">
        <div className="flex flex-col">
          <Typography variant="tag" color="darkerGray">
            Concluídos
          </Typography>
          <div className="flex items-center gap-2">
            <Checkmark isChecked size="sm" />
            <Typography
              variant={countsTextSmall ? 'h3' : 'h1'}
              color="darkBlue"
            >
              {finishedCount}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="tag" color="darkerGray">
            Pendentes
          </Typography>
          <div className="flex items-center gap-2">
            <StatisticIcon type="pending">
              <Image src={alertWhite} alt="Ícone chamados Pendentes" />
            </StatisticIcon>
            <Typography
              variant={countsTextSmall ? 'h3' : 'h1'}
              color="darkBlue"
            >
              {pendingCount}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="tag" color="darkerGray">
            Cancelados
          </Typography>
          <div className="flex items-center gap-2">
            <Checkmark isChecked={false} size="sm" />
            <Typography
              variant={countsTextSmall ? 'h3' : 'h1'}
              color="darkBlue"
            >
              {canceledCount}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="tag" color="darkerGray">
            Improdutivos
          </Typography>
          <div className="flex items-center gap-2">
            <StatisticIcon type="unproductive">
              <Image src={crossed} alt="Ícone chamados improdutivos" />
            </StatisticIcon>
            <Typography
              variant={countsTextSmall ? 'h3' : 'h1'}
              color="darkBlue"
            >
              {unproductiveCount}
            </Typography>
          </div>
        </div>
      </div>
    </SummaryCard>
  );
};

export { CallsSummaryCard };
