import styled from 'styled-components';

const getBorderColor = ({
  selected,
  theme: {
    colors: { lightGray, purple },
  },
}) => {
  return selected ? purple : lightGray;
};

export const Container = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 4px solid ${getBorderColor};
  padding-right: 12px;
  padding-left: 12px;
  :hover {
    cursor: pointer;
  }
`;
