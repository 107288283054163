import { activeServiceOrderGray, documentGray, scheduleSelected, scheduleUnselected } from "assets/images/icons"
import { Tabs } from "components/Tabs"
import { IconTab } from "components/Tabs/IconTab"
import { useState } from "react";
import { AdditionalService } from "../AdditionalService";
import { ChecklistTab } from "../ChecklistTab";

interface FollowupTabProps {
  callId: number;
  isFinishedCall?: boolean;
}

const FollowupTab = ({ callId, isFinishedCall }: FollowupTabProps) => {
  const [curentTab, setCurrentTab] = useState(0);

  function renderTab() {
    switch (curentTab) {
      case 0: {
        return <ChecklistTab callId={callId} />;
      }
      case 1: {
        return <AdditionalService callId={callId} isFinishedCall={isFinishedCall} />
      }
      default: {
        return <ChecklistTab callId={callId} />;
      }
    }
  }

  return (
    <div>
      <Tabs data={[{
        title: 'Checklist',
        iconSelected: activeServiceOrderGray,
        iconUnselected: documentGray,
      }, {
        title: 'Serviço adicional',
        iconSelected: scheduleSelected,
        iconUnselected: scheduleUnselected,
      },]}
        currentTab={curentTab}
        setCurrentTab={setCurrentTab}
        renderItem={IconTab}
      />
      <div className="w-full overflow-y-auto min-h-screen overflow-hidden">{renderTab()}
      </div>
    </div>
  )
}

export { FollowupTab }