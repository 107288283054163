import clsx from 'clsx';
import type { TabArrayData } from 'components/TabButtonStatus';
import { useCallback, useState } from 'react';

interface TabByStatusTechnicianCallHistoryProps {
  data: TabArrayData[];
  defaultSelected?: number;
  onClick: (tab: number) => void;
}

const TabByStatusTechnicianCallHistory = ({
  data,
  defaultSelected = 0,
  onClick,
}: TabByStatusTechnicianCallHistoryProps) => {
  const [tabSelected, setTabSelected] = useState(defaultSelected);

  const handleChange = useCallback(
    (tabId: number) => {
      setTabSelected(tabId);
      if (onClick) {
        onClick(tabId);
      }
    },
    [onClick]
  );

  return (
    <div className="w-full flex flex-row space-x-2 bg-lightGray p-2 rounded-2xl">
      {data.map((item) => (
        <button
          key={item.tabId}
          onClick={() => handleChange(item.tabId)}
          className={clsx('w-1/3 p-2 rounded-xl font-semibold', {
            'bg-lighterGray text-darkerGray': tabSelected !== item.tabId,
            'bg-purple text-white': tabSelected === item.tabId,
          })}
        >
          {item.title}
        </button>
      ))}
    </div>
  );
};

export { TabByStatusTechnicianCallHistory };
