import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 8px;
  top: 6px;
  display: flex;
  align-items: center;
  padding: 2px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 6px;
  background: ${(props) => props.theme.colors.lightGray};

  img {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
`;
