import { useMemo, useState } from 'react';
import { useTechnician } from 'queries/technician';
import { PersonalDocumentation } from './PersonalDocumentation';
import { FormOfDisplacement } from './FormOfDisplacement';
import {
  AddressInfo,
  DisplayToken,
  DocumentsList,
  EnglishLevel2,
  FooterSidesheetTechnicianScreening,
  HeaderTechnician,
  RefusingTechnician,
  ServiceCertificate,
  ServiceRegions,
  UrgentCalls,
} from 'features/Technician/components';
import { PeriodsChecklist } from 'features/Technician/components/TechnicianPersonalData/PersonalDataTab';
import { CnpjCard } from './CnpjCard';
import { ViewCanBuyParts } from 'features/Technician/components/ViewCanBuyParts';

export interface SidesheetTechnicianScreeningProps {
  id: string;
  onAfterUpdate?: () => Promise<void>;
  close(): void;
}

const SidesheetTechnicianScreening = ({
  id,
  onAfterUpdate,
  close,
}: SidesheetTechnicianScreeningProps) => {
  const { technician } = useTechnician(id);
  const [isRefusing, setIsRefusing] = useState(false);

  const technicianData = useMemo(() => {
    return (
      {
        infoPersonalData: {
          name: technician?.name,
          birthDate: technician?.birthDate,
          cpf: technician?.cpf,
          rg: technician?.rg,
          cnpj: technician?.cnpj,
          email: technician?.email,
          telephone: technician?.telephone,
          avatar: technician?.avatar,
          status: technician?.status,
        },
        address: technician?.address,
        storeParts: technician?.storeParts,
        transportation: technician?.transportation,
        documents: technician?.documents,
        regions: technician?.regions,
        englishLevel: technician?.englishLevel,
        services: technician?.services,
        periods: technician?.periods,
        urgentCalls: technician?.urgentCalls,
        status: technician?.status,
        tokens: technician?.user?.Tokens,
        cnpjCard: technician?.cnpjCard,
        canBuyParts: technician?.canBuyParts,
      } || {}
    );
  }, [technician]);

  return (
    <div
      className="flex flex-col max-h-screen min-h-screen w-[670px]"
      style={{ width: '670px' }}
    >
      {isRefusing ? (
        <RefusingTechnician
          id={id}
          setIsRefusing={setIsRefusing}
          onAfterUpdate={onAfterUpdate}
          close={close}
        />
      ) : (
        <>
          <HeaderTechnician
            displayCallData={false}
            infoPersonalData={technicianData.infoPersonalData}
          />
          <div className="h-full px-6 py-6 overflow-y-auto">
            <div className="flex flex-col space-y-6">
              {technicianData.status === 'WAITING_FOR_ACTIVATION' && (
                <DisplayToken tokens={technicianData.tokens} />
              )}

              <PersonalDocumentation
                infoPersonal={{ ...technicianData.infoPersonalData, id }}
              />
              <DocumentsList documents={technicianData.documents} />
              <div className="h-[2px] rounded-md bg-gray" />
              <AddressInfo address={technicianData.address} />
              <ServiceRegions regions={technicianData.regions} />
              <FormOfDisplacement
                displacement={technicianData?.transportation}
              />
              <div className="h-[2px] rounded-md bg-gray" />
              <EnglishLevel2 englishLevel={technician.englishLevel} />
              <ServiceCertificate services={technicianData.services} />
              {technician?.cnpjCard && (
                <>
                  <div className="h-[2px] rounded-md bg-gray" />
                  <CnpjCard linkPdf={technician?.cnpjCard} />
                </>
              )}
              <div className="h-[2px] rounded-md bg-gray" />
              <PeriodsChecklist periods={technicianData?.periods} />
              <UrgentCalls urgentCalls={technicianData.urgentCalls} />

              <ViewCanBuyParts canBuyParts={technicianData.canBuyParts} />
            </div>
          </div>

          {technicianData?.status !== 'REFUSED' && (
            <FooterSidesheetTechnicianScreening
              id={id}
              setIsRefusing={setIsRefusing}
              onAfterUpdate={onAfterUpdate}
            />
          )}
        </>
      )}
    </div>
  );
};

export { SidesheetTechnicianScreening };
