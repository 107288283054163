import { getDate, getDayOfWeek } from 'utils/dateUtils';
import { Typography } from 'components/Typography';
import { VerticalLine } from './styles';

interface TimelineProps {
  children: React.ReactNode;
  datetime: Date;
}

const Timeline = ({ children, datetime }: TimelineProps) => {
  return (
    <div className="flex flex-row gap-6">
      <div className="flex flex-col items-center">
        <Typography variant="h3" color="darkestBlue">
          {getDate(datetime)}
        </Typography>
        <Typography variant="tag" color="darkGray" className="mb-2">
          {getDayOfWeek(datetime)}
        </Typography>
        <VerticalLine />
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};

export { Timeline };
