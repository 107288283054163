import { SearchClient } from 'algoliasearch/lite';
import { AlgoliaTotalCount } from 'components/AlgoliaTotalCount';
import { TotalCountTechnicians } from '../TotalCountTechnicians';

interface AlgoliaTotalCountTechniciansProps {
  searchClient: SearchClient;
  filters: string;
}

const AlgoliaTotalCountTechnicians = ({
  filters,
  searchClient,
}: AlgoliaTotalCountTechniciansProps) => {
  return (
    <AlgoliaTotalCount
      filters={filters}
      searchClient={searchClient}
      indexName="technicians"
    >
      <TotalCountTechnicians />
    </AlgoliaTotalCount>
  );
};

export { AlgoliaTotalCountTechnicians };
