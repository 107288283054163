/* eslint-disable */
import React, { useState } from 'react';

import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

interface BusCardProps {
  title: string;
  icon: string;
  isEditable?: boolean;
  children?: React.ReactNode;
  condition: () => JSX.Element;
}

function BonusCard({
  title,
  condition,
  icon,
  children = null,
  isEditable = true,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative flex flex-col border-2 rounded-xl border-lightGray">
      <div className="flex flex-col p-4 space-y-4">
        <div className="absolute top-0 right-0 m-4">
          <img src={icon} />
        </div>
        <Typography variant="h2" color="darkestGray" className="w-2/3">
          {title}
        </Typography>
        <div className="flex flex-col mt-4 space-y-2">
          <Typography variant="h3" color="darkestGray">
            Condição
          </Typography>
          {condition()}
        </div>
      </div>

      {isEditable &&
        (!isOpen ? (
          <div className="flex justify-end w-full p-4">
            <Button
              className="w-1/6"
              size="medium"
              variant="outlined"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Editar
            </Button>
          </div>
        ) : (
          <div className="p-4 border-t-2 border-lightGray">
            {children(() => {
              setIsOpen(false);
            })}
          </div>
        ))}
    </div>
  );
}

export default BonusCard;
