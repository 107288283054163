import styled from 'styled-components';

const getWidth = ({ block, width }) => {
  return block ? '100%' : `${width}px`;
};

export const Container = styled.div`
  height: 100vh;
  width: ${(props) => getWidth(props)};
`;
