import { Button } from 'components/Button';
import { DropDownState } from 'components/DropDownState';
import { TextField } from 'components/Inputs/TextField';
import {
  useAddressByAddressId,
  useAdressCompany,
} from 'features/Company/hooks';
import {
  AddCompanyAddress,
  AddCompanyAddressById,
  EditCompanyAddressById,
} from 'features/Company/services';
import { Field, Form, Formik } from 'formik';
import { useUI } from 'hooks/useUI';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import { getAddress } from 'services/cep';
import { cepMask } from 'utils/maskUtils';
import * as Yup from 'yup';

interface FormAddCompanyAddressesProps {
  addressId?: string;
  companyId?: string;
  isEdit?: boolean;
  close?: () => void;
}

const FormAddCompanyAddresses = ({
  close,
  companyId,
  addressId,
  isEdit,
}: FormAddCompanyAddressesProps) => {
  const [loading, setLoading] = useState(false);
  const UI = useUI();
  const user = useUser();
  const address = useAddressByAddressId(addressId);
  const { refetch } = useAdressCompany();

  const initialValues = {
    address: {
      cep: address?.address?.cep,
      alias: address?.address?.alias,
      number: address?.address?.number,
      address: address?.address?.address,
      state: address?.address?.state,
      city: address?.address?.city,
      district: address?.address?.district,
      complement: address?.address?.complement,
    },
  };

  async function handleSubmit(values) {
    try {
      setLoading(true);

      if (isEdit && addressId) {
        await EditCompanyAddressById(addressId, values.address);

        await refetch();
        close();
        UI.operations.showToast('Endereço editado com sucesso');
        return;
      }

      if (user?.type === 'EuNerd') {
        await AddCompanyAddressById(companyId, values.address);
      } else {
        await AddCompanyAddress(values.address);
      }

      await refetch();
      close();
      UI.operations.showToast('Endereço adicionado com sucesso');
    } catch (error) {
      UI.operations.showToast(error?.message, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  const validationSchema = Yup.object().shape({
    address: Yup.object().shape({
      address: Yup.string().required('Campo obrigatório'),
      city: Yup.string().required('Campo obrigatório'),
      state: Yup.string().required('Campo obrigatório'),
      cep: Yup.string()
        .matches(/^[0-9]{5}-[0-9]{3}$/, 'Cep inválido')
        .required('Campo obrigatório'),
      alias: Yup.string().required('Campo obrigatório'),
      number: Yup.string().required('Campo obrigatório'),
      district: Yup.string().required('Campo obrigatório'),
      complement: Yup.string().notRequired(),
    }),
  });

  async function handleSearchCep(cep, setFieldValue) {
    const address = await getAddress(cep);
    if (!address.erro) {
      setFieldValue('address.cep', address.cep);
      setFieldValue('address.complement', address.complemento);
      setFieldValue('address.city', address.localidade);
      setFieldValue('address.address', address.logradouro);
      setFieldValue('address.state', address.uf);
      setFieldValue('address.district', address.bairro);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-6">
              <Field
                component={TextField}
                name="address.alias"
                label="Apelido"
              />
              <Field
                component={TextField}
                name="address.cep"
                label="Cep"
                onChange={(e) => {
                  const value = cepMask(e.target.value);
                  setFieldValue('address.cep', value);
                  if (value.length === 9) {
                    handleSearchCep(value, setFieldValue);
                  }
                }}
              />

              <div className="flex flex-row space-x-6">
                <DropDownState
                  value={values.address.state}
                  onChange={(e) => setFieldValue('address.state', e)}
                />
                <Field
                  component={TextField}
                  name="address.city"
                  label="Cidade"
                />
              </div>
              <Field component={TextField} name="address.address" label="Rua" />
              <Field
                component={TextField}
                label="Bairro"
                name="address.district"
              />
              <div className="flex flex-row space-x-6">
                <Field
                  component={TextField}
                  name="address.number"
                  label="Número"
                />
                <Field
                  component={TextField}
                  label="Complemento"
                  name="address.complement"
                  showOptionalLabel
                />
              </div>
              <div className="flex flex-row justify-end space-x-8 py-10">
                <Button
                  type="button"
                  variant="anchor"
                  onClick={() => {
                    close();
                  }}
                >
                  Cancelar
                </Button>

                <Button type="submit" className="w-72" loading={loading}>
                  Salvar
                </Button>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export { FormAddCompanyAddresses };
