import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CallSummaryState, CallSummaryPayload, UpdateUrgentCostPerCallPayload } from './types';

const initialState: CallSummaryState = {
  callOfTime: '',
  callType: '',
  categoryService: '',
  client: '',
  date: '',
  price: '',
  professional: '',
  urgentCostPerCall: 0,
  clientAvatar: '',
};

export const callSummarySlice = createSlice({
  name: 'callSummary',
  initialState,
  reducers: {
    addCallSummary: (
      state,
      { payload: { description, key } }: PayloadAction<CallSummaryPayload>
    ) => {
      state[key] = description;
    },
    resetValues: () => initialState,
    updateUrgentCostPerCall: (state, { payload: { value } }: PayloadAction<UpdateUrgentCostPerCallPayload>) => {
      state.urgentCostPerCall = value
    },
    updateClientAvatar: (state, { payload }) => {
      state.clientAvatar = payload.avatar
    }
  },
  extraReducers: {},
});

export default {
  callSummarySlice,
  callSummaryActions: callSummarySlice.actions,
};
