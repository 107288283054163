import { useCallback, useMemo, useState } from 'react';
import {
  Container,
  Content,
  LeftContentInfo,
  Icon,
  ContentActions,
  ContentActionsArrow,
  DetailContainer,
} from './styles';

import useUser from 'hooks/useUser';
import { inactiveDropdown } from 'assets/images/icons';
import { ProfessionalInfoDetail } from '../ProfessionalInfoDetail';
import { ClientFavoriteBadge } from '../ClientFavoriteBadge';
import { ProfessionalContact } from '../ProfessionalContact';
import { ProfessionalSpecialties } from '../ProfessionalSpecialties';
import { ProfessionalWeeklySchedule } from '../ProfessionalWeeklySchedule';

export interface ProfessionalSchedule {
  id: string;
  callId: number;
  createdAt: Date;
  startCall: Date;
  endCall: Date;
  technicianId: string;
}

export interface Service {
  id: string;
  name: string;
  description: string;
}

export interface ProfessionalService {
  service: Service;
}

export interface IProfessional {
  id: string;
  name: string;
  avatar: string;
  email: string;
  telephone: string;
  status: string;
  numberOfCalls: number;
  scheduleCallsTotal: number;
  isFavorite?: boolean;
  rating: number;
  schedule: ProfessionalSchedule[];
  services: ProfessionalService[];
  userType;
}

interface ProfessionalCardProps {
  professional: IProfessional;
  checked?: boolean;
  onAddProfessional: (professional: IProfessional) => void;
  onRemoveProfessional: (id: string) => void;
  disabled?: boolean;
}

const ProfessionalCard = ({
  professional,
  checked = false,
  onAddProfessional,
  onRemoveProfessional,
  disabled = false,
}: ProfessionalCardProps) => {
  const [showDetail, setShowDetail] = useState(false);

  const specialties = useMemo(() => {
    return professional?.services?.map((service) => service.service.name);
  }, [professional]);

  const { type } = useUser();

  const onSelect = useCallback(() => {
    if (professional?.status === 'AVAILABLE') {
      if (!checked) {
        !disabled && onAddProfessional(professional);
      } else {
        onRemoveProfessional(professional?.id);
      }
    }
  }, [
    checked,
    onAddProfessional,
    onRemoveProfessional,
    professional,
    disabled,
  ]);

  return (
    <Container selected={checked} isOpen={showDetail}>
      <Content selected={checked}>
        <LeftContentInfo>
          <Icon
            disabled={disabled}
            selected={checked}
            onClick={() => onSelect()}
          />
          <ProfessionalInfoDetail professional={professional} />
        </LeftContentInfo>
        <ContentActions>
          {professional?.isFavorite && <ClientFavoriteBadge />}
          {type === 'EuNerd' && (
            <ContentActionsArrow
              src={inactiveDropdown}
              isOpen={showDetail}
              onClick={() => setShowDetail(!showDetail)}
            />
          )}
        </ContentActions>
      </Content>
      <DetailContainer isOpen={showDetail} selected={checked}>
        <ProfessionalContact
          email={professional?.email}
          telephone={professional?.telephone}
        />
        <ProfessionalSpecialties specialties={specialties} />
        <ProfessionalWeeklySchedule schedule={professional?.schedule} />
      </DetailContainer>
    </Container>
  );
};

export { ProfessionalCard };
