import { Field, Form, Formik } from 'formik';

import { Button } from 'components/Button';
import { TextField } from 'components/Inputs/TextField';
import { Typography } from 'components/Typography';
import { InputField } from 'components/InputField';
import { currencyMask } from 'utils/formatUtils';
import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import { FileDrop } from 'components/FileDrop';
import { useUI } from 'hooks/useUI';
import { phoneMask } from 'utils/maskUtils';
import { AddBalanceType } from 'types/financial';
import { useCallback } from 'react';
import { Financial } from 'services/financial';
import { confirmBalance } from 'assets/images/illustrations';
import { clock, real } from 'assets/images/icons';

export interface AddBalanceInternalSidesheetProps {
  id: string;
  close: () => void;
}

const AddBalanceInternalSidesheet = ({
  close,
  id,
}: AddBalanceInternalSidesheetProps) => {
  const UI = useUI();

  const addBalance = useCallback(
    async (data: AddBalanceType) => {
      try {
        await Financial.addBalance(id, data);
        UI.operations.showToast('Saldo adicionado com sucesso!');
        close();
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao adicionar saldo';

        if (err.response?.status === 400) {
          erroMessage = err.response.data.message;
        }
        UI.operations.showToast(erroMessage, {
          type: 'error',
        });
      }
    },
    [id, close]
  );

  async function handleSubmit(values) {
    const value = values.value
      ? Number(values.value.replace('.', '').replace(',', '.'))
      : 0;
    const payload: AddBalanceType = {
      ...values,
      value,
    };

    UI.operations.confirm(
      'Confirmar adição de saldo',
      <>
        Você está confirmando que o pagamento foi realizado. <br />
        Após a confirmação, o saldo será liberado para o cliente.
      </>,
      () => {
        addBalance(payload);
      },
      {
        icon: <img src={confirmBalance} />,
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      }
    );
  }
  return (
    <Formik onSubmit={handleSubmit} initialValues={{}}>
      {({ setFieldValue }) => (
        <Form>
          <div className="flex flex-col flex-1 min-h-screen max-w-[603px] ">
            <div className="flex flex-col p-6 space-y-4">
              <div className="flex flex-col space-y-6">
                <Typography variant="h2" color="darkBlue">
                  Adicionar Saldo
                </Typography>
                <Typography variant="p1" color="darkBlue">
                  Inclua todas as informações do pagamento para confirmar a
                  adição do saldo para esse cliente.
                </Typography>
                <div className="flex flex-col space-y-6">
                  <Typography variant="h3" color="darkBlue">
                    Dados do pagamento
                  </Typography>
                  <div className="flex items-center w-full space-x-4">
                    <div className="w-1/2">
                      <Field
                        component={InputField}
                        name="value"
                        label="Valor pago"
                        icon={real}
                        placeholder="0,00"
                        onChange={(e) => {
                          setFieldValue('value', currencyMask(e.target.value));
                        }}
                      />
                    </div>
                    <div className="w-1/2">
                      <Field
                        component={InputField}
                        name="date"
                        label="Data do pagamento"
                        icon={clock}
                        type="date"
                        onChange={(e) => {
                          setFieldValue(
                            'date',
                            format(
                              utcToZonedTime(
                                e.target.value,
                                'America/Sao_Paulo'
                              ),
                              'yyyy/MM/dd'
                            )
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Field
                      as="select"
                      name="formOfPayment"
                      label="Forma de pagamento"
                      className="w-full p-2 border focus:outline-none focus:border-darkGray border-gray rounded-xl"
                    >
                      <option value={null} disabled>
                        Selecione a forma de pagamento
                      </option>
                      <option value="transference">
                        Transferência bancária (TED)
                      </option>
                      <option value="boleto">Boleto</option>
                      <option value="pix">PIX</option>
                    </Field>
                  </div>
                  <Typography variant="h3" color="darkBlue">
                    Adicionar comprovante de pagamento{' '}
                    <Typography variant="h3" color="darkBlue">
                      (Opcional)
                    </Typography>
                  </Typography>
                  <FileDrop
                    onChange={(e) => {
                      setFieldValue('file', e.target.files[0]);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-4">
                  <Typography variant="h3" color="darkBlue">
                    Responsável da empresa pelo pagamento
                  </Typography>
                  <Field
                    component={TextField}
                    label="Nome completo"
                    placeholder="Nome completo"
                    width="100%"
                    name="name"
                  />
                  <div className="flex items-center w-full space-x-4">
                    <div className="w-full">
                      <Field
                        component={TextField}
                        label="E-mail"
                        placeholder="E-mail"
                        width="100%"
                        name="email"
                      />
                    </div>
                    <div className="w-full">
                      <Field
                        component={TextField}
                        label="Telefone"
                        placeholder="Telefone"
                        width="100%"
                        name="telephone"
                        onChange={(e) =>
                          setFieldValue('telephone', phoneMask(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <Field
                    component={TextField}
                    label="Cargo"
                    placeholder="Cargo"
                    width="100%"
                    name="role"
                  />
                  <div className="flex flex-col mt-4 space-y-2">
                    <Typography variant="h3" color="darkBlue">
                      Observações
                    </Typography>

                    <Field
                      className="w-full p-3 border-2 focus:outline-none focus:border-purple border-gray rounded-xl"
                      name="message"
                      rows={5}
                      label="Mensagem"
                      as="textarea"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex self-end justify-end w-full p-4 mt-auto bg-lightGray">
              <div className="w-1/3">
                <Button type="submit" block onClick={null}>
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { AddBalanceInternalSidesheet };
