import {
  CallActions,
  ClientActions,
  FinancialActions,
  PlatformActions,
  TechnicianActions,
} from '../modulesActions';
import { ModulePermissionsType } from '../root';

export const OperationPermissions: ModulePermissionsType = {
  CALL: {
    actions: [
      CallActions.READ,
      CallActions.CREATE,
      CallActions.UPDATE,
      CallActions.CANCEL,
    ],
  },
  TECHNICIAN: {
    actions: [
      TechnicianActions.READ,
      TechnicianActions.CREATE,
      TechnicianActions.ACCEPT,
      TechnicianActions.REJECT,
    ],
  },
  FINANCIAL: {
    actions: [
      FinancialActions.READ,
      FinancialActions.ADD_BALANCE,
      FinancialActions.UPDATE_REGISTRATION_DATA,
    ],
  },
  CLIENT: {
    actions: [
      ClientActions.READ,
      ClientActions.CREATE,
      ClientActions.UPDATE,
      ClientActions.REPORT,
    ],
  },
  PLATFORM: {
    actions: [PlatformActions.READ],
  },
};
