import React from 'react';
import { Typography } from 'components/Typography';
import { ServiceItem } from '..';
import { ServiceSearchItem } from './ServiceSearchItem';

import { Container, CategoryContainer, Checkbox } from './styles';
import { checkedPurple } from 'assets/images/icons';

interface ServicesSearchBarAutocompleteItemProps {
  items: ServiceItem[];
  label: string;
  itemsSelected: string[];
  categorySelected: boolean;
  handleSelectItem(id: string): void;
  handleSelectCategory(): void;
}

const ServicesSearchBarAutocompleteItem = ({
  items,
  label,
  handleSelectItem,
  itemsSelected,
  categorySelected,
  handleSelectCategory,
}: ServicesSearchBarAutocompleteItemProps) => {
  return (
    <Container>
      {items && (
        <CategoryContainer onClick={handleSelectCategory} data-testid="button">
          <Checkbox selected={categorySelected}>
            {categorySelected && (
              <img src={checkedPurple} alt="Ícone de verificação da cor roxa" />
            )}
          </Checkbox>
          <Typography className="mb-2" variant="h3" color="darkestGray">
            {label}
          </Typography>
        </CategoryContainer>
      )}

      {items &&
        items?.map((item) => (
          <ServiceSearchItem
            key={item.id}
            item={item}
            selected={itemsSelected.includes(item.id)}
            handleSelect={handleSelectItem}
          />
        ))}
    </Container>
  );
};

export { ServicesSearchBarAutocompleteItem };
