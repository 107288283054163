/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { Container, Content, ColumnItem } from './styles';
import { Typography } from 'components/Typography';
import { formatCurrency } from 'utils/formatCurrency';
import { activeScheduledTime, activeUsedTime } from 'assets/images/icons';

interface ReviewCallHourItemProps {
  type?: 'default' | 'extra';
  time: string;
  timeInterval: string;
  total: number;
  valueDetail?: string;
}

const ReviewCallHourItem = ({
  time,
  timeInterval,
  type = 'default',
  total,
  valueDetail,
}: ReviewCallHourItemProps) => {
  return (
    <Container>
      <img
        src={type === 'default' ? activeScheduledTime : activeUsedTime}
        alt={`Ícone chamado de ${
          type === 'default' ? 'activeScheduledTime' : 'activeUsedTime'
        }`}
      />
      <Content>
        <ColumnItem>
          <Typography variant="p2" color="darkerGray">
            {type === 'default' ? 'Agendado' : 'Horas extra'}
          </Typography>
          <Typography variant="h3" color="darkestGray">
            {time}
          </Typography>
          <Typography variant="p1" color="darkestGray">
            {timeInterval}
          </Typography>
        </ColumnItem>
        <ColumnItem>
          <Typography variant="p2" color="darkerGray">
            {type === 'default' ? 'Custo base' : 'Custo total'}
          </Typography>
          <Typography variant="h3" color="darkestGray">
            {formatCurrency(total)}
          </Typography>
          {type === 'extra' && (
            <Typography variant="p1" color="darkestGray">
              {valueDetail}
            </Typography>
          )}
        </ColumnItem>
      </Content>
    </Container>
  );
};

export { ReviewCallHourItem };
