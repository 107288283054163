import clsx from 'clsx';
import { Typography } from 'components/Typography';
import { useState } from 'react';

const ChecklistItem = ({
  label,
  checked = false,
  last = false,
  onCheck = null,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isChecked, setIsChecked] = useState(false);
  return (
    <label className="relative inline-flex items-start space-x-4 ">
      <div className="flex flex-col h-full">
        <input
          type="checkbox"
          className={clsx(
            'w-5 h-5 text-purpleHover bg-center bg-contain form-checkbox cursor-pointer',
            {
              'border-lightGray rounded border-2': !checked,
            }
          )}
          checked={checked}
          onChange={onCheck}
          // disabled={!checked}
        />
        {!last && (
          <div className="h-[2rem] m-2">
            <div className="w-[2px] h-full bg-lightGray self-center" />
          </div>
        )}
      </div>
      <div className="flex flex-col w-full">
        <Typography variant="button" color="darkBlue">
          {label}
        </Typography>
        {!last && <div className="w-full h-[2px] bg-lightGray mt-4" />}
      </div>
    </label>
  );
};

export { ChecklistItem };
