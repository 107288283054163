import { format } from 'date-fns';
import { useCall } from 'queries/call';
import { Typography } from 'components/Typography';
import StatusTimeline from './StatusTimeline';
import CallLog from './CallLog';
import { formatUTCHour } from 'utils/formatUtils';
import { useCallback } from 'react';
import { Call } from 'services/call';
import { useUI } from 'hooks/useUI';
import { mapIcon } from 'assets/images/illustrations';
import { ChecklistItem } from './ChecklistItem';

const ChecklistTab = ({ callId }) => {
  const { call, refetch } = useCall(callId);
  const UI = useUI();

  function getStatus(callLog) {
    if (!callLog) {
      return null;
    }
    const callStatusList = callLog.map((l) => l.callStatus);
    if (
      callStatusList.includes('CALL_SUCCESSFULLY_ENDED') ||
      call.status === 'SUPPORT_CANCELED' ||
      call.status === 'UNDER_ANALYSIS'
    ) {
      return 'CALL_SUCCESSFULLY_ENDED';
    } else if (
      callStatusList.includes('SUPPORT_STARTED') ||
      call.status === 'SUPPORT_STARTED'
    ) {
      return 'SUPPORT_STARTED';
    } else if (
      callStatusList.includes('ON_THE_WAY') ||
      call.status === 'ON_THE_WAY'
    ) {
      return 'ON_THE_WAY';
    } else {
      return null;
    }
  }

  const toggleCheckList = useCallback(
    async (id: string) => {
      try {
        await Call.toggleChecklistItem(id);
        refetch();
      } catch (err) {
        UI.operations.showToast('Erro ao marcar item', {
          type: 'error',
        });
      }
    },
    [refetch, UI]
  );

  if (!call) {
    return null;
  }

  return (
    <div className="flex flex-col p-4 space-y-4 ">
      <div className="flex items-center space-x-4">
        <img src={mapIcon} />
        <Typography variant="h2" color="darkBlue">
          Iniciado {format(formatUTCHour(call?.startTime), "H'h'mm")}
        </Typography>
      </div>
      <StatusTimeline status={getStatus(call.callLog)} />
      {call.callLog?.length > 0 && <CallLog callId={callId} />}
      <Typography variant="h2" color="darkestGray">
        Checklist do Chamado
      </Typography>
      <div className="flex flex-col">
        {call.checklistItems?.map((a, idx) => {
          return (
            <ChecklistItem
              key={a.id}
              label={a.description}
              checked={a.checked}
              last={idx === call.checklist.length - 1}
              onCheck={() => toggleCheckList(a.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChecklistTab;
