import clsx from 'clsx';
import { Typography } from 'components/Typography';

const ReviewItem = ({ label, description = null, icon, danger = false }) => {
  return (
    <div className="flex items-center w-full py-2 space-x-4">
      <div
        className={clsx(
          'flex items-center justify-center w-10 h-10 rounded-md ',
          {
            'bg-purple bg-opacity-20 ': !danger,
            'bg-orange': danger,
          }
        )}
      >
        <img src={icon} />
      </div>
      <div className="flex flex-col">
        <Typography variant="h3" color="darkestGray">
          {label}
        </Typography>
        {description && (
          <Typography variant="p1" color="darkestGray">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ReviewItem;
