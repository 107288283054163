import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Content = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;

  p {
    padding: 6px 8px;
    background: ${(props) => props.theme.colors.lightGray};
    border-radius: 8px;
  }
`;
