import styled from 'styled-components';

export const Header = styled.div`
  background: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  padding: 1.5rem 1.2rem;
`;

export const Footer = styled.div`
  background: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  padding: 1rem 1.2rem;
  justify-content: flex-end;
  gap: 1.2rem;
`;

export const Main = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 32px 1.8rem;
  min-height: 761px;
  max-height: min-content;
  gap: 32px;
`;

export const Info = styled.section`
  display: flex;
  flex-direction: column;
  > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
`;
