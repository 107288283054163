import theme from 'themes';
import {
  LinearProgress as MaterialLinearProgress,
  withStyles,
} from '@material-ui/core';

export const LinearProgress = withStyles(() => ({
  colorPrimary: {
    backgroundColor: theme.colors.darkerGray,
    width: 206,
    height: 4,
    borderRadius: 4,
    opacity: 1,
  },
  barColorPrimary: {
    background: 'linear-gradient(270deg, #99E3B2 0%, #7FD6BE 50%)',
  },
}))(MaterialLinearProgress);
