import clsx from 'clsx';
import { Disclosure, Transition } from '@headlessui/react';
import { Typography } from 'components/Typography';
import { useTechnician } from 'queries/technician';
import { cpfMask, phoneMask } from 'utils/maskUtils';
import { Avatar } from 'components/Avatar';
import { useUI } from 'hooks/useUI';
import { SidesheetTypes } from 'ducks/ui/types';
import { technicianStatuses } from 'lib/technician';
import { whatsAppMessageToTechnician } from 'utils/whatsAppUtils';
import { CardActions } from './styles';
import useUser from 'hooks/useUser';
import {
  documentDark,
  downAccordionArrow,
  inactiveEmail,
  inactivePhone,
  messagePurple,
  starGray,
  suitcaseGray,
} from 'assets/images/icons';

interface TechnicianCardProps {
  id: string;
  callId?: number;
}

const TechnicianCard = ({ id, callId = 0 }: TechnicianCardProps) => {
  const { technician } = useTechnician(id);
  const UI = useUI();
  const { type } = useUser();

  return (
    <div className="w-full bg-white border-2 rounded-2xl border-gray">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="flex items-center justify-between w-full px-5 py-4 text-left rounded-lg focus:outline-none"
              data-testid="disclosure-button"
            >
              <div className="flex items-center space-x-4">
                <Avatar name={technician?.name} photoURL={technician?.avatar} />
                <div className="flex flex-col">
                  <Typography variant="h3" color="darkBlue">
                    {technician?.name}
                  </Typography>
                  <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                      <img src={starGray} />
                      <Typography variant="h3" color="darkestGray">
                        {technician?.rating}
                      </Typography>
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <img src={suitcaseGray} />
                        <Typography variant="h3" color="darkestGray">
                          {technician?.numberOfCalls}{' '}
                          {technician?.numberOfCalls > 1
                            ? 'atendimentos'
                            : 'atendimento'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <img
                src={downAccordionArrow}
                className={clsx(
                  'w-2 h-2 transition-all duration-200 ease-in-out',
                  {
                    'transform -rotate-180': open,
                  }
                )}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="pb-2 space-y-4 text-sm">
                <CardActions className="flex justify-center w-full p-4 space-x-4 border-b-2 border-gray">
                  {technician.telephone && (
                    <button
                      className="flex items-center justify-center w-full px-4 py-2 space-x-2 bg-opacity-50 rounded-md hover:bg-opacity-80 focus:outline-none bg-lightGray"
                      onClick={() =>
                        whatsAppMessageToTechnician(technician, callId)
                      }
                    >
                      <div className="p-2 rounded-md bg-lightGray">
                        <img src={messagePurple} />
                      </div>
                      <span className="font-bold text-darkerGray">
                        Enviar Mensagem
                      </span>
                    </button>
                  )}
                  {type === 'EuNerd' && (
                    <button
                      className="flex items-center justify-center w-full px-4 py-2 space-x-2 bg-opacity-50 rounded-md hover:bg-opacity-80 focus:outline-none bg-lightGray"
                      onClick={() => {
                        const data = {
                          id: technician?.id,
                          profile: {
                            avatarUrl: technician?.avatar,
                            name: technician?.name,
                          },
                          name: technician?.name,
                          cpf: technician?.cpf,
                          rg: technician?.rg,
                          cnpj: technician?.cnpj,
                          email: technician?.user?.email,
                          telephone: technician?.telephone,
                          address: technician?.address,
                          documents: technician?.documents,
                          locations: technician?.locations,
                          services:
                            technician?.services.length > 0 &&
                            technician.services.map(
                              (service) => service.service
                            ),
                          periods: technician?.periods,
                          numberOfCalls: technician?.numberOfCalls,
                          status: technicianStatuses[technician?.status],
                          rating: technician?.rating,
                          storeParts: technician?.storeParts,
                          urgentCalls: technician?.urgentCalls,
                        };
                        UI.operations.sidesheet.openSidesheet({
                          type: SidesheetTypes.SidesheetActiveTechnician,
                          sidesheetProps: {
                            id: data?.id,
                          },
                        });
                      }}
                    >
                      <div className="p-2 rounded-md bg-lightGray">
                        <img src={documentDark} />
                      </div>
                      <span className="font-bold text-darkerGray">
                        Perfil do profissional
                      </span>
                    </button>
                  )}
                </CardActions>
                <div className="flex flex-col px-4 pb-2 space-y-4">
                  <div className="space-y-2">
                    <Typography variant="h4" color="darkestGray">
                      Contato
                    </Typography>
                    <ul className="space-y-2">
                      <li className="flex items-center space-x-2">
                        <img src={documentDark} />
                        <Typography variant="p1" color="darkerGray">
                          CPF: {cpfMask(technician.cpf)}
                        </Typography>
                      </li>
                      <li className="flex items-center space-x-2">
                        <img src={documentDark} />
                        <Typography variant="p1" color="darkerGray">
                          RG: {technician.rg}
                        </Typography>
                      </li>
                      <li className="flex items-center space-x-2">
                        <img src={inactivePhone} />
                        <Typography variant="p1" color="darkerGray">
                          {phoneMask(technician.telephone)}
                        </Typography>
                      </li>
                      <li className="flex items-center space-x-2">
                        <img src={inactiveEmail} />
                        <Typography variant="p1" color="darkerGray">
                          {technician?.email}
                        </Typography>
                      </li>
                    </ul>
                  </div>
                  {type === 'EuNerd' && (
                    <div className="space-y-2">
                      <Typography variant="h4" color="darkestGray">
                        Endereço
                      </Typography>
                      <Typography variant="p1" color="darkerGray">
                        {`${technician.address?.address}, ${
                          technician.address?.number
                        }${
                          technician.address?.complement
                            ? ' ' + technician.address?.complement
                            : ''
                        } - ${technician.address?.city} - ${
                          technician.address?.state
                        } - ${technician.address?.cep}`}
                      </Typography>
                    </div>
                  )}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default TechnicianCard;
