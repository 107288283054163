/* eslint-disable */
import React, { useState } from 'react';
import { Container, Content } from './styles';
import { AlertLabel } from 'components/AlertComponents/AlertLabel';
import { Typography } from 'components/Typography';
import { DataTopic } from 'components/DataTopic';
import { ReviewCallHours } from 'components/ReviewCallHours';

import { useCall } from 'queries/call';
import { useUI } from 'hooks/useUI';
import { Call } from 'services/call';
import { activeSuccess, inactiveAlertHover } from 'assets/images/icons';
import { CallStatusReview } from 'components/Calls/CallStatusReview';

export interface CallReviewTabProps {
  callId: any;
}

export const CallReviewTab = ({ callId }: CallReviewTabProps) => {
  const { call } = useCall(callId);
  const UI = useUI();
  const [acceptExtraHour, setAcceptExtraHour] = useState(null);
  const [callReviewStatus, setCallReviewStatus] = useState('');

  const callUnproductive = call?.status === 'UNPRODUCTIVE_CALL';

  function handleFinishCall() {
    UI.operations.confirm(
      'Finalizar chamado',
      'Deseja finalizar este chamado?',
      async () => {
        try {
          if (callReviewStatus === 'call') {
            await Call.finishReview(callId, {
              aditionalCostIsConfirmed: acceptExtraHour || false,
              isUnproductive: false,
            });
          } else if (callReviewStatus === 'client') {
            await Call.finishReview(callId, {
              aditionalCostIsConfirmed: acceptExtraHour || false,
              isUnproductive: true,
              errorTypeUnproductiveCall: 'CLIENT',
            });
          } else if (callReviewStatus === 'professional') {
            await Call.finishReview(callId, {
              aditionalCostIsConfirmed: acceptExtraHour || false,
              isUnproductive: true,
              errorTypeUnproductiveCall: 'PROFESSIONAL',
            });
          }

          UI.operations.showToast('Chamado revisado com sucesso');
          close();
        } catch (err) {
          UI.operations.showToast('Erro ao revisar chamado', {
            type: 'error',
          });
        }
      }
    );
  }

  return (
    <Container>
      {call?.additionalCosts?.calculatedOvertime > 0 && (
        <AlertLabel
          title="Chamado finalizado com horas extra"
          icon={inactiveAlertHover}
        >
          <Typography variant="p1" color="darkerGray">
            É preciso verificar o uso de horas extras para este chamado
          </Typography>
        </AlertLabel>
      )}
      {callUnproductive && (
        <AlertLabel
          title="Este chamado foi marcado como improdutivo por uma das partes, e precisa de revisão para ser finalizado."
          icon={inactiveAlertHover}
        />
      )}
      {call?.status === 'CALL_SUCCESSFULLY_ENDED' && (
        <AlertLabel
          title="Chamado finalizado com sucesso."
          icon={activeSuccess}
          variant="success"
        />
      )}
      <Content>
        <DataTopic
          topics={[
            {
              title: 'Revisão de horas',
              content: () => (
                <ReviewCallHours
                  call={call}
                  onConfirmExtraHours={(value) => setAcceptExtraHour(value)}
                />
              ),
            },
            {
              title: 'Revisão de status',
              content: () => {
                return (
                  <CallStatusReview
                    call={call}
                    callReviewStatus={callReviewStatus}
                    onChange={(value) => setCallReviewStatus(value)}
                    onSubmit={() => handleFinishCall}
                  />
                );
              },
            },
          ]}
        />
      </Content>
    </Container>
  );
};
