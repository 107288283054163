import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';

interface CanBuyPartsProps {
  canBuyParts: boolean;
}

const CanBuyPartsCheck = ({ canBuyParts }: CanBuyPartsProps) => {
  return (
    <div className="space-y-4">
      <Typography variant="p1" color="darkerGray">
        Disponibilidade para compra de peças?
      </Typography>

      <div className="flex flex-row items-center space-x-2">
        <RadioButton
          label="Sim"
          value="true"
          defaultChecked={canBuyParts === true}
          disabled
        />
        <RadioButton
          label="Não"
          value="false"
          defaultChecked={canBuyParts === false}
          disabled
        />
      </div>
    </div>
  );
};
export { CanBuyPartsCheck };
