import { Field, useFormikContext } from 'formik';
import { getAddress } from 'services/cep';
import { cepMask } from 'utils/maskUtils';
import { TextField } from 'components/Inputs/TextField';
import { Typography } from 'components/Typography';
import { SecondFormRow } from './styles';
import { DropDownState } from 'components/DropDownState';

interface FormAddressServiceProps {
  titleformatAddress: string;
}

interface FormAddressServiceContext {
  address: {
    state: string;
  };
}

const FormAddressService = ({
  titleformatAddress,
}: FormAddressServiceProps) => {
  const { setFieldValue, setValues, values } =
    useFormikContext<FormAddressServiceContext>();

  async function handleSearchCep(cep) {
    const address = await getAddress(cep);
    setValues((v) => ({
      ...v,
      address: {
        ...v.address,
        cep: address.cep,
        complement: address.complemento,
        city: address.localidade,
        address: address.logradouro,
        state: address.uf,
        district: address.bairro,
      },
    }));
  }

  return (
    <div className="space-y-4">
      <Typography variant="p1" color="darkerGray">
        {titleformatAddress}
      </Typography>

      <div>
        <Field
          component={TextField}
          label="CEP"
          name="address.cep"
          onChange={(e) => {
            const value = cepMask(e.target.value);
            setFieldValue('address.cep', value);
            if (value.length === 9) {
              handleSearchCep(value);
            }
          }}
        />
        <SecondFormRow>
          <DropDownState
            value={values?.address?.state}
            onChange={(e) => setFieldValue('address.state', e)}
          />
          <Field component={TextField} label="Cidade" name="address.city" />
          <Field component={TextField} label="Bairro" name="address.district" />
        </SecondFormRow>
        <div className="flex flex-row gap-6">
          <Field
            component={TextField}
            label="Endereço"
            width="320px"
            name="address.address"
          />
          <Field
            component={TextField}
            label="Número"
            width="100px"
            name="address.number"
          />
        </div>
        <Field
          component={TextField}
          label="Complemento"
          width="340px"
          name="address.complement"
          showOptionalLabel
        />
      </div>
    </div>
  );
};

export { FormAddressService };
