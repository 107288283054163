import { star, starUnselected } from 'assets/images/icons';

interface StarRatingProps {
  rating: number;
  onChange: (index: number) => void;
}

const StarRating = ({ rating, onChange }: StarRatingProps) => {
  function renderStar(index: number) {
    return (
      <button
        key={index + 1}
        type="button"
        className="focus:outline-none"
        onClick={() => onChange(index + 1)}
      >
        <img
          src={rating >= index + 1 ? star : starUnselected}
          alt={rating >= index + 1 ? 'star' : 'starUnselected'}
          className="h-8"
        />
      </button>
    );
  }
  return (
    <div className="flex items-center justify-center w-full p-4 space-x-4">
      {Array(5)
        .fill(0)
        .map((_, index) => {
          return renderStar(index);
        })}
    </div>
  );
};

export { StarRating };
