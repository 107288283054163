import { Typography } from 'components/Typography';

interface SidesheetHeaderProps {
  children: React.ReactNode;
}

const SidesheetHeader = ({ children }: SidesheetHeaderProps) => {
  return (
    <div className="px-4 py-6 bg-lighterGray">
      <Typography color="darkBlue" variant="h1">
        {children}
      </Typography>
    </div>
  );
};

export { SidesheetHeader };
