import { IconButton } from 'components/IconButton';
import { Typography } from 'components/Typography';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from 'services/api';
import { Info } from '../styles';
import { ServicesSearchBarAutocomplete } from 'components/ServicesSearchBarAutocomplete';
import { specialChars } from 'utils/specialChars';

import { plusSign } from 'assets/images/icons';
import { ServiceItemCreate } from '../ServiceItemCreate';

interface IService {
  id: string;
  comment: string;
  name: string;
  certifications: File[];
}

type ServiceListFormikContext = Array<IService>;

const ServiceList = ({ services = [] }) => {
  const [adding, setAdding] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [comment, setComment] = useState('');
  const [certifications, setCertifications] = useState([]);
  const { data, error } = useSWR('/services', fetcher);
  const { setFieldValue } = useFormikContext<ServiceListFormikContext>();
  const [serviceList, setServiceList] = useState([]);
  const [categoriesSelecteds, setCategoriesSelecteds] = useState<string[]>([]);

  const handleSearchText = useCallback(() => {
    const filterItems = data?.services.map((service) => {
      const servicesFilter = service.services.filter((item) =>
        specialChars(item.name).match(specialChars(searchText))
      );
      if (servicesFilter.length > 0) {
        return {
          ...service,
          services: servicesFilter,
        };
      }
    });

    if (filterItems) {
      setServiceList(filterItems.filter((item) => item !== undefined));
    }
  }, [data, searchText]);

  const formatServicesArray = useCallback(
    (items) => {
      const serviceItems = data?.services
        .map((service) => {
          const newitems = service.services.filter((item) =>
            items.includes(item.id)
          );
          if (newitems.length > 0) {
            return newitems;
          }
        })
        .filter((item) => item !== undefined);
      // eslint-disable-next-line prefer-spread
      return [].concat.apply([], serviceItems);
    },
    [data]
  );

  const handleSelect = useCallback(
    (id: string) => {
      const findItem = services.find((item) => item.id === id);
      if (findItem) {
        const items = services.filter((item) => item.id === id);
        items.forEach((item) => {
          setFieldValue(
            'services',
            services.filter((j) => j.name !== item.name)
          );
        });
      } else {
        const servicesIds = services.map((s) => s.id);
        const items = [...servicesIds, id];
        const servicesSelecteds = formatServicesArray(items);
        servicesSelecteds.forEach((item, index) => {
          setFieldValue(`services[${index}]`, {
            id: item.id,
            name: item.name,
            comment: comment,
            certifications: certifications,
          });
        });
      }
    },
    [services, formatServicesArray, certifications, comment, setFieldValue]
  );

  const handleCheckAllServicesOfCategory = useCallback(
    (categoryId: string) => {
      const service = data?.services.find(
        (serviceObj) => serviceObj.id === categoryId
      );
      if (service) {
        const servicesPush = services;
        service.services.forEach((item) => {
          const findItem = servicesPush.findIndex(
            (serviceItem) => serviceItem.id === item.id
          );
          if (findItem >= 0) {
            servicesPush.splice(findItem, 1);
          } else {
            servicesPush.push({
              id: item.id,
              name: item.name,
              comment: comment,
              certifications: certifications,
            });
          }
        });
        setFieldValue('services', servicesPush);
      }
    },
    [data, services, certifications, comment, setFieldValue]
  );

  const handleSelectCategory = useCallback(
    (categoryId: string) => {
      const findCategorySelected = categoriesSelecteds.find(
        (item) => item === categoryId
      );
      if (findCategorySelected) {
        const newCategoriesSelecteds = categoriesSelecteds.filter(
          (item) => item !== categoryId
        );
        setCategoriesSelecteds(newCategoriesSelecteds);
      } else {
        setCategoriesSelecteds((oldValue) => [...oldValue, categoryId]);
      }
      handleCheckAllServicesOfCategory(categoryId);
    },
    [categoriesSelecteds, handleCheckAllServicesOfCategory]
  );

  useEffect(() => {
    if (!error && data) {
      setServiceList(data?.services);
    }
  }, [data, error]);

  useEffect(() => {
    handleSearchText();
  }, [searchText, handleSearchText]);

  return (
    <Info data-testid="service-list">
      <Typography variant="h3" color="darkBLue">
        Capacitações profissionais
      </Typography>
      {!adding ? (
        <IconButton
          icon={plusSign}
          size="small"
          filled
          onClick={() => setAdding(true)}
          width="200"
        >
          Adicionar Capacitação
        </IconButton>
      ) : (
        <>
          <ServicesSearchBarAutocomplete
            data={serviceList}
            searchText={searchText}
            setSearchText={setSearchText}
            handleSelect={handleSelect}
            itemsSelected={services.map((item) => item?.id)}
            categorySelected={categoriesSelecteds}
            handleSelectCategory={handleSelectCategory}
          />
        </>
      )}
      <div className="flex flex-col space-y-6">
        {services.map((service, i) => (
          <ServiceItemCreate
            service={service}
            key={i}
            onClose={() =>
              setFieldValue(
                'services',
                services.filter((j) => j.name !== service.name)
              )
            }
            addComment={(text) => {
              setComment(text);
              setFieldValue(`services[${i}]`, {
                ...service,
                comment: text,
                certifications: certifications,
              });
            }}
            addCertifications={(values) => {
              setCertifications(values);
              setFieldValue(`services[${i}]`, {
                ...service,
                comment,
                certifications: values,
              });
            }}
          />
        ))}
      </div>
    </Info>
  );
};

export { ServiceList };
