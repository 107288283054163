import { useState, useMemo } from 'react';
import { useTechnicianCalls } from 'queries/technician';
import { RevenueSumaryCard } from '../RevenueSumaryCard';
import { CallsSummaryCard } from '../CallsSummaryCard';
import { CardTechnicianCallHistory } from '../CardTechnicianCallHistory';
import { Typography } from 'components/Typography';
import { months } from 'components/DropdownMonths';

const RevenuesAndCallsTechnicians = ({ id }) => {
  const currentMonth = months[new Date().getMonth()];

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const technicianCalls = useTechnicianCalls(id, {
    month: selectedMonth.value,
  });

  const revenueData = useMemo(() => {
    return (
      {
        valueRevenue: technicianCalls?.calls?.reduce(
          (acc, curr) => acc + curr?.value,
          0
        ),
        finishedCount: technicianCalls?.totalConcluded,
        canceledCount: technicianCalls?.totalCanceled,
        pendingCount: technicianCalls?.totalPending,
        unproductiveCount: technicianCalls?.totalUnproductive,
      } || {}
    );
  }, [technicianCalls]);

  return (
    <div className="flex flex-col py-6">
      <div className="flex flex-row gap-6 mb-6">
        <RevenueSumaryCard
          valueRevenue={technicianCalls?.totalRevenue?.technicianValue || 0}
        />
        <CallsSummaryCard
          finishedCount={revenueData?.finishedCount}
          canceledCount={revenueData?.canceledCount}
          pendingCount={revenueData?.pendingCount}
          unproductiveCount={revenueData?.unproductiveCount}
        />
      </div>
      <Typography variant="h2" color="darkBlue" className="mb-6">
        Histórico de chamados
      </Typography>
      <CardTechnicianCallHistory
        idTechnician={id}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
    </div>
  );
};

export { RevenuesAndCallsTechnicians };
