import { Typography } from 'components/Typography';
import { Info } from '../styles';
import { TextField } from 'components/Inputs/TextField';
import { Field, useFormikContext } from 'formik';
import { cnpjMask, cpfMask, phoneMask } from 'utils/maskUtils';

const PersonalInfoForm = () => {
  const { setFieldValue } = useFormikContext();

  return (
    <Info data-testid="personal-info-form">
      <Typography variant="h3" color="darkBLue">
        Dados Pessoais
      </Typography>
      <div className="flex flex-row space-x-4">
        <div className="w-2/3">
          <Field
            component={TextField}
            label="Nome Completo"
            placeholder="Insira o nome completo"
            width="100%"
            name="name"
          />
        </div>
        <div className="w-1/3">
          <Field
            component={TextField}
            label="Data de nascimento"
            placeholder="Insira sua data de nascimento"
            name="birthDate"
            width="100%"
            type="date"
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="w-1/2">
          <Field
            component={TextField}
            label="CPF"
            placeholder="Insira o CPF"
            width="100%"
            name="cpf"
            onChange={(e) => {
              const value = cpfMask(e.target.value);
              setFieldValue('cpf', value);
            }}
          />
        </div>
        <div className="w-1/2">
          <Field
            component={TextField}
            label="CNPJ"
            placeholder="Insira o CNPJ"
            width="100%"
            name="cnpj"
            onChange={(e) => {
              const value = cnpjMask(e.target.value);
              setFieldValue('cnpj', value);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="w-1/2">
          <Field
            component={TextField}
            label="RG"
            placeholder="Insira o RG"
            width="100%"
            name="rg"
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue('rg', value);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="w-full">
          <Field
            component={TextField}
            label="Email"
            placeholder="Insira o Email"
            width="100%"
            name="email"
          />
        </div>
        <div className="w-1/2">
          <Field
            component={TextField}
            label="Telefone"
            placeholder="Insira o número de Telefone"
            width="100%"
            name="telephone"
            onChange={(e) => {
              const value = phoneMask(e.target.value);
              setFieldValue('telephone', value);
            }}
          />
        </div>
      </div>
    </Info>
  );
};

export { PersonalInfoForm };
