import { lighten } from 'polished';
import styled from 'styled-components';

interface CheckboxProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Checkbox = styled.div<CheckboxProps>`
  margin-top: 3px;
  width: 1rem;
  height: 1rem;
  border: 2px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.purple : theme.colors.lightGray};
  background: ${({ theme, selected }) =>
    selected ? lighten(0.25, theme.colors.purple) : theme.colors.white};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 530px;
`;
