import { Typography } from 'components/Typography';
import { UploadLogoButton } from 'components/UploadLogoButton';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import uploadAttachments from 'services/attachments';
import { ICollaborator } from 'types/collaborator';
import { UserForm } from '../UserForm';
import { uploadFile } from 'assets/images/icons';

import { Container, Header } from './styles';
import { CollaboratorService } from 'services/collaborator';

interface RegistrationInformationProps {
  isVisualization?: boolean;
  data?: ICollaborator;
}

const RegistrationInformation = ({
  isVisualization = false,
  data,
}: RegistrationInformationProps) => {
  const { setFieldValue } = useFormikContext<ICollaborator>();

  const handleUpload = useCallback(
    async (file) => {
      const attachment = await uploadAttachments([file]);
      setFieldValue('photo', attachment[0].url);
      await CollaboratorService.update(data?.id, {
        photo: attachment[0].url,
      });
    },

    [setFieldValue, data]
  );

  return (
    <Container data-testid="registration-information">
      <Header>
        <Typography variant="h2" color="darkBlue">
          Informações de Cadastro
        </Typography>
        <Typography variant="p1" color="darkestGray" className="subtitle">
          Cadastre informações básicas do colaborador, como nome, cargo que
          ocupa atualmente, e-mail e telefone. Você poderá editar quando houver
          necessidade.
        </Typography>
      </Header>
      <UploadLogoButton
        title="Adicionar Foto"
        onChange={handleUpload}
        image={data?.photo || uploadFile}
        data={data}
      />
      <UserForm isVisualization={isVisualization} data={data} />
    </Container>
  );
};

export { RegistrationInformation };
