import { Field, useFormikContext } from 'formik';
import { InputField } from 'components/InputField';
import { StatusButton } from 'components/StatusButton';
import { Alert } from 'components/AlertComponents/Alert';
import { format, utcToZonedTime } from 'date-fns-tz';
import { currencyMask } from 'utils/formatUtils';
import { addDays } from 'date-fns';
import { clock, km, percentual, real } from 'assets/images/icons';
import { CreateCompanyTypes } from 'features/Company/types';

const FormCompanyPayment = ({ isEdit = false }) => {
  const { values, setFieldValue } = useFormikContext<CreateCompanyTypes>();

  return (
    <div
      className="bg-white flex flex-col max-h-[min-content] mb-auto overflow-y-auto divide-y divide-gray"
      data-testid="form-company-payment"
    >
      <div className="flex flex-col px-16 py-6 mt-4 space-y-4">
        {isEdit && (
          <Alert type="danger">
            As alterações realizadas em <b>Informações base e Cobrança</b>{' '}
            somente serão aplicadas a partir do próximo mês
            <b> {'01/' + (new Date().getMonth() + 2)}</b>.
          </Alert>
        )}
        <h2 className="text-2xl font-bold">Informações base do contrato</h2>
        <div className="flex w-full space-x-4 ">
          <div className="flex flex-col w-1/2 space-y-2">
            <p className="text-sm font-bold">Valor contrato</p>
            <p className="text-xs text-darkerGray">
              Informe o valor acordado no contrato
            </p>
            <div className="flex w-full">
              <div className="w-1/3">
                <Field
                  component={InputField}
                  name="contract.amount"
                  label="Valor total contratado"
                  icon={String(real)}
                  placeholder="0,00"
                  onChange={(e) => {
                    setFieldValue(
                      'contract.amount',
                      currencyMask(e.target.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2 space-y-2">
            <p className="text-sm font-bold">Prazo do contrato</p>
            <p className="text-xs text-darkerGray">
              Insira o prazo de validade do contrato acordado
            </p>
            <div className="flex w-full space-x-4">
              <div className="w-1/2">
                <Field
                  component={InputField}
                  name="contract.start"
                  label="Início"
                  icon={String(clock)}
                  type="date"
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setFieldValue(
                        'contract.start',
                        format(
                          utcToZonedTime(e.target.value, 'America/Sao_Paulo'),
                          'yyyy/MM/dd'
                        )
                      );
                    } else {
                      setFieldValue(
                        'contract.start',
                        format(
                          utcToZonedTime(
                            addDays(new Date(), 1),
                            'America/Sao_Paulo'
                          ),
                          'yyyy/MM/dd'
                        )
                      );
                    }
                  }}
                />
              </div>
              <div className="w-1/2">
                <Field
                  component={InputField}
                  name="contract.end"
                  label="Fim"
                  icon={String(clock)}
                  type="date"
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setFieldValue(
                        'contract.end',
                        format(
                          utcToZonedTime(e.target.value, 'America/Sao_Paulo'),
                          'yyyy/MM/dd'
                        )
                      );
                    } else {
                      setFieldValue(
                        'contract.end',
                        format(
                          utcToZonedTime(
                            addDays(new Date(), 1),
                            'America/Sao_Paulo'
                          ),
                          'yyyy/MM/dd'
                        )
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-16 py-6 space-y-4">
        <h2 className="text-2xl font-bold">SLA&apos;s do Cliente</h2>
        <p className="text-xs text-darkerGray">
          O SLA (Service Level Agreement), é o prazo acordado em contrato para
          se entregar determinado serviço
        </p>
        <div className="flex w-full space-x-4 ">
          <div className="flex flex-col w-1/2 space-y-2">
            <p className="text-sm font-bold">Prazo de início dos chamados</p>
            <div className="flex w-full">
              <div className="w-1/3">
                <Field
                  component={InputField}
                  name="sla.startCalls"
                  label="Atendimentos devem iniciar em até (a partir da abertura do chamado)"
                  icon={String(clock)}
                  placeholder="0"
                  suffix="horas"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col space-y-2">
            <div className="flex justify-between space-x-4">
              <div className="flex w-1/4">
                <Field
                  component={InputField}
                  name="sla.cancellationMoreThen24h"
                  label="Cancelamentos mais que 24h antes"
                  icon={String(percentual)}
                  placeholder="0"
                  suffix="do valor total"
                  type="number"
                  disabled={true}
                />
              </div>
              <div className="flex w-1/4">
                <Field
                  component={InputField}
                  name="sla.cancellationFeeUpTo24h"
                  label="Cancelamento até 24h antes"
                  icon={String(percentual)}
                  placeholder="0"
                  suffix="do valor total"
                  type="number"
                  disabled={true}
                />
              </div>
              <div className="flex w-1/4">
                <Field
                  component={InputField}
                  name="sla.cancellationFeeUpTo6h"
                  label="Cancelamento menos que 6h antes"
                  icon={String(percentual)}
                  placeholder="0"
                  suffix="do valor total"
                  type="number"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full space-y-2">
            <p className="text-sm font-bold">Custo por chamado improdutivo</p>
            <p className="text-xs text-darkerGray">
              Em caso de chamado finalizado como improdutivo, por culpa do
              cliente, como o chamado deverá ser cobrado?
            </p>
            <div className="flex w-1/2 space-x-4">
              <StatusButton
                selected={
                  values.sla.unproductiveCostPerCall === 'TOTAL_EXPECTED_COST'
                }
                onClick={() => {
                  setFieldValue(
                    'sla.unproductiveCostPerCall',
                    'TOTAL_EXPECTED_COST'
                  );
                }}
                block
              >
                Cobrar valor previsto total
              </StatusButton>
              <StatusButton
                selected={
                  values.sla.unproductiveCostPerCall ===
                  'TECHNICAL_COST_OF_CALL'
                }
                onClick={() => {
                  setFieldValue(
                    'sla.unproductiveCostPerCall',
                    'TECHNICAL_COST_OF_CALL'
                  );
                }}
                block
              >
                Cobrar custo técnico do chamado
              </StatusButton>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full space-y-2">
            <p className="text-sm font-bold">Custo por chamados urgentes</p>
            <div className="flex w-1/4">
              <Field
                component={InputField}
                name="sla.urgentCostPerCall"
                label="Custo extra por chamados abertos como urgente"
                icon={String(percentual)}
                placeholder="0"
                suffix="do valor total"
                type="number"
                max={100}
                min={0}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-16 py-6 space-y-4">
        <h2 className="text-2xl font-bold">Cobrança</h2>

        <div className="flex w-full space-x-4 ">
          <div className="flex flex-col w-1/2 space-y-2">
            <p className="text-sm font-bold">Custo por KM rodado</p>
            <p className="text-xs text-darkerGray">
              Custo de deslocamento efetuado pelo técnico por quilômetro
              percorrido
            </p>
            <div className="flex w-full space-x-4">
              <div className="w-1/2">
                <Field
                  component={InputField}
                  name="sla.kmIncluded"
                  label="Quilometragem inclusa"
                  icon={String(km)}
                  placeholder="0"
                  type="number"
                />
              </div>
              <div className="w-1/2">
                <Field
                  component={InputField}
                  name="sla.extraCostPerKM"
                  label="Custo por quilômetro extra"
                  icon={String(real)}
                  placeholder="0,00"
                  onChange={(e) => {
                    setFieldValue(
                      'sla.extraCostPerKM',
                      currencyMask(e.target.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2 space-y-2">
            <p className="text-sm font-bold">Custo mensal plataforma</p>
            <p className="text-xs text-darkerGray">
              Custo e a sua recorrência sobre a utilização da plataforma Guio
            </p>
            <div className="flex w-full space-x-4">
              <div className="w-1/2">
                <Field
                  component={InputField}
                  name="sla.monthlyPlatformCost"
                  label="Valor"
                  icon={String(real)}
                  onChange={(e) => {
                    setFieldValue(
                      'sla.monthlyPlatformCost',
                      currencyMask(e.target.value)
                    );
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="flex flex-col w-full text-sm font-medium text-darkerGray">
                  <span className="pb-1">Recorrência</span>
                  <Field
                    as="select"
                    name="sla.recurrence"
                    label="Recorrencia"
                    className="bg-[#fff] w-full p-2 border focus:outline-none focus:border-darkGray border-gray rounded-xl"
                  >
                    <option value="monthly">Mensal</option>
                    <option value="percall">Por chamado</option>
                  </Field>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-16 py-6 space-y-4">
        <h2 className="text-2xl font-bold">Observações</h2>

        <div className="flex w-full space-x-4 ">
          <div className="flex flex-col w-1/2 space-y-2">
            <p className="text-xs text-darkerGray">
              Utilize o campo para adicionar observações sobre o cliente.
            </p>
            <div className="flex w-full space-x-4">
              <Field
                as="textarea"
                rows={6}
                className="w-full p-3 border focus:outline-none focus:border-darkGray border-gray rounded-xl"
                name="sla.obs"
                label="Observações"
                type="textarea"
                data-testid="form-company-payment-comment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FormCompanyPayment };
