import styled, { css } from 'styled-components';

interface ContainerProps {
  elevate: boolean;
}

export const Container = styled.div<ContainerProps>`
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  ${({ elevate }) =>
    elevate
      ? css`
          box-shadow: 0px 10px 20px rgba(108, 108, 117, 0.15);
        `
      : null};
`;
