import styled from 'styled-components';

export const ProfessionalsContainer = styled.div`

  display: grid;
  grid-template-columns: repeat(2, minmax(394px, 1fr));
  gap: 16px;

  padding-bottom: 8px;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(1, minmax(394px, 1fr));
    gap: 16px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-bottom: 32px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
`;

export const ProfessionalsSelectedSection = styled.div`
  margin-top: 32px;
  div > {
    margin-top: 8px;
  }
`;
