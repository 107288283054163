import { Typography } from 'components/Typography';
import { ChangeEvent } from 'react';
import { CheckboxContainer, CheckboxInput, Container } from './styles';

interface CheckboxProps {
  label: string;
  name?: string;
  checked?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: boolean;
}

const Checkbox = ({
  label,
  name,
  checked,
  value,
  onChange,
  disabled = false,
  defaultChecked,
}: CheckboxProps) => {
  return (
    <Container disabled={disabled}>
      <CheckboxInput
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        defaultChecked={defaultChecked}
      ></CheckboxInput>
      <CheckboxContainer checked={checked || defaultChecked} />
      <Typography variant="p1" color="darkerGray">
        {label}
      </Typography>
    </Container>
  );
};

export { Checkbox };
