import { SelectSearch } from 'components/SelectSearch';
import { Typography } from 'components/Typography';
import { useCallback } from 'react';

type AddressCompany = {
  address: string;
  addressBookCompanyId: string;
  alias: string;
  cep: string;
  city: string;
  complement: string;
  createdAt: string;
  distance: number;
  district: string;
  duration: number;
  id: string;
  lat: number;
  lng: number;
  number: string;
  state: string;
};

interface AdressesDropdownSearchProps {
  onChange: (data: { id: string; name: string }) => void;
  disabled?: boolean;
  addresses: AddressCompany[];
  onRemove?: () => void;
}

const AdressesDropdownSearch = ({
  onChange,
  disabled,
  addresses,
  onRemove,
}: AdressesDropdownSearchProps) => {
  const handleClick = useCallback(
    ({ id, name }) => {
      onChange({ id, name });
    },
    [onChange]
  );

  return (
    <div>
      <Typography variant="p1" color="darkerGray">
        Endereços cadastrados
      </Typography>
      <SelectSearch
        data={
          addresses?.map((address) => {
            return {
              id: address.id,
              name: address.alias,
              description: address.address,
            };
          }) || []
        }
        getItemSelected={handleClick}
        placeholder="Selecione"
        disabled={disabled}
        onRemove={onRemove}
      />
    </div>
  );
};

export { AdressesDropdownSearch };
