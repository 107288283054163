import styled, { css } from 'styled-components';

interface StyleProps {
  status: 'unavailable' | 'available';
  type: 'primary' | 'success' | 'danger' | 'warning';
}

const renderDividerColor = {
  primary: css`
    background: ${(props) => props.theme.colors.pink};
  `,
  success: css`
    background: ${(props) => props.theme.colors.green};
  `,
  danger: css`
    background: ${(props) => props.theme.colors.orange};
  `,
  warning: css`
    background: ${(props) => props.theme.colors.yellow};
  `,
};

export const Container = styled.div<StyleProps>`
  margin-top: 16px;
  padding: 12px 16px;
  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 8px;

  display: flex;
  align-items: flex-start;

  ${(props) =>
    props.status === 'unavailable' &&
    css`
      padding: 12px 4px;
      border-color: #fff1ee;
      background: #fff1ee;
      align-items: center;
    `}
`;

export const Divider = styled.div<StyleProps>`
  width: 4px;
  height: 48px;
  border-radius: 2px;
  ${(props) => renderDividerColor[props.type]};
  margin: 0 8px 0 16px;

  ${(props) =>
    props.status === 'unavailable' &&
    css`
      background: ${(props) => props.theme.colors.orangeHover};
      margin: 0 8px 0 0;
    `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  p {
    line-height: 14px;
  }
`;

export const UnavailableDay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
