import React from 'react';
import { appStore, logoMobile, playStore } from 'assets/images/illustrations';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { useUI } from 'hooks/useUI';
import { Container, Content, Image, Footer } from './styles';

interface TokenProps {
  technicianId?: string;
}

const Mobile = ({ technicianId }: TokenProps) => {
  const UI = useUI();

  function copyToClipboard() {
    navigator.clipboard.writeText(technicianId);
    return UI.operations.showToast('Token copiado com sucesso!');
  }

  return (
    <Container className="flex flex-col mt-4 w-full overflow-y-auto z-10">
      <Image src={logoMobile} className="flex mt-4" width="200" />
      <Content className="flex w-full bg-[#FFFFFF] h-full">
        {technicianId ? (
          <div className="flex flex-col p-4 mt-4">
            <Typography
              color="darkBlue"
              variant="h1"
              className="mb-6 leading-10"
            >
              Ative sua conta Eunerd!
            </Typography>
            <Typography
              color="darkerGray"
              variant="p1"
              className="mb-8 leading-6"
            >
              Parabéns, sua conta foi aprovada! Faça sua ativação seguindo os
              passos abaixo.
            </Typography>
            <Typography
              color="darkerGray"
              variant="p1"
              className="mb-8 leading-6"
            >
              1º Abra o aplicativo <b>Guio</b>;
              <br />
              <br />
              2º Na tela de login, clique em <b>ativar conta</b>;
              <br />
              <br />
              3º Copie e insira o token abaixo:
            </Typography>
            <Typography
              color="darkerGray"
              variant="p1"
              className="mb-4 leading-6"
            >
              Token
            </Typography>
            <Typography
              color="darkerGray"
              variant="h2"
              className="mb-8 leading-6 w-full pr-4"
            >
              <input
                className="flex w-full"
                type="text"
                name="technicianId"
                value={technicianId}
                disabled
                style={{
                  fontWeight: 'bold',
                  color: 'black',
                  backgroundColor: 'transparent',
                }}
              />
            </Typography>
            <div className="flex mb-8 gap-5 pr-4">
              <Button
                className="flex w-full justify-center"
                onClick={copyToClipboard}
              >
                Copiar Token
              </Button>
            </div>
            <Footer className="flex flex-row justify-content gap-5">
              <a
                className="flex left-0 w-1/2"
                href="https://play.google.com/store/apps/details?id=br.com.eunerd.guio"
              >
                <img className="rounded-lg" src={playStore} width="150" />
              </a>
              <a
                className="flex left-0 w-1/2"
                href="https://apps.apple.com/br/app/guio/id1558474605"
              >
                <img className="rounded-lg" src={appStore} width="150" />
              </a>
            </Footer>
          </div>
        ) : (
          <div className="flex flex-col p-4 mt-4">
            <Typography
              color="darkBlue"
              variant="h1"
              className="mb-6 leading-10"
            >
              Cadastre-se, seja um profissional Eunerd!
            </Typography>
            <Typography
              color="darkerGray"
              variant="p1"
              className="mb-8 leading-6"
            >
              Trabalhe quando quiser e tenha a possibilidade de receber por seu
              trabalho no mesmo dia.
            </Typography>
            <Typography
              color="darkerGray"
              variant="p1"
              className="mb-8 leading-6"
            >
              Baixe o nosso aplicativo e realize seu cadastro:
              <br />
              <br />
              1º Abra a Play Store (Android) ou App Store (iOS);
              <br />
              <br />
              2º Busque o aplicativo Guio;
              <br />
              <br />
              3º Baixe o aplicativo Guio e realize seu cadastro.
            </Typography>
            <Footer className="flex flex-row justify-content gap-5">
              <a
                className="flex left-0 w-1/2"
                href="https://play.google.com/store/apps/details?id=br.com.eunerd.guio"
              >
                <img
                  className="rounded-lg"
                  src={playStore}
                  width="150"
                  alt="PlayStore"
                />
              </a>
              <a
                className="flex left-0 w-1/2"
                href="https://apps.apple.com/br/app/guio/id1558474605"
              >
                <img
                  className="rounded-lg"
                  src={appStore}
                  width="150"
                  alt="AppStore"
                />
              </a>
            </Footer>
            <div className="flex h-[42px]">
              <br />
            </div>
          </div>
        )}
      </Content>
    </Container>
  );
};

export { Mobile };
