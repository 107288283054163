import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  align-items: center;
  height: 48px;
  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  :hover {
    background-color: rgba(128, 107, 255, 0.05);
    cursor: pointer;
  }
`;
