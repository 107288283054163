/* eslint-disable */
import React, { useCallback, useRef, useState } from 'react';
import { Typography } from 'components/Typography';
import { Accordion } from 'components/Accordion';
import { TextArea } from 'components/Inputs/TextArea';
import { IconButton } from 'components/IconButton';
import { CertificationItem } from 'components/CertificationItem';
import { closeLabel, plusSign } from 'assets/images/icons';

interface ServiceProps {
  id: string;
  name: string | any;
  comment: string;
  certifications: File[] | any;
  title?: string;
  description?: string;
}

interface ServiceItemCreateProps {
  service: ServiceProps;
  onClose?: () => void;
  addComment(comment: string): void;
  addCertifications(certifications: File[]): void;
}

const ServiceItemCreate = ({
  service,
  onClose,
  addComment,
  addCertifications,
}: ServiceItemCreateProps) => {
  const [comment, setComment] = useState('');
  const [certifications, setCertifications] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleChangeText = useCallback(
    (text: string) => {
      setComment(text);
      addComment(text);
    },
    [addComment]
  );

  const handleAddCertification = useCallback(
    (values: File[]) => {
      setCertifications(values);
      addCertifications(values);
    },
    [addCertifications]
  );

  return (
    <Accordion
      title={
        onClose ? (
          <div className="flex flex-row space-x-2">
            <img
              className="cursor-pointer"
              src={closeLabel}
              onClick={onClose}
              alt="Close Service Item"
            />
            <div>{service.name}</div>
          </div>
        ) : (
          service.name
        )
      }
    >
      <Typography variant="p1" color="darkestGray">
        {service.description}
      </Typography>
      <div className="mt-4">
        <Typography variant="p2" color="darkerGray">
          Descrição{' '}
          <span className="text-darkGray text-opacity-80">(opcional)</span>
        </Typography>
        <TextArea
          className="w-full"
          width="100%"
          height="104px"
          onChange={(e) => handleChangeText(e.target.value)}
          value={comment}
          testeId={`text-area-service-item-create-${service?.id}`}
        />
        <Typography variant="p2" color="darkerGray" className="mt-8">
          Possui certificados para esta capacitação?{' '}
          <span className="text-darkGray text-opacity-80">(opcional)</span>
        </Typography>
        <div className="flex flex-col gap-4">
          {certifications.map((c) => (
            <CertificationItem
              name={c.name}
              onClick={() =>
                handleAddCertification(certifications.filter((x) => x !== c))
              }
            />
          ))}
        </div>
        <div className="mt-2">
          <input
            type="file"
            multiple
            className="hidden"
            ref={fileInputRef}
            onChange={(e) => {
              const files = e.target.files;
              const filesArr = Array.prototype.slice.call(files);
              handleAddCertification([...certifications, ...filesArr]);
            }}
          />
          <IconButton
            type="button"
            icon={plusSign}
            size="small"
            filled
            onClick={() => fileInputRef.current.click()}
            width="220"
          >
            Adicionar novo certificado
          </IconButton>
        </div>
      </div>
    </Accordion>
  );
};

export { ServiceItemCreate };
