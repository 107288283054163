import { AboutNewTechnician } from 'features/Call/NotificationComponents/AboutNewTechnician';
import { ContactGuioCard } from 'features/Call/NotificationComponents/ContactGuioCard';
import { HoursReviewCard } from 'features/Call/NotificationComponents/HoursReviewCard';
import { LinkTechnicianCard } from 'features/Call/NotificationComponents/LinkTechnicianCard';
import { NotificationDate } from 'features/Call/NotificationComponents/NotificationDate';
import { RedirectToWhatsappCard } from 'features/Call/NotificationComponents/RedirectToWhatsappCard';
import { StatusReviewCard } from 'features/Call/NotificationComponents/StatusReviewCard';
import { TitleAndDescriptionCard } from 'features/Call/NotificationComponents/TitleAndDescriptionCard';
import { Typography } from 'components/Typography';
import { v4 } from 'uuid';

const NotificationCard = ({
  date,
  title,
  description,
  actions,
  callId,
  companyId,
  technicianId,
  refetch,
}) => {
  return (
    <div className="py-4 space-y-4">
      <NotificationDate date={date} />
      <TitleAndDescriptionCard title={title} description={description} />
      <div className="flex flex-col space-y-2">
        <Typography color="darkerGray" variant="overline">
          Ações
        </Typography>

        {actions.map((a) => {
          if (a === 'LINK_A_NEW_PROFESSIONAL') {
            return (
              <div className="flex flex-col space-y-2" key={v4()}>
                <LinkTechnicianCard
                  callId={callId}
                  refetch={refetch}
                  companyId={companyId}
                />
                <AboutNewTechnician />
              </div>
            );
          } else if (a === 'TALK_TO_THE_PROFESSIONAL') {
            return (
              <RedirectToWhatsappCard
                modelId={technicianId}
                callId={callId}
                key={v4()}
              />
            );
          } else if (a === 'HOURS_REVIEW') {
            return <HoursReviewCard callId={callId} key={v4()} />;
          } else if (a === 'STATUS_REVIEW') {
            return <StatusReviewCard callId={callId} key={v4()} />;
          } else if (a === 'BALANCE_REVIEW') {
            return null;
          } else if (a === 'CONTACT_GUIO') {
            return <ContactGuioCard key={v4()} />;
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export { NotificationCard };
