import { Disclosure, Transition } from '@headlessui/react';
import { downAccordionArrow } from 'assets/images/icons';
import cs from 'clsx';
import { Typography } from 'components/Typography';
import { Container } from './styles';

interface AccordionProps {
  icon?: string;
  title: string | React.ReactNode;
  children: React.ReactNode;
}

const Accordion = ({ icon = null, title, children }: AccordionProps) => {
  return (
    <Container>
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between w-full text-left rounded-lg focus:outline-none">
              <div className="flex items-center space-x-4 px-4 py-2">
                {icon && (
                  <img
                    src={icon}
                    className="object-cover w-12 h-12 rounded-full"
                  />
                )}
                <Typography variant="button" color="darkestGray">
                  {title}
                </Typography>
              </div>
              <Disclosure.Button
                className="flex px-4 py-2"
                data-testid="disclosure-button"
              >
                <img
                  src={downAccordionArrow}
                  alt="Icon"
                  className={cs(
                    'w-2 h-2 transition-all duration-200 ease-in-out',
                    {
                      'transform -rotate-180': open,
                    }
                  )}
                />
              </Disclosure.Button>
            </div>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="px-4 pt-4 pb-2">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </Container>
  );
};

export { Accordion };
