/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  HelloWorldModalProps,
  ConfirmCnpjModalProps,
} from 'components/UI/Modals/types';

import { NewServiceSidesheetProps } from 'components/UI/Sidesheet/NewService';
import { ConfirmCompanyModalProps } from 'components/UI/Modals/ConfirmCompanyModal';
import { ConfirmCompanyEditModalProps } from 'components/UI/Modals/ConfirmEditCompanyModal';
import { ConfirmCreateCallModalProps } from 'components/UI/Modals/ConfirmCreateCall';
import React from 'react';
import { ConfirmCancelModalProps } from 'components/UI/Modals/Confirmation';
import { AddBalanceInternalSidesheetProps } from 'components/UI/Sidesheet/AddBalanceInternal';
import { AlertCallSidesheetProps } from 'components/UI/Sidesheet/AlertCallSidesheet';
import { SidesheetLinkTechnicianProps } from 'components/UI/Sidesheet/SidesheetLinkTechnician';
import { CallReviewTabProps } from 'components/UI/Sidesheet/AlertCallSidesheet/CallReviewTab';
import { EditFormOfServiceFormOfServiceProps } from 'components/UI/Sidesheet/EditFormOfService';
import { ConfirmCreateCollaboratorModalProps } from 'components/UI/Modals/ConfirmCreateCollaborator';
import { UserProfileProps } from 'components/UI/Sidesheet/UserProfile';
import { DisableCollaboratorModalProps } from 'components/UI/Modals/DisableCollaborator';
import { ConfirmCreateFormsOfServiceModalProps } from 'components/UI/Modals/ConfirmCreateFormsOfService';
import { ConfirmChangeRegistrationDataModalProps } from 'components/UI/Modals/ConfirmChangeRegistrationData';
import { EnableCollaboratorModalProps } from 'components/UI/Modals/EnableCollaborator';
import { PaymentHistoryProps } from 'components/UI/Sidesheet/PaymentHistory';
import { ConfirmCreateCallTemplateModalProps } from 'components/UI/Modals/ConfirmCreateCallTemplate';
import { NewCallTemplateProps } from 'components/UI/Sidesheet/NewCallTemplate';
import { SidesheetFinishedCallsProps } from 'features/Call/Sidesheets/SidesheetFinishedCalls';
import { SidesheetOpenCallsProps } from 'features/Call/Sidesheets/SidesheetOpenCalls';
import { SidesheetAddNewCallProps } from 'features/Call/Sidesheets/SidesheetAddNewCall';
import { SidesheetEditCompanyProps } from 'features/Company/Sidesheets/SidesheetEditCompany';
import { SidesheetAddServiceCompanyProps } from 'features/Company/Sidesheets/SidesheetAddServiceCompany';
import { SidesheetActiveTechnicianProps } from 'features/Technician/Sidesheets/SidesheetActiveTechnician';
import { SidesheetTechnicianScreeningProps } from 'features/Technician/Sidesheets/SidesheetTechnicianScreening';
import { SidesheetViewCompanyProps } from 'features/Company/Sidesheets/SidesheetViewCompany';
import { SidesheetNewCompanyProps } from 'features/Company/Sidesheets/SidesheetNewCompany';
import { SidesheetCreateCompanyAddressProps } from 'features/Company/Sidesheets/SidesheetCreateCompanyAddress';

export interface State {
  currentModal?: Modal;
  modalIsOpen: boolean;
  currentSidesheet?: Sidesheet;
  sidesheetIsOpen: boolean;
  messages: Message[];
  componentList: any[];
}

type NotificationType = 'success' | 'error' | 'warning' | 'info';

export interface NotifyPayload {
  message: string;
  title: string;
  type: NotificationType;
  icon?: string;
}

export interface Message {
  message: string;
  title: string;
  type: NotificationType;
  icon?: string;
}

//Modal

export enum ModalTypes {
  HelloWorld,
  ConfirmCnpj,
  ConfirmCompany,
  ConfirmEditCompany,
  CancelCallConfirmation,
  ConfirmCreateCall,
  ModalGenericConfirmation,
  ConfirmCreateCollaborator,
  DisableCollaborator,
  ConfirmCreateFormsOfService,
  ConfirmChangeRegistrationData,
  EnableCollaborator,
  ConfirmCreateCallTemplate,
}

export type ModalIntrinsicProps = {
  close: () => void;
  isOpen: boolean;
  onAfterClose?: () => void;
};

export type ModalBaseProps<T> = T & ModalIntrinsicProps;

type ModalUseProps<P> = Omit<ModalBaseProps<P>, 'close' | 'isOpen'>;

export type OpenModalPayload =
  | {
      type: ModalTypes.HelloWorld;
      modalProps?: ModalUseProps<HelloWorldModalProps>;
    }
  | {
      type: ModalTypes.ConfirmCnpj;
      modalProps?: ModalUseProps<ConfirmCnpjModalProps>;
    }
  | {
      type: ModalTypes.ConfirmCompany;
      modalProps?: ModalUseProps<ConfirmCompanyModalProps>;
    }
  | {
      type: ModalTypes.ConfirmEditCompany;
      modalProps?: ModalUseProps<ConfirmCompanyEditModalProps>;
    }
  | {
      type: ModalTypes.CancelCallConfirmation;
      modalProps?: ModalUseProps<any>;
    }
  | {
      type: ModalTypes.ConfirmCreateCall;
      modalProps?: ModalUseProps<ConfirmCreateCallModalProps>;
    }
  | {
      type: ModalTypes.ConfirmCreateCollaborator;
      modalProps?: ModalUseProps<ConfirmCreateCollaboratorModalProps>;
    }
  | {
      type: ModalTypes.DisableCollaborator;
      modalProps?: ModalUseProps<DisableCollaboratorModalProps>;
    }
  | {
      type: ModalTypes.ConfirmCreateFormsOfService;
      modalProps?: ModalUseProps<ConfirmCreateFormsOfServiceModalProps>;
    }
  | {
      type: ModalTypes.ConfirmChangeRegistrationData;
      modalProps?: ModalUseProps<ConfirmChangeRegistrationDataModalProps>;
    }
  | {
      type: ModalTypes.ConfirmChangeRegistrationData;
      modalProps?: ModalUseProps<ConfirmChangeRegistrationDataModalProps>;
    }
  | {
      type: ModalTypes.EnableCollaborator;
      modalProps?: ModalUseProps<EnableCollaboratorModalProps>;
    }
  | {
      type: ModalTypes.ModalGenericConfirmation;
      modalProps?: ModalUseProps<ConfirmCancelModalProps>;
    }
  | {
      type: ModalTypes.ConfirmCreateCallTemplate;
      modalProps?: ModalUseProps<ConfirmCreateCallTemplateModalProps>;
    }
  | {
      type: ModalTypes.ModalGenericConfirmation;
      modalProps?: ModalUseProps<ConfirmCancelModalProps>;
    };

interface Modal {
  type: ModalTypes;
  props: any;
}

//Sidesheet

export type SidesheetIntrinsicProps = {
  close: () => void;
  isOpen: boolean;
  onAfterClose?: () => void;
  width?: 'wide' | 'normal';
  closeConfirmation?: Omit<OpenConfirmationPayload, 'onConfirm'>;
};

export type SidesheetBaseProps<T> = T & SidesheetIntrinsicProps;

type SidesheetUseProps<P> = Omit<SidesheetBaseProps<P>, 'close' | 'isOpen'>;

export enum SidesheetTypes {
  NewTechnician,

  NewService,

  ManageBonus,
  AddBalance,
  AddBalanceInternal,
  AlertCallSidesheet,
  PaymentHistory,
  NewUser,
  CallReviewTab,
  UserProfile,
  NewFormOfService,
  EditFormOfService,
  NewCallTemplate,

  SidesheetActiveTechnician,
  SidesheetTechnicianScreening,
  SidesheetFinishedCalls,
  SidesheetOpenCalls,
  SidesheetAddNewCall,

  SidesheetViewCompany,
  SidesheetNewCompany,
  SidesheetEdiCompany,
  SidesheetAddServiceCompany,

  SidesheetLinkTechnician,

  SidesheetCreateCompanyAddress,
}

export type OpenSidesheetPayload =
  | {
      type: SidesheetTypes.NewTechnician;
      sidesheetProps?: SidesheetUseProps<any>;
    }
  | {
      type: SidesheetTypes.NewService;
      sidesheetProps?: SidesheetUseProps<NewServiceSidesheetProps>;
    }
  | {
      type: SidesheetTypes.ManageBonus;
      sidesheetProps?: SidesheetUseProps<any>;
    }
  | {
      type: SidesheetTypes.AddBalance;
      sidesheetProps?: SidesheetUseProps<any>;
    }
  | {
      type: SidesheetTypes.AddBalanceInternal;
      sidesheetProps?: SidesheetUseProps<AddBalanceInternalSidesheetProps>;
    }
  | {
      type: SidesheetTypes.AlertCallSidesheet;
      sidesheetProps?: SidesheetUseProps<AlertCallSidesheetProps>;
    }
  | {
      type: SidesheetTypes.SidesheetLinkTechnician;
      sidesheetProps?: SidesheetUseProps<SidesheetLinkTechnicianProps>;
    }
  | {
      type: SidesheetTypes.NewUser;
      sidesheetProps?: SidesheetUseProps<any>;
    }
  | {
      type: SidesheetTypes.PaymentHistory;
      sidesheetProps?: SidesheetUseProps<any>;
    }
  | {
      type: SidesheetTypes.CallReviewTab;
      sidesheetProps?: SidesheetUseProps<CallReviewTabProps>;
    }
  | {
      type: SidesheetTypes.UserProfile;
      sidesheetProps?: SidesheetUseProps<UserProfileProps>;
    }
  | {
      type: SidesheetTypes.NewFormOfService;
      sidesheetProps?: SidesheetUseProps<any>;
    }
  | {
      type: SidesheetTypes.EditFormOfService;
      sidesheetProps?: SidesheetUseProps<EditFormOfServiceFormOfServiceProps>;
    }
  | {
      type: SidesheetTypes.NewCallTemplate;
      sidesheetProps?: SidesheetUseProps<NewCallTemplateProps>;
    }
  | {
      type: SidesheetTypes.PaymentHistory;
      sidesheetProps?: SidesheetUseProps<PaymentHistoryProps>;
    }
  | {
      type: SidesheetTypes.SidesheetActiveTechnician;
      sidesheetProps?: SidesheetUseProps<SidesheetActiveTechnicianProps>;
    }
  | {
      type: SidesheetTypes.SidesheetTechnicianScreening;
      sidesheetProps?: SidesheetUseProps<SidesheetTechnicianScreeningProps>;
    }
  | {
      type: SidesheetTypes.SidesheetFinishedCalls;
      sidesheetProps?: SidesheetUseProps<SidesheetFinishedCallsProps>;
    }
  | {
      type: SidesheetTypes.SidesheetOpenCalls;
      sidesheetProps?: SidesheetUseProps<SidesheetOpenCallsProps>;
    }
  | {
      type: SidesheetTypes.SidesheetAddNewCall;
      sidesheetProps?: SidesheetUseProps<SidesheetAddNewCallProps>;
    }
  | {
      type: SidesheetTypes.SidesheetViewCompany;
      sidesheetProps?: SidesheetUseProps<SidesheetViewCompanyProps>;
    }
  | {
      type: SidesheetTypes.SidesheetNewCompany;
      sidesheetProps?: SidesheetUseProps<SidesheetNewCompanyProps>;
    }
  | {
      type: SidesheetTypes.SidesheetEdiCompany;
      sidesheetProps?: SidesheetUseProps<SidesheetEditCompanyProps>;
    }
  | {
      type: SidesheetTypes.SidesheetAddServiceCompany;
      sidesheetProps?: SidesheetUseProps<SidesheetAddServiceCompanyProps>;
    }
  | {
      type: SidesheetTypes.SidesheetCreateCompanyAddress;
      sidesheetProps?: SidesheetUseProps<SidesheetCreateCompanyAddressProps>;
    };

interface Sidesheet {
  type: SidesheetTypes;
  props: any;
  open: boolean;
}

export interface OpenConfirmationPayload {
  title: string;
  description: string | JSX.Element;
  onConfirm: () => void;
  icon?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  danger?: boolean;
  cancelRed?: boolean;
}
