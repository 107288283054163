import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface ContainerProps {
  variant?: 'warning'| 'success';
}

const renderContainerVariant = {
  warning: css`
    background: ${({ theme }) => lighten(0.3, theme.colors.yellowClick)};
  `,
  success: css`
    background: ${({ theme }) => lighten(0.3, theme.colors.greenClick)};
  `
}

export const Container = styled.div<ContainerProps>`
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    margin-right: 1rem;
  }

  ${({ variant }) => renderContainerVariant[variant]}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
`;
