import { ToastProps } from 'react-toast-notifications';
import clsx from 'clsx';
import { cancelSearch } from 'assets/images/icons';

const Toast: React.FC<ToastProps> = ({
  appearance,
  children,
  transitionState,
  onDismiss,
}) => {
  return (
    <div
      className={clsx(
        'flex flex-row items-center justify-between p-6 z-[20001] mb-4 transition-all duration-800 ease-in-out border-l-4 border-gray-100 relative bg-lighterGray transform shadow-xl w-96 4xl:w-auto',
        {
          'border-red-500': appearance === 'error',
          'border-greenClick': appearance === 'success',
          'border-yellowClick': appearance === 'warning',
          'border-blue-500': appearance === 'info',
          'translate-y-full opacity-0':
            transitionState === 'exiting' || transitionState === 'entering',
        }
      )}
      style={{ zIndex: 999999 }}
    >
      <div className="flex items-center w-full space-x-4">{children}</div>
      <button onClick={() => onDismiss()}>
        <img src={cancelSearch} className="w-6 h-8" />
      </button>
    </div>
  );
};

export { Toast };
