import styled, { css } from 'styled-components';
import { Transition } from '@headlessui/react';
import { CallMarkerProps } from '.';

export const Marker = styled.button<CallMarkerProps>`
  position: absolute;
  width: 99px;
  height: 68px;
  transition: 0.2s;

  ${({ active }) =>
    active &&
    css`
      z-index: 10;
      transform: scale(1.2);
    `}
  &:hover,
  &:active {
    z-index: 11;
    /* transform: scale(1.2); */
    section {
      background-color: ${({ theme }) => theme.colors?.purple};
    }
  }
  &:active,
  &:focus {
    outline: 0;
  }
`;

export const Location = styled.section<CallMarkerProps>`
  width: 99px;
  height: 28px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors?.purple : theme.colors?.darkerGray};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const Text = styled.p`
  font: ${({ theme }) => theme.typography?.tag};
  color: ${({ theme }) => theme.colors?.lighterGray};
`;

export const Footer = styled.div`
  width: 99px;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const InformationContainer = styled.div`
  font: ${({ theme }) => theme.typography?.overline};
  color: ${({ theme }) => theme.colors?.lighterGray};
  width: 100%;
  height: 16px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const Divisor = styled.div`
  width: 2px;
  height: 16px;
  background: ${({ theme }) => theme.colors?.darkGray};
  border-radius: 1px;
`;

export const Image = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const MicroMarker = styled.button<CallMarkerProps>`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: ${({ theme, active }) =>
    !active ? theme.colors?.darkestGray : theme.colors?.purple};
  transition: 0.2s;
  ${({ active }) =>
    active &&
    css`
      z-index: 10;
      transform: scale(3);
    `}
  &:hover,
  &:active {
    transform: scale(3);
    background: ${({ theme }) => theme.colors?.purple};
  }
  &:active,
  &:focus {
    outline: 0;
  }
  &:active {
    transform: scale(0.95);
  }
`;

export const Container = styled.div`
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LookButton = styled<React.ElementType>(Transition)`
  position: absolute;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors?.purple};
  border-radius: 8px;
  transition: 0.2s;

  bottom: -14px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: auto;
  }
`;

export const PathMarker = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 24px;
  border: 4px dashed ${({ theme }) => theme.colors?.darkestGray};
  z-index: -2;
`;
