import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.lighterGray};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`;
