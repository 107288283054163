import {
  clock,
  defaultAvatar,
  dollarSign,
  toolsPurple,
  id as idIcon,
} from 'assets/images/icons';
import { Button } from 'components/Button';
import { IconLabel } from 'components/IconLabel';
import { List } from 'components/List';
import { StatusLabel } from 'components/StatusLabel';
import { serviceType, statusCallDetails } from 'lib/call';
import { formatCurrency } from 'utils/formatCurrency';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';

interface CallDetailsCardProps {
  value: number;
  companyName: string;
  serviceName: string;
  avatarClient: string;
  callType: string;
  statusCall: string;
  id: number;
  viewButton?: boolean;
}

const CallDetailsCard = ({
  value,
  companyName,
  serviceName,
  avatarClient,
  callType,
  statusCall,
  id,
  viewButton = true,
}: CallDetailsCardProps) => {
  const { operations } = useUI();

  const dataList = [
    { icon: idIcon, label: id },
    {
      icon: dollarSign,
      label: formatCurrency(value || 0)
    },
    { icon: avatarClient || defaultAvatar, label: companyName },
    { icon: toolsPurple, label: serviceName },
    { icon: clock, label: serviceType[callType]?.label },
  ];

  const handleOpenCall = () => {
    const finishedCall = [
      statusCallDetails.SUPPORT_CANCELED.value,
      statusCallDetails.CALL_SUCCESSFULLY_ENDED.value,
      statusCallDetails.UNPRODUCTIVE_CALL.value,
    ];

    const type = finishedCall.includes(statusCall)
      ? SidesheetTypes.SidesheetFinishedCalls
      : SidesheetTypes.SidesheetOpenCalls;

    operations.sidesheet.openSidesheet({
      type,
      sidesheetProps: {
        id: id,
        statusCall: statusCall,
      },
    });
  };

  return (
    <div
      className="border-2 border-lightGray rounded-2xl "
      data-testid="call-details-card"
    >
      <div className="border-t-2 border-lightGray first:border-t-0">
        <div className="p-3 items-center">
          <StatusLabel
            status={statusCall}
            icon={statusCallDetails[statusCall]?.icon}
          >
            {statusCallDetails[statusCall]?.label}
          </StatusLabel>
        </div>

        <List
          data={dataList}
          renderItem={({ icon, label }) => (
            <div className="p-5 border-t-2 border-lightGray">
              <IconLabel icon={icon} color="lightPurple">
                {label}
              </IconLabel>
            </div>
          )}
        />
        {viewButton && (
          <div className="w-full items-center flex justify-center py-4  border-t-2 border-lightGray">
            <Button
              variant="outlined"
              className="w-4/5"
              onClick={handleOpenCall}
            >
              Ver mais
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export { CallDetailsCard };
