import Location from 'lib/location';

export default class Technician {
  constructor(data) {
    this.id = data.id;
    this.birthDate = data.birthDate;
    this.cnpj = data.cnpj;
    this.status = technicianStatuses[data.status];
    this.cnpj = data.cnpj;
    this.cpf = data.cpf;
    this.rg = data.rg;
    this.documents = data.documents;
    this.name = data.name;
    this.email = data.email;
    this.telephone = data.telephone;
    this.schedule = '---';
    this.reviews = '---';
    this.rating = data.rating;
    this.numberOfCalls = data.numberOfCalls;
    this.storeParts = data.storeParts;
    this.urgentCalls = data.urgentCalls;
    this.locations = data.regions.map((region) => new Location(region.region));
    this.services = data.services.map((service) => ({
      name: service.service.name,
      comment: service.comment,
      description: service.service.description,
      certification: service.certifications,
    }));
    this.periods = data?.periods?.map((period) => ({
      weekDay: period.weekDay,
      morning: period.morning,
      afternoon: period.afternoon,
      night: period.night,
    }));
    this.revenue = '---';
    this.profile = {
      avatarUrl: data.avatar,
      name: data.name,
      status: data.status,
    };
    this.address = data.address;
    this.user = data.user;
    this.displacement = data.displacement;
    this.englishLevel = data.englishLevel;
  }
}

export const technicianStatuses = {
  AVAILABLE: {
    displayText: 'Disponível',
    color: 'greenClick',
  },
  INACTIVE: {
    displayText: 'Inativo',
    color: 'orange',
  },
  IN_PROGRESS: {
    displayText: 'Em progresso',
    color: 'blue',
  },
  IN_SERVICE: {
    displayText: 'Em serviço',
    color: 'orange',
  },
  PRE_APPROVED: {
    displayText: 'Pré aprovado',
    color: 'blue',
  },
  REFUSED: {
    displayText: 'Reprovado',
    color: 'orange',
  },
  WAITING_FOR_ACTIVATION: {
    displayText: 'Aguardando ativação',
    color: 'purple',
  },
  WAITING_FOR_APPROVAL: {
    displayText: 'Aguardando aprovação',
    color: 'yellowClick',
  },
};
