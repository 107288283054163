import styled from 'styled-components';

export const VerticalItemDivider = styled.div`
  position: absolute;
  width: 2px;
  height: 40px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 2px;
  bottom: -48px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;

  &:last-of-type {
    ${VerticalItemDivider} {
      display: none;
    }
  }
`;

export const TimelineContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const ChecklistCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 2px solid ${({ theme }) => theme.colors.purple};
  border-radius: 4px;
  background: #e7e6f7;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }
  input {
    flex: 1;
    margin-right: 16px;
    width: 31.25rem;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  padding: 3px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 4px;

  img {
    width: 12px;
    height: 12px;
  }

  transition: 0.1s;
  &:active {
    transform: scale(0.9);
  }
`;
