import { withTheme } from 'styled-components';
import { Container } from './styles';
import ClipLoader from 'react-spinners/ClipLoader';

type VariantTypes = 'primary' | 'secondary';

const getColor = (variant: VariantTypes, colors) => {
  switch (variant) {
    case 'primary':
      return colors.purple;
    case 'secondary':
      return colors.lighterGray;
    default:
      return colors.purple;
  }
};

export interface SpinnerProps {
  variant?: VariantTypes;
  theme?: { colors: unknown };
}

const Spinner = ({ theme: { colors }, variant = 'primary' }: SpinnerProps) => {
  return (
    <Container variant={variant}>
      <ClipLoader color={getColor(variant, colors)} size={24} />
    </Container>
  );
};

export default withTheme(Spinner);
