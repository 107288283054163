import { Typography } from 'components/Typography';
import { ButtonNewItem, IconPlusNewItem } from './style';
import { toAdd } from 'assets/images/icons';

const NewChecklistItemButton = ({ addNewItem, isDisabled }) => {
  return (
    <ButtonNewItem type="button" onClick={addNewItem} disabled={isDisabled}>
      <IconPlusNewItem>
        <img src={toAdd} />
      </IconPlusNewItem>
      <Typography variant="button" color="darkerGray">
        Adicionar novo item
      </Typography>
    </ButtonNewItem>
  );
};

export { NewChecklistItemButton };
