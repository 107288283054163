import clsx from 'clsx';
import { Typography } from 'components/Typography';

interface StatusTimelineProps {
  status: 'ON_THE_WAY' | 'SUPPORT_STARTED' | 'CALL_SUCCESSFULLY_ENDED';
}

const StatusTimeline: React.FC<StatusTimelineProps> = ({ status }) => {
  return (
    <div className="flex space-x-2">
      <div className="flex flex-col w-1/3">
        <Typography variant="button" color="darkGray">
          A caminho
        </Typography>
        <div
          className={clsx('w-full h-1 rounded', {
            'bg-greenClick': status === 'ON_THE_WAY',
            'bg-greenHover':
              status === 'SUPPORT_STARTED' ||
              status === 'CALL_SUCCESSFULLY_ENDED',
            'bg-lightGray': !status,
          })}
        ></div>
      </div>
      <div className="flex flex-col w-1/3">
        <Typography variant="button" color="darkGray">
          Iniciado
        </Typography>
        <div
          className={clsx('w-full h-1 rounded', {
            'bg-greenClick': status === 'SUPPORT_STARTED',
            'bg-greenHover': status === 'CALL_SUCCESSFULLY_ENDED',
            'bg-lightGray': status === 'ON_THE_WAY' || !status,
          })}
        ></div>
      </div>
      <div className="flex flex-col w-1/3">
        <Typography variant="button" color="darkGray">
          Finalizado
        </Typography>
        <div
          className={clsx('w-full h-1 rounded', {
            'bg-greenClick': status === 'CALL_SUCCESSFULLY_ENDED',
            'bg-lightGray': status !== 'CALL_SUCCESSFULLY_ENDED' || !status,
          })}
        ></div>
      </div>
    </div>
  );
};

export default StatusTimeline;
