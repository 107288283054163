import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  padding: 8px;
  width: 104px;
  border: 2px ;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 8px;
`;
