import { Typography } from 'components/Typography';
import { FormAddCompanyAddresses } from 'features/Company/components/FormAddCompanyAddresses';

export interface SidesheetCreateCompanyAddressProps {
  close: () => void;
  companyId: string;
  addressId?: string;
  isEdit?: boolean;
}

const SidesheetCreateCompanyAddress = ({
  close,
  companyId,
  addressId,
  isEdit,
}: SidesheetCreateCompanyAddressProps) => {
  return (
    <div className="flex flex-col max-h-screen min-h-screen">
      <div className="bg-lightGray flex py-6 px-14">
        {isEdit ? (
          <Typography variant="h1" color="darkBlue">
            Editar endereço
          </Typography>
        ) : (
          <Typography variant="h1" color="darkBLue">
            Cadastrar endereço
          </Typography>
        )}
      </div>

      <div className="py-6 px-14 space-y-6">
        <div>
          <Typography variant="h2" color="darkBLue">
            Informações
          </Typography>
          <Typography variant="p1" color="darkBLue">
            Insira o endereço da sua sede e filiais. Você poderá editar quando
            houver necessidade.
          </Typography>
        </div>
        <FormAddCompanyAddresses
          close={close}
          companyId={companyId}
          addressId={addressId}
          isEdit={isEdit}
        />
      </div>
    </div>
  );
};

export { SidesheetCreateCompanyAddress };
