import { CallActions } from 'utils/security/modulesActions';
import { usePermissions } from './usePermissions';

export function useCallPermissions() {
  const { permissions } = usePermissions();

  function canCreateCall(): boolean {
    return permissions?.CALL.actions.includes(CallActions.CREATE);
  }

  function canUpdateCall(): boolean {
    return permissions?.CALL.actions.includes(CallActions.UPDATE);
  }

  function canCancelCall(): boolean {
    return permissions?.CALL.actions.includes(CallActions.CANCEL);
  }

  function canReadCall(): boolean {
    return permissions?.CALL.actions.includes(CallActions.READ);
  }

  return {
    canReadCall: canReadCall(),
    canCreateCall: canCreateCall(),
    canUpdateCall: canUpdateCall(),
    canCancelCall: canCancelCall(),
  };
}
