import { SearchBoxInput } from './styles';
import { search } from 'assets/images/icons';

export function SearchCardAlgolia({ placeholder = 'Buscar' }) {
  return (
    <div className="w-[550px] justify-between flex items-center px-6 py-8 space-x-4 border-2 cursor-pointer rounded-xl border-gray hover:border-purpleHover">
      <div className="flex flex-row w-full space-x-4 items-center">
        <div className="flex items-center justify-center flex-shrink-0 w-[32px] h-[32px] rounded-md bg-purple">
          <img src={search} />
        </div>
        <SearchBoxInput
          autoFocus
          translations={{
            placeholder,
          }}
          className="text-[12px] w-full focus:text-sm font-bold placeholder-darkestGray focus:font-normal focus:placeholder-white"
        />
      </div>
    </div>
  );
}
