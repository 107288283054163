import { translateEnglishLevel } from 'data/englishLevel';
import { Typography } from 'components/Typography';
import { language } from 'assets/images/icons';
import { PersonalDataItem } from '../PersonalDataItem';

const EnglishLevel2 = ({ englishLevel }) => {
  return (
    <>
      <Typography variant="h3" color="darkBLue">
        Nível de inglês
      </Typography>
      <PersonalDataItem
        title=""
        value={translateEnglishLevel(englishLevel)}
        icon={language}
      />
    </>
  );
};

export { EnglishLevel2 };
