import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { Company } from 'services/company';
import { useUI } from 'hooks/useUI';
import { ModalTypes } from 'ducks/ui/types';
import { ViewFormCompanyData } from '../ViewFormCompanyData';
import { CompaniesTypes } from 'features/Company/types';

interface CompanyInfoDataProps {
  onEdit?: (values) => void;
  data?: CompaniesTypes;
}

const CompanyInfoData = ({ onEdit, data }: CompanyInfoDataProps) => {
  const queryClient = useQueryClient();
  const UI = useUI();

  const handleSubmit = async (values) => {
    UI.operations.modal.openModal({
      type: ModalTypes.ConfirmEditCompany,
      modalProps: {
        type:
          JSON.stringify(data.name) !== JSON.stringify(values.responsible)
            ? 'company-email'
            : 'company',
        email: values.responsible.email,
        onConfirm: async () => {
          try {
            await Company.edit(data.id, values);
            queryClient.refetchQueries('companies');
          } catch (err) {
            // TODO: Handle error with toast
          } finally {
            if (onEdit) {
              await onEdit(values);
            }
          }
        },
      },
    });
  };

  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validateOnMount={true}
      enableReinitialize
    >
      {() => (
        <Form>
          <ViewFormCompanyData />
        </Form>
      )}
    </Formik>
  );
};

export { CompanyInfoData };
