/* eslint-disable */
import { useFormikContext } from 'formik';
import { Typography } from 'components/Typography';
import { CheckReview } from './CheckReview';
import { Container } from './styles';
import { Button } from 'components/Button';
import { TextArea } from 'components/Inputs/TextArea';

export const PaymentStatusUpdate = ({ setCurrentStatus }) => {
  const { values, setFieldValue } = useFormikContext<any>();

  function validDisabled() {
    if (!values.paymentStatus) {
      return true;
    } else if (
      values.paymentStatus === 'PAYMENT_DECLINED' &&
      !values.paymentRefusedJustification
    ) {
      return true;
    }
  }

  return (
    <Container>
      <Typography variant="h3" color="darkestGray">
        Atualizar status de pagamento
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Como o chamado foi marcado como improdutivo, é preciso adicionar uma
        revisão para o status final. Com base nas justificativas apresentadas
        pela empresa e profissional, adicione o veredito para o chamado:
      </Typography>
      <CheckReview
        active={values.paymentStatus === 'SCHEDULED_PAYMENT'}
        onChange={() => setFieldValue('paymentStatus', 'SCHEDULED_PAYMENT')}
        title="Pagamento agendado realizado"
        desceription="Selecione esta opção caso o técnico tenha solicitado receber o pagamento apenas no fechamento do mês corrente."
      />
      <CheckReview
        active={values.paymentStatus === 'ANTECIPATED_PAYMENT'}
        onChange={() => setFieldValue('paymentStatus', 'ANTECIPATED_PAYMENT')}
        title="Pagamento antecipado realizado"
        desceription="Selecione esta opção caso o técnico tenha solicitado antecipar o pagamento. 
              O valor do chamado sofrerá redução de 5% pela antecipação."
      />
      <CheckReview
        active={values.paymentStatus === 'PAYMENT_DECLINED'}
        onChange={() => setFieldValue('paymentStatus', 'PAYMENT_DECLINED')}
        title="Pagamento recusado"
        desceription="Selecione esta opção caso o técnico tenha alguma irregularidade relacionada ao chamado. Exemplos, chamado improdutivo ou nota fiscal incorreta. Não esqueça de adicionar a justificativa para mesmo."
      />

      {values.paymentStatus === 'PAYMENT_DECLINED' && (
        <>
          <Typography variant="h3" color="darkestGray">
            Adicione uma justificativa para a decisão do pagamento recusado
          </Typography>
          <Typography variant="p1" color="darkerGray">
            Utilize o campo para adicionar a justificativa.
          </Typography>
          <TextArea
            width="100%"
            value={values.paymentRefusedJustification}
            onChange={(e) =>
              setFieldValue('paymentRefusedJustification', e.target.value)
            }
          />
        </>
      )}

      <Button
        block
        disabled={validDisabled()}
        onClick={() => setCurrentStatus(values.paymentStatus)}
      >
        Próximo
      </Button>
    </Container>
  );
};
