import { Typography } from 'components/Typography';
import { AlertIcon } from '../AlertIcon';

interface TitleAndDescriptionCardProps {
  title: string;
  description: string;
}

const TitleAndDescriptionCard = ({
  title,
  description,
}: TitleAndDescriptionCardProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row space-x-2">
        <AlertIcon />
        <Typography color="darkestBlue" variant="h3">
          {title}
        </Typography>
      </div>
      <Typography color="darkestGray" variant="p1">
        {description}
      </Typography>
    </div>
  );
};

export { TitleAndDescriptionCard };
