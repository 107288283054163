import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { ModalBaseProps } from 'components/UI/Modals/types';
import { Container, ExitButton } from './styles';
import cs from 'clsx';
import { closeModal as closeModalIcon } from 'assets/images/icons';

const ModalBase: React.FC<ModalBaseProps> = ({
  close,
  isOpen,
  children,
  onAfterClose,
}) => {
  function closeModal() {
    if (onAfterClose) {
      onAfterClose();
    }
    close();
  }

  const exitButton = useRef(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={cs(
                'bg-opacity-30 absolute inset-0 z-0 transition-opacity bg-black'
              )}
            />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Container>
              <button
                ref={exitButton}
                className="w-0 h-0 pointer-events-none"
              />
              <ExitButton type="button" onClick={closeModal}>
                <img src={closeModalIcon} alt="x" />
              </ExitButton>
              {children}
            </Container>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalBase;
