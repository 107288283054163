/* eslint-disable @typescript-eslint/no-explicit-any */
import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';
import { ENGLISH_LEVEL } from 'data/englishLevel';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { Container, Content } from './styles';
interface EnglishLevelProps {
  viewOnly?: boolean;
}

const EnglishLevel = ({ viewOnly = false }: EnglishLevelProps) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const englishLevelsList = useMemo(() => {
    return ENGLISH_LEVEL;
  }, []);

  return (
    <Container data-testid="english-level">
      <Typography variant="h3" color="darkBLue">
        Nível de inglês
      </Typography>
      <Content>
        {englishLevelsList.map((englishLevel) => (
          <RadioButton
            disabled={viewOnly}
            key={englishLevel?.value}
            checked={values?.englishLevel === englishLevel?.value}
            onChange={() => setFieldValue('englishLevel', englishLevel?.value)}
            value={englishLevel?.value}
            label={englishLevel?.label}
          />
        ))}
      </Content>
    </Container>
  );
};

export { EnglishLevel };
