import { TabButtonStatus } from 'components/TabButtonStatus';

interface TabsLinkProfessionalProps {
  onChange: (tab: number) => void;
  children: React.ReactNode;
}

const TabsLinkProfessional = ({
  onChange,
  children,
}: TabsLinkProfessionalProps) => {
  const statusTabs = [
    {
      tabId: 0,
      title: 'Automaticamente',
    },
    {
      tabId: 1,
      title: 'Manualmente',
    },
  ];

  return (
    <>
      <div className="flex flex-row gap-6 w-full">
        <TabButtonStatus data={statusTabs} onClick={onChange} />
      </div>
      {children}
    </>
  );
};

export { TabsLinkProfessional };
