import { Container, ImageContainer } from './styles';
import { greenTec } from 'assets/images/icons';

export type SizeType = 'medium' | 'small' | 'large';

interface IconProps {
  name?: string;
  size?: SizeType;
  selected: boolean;
  color: string;
  outline: boolean;
}

const Icon = ({
  name = greenTec,
  color,
  size = 'small',
  outline,
  selected,
}: IconProps) => {
  return (
    <Container color={color} size={size} outline={outline} selected={selected}>
      <ImageContainer color={color}>
        <img src={name} alt="Ícone personalizado" />
      </ImageContainer>
    </Container>
  );
};

export { Icon };
