import { IconButton } from 'components/IconButton';
import { closeHover, edit } from 'assets/images/icons';
import { formatCurrency } from 'utils/formatCurrency';
import { ServiceTypeTypes } from 'features/Company/types';

interface ServiceAddedListProps {
  services: ServiceTypeTypes[];
  onEdit?: (serviceID: string, id: string) => void;
  onDelete?: (serviceID: string, id: string) => void;
}

const ServiceAddedList = ({
  services,
  onEdit,
  onDelete,
}: ServiceAddedListProps) => {
  return (
    <div className="flex flex-col mt-4 space-y-2">
      {services?.map((s) => {
        return (
          <div
            className="flex items-center w-full space-x-4"
            key={s.service.id}
          >
            <div className="flex flex-col w-full space-y-4 border-2 border-gray rounded-xl">
              <div className="flex justify-between w-full px-4 py-3">
                <div className="flex flex-col justify-center w-1/5">
                  <p className="text-xs font-bold text-darkerGray">
                    Tipo de serviço
                  </p>
                  <p>{s.service.name}</p>
                </div>
                <div className="flex flex-col items-center self-end justify-center w-3/5 gap-3">
                  {s.formsOfService.includes('HOUR') && (
                    <div className="flex self-start flex-wrap gap-3 w-full xl:gap-x-0">
                      <div className="w-1/4 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Cobrança
                        </p>
                        <p>Por hora</p>
                      </div>
                      <div className="w-1/5 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Preço/hora
                        </p>
                        <p>{formatCurrency(s.costPerHour)}</p>
                      </div>
                      <div className="w-1/5 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Custo/hora
                        </p>
                        <p>{formatCurrency(s.costPerTechnicianHour)}</p>
                      </div>
                      <div className="w-1/5 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Tempo previsto
                        </p>
                        <p>
                          {s.expectedTime} hora{s.expectedTime > 1 && `s`}
                        </p>
                      </div>
                      <div className="w-1/4 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Preço final
                        </p>
                        <p>{formatCurrency(s.costPerHour * s.expectedTime)}</p>
                      </div>
                      <div className="w-1/5 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Custo final
                        </p>
                        <p>
                          {formatCurrency(
                            s.costPerTechnicianHour * s.expectedTime
                          )}
                        </p>
                      </div>
                      <div className="w-1/5 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Hora extra
                        </p>
                        <p>{formatCurrency(s.costPerExtraHour)}</p>
                      </div>
                    </div>
                  )}
                  {s.formsOfService.includes('PER_CALL') && (
                    <div className="flex self-start flex-wrap gap-3 w-full xl:gap-0">
                      <div className="w-1/4 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Cobrança{' '}
                        </p>
                        <p>Por chamado</p>
                      </div>
                      <div className="w-1/5 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Preço/chamado
                        </p>
                        <p>{formatCurrency(s.costPerCall)}</p>
                      </div>
                      <div className="w-1/5 xl:w-1/5">
                        <p className="text-xs font-bold text-darkerGray">
                          Custo/chamado
                        </p>
                        <p>{formatCurrency(s.costPerTechnicianCall)}</p>
                      </div>
                      <div className="w-1/5 xl:w-1/5"></div>
                      <div className="w-1/4 xl:w-1/5"></div>
                      <div className="w-1/5 xl:w-1/5"></div>
                      <div className="w-1/5 xl:w-1/5"></div>
                    </div>
                  )}
                </div>
                {onEdit && (
                  <div className="flex flex-col justify-center">
                    <IconButton
                      icon={edit}
                      size="small"
                      filled
                      type="button"
                      onClick={() => onEdit(s.service.id, s.id)}
                    >
                      Editar
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
            {onDelete && (
              <button
                onClick={() => onDelete(s.id, s.service.id)}
                className="flex items-center justify-center text-white rounded focus:outline-none bg-orange focus:bg-orangeClick hover:bg-orangeHover text-xs h-[16px] w-[16px]"
                type="button"
              >
                <img
                  src={closeHover}
                  alt="Icone fechar"
                  width="100"
                  height="100"
                />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export { ServiceAddedList };
