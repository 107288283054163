import styled, { css } from 'styled-components';
import type { StatusButtonProps } from '.';

interface IconProps{
  selected: boolean
}

export const Button = styled.button<StatusButtonProps>`
  background-color: ${({ selected, theme }) =>
    selected ? 'rgba(128, 107, 255, 0.1)' : theme.colors.white};
  height: ${({ container }) => (container ? 'auto' : '48px')};
  ${({ container }) =>
    container &&
    css`
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      justify-items: center;
    `};
  min-width: ${({ block }) => (block ? '100%' : '240px')};
  padding: 12px 30px;
  font-size: 90%;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkestGray};
  border-radius: 8px;
  border: 2px solid
    ${({selected, theme, color}) =>
    selected
      ? theme.colors[color] || theme.colors.purple
      : theme.colors.lightGray};
  ${({ disabled, theme, color }) =>
    !disabled
      ? css`
          transition: 0.2s;
          &:hover {
            color: ${theme.colors[color]};
            border: 2px solid ${theme.colors[color]};
          }
          &:active {
            transform: scale(0.9);
          }
          &:active,
          &:focus {
            outline: 0;
          }
        `
      : css`
          cursor: not-allowed;
        `}
`;

export const Icon = styled.div<IconProps>`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background-color: ${({ selected, theme, color }) =>
    selected
      ? theme.colors[color] || theme.colors.purple
      : theme.colors.lightGray};
  margin-right: 8px;
  border: 3px solid
    ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.lightGray};
  border-radius: 4px;
`;
