/* eslint-disable @next/next/no-img-element */
import { Typography } from 'components/Typography';
import React, { useMemo, useState } from 'react';
import { CardUserRolesPermissions } from '../CardUserRolesPermissions';
import { StatusButton } from 'components/StatusButton';
import {
  StatusButtonContainer,
  Container,
  ItemDetailText,
  Content,
  Title,
} from './styles';
import {
  adminRolePermission,
  financialRolePermission,
  operationRolePermission,
} from 'data/rolesPermission';
import {
  adminClientRolePermission,
  financialClientRolePermission,
  operationClientRolePermission
} from 'data/rolesClientPermission'
import { checkGreen, closeRed, inactiveInfo } from 'assets/images/icons';
import useUser from 'hooks/useUser';

export interface PermissionDetailItem {
  title: string;
  type: string;
}

interface AccessButtonItemProps {
  title: string;
  description: string;
  items: PermissionDetailItem[];
  selected: boolean;
  onClick(): void;
  type: 'admin' | 'operation' | 'financial';
  isVisualization?: boolean;
}

const AccessButtonItem = ({
  title,
  description,
  items,
  selected,
  onClick,
  type,
  isVisualization = false,
}: AccessButtonItemProps) => {
  const [showInfo, setShowInfo] = useState(false)
  const { type: typeUser } = useUser()

  const rolePermissions = useMemo(() => {
    if (typeUser === 'EuNerd') {
      const permissions = {
        admin: adminRolePermission,
        operation: operationRolePermission,
        financial: financialRolePermission,
      };
      return permissions[type];
    } else {
      const permissions = {
        admin: adminClientRolePermission,
        operation: operationClientRolePermission,
        financial: financialClientRolePermission,
      };
      return permissions[type];
    }
  }, [type, typeUser]);

  return (
    <StatusButtonContainer data-testid="access-button-item">
      <StatusButton
        selected={selected}
        onClick={() => onClick()}
        container
        block
        disabled={isVisualization}
      >
        <Container>
          <Title>
            <Typography variant="h3" color="darkBlue">
              {title}
            </Typography>
            <img
              src={inactiveInfo}
              onMouseEnter={() => setShowInfo(true)}
              alt="Inactive Info"
            />
          </Title>
          <Typography variant="button" color="darkestGray">
            {description}
          </Typography>
          <Content>
            {items.map((item) => (
              <ItemDetailText key={item.title}>
                <img
                  src={item.type === 'available' ? checkGreen : closeRed}
                  alt="Permission Type"
                />
                <Typography variant="p1" color="darkestGray">
                  {item.title}
                </Typography>
              </ItemDetailText>
            ))}
          </Content>
        </Container>
      </StatusButton>
      {showInfo && (
        <CardUserRolesPermissions
          title={title}
          handleClose={() => setShowInfo(false)}
          items={rolePermissions}
        />
      )}
    </StatusButtonContainer>
  );
};

export { AccessButtonItem };
