import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useAllCompanies, useCompany } from 'queries/clients';
import { Typography } from 'components/Typography';
import useUser from 'hooks/useUser';
import { FormAddressService } from './FormAddressService';
import { FormResponsibleForService } from './FormResponsibleForService';
import { useTemplateCall } from 'queries/templateCall';
import { TemplateCallService } from 'services/templateCall';
import { TemplateDropdownSearch } from '../TemplateDropdownSearch';
import { ClientDropdownSearch } from '../ClientDropdownSearch';
import { ClientTypeSelect } from '../ClientTypeSelect';
import { AdressesDropdownSearch } from './AdressesDropdownSearch';
import { useAddressCompanyById } from 'features/Company/hooks';
import { GetAddressCompanyByAddressId } from 'features/Company/services';

const ClientInformation = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setFieldValue, values, setValues } = useFormikContext<any>();
  const user = useUser();
  const allCompanies = useAllCompanies({ status: 'true' });
  const ownCompany = useCompany(user.companyId);
  const { data: templates } = useTemplateCall();
  const address = useAddressCompanyById(values.companyId);

  function resetClientDependentFields() {
    setFieldValue('responsibleName', '');
    setFieldValue('responsibleTelephone', '');
    setFieldValue('responsibleEmail', '');
    setFieldValue('address', {
      cep: '',
      city: '',
      state: '',
      address: '',
      number: '',
      complement: '',
      district: '',
    });
    setFieldValue('registeredAddress', null);
    setFieldValue('type', '');
    setFieldValue('serviceType', '');
    setFieldValue('serviceId', '');
    setFieldValue('technicianId', []);
  }

  useEffect(() => {
    if (user.type === 'Client') {
      setFieldValue('companyId', user.companyId);
      setFieldValue('companyName', user.companyName);
    }
    resetClientDependentFields();
  }, [user.type]);

  const onChangeClient = useCallback((data) => {
    const { id, name } = data;
    setValues((values) => ({
      ...values,
      companyId: id,
      companyName: name,
    }));

    resetClientDependentFields();
  }, []);

  const handleChangeTemplate = async (id) => {
    const response = await TemplateCallService.findById(id);

    const {
      attachments,
      checklistItems,
      type,
      serviceId,
      serviceType,
      serviceDetail,
    } = response.data;

    const checklistTech = checklistItems?.map((item, index) => {
      return { id: index + 1, value: item?.description };
    });

    setValues((values) => ({
      ...values,
      serviceDetail: serviceDetail,
      attachments: attachments,
      checklist: checklistTech,
      type: type,
      serviceId: serviceId,
      serviceType: serviceType,
    }));
  };

  async function handleChangeAddress(addressId) {
    const response = await GetAddressCompanyByAddressId(addressId);

    const { address, cep, city, state, number, complement, district } =
      response.data;

    setValues((values) => ({
      ...values,
      address: {
        cep: cep,
        city: city,
        state: state,
        address: address,
        number: number,
        complement: complement,
        district: district,
      },
    }));
  }

  return (
    <div>
      <div className="p-6 space-y-6 border-2 rounded-lg border-lightGray">
        <div>
          <Typography variant="h2" color="darkestGray">
            Informações sobre o cliente
          </Typography>
          <Typography variant="p1" color="darkerGray">
            Selecione o tipo de cliente: Empresa ou Filial. Defina o endereço de
            atendimento e o responsável no local por validar o chamado.
          </Typography>
        </div>

        <div className="flex flex-row items-center w-full gap-6">
          <ClientTypeSelect
            onChange={(value) => setFieldValue('clientType', value)}
            value={values.clientType}
            disabled={user.type === 'Client'}
          />
          <ClientDropdownSearch
            companies={
              user.type === 'Client'
                ? [ownCompany.company]
                : allCompanies?.companies || []
            }
            disabled={user.type === 'Client'}
            value={values?.companyName}
            onChange={(data) => onChangeClient(data)}
            onRemove={() => {
              setFieldValue('companyId', '');
              setFieldValue('companyName', '');
              resetClientDependentFields();
            }}
          />
          <TemplateDropdownSearch
            templates={templates || []}
            onChange={({ id }) => handleChangeTemplate(id)}
            disabled={!values.companyId}
          />
        </div>

        {address?.address.length > 0 && (
          <AdressesDropdownSearch
            addresses={address.address || []}
            onChange={({ id }) => handleChangeAddress(id)}
            onRemove={() => {
              setFieldValue('address', {
                cep: '',
                city: '',
                state: '',
                address: '',
                number: '',
                complement: '',
                district: '',
              });
            }}
          />
        )}

        <FormAddressService titleformatAddress="Endereço para o atendimento" />
        <FormResponsibleForService companyIdSelected={values?.companyId} />
      </div>
    </div>
  );
};

export { ClientInformation };
