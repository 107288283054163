import { useCallback, useState } from 'react';
import type { TabUnderLineAndBadgesTypes } from '..';
import { ButtonUnderlineAndBadges } from '../../ButtonUnderlineAndBadges';

interface TabUnderlineAndBadgesProps {
  tabsData: TabUnderLineAndBadgesTypes[];
  onClick: (tab: number) => void;
  defaultSelected?: number;
}

const TabUnderlineAndBadges = ({
  tabsData,
  onClick,
  defaultSelected = 0,
}: TabUnderlineAndBadgesProps) => {
  const [tabSelected, setTabSelected] = useState(defaultSelected);

  const handleChange = useCallback(
    (tabId: number) => {
      setTabSelected(tabId);
      if (onClick) {
        onClick(tabId);
      }
    },
    [onClick]
  );

  return (
    <div className="flex flex-row gap-4">
      {tabsData?.map(({ tabId, name, count }) => {
        return (
          <ButtonUnderlineAndBadges
            key={tabId}
            name={name}
            current={tabSelected === tabId}
            count={count}
            onClick={() => handleChange(tabId)}
          />
        );
      })}
    </div>
  );
};

export { TabUnderlineAndBadges };
