import { useState, useCallback, useEffect } from 'react';
import { Typography } from 'components/Typography';
import { ProfessionalsContainer, ProfessionalsSelectedSection } from './styles';
import { ProfessionalLinkedCallItem } from '../ProfessionalLinkedCallItem';
import { useCall } from 'queries/call';
import {
  useTechnicianListUnavailableShedule,
  useTechnicianStatus,
} from 'queries/technician';
import { TabsUnderlineAndBadges } from 'components/TabsUnderlineAndBadges';
import { ListTechnicians } from './ListTechnicians';
import { SearchBarAutocomplete } from 'components/SearchBarAutocomplete';
import { useFormikContext } from 'formik';
import { CreateCallProps } from 'types/call';

interface TabProfessionalProps {
  callId: string;
}

const TabProfessional = ({ callId }: TabProfessionalProps) => {
  const [searchText, setSearchText] = useState('');
  const [professionalsSelected, setProfessionalsSelected] = useState([]);
  const [numTab, setNumTab] = useState(0);
  const { setFieldValue } = useFormikContext<CreateCallProps>();
  const { call } = useCall(callId);

  const onAddProfessional = useCallback((professional) => {
    setProfessionalsSelected([professional]);
  }, []);

  const onRemoveProfessional = useCallback(
    (professionalId) => {
      const newProfessionals = professionalsSelected.filter(
        (item) => item.id !== professionalId
      );
      setProfessionalsSelected(newProfessionals);
    },
    [professionalsSelected]
  );

  useEffect(() => {
    if (professionalsSelected.length > 0) {
      setFieldValue('technicianId', [professionalsSelected[0]?.id]);
    } else {
      setFieldValue('technicianId', []);
    }
  }, [professionalsSelected, setFieldValue]);

  const filtersCall = {
    status: ['AVAILABLE'],
    services: [call?.serviceId],
    regions: [call?.address?.city],
    state: [call?.address?.state],
    startTime: [call?.startTime],
    serviceType: [call?.serviceType],
    ...(call?.type === 'Urgent' && { urgentCalls: true }),
  };

  const filtersOtherCall = {
    status: ['AVAILABLE'],
    regions: [call?.address?.city],
    state: [call?.address?.state],
    startTime: [call?.startTime],
    serviceType: [call?.serviceType],
    ...(call?.type === 'Urgent' && { urgentCalls: true }),
  };

  const { technicians } = useTechnicianStatus({
    filters: filtersCall,
    enabled: true,
  });
  const { techniciansUnavailable } = useTechnicianListUnavailableShedule({
    filters: filtersCall,
    enabled: true,
  });

  const { technicians: techniciansOther } = useTechnicianStatus({
    filters: filtersOtherCall,
    enabled: true,
  });

  const uniqueOtherTechnicians = techniciansOther?.technicians?.filter(
    (otherTech) =>
      !technicians?.technicians?.some((tech) => tech.id === otherTech.id)
  );

  const tabsData = [
    {
      tabId: 0,
      name: 'Disponíveis',
      count: technicians?.technicians?.length,
    },
    {
      tabId: 1,
      name: 'Indisponíveis',
      count: techniciansUnavailable?.technicians?.length,
    },
    {
      tabId: 2,
      name: 'Outros',
      count: uniqueOtherTechnicians?.length,
    },
  ];

  function renderTabSelected() {
    const tabs = {
      0: (
        <ListTechnicians
          dataTechnicians={technicians?.technicians}
          onAddProfessional={onAddProfessional}
          onRemoveProfessional={onRemoveProfessional}
          professionalsSelected={professionalsSelected}
          searchText={searchText}
        />
      ),
      1: (
        <ListTechnicians
          dataTechnicians={techniciansUnavailable?.technicians}
          onAddProfessional={onAddProfessional}
          onRemoveProfessional={onRemoveProfessional}
          professionalsSelected={professionalsSelected}
          searchText={searchText}
          isDisabled
        />
      ),
      2: (
        <ListTechnicians
          dataTechnicians={uniqueOtherTechnicians}
          onAddProfessional={onAddProfessional}
          onRemoveProfessional={onRemoveProfessional}
          professionalsSelected={professionalsSelected}
          searchText={searchText}
        />
      ),
    };
    return tabs[numTab] || tabs[0];
  }

  return (
    <div className="flex flex-col space-y-6">
      <Typography variant="h3" color="darkestGray">
        Vincular profissional manualmente
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Os técnicos apresentados passaram por um filtro de localidade e
        capacitação para que todos sejam compatíveis e aptos para atender o
        chamado. Caso deseje um técnico específico que está indisponível, é
        possível atualizar a data do atendimento de acordo com a agenda do
        profissional.
      </Typography>
      <SearchBarAutocomplete
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder="Buscar profissional"
      />
      <TabsUnderlineAndBadges
        tabsData={tabsData}
        onChange={(tab) => {
          setNumTab(tab);
        }}
      >
        {renderTabSelected()}
      </TabsUnderlineAndBadges>
      {professionalsSelected.length >= 1 && (
        <ProfessionalsSelectedSection>
          <Typography variant="h4" color="darkestGray">
            Profissional vinculado ao chamado
          </Typography>
          <ProfessionalsContainer>
            {professionalsSelected.map((item) => (
              <ProfessionalLinkedCallItem
                key={item.id}
                professional={item}
                onRemoveProfessional={onRemoveProfessional}
              />
            ))}
          </ProfessionalsContainer>
        </ProfessionalsSelectedSection>
      )}
    </div>
  );
};

export { TabProfessional };
