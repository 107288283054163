import { Typography } from 'components/Typography';
interface CurrencyValueProps {
  value: number | string;
}

const CurrencyValue = ({ value }: CurrencyValueProps) => {
  return (
    <Typography variant="h1" color="black">
      <Typography variant="button" color="darkGray">
        R$
      </Typography>
      {value}
    </Typography>
  );
};

export { CurrencyValue };
