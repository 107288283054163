import clsx from 'clsx';
import { IconType } from 'react-icons';

interface PaginationButtonProps {
  Icon?: IconType;
  pageNumber?: number;
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const PaginationButton = ({
  Icon,
  type = 'button',
  active,
  disabled,
  onClick,
  pageNumber,
}: PaginationButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'relative inline-flex px-4 py-2 text-sm font-medium focus:outline-none',
        { 'pointer-events-none': disabled }
      )}
    >
      {Icon && (
        <Icon className="hover:font-bold hover:text-purpleHover text-darkGray" />
      )}
      <p
        className={clsx(
          'text-sm hover:font-bold hover:text-purpleHover text-darkGray',
          {
            'text-purple font-bold': active,
          }
        )}
      >
        {pageNumber}
      </p>
    </button>
  );
};

export { PaginationButton };
