import { Typography } from 'components/Typography';
import { format, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const NotificationDate = ({ date }) => {
  return (
    <Typography color="darkestGray" variant="overline">
      {formatDistance(new Date(date), new Date(), {
        addSuffix: true,
        locale: ptBR,
      })}{' '}
      -{' '}
      {format(new Date(date), 'HH:mm', {
        locale: ptBR,
      })}
    </Typography>
  );
};

export { NotificationDate };
