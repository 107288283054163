import { useCall } from 'queries/call';
import Notification from './Notification';
import { v4 } from 'uuid';
import { NoAlerts } from 'components/NoAlerts';
import { useState } from 'react';
import Spinner from 'components/Spinner';
import { Pagination, usePaginationData } from 'components/Pagination';

const AlertsTab = ({ callId }) => {
  const { call, refetch, isLoading } = useCall(callId);
  const [currentPage, setCurrentPage] = useState(1);

  const dataPagination = usePaginationData({
    objectData: call.notifications,
    currentPage,
  });

  return (
    <div className="flex flex-col p-4 space-y-4 divide-y-2 divide-lightGray">
      {isLoading ? (
        <Spinner />
      ) : call.notifications?.length > 0 ? (
        <>
          {dataPagination.map((n) => {
            return (
              <Notification
                key={v4()}
                title={n.title}
                description={n.description}
                time={n.createdAt}
                actions={n.possibleActions}
                callId={call.id}
                modelId={call.callToTechnician[0]?.technicianId}
                companyId={call.companyId}
                refetch={refetch}
              />
            );
          })}
          <Pagination
            currentPage={currentPage}
            totalCount={call.notifications?.length}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      ) : (
        <NoAlerts type="size" handleAction={() => null} />
      )}
    </div>
  );
};

export default AlertsTab;
