import { useState } from 'react';
import { ModalTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { useTechnician } from 'queries/technician';
import { useQueryClient } from 'react-query';
import { Technicians } from 'services/technician';
import { Button } from 'components/Button';
import { call } from 'assets/images/illustrations';

const FooterSidesheetTechnician = ({ id, onAfterUpdate }) => {
  const UI = useUI();
  const queryClient = useQueryClient();

  const { technician, refetch } = useTechnician(id);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [loadingButtonTech, setLoadingButtonTech] = useState(false);

  const INACTIVE_STATUS = technician?.status === 'INACTIVE';

  async function deactivateTechnician() {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        title: 'Desativar Técnico',
        description: 'Tem certeza que deseja desativar este técnico?',
        cancelText: 'Não',
        confirmText: 'Sim, desativar técnico',
        icon: <img src={call} />,
        cancelRed: true,
        onConfirm: async () => {
          try {
            setLoadingButtonTech(true);
            await Technicians.deactivate(id);

            await refetch();
            queryClient.refetchQueries(technician);

            UI.operations.showToast('Técnico desativado com sucesso.');
            await onAfterUpdate?.();
          } catch (err) {
            UI.operations.showToast('Erro ao desativar este técnico.', {
              type: 'error',
            });
          } finally {
            setLoadingButtonTech(false);
          }
        },
      },
    });
  }
  async function activateTechnician() {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        title: 'Ativar Técnico',
        description: 'Tem certeza que deseja ativar este técnico?',
        cancelText: 'Não',
        confirmText: 'Sim, ativar técnico',
        icon: <img src={call} />,
        cancelRed: true,
        onConfirm: async () => {
          try {
            setLoadingButtonTech(true);
            await Technicians.activate(id);

            await refetch();
            queryClient.refetchQueries(technician);

            UI.operations.showToast('Técnico ativado com sucesso.');
            await onAfterUpdate?.();
          } catch (err) {
            UI.operations.showToast('Erro ao ativar este técnico.', {
              type: 'error',
            });
          } finally {
            setLoadingButtonTech(false);
          }
        },
      },
    });
  }

  async function deleteTechnician() {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        title: 'Excluir técnico',
        description: (
          <>
            Tem certeza que deseja excluir este técnico?
            <br />
            <br />
            Você está excluindo os dados <strong>permanentemente.</strong>
            <br />
            Após a confirmação, as informações serão apagadas no sistema
          </>
        ),
        cancelText: 'Não',
        confirmText: 'Sim, excluir técnico',
        icon: <img src={call} />,
        cancelRed: true,
        onConfirm: async () => {
          try {
            setLoadingButtonDelete(true);
            await Technicians.delete(id);

            await refetch();
            queryClient.refetchQueries(technician);

            UI.operations.showToast('Técnico excluido com sucesso.');
          } catch (err) {
            UI.operations.showToast('Erro ao excluir este técnico.', {
              type: 'error',
            });
          } finally {
            setLoadingButtonDelete(false);
          }
        },
      },
    });
  }
  return (
    <div className="flex justify-between w-full px-8 py-5 border-t-2 justify-self-end bg-lighterGray border-gray">
      <div className="flex justify-end w-full">
        <div className="flex items-center justify-center space-x-4">
          {INACTIVE_STATUS && (
            <Button
              type="button"
              color="orange"
              size="large"
              onClick={deleteTechnician}
              loading={loadingButtonDelete}
              disabled={loadingButtonTech}
            >
              Excluir técnico
            </Button>
          )}
          <Button
            type="button"
            size="large"
            color={INACTIVE_STATUS ? 'purple' : 'purple'}
            onClick={
              INACTIVE_STATUS ? activateTechnician : deactivateTechnician
            }
            loading={loadingButtonTech}
            disabled={loadingButtonDelete}
          >
            {INACTIVE_STATUS ? 'Ativar técnico' : 'Desativar técnico'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { FooterSidesheetTechnician };
