import { useQuery } from 'react-query';
import { fetcher } from 'services/api';
import { Technicians } from 'services/technician';
import { buildSearchParams } from 'utils/queryUtils';

export function useTechnician(id: string) {
  const { data, error, refetch } = useQuery(
    ['technician', id],
    () => Technicians.get(id),
    {
      enabled: Boolean(id),
    }
  );

  return {
    technician: data || {},
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}

export function useTechnicianStatus({ filters, enabled = undefined }) {
  const { data, error, refetch } = useQuery(
    ['technician', filters],
    () => {
      return fetcher(`/technicians?${buildSearchParams(filters)}`);
    },
    { refetchInterval: 30000, enabled: Boolean(enabled) }
  );

  return {
    technicians: data || [],
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}

export function useTechnicianListUnavailableShedule({
  filters,
  enabled = undefined,
}) {
  const { data, error, refetch } = useQuery(
    ['technicianListUnavailableShedule', filters],
    () => {
      return fetcher(
        `/technicians/unavailable-schedule?${buildSearchParams(filters)}`
      );
    },
    {
      enabled: Boolean(enabled),
    }
  );

  return {
    techniciansUnavailable: data || [],
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}

export function useTechnicianCounter() {
  const { data, error, refetch } = useQuery(
    ['technician'],
    () => {
      return fetcher(`/technicians/counters`);
    },
    { refetchInterval: 30000 }
  );
  return {
    counters: data || [],
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}

function useTechnicianCalls(id, data) {
  const { data: technicianCalls } = useQuery(
    [`/technician/${id}/calls`, data],
    () => Technicians.getCalls(id, data)
  );

  return technicianCalls?.data || [];
}

export function useBanklist() {
  const { data } = useQuery(['/juno/banks'], () => Technicians.banksList());

  return {
    data: data || {},
  };
}

export { useTechnicianCalls };
