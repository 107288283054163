import { Typography } from 'components/Typography';
import { Container } from './styles';

interface ProfessionalInfoStatsItemProps {
  icon: string;
  text: string;
}

const ProfessionalInfoStatsItem = ({
  icon,
  text,
}: ProfessionalInfoStatsItemProps) => {
  return (
    <Container>
      <img src={icon} alt="icon" />
      <Typography variant="overline" color="darkestGray">
        {text}
      </Typography>
    </Container>
  );
};

export { ProfessionalInfoStatsItem };
