import { Typography } from 'components/Typography';
import { Button } from 'components/Button';
import { Container } from './styles';
import { useUI } from 'hooks/useUI';
import { SidesheetTypes } from 'ducks/ui/types';
import { useCallPermissions } from 'hooks/permissions/useCallPermissions';
import { callsNotFound } from 'assets/images/illustrations';

interface CallsNotFoundProps {
  viewButton?: boolean;
  mensage?: string;
}

const CallsNotFound = ({
  viewButton = true,
  mensage = 'Nenhum chamado encontrado.',
}: CallsNotFoundProps) => {
  const UI = useUI();
  const { canCreateCall } = useCallPermissions();
  return (
    <Container>
      <img className="mb-10" src={callsNotFound} />
      <Typography className="mb-10" variant="h2" color="darkBlue">
        {mensage}
      </Typography>

      {viewButton && canCreateCall && (
        <Button
          size="large"
          onClick={() => {
            UI.operations.sidesheet.openSidesheet({
              type: SidesheetTypes.SidesheetAddNewCall,
            });
          }}
        >
          Novo chamado
        </Button>
      )}
    </Container>
  );
};
export { CallsNotFound };
