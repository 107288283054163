import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

import { Subtitle } from './styles';

import { useCallback } from 'react';
import { confirm } from 'assets/images/illustrations';

export interface ConfirmCreateCollaboratorModalProps {
  close: () => void;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
}

const ConfirmCreateCollaborator = ({
  close,
  onConfirm,
  isLoading,
}: ConfirmCreateCollaboratorModalProps) => {
  const handleClick = useCallback(async () => {
    await onConfirm();
    close();
  }, []);

  return (
    <div className="flex flex-col items-center max-w-xl text-center">
      <img src={confirm} alt="icon confirm" />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Confirmar cadastro
      </Typography>
      <Subtitle variant="h4" color="darkBlue" className="mb-8">
        Você está cadastrando um novo colaborador no sistema. Após sua
        confirmação, as instruções para definição da senha serão enviadas ao
        e-mail informado.
      </Subtitle>
      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={close}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={() => handleClick()}
          disabled={isLoading}
        >
          Confirmar Cadastro
        </Button>
      </div>
    </div>
  );
};

export { ConfirmCreateCollaborator };
