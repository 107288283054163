import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Container, ExitButton, SidesheetBaseContainer } from './styles';
import { SidesheetBaseProps } from './types';
import { exitArrow } from 'assets/images/icons';

const SidesheetBase: React.FC<SidesheetBaseProps> = ({
  close = () => null,
  isOpen,
  children,
  onAfterClose,
  width = 'normal',
}) => {
  function closeSidesheet() {
    if (onAfterClose) {
      onAfterClose();
    }
    close();
  }

  return (
    <Transition show={isOpen} as={Fragment} appear>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 min-h-full overflow-hidden"
        open={isOpen}
        onClose={close}
      >
        <div className="absolute inset-0 min-h-full overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 z-0 transition-opacity bg-black bg-opacity-30" />
          </Transition.Child>

          <SidesheetBaseContainer className="fixed inset-y-0 right-0 z-20 flex min-h-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className="relative min-h-full"
                style={{ width: width === 'normal' ? 'auto' : '80vw' }}
              >
                <Transition.Child
                  as={Fragment}
                  enter={`ease-in-out ${
                    width === 'normal' ? 'duration-500' : 'duration-1000'
                  }`}
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave={`ease-in-out ${
                    width === 'normal' ? 'duration-500' : 'duration-1000'
                  }`}
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-14">
                    <ExitButton
                      onClick={closeSidesheet}
                      data-testid="exit-button"
                    >
                      <img src={exitArrow} />
                      <span className="sr-only">Close panel</span>
                    </ExitButton>
                  </div>
                </Transition.Child>
                <Container>{children}</Container>
              </div>
            </Transition.Child>
          </SidesheetBaseContainer>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SidesheetBase;
