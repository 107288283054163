import styled, { css } from 'styled-components';
import type { AvatarProps } from '.';

const getLength = ({ size }) => {
  switch (size) {
    case 'small':
      return '30px';
    case 'medium':
      return '60px';
    case 'large':
      return '90px';
  }
};

const getStatusColor = {
  REFUSED: css`
    background: ${({ theme }) => theme.colors.orangeClick};
  `,
  WAITING_FOR_ACTIVATION: css`
    background: ${({ theme }) => theme.colors.purple};
  `,
  WAITING_FOR_APPROVAL: css`
    background: ${({ theme }) => theme.colors.yellowClick};
  `,
  AVAILABLE: css`
    background: ${({ theme }) => theme.colors.greenClick};
  `,
  IN_SERVICE: css`
    background: ${({ theme }) => theme.colors.purple};
  `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  background: #f2f2f2;
  height: ${getLength};
  width: ${getLength};
  border-radius: 9999px;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
`;

export const Status = styled.div<AvatarProps>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  ${({ status }) => getStatusColor[status]}
  bottom: 0;
  right: 0;
`;
