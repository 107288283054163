import { Typography } from 'components/Typography';
import { addDays, format, startOfWeek, subDays } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { toFirstLetterUppercase } from 'utils/formatWord';
import { ptBR } from 'date-fns/locale';
import { WEEKDAYS_SHORT_CALENDAR } from 'utils/dateUtils';
import { arrowLeftBlack, arrowRightBlack } from 'assets/images/icons';
import { ProfessionalSchedule } from '../ProfessionalCard';
import {
  IProfessionalWeeklyScheduleAppointment,
  ProfessionalWeeklyScheduleDetail,
} from '../ProfessionalWeeklyScheduleDetail';
import { Container, Header, WeekDaysHeader } from './styles';

interface ProfessionalWeeklyScheduleProps {
  schedule: ProfessionalSchedule[];
}

const ProfessionalWeeklySchedule = ({
  schedule,
}: ProfessionalWeeklyScheduleProps) => {
  const [weekDays, setWeekDays] = useState<Date[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [appointments, setAppointments] = useState<
    IProfessionalWeeklyScheduleAppointment[]
  >([]);
  const [currentMonth, setCurrentMonth] = useState(
    format(new Date(), 'LLLL', { locale: ptBR })
  );

  const onChangeWeek = useCallback(
    (type: 'next' | 'previous') => {
      const newWeekDate =
        type === 'next' ? addDays(currentDate, 7) : subDays(currentDate, 7);
      setCurrentDate(newWeekDate);
      const start = startOfWeek(newWeekDate);
      const currentDateMonthWeek = format(newWeekDate, 'LLLL', {
        locale: ptBR,
      });

      if (currentMonth !== currentDateMonthWeek) {
        setCurrentMonth(currentDateMonthWeek);
      }

      const days = Array.from({ length: 7 }, (_, i) => addDays(start, i));

      setWeekDays(days);
    },
    [currentDate, currentMonth]
  );

  const onChangeSelectedDate = useCallback(
    (date: Date) => {
      const daySelected = format(date, 'yyyy-MM-dd');
      const appointmentsList = [];

      schedule?.forEach((item) => {
        if (daySelected == format(new Date(item.startCall), 'yyyy-MM-dd')) {
          appointmentsList.push({
            id: item.id,
            client: 'Cliente',
            date: new Date(item.startCall),
            service: 'Serviço',
            status: 'available',
            timeEnd: format(new Date(item.endCall), "HH'h'mm"),
            timeStart: format(new Date(item.startCall), "HH'h'mm"),
            type: 'primary',
          });
        }
      });
      setAppointments(appointmentsList);

      setSelectedDate(date);
    },
    [schedule]
  );

  useEffect(() => {
    const start = startOfWeek(Date.now());
    const days = Array.from({ length: 7 }, (_, i) => addDays(start, i + 1));
    setWeekDays(days);
  }, []);

  useEffect(() => {
    onChangeSelectedDate(new Date());
  }, [onChangeSelectedDate]);

  return (
    <Container>
      <Typography variant="p1" color="darkestGray">
        Agenda da Semana
      </Typography>
      <WeekDaysHeader>
        {WEEKDAYS_SHORT_CALENDAR.map((day) => (
          <Typography
            key={day}
            variant="button"
            color="darkerGray"
            data-testid="professional-weekly-schedule-day"
          >
            {day.charAt(0)}
          </Typography>
        ))}
      </WeekDaysHeader>
      <Header>
        <div className="flex gap-6">
          <Typography variant="button" color="darkerGray">
            {toFirstLetterUppercase(currentMonth)}
          </Typography>
          <Typography variant="button" color="darkerGray">
            {String(weekDays[0]?.getDate()).padStart(2, '0')} -{' '}
            {String(weekDays[weekDays.length - 1]?.getDate()).padStart(2, '0')}
          </Typography>
        </div>
        <div className="flex gap-5">
          <img
            src={arrowLeftBlack}
            onClick={() => onChangeWeek('previous')}
            alt="icon arrow left"
          />
          <img
            src={arrowRightBlack}
            onClick={() => onChangeWeek('next')}
            alt="icon arrow right"
          />
        </div>
      </Header>
      {/* <Content>
        {weekDays?.map((date) => (
          <DayButton
            type="button"
            hasAppointments={
              schedule?.length > 0 &&
              Boolean(
                schedule?.find((s) => {
                  const startCall = new Date(s.startCall);

                  return (
                    startCall.getDate() === date.getDate() &&
                    startCall.getMonth() === date.getMonth()
                  );
                })
              )
            }
            key={date.getDate()}
            onClick={() => onChangeSelectedDate(date)}
            selected={
              format(date, 'd/M/yyyy') === format(selectedDate, 'd/M/yyyy')
            }
          >
            <Typography variant="button" color="darkGray">
              {date.getDate()}
            </Typography>
          </DayButton>
        ))}
      </Content> */}
      {selectedDate && (
        <ProfessionalWeeklyScheduleDetail
          date={selectedDate}
          appointments={appointments}
          // appointments={[
          //   {
          //     date: selectedDate,
          //     timeStart: '9h00',
          //     timeEnd: '12h00',
          //     client: 'Positivo Tecnologia',
          //     service: 'Cabeamento e conectividade',
          //     status: 'available',
          //     type: 'primary'
          //   },
          //   {
          //     date: selectedDate,
          //     timeStart: '15h00',
          //     timeEnd: '17h00',
          //     client: 'Positivo Tecnologia',
          //     service: 'Cabeamento e conectividade',
          //     status: 'available',
          //     type: 'warning'
          //   }
          // ]}
        />
      )}
    </Container>
  );
};

export { ProfessionalWeeklySchedule };
