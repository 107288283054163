import { useState } from 'react';
import _ from 'lodash';
import { Company } from 'services/company';
import { useUI } from 'hooks/useUI';
import { ModalTypes } from 'ducks/ui/types';
import { currencyToFloat } from 'utils/formatUtils';
import {
  FormCompanyData,
  FormCompanyPayment,
  FormReviewCompany,
  FormTypesOfServiceCompany,
} from 'features/Company/components';
import { MultiStepForm } from './MultiStepForm';
import { FormStep } from './FormStep';
import {
  FormCompanyDataSchema,
  FormCompanyPaymentSchema,
  FormTypesOfServiceCompanySchema,
} from './utils/validations';
import { initialValues } from './utils/initialValues';

export interface SidesheetNewCompanyProps {
  onCreate?: () => void;
  close: () => void;
}

const SidesheetNewCompany = ({ onCreate, close }: SidesheetNewCompanyProps) => {
  const [stepsDone, setDoneSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const UI = useUI();

  const handleSubmit = async (values) => {
    UI.operations.modal.openModal({
      type: ModalTypes.ConfirmCompany,
      modalProps: {
        email: values.responsible.email,
        onConfirm: async () => {
          const payload = { ...values };
          payload.collaborator = payload.responsible;
          payload.contract.end = new Date(payload.contract.end).toISOString();
          payload.contract.start = new Date(
            payload.contract.start
          ).toISOString();
          payload.contract.amount = currencyToFloat(payload.contract.amount);
          payload.sla.extraCostPerKM = currencyToFloat(
            payload.sla.extraCostPerKM
          );
          payload.sla.kmIncluded;
          payload.sla.monthlyPlatformCost = currencyToFloat(
            payload.sla.monthlyPlatformCost
          );
          payload.serviceType = payload.serviceType.map((m) => {
            return {
              ..._.omit(m, 'service'),
              name: m.service.name,
              description: m.service.description,
              serviceId: m.service.id,
            };
          });
          try {
            setLoading(true);
            await Company.create(payload);
            close();
            UI.operations.showToast(
              `Cliente ${payload.fantasyName} cadastrado com sucesso!`,
              {
                type: 'success',
              }
            );
          } catch (err) {
            for (const message of err.response.data.error.message) {
              UI.operations.showToast(message, {
                type: 'error',
              });
            }
          } finally {
            setLoading(false);
            if (onCreate) {
              onCreate();
            }
          }
        },
      },
    });
  };

  return (
    <div
      className="flex flex-col max-h-screen min-h-screen"
      data-testid="sidesheet-new-company"
    >
      <MultiStepForm
        stepsDone={stepsDone}
        setDoneSteps={setDoneSteps}
        loading={loading}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <FormStep validationSchema={FormCompanyDataSchema}>
          <FormCompanyData />
        </FormStep>

        <FormStep validationSchema={FormCompanyPaymentSchema}>
          <FormCompanyPayment />
        </FormStep>

        <FormStep validationSchema={FormTypesOfServiceCompanySchema}>
          <FormTypesOfServiceCompany />
        </FormStep>

        <FormStep>
          <FormReviewCompany />
        </FormStep>
      </MultiStepForm>
    </div>
  );
};
export { SidesheetNewCompany };
