import styled, { css } from 'styled-components';

interface CardUserRolesPermissionsProps {
  title: string;
}

export const Container = styled.div<CardUserRolesPermissionsProps>`
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background: ${({ theme }) => theme.colors.lightGray};
  width: 343px;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  position: absolute;
  top: ${({ title }) => title !== 'Financeiro' && css`calc(100% + 14px)`};
  left: 0;
  z-index: 10;
  animation: 0.3s animationCard;
  transition: 0.3s;

  @keyframes animationCard {
    0% {
      transform: translateY(20%);
    }
  }

  @media (max-width: 1440px) {
    width: 500px;
  }

  @media (max-width: 1366px) {
    width: 480px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ItemDetailText = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
