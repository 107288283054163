import useSWR from 'swr';
import { callStatus } from 'lib/call';
import api, { fetcher } from 'services/api';
import { buildSearchParams } from 'utils/queryUtils';
import { CreateCallProps } from 'types/call';

export const Call = {
  getAll: (filters) => useSWR(`/calls?${buildSearchParams(filters)}`, fetcher),
  get: async (id) => {
    const call = await api.get('/calls/' + id);
    return call.data;
  },
  create: (payload: CreateCallProps) => api.post('/calls', payload),
  cancel: async (id, params) => {
    const call = await api.post('/calls/cancel-call/' + id, params);
    return call.data;
  },
  updateTechnicians: (id, payload) => api.patch(`/calls/${id}`, payload),
  finishReview: async (
    id,
    params: {
      aditionalCostIsConfirmed: boolean;
      isUnproductive?: boolean;
      // Umproductive: typo no backend
      errorTypeUnproductiveCall?: 'CLIENT' | 'PROFESSIONAL';
    }
  ) => {
    const call = await api.post('/calls/callReview/' + id, params);
    return call.data;
  },
  endCallClient: async (id: string, params) => {
    const call = await api.post('/calls/end-call/client/' + id, params);
    return call.data;
  },
  toggleChecklistItem: async (id: string) => {
    const response = await api.post(`/toggle-checklist-item/${id}`);
    return response.data;
  },
};

export const MockedCall = {
  getAll: ({ status }) => {
    switch (status) {
      case callStatus.CALL_SUCCESSFULLY_ENDED.value:
        return {
          data: {
            calls: [
              {
                datetime: '2021-05-27T03:00:00',
                companyName: 'Positivo Tecnologia',
                clientLogo: '',
                revenue: '$150,00',
                service: 'Cabeamento e conectividade',
              },
              {
                datetime: '2021-05-27T03:00:00',
                companyName: 'Ipiranga',
                clientLogo: '',
                revenue: '$75,00',
                service: 'Cabeamento e conectividade',
              },
              {
                datetime: '2021-05-29T03:00:00',
                companyName: 'Padaria Bom Dia',
                clientLogo: '',
                revenue: '$200,00',
                service: 'Cabeamento e conectividade',
              },
            ],
          },
        };
      case callStatus.PENDING_COMPLETION.value:
        return {
          data: {
            calls: [
              {
                datetime: '2021-05-31T03:00:00',
                companyName: 'Positivo Tecnologia',
                clientLogo: '',
                revenue: '$100,00',
                service: 'Cabeamento e conectividade',
              },
            ],
          },
        };
      case callStatus.SUPPORT_CANCELED.value:
        return {
          data: {
            calls: [
              {
                datetime: '2021-06-01T03:00:00',
                companyName: 'Positivo Tecnologia',
                clientLogo: '',
                revenue: '$100,00',
                service: 'Cabeamento e conectividade',
              },
            ],
          },
        };
      default:
        return {
          data: {
            calls: [],
          },
        };
    }
  },
  getByTechnician: ({ technicianId, status }) => {
    switch (status) {
      case undefined:
        return {
          data: {
            calls: [
              {
                datetime: '2021-06-31',
              },
              {
                datetime: '2020-01-31',
              },
            ],
          },
        };
      default:
        return {
          data: {
            calls: [
              {
                datetime: '2021-05-27T03:00:00',
                companyName: 'Positivo Tecnologia',
                clientLogo: '',
                revenue: '$150,00',
                service: 'Cabeamento e conectividade',
              },
              {
                datetime: '2021-05-27T03:00:00',
                companyName: 'Ipiranga',
                clientLogo: '',
                revenue: '$75,00',
                service: 'Cabeamento e conectividade',
              },
              {
                datetime: '2021-05-29T03:00:00',
                companyName: 'Padaria Bom Dia',
                clientLogo: '',
                revenue: '$200,00',
                service: 'Cabeamento e conectividade',
              },
            ],
          },
        };
      case callStatus.PENDING_COMPLETION.value:
        return {
          data: {
            calls: [
              {
                datetime: '2021-05-31T03:00:00',
                companyName: 'Positivo Tecnologia',
                clientLogo: '',
                revenue: '$100,00',
                service: 'Cabeamento e conectividade',
              },
            ],
          },
        };
    }
  },
};
