import * as Yup from 'yup';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from 'components/Button';
import { InputField } from 'components/InputField';
import { useServices } from 'queries/clients';
import { yupValidations } from 'utils/validations/yup';
import { currencyMask, currencyToFloat } from 'utils/formatUtils';
import { MultiValueSelect } from 'components/Inputs/MultiValueSelect';
import {
  ServiceItem as ServiceItemData,
  ServicesSearchBarAutocomplete,
} from 'components/ServicesSearchBarAutocomplete';
import { specialChars } from 'utils/specialChars';
import { Alert } from 'components/AlertComponents/Alert';

import { clock, real } from 'assets/images/icons';
import { ServiceItemCompany } from '../../components/ServiceItemCompany';

export interface SidesheetAddServiceCompanyProps {
  onCreate?: (values: unknown) => void;
  serviceId?: string;
  usedServiceIds: string[];
  data?: unknown;
  isEdit?: boolean;
  servicesUser?: unknown;
  serviceTypeUser?: unknown;
}

const requiredWhen = (value, field, type) => {
  return value.includes(type) ? field.required('Campo obrigatório.') : field.notRequired();
};

const validationSchema = Yup.object().shape({
  services: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required('Campo obrigatório.'),
        description: Yup.string().required('Campo obrigatório.'),
        name: Yup.string().required('Campo obrigatório.'),
      })
    )
    .min(1, 'Selecione pelo menos um serviço.'),
  formsOfService: yupValidations.formsOfService.min(1, 'Selecione pelo menos uma forma de serviço.'),

  expectedTime: Yup.number().when('formsOfService', (formsOfService, field) => {
    return requiredWhen(formsOfService, field, 'HOUR');
  }),

  costPerHour: Yup.string().when('formsOfService', (formsOfService, field) => {
    return requiredWhen(formsOfService, field, 'HOUR');
  }),
  costPerExtraHour: Yup.string().when(
    'formsOfService',
    (formsOfService, field) => {
      return requiredWhen(formsOfService, field, 'HOUR');
    }
  ),
  costPerTechnicianHour: Yup.string().when(
    'formsOfService',
    (formsOfService, field) => {
      return requiredWhen(formsOfService, field, 'HOUR');
    }
  ),
  costPerTechnicianExtraHour: Yup.string().when(
    'formsOfService',
    (formsOfService, field) => {
      return requiredWhen(formsOfService, field, 'HOUR');
    }
  ),

  costPerCall: Yup.string().when('formsOfService', (formsOfService, field) => {
    return requiredWhen(formsOfService, field, 'PER_CALL');
  }),

  costPerTechnicianCall: Yup.string().when(
    'formsOfService',
    (formsOfService, field) => {
      return requiredWhen(formsOfService, field, 'PER_CALL');
    }
  ),
});

const options = [
  { value: 'PER_CALL', label: 'Por chamado' },
  { value: 'HOUR', label: 'Por Hora' },
];

const SidesheetAddServiceCompany = ({
  onCreate,
  close,
  data,
  isEdit,
  servicesUser,
  serviceTypeUser,
}) => {
  const [searchText, setSearchText] = useState('');
  const services = useServices();
  const [serviceList, setServiceList] = useState();
  const [itemsSelected, setItemsSelected] = useState<ServiceItemData[]>([]);
  const [categoriesSelecteds, setCategoriesSelecteds] = useState<string[]>([]);

  const findIdUpdateService = (id) => {
    if (!servicesUser) return;

    const servicesFindById = servicesUser.find((item) => {
      return item.service.id == id;
    });

    return servicesFindById && servicesFindById.id;
  };

  const handleSubmit = (values, actions) => {
    const payload = values.services.map((s) => {
      return {
        ..._.omit(values, 'services'),
        costPerHour: currencyToFloat(values.costPerHour) || 0,
        costPerExtraHour: currencyToFloat(values.costPerExtraHour) || 0,
        costPerTechnicianHour:
          currencyToFloat(values.costPerTechnicianHour) || 0,
        costPerTechnicianExtraHour:
          currencyToFloat(values.costPerTechnicianExtraHour) || 0,
        costPerCall: currencyToFloat(values.costPerCall) || 0,
        costPerTechnicianCall:
          currencyToFloat(values.costPerTechnicianCall) || 0,
        id: isEdit && findIdUpdateService(s.id),
        service: {
          id: s.id || s.serviceId,
          name: s.name,
          description: s.description,
        },
        expectedTime: values.expectedTime,
      };
    });

    onCreate(payload);
    actions.resetForm();
    close();
  };

  const hiddenServicesFromUser = (selected, servicesUser) => {
    const userItems = servicesUser?.map((item) => {
      return item.service.id;
    });
    const result = selected.filter((item) => {
      if (isEdit) {
        return userItems?.includes(item.id);
      } else {
        return !userItems?.includes(item.id);
      }
    });
    return result;
  };

  const separateAlreadyAddedServices = (services, servicesUser) => {
    const userItems = servicesUser?.map((item) => {
      return item.service.id;
    });
    const modifyServices = services?.map((item) => {
      return {
        serviceCategory: item.serviceCategory,
        id: item.id,
        services: item.services.filter((s) => {
          if (isEdit) {
            return userItems?.includes(s.id);
          } else {
            return !userItems?.includes(s.id);
          }
        }),
      };
    });
    const modifyServiceCategory = modifyServices.map((item) => {
      if (item.services.length !== 0) {
        return {
          serviceCategory: item.serviceCategory,
          id: item.id,
          services: item.services,
        };
      }
    });
    return modifyServiceCategory;
  };

  const handleSearchText = useCallback(() => {
    const filterItems = services?.services.map((service) => {
      const servicesFilter = service.services.filter((item) =>
        specialChars(item.name).match(specialChars(searchText))
      );
      if (servicesFilter.length > 0) {
        return {
          ...service,
          services: servicesFilter,
        };
      }
    });

    if (filterItems) {
      setServiceList(
        separateAlreadyAddedServices(
          filterItems.filter((item) => item !== undefined),
          serviceTypeUser ?? servicesUser
        )
      );
    }
  }, [services, searchText, servicesUser, serviceTypeUser]);

  const handleSelect = useCallback(
    (id: string) => {
      const findItem = itemsSelected.find((item) => item.id === id);
      if (findItem) {
        const items = itemsSelected.filter((item) => item.id !== id);
        setItemsSelected(items);

        const categories = services?.services.find((cat) =>
          cat.services.find((c) => c.id === findItem.id)
        );
        const newCategoriesSelected = categoriesSelecteds.filter(
          (cat) => cat !== categories.id
        );
        setCategoriesSelecteds(newCategoriesSelected);
      } else {
        const categories = services?.services.find((cat) =>
          cat.services.find((c) => c.id === id)
        );

        const serviceSelected = categories.services.find(
          (service) => service.id === id
        );

        const filtered = [serviceSelected];
        categories.services.forEach((item) => {
          itemsSelected.forEach((s) => {
            if (s.id === item.id) {
              filtered.push(item);
            }
          });
        });

        const availableServices = hiddenServicesFromUser(
          categories.services,
          servicesUser
        );

        if (availableServices.length === filtered.length) {
          setCategoriesSelecteds([...categoriesSelecteds, categories.id]);
        }
        setItemsSelected([...itemsSelected, serviceSelected]);
      }
    },
    [
      categoriesSelecteds,
      hiddenServicesFromUser,
      itemsSelected,
      services?.services,
      servicesUser,
    ]
  );

  const handleCheckAllServicesOfCategory = useCallback(
    (categoryId: string) => {
      const service = services?.services.find(
        (serviceObj) => serviceObj.id === categoryId
      );
      const newItemsSelected = Array.isArray(data?.service)
        ? [...data.service, ...itemsSelected]
        : [];

      itemsSelected.forEach((item) => {
        const containItem = service.services.some((s) => s.id === item.id);
        if (!containItem) {
          newItemsSelected.push(item);
        }
      });
      if (categoriesSelecteds.includes(categoryId)) {
        setItemsSelected(
          hiddenServicesFromUser(
            newItemsSelected,
            serviceTypeUser ?? servicesUser
          )
        );
      } else {
        setItemsSelected(
          hiddenServicesFromUser(
            [...newItemsSelected, ...service.services],
            serviceTypeUser ?? servicesUser
          )
        );
      }
    },
    [
      services?.services,
      data?.service,
      itemsSelected,
      categoriesSelecteds,
      serviceTypeUser,
      servicesUser,
    ]
  );

  const handleSelectCategory = useCallback(
    (categoryId: string) => {
      const findCategorySelected = categoriesSelecteds.find(
        (item) => item === categoryId
      );
      if (findCategorySelected) {
        const newCategoriesSelecteds = categoriesSelecteds.filter(
          (item) => item !== categoryId
        );
        setCategoriesSelecteds(newCategoriesSelecteds);
      } else {
        setCategoriesSelecteds((oldValue) => [...oldValue, categoryId]);
      }
      handleCheckAllServicesOfCategory(categoryId);
    },
    [categoriesSelecteds, handleCheckAllServicesOfCategory]
  );

  useEffect(() => {
    if (
      (!services.isError && services.services) ||
      servicesUser ||
      serviceTypeUser
    ) {
      serviceTypeUser;
      setServiceList(
        separateAlreadyAddedServices(
          services?.services,
          serviceTypeUser ?? servicesUser
        )
      );

      if (data) {
        if (data.service) {
          return setItemsSelected([data.service]);
        }
        setItemsSelected((oldValues) => [...oldValues, data?.service]);
      }
    }
  }, [
    data,
    services.isError,
    services.services,
    servicesUser,
    serviceTypeUser,
  ]);

  useEffect(() => {
    handleSearchText();
  }, [searchText]);

  return (
    <Formik
      initialValues={
        data
          ? {
            ...data,
            services: [data.service],
            costPerExtraHour: currencyMask(
              (data?.costPerExtraHour * 100).toString() || null
            ),
            costPerHour: currencyMask(
              (data?.costPerHour * 100).toString() || null
            ),
            costPerTechnicianExtraHour: currencyMask(
              (data?.costPerTechnicianExtraHour * 100).toString() || null
            ),
            costPerCall: currencyMask(
              (data?.costPerCall * 100).toString() || null
            ),
            costPerTechnicianHour: currencyMask(
              (data?.costPerTechnicianHour * 100).toString() || null
            ),
            costPerTechnicianCall: currencyMask(
              (data?.costPerTechnicianCall * 100).toString() || null
            ),
            expectedTime: data?.expectedTime,
          }
          : { services: [], formsOfService: [], necessaryProfessionals: 1 }
      }
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ values, isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form className="flex flex-col flex-1 min-h-screen max-h-screnn min-w-[800px] max-w-min">
          <div className="flex flex-col flex-1 px-8 py-5 space-y-8">
            <h1 className="text-2xl font-bold text-darkBlue">
              {!isEdit ? 'Adicionar' : 'Salvar'} tipo de serviço
            </h1>
            {isEdit && (
              <Alert type="danger">
                Ao clicar em <b>Editar forma de atendimento</b>, as alterações
                serão aplicadas a partir do próximo mês
                <b> {'01/' + (new Date().getMonth() + 2)}</b>.
              </Alert>
            )}
            <div className="flex flex-col flex-1 space-y-4">
              <p className="text-sm text-darkerGray">
                Adicione tipos de serviços que estarão disponíveis para este
                cliente.
              </p>
              <ServicesSearchBarAutocomplete
                data={serviceList}
                searchText={searchText}
                setSearchText={setSearchText}
                onChange={() => {
                  setFieldValue('services', itemsSelected);
                }}
                handleSelect={handleSelect}
                itemsSelected={itemsSelected.map((item) => item.id)}
                categorySelected={categoriesSelecteds}
                handleSelectCategory={handleSelectCategory}
              />

              <p className="text-sm text-darkerGray">
                Você pode adicionar mais de uma forma de atendimento por vez.
              </p>
              <div className="flex flex-col space-y-4">
                {itemsSelected.map((s) => (
                  <ServiceItemCompany
                    preventRemove={isEdit}
                    key={s.id}
                    id={s.id}
                    name={s.name}
                    description={s.description}
                    handleRemove={handleSelect}
                  />
                ))}
              </div>

              {itemsSelected.length > 1 && (
                <div className="flex flex-col w-full p-4 space-y-2 bg-lighterGray rounded-xl">
                  <p className="text-sm font-bold">Atenção!</p>
                  <p className="text-sm text-darkerGray">
                    Ao adicionar mais de uma forma de atendimento, todas serão
                    geridas pelas regras de atendimento cadastradas abaixo.
                  </p>
                </div>
              )}

              {itemsSelected.length > 0 && (
                <>
                  <div className="flex flex-col space-y-4">
                    <p className="font-bold text-h4">
                      Regras para este atendimento
                    </p>
                    <p className="text-sm text-darkerGray">
                      Adicione as SLA&apos;s que servirão como regra para este
                      atendimento:
                    </p>
                    <div className="w-1/2">
                      <label className="flex flex-col w-full text-sm font-medium text-darkerGray">
                        <span className="pb-1">Forma de atendimento</span>
                        <MultiValueSelect
                          name="formsOfService"
                          defaultValue={
                            data?.formsOfService?.map((v) =>
                              options.find((i) => i.value === v)
                            ) || []
                          }
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          options={options}
                        />
                      </label>
                    </div>
                    <div className="flex w-full space-x-4">
                      {values.formsOfService.includes('HOUR') && (
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="expectedTime"
                            label="Tempo previsto"
                            icon={clock}
                            placeholder="0"
                            suffix="horas"
                            type="number"
                          />
                        </div>
                      )}
                    </div>

                    {values.formsOfService.includes('HOUR') && (
                      <div className="flex w-full space-x-4">
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerHour"
                            label="Valor por hora"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerExtraHour"
                            label="Valor por hora extra"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerExtraHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex w-full space-x-4">
                      <div className="w-1/2">
                        {values.formsOfService.includes('PER_CALL') && (
                          <Field
                            component={InputField}
                            name="costPerCall"
                            label="Valor por chamado"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerCall',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-4">
                    <p className="font-bold text-h4">
                      Custo técnico do atendimento
                    </p>
                    <p className="text-sm text-darkerGray">
                      Regras sugeridas com base no cadastro da forma de
                      atendimento. Pode ser alterado conforme negociado com cada
                      cliente.
                    </p>

                    {values.formsOfService.includes('HOUR') && (
                      <div className="flex w-full space-x-4">
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerTechnicianHour"
                            label="Valor a ser pago por hora"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerTechnicianHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                        <div className="w-1/2">
                          <Field
                            component={InputField}
                            name="costPerTechnicianExtraHour"
                            label="Valor a ser pago por hora extra"
                            icon={real}
                            placeholder="0,00"
                            onChange={(e) => {
                              setFieldValue(
                                'costPerTechnicianExtraHour',
                                currencyMask(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {values.formsOfService.includes('PER_CALL') && (
                      <div className="flex w-full space-x-4">
                        <Field
                          component={InputField}
                          name="costPerTechnicianCall"
                          label="Valor a ser pago por chamado"
                          icon={real}
                          placeholder="0,00"
                          onChange={(e) => {
                            setFieldValue(
                              'costPerTechnicianCall',
                              currencyMask(e.target.value)
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex self-end justify-end w-full px-8 py-5 space-x-8 border-t-2 bg-lighterGray border-gray">
            <button
              type="button"
              onClick={() => close()}
              className="text-sm font-medium text-darkerGray focus:outline-none"
            >
              Cancelar
            </button>
            <Button type="submit" disabled={!isValid || isSubmitting}>
              {!isEdit ? 'Adicionar' : 'Editar'} forma de atendimento
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { SidesheetAddServiceCompany };
