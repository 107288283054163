import styled from 'styled-components';
import { Typography } from 'components/Typography';

export const Subtitle = styled(Typography)`
  max-width: 530px;
  text-align: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;
