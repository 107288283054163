import { translateDisplacement } from 'data/displacements';
import { Typography } from 'components/Typography';
import { displacement as displacementIcon } from 'assets/images/icons';

const FormOfDisplacement = ({ displacement }) => {
  return (
    <div className="space-y-4">
      <Typography variant="h4" color="darkBLue">
        Método de deslocamento
      </Typography>
      <div className="flex flex-row items-center space-x-2">
        <img src={displacementIcon} alt="Icon displacement" />

        <Typography variant="p1" color="darkerGray" className="max-w-[239px]">
          {translateDisplacement(displacement)}
        </Typography>
      </div>
    </div>
  );
};

export { FormOfDisplacement };
