import theme from 'themes';

type ColorType = keyof typeof theme.colors;

interface StatusColorIndicatorProps {
  color: ColorType;
  mini?: boolean;
}

const StatusColorIndicator = ({
  color,
  mini = false,
}: StatusColorIndicatorProps) => {
  return (
    <div
      className={` rounded-full ${mini ? 'w-2 h-2' : 'w-3 h-3'}`}
      style={{ backgroundColor: theme.colors[color] }}
      data-testid="statusColorIndicator"
    ></div>
  );
};

export { StatusColorIndicator };
