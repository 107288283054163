import React from 'react';
import { Typography } from 'components/Typography';
import { Container } from './styles';

interface IconTabProps {
  iconSelected: string;
  iconUnselected: string;
  title: string;
  disabled?: boolean;
  selected: boolean;
  setSelected: (selected: number | boolean) => void;
  tabIndex: number | boolean;
}

const IconTab = ({
  iconSelected,
  iconUnselected,
  title,
  disabled = false,
  selected,
  setSelected,
  tabIndex,
}: IconTabProps) => {
  const handleClick = () => {
    setSelected(tabIndex);
  };

  return (
    <Container selected={selected} onClick={handleClick} disabled={disabled}>
      <img
        src={selected ? iconSelected : iconUnselected}
        alt={selected ? 'iconSelected' : 'iconUnselected'}
      />
      <Typography variant="button" color={selected ? 'darkBlue' : 'darkerGray'}>
        {title}
      </Typography>
    </Container>
  );
};

export { IconTab };
