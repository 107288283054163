import clsx from 'clsx';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { callStatus } from 'lib/call';
import { useCall } from 'queries/call';

export const TimeLine = ({ callId }) => {
  const { call } = useCall(callId);
  const timeline = call?.callLog
    ? call?.callLog.map((t) => {
        return {
          id: t.id,
          label: callStatus[t.callStatus].displayText,
          time: format(new Date(t.createdAt || null), 'HH:mm', {
            locale: ptBR,
          }),
          distance: '--',
          color: callStatus[t.callStatus].color,
        };
      })
    : [];

  if (!callId) {
    return null;
  }

  return (
    <>
      <Typography variant="h3" color="darkestGray">
        Linha do tempo:
      </Typography>
      <ul>
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-4">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  className="absolute top-4 left-1 -ml-px h-[90%] w-0.5 bg-gray "
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-baseline space-x-4">
                <div className="flex py-2 bg-white">
                  <div
                    className={clsx('h-2 w-2 rounded-full ', {
                      'bg-blue-400': event.color === 'blue',
                      'bg-green': event.color === 'green',
                      'bg-gray': event.color === 'gray',
                      'bg-purple': event.color === 'purple',
                    })}
                  />
                </div>
                <div className="flex flex-col space-y-4">
                  <p className="text-xs font-semibold text-darkestGray">
                    {event.label}
                  </p>
                  <div className="flex items-center flex-1 h-full flex-item">
                    <div className="w-2/12 h-full">
                      <div className="flex items-center space-x-2">
                        <div className="flex flex-col">
                          <p className="text-xs font-semibold text-darkGray">
                            Horário
                          </p>
                          <span className="text-sm">{event.time}</span>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-xs font-semibold text-darkGray">
                            Distância
                          </p>
                          <span className="text-sm">{event.distance}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
