import { Disclosure, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Typography } from 'components/Typography';
import { useCompany } from 'queries/clients';
import { phoneMask } from 'utils/maskUtils';
import { Avatar } from 'components/Avatar';
import { useUI } from 'hooks/useUI';
import { SidesheetTypes } from 'ducks/ui/types';
import { whatsAppMessageToCompany } from 'utils/whatsAppUtils';

import { useCall } from 'queries/call';
import useUser from 'hooks/useUser';
import {
  documentDark,
  downAccordionArrow,
  inactiveContact,
  inactiveEmail,
  inactivePhone,
  messagePurple,
} from 'assets/images/icons';
import { CardActions } from '../CancelSection/styles';

const CompanyCard = ({ id, callId }) => {
  const { company } = useCompany(id);
  const { type } = useUser();
  const { call } = useCall(callId);
  const UI = useUI();

  return (
    <div className="w-full bg-white border-2 rounded-2xl border-gray">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="flex items-center justify-between w-full px-5 py-4 text-left rounded-lg focus:outline-none"
              data-testid="disclosure-button"
            >
              <div className="flex items-center space-x-4">
                <Avatar
                  name={company?.name}
                  photoURL={company?.avatar}
                  data-testid="avatar"
                />
                <Typography variant="h3" color="darkestGray">
                  {company?.fantasyName}
                </Typography>
              </div>

              <img
                src={downAccordionArrow}
                className={clsx(
                  'w-2 h-2 transition-all duration-200 ease-in-out',
                  {
                    'transform -rotate-180': open,
                  }
                )}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="pb-2 space-y-4 text-sm">
                {type === 'EuNerd' && (
                  <CardActions className="flex justify-center w-full p-4 space-x-4 border-b-2 border-gray">
                    {company?.responsible?.phone[0]?.phone && (
                      <button
                        className="flex items-center justify-center w-full px-4 py-2 space-x-2 bg-opacity-50 rounded-md hover:bg-opacity-80 focus:outline-none bg-lightGray"
                        onClick={() =>
                          whatsAppMessageToCompany(company, callId)
                        }
                      >
                        <div className="p-2 rounded-md bg-lightGray">
                          <img src={messagePurple} />
                        </div>
                        <span className="font-bold text-darkerGray">
                          Enviar Mensagem
                        </span>
                      </button>
                    )}
                    <button
                      className="flex items-center justify-center w-full px-4 py-2 space-x-2 bg-opacity-50 rounded-md hover:bg-opacity-80 focus:outline-none bg-lightGray"
                      onClick={() =>
                        UI.operations.sidesheet.openSidesheet({
                          type: SidesheetTypes.SidesheetViewCompany,
                          sidesheetProps: {
                            companyId: id,
                          },
                        })
                      }
                    >
                      <div className="p-2 rounded-md bg-lightGray">
                        <img src={documentDark} />
                      </div>
                      <span className="font-bold text-darkerGray">
                        Perfil do cliente
                      </span>
                    </button>
                  </CardActions>
                )}
                <div className="flex flex-col px-4 pb-2 space-y-4">
                  <div className="space-y-2">
                    <Typography variant="h4" color="darkestGray">
                      Contato
                    </Typography>
                    <ul className="space-y-2">
                      <li className="flex items-center space-x-2">
                        <img src={inactiveContact} />
                        <Typography variant="p1" color="darkerGray">
                          {call.responsible?.name}
                        </Typography>
                      </li>
                      <li className="flex items-center space-x-2">
                        <img src={inactiveEmail} />
                        <Typography variant="p1" color="darkerGray">
                          {call.responsible?.email}
                        </Typography>
                      </li>
                      <li className="flex items-center space-x-2">
                        <img src={inactivePhone} />
                        <Typography variant="p1" color="darkerGray">
                          {phoneMask(
                            call.responsible?.telephone ||
                            company.responsible?.phone[0].phone
                          )}
                        </Typography>
                      </li>
                    </ul>
                  </div>
                  <div className="space-y-2">
                    <Typography variant="h4" color="darkestGray">
                      Endereço
                    </Typography>
                    <Typography variant="p1" color="darkerGray">
                      {`${call.address?.address}, ${call.address?.number}${call.address?.complement
                          ? ' ' + call.address?.complement
                          : ''
                        } - ${call.address?.city} - ${call.address?.state} - ${call.address?.cep
                        }`}
                    </Typography>
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export { CompanyCard };
