/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Typography } from 'components/Typography';
import clsx from 'clsx';

const InputDescription = () => {
  const { touched, errors } = useFormikContext<any>();
  return (
    <div>
      <Typography variant="h3" color="darkestGray">
        Descrição
      </Typography>
      <Field
        as="textarea"
        rows={6}
        className={clsx(
          'w-full p-3 border-2 rounded-xl focus:outline-none',

          touched.serviceDetail && errors.serviceDetail
            ? 'border-orange focus:border-orange'
            : 'border-gray focus:border-darkGray'
        )}
        name="serviceDetail"
        label="Descrição"
        type="textarea"
        invalid={touched.serviceDetail && errors.serviceDetail}
      />
      <span className="m-0 text-sm text-orangeClick">
        <ErrorMessage name="serviceDetail" />
      </span>
    </div>
  );
};

export { InputDescription };
