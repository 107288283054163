import styled from 'styled-components';

export const CardActions = styled.div`
  @media (max-width: 1440px) {
    flex-direction: column;
    gap: 0.5rem;
    button + button {
      margin: 0 !important;
    }
  }
`;
