import { certification, redX } from 'assets/images/icons';
import { Typography } from 'components/Typography';

interface CertificationItemProps {
  name: string;
  onClick: () => void;
}

const CertificationItem = ({ name, onClick }: CertificationItemProps) => {
  return (
    <div className="border-2 border-[#F2F2F2] rounded-lg flex flex-row p-4 justify-between items-center">
      <div className="flex flex-row gap-2">
        <img src={certification} alt="Ícone do certificado" />
        <div className="flex flex-col gap-1">
          <Typography variant="button" color="darkerGray" className="leading-4">
            {name}
          </Typography>
          <Typography variant="tag" color="darkGray" className="leading-3">
            Certificação
          </Typography>
        </div>
      </div>
      <button
        type="button"
        className="bg-lightGray flex items-center justify-center w-4 h-4 rounded"
        onClick={onClick}
      >
        <img src={redX} alt="Ícone x" />
      </button>
    </div>
  );
};

export { CertificationItem };
