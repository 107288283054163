import { call } from 'assets/images/illustrations';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';

export interface ConfirmCompanyEditModalProps {
  onConfirm: () => void;
  close: () => void;
  type: 'company' | 'contract' | 'company-email' | 'services';
  email?: string;
}

const ConfirmCompanyEditModal = ({
  onConfirm,
  close,
  type,
  email,
}: ConfirmCompanyEditModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    close();
  };

  function getTestsByType() {
    switch (type) {
      case 'company': {
        return {
          title: 'Alteração de dados cadastrais',
          description: (
            <>
              Você está alterando os dados cadastrais.
              <br />
              Após a confirmação, as informações serão atualizadas no sistema.
            </>
          ),
        };
      }
      case 'company-email': {
        return {
          title: 'Alteração de dados cadastrais',
          description: (
            <>
              Você está alterando os dados cadastrais.
              <br />
              Após a confirmação, será enviado um convite para o novo
              Responsável:
              <br />
              <span className="font-semibold underline text-darkestGray">
                {email}
              </span>
              <br />
              com o convite para acessar a plataforma.
            </>
          ),
        };
      }
      case 'contract': {
        return {
          title: 'Alteração de informações base e cobrança do contrato',
          description: (
            <>
              Você está alterando Informações base e Cobrança do contrato.
              <br />
              As alterações serão aplicadas a partir do próximo mês.
            </>
          ),
        };
      }
      case 'services': {
        return {
          title: 'Alteração de Tipos de serviços',
          description: (
            <>
              Você está alterando Informações de Tipos de serviços.
              <br />
              As alterações serão aplicadas a partir do próximo mês.
            </>
          ),
        };
      }
      default: {
        return {
          title: 'Alteração de dados cadastrais',
          description: (
            <>
              Você está alterando os dados cadastrais.
              <br />
              Após a confirmação, as informações serão atualizadas no sistema.
            </>
          ),
        };
      }
    }
  }

  const texts = getTestsByType();

  return (
    <div className="flex flex-col items-center w-full text-center">
      <img src={call} className="h-[160px] w-[160px] mb-10" alt="icon call" />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        {texts.title}
      </Typography>
      <Typography variant="h4" color="darkBlue" className="">
        {texts.description}
      </Typography>

      <div className="flex flex-row gap-6 mt-10">
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={close}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </div>
    </div>
  );
};

export { ConfirmCompanyEditModal };
