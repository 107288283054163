import { useState } from 'react';
import { useRef } from 'react';
import { Button } from 'components/Button';
import { search } from 'assets/images/icons';

interface SearchCardProps {
  onChange: (e) => void;
  placeholder?: string;
}

const SearchCard = ({ onChange, placeholder = 'Buscar' }: SearchCardProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState('');
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={() => {
        inputRef.current?.focus();
      }}
      className="w-[550px] justify-between flex items-center px-6 py-8 space-x-4 border-2 cursor-pointer rounded-xl border-gray hover:border-purpleHover"
    >
      <div className="flex flex-row w-full space-x-4">
        <div className="flex items-center justify-center flex-shrink-0 w-[32px] h-[32px] rounded-md bg-purple">
          <img src={search} alt="Ícone de pesquisa" />
        </div>
        <input
          ref={inputRef}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          className="text-[12px] w-full focus:text-sm font-bold placeholder-darkestGray focus:font-normal focus:placeholder-white"
        />
      </div>
      <Button onClick={() => onChange && onChange(value)}>Buscar</Button>
    </div>
  );
};
export { SearchCard };
