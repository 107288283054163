import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 0 11px 0;
  gap: 8px;
`;

export const AddNewItemContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 32px;
  outline: none !important;
  width: fit-content;
  margin: 16px 0 0 30px;

  background: #f8f8f8;

  border: 2px solid ${({ theme }) => theme.colors.lighterGray};

  transform: border-color 0.2s;
  &:hover,
  &:active {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }
`;

export const AddNewItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.lightGray};
`;

export const FileInput = styled.input`
  display: none;
`;
