import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  margin: 0 14px 0 16px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  object-fit: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  span {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;

  div + div {
    margin-left: 20px;
  }
`;
