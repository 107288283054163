import { Typography } from 'components/Typography';
import { RadioButton } from 'components/RadioButton';

interface UrgentCallsProps {
  urgentCalls: boolean;
}

const UrgentCallsCheck = ({ urgentCalls }: UrgentCallsProps) => {
  return (
    <div className="space-y-4">
      <Typography variant="p1" color="darkerGray">
        Disponibilidade para chamados urgentes?
      </Typography>

      <div className="flex flex-row items-center space-x-2">
        <RadioButton
          label="Sim"
          value="true"
          defaultChecked={urgentCalls === true}
          disabled
        />
        <RadioButton
          label="Não"
          value="false"
          defaultChecked={urgentCalls === false}
          disabled
        />
      </div>
    </div>
  );
};

export { UrgentCallsCheck };
