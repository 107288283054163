import clsx from 'clsx';
import { Button } from 'components/Button';
import { InputField } from 'components/InputField';
import { SelectSearch } from 'components/SelectSearch';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useUI } from 'hooks/useUI';
import { useAdditionalServiceTypes } from 'queries/additionalService';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  AdditionalService,
  createAdditionalService,
} from 'services/additionalService';
import uploadAttachments from 'services/attachments';
import { Attachments } from '../../ServiceDetail/Attachments';
import { ViewAttachments } from '../ViewAttachments';
import { ModalTypes } from 'ducks/ui/types';
import * as Yup from 'yup';

interface FormAdditionalServiceProps {
  callId: number;
  close?: () => void;
  isEdit?: boolean;
  listServices?: createAdditionalService;
}

const FormAdditionalService = ({
  callId,
  close,
  isEdit,
  listServices,
}: FormAdditionalServiceProps) => {
  const { additionalServiceTypes } = useAdditionalServiceTypes();
  const [selectedId, setSelectedId] = useState(null);
  const UI = useUI();
  const queryClient = useQueryClient();

  const schemaValidation = Yup.object().shape({
    title: Yup.string().required('Título é obrigatório'),
    description: Yup.string().required('Descrição é obrigatória'),
    value: Yup.number().required('Valor é obrigatório'),
  });

  const initialValues = {
    additionalServiceId: listServices?.additionalServiceId,
    callId: listServices?.callId,
    title: listServices?.title,
    description: listServices?.description,
    value: listServices?.value,
    attachments: [],
    id: listServices?.id,
  };

  const handleSelect = (option) => {
    setSelectedId(option.id);
  };
  const onRemove = () => {
    setSelectedId(null);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      additionalServiceId: selectedId,
      callId: Number(callId),
      title: values.title,
      description: values.description,
      value: Number(values.value),
      technicianValue: Number(values.technicianValue),
      attachments: values.attachments,
    };

    if (values?.attachments?.length > 0) {
      const attachments = await uploadAttachments(
        values?.attachments?.map((file) => file.file)
      );
      payload.attachments = attachments;
    }

    try {
      if (isEdit) {
        await AdditionalService.update(listServices?.id, payload);
        UI.operations.showToast('Serviço alterado com sucesso!', {
          type: 'success',
        });
      } else {
        await AdditionalService.create(payload);
        UI.operations.showToast('Serviço adicionado com sucesso!', {
          type: 'success',
        });
        resetForm();
        close();
      }
      queryClient.invalidateQueries('additional-service');
    } catch (error) {
      UI.operations.showToast('Erro ao adicionar serviço', { type: 'error' });
    }
  };

  const handleDelete = async () => {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        onConfirm: async () => {
          try {
            await AdditionalService.delete(listServices?.id);
            UI.operations.showToast('Serviço excluído com sucesso!', {
              type: 'success',
            });
            queryClient.invalidateQueries('additional-service');
          } catch (error) {
            UI.operations.showToast('Erro ao deletar serviço', {
              type: 'error',
            });
          }
        },
        title: 'Excluir serviço',
        description: 'Tem certeza que deseja excluir esse serviço?',
        confirmText: 'Excluir',
        cancelText: 'Cancelar',
        danger: true,
      },
    });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schemaValidation}
        className="w-full"
        enableReinitialize
      >
        {({
          isSubmitting,
          setFieldValue,
          values,
          dirty,
          isValid,
          errors,
          touched,
        }) => (
          <Form>
            <div
              className={clsx(
                'flex flex-col w-full min-w-full border-2 rounded-xl p-2',
                {
                  'bg-lighterGray border-gray space-y-1': isEdit,
                  'bg-white border-purple space-y-2': !isEdit,
                }
              )}
            >
              <div>
                <label className="flex flex-col mb-1 text-sm font-medium text-darkerGray">
                  Tipo de serviço adicional
                </label>
                <SelectSearch
                  data={additionalServiceTypes}
                  placeholder="Selecione um tipo de serviço adicional"
                  getItemSelected={handleSelect}
                  onRemove={onRemove}
                  value={selectedId}
                  nameSelected={
                    additionalServiceTypes?.find(
                      (item) => item.id === listServices?.additionalServiceId
                    )?.name
                  }
                />
              </div>
              <Field
                component={InputField}
                name="title"
                label="Título"
                width="100%"
                onChange={(e) => setFieldValue('title', e.target.value)}
              />
              <div>
                <label className="flex flex-col mb-1 text-sm font-medium text-darkerGray">
                  Descrição
                </label>

                <Field
                  className={clsx(
                    'w-full p-3  focus:outline-none focus:border-purple border-gray rounded-xl',
                    {
                      'border-red-500':
                        errors.description && touched.description,
                      'border-2': !errors.description,
                      border: errors.description,
                    }
                  )}
                  name="description"
                  rows={2}
                  as="textarea"
                  width="100%"
                  onChange={(e) => setFieldValue('description', e.target.value)}
                />
                <ErrorMessage name={'description'}>
                  {(msg) => (
                    <div className="w-full text-xs text-red-500">{msg}</div>
                  )}
                </ErrorMessage>
              </div>
              <Field
                component={InputField}
                name="value"
                label="Valor Cliente"
                placeholder="0,00"
                width="100%"
                type="number"
                onChange={(e) => setFieldValue('value', e.target.value)}
              />
              <Field
                component={InputField}
                name="technicianValue"
                label="Valor Técnico (Opcional)"
                placeholder="0,00"
                width="100%"
                type="number"
                onChange={(e) =>
                  setFieldValue('technicianValue', e.target.value)
                }
              />
              {isEdit && (
                <ViewAttachments attachments={listServices?.attachments} />
              )}
              {!isEdit && (
                <Attachments
                  onChange={(items) => {
                    setFieldValue('attachments', items);
                  }}
                  onDelete={(id) => {
                    setFieldValue(
                      'attachments',
                      values.attachments.filter((file) => file.id !== id)
                    );
                  }}
                />
              )}
              <div className="flex justify-end py-2">
                {isEdit && (
                  <Button
                    type="button"
                    onClick={handleDelete}
                    className="mr-2"
                    color="orange"
                  >
                    Excluir
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid}
                >
                  {isEdit && (isSubmitting ? 'Salvando' : 'Alterar')}
                  {!isEdit && (isSubmitting ? 'Salvando' : 'Adicionar')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { FormAdditionalService };
