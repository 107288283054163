import { Typography } from 'components/Typography';
import { Container } from './styles';

export interface SearchOptionItem {
  id: number | string;
  name: string;
  description: string;
}

interface SearchOptionProps {
  option: SearchOptionItem;
  active?: boolean;
  onSelectItem: (option: SearchOptionItem) => void;
}

const SearchOption = ({ active = false, option, onSelectItem }: SearchOptionProps) => {

  return (
    <Container
      active={active}
      onClick={() => { onSelectItem(option) }}
      data-testid='search-option'
    >
      <Typography variant="button" color="darkerGray">
        {option.name}
      </Typography>
      <Typography variant="p1" color="darkGray">
        {option.description}
      </Typography>
    </Container>
  );
}

export { SearchOption };
