import { v4 } from 'uuid';
import { CallDetailsCard } from 'components/CallDetailsCard';
import { Timeline } from 'components/Timeline';
import { CallsNotFound } from 'components/Tables/States';

type TechnicianCallHistoryTypes = {
  company: {
    id: string;
    name: string;
    avatar: string;
  };
  finalTime: string;
  id: number;
  service: {
    name: string;
    id: string;
    description: string;
  };
  serviceType: string;
  startTime: Date;
  status: string;
  value: number;
  technicianValue: number;
};

type TechnicianCallsType = {
  calls: TechnicianCallHistoryTypes[];
  totalConcluded: number;
  totalCanceled: number;
  totalCalls: number;
  totalRevenue: {
    technicianValue: number;
    value: number;
  };
};

interface TimelineScheduleProps {
  technicianCalls: TechnicianCallsType;
}

const TimelineSchedule = ({ technicianCalls }: TimelineScheduleProps) => {
  return (
    <div className="flex flex-col gap-6 pt-6">
      {technicianCalls?.calls?.length > 0 ? (
        technicianCalls?.calls?.map((h) => (
          <Timeline datetime={h.startTime} key={v4()}>
            <div className="flex flex-col gap-4">
              <CallDetailsCard
                key={h.id}
                id={h.id}
                value={h.technicianValue}
                companyName={h.company?.name}
                serviceName={h.service?.name}
                avatarClient={h.company?.avatar}
                callType={h.serviceType}
                statusCall={h.status}
              />
            </div>
          </Timeline>
        ))
      ) : (
        <CallsNotFound viewButton={false} />
      )}
    </div>
  );
};

export { TimelineSchedule };
