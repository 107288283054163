import React, { useState } from 'react';
import { specialChars } from 'utils/specialChars';
import {
  Container,
  Image,
  Input,
  ListContainer,
  SearchBarContainer,
} from './styles';
import { searchBlack } from 'assets/images/icons';
import { StaticList } from 'components/List/StaticList';

interface SearchBarAutocompleteProps {
  data?: Array<string | { name: string; description: string }>;
  placeholder: string;
  onClick?: (item) => void;
  searchText?: string;
  setSearchText?: (string) => void;
  disabled?: boolean;
}

const SearchBarAutocomplete = ({
  data = [],
  placeholder,
  onClick = () => null,
  searchText,
  setSearchText,
  disabled = false,
}: SearchBarAutocompleteProps) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const filterData = (data, searchText) => {
    return data?.filter((item) => {
      return specialChars(typeof item === 'string' ? item : item.name).match(
        specialChars(searchText)
      );
    });
  };

  const handleFocus = (event) => {
    switch (event.type) {
      case 'focus':
        setFocused(true);
        break;
      case 'blur':
        setTimeout(() => {
          setFocused(false);
        }, 500);
        break;
    }
  };

  const filteredData = filterData(data, searchText) || [];

  return (
    <Container focused={focused} disabled={disabled}>
      <SearchBarContainer>
        <Image src={searchBlack} alt="Ícone de pesquisa" />
        <Input
          placeholder={placeholder}
          onChange={handleChange}
          value={searchText}
          onFocus={handleFocus}
          onBlur={handleFocus}
          disabled={disabled}
        />
      </SearchBarContainer>
      {focused && (
        <ListContainer itemCount={filteredData.length}>
          <StaticList data={filteredData} onClick={onClick} />
        </ListContainer>
      )}
    </Container>
  );
};

export { SearchBarAutocomplete };
