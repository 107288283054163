import styled, { css } from 'styled-components';

interface DayButtonProps {
  selected: boolean;
  hasAppointments: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const WeekDaysHeader = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }) => theme.colors.lightGray};
  padding: 8px 0;
  border-radius: 4px;
`;

export const Header = styled.div`
  margin: 8px 0 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 0;
`;

export const DayButton = styled.button<DayButtonProps>`
  padding: 8px 0;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;

  &:active {
    background: ${({ theme }) => theme.colors.purple};
  }

  ${(props) =>
    css`
      background: ${({ theme }) =>
        props.hasAppointments && !props.selected
          ? theme.colors.purpleClick
          : props.selected
          ? theme.colors.purple
          : theme.colors.white};
      span {
        color: ${({ theme }) =>
          props.selected || props.hasAppointments ? theme.colors.white : null};
      }
    `}
`;
