import styled from 'styled-components';

interface DayItemProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DatesContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: flex-start;
  gap: 0.5rem;
  max-width: 653px;
`;

export const DaysContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
  min-width: 160px;
  height: fit-content;
`;

export const DayItem = styled.div<DayItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.purple : theme.colors.gray};

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
  }
`;
