import * as Yup from 'yup';
import { validateCNPJ } from './cnpj';

const yupValidations = {
  notRequiredString: Yup.string().nullable(),
  requiredString: Yup.string().required('Este campo é obrigatório'),
  requiredPassword: Yup.string()
    .min(5, 'Senha deve ter ao menos 5 caracteres.')
    .required('Este campo é obrigatório'),
  requiredNumber: Yup.number()
    .typeError('Este campo precisa ser um número')
    .required('Este campo é obrigatório'),
  requiredEmail: Yup.string()
    .email('E-mail inválido')
    .required('Este campo é obrigatório')
    .trim(),
  cnpj: Yup.string()
    .test('cnpj-test', function (value) {
      return (
        validateCNPJ(value) ||
        this.createError({
          message: `CNPJ inválido`,
          path: 'cnpj',
        })
      );
    })
    .required('Este campo é obrigatório'),
  unproductiveCostPerCall: Yup.string()
    .oneOf(['TECHNICAL_COST_OF_CALL', 'TOTAL_EXPECTED_COST'])
    .required('Este campo é obrigatório'),
  phoneAndBranchLineArray: Yup.array()
    .of(
      Yup.object().shape({
        phone: Yup.string().required('Este campo é obrigatório'),
        branchLine: Yup.string().nullable(),
      })
    )
    .required(),
  formsOfService: Yup.array().of(
    Yup.string().oneOf([
      'PER_CALL',
      'HALF_DAILY',
      'DAILY',
      'WEEKLY',
      'BIWEEKLY',
      'MONTHLY',
      'HOUR',
    ])
  ),
};

export { yupValidations };
