import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { yupValidations } from 'utils/validations/yup';
import { useUI } from 'hooks/useUI';
import { useCall } from 'queries/call';
import { Call } from 'services/call';
import useUser from 'hooks/useUser';
import uploadAttachments from 'services/attachments';
import { Alert } from 'components/AlertComponents/Alert';
import { Button } from 'components/Button';
import { TitleNumber } from 'components/TitleNumber';
import { Typography } from 'components/Typography';

import {
  FinishAlertCallForm,
  FinishCallClientForm,
  EntityCard,
  ExtraHourReviewSection,
} from '../index';
import { AttachmentCard } from 'components/AttachmentCard';
import { LoadingSidesheet } from 'components/LoadingSidesheet';
import { useQueryClient } from 'react-query';

const FinishedCallTab = ({ callId }) => {
  const UI = useUI();
  const { call, refetch, isLoading } = useCall(callId);
  const user = useUser();
  const queryClient = useQueryClient();

  const [finishReason, setFinishReason] = useState(null);
  const [acceptExtraHour, setAcceptExtraHour] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  if (isLoading) {
    return <LoadingSidesheet />;
  }

  const CALL_STATUS = call?.status;
  const successfully = CALL_STATUS === 'CALL_SUCCESSFULLY_ENDED';
  const hasAnalysis = CALL_STATUS === 'UNDER_ANALYSIS';
  const hasUnproductive = CALL_STATUS === 'UNPRODUCTIVE_CALL';
  const hasExtraHour =
    hasAnalysis && user.type === 'EuNerd' && Boolean(call?.additionalCosts);
  const hasReview = call?.callReview;
  const selectedUnproductive = call?.errorTypeUnproductiveCall;
  const clientEndedCall = call?.endedCallClient;
  const technicianEndedCall = call?.endedCallTechnician;
  const hasAnUnproductiveAnalysis =
    (clientEndedCall && !clientEndedCall.completedSuccessfully) ||
    (technicianEndedCall && !technicianEndedCall.completedSuccessfully);

  function handleFinishCall() {
    UI.operations.confirm(
      'Finalizar chamado',
      'Deseja finalizar este chamado?',
      async () => {
        setDisableButton(true);
        try {
          if (finishReason === 'success') {
            await Call.finishReview(callId, {
              aditionalCostIsConfirmed: acceptExtraHour || false,
              isUnproductive: false,
            });
          } else if (finishReason === 'unproductive_company') {
            await Call.finishReview(callId, {
              aditionalCostIsConfirmed: acceptExtraHour || false,
              isUnproductive: true,
              errorTypeUnproductiveCall: 'CLIENT',
            });
          } else if (finishReason === 'unproductive_professional') {
            await Call.finishReview(callId, {
              aditionalCostIsConfirmed: acceptExtraHour || false,
              isUnproductive: true,
              errorTypeUnproductiveCall: 'PROFESSIONAL',
            });
          }
          await queryClient.refetchQueries('call');
          UI.operations.showToast('Chamado revisado com sucesso');
          close();
        } catch (err) {
          UI.operations.showToast('Erro ao revisar chamado', {
            type: 'error',
          });
        } finally {
          setDisableButton(false);
        }
      }
    );
  }
  async function handleFinishCallClient(values) {
    UI.operations.confirm(
      'Finalizar chamado',
      'Deseja finalizar este chamado?',
      async () => {
        setDisableButton(true);
        const attachments = await uploadAttachments(values.files);
        try {
          await Call.endCallClient(callId, {
            completedSuccessfully: values.success,
            description: values.description,
            stars: values.rating,
            note: values.reason,
            technicianId: call?.callToTechnician[0]?.technicianId,
            attachments,
          });

          await queryClient.refetchQueries('call');
          UI.operations.showToast('Chamado finalizado com sucesso');
          await refetch();
          close();
        } catch (err) {
          UI.operations.showToast('Erro ao finalizar chamado', {
            type: 'error',
          });
        } finally {
          setDisableButton(false);
        }
      }
    );
  }

  return (
    <>
      <div className="px-6 py-4 space-y-4">
        {successfully && (
          <Alert
            type="success"
            variant="colored"
            title="Chamado finalizado com sucesso."
          />
        )}
        {hasAnalysis && !hasAnUnproductiveAnalysis && (
          <Alert
            type="danger"
            variant="colored"
            title="Chamado pendente de finalização"
          >
            É preciso verificar as informações do chamado para finalizá-lo.
          </Alert>
        )}
        {hasUnproductive && (
          <Alert
            type="danger"
            variant="colored"
            title="Este chamado foi finalizado como improdutivo."
          />
        )}
        {hasAnUnproductiveAnalysis && !hasUnproductive && hasAnalysis && (
          <Alert
            type="danger"
            variant="colored"
            title="Este chamado foi marcado como improdutivo por uma das partes, e
          precisa de revisão para ser finalizado."
          />
        )}
        {hasExtraHour && (
          <>
            <Alert
              type="danger"
              variant="colored"
              title="Chamado finalizado com horas extra"
            >
              É preciso verificar o uso de horas extras para este chamado.
            </Alert>{' '}
            <ExtraHourReviewSection
              callId={callId}
              accepted={acceptExtraHour}
              onSelect={(value) => {
                setAcceptExtraHour(value);
              }}
            />
            <div className="flex items-center space-x-4">
              <TitleNumber>2</TitleNumber>
              <Typography variant="h2" color="darkestGray">
                Revisão de status
              </Typography>
            </div>
          </>
        )}
        {hasReview && selectedUnproductive && (
          <Alert
            type="info"
            title={
              `Chamado finalizado como improdutivo, por culpa do ` +
                selectedUnproductive ===
                'Client'
                ? 'Cliente'
                : 'Técnico'
            }
          >
            {selectedUnproductive === 'Client'
              ? 'O valor total do chamado será faturado para ' +
              call?.companyName
              : 'O valor total do chamado não será cobrado do cliente.'}
          </Alert>
        )}
        {clientEndedCall && (
          <EntityCard
            type="Company"
            id={call?.companyId}
            status={clientEndedCall?.completedSuccessfully}
            description={clientEndedCall?.description}
            attachments={clientEndedCall?.attachments}
          />
        )}
        {technicianEndedCall && (
          <EntityCard
            type="Technician"
            status={technicianEndedCall.completedSuccessfully}
            id={call?.callToTechnician[0]?.technicianId}
            description={technicianEndedCall?.description}
            attachments={technicianEndedCall?.attachments}
          />
        )}
        {user.type === 'EuNerd' && call?.chit && (
          <>
            <Typography variant="h3" color="darkestGray">
              Nota fiscal
            </Typography>
            <AttachmentCard
              key={call?.chit?.id}
              name={call?.chit?.name}
              description=""
              handleDownload={() => {
                window.open(call?.chit?.url);
              }}
            />
          </>
        )}
      </div>
      {user.type === 'Client' && call?.status === 'PENDING_COMPLETION' && (
        <Formik
          onSubmit={handleFinishCallClient}
          validationSchema={Yup.object().shape({
            description: yupValidations.requiredString,
          })}
          initialValues={{
            files: [],
            description: '',
            success: null,
            rating: 0,
            favorite: false,
          }}
        >
          <FinishCallClientForm
            disableButton={disableButton}
            technicianId={call?.callToTechnician[0]?.technicianId}
          />
        </Formik>
      )}

      {user.type === 'EuNerd' && hasAnalysis && (
        <div className="flex flex-col w-full p-4 space-y-4 bg-lighterGray">
          <FinishAlertCallForm
            finishReason={finishReason}
            setFinishReason={setFinishReason}
            companyName={call?.companyName}
          />
          <Button
            color="purple"
            block
            onClick={
              user.type === 'EuNerd' ? handleFinishCall : handleFinishCallClient
            }
            disabled={
              (hasExtraHour && acceptExtraHour === null) ||
              !finishReason ||
              disableButton
            }
          >
            Finalizar revisão
          </Button>
        </div>
      )}
    </>
  );
};

export { FinishedCallTab };
