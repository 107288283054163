import { Typography } from 'components/Typography';
import React from 'react';

import { Container, Content } from './styles';

interface ReviewJustificationProps {
  description: string;
}

export const ReviewJustification: React.FC<ReviewJustificationProps> = ({ description }) => {
  return (
    <Container>
      <Typography variant="p2" color="darkestGray">
        Justificativa:
      </Typography>
      <Content>
        <Typography variant="p1" color="darkestGray">
          {description}
        </Typography>
      </Content>
    </Container>
  );
}
