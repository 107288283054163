import { useMemo } from 'react';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { alertsError, noAlertsChar } from 'assets/images/illustrations';

import { Container } from './styles';

interface NoAlertsProps {
  type?: 'error' | 'size';
  module?: 'call' | 'alert';
  handleAction(): void;
}

interface AlertLabelType {
  error: string;
  size: string;
}

interface AlertLabel {
  title: AlertLabelType;
  subtitle: AlertLabelType;
}

const NoAlerts = ({
  type = 'size',
  module = 'call',
  handleAction,
}: NoAlertsProps) => {
  const labels: AlertLabel = useMemo(() => {
    const labelsModule = {
      call: {
        title: {
          error: 'Ops, tivemos um erro!',
          size: 'Este chamado não possui alertas!',
        },
        subtitle: {
          error:
            'Desculpe! Tivemos um pequeno imprevisto, tente recarregar a página novamente',
          size: 'Todos os alertas relacionados ao chamado aparecerão aqui.',
        },
      },
      alert: {
        title: {
          error: 'Ops, tivemos um erro!',
          size: 'Sem alertas no momento.',
        },
        subtitle: {
          error:
            'Desculpe! Tivemos um pequeno imprevisto, tente recarregar a página novamente',
          size: 'Todos os alertas aparecerão aqui.',
        },
      },
    };

    return labelsModule[module];
  }, [module]);

  return (
    <Container>
      <img src={type === 'size' ? noAlertsChar : alertsError} alt="No Alerts" />
      <Typography variant="h2" color="darkBlue">
        {labels.title[type]}
      </Typography>
      <Typography variant="p1" color="darkerGray">
        {labels.subtitle[type]}
      </Typography>
      {type === 'error' && (
        <Button size="large" onClick={() => handleAction()}>
          Tentar novamente
        </Button>
      )}
    </Container>
  );
};

export { NoAlerts };
