import { Button } from 'components/Button';
import { FormikValues } from 'formik';

interface FormNavigationProps {
  onBackClick: (values: FormikValues) => void;
  stepNumber: number;
  loading?: boolean;
  setLoading?: () => void;
}

export const FormNavigation = ({
  onBackClick,
  stepNumber,
  loading = false,
}: FormNavigationProps) => {
  const BackButton = ({ text }) => {
    return (
      <button
        onClick={onBackClick}
        type="button"
        className="flex items-center justify-center space-x-2 text-sm font-bold text-darkerGray focus:outline-none"
      >
        <span>{text}</span>
      </button>
    );
  };
  const button = {
    1: <Button type="submit">Continuar para Informações de cobrança</Button>,
    2: (
      <div className="flex items-center justify-center space-x-4">
        <BackButton text="Voltar para Dados cadastrais" />
        <Button type="submit">Continuar para Tipos de serviços</Button>
      </div>
    ),
    3: (
      <div className="flex items-center justify-center space-x-4">
        <BackButton text="Voltar para Informações de cobrança" />
        <Button type="submit">Continuar para Revisão</Button>
      </div>
    ),
    4: (
      <div className="flex items-center justify-center space-x-4">
        <BackButton text="Voltar para tipos de serviços" />
        <Button type="submit" loading={loading}>
          Finalizar cadastro do cliente
        </Button>
      </div>
    ),
  };
  return (
    <div className="flex justify-between py-5 border-t-2 justify-self-end bg-lighterGray border-gray px-14">
      <div className="flex justify-end w-full">{button[stepNumber]}</div>
    </div>
  );
};
