import useSWR from 'swr';
import api, { fetcher } from 'services/api';
import { buildSearchParams } from 'utils/queryUtils';
import { IUser } from 'types/user';

interface IResponseGetUser {
  success: boolean;
  user: IUser;
}

export const UserService = {
  getAll: (filters) => useSWR(`/users?${buildSearchParams(filters)}`, fetcher),
  get: (id: string) => api.get<IResponseGetUser>(`/users/${id}`),
}