/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormikContext } from 'formik';
import { Typography } from 'components/Typography';
import { CallChecklist } from './CallChecklist';
import { InputDescription } from './InputDescription';
import { Attachments } from './Attachments';

const ServiceDetail = () => {
  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <div className="flex flex-col p-6 space-y-6 border-2 rounded-lg border-lightGray">
      <div>
        <Typography variant="h2" color="darkestGray">
          Detalhe o serviço
        </Typography>
        <Typography variant="p1" color="darkerGray" className="">
          Descreva o serviço e cadastre o checklist que o técnico deve realizar
          dentro de um chamado, é a partir deste detalhamento que o profissional
          fará a identificação do que precisa ser feito.
        </Typography>
      </div>

      <div className="flex flex-col space-y-6">
        <InputDescription />
        <CallChecklist />

        <Attachments
          defaultValues={values?.attachments || []}
          onChange={(items) => {
            setFieldValue('attachments', items);
          }}
          onDelete={(id) => {
            setFieldValue(
              'attachments',
              values.attachments.filter((file) => file.id !== id)
            );
          }}
        />
      </div>
    </div>
  );
};

export { ServiceDetail };
