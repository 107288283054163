import { Select } from 'components/Select';
import { Typography } from 'components/Typography';

interface ClientTypeSelectProps {
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
}

const ClientTypeSelect = ({
  onChange,
  disabled = false,
  value = null,
}: ClientTypeSelectProps) => {
  return (
    <div>
      <Typography variant="p1" color="darkerGray">
        Tipo de cliente
      </Typography>
      <Select
        disabled={disabled}
        options={[
          {
            label: 'Empresa',
            value: 'Company',
          },
          {
            label: 'Filial',
            value: 'Branch',
          },
        ]}
        getOptionValue={(option) => onChange(option.value)}
        value={value}
      />
    </div>
  );
};

export { ClientTypeSelect };
