import Select from 'react-select';

interface MultiValueSelectProps {
  name: string;
  defaultValue?: unknown[];
  options: { value: string; label: string }[];
  onChange: (field: string, value: unknown, shouldValidate?: boolean) => void;
  onBlur: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
}

const MultiValueSelect = ({
  defaultValue,
  options,
  name,
  onChange,
  onBlur,
}: MultiValueSelectProps) => {
  return (
    <Select
      defaultValue={defaultValue || []}
      isMulti
      isClearable={false}
      styles={{
        control: (styles, state) => ({
          ...styles,
          border: state.isFocused ? '1px solid #C2C2C2' : '1px solid #E9E9E9',
          '&:hover': {
            border: state.isFocused ? '1px solid #C2C2C2' : '1px solid #E9E9E9',
          },
          backgroundColor: 'white',
          borderRadius: '12px',
          boxShadow: 'none',
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      name={name}
      options={options}
      placeholder="Escolha"
      onChange={(value) =>
        onChange(
          name,
          value.map((v) => v.value || v)
        )
      }
      onBlur={() => onBlur(name, true)}
    />
  );
};

export { MultiValueSelect };
