import { FieldReadOnlyInfo } from 'components/FieldReadOnlyInfo';
import { TextField } from 'components/Inputs/TextField';
import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { ICollaborator } from 'types/collaborator';
import { phoneMask } from 'utils/maskUtils';

import { Container, FormContent, FormRow } from './styles';

interface UserFormProps {
  isVisualization?: boolean;
  data?: ICollaborator;
}

const UserForm = ({ isVisualization = false, data }: UserFormProps) => {
  const { setFieldValue } = useFormikContext();

  const renderFormVisualization = useCallback(() => {
    return (
      <FormContent>
        <FieldReadOnlyInfo label="Nome completo" value={data?.name} />
        <FieldReadOnlyInfo label="Cargo" value={data?.adjutancy} />
        <FormRow>
          <FieldReadOnlyInfo label="E-mail" value={data?.email} />
          <FieldReadOnlyInfo label="Telefone" value={data?.phone[0].phone} />
        </FormRow>
      </FormContent>
    );
  }, [data]);

  return (
    <Container data-testid="user-form">
      {!isVisualization ? (
        <FormContent>
          <Field component={TextField} label="Nome completo" name="name" />
          <Field component={TextField} label="Cargo" name="adjutancy" />
          <FormRow>
            <Field component={TextField} label="E-mail" name="email" />
            <Field
              component={TextField}
              label="Telefone"
              name="phone"
              onChange={(e) => {
                const value = phoneMask(e.target.value);
                setFieldValue('phone', value);
              }}
            />
          </FormRow>
        </FormContent>
      ) : (
        renderFormVisualization()
      )}
    </Container>
  );
};

export { UserForm };
