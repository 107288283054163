import { Container } from './styles';
import { Tag } from 'components/Tag';

interface TagListProps {
  expand: number;
  data?: string[];
  className?: string;
}

const TagList = ({ expand, data = [], className }: TagListProps) => {
  const slice = data.slice(0, expand);
  const excess = data.slice(expand);
  return (
    <Container data-testid="tagList" className={className}>
      {slice.map((v, i) => (
        <Tag key={i}>{v}</Tag>
      ))}
      {excess.length >= 1 && <Tag>+{excess.length}</Tag>}
    </Container>
  );
};

export { TagList };
