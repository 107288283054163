import { useCallback, useState } from 'react';
import { Typography } from 'components/Typography';
import { Container } from './styles';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { callSummarySlice } from 'ducks/callSummary';
import { SelectSearch } from 'components/SelectSearch';
import { SearchOptionItem } from 'components/SelectSearch/SearchContainer/SearchOption';

interface ClientDropdownSearchProps {
  companies: SearchOptionItem[];
  onChange: (item) => void;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  onRemove?: () => void;
}

const ClientDropdownSearch = ({
  companies,
  onChange,
  disabled,
  value,
  placeholder = 'Selecione o cliente',
  onRemove,
}: ClientDropdownSearchProps) => {
  const [_, setSearchText] = useState(value);
  const dispatch = useThunkDispatch();

  const handleClick = useCallback(
    ({ id, name, avatar }) => {
      setSearchText(name);
      dispatch(callSummarySlice.actions.updateClientAvatar({ avatar: avatar }));
      onChange({ id: id, name: name });
    },
    [onChange, companies]
  );

  return (
    <Container>
      <Typography variant="p1" color="darkerGray">
        Cliente
      </Typography>
      <SelectSearch
        data={companies?.map((company) => company) || []}
        getItemSelected={handleClick}
        placeholder={placeholder}
        disabled={disabled}
        onRemove={onRemove}
        value={value}
        nameSelected={value}
      />
    </Container>
  );
};

export { ClientDropdownSearch };
