import React from 'react';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { PermissionDetailItem } from '../AccessButtonItem';
import { checkGreen, closeRed, inactiveInfo } from 'assets/images/icons';
import { v4 } from 'uuid';

import { Container, Header, Title, Content, ItemDetailText } from './styles';

export interface PermissionDetailItemGroup {
  title: string;
  data: PermissionDetailItem[];
}

interface CardUserRolesPermissionsProps {
  title: string;
  handleClose(): void;
  items: PermissionDetailItemGroup[];
}

const CardUserRolesPermissions = ({
  handleClose,
  items,
  title,
}: CardUserRolesPermissionsProps) => {
  return (
    <Container title={title} data-testid="card-user-roles-permissions">
      <Header>
        <Title>
          <img src={inactiveInfo} alt="Inactive info" />
          <Typography variant="h3" color="darkestGray">
            {title}
          </Typography>
        </Title>
        <Button variant="outlined" color="white" onClick={() => handleClose()}>
          <Typography variant="button" color="darkestGray">
            Não mostrar
          </Typography>
        </Button>
      </Header>
      {items.map((item) => (
        <Content key={v4()}>
          <Typography variant="h4" color="darkestGray">
            {item.title}
          </Typography>
          {item.data.map((dataItem) => (
            <ItemDetailText key={v4()}>
              <img
                src={dataItem.type === 'available' ? checkGreen : closeRed}
              />
              <Typography variant="p1" color="darkestGray">
                {dataItem.title}
              </Typography>
            </ItemDetailText>
          ))}
        </Content>
      ))}
    </Container>
  );
};

export { CardUserRolesPermissions };
