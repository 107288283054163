import { lighten } from 'polished';
import styled from 'styled-components';

interface CheckboxProps {
  selected: boolean;
}

export const Container = styled.div`
  margin-bottom: 0.5rem;
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: top;
  gap: 0.5rem;
  cursor: pointer;
  width: fit-content;
`;

export const Checkbox = styled.div<CheckboxProps>`
  margin-top: 3px;
  width: 1rem;
  height: 1rem;
  border: 2px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.purple : theme.colors.lightGray};
  background: ${({ theme, selected }) =>
    selected ? lighten(0.25, theme.colors.purple) : theme.colors.white};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;