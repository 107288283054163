/* eslint-disable */
import { Button } from 'components/Button';
import { TextArea } from 'components/Inputs/TextArea';
import { TextField } from 'components/Inputs/TextField';
import { Select } from 'components/Select';
import { Typography } from 'components/Typography';
import { categories } from 'data/formsOfServicesCategories';
import { Field, Formik, Form } from 'formik';
import { useUI } from 'hooks/useUI';
import React, { useCallback, useState } from 'react';
import { Service } from 'services/service';
import * as Yup from 'yup';
import {
  Container,
  Header,
  Content,
  TextInfoContainer,
  Footer,
  FormGroup,
} from './styles';

export interface EditFormOfServiceFormOfServiceProps {
  close(): void;
  onAfterClose(): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const EditFormOfService = ({
  close,
  onAfterClose,
  data,
}: EditFormOfServiceFormOfServiceProps) => {
  const { operations } = useUI();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (serviceData) => {
      try {
        setLoading(true);
        const { id } = categories.filter(
          (category) => category.value === serviceData.category
        )[0];
        await Service.update(data.id, { ...serviceData, categoryId: id });
        operations.showToast('Tipo de serviço atualizado com sucesso!');
        close();
        onAfterClose();
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao cadastrar o tipo de serviço';

        if (err.response?.status === 400) {
          erroMessage = err.response.data.message;
        }
        operations.showToast(erroMessage, {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [data, onAfterClose]
  );

  return (
    <Formik
      initialValues={{
        name: data?.name,
        category: data?.category,
        description: data?.description,
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('nome obrigatório'),
        description: Yup.string().required('Descrição obrigatória'),
        category: Yup.string().required('Descrição obrigatória'),
      })}
    >
      {({ setFieldValue, values, isValid }) => (
        <Container>
          <Header>
            <Typography variant="h1" color="darkBlue">
              {data?.name}
            </Typography>
          </Header>
          <Form>
            <Content>
              <TextInfoContainer>
                <Typography variant="h2" color="darkBlue">
                  Sobre o tipo de serviço
                </Typography>
                <Typography variant="p1" color="darkBlue">
                  Adicione novos tipos de serviço que estarão disponíveis para
                  os clientes, elas poderão ser vinculadas em um contrato.
                </Typography>
              </TextInfoContainer>
              <Typography variant="h3" color="darkBlue">
                Dados para cadastro
              </Typography>
              <Field
                component={TextField}
                label="Nome do tipo de serviço"
                name="name"
              />
              <FormGroup>
                <Typography variant="p1" color="darkerGray">
                  Categoria
                </Typography>
                <Select
                  options={categories}
                  value={data?.category}
                  getOptionValue={(option) => {
                    if (option) {
                      setFieldValue('category', option.value);
                    }
                  }}
                />
              </FormGroup>
              <Typography variant="h3" color="darkBlue">
                Descrição da novo tipo de serviço
              </Typography>
              <Typography variant="p1" color="darkBlue">
                Será a partir desta descrição que os profissionais e clientes
                farão a identificação do que compete este serviço.
              </Typography>
              <Field
                component={TextArea}
                label=""
                name="description"
                value={values.description}
                onChange={(e) => setFieldValue('description', e.target.value)}
              />
            </Content>
            <Footer>
              <Button variant="anchor" onClick={() => close()}>
                Cancelar
              </Button>
              <Button
                className="submit-button"
                type="submit"
                loading={loading}
                disabled={!isValid}
              >
                Salvar alterações
              </Button>
            </Footer>
          </Form>
        </Container>
      )}
    </Formik>
  );
};
