import styled from 'styled-components';

export const Circle = styled.span`
  margin-right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ theme, color }) => theme.colors[color]};
  flex-shrink: 0;
`;
