import { Typography } from 'components/Typography';
import { connectStats } from 'react-instantsearch-dom';

interface TotalCountTechniciansProps {
  nbHits: number;
}

const TotalCountTechniciansComponent = ({
  nbHits,
}: TotalCountTechniciansProps) => {
  return (
    <Typography
      variant="h4"
      color="darkerGray"
    >{`${nbHits} resultados`}</Typography>
  );
};

const TotalCountTechnicians = connectStats<TotalCountTechniciansProps>(
  TotalCountTechniciansComponent
);

export { TotalCountTechnicians };
