import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 1rem;
  border: 2px solid #f2f2f2;
  background: #ffffff;
  width: 100%;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
`;
