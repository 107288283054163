import React from 'react';
import { Typography } from 'components/Typography';
import { Container } from './styles';

type ItemTypes = {
  label: string;
  id: number | string;
};

interface ChecklistItemProps {
  checked: boolean;
  toggleChecklistItem: (e) => void;
  item: ItemTypes;
}

const ChecklistItem = ({
  checked,
  toggleChecklistItem,
  item,
}: ChecklistItemProps) => {
  const handleChange = () => {
    toggleChecklistItem(item);
  };

  return (
    <Container>
      <input
        className='cursor-pointer'
        type="checkbox"
        id={`checkbox-${item.id}-${item.label}`}
        role="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={`checkbox-${item.id}-${item.label}`}>
        <Typography variant="button">{item.label}</Typography>
      </label>
    </Container>
  );
};

export { ChecklistItem };
