import React from 'react';
import { Typography } from 'components/Typography';
import { Container } from './styles';

interface TabProps {
  title: string;
  selected: boolean;
  setSelected: (selected: boolean | number) => void;
  tabIndex: number | boolean;
}

const Tab = ({ title, selected, setSelected, tabIndex }: TabProps) => {
  const handleClick = () => {
    setSelected(tabIndex);
  };

  return (
    <Container selected={selected} onClick={handleClick}>
      <Typography variant="button" color="darkestGray">
        {title}
      </Typography>
    </Container>
  );
};

export { Tab };
