import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { IconLabel } from 'components/IconLabel';
import { Typography } from 'components/Typography';
import { technicianStatuses } from 'lib/technician';
import { whatsAppMessageToTechnicianProfile } from 'utils/whatsAppUtils';
import { message, services, starUnselected } from 'assets/images/icons';
import { Alert } from 'components/AlertComponents/Alert';

interface HeaderTechnicianProps {
  infoPersonalData: {
    avatar: string;
    name: string;
    telephone: string;
    rating?: number;
    numberOfCalls?: number;
    status?: string;
  };
  displayCallData?: boolean;
}

const HeaderTechnician = ({
  infoPersonalData: {
    avatar = '',
    name,
    rating = 0,
    numberOfCalls = 0,
    status,
    telephone,
  },
  displayCallData = true,
}: HeaderTechnicianProps) => {
  return (
    <div className="p-6 bg-lighterGray">
      <div className="flex flex-row items-center gap-5 mb-5">
        <Avatar name={name} photoURL={avatar} size="large" />
        <div className="flex flex-col gap-5">
          <Typography variant="h1" color="darkBlue">
            {name}
          </Typography>
          {displayCallData && (
            <div className="flex flex-row gap-6">
              <IconLabel icon={starUnselected} color="yellowClick">
                {rating}
              </IconLabel>
              <IconLabel icon={services} color="purple">
                {numberOfCalls} chamados
              </IconLabel>
              <IconLabel color="greenClick" outline selected>
                Status: {technicianStatuses[status]?.displayText}
              </IconLabel>
            </div>
          )}
        </div>
      </div>
      {status === 'REFUSED' && (
        <Alert
          title="Este profissional foi recusado pela equipe Eunerd."
          type="danger"
          variant="colored"
        />
      )}

      {status !== 'REFUSED' && (
        <Button
          block
          color="purple"
          variant="outlined"
          size="large"
          onClick={() =>
            whatsAppMessageToTechnicianProfile({ name, telephone })
          }
        >
          <div className="flex justify-center gap-2">
            <img src={message} alt="icon message" />
            <Typography variant="button" className="self-center">
              Enviar Mensagem
            </Typography>
          </div>
        </Button>
      )}
    </div>
  );
};

export { HeaderTechnician };
