import {
  documentDark,
  documentWhite,
  dollarSign,
  dollarSignWhite,
  suitcaseDark,
  suitcaseWhite,
} from 'assets/images/icons';
import { Stepper } from 'components/Stepper';
import { Typography } from 'components/Typography';
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues,
} from 'formik';

import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormNavigation } from '../FormNavigation';

interface MultiStepFormProps extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  loading?: boolean;
  stepsDone: number;
  setDoneSteps: Dispatch<SetStateAction<number>>;
}

const convertArrayToObject = (array) => {
  const convert = { ...array };

  const object = Object.fromEntries(
    Object.entries(convert).map(([key, value]) => [parseInt(key) + 1, value])
  );
  return object;
};

const MultiStepForm = ({
  children,
  initialValues,
  onSubmit,
  loading = false,
  stepsDone,
  setDoneSteps,
}: MultiStepFormProps) => {
  const [stepNumber, setStepNumber] = useState(1);
  const steps = convertArrayToObject(
    React.Children.toArray(children) as React.ReactElement[]
  );
  const [snapshot, setSnapshot] = useState(initialValues);
  const step = steps[stepNumber] as FormikValues;
  const isLastStep = stepNumber === 4;

  const next = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
    setDoneSteps(stepsDone + 1);
  };

  const previous = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
    setDoneSteps(stepsDone - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }
    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <Form className="flex flex-col justify-between h-full">
          <div className="flex flex-col w-full pt-6 bg-lighterGray">
            <Typography variant="h1" color="darkBLue" className="px-14">
              Cadastrar novo cliente
            </Typography>
            <Stepper currentStep={stepNumber} setCurrentStep={setStepNumber}>
              <Stepper.Step
                title="Dados cadastrais"
                icon={(active) => (active ? documentWhite : documentDark)}
              />
              <Stepper.Step
                title="Informações de cobrança"
                disabled={stepsDone < 1}
                icon={(active) => (active ? dollarSignWhite : dollarSign)}
              />
              <Stepper.Step
                title="Tipos de serviços"
                disabled={stepsDone < 2}
                icon={(active) => (active ? suitcaseWhite : suitcaseDark)}
              />
              <Stepper.Step
                title="Revisão"
                disabled={stepsDone < 3}
                icon={(active) => (active ? documentWhite : documentDark)}
              />
            </Stepper>
          </div>
          <div className="bg-white flex flex-col max-h-[min-content] mb-auto overflow-y-auto">
            {step}
          </div>
          <FormNavigation
            loading={loading}
            stepNumber={stepNumber}
            onBackClick={() => previous(formik.values)}
          />
        </Form>
      )}
    </Formik>
  );
};

export { MultiStepForm };
