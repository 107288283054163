export const specialChars = (value) => {
  if (!value) return '';
  return value
    .replace(/[|&;$%@"<>()+,/]/g, '')
    .replace(/_+/, '_')
    .replace(/\s/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};
