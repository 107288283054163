import { FormikValues } from 'formik';
interface FormStepProps extends FormikValues {
  children: React.ReactNode;
}

const FormStep = ({ children }: FormStepProps) => {
  return <>{children}</>;
};

export { FormStep };
