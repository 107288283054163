import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toFirstLetterUppercase } from 'utils/formatWord';
import { DayDetail } from '../DayDetail';
import { Container } from './styles';

export interface IProfessionalWeeklyScheduleAppointment {
  id: string;
  date: Date;
  timeStart: string;
  timeEnd: string;
  client: string;
  service: string;
  status: 'unavailable' | 'available';
  type: 'primary' | 'success' | 'danger' | 'warning';
}

interface ProfessionalWeeklyScheduleDetailProps {
  date: Date;
  appointments: IProfessionalWeeklyScheduleAppointment[];
}

const ProfessionalWeeklyScheduleDetail = ({
  date,
  appointments,
}: ProfessionalWeeklyScheduleDetailProps) => {
  return (
    <Container>
      <Typography variant="button" color="darkerGray">
        {`Compromissos ${String(date.getDate()).padStart(
          2,
          '0'
        )} ${toFirstLetterUppercase(format(date, 'MMMM', { locale: ptBR }))}`}
      </Typography>
      <Typography variant="p1" color="darkestGray">
        ({appointments.length} Chamados)
      </Typography>
      {appointments.map((appointment) => (
        <DayDetail key={appointment.id} appointment={appointment} />
      ))}
    </Container>
  );
};

export { ProfessionalWeeklyScheduleDetail };
