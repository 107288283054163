import { lighten } from 'polished';
import styled from 'styled-components';

export const PaymentInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const PaymentInfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 0.25rem;

  border: 2px solid ${({ theme, color }) => theme.colors[color]};
  background: ${({ theme, color }) => lighten(0.4, theme.colors[color])};
`;
