import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  img {
    width: 100% !important;
    height: 102px !important;
    border-radius: 0.5rem;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }
`;

export const Subtitle = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.lighterGray};
`;
