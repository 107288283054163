import { callsNotFound } from 'assets/images/illustrations';
import { Typography } from 'components/Typography';
import { Container } from './styles';

const AddressesNotFound = () => {
  return (
    <Container>
      <img className="mb-10" src={callsNotFound} />
      <Typography className="mb-10" variant="h2" color="darkBlue">
        Nenhum endereço encontrado
      </Typography>
    </Container>
  );
};

export { AddressesNotFound };
