import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 24px;
  > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
`;
