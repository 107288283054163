import { useCallback } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { Typography } from 'components/Typography';
import { schedule, urgent } from 'assets/images/icons';

interface ButtonFormikContext {
  type: string;
}

interface ButtonTypeNewCallProps {
  disabled?: boolean;
}

const ButtonTypeNewCall = ({ disabled }: ButtonTypeNewCallProps) => {
  const { setFieldValue, values } = useFormikContext<ButtonFormikContext>();

  const typesOfCallToSelect = [
    {
      value: 'Urgent',
      label: 'Urgente',
      icon: urgent,
      styledActive: 'bg-orange/10 border-orange',
      styledNotActive:
        'border-lightGray bg-white hover:bg-lightestGray hover:border-lightestGray',
      styledDefault: 'hover:bg-orange/20 hover:border-orange',
    },
    {
      value: 'Scheduled',
      label: 'Agendado',
      disabled,
      icon: schedule,
      styledActive: 'bg-green/10 border-green',
      styledNotActive:
        'border-lightGray bg-white hover:bg-lightestGray hover:border-lightestGray',
      styledDefault: 'hover:bg-green/20 hover:border-green',
    },
  ];

  const onChageTypeCall = useCallback(
    (typeSelected) => {
      setFieldValue('type', typeSelected);
      setFieldValue('serviceType', '');
      setFieldValue('serviceId', '');
      setFieldValue('technicianId', []);
    },
    [setFieldValue]
  );
  const notSelectedType = values.type === '';

  return (
    <RadioGroup value={values.type} onChange={onChageTypeCall}>
      <div className="flex space-x-6">
        {typesOfCallToSelect.map((c) => (
          <RadioGroup.Option
            disabled={c.disabled}
            key={c.value}
            style={{
              width: '15rem',
            }}
            value={c.value}
            className={({ checked, active }) =>
              clsx(
                `flex items-center w-60 cursor-pointer justify-center p-4 space-x-2 rounded-lg outline-none border-2 ${
                  !c.disabled && c.styledDefault
                }`,
                active && !notSelectedType
                  ? [c.styledActive]
                  : [c.styledNotActive],
                checked && !notSelectedType
                  ? [c.styledActive]
                  : [c.styledNotActive]
              )
            }
          >
            {({ active, checked }) => (
              <>
                <img src={c.icon} alt="Icone" />
                <Typography
                  variant="button"
                  color={active || checked ? 'darkestGray' : 'darkerGray'}
                >
                  {c.label}
                </Typography>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export { ButtonTypeNewCall };
