import { SearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';

export const SearchBoxInput = styled(SearchBox)`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  .ais-SearchBox-form {
    width: 100%;
    display: flex;
    align-items: center;

    .ais-SearchBox-input {
      width: 100%;
    }
  }

  .ais-SearchBox-submit {
    display: none;
  }

  .ais-SearchBox-reset {
    cursor: pointer;
    display: none;
    padding-right: 0.75rem;
  }

  &::placeholder {
    content: 'Buscar';
  }
`;
