import { IconButton } from 'components/IconButton';
import { SearchBarAutocomplete } from 'components/SearchBarAutocomplete';
import { Typography } from 'components/Typography';
import { useCallback, useState } from 'react';
import { Info } from '../styles';
import { fetcher } from 'services/api';
import { Button } from 'components/Button';
import { useFormikContext } from 'formik';
import { Label } from 'components/Label';
import { plusSign } from 'assets/images/icons';
import useSWR from 'swr';

const RegionList = ({ regions = [] }) => {
  const [adding, setAdding] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { data, error } = useSWR('/regions', fetcher);
  const { setFieldValue } = useFormikContext();

  const handleAddRegion = useCallback(() => {
    const [name, uf] = searchText.split(' - ');
    const selectedRegion = data.regions.find(
      (d) => d.name === name && d.uf === uf
    );
    setFieldValue(`regions[${regions.length}]`, selectedRegion);
    setAdding(false);
    setSearchText('');
  }, [searchText, setFieldValue, data, regions]);

  return (
    <Info data-testid="region-list">
      <Typography variant="h3" color="darkBLue">
        Cidade de atendimento
      </Typography>
      <div className="flex flex-row flex-wrap gap-2">
        {regions.map((r, i) => (
          <Label
            key={i}
            onClose={() =>
              setFieldValue(
                'regions',
                regions.filter((j) => j.name !== r.name)
              )
            }
          >
            {r.name} - {r.uf}
          </Label>
        ))}
      </div>
      {!adding ? (
        <IconButton
          icon={plusSign}
          size="small"
          filled
          onClick={() => setAdding(true)}
          width="160"
        >
          Adicionar Cidade
        </IconButton>
      ) : (
        <>
          <SearchBarAutocomplete
            searchText={searchText}
            setSearchText={setSearchText}
            data={
              !error && data?.success
                ? data?.regions
                    .filter((d) => !regions.find((r) => r.name === d.name))
                    .map((d) => `${d.name} - ${d.uf}`)
                : []
            }
            placeholder="Digite e selecione o local"
            onClick={(item) => {
              setSearchText(item);
            }}
          />

          {data?.regions.find((d) => {
            const [name, uf] = searchText.split(' - ');
            return d.name === name && d.uf === uf;
          }) ? (
            <Button
              color="purple"
              variant="outlined"
              size="medium"
              onClick={() => handleAddRegion()}
              width="105"
              className="self-end"
            >
              Salvar Cidade
            </Button>
          ) : null}
        </>
      )}
    </Info>
  );
};

export { RegionList };
