/* eslint-disable */
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useUI } from 'hooks/useUI';
import useUser from 'hooks/useUser';
import { useTemplateCallById } from 'queries/templateCall';
import React, { useCallback, useState } from 'react';
import uploadAttachments from 'services/attachments';
import { TemplateCallService } from 'services/templateCall';

import { InformationAboutTemplate } from './InformationAboutTemplate';
import { ServiceDetail } from './ServiceDetail';

import { Container, Header, Content, Section, Footer } from './styles';
import { yupValidations } from 'utils/validations/yup';
import { AboutCall } from 'features/Call/components';

export interface NewCallTemplateProps {
  close: () => void;
  onAfterClose: () => void;
  id?: string;
}

const NewCallTemplate = ({ close, id, onAfterClose }: NewCallTemplateProps) => {
  const { companyId } = useUser();
  const { operations } = useUI();
  const { data } = useTemplateCallById(id);
  const [loading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setIsLoading(true);

        const data = {
          ...values,
          necessaryProfessionals: 1,
        };

        if (values?.attachments) {
          const attachments = await uploadAttachments(
            values?.attachments?.map((file) => file.file)
          );
          data.attachments = attachments;
        }

        const checklist = values.checklist.map((i) => i.value);

        const dataTemplate = {
          name: data.name,
          type: data.type,
          companyId: data.companyId,
          serviceId: data.serviceId,
          serviceType: data.serviceType,
          necessaryProfessionals: data.necessaryProfessionals,
          serviceDetail: data.serviceDetail,
          attachments: data.attachments,
          checklist,
        };

        if (id) {
          await TemplateCallService.update(id, dataTemplate);
        } else {
          await TemplateCallService.create(dataTemplate);
        }

        operations.showToast('Template do chamado salvo com sucesso');
        close();
        onAfterClose();
      } catch (err) {
        let erroMessage = 'Ocorreu um erro ao criar o template do chamado';

        if (err.response?.status === 400) {
          erroMessage = err.response.data.message;
        }
        operations.showToast(erroMessage, {
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [id, close, onAfterClose, operations]
  );

  const getSchemaValidation = useCallback(() => {
    return Yup.object().shape({
      name: yupValidations.requiredString,
      serviceDetail: yupValidations.requiredString,
      type: yupValidations.requiredString,
      checklist: Yup.array(
        Yup.object()
          .shape({
            id: Yup.string().required('Campo obrigatório'),
            value: Yup.string().required('Campo obrigatório'),
          })
          .required('Campo obrigatório')
      ),
      serviceId: yupValidations.requiredString,
      serviceType: yupValidations.requiredString,
    });
  }, []);

  return (
    <Container data-testid="new-call-template">
      <Header>
        <Typography variant="h1" color="darkBlue">
          {id ? 'Template' : 'Novo Template'}
        </Typography>
        {id && (
          <Typography variant="h2" color="darkBlue">
            {data?.name}
          </Typography>
        )}
      </Header>
      <Formik
        initialValues={{
          companyId,
          name: '',
          serviceDetail: '',
          type: '',
          checklist: [],
          serviceId: '',
          serviceType: '',
        }}
        initialErrors={{
          companyId,
          name: '',
          serviceDetail: '',
          type: '',
          checklist: [],
          serviceId: '',
          serviceType: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={getSchemaValidation()}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <Content>
              <InformationAboutTemplate data={data} />
              <ServiceDetail />
              <Section>
                <AboutCall isNewTemplate={true} />
              </Section>
            </Content>
            <Footer>
              <Button
                type="submit"
                loading={loading}
                disabled={!isValid || isSubmitting}
              >
                {id ? 'Atualizar' : 'Concluir'}
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export { NewCallTemplate };
