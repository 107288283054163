import { useFormikContext } from 'formik';
import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';
import { AssigneeCard } from './AssigneeCard';
import { AssigneeForm } from './AssigneeForm';
import { useGetUser } from 'queries/users';
import useUser from 'hooks/useUser';

interface FormResponsibleForServiceProps {
  companyIdSelected: string;
}

const FormResponsibleForService = ({
  companyIdSelected,
}: FormResponsibleForServiceProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setValues, values } = useFormikContext<any>();
  const { id } = useUser();
  const { data } = useGetUser(id);

  const applyAutoFillAssignee = () => {
    setValues((v) => ({
      ...v,
      registeredAddress: true,
      responsibleName: data?.collaborator?.name,
      responsibleTelephone: data?.collaborator?.phone[0]?.phone,
      responsibleEmail: data?.collaborator?.email,
    }));
  };

  return (
    <div className="space-y-4">
      {companyIdSelected && (
        <>
          <Typography variant="p1" color="darkerGray">
            O responsável que irá validar o atendimento será o mesmo que está abrindo o chamado?
          </Typography>
          <div className="flex flex-row justify-between w-1/3">
            <RadioButton
              checked={values.registeredAddress === true}
              onChange={() => {
                applyAutoFillAssignee();
              }}
              value="true"
              label="Sim"
            />
            <RadioButton
              checked={values.registeredAddress === false}
              onChange={() => {
                setValues((v) => ({
                  ...v,
                  registeredAddress: false,
                  responsibleName: '',
                  responsibleTelephone: '',
                  responsibleEmail: '',
                }));
              }}
              value="false"
              label="Outro responsável"
            />
          </div>
          {values.registeredAddress === true && (
            <AssigneeCard
              assignee={{
                name: data?.collaborator?.name,
                phone: data?.collaborator?.phone[0]?.phone,
                email: data?.collaborator?.email,
              }}
            />
          )}
          {values.registeredAddress === false && <AssigneeForm />}
        </>
      )}
    </div>
  );
};

export { FormResponsibleForService };
