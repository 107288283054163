import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  ToastProvider as ReactToastProvider,
  useToasts,
} from 'react-toast-notifications';

import { uiSlice } from 'ducks/ui';
import { RootState } from 'redux/types';

import ToastContent from './ToastContent';
import ToastContainer from './ToastContainer';
import { Toast } from './Toast';

const ToastMessageHandler: React.FC = () => {
  const messages = useSelector(
    (state: RootState) => state.ui.messages,
    (l, r) => l.length === r.length
  );
  const dispatch = useDispatch<Dispatch>();
  const { addToast } = useToasts();
  useEffect(() => {
    if (messages && messages.length) {
      messages.forEach((message) => {
        addToast(
          <ToastContent
            title={message.title}
            message={message.message}
            icon={message.icon}
          />,
          { appearance: message.type }
        );
      });
      setTimeout(() => dispatch(uiSlice.actions.clearMessages()));
    }
  }, [messages]);
  return null;
};

const ToastProvider = ({ children }) => (
  <ReactToastProvider
    components={{ Toast: Toast, ToastContainer }}
    autoDismiss
    autoDismissTimeout={6000}
    placement="bottom-center"
  >
    <ToastMessageHandler />
    {children}
  </ReactToastProvider>
);

export { ToastProvider };
