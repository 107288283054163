import React from 'react';
import flattenKeys from 'utils/flattenKeys';
import { Container, ErrorMessage, Input, Label, OptionalLabel } from './styles';

type FieldTypes = {
  name: string;
  onBlur: () => void;
  onChange: () => void;
  value: string;
};

type FormTypes = {
  touched: {
    [key: string]: boolean;
  };
  errors: {
    [key: string]: string;
  };
};
interface TextFieldProps {
  errorMessage?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  onChange?: (e) => void;
  width?: string | number;
  height?: string | number;
  field?: FieldTypes;
  form?: FormTypes;
  disabled?: boolean;
  showOptionalLabel?: boolean;
  dataTestId?: string;
  maxLength?: number;
}

const TextField = ({
  errorMessage,
  label,
  name,
  placeholder,
  type = 'text',
  value,
  onChange,
  width,
  height,
  field,
  form,
  disabled,
  showOptionalLabel = false,
  dataTestId,
  maxLength,
}: TextFieldProps) => {
  let handleBlur = null;

  if (field) {
    value = field.value;
    handleBlur = field.onBlur;
    name = field.name;

    if (!onChange) {
      onChange = field.onChange;
    }

    if (flattenKeys(form.touched)[field.name]) {
      errorMessage = flattenKeys(form.errors)[field.name];
    }
  }

  return (
    <Container>
      <Label htmlFor={label}>
        {label}
        {showOptionalLabel && <OptionalLabel> (opcional)</OptionalLabel>}
      </Label>
      <Input
        id={label}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        invalid={errorMessage}
        width={width}
        height={height}
        onBlur={handleBlur}
        disabled={disabled}
        maxLength={maxLength}
        data-testid={dataTestId}
      />
      <div>
        <ErrorMessage hidden={!errorMessage}>{errorMessage}</ErrorMessage>
      </div>
    </Container>
  );
};

export { TextField };
