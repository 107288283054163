import React from 'react';
import { Button as StyledButton, AnchorButton } from './styles';

type ButtonType = 'button' | 'submit' | 'reset';
type VariantType = 'outlined' | 'contained' | 'anchor';

export interface ButtonProps {
  block?: boolean;
  children: React.ReactNode;
  color?: string;
  disabled?: boolean;
  size?: string;
  type?: ButtonType;
  onClick?: (event?: React.MouseEvent<HTMLInputElement>) => void;
  variant?: VariantType;
  width?: number | string;
  className?: string;
  loading?: boolean;
  disableHover?: boolean;
  isLoading?: boolean;
}

const Button = ({
  block = false,
  children,
  color = 'purple',
  disabled = false,
  size = 'medium',
  type = 'button',
  onClick,
  variant = 'contained',
  width = null,
  className = '',
  loading = false,
  disableHover = false,
}: ButtonProps) => {
  if (variant === 'anchor') {
    return (
      <AnchorButton type={type} onClick={onClick} className={className}>
        {children}
      </AnchorButton>
    );
  }

  return (
    <StyledButton
      block={block}
      color={color}
      type={type}
      variant={variant}
      size={size}
      onClick={onClick}
      width={width}
      disabled={disabled || loading}
      className={className}
      isLoading={loading}
      disableHover={disableHover}
    >
      {loading ? 'Aguarde...' : children}
    </StyledButton>
  );
};

export { Button };
