import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.lighterGray};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  button {
    width: 100%;
  }
`;

