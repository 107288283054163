import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 1.5rem 0 2.5rem 0;
    max-width: 272px;
  }

  button {
    width: 350px;
  }
`;
