import { Typography } from 'components/Typography';
import { Accordion } from 'components/Accordion';
import { closeLabel } from 'assets/images/icons';
import { ServiceTechnicianTypes } from 'features/Technician/types';

interface ServiceItemProps {
  service: ServiceTechnicianTypes;
  onClose?: () => void;
}

const ServiceItem = ({ service, onClose }: ServiceItemProps) => {
  return (
    <Accordion
      title={
        onClose ? (
          <div className="flex flex-row space-x-2">
            <img
              src={closeLabel}
              onClick={onClose}
              alt="Imagem com ícone de fechar a label"
            />
            <div>{service?.name}</div>
          </div>
        ) : (
          service?.name
        )
      }
    >
      <Typography variant="p1" color="darkestGray">
        {service?.description}
      </Typography>
    </Accordion>
  );
};

export { ServiceItem };
