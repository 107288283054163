import { useMemo } from 'react';
import { format } from 'date-fns';
import { callStatus } from 'lib/call';
import { useCall } from 'queries/call';
import clsx from 'clsx';
import { Typography } from 'components/Typography';

type timeLineTypes = {
  id: string;
  label: string;
  time: typeof Date;
  distance: string;
  color: string;
};

interface CardTimeLineProps {
  callId: number;
}

const CardTimeLine = ({ callId }: CardTimeLineProps) => {
  const { call } = useCall(callId);

  const timeLineData = useMemo<timeLineTypes[]>(() => {
    return call?.callLog?.map((t) => {
      return (
        {
          id: t?.id,
          label: callStatus[t?.callStatus]?.displayText,
          time: format(new Date(t?.createdAt || null), 'HH:mm'),
          distance: '--',
          color: callStatus[t?.callStatus]?.color,
        } || []
      );
    });
  }, [call?.callLog]);

  return (
    <div
      className="absolute top-0 left-0 flex flex-col px-6 m-4 space-y-2 overflow-y-auto bg-white border-2 rounded-xl border-gray"
      style={{ maxHeight: '90%' }}
    >
      <div className="sticky top-0 z-10 py-4 -mt-4 bg-white">
        <Typography variant="h3" color="darkestGray">
          Linha do tempo:
        </Typography>
      </div>
      <ul>
        {timeLineData?.map((t, idx) => (
          <li key={t?.id}>
            <div className="relative pb-4">
              {timeLineData?.length - 1 !== idx ? (
                <span
                  className="absolute top-4 left-1 -ml-px h-[90%] w-0.5 bg-gray "
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-baseline space-x-4">
                <div className="flex py-2 bg-white">
                  <div
                    data-testid={'call-timeline-status-' + t?.id}
                    className={clsx('h-2 w-2 rounded-full ', {
                      'bg-blue-400': t?.color === 'blue',
                      'bg-green': t?.color === 'green',
                      'bg-gray': t?.color === 'gray',
                      'bg-purple': t?.color === 'purple',
                      'bg-orange': t?.color === 'orange',
                    })}
                  />
                </div>
                <div className="flex flex-col space-y-4">
                  <p className="text-xs font-semibold text-darkestGray">
                    {t?.label}
                  </p>
                  <div className="flex items-center flex-1 h-full flex-item">
                    <div className="w-2/12 h-full">
                      <div className="flex items-center space-x-2">
                        <div className="flex flex-col">
                          <p className="text-xs font-semibold text-darkGray">
                            Horário
                          </p>
                          <span className="text-sm">{t?.time}</span>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-xs font-semibold text-darkGray">
                            Distância
                          </p>
                          <span className="text-sm">{t?.distance}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { CardTimeLine };
