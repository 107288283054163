import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 8px;

  .DayPicker {
    background: ${(props) => props.theme.colors.white};
    width: 300px;
    .DayPicker-Months {
      width: 100%;

      .DayPicker-Month {
        width: 100%;
        margin-top: 0 !important;

        .DayPicker-Caption {
          display: none;
        }
      }

      .DayPicker-Weekdays {
        margin-top: 0;

        .DayPicker-WeekdaysRow {
          .DayPicker-Weekday {
            color: ${(props) => props.theme.colors.darkBlue};
            font-weight: bold;
            size: 12px;
          }
        }
      }
    }
  }

  .DayPicker-Week {
    .DayPicker-Day.DayPicker-Day--today {
      font-weight: normal;
      color: ${(props) => props.theme.colors.darkBlue};
    }
    .DayPicker-Day {
      padding: 4px 6px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      transition: background 0.2s;

      &:hover {
        color: ${(props) => props.theme.colors.white};
        background: ${(props) => props.theme.colors.purple} !important;
      }
    }

    .DayPicker-Day.DayPicker-Day--outside {
      color: ${(props) => props.theme.colors.darkGray};

      &:hover {
        color: ${(props) => props.theme.colors.darkGray};
        background: ${(props) => props.theme.colors.white} !important;
      }
    }

    .DayPicker-Day.DayPicker-Day--disabled {
      color: ${(props) => props.theme.colors.darkGray};
      background: ${(props) => props.theme.colors.white} !important;
      cursor: not-allowed;
    }
  }

  .DayPicker-Day.DayPicker-Day--selected {
    background: ${(props) => props.theme.colors.purple};
    border-radius: 4px;
    margin: 4px;
  }
`;
