import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 8px;
`;
