export const toFirstLetterUppercase = (word: string) => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

export const formatAvatarName = (name: string) => {
  return name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
};
