import { Typography } from 'components/Typography';
import { Circle } from 'components/styles';

interface StatusIndicatorProps {
  color: string;
  status: string;
}

const StatusIndicator = ({ color, status }: StatusIndicatorProps) => {
  return (
    <div className="flex items-center gap-2">
      <Circle color={color} data-testid="circle" />
      <Typography variant="p1" color="darkerGray">
        {status}
      </Typography>
    </div>
  );
};

export { StatusIndicator };
