import { useQuery } from 'react-query';
import { TemplateCallService } from 'services/templateCall';

export function useTemplateCall() {
  const { data, error, isLoading, refetch } = useQuery(
    ['templateCall'],
    () => TemplateCallService.findAll()
  );

  return {
    data: data?.data || [],
    isLoading,
    isError: error,
    refetch,
  };
}

export function useTemplateCallById(id: string) {
  const { data, error, isLoading, refetch } = useQuery(
    ['templateCallById', id],
    () => TemplateCallService.findById(id), {
      enabled: Boolean(id),
    }
  );

  return {
    data: data?.data || {},
    isLoading,
    isError: error,
    refetch,
  };
}
