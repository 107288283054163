import { useState } from 'react';
import { SidesheetHeader } from 'components/SidesheetHeader';
import { Typography } from 'components/Typography';
import { addBalanceAlternatePlus } from 'assets/images/illustrations';
import { arrowLeftBlack } from 'assets/images/icons';
import { Button } from 'components/Button';

const AddBalanceSidesheet = () => {
  const [stage, setStage] = useState(0);

  return (
    <div className="flex flex-col flex-1 min-h-screen max-w-[603px] ">
      {stage === 0 ? (
        <SidesheetHeader>Adicionar Saldo</SidesheetHeader>
      ) : (
        <div className="flex px-4 py-6 space-x-4 bg-lighterGray">
          <button
            className="focus:outline-none"
            onClick={() => {
              setStage(0);
            }}
          >
            <img src={arrowLeftBlack} />
          </button>
          <div className="flex flex-col">
            <Typography variant="h3" color="darkestGray">
              Adicionar Saldo
            </Typography>
            <div className="flex items-center space-x-1">
              <Typography variant="p1" color="darkGray">
                Contratar Saldo Extra
              </Typography>{' '}
              <Typography variant="button" color="darkestGray">
                / Gestão financeira
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div className="w-full h-full flex items-center justify-center px-8 py-10">
        <div className="border-[1px] rounded-2xl border-gray w-full h-full px-24 flex flex-col justify-center items-center space-y-4">
          <img
            src={addBalanceAlternatePlus}
            alt="icon"
            className="w-[225px] h-[135px]"
          />
          <Typography variant="h2" className="text-center">
            Negocie diretamente com nosso financeiro
          </Typography>
          <Typography className="text-center">
            Entre em contato com nossa equipe e veja as formas de pagamento
            disponíveis ou aumentar limite atual do contrato.
          </Typography>
          <Button
            type="submit"
            className="w-80"
            onClick={() =>
              window.open('https://meetings.hubspot.com/caio-silva')
            }
          >
            Falar com o Financeiro
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddBalanceSidesheet };
