import styled, { css } from 'styled-components';

interface TablesProps {
  isActive: boolean;
}

export const table = styled.table`
  min-width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
`;

export const thead = styled.thead`
  background: #f2f2f2;
  border-radius: 8px;
`;

export const tr = styled.tr<TablesProps>`
  &:nth-of-type(even) {
    background-color: rgba(242, 242, 242, 0.5);
  }
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightPurple};
    td,
    p,
    span,
    td > div > div > div > p[color='darkerGray'] {
      color: ${({ theme }) => theme.colors.purple};
    }
    td > div > div > div > p {
      color: ${({ theme }) => theme.colors.white};
    }
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.lightPurple} !important;
      td,
      p,
      span,
      td > div > div > div > p[color='darkerGray'] {
        color: ${({ theme }) => theme.colors.purple};
      }
      td > div > div > div > p {
        color: ${({ theme }) => theme.colors.white};
      }
    `};
`;

export const th = styled.th`
  text-align: left;
  font-feature-settings: 'liga' off;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
`;

export const td = styled.td`
  font-feature-settings: 'liga' off;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #6c6c75;
  line-height: 24px;

  &:nth-last-child(1) {
    button {
      min-width: 40px;
    }
  }
`;
