import { FlatTab } from './FlatTab';
import { IconTab } from './IconTab';

type renderItemType = typeof FlatTab | typeof IconTab;

interface DataProps {
  title?: string;
  iconSelected?: string;
  iconUnselected?: string;
  disabled?: boolean;
}

export interface renderItemProps {
  selected?: boolean;
  setSelected?: React.Dispatch<React.SetStateAction<number | boolean>>;
  tabIndex?: number;
  iconSelected?: string;
  iconUnselected?: string;
  title?: string;
  disabled?: boolean;
}

interface TabsProps {
  data?: Array<DataProps>;
  className?: string;
  renderItem: React.ComponentType<renderItemProps | renderItemType | any>;
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
}

const Tabs = ({
  data = [],
  className = '',
  renderItem,
  currentTab,
  setCurrentTab,
}: TabsProps) => {
  const TabItem = renderItem;

  return (
    <div className={['flex flex-row', className].join(' ')}>
      {data.map((item, index) => {
        return (
          <TabItem
            selected={currentTab === index}
            setSelected={setCurrentTab}
            tabIndex={index}
            {...item}
            key={index}
          />
        );
      })}
    </div>
  );
};

export { Tabs };
