import { Typography } from 'components/Typography';
import React, { ComponentType, memo } from 'react';

import { Container, TopictNumber, TopictTitle, Body } from './styles';

interface DataTopicItemProps {
  topic: number;
  title: string;
  content: ComponentType;
}

const DataTopicItemComponent = ({
  content: Content,
  title,
  topic,
}: DataTopicItemProps) => {
  return (
    <Container>
      <TopictTitle>
        <TopictNumber>
          <Typography variant="button" color="purple">
            {topic}
          </Typography>
        </TopictNumber>
        <Typography variant="h2" color="darkestGray">
          {title}
        </Typography>
      </TopictTitle>
      <Body>
        <Content />
      </Body>
    </Container>
  );
};

const DataTopicItem = memo(DataTopicItemComponent);

export { DataTopicItem };
