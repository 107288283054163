import { documentGray, downloadGray } from 'assets/images/icons';
import { Typography } from 'components/Typography';

const AttachmentCard = ({ name, description, handleDownload }) => {
  return (
    <div className="flex items-center justify-between px-6 py-4 border-2 rounded-xl border-lightGray">
      <div className="flex items-center space-x-4">
        <img
          src={documentGray}
          className="flex items-center justify-center w-8 h-8"
          alt="Imagem do botão de documento cinza"
        />
        <div className="flex flex-col">
          <Typography variant="h3" color="darkerGray">
            {name}
          </Typography>
          <Typography variant="overline" color="darkerGray">
            {description}
          </Typography>
        </div>
      </div>
      <button
        className="flex-shrink-0 focus:outline-none"
        onClick={handleDownload}
        type="button"
      >
        <img src={downloadGray} alt="Imagem do botão de download cinza" />
      </button>
    </div>
  );
};

export { AttachmentCard };
