import { Container, Content } from './styles';
import { Typography } from 'components/Typography';

interface AlertLabelProps {
  variant?: 'warning' | 'success';
  title?: string;
  children?: React.ReactNode;
  icon?: string;
}

const AlertLabel = ({
  title,
  children,
  icon,
  variant = 'warning',
}: AlertLabelProps) => {
  return (
    <Container variant={variant} data-testid="container-alert">
      {icon && <img src={icon} alt="icon" />}
      <Content>
        {title && (
          <Typography variant="h3" color="darkBlue">
            {title}
          </Typography>
        )}
        {children}
      </Content>
    </Container>
  );
};

export { AlertLabel };
