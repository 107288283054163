import { FieldReadOnlyInfo } from 'components/FieldReadOnlyInfo';
import { Typography } from 'components/Typography';
import { useCompany } from 'queries/clients';
import { useTechnician } from 'queries/technician';
import { v4 } from 'uuid';

type AttachmentsTypes = {
  id: string;
  name: string;
  url: string;
};
interface EntityCardProps {
  id: string;
  type: string;
  status: boolean;
  description: string;
  attachments: AttachmentsTypes[];
}

const EntityCard = ({
  id,
  type,
  status,
  description,
  attachments = [],
}: EntityCardProps) => {
  const company = useCompany(type === 'Company' ? id : null);
  const technician = useTechnician(type === 'Technician' ? id : null);

  if (!id) {
    return null;
  }

  const avatar = technician.technician?.avatar || company.company?.avatar;

  return (
    <>
      <Typography variant="h3" color="darkestGray">
        {type === 'Company' ? 'Cliente' : 'Profissional'}
      </Typography>
      <div className="flex flex-col p-4 space-y-4 border-2 rounded-xl border-lightGray">
        <div className="flex items-center space-x-4">
          {avatar ? (
            <img
              src={avatar}
              className="w-12 h-12 rounded-full"
              alt="avatar"
              data-testid="avatar"
            />
          ) : null}

          <Typography variant="h2" color="darkestBlue">
            {company.company.name || technician.technician?.name}
          </Typography>
        </div>
        <FieldReadOnlyInfo
          variant={status ? 'success' : 'danger'}
          label="Finalizado como:"
          value={
            status
              ? 'Chamado finalizado com sucesso'
              : 'Chamado finalizado como improdutivo'
          }
        />
        {description && (
          <FieldReadOnlyInfo label="Justificativa:" value={description} />
        )}
        {attachments.length > 0 && (
          <div className="flex flex-col space-y-4">
            <p className="text-sm text-darkerGray">Anexos</p>
            <div className="grid grid-cols-2 gap-4">
              {attachments.map((a) => {
                return (
                  <div className="space-y-4" key={v4()}>
                    <a href={a.url} target="_blank" rel="noopener noreferrer">
                      <img
                        className="object-cover w-full h-24 overflow-hidden rounded-xl"
                        src={a.url}
                        alt="anexo"
                      />
                    </a>
                    <div className="p-4 space-y-4 border-2 rounded-xl border-lightGray">
                      <p className="text-sm font-semibold text-darkerGray break-all">
                        {a.name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { EntityCard };
