import React, { memo } from 'react';

import { Container, FileInfo, DownloadFileButton } from './styles';
import { Typography } from 'components/Typography';
import {
  attachmentsDocument,
  attachmentsDownload,
  attachmentsRemove,
} from 'assets/images/icons';

interface AttachmentItemProps {
  file: File;
  id: string;
  onDelete: (id: string) => void;
}

const AttachmentItem = ({ file, id, onDelete }: AttachmentItemProps) => {
  return (
    <Container>
      <div className="flex items-center space-x-4">
        <FileInfo>
          <img src={attachmentsDocument} alt="Document attachments" />
          <Typography
            variant="button"
            color="darkerGray"
            className="max-w-full truncate"
          >
            {file?.name}
          </Typography>
        </FileInfo>

        <DownloadFileButton
          href={URL.createObjectURL(file)}
          download={file?.name}
          type="button"
        >
          <img src={attachmentsDownload} alt="Download attachments" />
        </DownloadFileButton>
        <DownloadFileButton
          onClick={() => {
            onDelete(id);
          }}
          type="button"
        >
          <img src={attachmentsRemove} alt="Remove attachments" />
        </DownloadFileButton>
      </div>
    </Container>
  );
};

export default memo(AttachmentItem);
