import { useQuery } from 'react-query';
import { fetcher } from 'services/api';

export const useServicesByCompany = (companyId) => {
  const { data, error } = useQuery(
    [`/service-type/`, companyId],
    () => {
      return fetcher(`/service-type/${companyId}/company`);
    },
    {
      enabled: Boolean(companyId),
    }
  );

  return {
    services: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};
