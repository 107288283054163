import { TabUnderlineAndBadges } from './TabUnderlineAndBadges';

type TabUnderLineAndBadgesTypes = {
  tabId: number;
  name: string;
  count?: number;
};

interface TabsUnderlineAndBadgesProps {
  tabsData: TabUnderLineAndBadgesTypes[];
  children: React.ReactNode;
  onChange: (tabId: number) => void;
}

const TabsUnderlineAndBadges = ({
  tabsData,
  children,
  onChange,
}: TabsUnderlineAndBadgesProps) => {
  return (
    <>
      <div>
        <TabUnderlineAndBadges tabsData={tabsData} onClick={onChange} />
      </div>
      {children}
    </>
  );
};

export { TabsUnderlineAndBadges };
export type { TabUnderLineAndBadgesTypes };
