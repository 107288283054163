import styled, { css, keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
`;

interface StylesProps {
  isOpen: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  user-select: none;
`;

export const SelectContainer = styled.div<StylesProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 130px;

  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 16px;
  padding: 16px 12px;

  transition: border-color 0.2s;

  img {
    ${(props) =>
      props.isOpen &&
      css`
        animation: ${rotateAnimation} 0.5s ease;
        transform: rotate(180deg);
      `}

    margin-left: 32px;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.purple};
  }
  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border-color: ${theme.colors.lightGray} !important;
      span {
        color: ${theme.colors.darkGray} !important;
      }
    `}
`;

export const SelectOpenList = styled.ul<StylesProps>`
  ${(props) =>
    props.isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  position: absolute;
  z-index: 1;

  list-style: none;
  padding: 16px;
  top: 64px;

  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 8px;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
`;

export const Display = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  img {
    margin-left: 0;
    margin-right: 8px;
  }
`;
