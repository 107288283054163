import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { CompaniesTypes } from 'features/Company/types';
import { v4 } from 'uuid';
import { ViewContractInformation } from '../ViewContractInformation';
import { ViewTypesOfServices } from '../ViewTypesOfServices';

interface CompanyContractDataProps {
  data: CompaniesTypes;
}

export enum EditClientSections {
  Details = 1,
  Contract,
  Services,
}

const CompanyContractData = ({ data }: CompanyContractDataProps) => {
  const UI = useUI();
  function handleOpenEdit(section: EditClientSections) {
    UI.operations.sidesheet.openSidesheet({
      type: SidesheetTypes.SidesheetEdiCompany,
      sidesheetProps: {
        section,
        id: data.id,
        width: 'wide',
      },
    });
  }

  return (
    <div>
      <ViewContractInformation
        currentSla={data.currentSla}
        contract={data.contract}
        onClick={() => handleOpenEdit(EditClientSections.Contract)}
      />
      <ViewTypesOfServices
        key={v4()}
        serviceType={data.serviceType}
        onClick={() => handleOpenEdit(EditClientSections.Services)}
      />
    </div>
  );
};

export { CompanyContractData };
