import clsx from 'clsx';
import { Typography } from 'components/Typography';

interface reviewItemProps {
  label: string;
  description?: string;
  icon: string;
  danger?: boolean;
}

const ReviewItem = ({
  label,
  description,
  icon,
  danger = false,
}: reviewItemProps) => {
  return (
    <div className="flex items-center w-full py-2 space-x-4">
      <div
        data-testid="review-item-container-img"
        className={clsx(
          'flex items-center justify-center w-10 h-10 rounded-md ',
          {
            'bg-purple bg-opacity-20 ': !danger,
            'bg-orange': danger,
          }
        )}
      >
        <img src={icon} alt="icon" />
      </div>
      <div className="flex flex-col">
        <Typography variant="h3" color="darkestGray">
          {label}
        </Typography>
        {description && (
          <Typography variant="p1" color="darkestGray">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export { ReviewItem };
