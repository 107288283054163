import { ModalTypes } from 'ducks/ui/types';
import ModalHelloWorld from './HelloWorldModal';
import { ConfirmCnpjModal } from './ConfirmCnpjModal';
import { ConfirmCompanyModal } from './ConfirmCompanyModal';
import { ConfirmCompanyEditModal } from './ConfirmEditCompanyModal';
import { ConfirmCancelModal } from './ConfirmCancelCall';
import { ConfirmCreateCallModal } from './ConfirmCreateCall';
import { ConfirmationModal } from './Confirmation';
import { ConfirmCreateCollaborator } from './ConfirmCreateCollaborator';
import { DisableCollaborator } from './DisableCollaborator';
import { ConfirmCreateFormsOfService } from './ConfirmCreateFormsOfService';
import { ConfirmChangeRegistrationData } from './ConfirmChangeRegistrationData';
import { EnableCollaborator } from './EnableCollaborator';
import { ConfirmCreateCallTemplateModal } from './ConfirmCreateCallTemplate';

export type ModalBaseProps = {
  close: () => void;
  isOpen: boolean;
  onAfterClose?: () => void;
};

export interface HelloWorldModalProps extends ModalBaseProps {
  message: string;
}

export interface ConfirmCnpjModalProps extends ModalBaseProps {
  cnpj: string;
  id: string;
}

export const modalTypes: {
  [key in ModalTypes]: React.ComponentType | React.ElementType;
} = {
  [ModalTypes.HelloWorld]: ModalHelloWorld,
  [ModalTypes.ConfirmCnpj]: ConfirmCnpjModal,
  [ModalTypes.ConfirmCompany]: ConfirmCompanyModal,
  [ModalTypes.ConfirmEditCompany]: ConfirmCompanyEditModal,
  [ModalTypes.CancelCallConfirmation]: ConfirmCancelModal,
  [ModalTypes.ConfirmCreateCall]: ConfirmCreateCallModal,
  [ModalTypes.ModalGenericConfirmation]: ConfirmationModal,
  [ModalTypes.ConfirmCreateCollaborator]: ConfirmCreateCollaborator,
  [ModalTypes.DisableCollaborator]: DisableCollaborator,
  [ModalTypes.ConfirmCreateFormsOfService]: ConfirmCreateFormsOfService,
  [ModalTypes.ConfirmChangeRegistrationData]: ConfirmChangeRegistrationData,
  [ModalTypes.EnableCollaborator]: EnableCollaborator,
  [ModalTypes.ConfirmCreateCallTemplate]: ConfirmCreateCallTemplateModal,
};
