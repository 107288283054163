import { LinearProgress as StyledLinearProgress } from './styles';

interface LinearProressProps {
  value?: number;
}

const LinearProgress = ({ value = 0 }: LinearProressProps) => {
  return value ? (
    <StyledLinearProgress
      color="primary"
      variant="buffer"
      value={value}
      valueBuffer={value}
    />
  ) : (
    <StyledLinearProgress color="primary" variant="indeterminate" />
  );
};

export { LinearProgress };
