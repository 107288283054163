import { useQuery } from 'react-query';
import { AdditionalService } from 'services/additionalService';

function useAdditionalServiceTypes() {
  const { data: additionalServiceTypes } = useQuery(
    ['additionalService-types'],
    () => AdditionalService.getAdditionalServiceTypes()
  );
  return { additionalServiceTypes };
}

function useAdditionalServiceById(id) {
  const { data: additionalService } = useQuery(
    [`additionalService-id`],
    () => AdditionalService.getById(id)
  );
  return { additionalService };
}

function useAdditionalServiceByCallId(callId) {
  const { data: listServices, isLoading } = useQuery(
    [`additional-service`],
    () => AdditionalService.getServiceByCallId(callId)
  );
  return { listServices, isLoading };
}


export {
  useAdditionalServiceTypes,
  useAdditionalServiceById,
  useAdditionalServiceByCallId
};