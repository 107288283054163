import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';

interface LinkTechnicianCardProps {
  callId: string;
  companyId: string;
  refetch: unknown;
}

const LinkTechnicianCard = ({
  callId,
  companyId,
  refetch,
}: LinkTechnicianCardProps) => {
  const UI = useUI();

  return (
    <div className="flex flex-col p-4 space-y-2 border-2 border-lightGray rounded-xl">
      <Typography variant="h3" color="darkerGray">
        Vincular novo profissional
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Você pode alterar o profissional vinculado ao chamado selecionado uma
        das opções abaixo.
      </Typography>
      <div className="flex justify-end w-100">
        <Button
          onClick={() => {
            UI.operations.sidesheet.openSidesheet({
              type: SidesheetTypes.SidesheetLinkTechnician,
              sidesheetProps: {
                callId: callId,
                companyId: companyId,
                onAfterClose: () => {
                  refetch = { refetch };
                },
              },
            });
          }}
          type="button"
          variant="outlined"
          className="seld-end"
        >
          Vincular novo profissional
        </Button>
      </div>
    </div>
  );
};

export { LinkTechnicianCard };
