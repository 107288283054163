import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  img {
    margin-right: 8px;
  }
`;
