import { Typography } from 'components/Typography';
import React from 'react';

import { Container, Content } from './styles';

interface FieldInformationItemProps {
  label: string;
  content: string;
}

const FieldInformationItem = ({
  content,
  label,
}: FieldInformationItemProps) => {
  return (
    <Container data-testid="field-information-item">
      <Typography variant="p1" color="darkerGray">
        {label}
      </Typography>
      <Content>
        <Typography variant="p1" color="darkerGray">
          {content}
        </Typography>
      </Content>
    </Container>
  );
};

export { FieldInformationItem };
