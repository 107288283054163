import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 8px;
  background: #f2f2f2;
  border-radius: 2px;
  max-width: max-content;
`;
