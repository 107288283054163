import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2.5rem 1.5rem;
  background: ${({ theme }) => rgba(theme.colors.lightGray, 0.5)};
  gap: 1.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
