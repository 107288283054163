import styled from 'styled-components';
import { SpinnerProps } from '.';

const getBackgroundColor = ({ variant, theme: { colors } }) => {
  switch (variant) {
    case 'primary':
      return colors.white;
    case 'secondary':
      return colors.lighterGray;
    default:
      return colors.white;
  }
};

export const Container = styled.div<SpinnerProps>`
  display: flex;
  justify-content: center;
  background-color: ${getBackgroundColor};
  align-items: center;
`;
