import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

export const SidesheetBaseContainer = styled.div`
  max-width: 85vw;
  padding-left: 2.5rem;

  @media (max-width: 1440px) {
    padding-left: 0;
  }
`;

export const ExitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #806bff;
  border-radius: 8px;
  width: 36px;
  height: 60px;
  outline: none !important;
`;
