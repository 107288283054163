import { Typography } from 'components/Typography';

interface TitleNumberProps {
  children: React.ReactNode;
}

const TitleNumber = ({ children }: TitleNumberProps) => {
  return (
    <div className="flex items-center justify-center w-8 h-8 bg-opacity-25 rounded bg-purple">
      <Typography variant="button" color="purple">
        {children}
      </Typography>
    </div>
  );
};

export { TitleNumber };
