import React, { useState, useEffect } from 'react';
import { Container, Top, Text, Footer, Content, Space, Icon } from './styles';
import { LinearProgress } from 'components/LinearProgress';
import {
  inactiveAlertSearch as inactiveAlert,
  select,
} from 'assets/images/icons';

const Card = ({ status = ' ', style, time = null }) => {
  const [cardBody, setCardBody] = useState(<></>);

  useEffect(() => {
    if (status)
      switch (status) {
        case 'LOOKING_FOR_PROFESSIONAL':
          return setCardBody(
            <>
              <Top>
                <Text>Buscando profissional</Text>
              </Top>
              <Footer>
                <LinearProgress />
              </Footer>
            </>
          );

        case 'OPERATIONS_LOOKING_FOR_PROFESSIONAL':
          return setCardBody(
            <>
              <Top>
                <Text>Buscando profissional</Text>
              </Top>
              <Footer>
                <LinearProgress />
              </Footer>
            </>
          );

        case 'PROFESSIONAL_NOT_FOUND':
          return setCardBody(
            <>
              <Top style={{ backgroundColor: style.backgroundColor }}>
                <Text>
                  <Icon src={inactiveAlert} alt="" />
                </Text>
              </Top>
              <Footer>
                <Content>
                  <Text>É preciso buscar</Text>

                  <Text>um novo profissional</Text>
                </Content>
              </Footer>
            </>
          );

        case 'SCHEDULED_CALL':
          return setCardBody(
            <>
              <Top>
                <Text>Profissional vinculado</Text>
              </Top>
              <Footer>
                <Content>
                  <Text>Início previsto para:</Text>
                  <Text>{time || 'Não definido'}</Text>
                </Content>
              </Footer>
            </>
          );

        case 'WAITING_TECHNICIANS_ACCEPTANCE':
          return setCardBody(
            <>
              <Top>
                <Text>Aguardando técnico aceitar</Text>
              </Top>
              <Footer>
                <Content>
                  <Text>Início do chamado em:</Text>
                  <Text>{time || '1 hora e 52 minutos'}</Text>
                </Content>
              </Footer>
            </>
          );

        case 'ON_THE_WAY':
          return setCardBody(
            <>
              <Top>
                <Text>Profissional à caminho</Text>
              </Top>
              <Footer>
                <Content>
                  <Text>8,2km de distância</Text>
                  <Text>Previsão de chegada:</Text>
                  <Text>Entre {time || 'Não definido'}</Text>
                  <Space />
                  <LinearProgress value={20} />
                </Content>
              </Footer>
            </>
          );

        case 'SUPPORT_STARTED':
          return setCardBody(
            <>
              <Top>
                <Text>Suporte inciado</Text>
              </Top>
              <Footer>
                <Content>
                  <Text>Previsão de suporte</Text>
                  <Text>{time || '15:34h e 17:00h'}</Text>
                </Content>
              </Footer>
            </>
          );

        case 'CALL_SUCCESSFULLY_ENDED':
          return setCardBody(
            <>
              <Top style={{ backgroundColor: style.backgroundColor }}>
                <Icon src={select} alt="" />
              </Top>
              <Footer>
                <Content>
                  <Text>Suporte finalizado</Text>
                </Content>
              </Footer>
            </>
          );

        default:
          break;
      }
  }, [status]);
  return <Container style={{ ...style }}>{cardBody}</Container>;
};

export default Card;
