import clsx from 'clsx';

interface FieldReadOnlyInfoProps {
  value: string;
  label: string;
  disabled?: boolean;
  variant?: string;
  optional?: boolean;
}

const FieldReadOnlyInfo = ({
  value,
  label,
  disabled = false,
  variant = 'normal',
  optional = false,
}: FieldReadOnlyInfoProps) => {
  return (
    <div
      data-testid="field-read-only-info"
      className={clsx('space-y-2 w-full', {
        'opacity-50': disabled,
      })}
    >
      {optional ? (
        <p className="text-sm text-darkerGray w-48">
          {label}{' '}
          <span className="text-sm font-medium text-darkGray">(opcional)</span>
        </p>
      ) : (
        <p className="text-sm text-darkerGray">{label}</p>
      )}
      <div
        className={clsx('p-3 text-sm rounded-xl break-all h-11', {
          'bg-green bg-opacity-20': variant === 'success',
          'bg-orange bg-opacity-20': variant === 'danger',
          'bg-lightGray': variant === 'normal',
        })}
      >
        {value ?? <div className="p-3 text-sm rounded-xl bg-lightGray" />}
      </div>
    </div>
  );
};

export { FieldReadOnlyInfo };
