import { useTechnician } from 'queries/technician';
import React, { useMemo } from 'react';
import { CallAddStatusReview } from './CallAddStatusReview';
import { CallStatusReviewItem } from './CallStatusReviewItem';

import { Container } from './styles';

interface CallStatusReviewProps {
  call: any;
  onChange(valeu: string): void;
  onSubmit(): void;
  callReviewStatus: string;
}

export const CallStatusReview: React.FC<CallStatusReviewProps> = ({
  call,
  onChange,
  onSubmit,
  callReviewStatus,
}) => {
  const { technician } = useTechnician(
    call?.callToTechnician && call?.callToTechnician[0]?.technicianId
  );
  const clientFiles = useMemo(() => {
    return call?.endedCallClient?.attachments.map((attachment) => ({
      image: attachment.url,
      subtitle: attachment.name,
    }));
  }, [call]);

  const technicianFiles = useMemo(() => {
    return call?.endedCallTechnician?.attachments.map((attachment) => ({
      image: attachment.url,
      subtitle: attachment.name,
    }));
  }, [call]);

  const callUnproductive = call?.status === 'UNPRODUCTIVE_CALL';

  return (
    <Container>
      {call?.endedCallClient && (
        <CallStatusReviewItem
          title="Cliente"
          username={call?.companyName}
          userLogo={technician?.company?.avatar}
          callStatus={
            call?.endedCallClient?.completedSuccessfully ? 'success' : 'warning'
          }
          callStatusDescription={call?.endedCallClient?.description}
          justification={call?.endedCallClient?.note || '--'}
          files={clientFiles}
        />
      )}
      {call?.endedCallTechnician && (
        <CallStatusReviewItem
          title="Profissional"
          username={technician?.name}
          userLogo={technician?.avatar}
          callStatus={
            call?.endedCallTechnician?.completedSuccessfully
              ? 'success'
              : 'warning'
          }
          callStatusDescription={call?.endedCallTechnician?.description}
          justification={call?.endedCallTechnician?.note || '--'}
          files={technicianFiles}
        />
      )}
      {callUnproductive && (
        <CallAddStatusReview
          callReviewStatus={callReviewStatus}
          onChange={onChange}
          onSubmit={onSubmit}
          company={call?.companyName}
        />
      )}
    </Container>
  );
};
