import React from 'react';
import { Zoom } from '@material-ui/core';
import {
  Tooltip as StyledTooltip,
  InformationContainer,
  Image,
  Divisor,
} from './styles';
import {
  Marker,
  Location,
  Footer,
  Text,
  LookButton,
} from 'components/Markers/CallMarker/styles';
import {
  alertNotification,
  inactiveTechniciansGray,
  makerFooter,
  search,
} from 'assets/images/icons';

interface TooltipProps {
  location: string;
  childrenComponent: React.ReactNode;
  hidden?: boolean;
  calls?: number;
  notifications?: number;
}

const Tooltip = ({
  location,
  childrenComponent,
  hidden = false,
  calls = 0,
  notifications = 0,
}: TooltipProps) => {
  return (
    <StyledTooltip
      className={hidden ? 'hidden' : ''}
      TransitionComponent={Zoom}
      title={
        <Marker>
          <Location>
            <Text>{location}</Text>
          </Location>
          <Footer style={{ backgroundImage: `url(${makerFooter})` }}>
            <InformationContainer>
              <Image src={inactiveTechniciansGray} alt="" /> {calls}
            </InformationContainer>
            <Divisor />
            <InformationContainer>
              <Image src={alertNotification} alt="" /> {notifications}
            </InformationContainer>
            <LookButton
              show={true}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Image src={search} alt="" />
            </LookButton>
          </Footer>
        </Marker>
      }
      placement="top"
      arrow
    >
      <div>{childrenComponent}</div>
    </StyledTooltip>
  );
};

export { Tooltip };
