import { useState } from 'react';
import { v4 } from 'uuid';
import { useCall } from 'queries/call';
import { Pagination, usePaginationData } from 'components/Pagination';
import { NoAlerts } from 'components/NoAlerts';
import { NotificationCard } from '../NotificationCard';
import { LoadingSidesheet } from 'components/LoadingSidesheet';

interface NotificationsTabProps {
  callId: number;
}

const NotificationsTab = ({ callId }: NotificationsTabProps) => {
  const { call, isLoading, refetch } = useCall(callId);
  const [currentPage, setCurrentPage] = useState(1);

  const dataPagination = usePaginationData({
    objectData: call?.notifications,
    currentPage,
  });

  if (isLoading) {
    return <LoadingSidesheet />;
  }

  return (
    <div className="px-6 py-4 space-y-8">
      <div className="flex flex-col space-y-4 divide-y-2 divide-lightGray">
        {call?.notifications?.length > 0 ? (
          <>
            {dataPagination.map((n) => {
              return (
                <NotificationCard
                  key={v4()}
                  callId={call?.id}
                  companyId={call?.companyId}
                  date={n.createdAt}
                  title={n.title}
                  description={n.description}
                  actions={n.possibleActions}
                  refetch={refetch}
                  technicianId={call?.callToTechnician[0]?.technicianId}
                />
              );
            })}
            <Pagination
              currentPage={currentPage}
              totalCount={call?.notifications?.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        ) : (
          <NoAlerts type="size" handleAction={() => null} />
        )}
      </div>
    </div>
  );
};

export { NotificationsTab };
