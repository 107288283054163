import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { IProfessionalWeeklyScheduleAppointment } from '../ProfessionalWeeklyScheduleDetail';
import { Container, Divider, Info, UnavailableDay } from './styles';

interface DayDetailProps {
  appointment: IProfessionalWeeklyScheduleAppointment;
}

const DayDetail = ({ appointment }: DayDetailProps) => {
  const { client, date, service, status, timeEnd, timeStart, type } =
    appointment;

  return (
    <Container status={status} type={type} data-testid="day-detail-container">
      {status === 'available' && (
        <Typography variant="h3" color="darkerGray">
          {format(date, 'HH:mm')}
        </Typography>
      )}
      <Divider status={status} type={type} />

      {status === 'unavailable' ? (
        <UnavailableDay>
          <Typography variant="tag" color="darkerGray">
            Dia
          </Typography>
          <Typography variant="p3" color="darkerGray">
            Indisponível
          </Typography>
        </UnavailableDay>
      ) : (
        <Info>
          <Typography variant="tag" color="darkerGray">
            {timeStart} - {timeEnd}
          </Typography>

          <div>
            <Typography variant="p3" color="darkerGray">
              {client}
            </Typography>
            <Typography variant="p3" color="darkerGray">
              {service}
            </Typography>
          </div>
        </Info>
      )}
    </Container>
  );
};

export { DayDetail };
