import { AlertLabel } from 'components/AlertComponents/AlertLabel';
import { AttachmentCard } from 'components/AttachmentCard';
import TechnicianCard from 'components/Calls/ServiceTab/TechnicianCard';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useCall } from 'queries/call';

import { CardPaymentInfo } from '../CardPaymentInfo';
import { ResumeService } from '../ResumeService';
import { StepsReviewPayment } from './StepsReviewPayment';
import { Container } from './styles';
import { Financial } from 'services/financial';
import { useUI } from 'hooks/useUI';
import { inactiveAlertHover } from 'assets/images/icons';
import { useCompany } from 'queries/clients';
import { LoadingSidesheet } from 'components/LoadingSidesheet';

interface PaymentReviewTabProps {
  close: () => void;
  callId: string;
}

export const PaymentReviewTab = ({ close, callId }: PaymentReviewTabProps) => {
  const UI = useUI();
  const { call, isLoading } = useCall(callId);
  const { company } = useCompany(call?.company?.id);

  if (isLoading) {
    return <LoadingSidesheet />;
  }

  const handleSubmit = async (values) => {
    try {
      const payload = {
        status: values.paymentStatus,
        justificationOfRefusal: values.paymentRefusedJustification,
      };
      await Financial.updatePayment(callId, payload);

      close();
      UI.operations.showToast('Pagamento realizado com sucesso!');
    } catch (err) {
      let errorMessage = 'Ocorreu um erro ao realizar o pagamento';

      if (err.response?.status === 400) {
        errorMessage = err.response.data.message;
      }
      UI.operations.showToast(errorMessage, {
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          paymentStatus: call?.chit?.status,
          paymentRefusedJustification: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="px-6">
            {call.chit?.antecipatedPayment && (
              <AlertLabel
                title={`Pagamento antecipado solicitado no dia ${format(
                  new Date(call.chit.paidOutAt),
                  "dd/MM/yyyy 'às' HH:mm"
                )} `}
                icon={inactiveAlertHover}
              >
                <Typography variant="p1" color="darkerGray">
                  O técnico solicitou antecipar o pagamento deste chamado
                </Typography>
              </AlertLabel>
            )}

            <div className="space-y-6">
              <ResumeService call={call} />
              <div>
                <Typography variant="h2" color="darkestGray">
                  Profissional
                </Typography>
                {call?.callToTechnician && (
                  <TechnicianCard
                    id={call?.callToTechnician[0]?.technicianId}
                    callId={call?.id}
                  />
                )}
              </div>

              <div className="space-y-4">
                <CardPaymentInfo
                  call={call}
                  urgentCostPerCall={company?.currentSla?.urgentCostPerCall}
                />
                <Typography variant="h3" color="darkestGray">
                  Nota fiscal
                </Typography>
                <AttachmentCard
                  key={call?.chit?.id}
                  name={call?.chit?.name}
                  description=""
                  handleDownload={() => {
                    window.open(call?.chit?.url);
                  }}
                />

                {call?.attachments?.length <= 0 && (
                  <Typography variant="p1" color="darkerGray">
                    Nenhum anexo
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <StepsReviewPayment callStatus={call?.chit?.status} />
          </div>
        </Form>
      </Formik>
    </Container>
  );
};
