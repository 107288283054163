import { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'components/Tabs';
import { IconTab } from 'components/Tabs/IconTab';
import {
  activeChecklist,
  financesSelected,
  financesUnselected,
  inactiveChecklistGray,
  scheduleSelected,
  scheduleUnselected,
} from 'assets/images/icons';
import {
  CardTimeLine,
  ChecklistTab,
  FinishedCallTab,
  FollowupTab,
  ServiceOrderTab,
} from 'features/Call/components';
import dynamic from 'next/dynamic';
import Spinner from 'components/Spinner';
import { format } from 'date-fns';
import { formatUTCHour } from 'utils/formatUtils';
import { useCall } from 'queries/call';
import useUser from 'hooks/useUser';

export interface SidesheetFinishedCallsProps {
  id: number;
  statusCall: string;
}
const SidesheetFinishedCalls = ({
  id,
  statusCall,
}: SidesheetFinishedCallsProps) => {
  const [numTab, setNumTab] = useState(0);
  const [zoom, setZoom] = useState(13);
  const { call, isLoading } = useCall(id);
  const isCanceled = statusCall === 'SUPPORT_CANCELED';
  const INITIAL_TAB = isCanceled ? 1 : 0;
  const { type } = useUser();

  useEffect(() => {
    setNumTab(INITIAL_TAB);
  }, [INITIAL_TAB]);

  function renderTab() {
    const tabs = {
      0: <FinishedCallTab callId={id} />,
      1: <ServiceOrderTab callId={id} />,
      2:
        type === 'EuNerd' ? (
          <FollowupTab callId={id} isFinishedCall />
        ) : (
          <ChecklistTab callId={id} />
        ),
    };
    return tabs[numTab] || tabs[0];
  }

  const marker = useMemo(() => {
    return {
      id: call?.id,
      lat: call?.address?.lat || call?.city?.lat,
      lng: call?.address?.lng || call?.city?.lng,
      status: call?.status,
      inProgress: true,
      active: true,
      time: call?.startTime
        ? format(formatUTCHour(call?.startTime), "dd/MM 'às' HH:mm")
        : null,
      type: 'client',
    };
  }, [call]);

  const LeafletMap = useMemo(
    () =>
      dynamic(() => import('components/LeafletMap'), {
        ssr: false,
        loading: () => <Spinner />,
      }),
    []
  );

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-1/2 min-w-[720px] max-w-min max-h-screen space-y-4 overflow-y-auto">
        <div className="sticky top-0 w-full px-4 bg-lighterGray z-50">
          <Tabs
            data={[
              {
                title: 'Finalizar chamado',
                iconSelected: financesSelected,
                iconUnselected: financesUnselected,
                disabled: isCanceled,
              },
              {
                title: 'Ordem de Serviço',
                iconSelected: scheduleSelected,
                iconUnselected: scheduleUnselected,
              },
              {
                title: 'Acompanhamento',
                iconSelected: activeChecklist,
                iconUnselected: inactiveChecklistGray,
              },
            ]}
            renderItem={IconTab}
            currentTab={numTab}
            setCurrentTab={setNumTab}
          />
        </div>
        {renderTab()}
      </div>
      {!isLoading && (
        <div className="relative w-1/2 h-full min-w-[720px] max-w-min">
          <LeafletMap
            calls={[marker]}
            defaultZoom={zoom}
            onChangeZoom={(mapZoom) => setZoom(mapZoom)}
            center={[call?.city?.lat, call?.city?.lng]}
          />
          <div>
            <CardTimeLine callId={id} />
          </div>
        </div>
      )}
    </div>
  );
};

export { SidesheetFinishedCalls };
