import { callBonus, ratingBonus } from 'assets/images/illustrations';
import { Typography } from 'components/Typography';

import { useRewards } from 'queries/financial';
import { BonusCard } from '../BonusCard';

const CompanyBonusPreview = () => {
  const rewards = useRewards();

  return (
    <div className="space-y-4">
      <div>
        <Typography variant="h2" color="darkBlue">
          Gerenciar bonificações
        </Typography>
        <Typography color="darkerGray" variant="p1">
          Área destinada para empresas que possuem seus próprios técnicos
          controlarem o percentual e os valores das bonificações.
        </Typography>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <BonusCard
          isEditable={false}
          title="Bonificação por avaliações"
          icon={ratingBonus}
          condition={() => {
            return (
              <Typography variant="h4" color="darkestGray">
                Se a média das avaliações no mês for no mínimo{' '}
                <b>{rewards.rewards?.ratingRewardAverageRatingNeeded || 0}</b>,
                o técnico receberá{' '}
                <b>{(rewards.rewards?.ratingRewardBonus * 100 || 0) + '%'}</b> a
                mais sobre seu faturamento do mês.
              </Typography>
            );
          }}
        />

        <BonusCard
          isEditable={false}
          title="Bonificação por quantidade de chamados"
          icon={callBonus}
          condition={() => {
            return (
              <Typography variant="h4" color="darkestGray">
                Se a quantidade de chamados no mês for no mínimo{' '}
                <b>{rewards.rewards?.callRewardNumberOfCalls || 0}</b>, o
                técnico receberá{' '}
                <b>{(rewards.rewards?.callRewardBonus * 100 || 0) + '%'}</b> a
                mais sobre seu faturamento do mês.
              </Typography>
            );
          }}
        />
        {/* <BonusCard
          isEditable={false}
          title="Bonificação por indicação de profissionais"
          icon={referIcon}
          condition={() => {
            return (
              <Typography variant="h4" color="darkestGray">
                Se o profissional indicado finalizar o primeiro atendimento com
                sucesso, o técnico responsável pela indicação receberá R$100
              </Typography>
            );
          }}
        /> */}
      </div>
    </div>
  );
};

export { CompanyBonusPreview };
