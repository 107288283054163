import { useMemo, useState } from 'react';
import { Tabs } from 'components/Tabs';
import { IconTab } from 'components/Tabs/IconTab';
import {
  activeChecklist,
  activeServiceOrderGray,
  documentGray,
  inactiveChecklistGray,
  scheduleSelected,
  scheduleUnselected,
} from 'assets/images/icons';
import {
  NotificationsTab,
  ServiceOrderTab,
  FollowupTab,
  ChecklistTab,
} from 'features/Call/components';
import dynamic from 'next/dynamic';
import Spinner from 'components/Spinner';
import { format } from 'date-fns';
import { useCall } from 'queries/call';
import useUser from 'hooks/useUser';
import { ptBR } from 'date-fns/locale';

export interface SidesheetOpenCallsProps {
  id: number;
  statusCall?: string;
  close?: () => void;
}

const SidesheetOpenCalls = ({ id, close }: SidesheetOpenCallsProps) => {
  const [numTab, setNumTab] = useState(0);
  const [zoom, setZoom] = useState(13);
  const { call, isLoading } = useCall(id);
  const { type } = useUser();

  function renderTab() {
    const tabs = {
      0: <ServiceOrderTab close={close} callId={id} />,
      1: <NotificationsTab callId={id} />,
      2:
        type === 'EuNerd' ? (
          <FollowupTab callId={Number(id)} />
        ) : (
          <ChecklistTab callId={id} />
        ),
    };
    return tabs[numTab] || tabs[0];
  }

  const marker = useMemo(() => {
    return {
      id: call?.id,
      lat: call?.address?.lat || call?.city?.lat,
      lng: call?.address?.lng || call?.city?.lng,
      status: call?.status,
      inProgress: true,
      active: true,
      time: call?.startTime
        ? format(new Date(call?.startTime), "dd/MM 'às' HH:mm", {
            locale: ptBR,
          })
        : null,
      type: 'client',
    };
  }, [call]);

  const LeafletMap = useMemo(
    () =>
      dynamic(() => import('components/LeafletMap'), {
        ssr: false,
        loading: () => <Spinner />,
      }),
    []
  );

  return (
    <div className="flex flex-row w-full ">
      <div
        className="flex flex-col w-1/2 min-w-[720px] max-h-screen space-y-4 overflow-y-auto"
        style={{ maxWidth: '720px' }}
      >
        <div className="sticky top-0 w-full px-4 bg-lighterGray z-50">
          <Tabs
            data={[
              {
                title: 'Ordem de serviço',
                iconSelected: activeServiceOrderGray,
                iconUnselected: documentGray,
              },
              {
                title: 'Alertas',
                iconSelected: scheduleSelected,
                iconUnselected: scheduleUnselected,
              },
              {
                title: 'Acompanhamento',
                iconSelected: activeChecklist,
                iconUnselected: inactiveChecklistGray,
              },
            ]}
            currentTab={numTab}
            setCurrentTab={setNumTab}
            renderItem={IconTab}
          />
        </div>
        {renderTab()}
      </div>
      {!isLoading && (
        <div className="relative w-1/2 h-full min-w-[720px] max-w-min">
          <LeafletMap
            calls={[marker]}
            defaultZoom={zoom}
            onChangeZoom={(mapZoom) => setZoom(mapZoom)}
            center={[call?.city?.lat, call?.city?.lng]}
          />
        </div>
      )}
    </div>
  );
};

export { SidesheetOpenCalls };
