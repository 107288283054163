import type {
  RegionType,
  ServicesCerificateType,
} from 'features/Technician/types';
import { useMemo } from 'react';
import { AccordionProfessional } from '../../AccordionProfessional';

type ProfileProfessionalTypes = {
  avatar: string;
  cnpj: string;
  email: string;
  id: string;
  name: string;
  numberOfCalls: number;
  rating: number;
  regions: RegionType[];
  scheduleCallsTotal: number;
  services: ServicesCerificateType[];
  status: string;
  storeParts: boolean;
  telephone: string;
  user: { deviceId: string };
};

interface ListOfAvailableProfessionalsProps {
  dataTechnicians: ProfileProfessionalTypes[];
  search: string;
}

const ListOfAvailableProfessionals = ({
  dataTechnicians,
  search,
}: ListOfAvailableProfessionalsProps) => {
  const filterByname = useMemo(() => {
    return dataTechnicians?.filter((professional) =>
      professional.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [dataTechnicians, search]);

  return (
    <div className="w-full">
      <AccordionProfessional dataTechnicians={filterByname} />
    </div>
  );
};

export { ListOfAvailableProfessionals };
export type { ProfileProfessionalTypes };
