import styled from 'styled-components';

interface StatisticIconProps {
  type: 'unproductive' | 'pending';
}

export const StatisticIcon = styled.div<StatisticIconProps>`
  width: 1rem;
  height: 1rem;
  background: ${({ theme, type }) =>
    type === 'unproductive' ? theme.colors.darkGray : theme.colors.yellow};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 8px;
  height: 8px;
`;
