/* eslint-disable @next/next/no-img-element */
import {
  shoppingCartGreen,
  shoppingCartDisabled,
} from 'assets/images/slaIcons';
import { TooltipButton } from 'components/TooltipButton';

interface ShoppingCartProps {
  active?: boolean;
}

const ShoppingCart = ({ active = false }: ShoppingCartProps) => {
  return (
    <div className="rounded-4 flex items-center justify-center w-8 h-12">
      <TooltipButton description={active ? 'Possui compras' : 'Sem compras'}>
        <img
          src={active ? shoppingCartGreen : shoppingCartDisabled}
          alt="Icone carrinho de compras"
        />
      </TooltipButton>
    </div>
  );
};

export { ShoppingCart };
