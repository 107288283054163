import { TabButtonStatus } from 'components/TabButtonStatus';

interface TabsTechniciansProps {
  children: React.ReactNode;
  onChange: (tab: number) => void;
}

const TabsTechnicians = ({ children, onChange }: TabsTechniciansProps) => {
  const statusTabs = [
    {
      tabId: 0,
      title: 'Técnicos Ativos',
    },
    {
      tabId: 1,
      title: 'Triagem de Técnicos',
    },
    {
      tabId: 2,
      title: 'Técnicos Inativos',
    },
  ];

  return (
    <>
      <div className="gap-6 mb-6 space-x-6 space-y-2">
        <TabButtonStatus
          data={statusTabs}
          defaultSelected={0}
          onClick={onChange}
        />
      </div>
      {children}
    </>
  );
};

export { TabsTechnicians };
