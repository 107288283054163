/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useServicesByCompany } from 'queries/services';
import { useFormikContext } from 'formik';
import { useCompany } from 'queries/clients';
import { Typography } from 'components/Typography';
import { Select } from 'components/Select';
import { SelectSearch } from 'components/SelectSearch';
import { ascendingSort } from 'utils/sortUtils';
import { ValidateSlaPeriod } from '../aboutCallUtil';
import { TimePicker } from 'components/TimePicker';
import { CallValue } from './CallValue';
import { formatCurrency } from 'utils/formatCurrency';
import { Alert } from 'components/AlertComponents/Alert';
import { WhenCallSummary } from '../../WhenCallSummary';
import { WhenCallSelect } from '../../WhenCallSelect';
import { formatUTCHour } from 'utils/formatUtils';

export const optionsService = [
  {
    label: 'Por Chamado',
    value: 'PER_CALL',
  },
  {
    label: 'Hora',
    value: 'HOUR',
  },
];

export type CallCostInformationTypes = {
  costPerHour: number;
  costPerCall: number;
  service: {
    id: string;
    name: string;
    description: string;
  };
  expectedTime: number;
  formsOfService: string[];
  serviceTypeId: string;
};

interface FormAboutTheCallProps {
  isNewTemplate?: boolean;
  setDisabled?: (value: boolean) => void;
}

const FormAboutTheCall = ({
  isNewTemplate = false,
  setDisabled,
}: FormAboutTheCallProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { services } = useServicesByCompany(values.companyId);
  const { company } = useCompany(values.companyId);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [slaIsAppliedMessage, setSlaIsAppliedMessage] = useState(false);
  const [infoCostCall, setInfoCostCall] = useState<CallCostInformationTypes>({
    costPerHour: 0,
    costPerCall: 0,
    service: {
      id: '',
      name: '',
      description: '',
    },
    expectedTime: 0,
    formsOfService: [],
    serviceTypeId: '',
  });

  const initialTime = values?.time?.firstTime < 8;
  const finalTime = values?.time?.firstTime >= 18;

  const changeScheduledToUrgent =
    (values?.type === 'Scheduled' && initialTime) || finalTime;

  const outsideBusinessHours =
    ((values?.type === 'Urgent' && initialTime) || finalTime) &&
    !(values?.time?.firstTime >= 8 && values?.time?.firstTime < 18);

  const typesTheService = services?.serviceType
    ?.map((i) => i.formsOfService)
    .flat()
    .filter((v, i, a) => a.indexOf(v) == i);

  const listServicesForTypeSelected = services?.serviceType?.filter((i) =>
    i.formsOfService.includes(values?.serviceType)
  );

  const onChangeDatesSelected = (dates: Date[]) => {
    setSelectedDays(ascendingSort(dates));
    setFieldValue('startTime', ascendingSort(dates));
  };

  const onChangeServiceTypeSelected = useCallback(
    ({
      costPerCall,
      costPerHour,
      expectedTime,
      formsOfService,
      service,
      serviceTypeId,
    }: CallCostInformationTypes) => {
      setFieldValue('serviceId', service.id);
      setInfoCostCall({
        costPerCall,
        costPerHour,
        expectedTime,
        formsOfService,
        service,
        serviceTypeId,
      });
    },
    [setFieldValue]
  );

  const calculateCostPerTypeCall = useMemo(() => {
    let totalValue = 0;

    if (values.serviceType === 'HOUR') {
      totalValue =
        infoCostCall.expectedTime *
        infoCostCall.costPerHour *
        selectedDays.length;
    } else {
      totalValue = infoCostCall.costPerCall * selectedDays.length;
    }

    return totalValue;
  }, [infoCostCall, values.serviceType, selectedDays]);

  const totalCallValue = useMemo(() => {
    if (values.type === 'Urgent') {
      return (
        formatCurrency(
          calculateCostPerTypeCall *
          (1 + (company?.currentSla?.urgentCostPerCall || 0) / 100)
        )
      );
    } else {
      return formatCurrency(calculateCostPerTypeCall);
    }
  }, [
    calculateCostPerTypeCall,
    company?.currentSla?.urgentCostPerCall,
    values.type,
  ]);

  useEffect(() => {
    if (calculateCostPerTypeCall > 0) {
      setFieldValue('totalCallValue', totalCallValue);
    }
  }, [calculateCostPerTypeCall, setFieldValue, totalCallValue]);

  const onChangeTypeCall = useCallback(
    (item) => {
      setFieldValue('serviceType', item.value);
      setFieldValue('serviceId', '');
    },
    [setFieldValue]
  );

  const handleOnRemoveService = useCallback(() => {
    setFieldValue('technicianId', []);
    setFieldValue('serviceId', '');
  }, [setFieldValue]);

  const slaIsApplied = useMemo(() => {
    const hourCall = values?.startTime?.map((s: string | number | Date) => {
      s = new Date(s).setUTCHours(values?.time?.firstTime);

      return new Date(formatUTCHour(s));
    });

    const check = ValidateSlaPeriod({
      slaStartCalls: company?.currentSla?.startCalls,
      type: values.type,
      hourCall: hourCall,
    });

    return check;
  }, [
    values?.startTime,
    values.type,
    values?.time?.firstTime,
    company?.currentSla?.startCalls,
  ]);

  const expectedTimeCall = infoCostCall.expectedTime * selectedDays.length;
  const verifySla = slaIsApplied && values?.time?.firstTime;

  useEffect(() => {
    if (values?.type === 'Scheduled') {
      setSlaIsAppliedMessage(false);
    }
    if (changeScheduledToUrgent) {
      setFieldValue('type', 'Urgent');
    }
  }, [
    values,
    changeScheduledToUrgent,
    slaIsApplied,
    setDisabled,
    setFieldValue,
  ]);

  useEffect(() => {
    if (verifySla) {
      setSlaIsAppliedMessage(true);
      setFieldValue('type', 'Urgent');
      setDisabled(true);
    } else {
      setSlaIsAppliedMessage(false);
    }

    if (!verifySla && !changeScheduledToUrgent) {
      setDisabled(false);
    }

    if (outsideBusinessHours) {
      setDisabled(true);
    }
  }, [
    setDisabled,
    verifySla,
    setFieldValue,
    changeScheduledToUrgent,
    outsideBusinessHours,
  ]);

  return (
    <div className="flex flex-col w-full space-y-4">
      {outsideBusinessHours && (
        <Alert type="danger" variant="colored">
          <p className="font-medium">
            Chamado urgente fora do horário comercial, por se tratar de um
            atendimento fora do horário comercial, podemos levar mais tempo que
            o habitual para encontrar um profissional.
          </p>
        </Alert>
      )}
      <div className="flex flex-row space-x-6">
        <div className="flex flex-col w-1/3">
          <Typography variant="p1" color="darkerGray">
            Forma de atendimento
          </Typography>
          <Select
            options={optionsService.filter((i) =>
              typesTheService?.includes(i.value)
            )}
            getOptionValue={(e) => onChangeTypeCall(e)}
            serviceTypeSelected={values.serviceType}
            value={values.serviceType}
          />
        </div>
        <div className="flex flex-col w-2/3">
          <Typography variant="p1" color="darkerGray">
            Tipo de serviço
          </Typography>
          {services ? (
            <SelectSearch
              data={
                listServicesForTypeSelected?.map((i) => ({
                  ...i.service,
                  costPerHour: i.costPerHour,
                  costPerCall: i.costPerCall,
                  service: {
                    id: i.service.id,
                    name: i.service.name,
                    description: i.service.description,
                  },
                  expectedTime: i.expectedTime,
                  formsOfService: i.formsOfService,
                  serviceTypeId: i.id,
                })) || []
              }
              getItemSelected={onChangeServiceTypeSelected as any}
              serviceIdSelected={values.serviceId}
              value={values.serviceId}
              onRemove={handleOnRemoveService}
            />
          ) : null}
        </div>
      </div>

      {!isNewTemplate && (
        <div className="flex flex-row space-x-6">
          <div>
            <Typography variant="p1" color="darkerGray">
              Quando
            </Typography>
            <WhenCallSelect
              onChange={(e) => onChangeDatesSelected(e)}
              getMonth={(month) => month}
            />
          </div>
          <div>
            <Typography variant="p1" color="darkerGray">
              A partir de
            </Typography>
            <TimePicker onChange={(e) => setFieldValue('time', e)} />
          </div>
        </div>
      )}

      {slaIsAppliedMessage ? (
        <div className="py-3">
          <Alert type="danger" variant="colored">
            <p className="text-base font-medium">Chamado fora do prazo!</p>
            <p>
              O horário do chamado não correspondente ao seu SLA. O chamado foi
              atualizado automaticamente para o modo Urgente.
            </p>
          </Alert>
        </div>
      ) : null}

      {values?.startTime?.length > 1 && (
        <Alert title="Repetir chamado" type="warning">
          <p className="text-base font-medium pb-2">
            Atendimentos de múltiplos dias
          </p>
          <p>
            Defina as datas de sua preferência selecionando os dias em que devem
            acontecer. Atendimentos de múltiplos dias devem ocorrer
            obrigatoriamente no mesmo horário.
          </p>
        </Alert>
      )}

      {selectedDays.length > 0 && values.serviceId && (
        <WhenCallSummary
          formsOfService={infoCostCall.formsOfService}
          days={selectedDays}
          timeDiff={
            infoCostCall.formsOfService.includes('PER_CALL')
              ? ''
              : expectedTimeCall.toString() +
              (expectedTimeCall > 1
                ? ' horas de trabalho'
                : ' hora de trabalho')
          }
        />
      )}

      {calculateCostPerTypeCall > 0 && values.serviceType && (
        <>
          <CallValue valueCall={totalCallValue} />
          <Alert title="Deslocamento" type="warning">
            <p className="text-base font-medium pb-2">
              Deslocamento do profissional
            </p>
            <p>
              Valores e regras de deslocamento quando constem contratualmente
              podem ser aplicados.
            </p>
          </Alert>
        </>
      )}
    </div>
  );
};

export { FormAboutTheCall };
