import clsx from 'clsx';

interface ButtonUnderlineAndBadgesProps {
  name: string;
  current: boolean;
  count?: number;
  onClick: () => void;
}

const ButtonUnderlineAndBadges = ({
  name,
  current,
  count,
  onClick,
}: ButtonUnderlineAndBadgesProps) => {
  return (
    <div className="border-b border-lightGray">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        <button
          type="button"
          onClick={onClick}
          key={name}
          className={clsx(
            current
              ? 'border-purple text-purpleHover font-bold'
              : 'border-transparent text-gray-500 hover:text-darkerGray font-medium hover:border-lightGray',
            'whitespace-nowrap flex py-4 px-1 border-b-2 text-sm'
          )}
        >
          {name}
          {count >= 0 ? (
            <span
              className={clsx(
                current
                  ? 'bg-gray text-purpleHover'
                  : 'bg-gray text-darkerGray',
                'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
              )}
            >
              {count}
            </span>
          ) : null}
        </button>
      </nav>
    </div>
  );
};

export { ButtonUnderlineAndBadges };
