export function removeSpecialChars(value: string) {
  return value
    .replace(/[|&;$%@"<>()+,/]/g, '')
    .replace(/_+/, '_')
    .replace(/\s/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export function validateEmail(email: string) {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return re.test(String(email).toLowerCase());
}

export function validateCpf(cpf: string) {
  if (cpf) {
    let sum = 0;
    const formattedCPF = cpf.replaceAll('-', '').replaceAll('.', '');
    if (
      !formattedCPF ||
      formattedCPF === '00000000000' ||
      formattedCPF === '11111111111' ||
      formattedCPF === '22222222222' ||
      formattedCPF === '33333333333' ||
      formattedCPF === '44444444444' ||
      formattedCPF === '55555555555' ||
      formattedCPF === '66666666666' ||
      formattedCPF === '77777777777' ||
      formattedCPF === '88888888888' ||
      formattedCPF === '99999999999'
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      sum += Number(formattedCPF.substring(i - 1, i)) * (11 - i);
    }
    let leftOver = (sum * 10) % 11;

    if (leftOver === 10 || leftOver === 11) leftOver = 0;
    if (leftOver !== Number(formattedCPF.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += Number(formattedCPF.substring(i - 1, i)) * (12 - i);
    }
    leftOver = (sum * 10) % 11;

    if (leftOver === 10 || leftOver === 11) leftOver = 0;
    if (leftOver !== Number(formattedCPF.substring(10, 11))) return false;
    return true;
  } else {
    return false;
  }
}

export function validateCNPJ(cnpj) {
  if (cnpj) {
    let formattedCNPJ = cnpj.replace(/[^\d]+/g, '');

    if (formattedCNPJ == '') return false;

    if (formattedCNPJ.length != 14) return false;

    if (
      formattedCNPJ == '00000000000000' ||
      formattedCNPJ == '11111111111111' ||
      formattedCNPJ == '22222222222222' ||
      formattedCNPJ == '33333333333333' ||
      formattedCNPJ == '44444444444444' ||
      formattedCNPJ == '55555555555555' ||
      formattedCNPJ == '66666666666666' ||
      formattedCNPJ == '77777777777777' ||
      formattedCNPJ == '88888888888888' ||
      formattedCNPJ == '99999999999999'
    )
      return false;

    let size = formattedCNPJ.length - 2;
    let numbers = formattedCNPJ.substring(0, size);
    let digits = formattedCNPJ.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(0)) return false;

    size = size + 1;
    numbers = formattedCNPJ.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(1)) return false;

    return true;
  } else {
    return false;
  }
}

export function validateBirthDate(birthDate) {
  try {
    const today = new Date();
    const bDate = new Date(birthDate);
    let age = today.getFullYear() - bDate.getFullYear();
    const m = today.getMonth() - bDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < bDate.getDate())) age--;

    if (age < 18) {
      return false;
    }

    if (age >= 18 && age <= 80) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}
