import Spinner from 'components/Spinner';
import { BigNumber } from 'components/BigNumber';

const BigNumberContainer = (props) => {
  const { isLoading, isError, isHighlighted } = props;
  function renderData() {
    if (isLoading) {
      return (
        <BigNumber
          value={
            <div className="flex mb-2">
              <Spinner />
            </div>
          }
          {...props}
        />
      );
    }
    if (isError) {
      return <BigNumber value={'-'} {...props} />;
    }
    return <BigNumber value={isHighlighted ?? '-'} {...props} />;
  }
  return renderData();
};

export { BigNumberContainer };
