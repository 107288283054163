import styled from 'styled-components';

const borderColor = ({ focused, theme: { colors } }) => {
  return focused ? colors.purple : colors.lightGray;
};

export const Container = styled.div<{ focused: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 2px solid ${borderColor};
  border-radius: 16px;
  padding: 4px 16px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  transform: border-color 0.2s;
  &:hover,
  &:active {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }

  position: relative;
  user-select: none;
`;

export const Input = styled.input`
  flex: 1;
  font-size: ${(props) => props.theme.typography.p1.fontSize};
  font-weight: ${(props) => props.theme.typography.p1.fontWeight};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

export const Image = styled.img`
  width: 16px;
  height: 16px;
`;

export const ListContainer = styled.div<{ itemCount: number }>`
  height: ${(props) =>
    props.itemCount > 3 ? '184px' : 48 * props.itemCount + 'px'};
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.lighterGray};
  }
  ::-webkit-scrollbar {
    width: 3px;
    background: ${(props) => props.theme.colors.lighterGray};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.darkGray};
  }
  /* display: flex;
  position: absolute;
  z-index: 1;

  list-style: none;
  padding: 16px;
  top: 64px;
  margin-left: -15px;

  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};

  & li {
    width: 160px;
  } */
`;

export const SearchBarContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 12px 0px;
`;
