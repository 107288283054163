/* eslint-disable @next/next/no-img-element */
import { noCustomers } from "assets/images/illustrations"
import { Typography } from "components/Typography"
import { CardTraining } from "./CardTraining"
import { v4 } from "uuid"


const CompanyTrainings = ({ trainings }) => {
  return (
    <div className="flex flex-col flex-1 px-16 py-6 space-y-8">
      <Typography variant="h2" color="darkBlue">
        Treinamentos
      </Typography>

      {trainings?.length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          {trainings?.map((t) => (
            <CardTraining
              key={v4()} item={t}
            />))}
        </div>
      ) :
        (
          <div className="flex flex-col items-center space-y-4">
            <img src={noCustomers} alt="imagem que representa falta de informações" />
            <Typography variant="h4">
              Empresa não possui treinamentos cadastrados
            </Typography >
          </div>
        )
      }
    </div>
  )
}

export { CompanyTrainings }