import { Media } from './media';

export default async function uploadAttachments(files: File[]) {
  const uploadedFiles = await Promise.all(
    files.map(async (file) => {
      const attachment = await Media.upload(file);
      return attachment;
    })
  );

  return uploadedFiles || [];
}
