export const categories = [
  {
    id: '3',
    value: 'NETWORK_AND_PERIPHERAL_INFRASTRUCTURE',
    label: 'Rede e Infraestrutura Periférica',
  },
  {
    id: '2',
    value: 'MICROINFORMATICS',
    label: 'Micro Informática',
  },
  {
    id: '4',
    value: 'REMOTE',
    label: 'Controle Remoto',
  },
  {
    id: '1',
    value: 'EXTRAORDINARY_SERVICES',
    label: 'Serviços Extraordinários',
  },
  {
    id: '5',
    value: 'SERVERS',
    label: 'Servidores',
  },
];
