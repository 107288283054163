import { AlertLabel } from 'components/AlertComponents/AlertLabel';
import { Typography } from 'components/Typography';
import React from 'react';

import { Container } from './styles';

interface ReviewStatusProps {
  status: 'warning' | 'success';
  icon: string;
  description: string;
}

export const ReviewStatus: React.FC<ReviewStatusProps> = ({ description, status, icon }) => {
  return (
    <Container>
      <Typography variant="p2" color="darkestGray">
        Finalizado como:
      </Typography>
      <AlertLabel icon={icon} variant={status}>
        {description}
      </AlertLabel>
    </Container>
  );
}
