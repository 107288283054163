import styled from 'styled-components';
import { SizeType } from '.';

interface ContainerProps {
  size: SizeType;
  selected: boolean;
  color: string;
  outline: boolean;
}

interface ImageContainerProps {
  color: string
}

const getImageBackgroundColor = ({ color, theme: { colors } }) => {
  return colors[color];
};

const getContainerBackgroundColor = ({ color, outline, theme: { colors } }) => {
  if (outline) {
    return colors.lightGray;
  }

  return colors[color];
};

const getLength = ({ size }) => {
  switch (size) {
    case 'small':
      return '24px';
    case 'medium':
      return '32px';
    case 'large':
      return '40px';
  }
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getContainerBackgroundColor};
  border-radius: 4px;
  inner-border-radius: 4px;
  width: ${getLength};
  height: ${getLength};
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${getImageBackgroundColor};
  min-width: 16px;
  min-height: 16px;
`;
