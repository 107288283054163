import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { ModalTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { useTechnician } from 'queries/technician';
import { Technicians } from 'services/technician';
import { Button } from 'components/Button';

const FooterSidesheetTechnicianScreening = ({
  id,
  setIsRefusing,
  onAfterUpdate,
}) => {
  const UI = useUI();
  const queryClient = useQueryClient();
  const { technician, refetch } = useTechnician(id);
  const [loading, setLoading] = useState(false);

  async function handleApprove() {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        title: 'Aprovar Técnico',
        description: 'Tem certeza que deseja aprovar este técnico?',
        cancelText: 'Não',
        confirmText: 'Sim, Aprovar técnico',
        //   icon: <img src={chamado} />,
        cancelRed: true,
        onConfirm: async () => {
          try {
            setLoading(true);
            await Technicians.approve(technician?.id);

            await refetch();
            queryClient.refetchQueries(technician);

            UI.operations.showToast('Técnico aprovado com sucesso.');
            await onAfterUpdate?.();
          } catch (err) {
            UI.operations.showToast('Falha ao aprovar técnico.', {
              type: 'error',
            });
          } finally {
            setLoading(false);
          }
        },
      },
    });
  }

  return (
    <div className="flex justify-between w-full px-8 py-5 border-t-2 justify-self-end bg-lighterGray border-gray">
      <div className="flex justify-end w-full">
        <div className="flex items-center justify-center space-x-4">
          <Button
            type="button"
            size="large"
            color="purple"
            onClick={handleApprove}
            loading={loading}
            disabled={
              !technician?.cnpj ||
              technician?.status === 'WAITING_FOR_ACTIVATION' ||
              technician?.status === 'REFUSED'
            }
          >
            Aprovar profissional
          </Button>
          <Button
            type="button"
            size="large"
            color="orange"
            loading={loading}
            onClick={() => setIsRefusing(true)}
            disabled={
              technician?.status === 'WAITING_FOR_ACTIVATION' ||
              technician?.status === 'REFUSED'
            }
          >
            Recusar Profissional
          </Button>
        </div>
      </div>
    </div>
  );
};

export { FooterSidesheetTechnicianScreening };
