import { useQuery } from 'react-query';
import { Financial } from 'services/financial';

interface MonthAndYear {
  month: string;
  year: string;
}

interface TwoPeriodsDate {
  first: {
    month: number;
    year: number;
  };
  second: {
    month: number;
    year: number;
  };
}

export function useTotalNetIncome() {
  const { data: netIncomeData } = useQuery(
    ['/financial/total-net-income'],
    () => Financial.getTotalNetIncome()
  );
  return { netIncomeData };
}

export function useTotalGrossIncome(data?: TwoPeriodsDate) {
  const { data: totalGrossIncome } = useQuery(
    [`/financial/total-gross-income-by-period`, data],
    () => Financial.getTotalGrossIncome(data)
  );
  return totalGrossIncome;
}

export function useTotalTechnicinCost(data?: TwoPeriodsDate) {
  const { data: totalTechnicinCost } = useQuery(
    [`/financial/total-technician-cost`, data],
    () => Financial.getTotalTechnicinCost(data)
  );
  return totalTechnicinCost;
}

export function useTotalProfit(data?: TwoPeriodsDate) {
  const { data: profit } = useQuery([`/financial/profit`, data], () =>
    Financial.getTotalProfit(data)
  );
  return profit;
}

export function useTotalCalls(data?: TwoPeriodsDate) {
  const { data: totalCalls } = useQuery(['/financial/total-calls', data], () =>
    Financial.getTotalCalls(data)
  );
  return totalCalls;
}

export function useTotalCallsCompany(id, data?: TwoPeriodsDate) {
  const { data: totalCallsCompany } = useQuery(
    [`/financial/total-calls/${id}`, data],
    () => Financial.getTotalCallsCompany(id, data)
  );
  return totalCallsCompany;
}

export function useCallHistory(data: MonthAndYear) {
  const { data: callHistory, error } = useQuery(
    [`/financial/call-history`, data],
    () => Financial.getCallHistory(data)
  );
  return {
    data: callHistory,
    isLoading: !error && !callHistory,
    isError: error,
  };
}

export function usePaymentHistory(data: MonthAndYear) {
  const { data: paymentHistory, error } = useQuery(
    [`/financial/payment-history`, data],
    () => Financial.getPaymentHistory(data)
  );
  return {
    data: paymentHistory,
    isLoading: !error && !paymentHistory,
    isError: error,
  };
}

export function useFinancialHistory(id, data?: MonthAndYear) {
  const { data: financialHistory } = useQuery(
    [`/financial/financial-history/${id}`, data],
    () => Financial.getFinancialHistory(id, data)
  );
  return financialHistory;
}

export function useTotalCallsSixMonths(id, data?: MonthAndYear) {
  const { data: totalCallsSixMonths } = useQuery(
    [`/financial/total-calls-six-months/${id}`, data],
    () => Financial.getTotalCallsSixMonths(id, data)
  );
  return totalCallsSixMonths;
}

export function useSpendCompany(id, data?: TwoPeriodsDate) {
  const { data: spendData } = useQuery([`/financial/spend/${id}`, data], () =>
    Financial.getSpendCompany(id, data)
  );
  return { spendData };
}

export function useSpendSixMonths(id, data?: MonthAndYear) {
  const { data: spendSixMonths } = useQuery(
    [`/financial/spend-per-six-months/${id}`, data],
    () => Financial.getSpendSixMonths(id, data)
  );
  return { spendSixMonths };
}

export function useMonthlyCostEvolution(id) {
  const { data: monthlyCostEvolution } = useQuery(
    [`/financial/monthly-cost-evolution/${id}`],
    () => Financial.getMonthlyCostEvolution(id)
  );
  return { monthlyCostEvolution };
}

export function useUnproductiveSpend(id) {
  const { data: unproductiveSpend } = useQuery(
    [`/financial/unproductive-spend/${id}`],
    () => Financial.getUnproductiveSpend(id)
  );
  return { unproductiveSpend };
}

export function useRewards() {
  const { data: rewards } = useQuery([`/rewards/`], () =>
    Financial.getRewards()
  );
  return { rewards };
}

export function useHistoryRewards(data?: MonthAndYear) {
  const { data: historyRewards } = useQuery([`/rewards/history`, data], () =>
    Financial.getHistoryRewards(data)
  );
  return { historyRewards };
}
