import { specialChars } from './specialChars';

export function whatsAppMessageToTechnician(technician, callId: number) {
  const phone: string = specialChars(technician?.telephone);
  const responsible = technician?.name;
  return window.open(
    `https://api.whatsapp.com/send?phone=${
      phone.substring(0, 2) === '55' ? `+${phone}` : `+55${phone}`
    }&text=Olá${
      responsible ? `, ${responsible}` : ''
    }! Sou da operação do Guio e responsável direto pelo atendimento do chamado ${callId}.`,
    '_blank'
  );
}

export function whatsAppMessageToCompany(company, callId: number) {
  const phone: string = specialChars(company?.responsible?.phone[0]?.phone);
  const responsible = company?.responsible?.name;
  return window.open(
    `https://api.whatsapp.com/send?phone=${
      phone.substring(0, 2) === '55' ? `+${phone}` : `+55${phone}`
    }&text=Olá${
      responsible ? `, ${responsible}` : ''
    }! Sou da operação do Guio e responsável direto pelo atendimento do chamado ${callId}.`,
    '_blank'
  );
}

export function whatsAppMessageToTechnicianProfile(technician) {
  const phone: string = specialChars(technician?.telephone);
  const responsible = technician?.name;
  return window.open(
    `https://api.whatsapp.com/send?phone=${
      phone.substring(0, 2) === '55' ? `+${phone}` : `+55${phone}`
    }&text=Olá${
      responsible ? `, ${responsible}` : ''
    }! Sou da operação do Guio. Tudo bem com você?`,
    '_blank'
  );
}
