import {
  bike,
  bus,
  car,
  motorcycle,
  walking,
} from 'assets/images/illustrations';

export const DISPLACEMENTS = [
  {
    value: 'WALKING',
    label: 'Caminhando',
    icon: walking,
  },
  {
    value: 'BIKE',
    label: 'Bicicleta',
    icon: bike,
  },
  {
    value: 'MOTORCYCLE',
    label: 'Moto',
    icon: motorcycle,
  },
  {
    value: 'CAR',
    label: 'Carro',
    icon: car,
  },
  {
    value: 'OTHERS',
    label: 'Ônibus, metrô ou apps',
    icon: bus,
  },
];

export enum DISPLACEMENT_TRANSLATE {
  WALKING = 'Caminhando',
  BIKE = 'Bicicleta',
  MOTORCYCLE = 'Moto',
  CAR = 'Carro',
  OTHERS = 'Ônibus, metrô ou apps',
}

export const translateDisplacement = (displacement: string[]) => {
  if (!displacement) {
    return 'Não informado!';
  }

  const displacementFormatted = displacement.map((e) => {
    if (DISPLACEMENT_TRANSLATE[e.toUpperCase()]) {
      return DISPLACEMENT_TRANSLATE[e.toUpperCase()];
    }
  });

  if (displacementFormatted.length === 0) {
    return 'Método de deslocamento não encontrado!';
  }

  return displacementFormatted.join(', ');
};
