import { useState } from 'react';
import { SearchBarAutocomplete } from 'components/SearchBarAutocomplete';
import { TabsUnderlineAndBadges } from 'components/TabsUnderlineAndBadges';
import { CreateCallProps } from 'types/call';
import { useFormikContext } from 'formik';
import { Typography } from 'components/Typography';
import { Alert } from 'components/AlertComponents/Alert';
import {
  useTechnicianListUnavailableShedule,
  useTechnicianStatus,
} from 'queries/technician';
import { ListUnavailableProfessionals } from './ListUnavailableProfessionals';
import { ListOfAvailableProfessionals } from './ListOfAvailableProfessionals';
import { ListOtherProfessionals } from './ListOtherProfessionals';

const ListProfessionals = () => {
  const [numTab, setNumTab] = useState(0);
  const { values } = useFormikContext<CreateCallProps>();
  const [searchText, setSearchText] = useState('');

  const hourCallISO =
    values?.startTime && values.time?.firstTime
      ? values?.startTime?.map((s: string | number | Date) => {
          s = new Date(s).setUTCHours(values.time?.firstTime);
          return new Date(s)?.toISOString();
        })
      : [];

  const filtersCall = {
    status: ['AVAILABLE'],
    services: [values.serviceId],
    regions: [values.address.city],
    state: [values.address.state],
    startTime: [hourCallISO],
    serviceType: [values.serviceType],
    ...(values.type === 'Urgent' && { urgentCalls: true }),
  };

  const filtersOtherCall = {
    status: ['AVAILABLE'],
    regions: [values.address.city],
    state: [values?.address?.state],
    startTime: [hourCallISO],
    serviceType: [values.serviceType],
    ...(values.type === 'Urgent' && { urgentCalls: true }),
  };

  const itIsValidToGet =
    values.serviceId !== '' &&
    values.address.city !== '' &&
    values.serviceType !== ('PER_HOUR ' || 'PER_CALL') &&
    values.startTime.length > 0 &&
    values.time !== null;

  const { technicians } = useTechnicianStatus({
    filters: filtersCall,
    enabled: itIsValidToGet,
  });
  const { techniciansUnavailable } = useTechnicianListUnavailableShedule({
    filters: filtersCall,
    enabled: itIsValidToGet,
  });

  const { technicians: techniciansOther } = useTechnicianStatus({
    filters: filtersOtherCall,
    enabled: itIsValidToGet,
  });

  const uniqueOtherTechnicians = techniciansOther?.technicians?.filter(
    (otherTech) =>
      !technicians?.technicians?.some((tech) => tech.id === otherTech.id)
  );

  const tabsData = [
    {
      tabId: 0,
      name: 'Disponíveis',
      count: technicians?.technicians?.length,
    },
    {
      tabId: 1,
      name: 'Indisponíveis',
      count: techniciansUnavailable?.technicians?.length,
    },
    {
      tabId: 2,
      name: 'Outros',
      count: uniqueOtherTechnicians?.length,
    },
  ];

  function renderTabSelected() {
    const tabs = {
      0: (
        <ListOfAvailableProfessionals
          search={searchText}
          dataTechnicians={technicians?.technicians}
        />
      ),
      1: (
        <ListUnavailableProfessionals
          search={searchText}
          dataTechnicians={techniciansUnavailable?.technicians}
        />
      ),
      2: (
        <ListOtherProfessionals
          search={searchText}
          dataTechnicians={uniqueOtherTechnicians}
        />
      ),
    };
    return tabs[numTab] || tabs[0];
  }

  return (
    <div className="flex flex-col">
      <SearchBarAutocomplete
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder="Buscar profissional"
      />
      <TabsUnderlineAndBadges
        tabsData={tabsData}
        onChange={(tab) => {
          setNumTab(tab);
        }}
      >
        {itIsValidToGet ? (
          renderTabSelected()
        ) : (
          <div className="py-4">
            <Alert type="info">
              <Typography variant="p1" color="darkerGray">
                Para visualizar os profissionais, é necessário selecionar um
                serviço, uma data e um local.
              </Typography>
            </Alert>
          </div>
        )}
      </TabsUnderlineAndBadges>
    </div>
  );
};

export { ListProfessionals };
