import React from 'react';

const VerticalRule = ({ height = 60 }) => {
  return (
    <div
      style={{
        height: '108px',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          borderLeft: '2px solid #F2F2F2',
          height: height,
        }}
      />
    </div>
  );
};

export default VerticalRule;
