import { Pagination, usePaginationData } from 'components/Pagination';
import { TableContainer } from 'components/Tables';
import { AddressesNotFound } from 'components/Tables/States/AddressesNotFound';
import { AddressesError } from 'components/Tables/States/AdressesError';
import { useMemo, useState } from 'react';

const TableCompanyAddresses = ({
  searchText,
  handleOpenSidesheet,
  listAddress,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      Header: 'Apelido',
      accessor: 'alias',
    },
    {
      Header: 'CEP',
      accessor: 'cep',
    },
    {
      Header: 'Cidade',
      accessor: 'city',
    },
    {
      Header: 'Estado',
      accessor: 'state',
    },
    {
      Header: 'Endereço',
      accessor: 'address',
    },
    {
      Header: 'Número',
      accessor: 'number',
    },
    {
      Header: 'Bairro',
      accessor: 'district',
    },
  ];

  const filterSearchText = useMemo(() => {
    return listAddress?.filter(
      (c) =>
        c.alias?.toLowerCase().includes(searchText.toLowerCase()) ||
        c.cep?.toLowerCase().includes(searchText.toLowerCase()) ||
        c.city?.toLowerCase().includes(searchText.toLowerCase()) ||
        c.state?.toLowerCase().includes(searchText.toLowerCase()) ||
        c.address?.toLowerCase().includes(searchText.toLowerCase()) ||
        c.district?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, listAddress]);

  const dataPagination = usePaginationData({
    objectData: filterSearchText,
    currentPage,
  });

  return (
    <>
      <TableContainer
        columns={columns}
        data={dataPagination}
        error={false}
        loading={false}
        ErrorComponent={AddressesError}
        NotFoundComponent={AddressesNotFound}
        onRowClick={handleOpenSidesheet}
      />
      <div className="pt-2">
        <Pagination
          currentPage={currentPage}
          totalCount={filterSearchText?.length}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export { TableCompanyAddresses };
