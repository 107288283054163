import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { confirm } from 'assets/images/illustrations';

import { Subtitle } from './styles';

export interface ConfirmCreateCallModalProps {
  close: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

const ConfirmCreateCallModal = ({
  close,
  onConfirm,
  isLoading,
}: ConfirmCreateCallModalProps) => {
  return (
    <div className="flex flex-col items-center max-w-xl text-center">
      <img src={confirm} alt="icon confirm" />
      <Typography variant="h2" color="darkBlue" className="mb-8">
        Confirmar novo chamado
      </Typography>
      <Subtitle variant="h4" color="darkBlue" className="mb-8">
        Você está confirmando a abertura de um novo chamado. Após confirmação,
        não será possível editar as informações deste chamado.
      </Subtitle>
      <div className="flex flex-row gap-6">
        <Button
          variant="outlined"
          color="orange"
          width="200"
          size="large"
          onClick={close}
        >
          Não, voltar
        </Button>
        <Button
          variant="outlined"
          color="purple"
          width="200"
          size="large"
          onClick={() => onConfirm()}
          disabled={isLoading}
        >
          Confirmar
        </Button>
      </div>
    </div>
  );
};

export { ConfirmCreateCallModal };
