import { useMemo } from 'react';
import { AccordionProfessional } from '../../AccordionProfessional';
import { ProfileProfessionalTypes } from '../ListOfAvailableProfessionals';

interface ListUnavailableProfessionals {
  dataTechnicians: ProfileProfessionalTypes[];
  search: string;
}

const ListUnavailableProfessionals = ({
  dataTechnicians,
  search,
}: ListUnavailableProfessionals) => {
  const filterByname = useMemo(() => {
    return dataTechnicians?.filter((professional) =>
      professional.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [dataTechnicians, search]);

  return (
    <div className="w-full">
      <AccordionProfessional dataTechnicians={filterByname} isDisabled={true} />
    </div>
  );
};

export { ListUnavailableProfessionals };
