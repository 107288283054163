import styled from 'styled-components';

export const Marker = styled.button`
  position: absolute;

  width: 62px;
  height: 70px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &:active,
  &:focus {
    outline: 0;
  }
`;

export const Avatar = styled.div`
  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 7px;
  width: 58px;
  height: 58px;

  z-index: -1;
`;

export const StatusBar = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  padding: 0 14px;
  top: 33px;
  margin-top: 7.5px;

  width: 222px;
  height: 44px;
  background: ${({ theme }) => theme.colors.lighterGray};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 999;
`;

export const StatusBarText = styled.p`
  ${({ theme }) => theme.typography.button};
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 24px;
  margin: 0 8px;
`;

export const Container = styled.div`
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
