import { Typography } from 'components/Typography';
import { useFormikContext } from 'formik';
import { SummaryItem } from './SummaryItem';
import {
  activeServiceOrder,
  categoryServiceActive,
  categoryValueInactive,
  clientSummary,
  inactiveTechnicians,
  scheduledCall,
  time,
  urgentCall,
  when,
} from 'assets/images/icons';

interface IAddress {
  cep: string;
  city: string;
  state: string;
  address: string;
  number: string;
  complement: string;
  district: string;
}

interface ITime {
  firstTime: number;
  firstTimeFormatted: string;
  secondTimeFormatted?: string;
  secondTime?: string;
}

interface FormikContextValues {
  companyName: string;
  responsibleTelephone: string;
  address: IAddress;
  serviceType: string;
  type: string;
  searchType: string;
  startTime: Date[];
  time: ITime;
  totalCallValue: string;
}

export const typesOfCall = {
  Urgent: {
    icon: urgentCall,
    label: 'Urgente',
    description: 'Chamado urgente',
  },
  Scheduled: {
    icon: scheduledCall,
    label: 'Agendado',
    description: 'Chamado agendado',
  },
};

export const typesOfService = {
  PER_CALL: {
    label: 'Por Chamado',
    icon: time,
    description: 'Cobrança por chamado',
  },
  HOUR: {
    label: 'Hora',
    icon: time,
    description: 'Cobrança por hora',
  },
};

const SummaryNewCall = () => {
  const { values } = useFormikContext<FormikContextValues>();

  return (
    <>
      <div
        className="absolute top-auto flex flex-col space-y-2 overflow-y-auto bg-white border-2 rounded-lg shadow-2xl border-lightGray"
        style={{
          maxHeight: '75%',
          maxWidth: '28%',
          minWidth: '22%',
        }}
      >
        <div className="flex flex-row items-center p-3 space-x-2 bg-gray">
          <img
            src={activeServiceOrder}
            alt="Resumo do chamado"
            className="w-6 h-6"
          />

          <Typography variant="h2" color="darkestGray">
            Resumo do chamado
          </Typography>
        </div>
        <div className="px-4 pb-4 space-y-1">
          <SummaryItem
            title="Tipo de chamado"
            icon={typesOfCall[values.type]?.icon || urgentCall}
            description={
              values.type ? typesOfCall[values.type]?.description : ''
            }
            showTitle
          />
          <SummaryItem
            title="Cliente"
            icon={clientSummary}
            description={values?.companyName || ''}
            showTitle
          />
          <SummaryItem
            title="Tipo de cobrança"
            icon={
              typesOfService[values.serviceType]?.icon || categoryServiceActive
            }
            description={typesOfService[values.serviceType]?.description || ''}
            showTitle
          />

          <SummaryItem
            title="Valor"
            icon={categoryValueInactive}
            description={values.serviceType ? values?.totalCallValue : ''}
            showTitle
          />
          <SummaryItem
            title="Quando"
            icon={when}
            description={
              values?.startTime[0] &&
              values?.time?.firstTimeFormatted &&
              values?.time?.secondTimeFormatted &&
              `${values?.startTime[0]?.toLocaleDateString()} às ${String(
                values?.time?.firstTime
              ).padStart(2, '0')}:${values?.time?.secondTimeFormatted}`
            }
            showTitle
          />
          <SummaryItem
            title="Técnico"
            icon={inactiveTechnicians}
            description={
              values.searchType &&
              `Atribuído ao chamado ${
                values?.searchType === 'Manual'
                  ? 'manualmente'
                  : 'automaticamente'
              }`
            }
            showTitle
          />
        </div>
      </div>
    </>
  );
};

export { SummaryNewCall };
