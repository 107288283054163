import { Typography } from 'components/Typography';
import { useCallback } from 'react';
import { Container, Content, ToggleCircle, ToggleText } from './styles';

interface ToggleProps {
  checked: boolean;
  label?: string;
  onClick(value: boolean): void;
}

const Toggle = ({ checked, onClick, label }: ToggleProps) => {
  const handleClick = useCallback(() => {
    onClick(!checked);
  }, [onClick, checked]);

  return (
    <Container data-testid="toggle-component">
      {label ? (
        <Typography variant="h4" color="darkerGray">
          {label}
        </Typography>
      ) : null}
      <Content onClick={() => handleClick()} data-testid="toggle-button">
        <ToggleText checked={checked} variant="tag" color="purple">
          SIM
        </ToggleText>
        <ToggleText checked={!checked} variant="tag" color="orange">
          NÃO
        </ToggleText>
        <ToggleCircle checked={checked} />
      </Content>
    </Container>
  );
};

export { Toggle };
