import { Typography } from 'components/Typography';
import { TagList } from 'components/TagList';
import type { RegionType } from 'features/Technician/types';

interface ServiceRegionsProps {
  regions: RegionType[];
}

const ServiceRegions = ({ regions }: ServiceRegionsProps) => {
  return (
    <>
      <Typography variant="h3" color="darkBLue">
        Cidade de atendimento
      </Typography>
      <div className="flex flex-col gap-5">
        <div className="flex flex-row w-full gap-4 overflow-x-auto pb-2">
          <TagList
            expand={99}
            data={regions?.map((r) => r.region.name + ' - ' + r.region.uf)}
          />
        </div>
      </div>
    </>
  );
};

export { ServiceRegions };
