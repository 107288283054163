import { ListItem } from '../ListItem';

type DataTypes = {
  name: string;
};

interface StaticListProps {
  data: DataTypes[];
  onClick: (item) => void;
}

const StaticList = ({ data = [], onClick }: StaticListProps) => {
  return (
    <ul>
      {data.map((item, index) => {
        return (
          <ListItem
            key={index}
            onClick={() => {
              if (onClick) {
                onClick(item);
              }
            }}
          >
            {typeof item === 'string' ? item : item.name}
          </ListItem>
        );
      })}
    </ul>
  );
};

export { StaticList };
