import groupBy from 'lodash/groupBy';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { v4 } from 'uuid';
import { Typography } from 'components/Typography';
import { useCall } from 'queries/call';
import { callStatus } from 'lib/call';
import { StatusColorIndicator } from '../StatusColorIndicator';

const CallLog = ({ callId }) => {
  const { call } = useCall(callId);

  const groupedByDay = groupBy(call?.callLog, (value) => {
    return new Date(value.createdAt || null).getDate();
  });

  return (
    <div
      className="flex flex-col w-full p-6 space-y-4 border-2 border-lightGray rounded-xl"
      key={v4()}
    >
      {Object.keys(groupedByDay)
        .reverse()
        .map((day) => {
          const logs = groupedByDay[day].reverse();
          return (
            <div className="flex items-start space-x-2" key={v4()}>
              <div className="flex items-center w-1/3 mt-4 space-x-2">
                <StatusColorIndicator
                  mini
                  color={callStatus[logs[0].callStatus].color}
                />
                <Typography variant="p1" color="darkerGray">
                  {format(new Date(logs[0].createdAt || null), "dd 'de' MMMM", {
                    locale: ptBR,
                  })}
                </Typography>
              </div>
              <div className="flex flex-col w-2/3 space-y-2 ">
                {logs.map((log, idx) => {
                  return (
                    <div
                      className="flex flex-col p-4 space-y-2 border-2 border-lightGray rounded-xl"
                      key={idx}
                    >
                      <div className="flex items-center space-x-2">
                        <StatusColorIndicator
                          color={callStatus[log.callStatus].color}
                        />
                        <Typography variant="tag" color="darkerGray">
                          {callStatus[log.callStatus].displayText}
                        </Typography>
                      </div>
                      {log.createdAt && log.callStatus && (
                        <div className="flex items-start space-x-4">
                          <div className="flex flex-col">
                            <Typography variant="tag" color="darkGray">
                              Horário
                            </Typography>
                            <Typography variant="p1" color="darkerGray">
                              {format(new Date(log.createdAt || null), 'HH:mm')}
                            </Typography>
                          </div>
                          <div className="flex flex-col">
                            <Typography variant="tag" color="darkGray">
                              Status
                            </Typography>
                            <Typography variant="p1" color="darkerGray">
                              {callStatus[log.callStatus]?.displayText}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { CallLog };
