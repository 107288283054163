import { cnpjUpload } from 'assets/images/illustrations';
import { Typography } from 'components/Typography';

interface CnpjCardProps {
  linkPdf: string;
}

const CnpjCard = ({ linkPdf }: CnpjCardProps) => {
  return (
    <section className="space-y-4 flex-col justify-center items-center">
      <Typography variant="h3" color="darkBLue">
        Cartão CNPJ
      </Typography>

      <div className="w-52 h-52 bg-[#F2F2F2] rounded-2xl px-2.5 pt-2.5 m-auto">
        <a href={linkPdf} target="_blank" rel="noreferrer">
          <div className="flex bg-white h-4/5 rounded-lg justify-center items-center flex-col">
            <img src={cnpjUpload} alt="Cartão CNPJ" />
          </div>

          <p className="flex h-1/5 justify-center items-center text-xs text-[#C2C2C2]">
            cartão cnpj.pdf
          </p>
        </a>
      </div>
    </section>
  );
};

export { CnpjCard };
