import { useState } from 'react';
import { useCompany, useTrainings } from 'queries/clients';
import { Tabs } from 'components/Tabs';
import { Tab } from 'components/Tabs/Tab';
import { Avatar } from 'components/Avatar';
import {
  CompanyContractData,
  CompanyFinancialData,
  CompanyInfoData,
  FooterSidesheetCompany,
} from 'features/Company/components';
import { CompanyAddressesTab } from 'features/Company/components/CompanyAddresses/CompanyAddressesTab';
import { CompanyTrainings } from 'features/Company/components/CompanyTrainings';

export interface SidesheetViewCompanyProps {
  companyId?: string;
  close: () => void;
}

const SidesheetViewCompany = ({
  companyId,
  close,
}: SidesheetViewCompanyProps) => {
  const { company } = useCompany(companyId);
  const { trainings } = useTrainings(companyId)
  const [currentTab, setCurrentTab] = useState(0);

  function renderTabSelected() {
    const tabs = {
      0: <CompanyInfoData data={company} />,
      1: <CompanyAddressesTab companyId={company?.id} />,
      2: <CompanyFinancialData data={company} />,
      3: <CompanyContractData data={company} />,
      4: <CompanyTrainings trainings={trainings} />,
    };
    return tabs[currentTab] || tabs[0];
  }

  return (
    <div className="flex flex-col w-full max-h-screen min-h-screen">
      <div className="pt-6 px-14 bg-lighterGray">
        <div className="flex flex-row items-center gap-6">
          <Avatar
            longName={company?.fantasyName}
            name={company?.fantasyName}
            photoURL={company.avatar}
            size="large"
            status="ativo"
          />
        </div>
        <div className="z-10 w-full mt-4">
          <Tabs
            data={[
              {
                title: 'Dados Cadastrais',
              },
              {
                title: 'Endereços',
              },
              {
                title: 'Financeiro',
              },
              {
                title: 'Contrato',
              },
              {
                title: 'Treinamentos',
              },
            ]}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            renderItem={Tab}
          />
        </div>
        <div className="w-full -mt-1 border-t-4 border-lightGray" />
      </div>

      <div className="h-full px-6 overflow-y-auto">{renderTabSelected()}</div>

      <FooterSidesheetCompany companyId={companyId} close={close} />
    </div>
  );
};

export { SidesheetViewCompany };
