import { Typography } from 'components/Typography';
import { Icon } from './styles';

interface PersonalDataItemProps {
  title: string;
  value: string;
  icon: string;
  hasTitle?: boolean;
}

const PersonalDataItem = ({
  title,
  value,
  icon,
  hasTitle = true,
}: PersonalDataItemProps) => {
  return (
    <div className="flex flex-col space-y-2">
      {hasTitle && (
        <Typography variant="h4" color="darkestGray">
          {title}
        </Typography>
      )}
      <div className="flex flex-row items-center space-x-2">
        <Icon alt="Icon" src={icon} />
        <Typography variant="p1" color="darkerGray" className="max-w-[239px]">
          {value}
        </Typography>
      </div>
    </div>
  );
};

export { PersonalDataItem };
