import { checked, crossed } from 'assets/images/icons';
import { Container, Image } from './styles';

export interface CheckmarkProps {
  isChecked?: boolean;
  checked?: boolean;
  size?: 'sm' | 'md';
}

const Checkmark = ({ isChecked = true, size = 'md' }: CheckmarkProps) => {
  return (
    <Container checked={isChecked} size={size}>
      <Image
        src={isChecked ? checked : crossed}
        alt={`Ícone chamado de ${isChecked ? 'checked' : 'crossed'}`}
      />
    </Container>
  );
};

export { Checkmark };
