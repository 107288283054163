import { Typography } from 'components/Typography';
import { IProfessional } from '../ProfessionalCard';
import { Container, CloseButton } from './styles';
import { cancelHover, defaultAvatar } from 'assets/images/icons';

interface ProfessionalLinkedCallItemProps {
  professional: IProfessional;
  onRemoveProfessional(id: string): void;
}

const ProfessionalLinkedCallItem = ({
  professional: { id, name, avatar },
  onRemoveProfessional,
}: ProfessionalLinkedCallItemProps) => {
  return (
    <Container>
      <div>
        <img src={avatar || defaultAvatar} alt="avatar" />
        <Typography variant="p1" color="darkBlue">
          {name}
        </Typography>
      </div>
      <CloseButton type="button" onClick={() => onRemoveProfessional(id)}>
        <img src={cancelHover} alt="icon cancel" />
      </CloseButton>
    </Container>
  );
};

export { ProfessionalLinkedCallItem };
