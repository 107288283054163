import { Container } from './styles';
import { Typography } from 'components/Typography';
import { starFilled } from 'assets/images/icons';

const ClientFavoriteBadge = () => {
  return (
    <Container>
      <img src={starFilled} alt="icon start fielled" />
      <Typography variant="tag" color="darkerGray">
        Favorito do cliente
      </Typography>
    </Container>
  );
};

export { ClientFavoriteBadge };
