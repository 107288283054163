import { getToken } from 'services/auth';
import parseJwt from 'utils/parseJwt';

export interface IUserToken {
  companyName: string | null;
  id: string;
  email: string;
  type: 'Client' | 'EuNerd';
  companyId: string;
  role: string;
  iat: number;
  searchKey: string;
}

export default function useUser(): IUserToken {
  const token = getToken();

  const user = parseJwt(token);

  return user || {};
}
