import { useEffect, useMemo, useState } from 'react';
import { ChecklistItem } from './ChecklistItem';
import { NewChecklistItemButton } from './NewChecklistItemButton';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { IRegisteredItems } from 'types/call';
import { restrictToParentElement } from '@dnd-kit/modifiers';

interface ChecklistComponentProps {
  onChangeSaveItem?: (e) => void;
  checklistValues?: IRegisteredItems[];
  onChangeRemoveItem?: (
    e: React.FormEvent<HTMLInputElement>,
    idx: number
  ) => void;
}

const ChecklistComponent = ({
  onChangeSaveItem,
  checklistValues = [],
  onChangeRemoveItem,
}: ChecklistComponentProps) => {
  const [registeredItems, setRegisteredItems] = useState<IRegisteredItems[]>(
    []
  );
  const [isDragEnd, setIsDragEnd] = useState(false);

  const checkIsEmpty = registeredItems.some(
    (checkItem) => checkItem.value === ''
  );

  const itemIds = useMemo(
    () => registeredItems.map((item) => item.id),
    [registeredItems]
  );

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setRegisteredItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        if (items[newIndex].value === '') {
          return items;
        }
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setIsDragEnd(true);
  };

  useEffect(() => {
    if (checklistValues) {
      setRegisteredItems(checklistValues);
    }
  }, [checklistValues]);

  const addNewItem = () => {
    setRegisteredItems((prevItems) => [
      ...prevItems,
      { id: registeredItems.length + 1, value: '' },
    ]);
  };

  const removeItem = (_: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    setRegisteredItems((prevItems) => [
      ...prevItems.filter((_, index) => index !== idx),
    ]);

    onChangeRemoveItem?.(_, idx);
  };

  const onChangeItem = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
    items: IRegisteredItems[]
  ) => {
    const newItems = [...items];
    newItems[idx]['value'] = e.target.value;
    setRegisteredItems(newItems);

    onChangeSaveItem(newItems);
  };

  useEffect(() => {
    if (isDragEnd) {
      onChangeSaveItem(registeredItems);
      setIsDragEnd(false);
    }
  }, [isDragEnd, registeredItems]);

  return (
    <div className="flex flex-col w-1/2">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext
          items={itemIds}
          strategy={verticalListSortingStrategy}
          disabled={checkIsEmpty}
        >
          {registeredItems?.map((item, idx) => (
            <ChecklistItem
              id={item.id}
              key={item.id}
              counterDisplays={idx + 1}
              disabled={item.value === ''}
              value={item.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeItem(e, idx, registeredItems)
              }
              removeItem={(e: React.ChangeEvent<HTMLInputElement>) =>
                removeItem(e, idx)
              }
              addNewItem={addNewItem}
            />
          ))}
        </SortableContext>
      </DndContext>

      <NewChecklistItemButton
        addNewItem={() => addNewItem()}
        isDisabled={checkIsEmpty}
      />
    </div>
  );
};

export { ChecklistComponent };
