import { LineChart } from 'components/Charts/Line';
import { formatCurrency } from 'utils/formatCurrency';
import { CurrencyValue } from '../CurrencyValue';

interface ChartData {
  name: string;
  pv: number;
  label: string;
}
interface ChartCardProps {
  title: string;
  value: number;
  danger?: boolean;
  data: ChartData[];
}
const ChartCard = ({ title, value, danger = false, data }: ChartCardProps) => {
  return (
    <div className="flex flex-col p-6 space-y-4 border-2 border-gray rounded-xl">
      <div className="flex justify-between flex-1">
        <p className="font-bold text-normal">{title}</p>
        <CurrencyValue value={formatCurrency(value, false)} />
      </div>
      <div className="w-full">
        <LineChart
          data={data}
          dataKey="value"
          labelKey="month"
          danger={danger}
        />
      </div>
    </div>
  );
};

export { ChartCard };
