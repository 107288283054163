const adminRolePermission = [
  {
    title: 'Acesso a Gestão de:',
    data: [
      {
        title: 'Chamado',
        type: 'available',
      },
      {
        title: 'Técnicos',
        type: 'available',
      },
      {
        title: 'Financeira',
        type: 'available',
      },
      {
        title: 'Clientes',
        type: 'available',
      },
      {
        title: 'Plataforma',
        type: 'available',
      },
    ],
  },
];

const operationRolePermission = [
  {
    title: 'Acesso a Gestão de:',
    data: [
      {
        title: 'Chamado',
        type: 'available',
      },
      {
        title: 'Técnicos',
        type: 'available',
      },
      {
        title: 'Financeira',
        type: 'available',
      },
      {
        title: 'Clientes',
        type: 'available',
      },
      {
        title: 'Plataforma',
        type: 'unavailable',
      },
    ],
  },
];

const financialRolePermission = [
  {
    title: 'Acesso a Gestão de:',
    data: [
      {
        title: 'Chamado',
        type: 'unavailable',
      },
      {
        title: 'Técnicos',
        type: 'unavailable',
      },
      {
        title: 'Financeira',
        type: 'available',
      },
      {
        title: 'Clientes',
        type: 'available',
      },
      {
        title: 'Plataforma',
        type: 'unavailable',
      },
    ],
  },
];

export {
  adminRolePermission,
  financialRolePermission,
  operationRolePermission,
};
