import React, { useEffect, useState } from 'react';
import { Tabs } from 'components/Tabs';
import { IconTab } from 'components/Tabs/IconTab';

import { Container } from './styles';
import { PaymentReviewTab } from './PaymentReviewTab';
import { PaymentServiceTab } from './PaymentServiceTab';
import {
  activeReviewCall,
  activeServiceOrderGray,
  inactiveReviewCall,
  inactiveServiceOrder,
} from 'assets/images/icons';

export interface PaymentHistoryProps {
  callId?: string;
  close?: () => void;
}

export const PaymentHistory = ({ close, callId }: PaymentHistoryProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const INITIAL_TAB = 0;

  useEffect(() => {
    setCurrentTab(INITIAL_TAB);
  }, [INITIAL_TAB]);

  return (
    <div className="flex w-full">
      <Container className="flex flex-col max-h-screen space-y-4 overflow-y-auto">
        <div className="w-full px-4 bg-lighterGray">
          <Tabs
            data={[
              {
                title: 'Revisão de Pagamentos',
                iconSelected: activeReviewCall,
                iconUnselected: inactiveReviewCall,
              },
              {
                title: 'Ordem de Serviço',
                iconSelected: activeServiceOrderGray,
                iconUnselected: inactiveServiceOrder,
              },
            ]}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            renderItem={IconTab}
          />
        </div>
        {currentTab === 0 ? (
          <PaymentReviewTab close={close} callId={callId} />
        ) : (
          <PaymentServiceTab callId={callId} />
        )}
      </Container>
    </div>
  );
};
