import { useClickOutside } from 'hooks/useClickOutside';
import { useCallback, useEffect, useRef, useState } from 'react';
import { specialChars } from 'utils/specialChars';
import { SearchInput } from './SearchInput';
import { SearchOptionItem, SearchOption } from './SearchOption';
import { Container, OptionListContainer } from './styles';

interface SearchContainerProps {
  isOpen?: boolean;
  data: SearchOptionItem[];
  itemSelected?: SearchOptionItem | string;
  getItemSelected(item: SearchOptionItem): void;
}

const SearchContainer = ({
  isOpen = false,
  data,
  itemSelected,
  getItemSelected,
}: SearchContainerProps) => {
  const [dataFiltered, setDataFiltered] = useState(data);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDataFiltered(data);
  }, [isOpen, data]);

  useClickOutside({
    action: () => handleFilterService(''),
    ref: selectRef,
  });

  const handleFilterService = useCallback(
    (searchText: string) => {
      const newDataFiltered = data?.filter((item) => {
        return specialChars(typeof item === 'string' ? item : item.name).match(
          specialChars(searchText)
        );
      });
      setDataFiltered(newDataFiltered);
    },
    [data]
  );

  const handleSelectItem = useCallback(
    (item: SearchOptionItem) => {
      getItemSelected(item);
      setDataFiltered(data);
    },
    [getItemSelected, data]
  );

  return (
    <Container isOpen={isOpen}>
      <SearchInput onChange={handleFilterService} isOpen={isOpen} />
      <OptionListContainer>
        {dataFiltered?.map((option) => (
          <SearchOption
            key={option?.id}
            option={option}
            onSelectItem={handleSelectItem}
            active={itemSelected === option}
          />
        ))}
      </OptionListContainer>
    </Container>
  );
};

export { SearchContainer };
