import { TabButtonStatus } from 'components/TabButtonStatus';

interface CallsTabsProps {
  children: React.ReactNode;
  onChange: (tab: number) => void;
}

const CallsTabs = ({ children, onChange }: CallsTabsProps) => {
  const statusTabs = [
    {
      tabId: 0,
      title: 'Chamados Abertos',
    },
    {
      tabId: 1,
      title: 'Chamados Finalizados',
    },
  ];

  return (
    <>
      <div className="gap-6 mb-6 space-x-6 space-y-2">
        <TabButtonStatus
          data={statusTabs}
          defaultSelected={0}
          onClick={onChange}
        />
      </div>
      {children}
    </>
  );
};

export { CallsTabs };
