import styled, { css, keyframes } from 'styled-components';

interface StyleProps {
  selected: boolean;
}

interface IconProps {
  selected: boolean;
  disabled: boolean;
}

interface ContainerProps {
  isOpen: boolean;
  selected: boolean;
}

interface DetailContainerProps {
  isOpen: boolean;
  selected?: boolean;
}

const rotateAnimation = keyframes`
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.colors.lightGray};

  ${(props) =>
    props.selected &&
    css`
      background: rgba(128, 107, 255, 0.1);
      border-color: ${props.theme.colors.purple};
    `}

  ${(props) =>
    props.isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const Content = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContentInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const ContentActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ContentActionsArrow = styled.img<DetailContainerProps>`
  margin-right: 27px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-top: 13px;
  ${(props) =>
    props.isOpen &&
    css`
      animation: ${rotateAnimation} 0.5s ease;
      transform: rotate(180deg);
    `}
`;

export const Icon = styled.div<IconProps>`
  display: inline-block;
  vertical-align: middle;
  ${(props) =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}

  width: 16px;
  height: 16px;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.purple : props.theme.colors.lightGray};
  margin-right: 8px;
  border: 3px solid
    ${(props) =>
      props.selected ? props.theme.colors.white : props.theme.colors.lightGray};
  border-radius: 4px;
`;

export const DetailContainer = styled.div<DetailContainerProps>`
  ${(props) =>
    props.isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  transition: opacity 0.2s;

  position: absolute;
  z-index: 1;
  left: -2px;
  padding: 20px 24px;
  top: 80px;

  border: 2px solid
    ${(props) =>
      props.isOpen && props.selected
        ? props.theme.colors.purple
        : props.theme.colors.lightGray};

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  width: calc(100% + 4px);
  background: ${(props) => props.theme.colors.white};
`;
