/* eslint-disable @typescript-eslint/no-explicit-any */
import { SidesheetTypes } from 'ducks/ui/types';

import { NewTechnician } from './NewTechnician';
import NewServiceSidesheet from './NewService';

import ManageBonusSidesheet from './ManageBonus';
import { AddBalanceSidesheet } from './AddBalance';
import { AddBalanceInternalSidesheet } from './AddBalanceInternal';
import { AlertCallSidesheet } from './AlertCallSidesheet';
import { PaymentHistory } from './PaymentHistory';
import { SidesheetLinkTechnician } from './SidesheetLinkTechnician';
import { NewUser } from './NewUser';
import { UserProfile } from './UserProfile';
import { CallReviewTab } from 'components/UI/Sidesheet/AlertCallSidesheet/CallReviewTab';
import { NewFormOfService } from './NewFormOfService';
import { EditFormOfService } from './EditFormOfService';
import { NewCallTemplate } from './NewCallTemplate';

import { SidesheetFinishedCalls } from 'features/Call/Sidesheets/SidesheetFinishedCalls';
import { SidesheetOpenCalls } from 'features/Call/Sidesheets/SidesheetOpenCalls';
import { SidesheetAddNewCall } from 'features/Call/Sidesheets/SidesheetAddNewCall';

import { SidesheetEditCompany } from 'features/Company/Sidesheets/SidesheetEditCompany';
import { SidesheetAddServiceCompany } from 'features/Company/Sidesheets/SidesheetAddServiceCompany';
import { SidesheetNewCompany } from 'features/Company/Sidesheets/SidesheetNewCompany';
import { SidesheetViewCompany } from 'features/Company/Sidesheets/SidesheetViewCompany';
import { SidesheetActiveTechnician } from 'features/Technician/Sidesheets/SidesheetActiveTechnician';
import { SidesheetTechnicianScreening } from 'features/Technician/Sidesheets/SidesheetTechnicianScreening';
import { SidesheetCreateCompanyAddress } from 'features/Company/Sidesheets/SidesheetCreateCompanyAddress';

export type SidesheetBaseProps = {
  close: (bypass?: boolean) => void;
  isOpen: boolean;
  onAfterClose?: () => void;
  width?: 'normal' | 'wide';
};

export interface TechnicianActiveProfileProps {
  data: any;
}

export interface TechnicianScreeningProfileProps {
  data: any;
  close: () => void;
  onAfterClose?: () => void;
}
export const sidesheetTypes: {
  [key in SidesheetTypes]: React.ComponentType | React.ReactNode;
} = {
  [SidesheetTypes.NewTechnician]: NewTechnician,
  [SidesheetTypes.NewService]: NewServiceSidesheet,
  [SidesheetTypes.ManageBonus]: ManageBonusSidesheet,
  [SidesheetTypes.AddBalance]: AddBalanceSidesheet,
  [SidesheetTypes.AddBalanceInternal]: AddBalanceInternalSidesheet,
  [SidesheetTypes.AlertCallSidesheet]: AlertCallSidesheet,
  [SidesheetTypes.PaymentHistory]: PaymentHistory,
  [SidesheetTypes.SidesheetLinkTechnician]: SidesheetLinkTechnician,
  [SidesheetTypes.NewUser]: NewUser,
  [SidesheetTypes.CallReviewTab]: CallReviewTab,
  [SidesheetTypes.UserProfile]: UserProfile,
  [SidesheetTypes.NewFormOfService]: NewFormOfService,
  [SidesheetTypes.EditFormOfService]: EditFormOfService,
  [SidesheetTypes.NewCallTemplate]: NewCallTemplate,
  [SidesheetTypes.SidesheetActiveTechnician]: SidesheetActiveTechnician,
  [SidesheetTypes.SidesheetTechnicianScreening]: SidesheetTechnicianScreening,
  [SidesheetTypes.SidesheetFinishedCalls]: SidesheetFinishedCalls,
  [SidesheetTypes.SidesheetOpenCalls]: SidesheetOpenCalls,
  [SidesheetTypes.SidesheetAddNewCall]: SidesheetAddNewCall,
  [SidesheetTypes.SidesheetNewCompany]: SidesheetNewCompany,
  [SidesheetTypes.SidesheetViewCompany]: SidesheetViewCompany,
  [SidesheetTypes.SidesheetEdiCompany]: SidesheetEditCompany,
  [SidesheetTypes.SidesheetAddServiceCompany]: SidesheetAddServiceCompany,
  [SidesheetTypes.SidesheetCreateCompanyAddress]: SidesheetCreateCompanyAddress,
};
