import { ErrorMessage } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';
import flattenKeys from 'utils/flattenKeys';
import ISOToDateInput from 'utils/ISOToDateInput';

const InputField = ({
  field,
  form: { touched, errors },
  disabled,
  optional,
  icon: Icon,
  ...props
}) => {
  const value =
    props.type === 'date' ? ISOToDateInput(field.value) : field.value;

  return (
    <div>
      {optional ? (
        <div className="flex flex-row">
          <label
            className="flex flex-col mb-1 text-sm font-medium text-darkerGray"
            htmlFor={'field_' + field.name}
          >
            {props.label}
          </label>
          <span className="flex flex-col mb-1 ml-1 text-sm font-medium text-darkGray">
            (opcional)
          </span>
        </div>
      ) : (
        <label
          className="flex flex-col w-full mb-1 text-sm font-medium text-darkerGray"
          htmlFor={'field_' + field.name}
        >
          {props.label}
        </label>
      )}
      {Icon ? (
        <div
          className={clsx(
            'flex flex-row items-center justify-center p-[2px] overflow-hidden border rounded-xl  space-x-2 focus-within:border-darkGray',
            {
              'border-red-500':
                flattenKeys(touched)[field.name] &&
                flattenKeys(errors)[field.name],
              'border-gray':
                !flattenKeys(touched)[field.name] ||
                !flattenKeys(errors)[field.name],
              'bg-lightGray cursor-not-allowed': disabled,
            }
          )}
        >
          <div className="flex items-center justify-center h-full p-2 rounded-xl bg-purple bg-opacity-20 text-purpleClick">
            <span className="h-[16px] w-[16px] text-xs items-center justify-center flex">
              {typeof Icon === 'string' ? (
                Icon ? (
                  <img src={Icon} />
                ) : (
                  Icon
                )
              ) : (
                <Icon />
              )}
            </span>
          </div>
          <div className="flex items-center justify-between w-full">
            <input
              className={clsx('w-full placeholder-gray', {
                'bg-lightGray cursor-not-allowed': disabled,
              })}
              id={'field_' + field.name}
              {...field}
              {...props}
              value={value}
              disabled={disabled}
            />
            {props.suffix && (
              <span className="pr-4 select-none whitespace-nowrap">
                {props.suffix}
              </span>
            )}
          </div>
        </div>
      ) : (
        <input
          className={clsx(
            'w-full p-2 border rounded-xl focus:border-darkGray',
            {
              'border-red-500':
                flattenKeys(touched)[field.name] &&
                flattenKeys(errors)[field.name],
              'border-gray':
                !flattenKeys(touched)[field.name] ||
                !flattenKeys(errors)[field.name],
              'bg-lightGray cursor-not-allowed': disabled,
            }
          )}
          id={'field_' + field.name}
          {...field}
          {...props}
          value={value}
          disabled={disabled}
        />
      )}
      <ErrorMessage name={field.name}>
        {(msg) => <div className="w-full text-xs text-red-500">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export { InputField };
