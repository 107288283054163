export function bresenhamLine(initialCoords, finalCoords) {
  let x1 = initialCoords.lat;
  let y1 = initialCoords.lng;
  let x2 = finalCoords.lat;
  let y2 = finalCoords.lng;

  let dx = Math.abs(x2 - x1),
    sx = x1 < x2 ? 0.001 : -0.001;
  let dy = Math.abs(y2 - y1),
    sy = y1 < y2 ? 0.001 : -0.001;
  let err = (dx > dy ? dx : -dy) / 2;

  let coordinates = [];

  while (true) {
    coordinates[coordinates.length] = [x1, y1];

    if (x1 <= x2 && y1 <= y2) {
      break;
    }

    let e2 = err;

    if (e2 > -dx) {
      err -= dy;
      x1 += sx;
    }

    if (e2 < dy) {
      err += dx;
      y1 += sy;
    }
  }

  return coordinates;
}

export function coordsCompare(coords, markers) {
  if (coords.latDestinationGreaterThan) {
    if (coords.lngDestinationGreaterThan) {
      // Lat maior e Lng maior
      // console.log('case 1.1');
      return bresenhamLine(markers[1], markers[0]);
    } else {
      // Lat maior e Lng menor
      // console.log('case 1.2');
      return bresenhamLine(markers[0], markers[1]);
    }
  } else {
    if (coords.lngDestinationGreaterThan) {
      // Lat menor e Lng maior
      // console.log('case 2.1');
      return bresenhamLine(markers[1], markers[0]);
    } else {
      // Lat maior e Lng menor
      // console.log('case 2.2');
      return bresenhamLine(markers[0], markers[1]);
    }
  }
}

// Other way to do
// const variation = 0.001;
// if (markers[0].lat > markers[1].lat && markers[0].lng > markers[1].lng) {
//   // Top to bottom
//   let markerLat = markers[0].lat - 2 * variation;
//   let markerLng = markers[0].lng - 2 * variation;
//   while (
//     markerLat - variation > markers[1].lat ||
//     markerLng - variation > markers[1].lng
//   ) {
//     if (+(markerLat - variation).toFixed(3) >= +markers[1].lat.toFixed(3))
//       markerLat = +(markerLat - variation).toFixed(3);
//     else markerLat;

//     if (+(markerLng - variation).toFixed(3) >= +markers[1].lng.toFixed(3))
//       markerLng = +(markerLng - variation).toFixed(3);
//     else markerLng;

//     targetMarkers.push({ lat: markerLat, lng: markerLng, variant: 'path' });
//   }
// } else {
//   // Bottom to top
//   let markerLat = markers[0].lat + 2 * variation;
//   let markerLng = markers[0].lng + 2 * variation;
//   while (
//     markerLat + variation < markers[1].lat ||
//     markerLng + variation < markers[1].lng
//   ) {
//     if (+(markerLat + variation).toFixed(3) <= +markers[1].lat.toFixed(3))
//       markerLat = +(markerLat + variation).toFixed(3);
//     else markerLat;

//     if (+(markerLng + variation).toFixed(3) <= +markers[1].lng.toFixed(3))
//       markerLng = +(markerLng + variation).toFixed(3);
//     else markerLng;

//     targetMarkers.push({ lat: markerLat, lng: markerLng, variant: 'path' });
//   }
// }
