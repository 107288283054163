import { useMemo } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { formatUTCHour } from 'utils/formatUtils';
import { differenceInHours, format } from 'date-fns';
import { useCall } from 'queries/call';
import { Typography } from 'components/Typography';
import {
  alertWhite,
  clock,
  dollarSign,
  id,
  toolsPurple,
} from 'assets/images/icons';
import { ReviewItem } from '../ReviewItem';
import { ServiceOrderDays } from '../ServiceOrderDays';
import { useAdditionalServiceByCallId } from 'queries/additionalService';
import { ptBR } from 'date-fns/locale';

interface ServiceOrderSummaryProps {
  callId: number;
  withCalendar?: boolean;
}
type ServiceOrderSummaryTypes = {
  id: number;
  type: string;
  serviceName: string;
  serviceType: string;
  value: number;
  startTime: string;
  finalTime: string;
  createdAt: string;
};

const ServiceOrderSummary = ({
  callId,
  withCalendar,
}: ServiceOrderSummaryProps) => {
  const { call, isLoading } = useCall(callId);
  const { listServices } = useAdditionalServiceByCallId(callId)

  const sumAdditionalServices = useMemo(() => {
    return listServices?.call.reduce((acc, service) => {
      return acc + service.value;
    }, 0);
  }, [listServices]);

  const summaryData = useMemo(() => {
    return {
      id: call?.id,
      type: call?.type,
      serviceName: call?.service?.name,
      serviceType: call?.serviceType,
      value: call?.value,
      startTime: call?.startTime,
      finalTime: call?.finalTime,
      createdAt: call?.createdAt,
    } as ServiceOrderSummaryTypes;
  }, [call]);

  if (isLoading) {
    return null;
  }
  return (
    <div className="flex flex-col space-y-4">
      <Typography variant="h2" color="darkestGray">
        Resumo
      </Typography>
      <div className="flex flex-col space-y-2 divide-y-2 divide-lightGray">
        <ReviewItem label={`${summaryData?.id}`} icon={id} />
        {summaryData?.type === 'Urgent' && (
          <ReviewItem label="Chamado Urgente" icon={alertWhite} danger />
        )}
        <ReviewItem label={summaryData?.serviceName} icon={toolsPurple} />
        <ReviewItem
          label={`${formatCurrency(summaryData?.value)}`}
          icon={dollarSign}
        />

        {listServices?.call?.length > 0 &&
          <ReviewItem
            label={`${formatCurrency(sumAdditionalServices)} Serviço adicional`}
            icon={dollarSign}
          />
        }

        {summaryData?.serviceType === 'HOUR' ? (
          <ReviewItem
            label="Chamado por hora"
            icon={clock}
            description={`${format(new Date(summaryData?.startTime), "HH'h'mm", {
              locale: ptBR,
            })} à ${format(new Date(summaryData?.finalTime), "HH'h'mm", {
              locale: ptBR,
            })} (${differenceInHours(
              formatUTCHour(summaryData?.finalTime),
              formatUTCHour(summaryData?.startTime)
            )} horas de trabalho)`}
          />
        ) : (
          <ReviewItem label="Cobrança por chamado" icon={clock} />
        )}

        <ReviewItem label="Abertura chamado"
          icon={clock}
          description={format(new Date(summaryData.createdAt), "dd/MM/yyyy - HH'h'mm", {
            locale: ptBR,
          })} />

        {call?.needsParts && (<div className='py-6 space-y-2'>
          <div>
            <Typography variant="h2" color="darkestGray">
              Compra de peças
            </Typography>
          </div>
          <Typography variant="p1">
            {call?.partsDescription}
          </Typography>
        </div>)}

        {withCalendar === true && Object.keys(call).length > 0 && (
          <ServiceOrderDays
            startTime={call?.startTime}
            finalTime={call?.finalTime}
          />
        )}
        {/* {withCalendar === true && Object.keys(call).length > 0 && (
          <DayPicker
            weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
            months={[
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ]}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            classNames={{
              weekday: '!no-underline ',
              disabled: 'cursor-default',
              month: 'flex flex-col space-y-2 font-bold text-darkestGray py-2',
              weekdaysRow:
                'bg-gray py-1 rounded-md text-darkerGray text-sm font-bold flex justify-around space-x-2',
              weekNumber: 'flex !no-underline',
              day: 'flex font-medium text-darkGray w-[80px] justify-center text-sm cursor-pointer py-1',
              week: 'flex justify-around space-x-2',
              selected: 'bg-purple !text-white rounded-md',
            }}
            initialMonth={new Date(call?.startTime)}
            disabledDays={[
              {
                before: new Date(call?.startTime),
                after: new Date(call?.finalTime),
              },
            ]}
            selectedDays={[
              {
                from: new Date(call?.startTime),
                to: endDateCalendar,
              },
            ]}
          />
        )} */}
      </div>
    </div>
  );
};
export { ServiceOrderSummary };
