import useSWR from 'swr';
import { fetcher } from 'services/api';
import groupBy from 'lodash/groupBy';

import Map from 'components/Map';
import CallsMapSideSheet from './CallSidesheet';
import { format } from 'date-fns';
import { formatUTCHour } from 'utils/formatUtils';
import { callStatus } from 'lib/call';

interface CallsMapProps {
  center: {
    lat: number;
    lng: number;
  };
  callSheet: { id: string; open: boolean };
  cityFilterId?: number;
  openCallSidesheet: (id) => void;
  closeSidesheet: () => void;
  setCityFilter: (city) => void;
  onMapLoaded?: ({ map }) => void;
  status?: 'active' | 'screening';
}

const CallsMap: React.FC<CallsMapProps> = ({
  center,
  callSheet,
  closeSidesheet,
  openCallSidesheet,
  setCityFilter = () => {},
  onMapLoaded = () => {},
  cityFilterId = null,
  status,
}) => {
  const { data, error } = useSWR(`/calls`, fetcher);

  const processedData =
    status === 'active'
      ? data?.calls?.filter(
          (call) =>
            call.status === callStatus.LOOKING_FOR_PROFESSIONAL.value ||
            call.status === callStatus.PROFESSIONAL_NOT_FOUND.value ||
            call.status === callStatus.SCHEDULED_CALL.value ||
            call.status === callStatus.WAITING_TECHNICIANS_ACCEPTANCE.value ||
            call.status === callStatus.CALL_REFUSED_BY_TECHNICIAN.value ||
            call.status === callStatus.DELAYED_CALL.value ||
            call.status === callStatus.REQUESTED_CANCELLATION.value ||
            call.status === callStatus.ON_THE_WAY.value ||
            call.status === callStatus.SUPPORT_STARTED.value ||
            call.status === callStatus.PENDING_COMPLETION.value ||
            call.status === callStatus.OPERATIONS_LOOKING_FOR_PROFESSIONAL.value 
        )
      : data?.calls?.filter(
          (call) =>
            call.status === callStatus.SUPPORT_CANCELED.value ||
            call.status === callStatus.CALL_SUCCESSFULLY_ENDED.value ||
            call.status === callStatus.UNDER_ANALYSIS.value ||
            call.status === callStatus.UNPRODUCTIVE_CALL.value
        );

  const callsPerRegions = Boolean(status)
    ? groupBy(
        processedData
          ?.filter((c) => (callSheet.open ? c.id === callSheet.id : true))
          .filter((c) => c.city),
        (value) => value.city?.id
      )
    : groupBy(
        data?.calls
          ?.filter((c) => (callSheet.open ? c.id === callSheet.id : true))
          .filter((c) => c.city),
        (value) => value.city?.id
      );

  function isInProgress(status) {
    if (status === 'ON_THE_WAY' || status === 'SUPPORT_STARTED') {
      return true;
    } else if (
      // status === 'ASSIGNED' &&
      status === 'LOOKING_FOR_PROFESSIONAL'
    ) {
      return true;
    } else {
      return false;
    }
  }

  const cities =
    Object.keys(callsPerRegions)?.map((city) => {
      const cityCalls = callsPerRegions[city];
      return {
        onClick: () => {
          if (setCityFilter) {
            if (cityFilterId !== cityCalls[0]?.city.ibge_cod)
              setCityFilter(cityCalls[0]?.city);
            else {
              setCityFilter(null);
            }
          }
        },
        active: cityFilterId === cityCalls[0]?.city.ibge_cod,
        id: cityCalls[0]?.id,
        lat: cityCalls[0]?.city?.lat,
        lng: cityCalls[0]?.city?.lng,
        location: cityCalls[0]?.city.name,
        calls: cityCalls.length,
        notifications: 0,
        variant: cityCalls[0]?.city.capital ? 'call' : 'micro',
        type: 'call',
      };
    }) || [];

  const calls = Boolean(status)
    ? processedData
        ?.filter((c) => (callSheet.open ? c.id === callSheet.id : true))
        .map((call) => {
          return {
            id: call.id,
            lat: call.address?.lat || call.city?.lat,
            lng: call.address?.lng || call.city?.lng,
            status: call.status,
            inProgress: true,
            time: call.startTime
              ? // format(new Date(call.startTime), "dd/MM 'às' HH:mm")
                format(formatUTCHour(call.startTime), "dd/MM 'às' HH:mm")
              : null,
            type: 'client',
            onClick: () => {
              openCallSidesheet(call.id);
            },
          };
        }) || []
    : data?.calls
        ?.filter((c) => (callSheet.open ? c.id === callSheet.id : true))
        .map((call) => {
          return {
            id: call.id,
            lat: call.address?.lat || call.city?.lat,
            lng: call.address?.lng || call.city?.lng,
            status: call.status,
            inProgress: true,
            time: call.startTime
              ? // format(new Date(call.startTime), "dd/MM 'às' HH:mm")
                format(formatUTCHour(call.startTime), "dd/MM 'às' HH:mm")
              : null,
            type: 'client',
            onClick: () => {
              openCallSidesheet(call.id);
            },
          };
        }) || [];

  return (
    <div className="relative w-full">
      <CallsMapSideSheet
        isOpen={callSheet?.open}
        callId={callSheet?.id}
        close={closeSidesheet}
      />
      <Map
        currentOpen={callSheet.open && callSheet.id}
        onMapLoaded={onMapLoaded}
        defaultCenter={center}
        defaultZoom={5}
        markers={[...cities, ...calls]}
      />
    </div>
  );
};

export default CallsMap;
