/* eslint-disable @next/next/no-img-element */
import { cnpjUpload } from "assets/images/illustrations";
import { v4 } from "uuid";

const ViewAttachments = ({ attachments }) => {

  const renderURl = (url) => {
    if (url.endsWith('.pdf')) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            className="object-cover w-full h-24 overflow-hidden rounded-xl"
            src={cnpjUpload}
            alt="anexo"
          />
        </a>
      );
    } else {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            className="object-cover w-full h-24 overflow-hidden rounded-xl"
            src={url}
            alt="anexo"
          />
        </a>)
    }
  }

  return (
    attachments.length > 0 && (
      <div className="flex flex-col space-y-2">
        <label
          className="flex flex-col mb-1 text-sm font-medium text-darkerGray">Anexos</label>
        <div className="grid grid-cols-2 gap-4">
          {attachments.map((a) => {
            return (
              <div className="space-y-2" key={v4()}>
                {renderURl(a.url)}
                <div className="p-2 space-y-2  border-2 rounded-xl border-lightGray">
                  <p className="text-sm font-semibold text-darkerGray break-all">
                    {a.name}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  )
}

export { ViewAttachments }