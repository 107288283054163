import { useState } from 'react';
import { useUI } from 'hooks/useUI';
import { useTechnician } from 'queries/technician';
import { Technicians } from 'services/technician';
import { Button } from 'components/Button';
import { TextArea } from 'components/Inputs/TextArea';
import { Typography } from 'components/Typography';
import { Checkbox } from 'components/Checkbox';
import { TechnicianCard } from '../TechnicianCard';

const RefusingTechnician = ({ id, setIsRefusing, onAfterUpdate, close }) => {
  const UI = useUI();
  const { technician } = useTechnician(id);
  const [selectedValue, setSelectedValue] = useState(['PROFESSIONAL_PROFILE']);
  const [description, setDescription] = useState('');

  const handleRefuse = async () => {
    const payload = selectedValue.map((v) => {
      if (v === 'OTHERS') {
        return {
          reason: v,
          description: description,
        };
      }
      return {
        reason: v,
      };
    });

    try {
      const response = await Technicians.refuse(
        { refuse: payload },
        technician?.id
      );
      if (response.status === 200) {
        UI.operations.showToast(
          `Técnico: ${technician?.name}, recusado com sucesso.`
        );
        setIsRefusing(false);
        close();
        await onAfterUpdate?.();
      }
    } catch {
      UI.operations.showToast('Falha ao recusar técnico', { type: 'error' });
    }
  };

  return (
    <div
      className="flex flex-col max-h-screen min-h-screen w-[670px]"
      style={{ width: '670px' }}
    >
      <div className="h-full overflow-y-auto">
        <div className="p-6 bg-lighterGray">
          <Typography variant="h1" color="darkBLue">
            Recusar Profissional
          </Typography>
        </div>
        <div className="flex  flex-col p-6">
          <TechnicianCard data={technician} />
          <div className="mt-6">
            <Typography variant="h4" color="darkestGray">
              Motivo da recusa
            </Typography>
            <Typography variant="p1" color="darkerGray" className="mt-1">
              Nos informe o(s) motivo(s) considerados para recusar o
              profissional.
            </Typography>
            <div className="flex flex-col mt-4 space-y-4">
              <Checkbox
                state={selectedValue}
                setState={setSelectedValue}
                value="PROFESSIONAL_PROFILE"
                label="Perfil Profissional"
                multipleValues
              />
              <Checkbox
                state={selectedValue}
                setState={setSelectedValue}
                value="QUALIFICATIONS"
                label="Qualificações"
                multipleValues
              />

              <Checkbox
                state={selectedValue}
                setState={setSelectedValue}
                value="OTHERS"
                label="Outro"
                multipleValues
              />

              {selectedValue.includes('OTHERS') && (
                <TextArea
                  placeholder="Insira aqui qual outro motivo de recusa do profissional."
                  width="539px"
                  height="104px"
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={!selectedValue?.includes('OTHERS')}
                  value={description}
                  name="Outro"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-6 p-6 bg-lighterGray justify-self-end">
        <Button variant="anchor" onClick={() => setIsRefusing(false)}>
          Voltar
        </Button>
        <Button color="orange" size="large" onClick={handleRefuse} width="156">
          Confirmar recusa
        </Button>
      </div>
    </div>
  );
};

export { RefusingTechnician };
