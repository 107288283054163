const ToastContent: React.FC<{
  title: string;
  message: string;
  icon: string;
}> = ({ title, message, icon }) => {
  return (
    <div className="flex items-center space-x-6">
      {icon && <img src={icon} />}
      <div className="flex flex-col">
        <p className="font-bold text-darkestGray">{title}</p>
        {message && <p className="">{message}</p>}
      </div>
    </div>
  );
};

export default ToastContent;
