import { Avatar } from 'components/Avatar/';
import { Container } from './styles';
import { Typography } from 'components/Typography';

import { email as emailIcon, phone as phoneIcon } from 'assets/images/icons';
import { TagList } from 'components/TagList';
import { PersonalDataItem } from '../PersonalDataItem';

export const TechnicianCard = ({ data }) => {
  return (
    <div className="space-y-1">
      <Typography variant="h4" color="darkestGray">
        Profissional
      </Typography>
      <Container>
        <div className="flex flex-row items-center space-x-3">
          <Avatar
            name={data?.profile?.name}
            photoURL={data?.profile?.avatarUrl}
          />
          <Typography variant="h3" color="darkerGray">
            {data?.name}
          </Typography>
        </div>
        <div className="space-y-1">
          <Typography variant="h4" color="darkestGray">
            Contato
          </Typography>
          <PersonalDataItem
            title="Telefone"
            value={data?.telephone}
            icon={phoneIcon}
            hasTitle={false}
          />
          <PersonalDataItem
            title="E-mail"
            value={data?.email}
            icon={emailIcon}
            hasTitle={false}
          />
        </div>
        <div className="space-y-1">
          <Typography variant="h4" color="darkestGray">
            Capacitações
          </Typography>
          <TagList
            className="overflow-y-auto max-h-60"
            expand={99}
            data={data?.services?.map((service) => service.service.name)}
          />
        </div>
      </Container>
    </div>
  );
};
