import api from 'services/api';
import { AddBalanceType } from 'types/financial';
import { buildSearchParams } from 'utils/queryUtils';

export const Financial = {
  getTotalNetIncome: async () => {
    const { data } = await api.get('/financial/total-net-income');
    return data;
  },
  getTotalGrossIncome: async (data) => {
    const totalGrossIncome = await api.post(
      '/financial/total-gross-income-by-period',
      data
    );
    return totalGrossIncome;
  },
  getTotalTechnicinCost: async (data) => {
    const totalTechnicinCost = await api.post(
      '/financial/total-technician-cost',
      data
    );
    return totalTechnicinCost;
  },
  getTotalProfit: async (data) => {
    const profit = await api.post('/financial/profit', data);
    return profit;
  },
  getTotalCalls: async (data) => {
    const totalCalls = await api.post('/financial/total-calls', data);
    return totalCalls;
  },
  getTotalCallsCompany: async (id, data) => {
    const totalCallsCompany = await api.post(
      `/financial/total-calls/${id}`,
      data
    );
    return totalCallsCompany;
  },
  getCallHistory: async (data) => {
    const callHistory = await api.post('/financial/call-history', data);
    return callHistory;
  },
  getPaymentHistory: async (data) => {
    const paymentHistory = await api.post('/financial/payment-history', data);
    return paymentHistory;
  },
  updatePayment: (callId, payload) =>
    api.post(`/calls/update-payment-status/${callId}`, payload),

  getFinancialHistory: async (id, data) => {
    const financialHistory = await api.post(
      `/financial/financial-history/${id}`,
      data
    );
    return financialHistory;
  },
  getTotalCallsSixMonths: async (id, data) => {
    const totalCallsSixMonths = await api.post(
      `/financial/total-calls-six-months/${id}`,
      data
    );
    return totalCallsSixMonths;
  },
  getSpendCompany: async (id, data) => {
    const spendData = await api.post(`/financial/spend/${id}`, data);
    return spendData;
  },
  getSpendSixMonths: async (id, data) => {
    const spendSixMonths = await api.post(
      `/financial/spend-per-six-months/${id}`,
      data
    );
    return spendSixMonths;
  },
  getMonthlyCostEvolution: async (id) => {
    const monthlyCostEvolution = await api.post(
      `/financial/monthly-cost-evolution/${id}`
    );
    return monthlyCostEvolution;
  },
  getUnproductiveSpend: async (id) => {
    const unproductiveSpend = await api.get(
      '/financial/unproductive-spend/' + id
    );
    return unproductiveSpend;
  },
  configRewards: (payload) => {
    api.post('/rewards/', payload);
  },
  getRewards: async () => {
    const { data } = await api.get('/rewards');
    return data;
  },
  getHistoryRewards: async (data) => {
    const historyRewardsData = await api.post('/rewards/history', data);
    return historyRewardsData;
  },
  getReportsCall: async (filters) => {
    const { data: reports } = await api.get(
      `/financial/reports/call?${buildSearchParams(filters)}`
    );
    return reports;
  },
  getReportsPayment: async (filters) => {
    const response = await api.get(
      `/financial/reports/payment-history?${buildSearchParams(filters)}`
    );
    return response;
  },
  getReportsFinancial: async (filters) => {
    const { data: reports } = await api.get(
      `/financial/reports/financial?${buildSearchParams(filters)}`
    );
    return reports;
  },
  addBalance: async (companyId: string, data: AddBalanceType) => {
    return api.post(`/financial/add-balance/${companyId}`, data);
  },
};
