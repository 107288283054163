import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1345px;

  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 1440px) {
    width: 1100px;
  }
  
  @media (max-width: 1366px) {
    width: 1050px;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  padding: 1.5rem;
`;

// export const Footer = styled.div`
//   margin-bottom: 2.5rem;
//   padding: 2rem 1.5rem 0;
//   display: flex;
//   gap: 1rem;
//   align-items: center;
//   justify-content: flex-end;

//   @media (max-width: 1440px) {
//     padding: 1rem 1.5rem;
//   }

//   button {
//     width: 300px;
//   }
// `;

export const Footer = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  background: ${({ theme }) => theme.colors.lightGray};

  @media (max-width: 1440px) {
    padding: 1rem 1.5rem;
  }

  button {
    width: 300px;
  }
`;