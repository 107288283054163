import { ContentCard } from 'components/ContentCard';
import { IconButton } from 'components/IconButton';
import { Typography } from 'components/Typography';
import { edit, suitcaseDark } from 'assets/images/icons';
import { ServiceAddedList } from '../ServiceAddedList';
import { ServiceTypeTypes } from 'features/Company/types';

interface ViewTypesOfServicesProps {
  serviceType: ServiceTypeTypes[];
  onClick?: () => void;
}

const ViewTypesOfServices = ({
  serviceType,
  onClick,
}: ViewTypesOfServicesProps) => {
  return (
    <div className="flex flex-col flex-1 px-16 py-6 space-y-8">
      <ContentCard
        title="Tipos de serviços"
        icon={suitcaseDark}
        renderAction={
          <IconButton
            icon={edit}
            size="small"
            filled
            type="button"
            onClick={onClick}
          >
            Editar
          </IconButton>
        }
      >
        <div>
          <Typography variant="button" color="darkestGray">
            Serviços disponíveis para o cliente
          </Typography>
          {serviceType?.length > 0 ? (
            <ServiceAddedList services={serviceType} />
          ) : (
            <div className="w-full flex flex-col h-[500px] items-center justify-center">
              <p className="text-2xl font-bold">Nenhum serviço cadastrado</p>
            </div>
          )}
        </div>
      </ContentCard>
    </div>
  );
};
export { ViewTypesOfServices };
