import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { useCall } from 'queries/call';
import { TitleNumber } from 'components/TitleNumber';
import { formatCurrency } from 'utils/formatCurrency';
import { format, intervalToDuration } from 'date-fns';
import { useMemo } from 'react';
import { formatUTCHour } from 'utils/formatUtils';
import { activeScheduledTime, activeUsedTime } from 'assets/images/icons';
import { ptBR } from 'date-fns/locale';

const ExtraHourReviewSection = ({ callId, onSelect, accepted }) => {
  const { call } = useCall(callId);

  function confirmRemoveExtraHour() {
    onSelect(false);
  }

  function confirmAcceptExtraHour() {
    onSelect(true);
  }

  const callHourInterval = useMemo(() => {
    return intervalToDuration({
      start: formatUTCHour(call?.startTime),
      end: formatUTCHour(call?.finalTime),
    });
  }, [call]);

  const extraHourInterval = useMemo(
    () =>
      call?.additionalCosts.calculatedOvertime
        ? intervalToDuration({
          start: 0,
          end: call?.additionalCosts.calculatedOvertime * 3600000,
        })
        : {},
    [call?.additionalCosts.calculatedOvertime]
  );

  const callTimeString = callHourInterval.hours
    ? `${callHourInterval.hours} horas e ${callHourInterval.minutes} minutos`
    : `${callHourInterval.minutes} minutos`;

  const additionalTimeString = extraHourInterval.days
    ? `${extraHourInterval.days} dias, ${extraHourInterval.hours} horas${extraHourInterval.minutes > 0
      ? ` e ${extraHourInterval.minutes} minutos`
      : ''
    }`
    : extraHourInterval.hours
      ? `${extraHourInterval.hours} horas${extraHourInterval.minutes > 0
        ? ` e ${extraHourInterval.minutes} minutos`
        : ''
      }`
      : `${extraHourInterval.minutes} minutos`;

  return (
    <>
      <div className="flex items-center space-x-4">
        <TitleNumber>1</TitleNumber>
        <Typography variant="h2" color="darkestGray">
          Revisão de horas
        </Typography>
      </div>
      <Typography variant="p1" color="darkerGray">
        Este chamado utilizou horas além do agendado. Utilize a linha do tempo
        ao lado para verificar como as horas foram utilizadas.
      </Typography>
      <div className="flex w-full p-6 space-x-4 rounded-md bg-lighterGray">
        <div>
          <img src={activeScheduledTime} />
        </div>
        <div className="flex flex-col space-y-4">
          <Typography variant="p2" color="darkerGray">
            Agendado
          </Typography>
          <Typography variant="h3" color="darkestGray">
            {callTimeString}
          </Typography>
          <Typography variant="p1" color="darkestGray">
            De {format(new Date(call?.startTime), "H'h'mm", { locale: ptBR })} à{' '}
            {format(new Date(call?.finalTime), "H'h'mm", { locale: ptBR })}
          </Typography>
        </div>
        <div className="flex flex-col space-y-4">
          <Typography variant="p2" color="darkerGray">
            Custo base
          </Typography>
          <Typography variant="h3" color="darkestGray">
            {formatCurrency(call?.value)}
          </Typography>
        </div>
      </div>
      <div className="flex w-full p-6 space-x-4 rounded-md bg-lighterGray">
        <div>
          <img src={activeUsedTime} />
        </div>
        <div className="flex flex-col space-y-4">
          <Typography variant="p2" color="darkerGray">
            Horas extra
          </Typography>
          <Typography variant="h3" color="darkestGray">
            {additionalTimeString}
          </Typography>
          <Typography variant="p1" color="darkestGray">
            De{' '}
            {format(new Date(call?.additionalCosts?.callStartTime), "H'h'mm", {
              locale: ptBR,
            })}{' '}
            à{' '}
            {format(new Date(call?.additionalCosts?.callEndTime), "H'h'mm", {
              locale: ptBR,
            })}
          </Typography>
        </div>
        <div className="flex flex-col space-y-4">
          <Typography variant="p2" color="darkerGray">
            Custo total
          </Typography>
          <Typography variant="h3" color="darkestGray">
            {formatCurrency(call?.value + call?.additionalCosts.overtimeCost)}
          </Typography>
          <Typography variant="p1" color="darkestGray">
            {formatCurrency(call?.value)} +{' '}
            {formatCurrency(call?.additionalCosts.overtimeCost)}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col p-6 space-y-4 border-2 rounded-md border-lightGray">
        <Typography variant="p1" color="darkerGray">
          Este chamado utilizou <b>{additionalTimeString}</b> a mais que o
          inicialmente planejado, e por isso terá um custo adicional de{' '}
          <b>{formatCurrency(call?.additionalCosts.overtimeCost)}</b>.
        </Typography>
        <Typography variant="p1" color="darkerGray">
          Com base na linha do tempo do chamado, você autoriza o uso de horas
          extras?
        </Typography>
        <div className="flex w-full space-x-4">
          <Button
            variant={accepted !== null && accepted ? 'contained' : 'outlined'}
            color="purple"
            block
            disableHover={accepted !== null && accepted}
            onClick={confirmAcceptExtraHour}
          >
            Confirmar
          </Button>
          <Button
            variant={
              accepted !== null && accepted === false ? 'contained' : 'outlined'
            }
            color="orange"
            block
            disableHover={accepted !== null && accepted === false}
            onClick={confirmRemoveExtraHour}
          >
            Remover horas extra
          </Button>
        </div>
      </div>
    </>
  );
};

export { ExtraHourReviewSection };
