import { CompaniesTypes } from 'features/Company/types';
import { CompanyBalance } from '../CompanyBalance';
import { CompanyBonusPreview } from '../CompanyBonusPreview';
import { CompanyChartsView } from '../CompanyChartsView';

interface CompanyFinancialDataProps {
  data: CompaniesTypes;
}

const CompanyFinancialData = ({ data }: CompanyFinancialDataProps) => {
  return (
    <div className="flex flex-row w-full p-8">
      <div className="flex flex-col w-1/2 gap-6 px-6 lg:w-2/3">
        <CompanyBalance contract={data?.contract} />
        <CompanyBonusPreview />
      </div>

      <div className="flex flex-col w-1/2 gap-6 px-6 lg:w-1/3">
        <CompanyChartsView dataId={data?.id} />
      </div>
    </div>
  );
};

export { CompanyFinancialData };
