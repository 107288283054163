import { useMemo } from 'react';
import {
  LineChart as ReLineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  CartesianGrid,
  YAxis,
} from 'recharts';
import theme from 'themes/index';

const LineChart = ({
  data,
  dataKey,
  labelKey,
  danger = false,
  color = null,
  interval = 0,
  axis = [],
  height = 120,
  showYTick = false,
  customTooltip = undefined,
}) => {
  const accentColor = useMemo(
    () =>
      color
        ? theme.colors[color]
        : !danger
        ? theme.colors.purple
        : theme.colors.orange,
    [color, danger]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface DotProps {
    cx?: number | string;
    cy?: number | string;
    color?: string;
  }

  const Dot = ({ cx, cy, color }: DotProps) => {
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        stroke="white"
        strokeWidth={2}
        fill={color || accentColor}
      />
    );
  };

  interface LabelProps {
    x?: number | string;
    y?: number | string;
    stroke?: string;
    value?: unknown;
  }

  const Label = ({ x, y, stroke, value }: LabelProps) => {
    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ReLineChart data={data}>
        <XAxis
          allowDataOverflow={false}
          dataKey={labelKey}
          label={<Label />}
          fontFamily="Asap"
          fontSize={10}
          fontWeight="bold"
          interval={interval}
          padding={{ left: 25, right: 25 }}
          axisLine={false}
          tickLine={false}
        />
        {showYTick && (
          <YAxis
            fontFamily="Asap"
            fontSize={10}
            fontWeight="bold"
            tickLine={false}
            tick={{ fill: theme.colors.darkGray }}
            axisLine={false}
          />
        )}

        <CartesianGrid stroke="#f5f5f5" horizontal={false} />
        <Tooltip
          contentStyle={{
            backgroundColor: 'white',

            borderBottom: 'solid 5px ' + accentColor,
            borderLeft: 'none',
            borderTop: 'none',
            borderRight: 'none',
            borderRadius: '6px',
            fontSize: '12px',
            fontFamily: 'Asap',
            fontWeight: 'bold',
          }}
          content={customTooltip}
          wrapperClassName="shadow-lg"
          formatter={(value, name, props) => {
            return [value, props.payload.label || name];
          }}
        />
        {axis.length > 0 ? (
          axis.map((a, i) => {
            return (
              <Line
                key={i}
                type="linear"
                dataKey={a.name}
                stroke={theme.colors[a.color]}
                strokeWidth={3}
                dot={false}
                activeDot={<Dot color={theme.colors[a.color]} />}
              />
            );
          })
        ) : (
          <Line
            type="linear"
            dataKey={dataKey}
            stroke={accentColor}
            strokeWidth={3}
            dot={false}
            activeDot={<Dot />}
          />
        )}
      </ReLineChart>
    </ResponsiveContainer>
  );
};

export { LineChart };
