import { useMemo } from 'react';
import { useTechnician } from 'queries/technician';
import { DocumentsList } from '../DocumentsList';
import { EnglishLevel } from '../EnglishLevel';
import { FormOfDisplacement } from '../FormOfDisplacement';
import { PersonalDocumentation } from '../PersonalDocumentation';
import { ServiceCertificate } from '../ServiceCertificate';
import { ServiceRegions } from '../ServiceRegions';
import { StorePartsAvailability } from '../StorePartsAvailability';
import {
  AddressDataForm,
  PeriodsChecklist,
  UrgentCallsCheck,
  CanBuyPartsCheck,
} from './PersonalDataTab';
import type { InfoPersonalType } from 'features/Technician/types';
import { TrainingsTech } from '../TrainingsTech';

const TechnicianPersonalData = ({ id }) => {
  const { technician } = useTechnician(id);

  const technicianData = useMemo(() => {
    return (
      {
        infoPersonalData: {
          name: technician?.name,
          birthDate: technician?.birthDate,
          cpf: technician?.cpf,
          rg: technician?.rg,
          cnpj: technician?.cnpj,
          email: technician?.email,
          telephone: technician?.telephone,
          avatar: technician?.avatar,
        } as InfoPersonalType,
        address: technician?.address,
        storeParts: technician?.storeParts,
        transportation: technician?.transportation,
        documents: technician?.documents,
        regions: technician?.regions,
        englishLevel: technician?.englishLevel,
        services: technician?.services,
        periods: technician?.periods,
        urgentCalls: technician?.urgentCalls,
        trainings: technician?.trainings,
        canBuyParts: technician?.canBuyParts,
      } || {}
    );
  }, [technician]);

  return (
    <div className="py-6 space-y-6">
      <PersonalDocumentation infoPersonal={technicianData?.infoPersonalData} />
      <AddressDataForm address={technicianData?.address} />
      <StorePartsAvailability storeParts={technicianData?.storeParts} />
      <FormOfDisplacement displacement={technicianData?.transportation} />
      {technicianData?.trainings.length > 0 && (
        <TrainingsTech trainings={technicianData?.trainings} />
      )}
      <DocumentsList documents={technicianData?.documents} />
      <ServiceRegions regions={technicianData?.regions} />
      <EnglishLevel englishLevel={technicianData?.englishLevel} />
      <ServiceCertificate services={technicianData?.services} />
      <PeriodsChecklist periods={technicianData?.periods} />
      <UrgentCallsCheck urgentCalls={technicianData?.urgentCalls} />
      <CanBuyPartsCheck canBuyParts={technicianData?.canBuyParts} />
    </div>
  );
};

export { TechnicianPersonalData };
