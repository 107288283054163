import { useCallback } from 'react';
import { Typography } from 'components/Typography';
import { Container, Avatar, Content, Body } from './styles';
import {
  defaultAvatar,
  inactiveTechniciansGrayInfo,
  starGray,
} from 'assets/images/icons';
import { IProfessional } from '../ProfessionalCard';
import { ProfessionalInfoStatsItem } from '../ProfessionalInfoStatsItem';

interface ProfessionalInfoDetailProps {
  professional: IProfessional;
}

const ProfessionalInfoDetail = ({
  professional: { name, avatar, numberOfCalls, rating },
}: ProfessionalInfoDetailProps) => {
  const renderScheduleCallsTotalText = useCallback(() => {
    const text = numberOfCalls === 1 ? 'atendimento' : 'atendimentos';

    return `${numberOfCalls} ${text}`;
  }, [numberOfCalls]);

  return (
    <Container>
      <Avatar src={avatar || defaultAvatar} alt="avatar" />
      <Content>
        <Typography variant="button" color="darkBlue">
          {name}
        </Typography>
        <Body>
          <ProfessionalInfoStatsItem icon={starGray} text={String(rating)} />
          <ProfessionalInfoStatsItem
            icon={inactiveTechniciansGrayInfo}
            text={renderScheduleCallsTotalText()}
          />
        </Body>
      </Content>
    </Container>
  );
};

export { ProfessionalInfoDetail };
