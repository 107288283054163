import useSWR from 'swr';
import api, { fetcher } from 'services/api';
import { buildSearchParams } from 'utils/queryUtils';

export const Service = {
  getAll: (filters) =>
    useSWR(`/services?${buildSearchParams(filters)}`, fetcher),
  getByCompany: (companyId) =>
    useSWR(`/service-type/${companyId}/company`, fetcher),
  create: data => api.post('/services', data),
  update: (id: string, data: any) => api.patch(`/services/${id}`, data),
};
