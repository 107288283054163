import api from 'services/api';
import { buildSearchParams } from 'utils/queryUtils';

const CompanyEdit = (id, payload) => {
  return api.patch(`/company/` + id + '/registration-data', payload);
};

function AddCompanyAddress(payload) {
  return api.post(`/address-book`, payload);
}

function AddCompanyAddressById(companyId, payload) {
  return api.post(`/address-book?${buildSearchParams({ companyId })}`, payload);
}

function GetAddressCompany() {
  return api.get(`/address-book`);
}

function GetAddressCompanyById(companyId) {
  return api.get(`/address-book?${buildSearchParams({ companyId })}`);
}

function GetAddressCompanyByAddressId(id) {
  return api.get(`/address-book/` + id);
}

function EditCompanyAddressById(id, payload) {
  return api.patch(`/address-book/` + id, payload);
}

export {
  CompanyEdit,
  AddCompanyAddress,
  AddCompanyAddressById,
  GetAddressCompany,
  GetAddressCompanyById,
  GetAddressCompanyByAddressId,
  EditCompanyAddressById,
};
