import { Pagination, usePaginationData } from 'components/Pagination';
import { Typography } from 'components/Typography';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { CreateCallProps } from 'types/call';
import { ProfessionalCard, ProfessionalLinkedCallItem } from '../..';
import type { ProfileProfessionalTypes } from '../ListProfessionals/ListOfAvailableProfessionals';
import { IProfessional } from '../ProfessionalCard';
import {
  ProfessionalsContainer,
  ProfessionalsSelectedSection,
} from '../TabProfessional/styles';

interface AccordionProfessionalProps {
  dataTechnicians?: ProfileProfessionalTypes[];
  isDisabled?: boolean;
}

const AccordionProfessional = ({
  dataTechnicians,
  isDisabled = false,
}: AccordionProfessionalProps) => {
  const [professional, setProfessional] = useState<IProfessional[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { setFieldValue, values } = useFormikContext<CreateCallProps>();

  const linkedProfessional = useCallback(
    (professionalSelected) => {
      setProfessional([professionalSelected]);
      setFieldValue('technicianId', [professionalSelected.id]);
    },
    [setFieldValue]
  );

  const unlinkedProfessional = useCallback(
    (professionalSelectedID) => {
      setProfessional(
        professional.filter(
          (professional) => professional.id !== professionalSelectedID
        )
      );
      setFieldValue('technicianId', []);
    },
    [professional, setFieldValue]
  );

  useEffect(() => {
    if (
      !values.address &&
      !values.serviceDetail &&
      !values.serviceType &&
      !values.type &&
      !values.time
    ) {
      setFieldValue('technicianId', []);
    }
  }, [values, setFieldValue]);

  useEffect(() => {
    if (values.technicianId.length === 0) {
      setProfessional([]);
    }
  }, [values, setFieldValue]);

  const dataPagination = usePaginationData({
    objectData: dataTechnicians,
    currentPage: currentPage,
  });

  return (
    <div className="py-4">
      <ProfessionalsContainer>
        {dataPagination?.map((t) => (
          <ProfessionalCard
            key={t.id}
            professional={t}
            checked={
              !isDisabled &&
              (values.technicianId === t.id ||
                professional?.map((p) => p.id).includes(t.id))
            }
            onAddProfessional={linkedProfessional}
            onRemoveProfessional={unlinkedProfessional}
            disabled={isDisabled}
          />
        ))}
      </ProfessionalsContainer>
      <Pagination
        currentPage={currentPage}
        totalCount={dataTechnicians?.length}
        onPageChange={(page) => setCurrentPage(page)}
      />
      {!isDisabled &&
        professional?.length >= 1 &&
        values.address &&
        values.serviceDetail &&
        values.serviceType &&
        values.type &&
        values.technicianId &&
        values.time && (
          <ProfessionalsSelectedSection>
            <Typography variant="h4" color="darkestGray">
              Profissional vinculado ao chamado
            </Typography>
            <ProfessionalsContainer>
              {professional.map((item) => (
                <ProfessionalLinkedCallItem
                  key={item.id}
                  professional={item}
                  onRemoveProfessional={unlinkedProfessional}
                />
              ))}
            </ProfessionalsContainer>
          </ProfessionalsSelectedSection>
        )}

      {dataTechnicians?.length === 0 && (
        <Typography variant="h4" color="darkestGray">
          Nenhum profissional encontrado
        </Typography>
      )}
    </div>
  );
};

export { AccordionProfessional };
