export const ascendingSort = (obj) => {
  return obj.sort((a, b) => {
    return a - b;
  });
};

export const descendingSort = (obj) => {
  return obj.sort((a, b) => {
    return b - a;
  });
};
