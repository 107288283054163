import React from 'react';
import { Configure } from 'react-instantsearch-dom';

interface AlgoliaFilterConfigurationProps {
  analytics?: boolean;
  filters: string;
  itemsPerPage?: number;
  withPagination?: boolean;
}

const AlgoliaFilterConfiguration = ({
  filters,
  analytics = false,
  itemsPerPage = 10,
  withPagination = true,
}: AlgoliaFilterConfigurationProps) => {
  return withPagination ? (
    <Configure
      analytics={analytics}
      filters={filters}
      hitsPerPage={itemsPerPage}
    />
  ) : (
    <Configure analytics={analytics} filters={filters} />
  );
};

export default AlgoliaFilterConfiguration;
