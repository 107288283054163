import { Children, cloneElement } from 'react';
import clsx from 'clsx';

interface StepProps {
  step?: number;
  currentStep?: number;
  steps?: number;
  title: string;
  icon: string | ((active: boolean) => string);
  setCurrentStep?: (step: number) => void;
  disabled?: boolean;
  isTab?: boolean;
}

const Step = ({
  steps,
  step,
  currentStep,
  setCurrentStep,
  title,
  icon,
  isTab,
  disabled,
}: StepProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => {
        setCurrentStep(step);
      }}
      className={clsx(
        'flex flex-row items-center space-x-2 py-2 px-4 focus:outline-none border-b-4 text-left',
        {
          'border-purpleHover': currentStep === step,
          'border-[transparent]': currentStep !== step,
          'opacity-50 cursor-default': disabled,
        }
      )}
    >
      <div
        className={clsx(
          'rounded-full bg-purpleHover flex items-center justify-center h-8 w-8 ',
          {
            'bg-opacity-20 text-purpleHover': currentStep !== step,
            'text-white ': currentStep === step,
          }
        )}
      >
        {typeof icon === 'function' ? (
          <img src={icon(currentStep === step)} alt="Ícone personalizável" />
        ) : (
          <img src={icon} alt="Ícone personalizável" />
        )}
      </div>
      <div className="flex flex-col">
        {!isTab && (
          <p className="text-xs font-medium text-darkGray">
            Etapa {step}/{steps}
          </p>
        )}
        <p className="text-sm font-bold text-darkBlue">{title}</p>
      </div>
    </button>
  );
};

const Stepper = ({ children, currentStep, setCurrentStep, isTabs = false }) => {
  return (
    <div className="flex px-16 mt-4 space-x-8">
      {Children.map(children, (child, i) => {
        return cloneElement(child, {
          ...child.props,
          step: i + 1,
          steps: Children.count(children),
          currentStep,
          setCurrentStep,
          isTab: isTabs,
        });
      })}
    </div>
  );
};

Stepper.Step = Step;

export { Stepper };
