import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 24px;

  img {
    cursor: pointer;
  }
`;
