import styled, { css } from 'styled-components';

interface ContainerProps {
  checked: boolean;
  size: 'sm' | 'md';
}

const renderSize = {
  sm: css`
    width: 1rem;
    height: 1rem;
    min-height: 1rem;
  `,
  md: css`
    width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
  `,
};

export const Container = styled.div<ContainerProps>`
  ${({ size }) => renderSize[size]}
  background: ${({ theme, checked }) =>
    checked ? theme.colors.greenClick : theme.colors.orange};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 8px;
  height: 8px;
`;
