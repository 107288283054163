import styled, { css } from 'styled-components';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${props => props.isOpen ? css`
    display: block;
  ` : css`
    display: none;
  `}

  position: absolute;
  z-index: 1;

  padding: 16px 0;
  top: 64px;

  border: 2px solid ${props => props.theme.colors.lightGray};
  border-radius: 16px;
  width: 100%;
  background: ${props => props.theme.colors.white};
`;

export const OptionListContainer = styled.ul`
  list-style: none;
  max-height: 264px;
  overflow-y: auto;
  padding-left:16px;

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.lighterGray};
  }
  ::-webkit-scrollbar {
    width: 3px;
    background: ${(props) => props.theme.colors.lighterGray};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.darkGray};
  }
`;