/* eslint-disable @next/next/no-img-element */
import { finished, notUrgent, paused } from 'assets/images/slaIcons';
import { useEffect, useState } from 'react';
import { TooltipButton } from 'components/TooltipButton';

interface SlaCountdownProps {
  type: string;
  timeRemaining: number;
}
const SlaCountdown = ({ type, timeRemaining }: SlaCountdownProps) => {
  const [isWorkSchedule, setIsWorkSchedule] = useState(false);

  useEffect(() => {
    const verifyIsWorkSchedule = () => {
      const currentTime = new Date().getHours();
      setIsWorkSchedule(currentTime >= 8 && currentTime < 20);
    };

    verifyIsWorkSchedule();
    const intervalId = setInterval(verifyIsWorkSchedule, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function RenderTimeRemaining({ isWorkSchedule, timeRemaining }) {
    if (isWorkSchedule === true && timeRemaining > 0 && timeRemaining <= 3) {
      return (
        <TooltipButton description={'Sla crítico'}>
          <div className="h-6 w-8 border border-orangeHover rounded flex items-center justify-center">
            <p className="font-semibold text-xs text-orangeHover">
              {timeRemaining}h
            </p>
          </div>
        </TooltipButton>
      );
    } else if (isWorkSchedule === true && timeRemaining > 3) {
      return (
        <TooltipButton description={'Sla no prazo'}>
          <div className="h-6 w-8 border border-greenDark rounded flex items-center justify-center">
            <p className="font-semibold text-xs text-greenDark">
              {timeRemaining}h
            </p>
          </div>
        </TooltipButton>
      );
    } else if (isWorkSchedule === true && timeRemaining <= 0) {
      return (
        <TooltipButton description={'Sla expirado'}>
          <img src={finished} alt="" />
        </TooltipButton>
      );
    } else if (isWorkSchedule === false && timeRemaining > 0) {
      return (
        <TooltipButton description={'Sla pausado'}>
          <img src={paused} alt="" />
        </TooltipButton>
      );
    } else {
      return (
        <TooltipButton description={'Sla expirado'}>
          <img src={finished} alt="" />
        </TooltipButton>
      );
    }
  }

  return (
    <div className="w-8 h-12 flex items-center justify-center">
      {type === 'Urgent' ? (
        <div className="flex items-center justify-center w-full">
          <RenderTimeRemaining
            isWorkSchedule={isWorkSchedule}
            timeRemaining={timeRemaining}
          />
        </div>
      ) : (
        <TooltipButton description="Sem Sla">
          <img src={notUrgent} alt="Icone de relógio desabilitado" />
        </TooltipButton>
      )}
    </div>
  );
};

export { SlaCountdown };
