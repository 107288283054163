import { CallActions, ClientActions, FinancialActions, TechnicianActions, PlatformActions } from "../modulesActions";
import { ModulePermissionsType } from "../root";

export const AdminPermissions: ModulePermissionsType = {
  CALL: {
    actions: [
      CallActions.READ,
      CallActions.CREATE,
      CallActions.UPDATE,
      CallActions.CANCEL,
    ]
  },
  TECHNICIAN: {
    actions: [
      TechnicianActions.READ,
      TechnicianActions.CREATE,
      TechnicianActions.ACCEPT,
      TechnicianActions.REJECT,
    ]
  },
  FINANCIAL: {
    actions: [
      FinancialActions.READ,
      FinancialActions.ADD_BALANCE,
      FinancialActions.UPDATE_REGISTRATION_DATA,
    ]
  },
  CLIENT: {
    actions: [
      ClientActions.READ,
      ClientActions.CREATE,
      ClientActions.UPDATE,
      ClientActions.REPORT,
    ]
  },
  PLATFORM: {
    actions: [
      PlatformActions.READ,
      PlatformActions.MANAGEMENT_USER,
      PlatformActions.MANAGEMENT_SERVICES,
      PlatformActions.REGISTRATION_DATA,
    ]
  },
}