import { Typography } from 'components/Typography';
import { Container, Content } from './styles';
interface ProfessionalSpecialtiesProps {
  specialties: string[];
}

const ProfessionalSpecialties = ({
  specialties,
}: ProfessionalSpecialtiesProps) => {
  return (
    <Container>
      <Typography variant="p1" color="darkestGray">
        Especialidades
      </Typography>
      <Content>
        {specialties?.map((item) => (
          <Typography key={item} variant="tag" color="darkerGray">
            {item}
          </Typography>
        ))}
      </Content>
    </Container>
  );
};

export { ProfessionalSpecialties };
