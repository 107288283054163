import { Typography } from 'components/Typography';
import type { TokenType } from 'features/Technician/types';
interface DisplayTokenProps {
  tokens: TokenType[];
}

const DisplayToken = ({ tokens }: DisplayTokenProps) => {
  return (
    <div className="flex flex-row py-4 space-x-4">
      <Typography variant="h3" color="darkBLue">
        Token de ativação:
      </Typography>
      <Typography variant="h4" color="darkestGray">
        {tokens?.find(({ type }) => type === 'ONBOARDING')?.id}
      </Typography>
    </div>
  );
};

export { DisplayToken };
