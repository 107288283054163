/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx"
import { ChecklistItem } from "components/Checklist/ChecklistItem"
import { Typography } from "components/Typography"
import { ErrorMessage, Field, useFormikContext } from "formik"
import { useEffect } from "react"
import { v4 } from "uuid"

const NeedParts = () => {
  const { touched, errors, values, setFieldValue } = useFormikContext<any>();
  const handleCheckboxChange = () => {
    setFieldValue('needsParts', !values.needsParts);
  };

  useEffect(() => {
    if (!values.needParts) {
      setFieldValue('partsDescription', '');
    }
  }, [setFieldValue, values.needParts]);

  return (
    <div className="flex flex-col p-6 space-y-6 border-2 rounded-lg border-lightGray">
      <Typography variant="h2" color="darkestGray">
        Compra de material
      </Typography>
      <Typography variant="p1" color="darkerGray">
        Informe abaixo se o chamado necessitará repor ou instalar nova peça. Lembre-se o técnico irá comprar
        o material, mas o valor da compra será incluído no valor total do chamado
      </Typography>

      <ChecklistItem checked={values.needParts}
        toggleChecklistItem={handleCheckboxChange}
        item={{
          label: "Necessário compra de material",
          id: v4(),
        }} />

      {values.needsParts && (
        <>
          <Typography variant="h3" color="darkestGray">
            Descrição
          </Typography>
          <Field
            as="textarea"
            rows={6}
            className={clsx(
              'w-full p-3 border-2 rounded-xl focus:outline-none',
              touched.partsDescription && errors.partsDescription
                ? 'border-orange focus:border-orange'
                : 'border-gray focus:border-darkGray'
            )}
            name="partsDescription"
            label="Descrição"
            type="textarea"
            invalid={touched.partsDescription && errors.partsDescription}
          />
          <span className="m-0 text-sm text-orangeClick">
            <ErrorMessage name="partsDescription" />
          </span></>
      )}
    </div>
  )
}

export { NeedParts }