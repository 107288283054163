import { technicianMap } from 'assets/images/illustrations';
import { Typography } from 'components/Typography';
import { callStatus } from 'lib/call';

interface StatusCardProps {
  status: string;
  time: string;
}
const StatusCard = ({ status, time }: StatusCardProps) => {
  function getStatusText() {
    return callStatus[status]?.displayText;
  }
  return (
    <div className="flex items-center justify-between p-4 border-2 rounded-xl border-lightGray">
      <div className="flex items-center space-x-4">
        <img
          src={technicianMap}
          alt="icon"
          className="flex items-center justify-center w-12 h-12 "
        />
        <div className="flex flex-col">
          <Typography variant="h3" color="darkestGray">
            {getStatusText()}
          </Typography>
          <Typography variant="p3" color="darkerGray">
            {time}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { StatusCard };
