import { Typography } from 'components/Typography';
import { Checkbox } from 'components/UI/Sidesheet/NewTechnician/Displacement/Checkbox';
import { DISPLACEMENTS } from 'data/displacements';

type displacementTypes = 'WALKING' | 'CAR' | 'BIKE' | 'MOTORCYCLE' | 'OTHERS';

interface FormOfDisplacementProps {
  displacement: displacementTypes[];
}

const FormOfDisplacement = ({ displacement }: FormOfDisplacementProps) => {
  return (
    <div className="space-y-4">
      <Typography variant="h3" color="darkBLue">
        Deslocamento
      </Typography>
      <div className="flex flex-row space-x-2">
        {DISPLACEMENTS.map((d) => (
          <Checkbox
            key={d.value}
            defaultChecked={displacement?.includes(d.value as displacementTypes)}
            value={d.value}
            label={d.label}
            disabled
          />
        ))}
      </div>
    </div>
  );
};

export { FormOfDisplacement };
