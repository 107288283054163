import { useState } from 'react';
import {
  Marker,
  Location,
  Text,
  Footer,
  InformationContainer,
  Divisor,
  Image,
  MicroMarker,
  Container,
  LookButton,
  PathMarker,
} from './styles';
import { Tooltip } from 'components/Tooltip';
import {
  alertNotification,
  inactiveTechniciansGray,
  makerFooter,
  search,
} from 'assets/images/icons';

type VariantTypes = 'call' | 'micro' | 'path';

interface CallMarkerProps {
  onClick?: () => void;
  variant?: VariantTypes;
  className?: string;
  location?: string;
  calls?: number;
  notifications?: number;
  active?: boolean;
}

const CallMarker = ({
  onClick,
  variant = 'call',
  className = '',
  location = '',
  calls = 22,
  notifications = 6,
  active = false,
}: CallMarkerProps) => {
  const [hoverEffect, setHoverEffect] = useState(false);
  switch (variant) {
    case 'call':
      return (
        <Container
          onMouseEnter={() => setHoverEffect(true)}
          onMouseLeave={() => setHoverEffect(false)}
        >
          <Marker onClick={onClick} className={className} active={active}>
            <Location active={active}>
              <Text>{location}</Text>
            </Location>
            <Footer style={{ backgroundImage: `url(${makerFooter})` }}>
              <InformationContainer>
                <Image src={inactiveTechniciansGray} alt="" /> {calls}
              </InformationContainer>
              <Divisor />
              <InformationContainer>
                <Image src={alertNotification} alt="" /> {notifications}
              </InformationContainer>
              <LookButton
                show={hoverEffect || active}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Image src={search} alt="" />
              </LookButton>
            </Footer>
          </Marker>
        </Container>
      );
    case 'micro':
      return (
        <Tooltip
          location={location}
          calls={calls}
          notifications={notifications}
          childrenComponent={
            <MicroMarker
              onClick={onClick}
              className={className}
              active={active}
            />
          }
        />
      );
    case 'path':
      return <PathMarker className={className} />;

    default:
      break;
  }
};

export { CallMarker };
export type { CallMarkerProps };
