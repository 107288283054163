import React, { ComponentType } from 'react';
import { Container } from './styles';
import { DataTopicItem } from './DataTopicItem';
import { v4 } from 'uuid';

export interface IDataTopic {
  title: string;
  content: ComponentType;
}

interface DataTopicProps {
  topics: IDataTopic[];
}

const DataTopic = ({ topics }: DataTopicProps) => {
  return (
    <Container>
      {topics.map((topic, index) => (
        <DataTopicItem
          key={v4()}
          topic={index + 1}
          title={topic.title}
          content={topic.content}
        />
      ))}
    </Container>
  );
};

export { DataTopic };
