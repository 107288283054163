import { useRef, useCallback, ChangeEvent, useState } from 'react';
import { toAdd } from 'assets/images/icons';
import { Typography } from 'components/Typography';

import {
  Container,
  Content,
  AddNewItemContainer,
  AddNewItemIcon,
  FileInput,
} from './styles';
import AttachmentItem from 'components/AttachmentItem';
import { v4 } from 'uuid';
import { useFormikContext } from 'formik';

const CnpjCard: React.FC = () => {
  const { setFieldValue } = useFormikContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<{ id: string; file: File } | undefined>();

  const handleAddNewFiles = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const onChangeFiles = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        setFile({ id: v4(), file });

        setFieldValue('cnpjCardFile', file);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    },
    [setFieldValue]
  );

  return (
    <Container data-testid="cnpj-card-container">
      <Typography variant="h3" color="darkBLue">
        Cartão CNPJ
      </Typography>
      <Content>
        {file ? (
          <div key={file.id} className="flex items-center w-full space-x-4">
            <AttachmentItem
              key={v4()}
              id={file.id}
              file={file.file}
              onDelete={() => {
                setFile(undefined);
              }}
            />
          </div>
        ) : (
          <>
            <AddNewItemContainer
              type="button"
              onClick={() => handleAddNewFiles()}
              data-testid="add-new-cnpj-card-button"
            >
              <AddNewItemIcon>
                <img src={toAdd} alt="Add new file" />
              </AddNewItemIcon>
              <Typography variant="button" color="darkerGray">
                Selecionar arquivo
              </Typography>
            </AddNewItemContainer>
            <FileInput
              ref={fileInputRef}
              type="file"
              accept="application/pdf,image/png,image/jpg,image/jpeg"
              onChange={onChangeFiles}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

export { CnpjCard };
