import api from './api';
import Cookie from 'js-cookie';
import { addDays } from 'date-fns';

const isBrowser = typeof window !== 'undefined';
export const GUIO_AUTH_TOKEN = 'GUIO_AUTH_TOKEN';
export const GUIO_REFRESH_TOKEN = 'GUIO_REFRESH_TOKEN';

export const isAuthenticated = () =>
  isBrowser && window.localStorage.getItem(GUIO_AUTH_TOKEN) !== null;
export const getToken = () =>
  isBrowser ? window.localStorage.getItem(GUIO_AUTH_TOKEN) : '';

export const setToken = (token, refreshToken) => {
  if (isBrowser) {
    window.localStorage.setItem(GUIO_AUTH_TOKEN, token);
    window.localStorage.setItem(GUIO_REFRESH_TOKEN, refreshToken);
    const expires = addDays(new Date(), 1);
    Cookie.set(GUIO_AUTH_TOKEN, token, {
      expires,
    });
    Cookie.set(GUIO_REFRESH_TOKEN, refreshToken, {
      expires,
    });
  }
};

export const getRefreshToken = () =>
  isBrowser ? window.localStorage.getItem(GUIO_REFRESH_TOKEN) : '';

export const setRefreshToken = (refreshToken) => {
  if (isBrowser) {
    window.localStorage.setItem(GUIO_REFRESH_TOKEN, refreshToken);
    const expires = addDays(new Date(), 1);
    Cookie.set(GUIO_REFRESH_TOKEN, refreshToken, {
      expires,
    });
  }
};

export const logout = () => {
  if (isBrowser) {
    window.localStorage.removeItem(GUIO_AUTH_TOKEN);
    Cookie.remove(GUIO_AUTH_TOKEN);
    window.localStorage.removeItem(GUIO_REFRESH_TOKEN);
    Cookie.remove(GUIO_REFRESH_TOKEN);
  }
};

export const Auth = {
  login: ({ email, password }) => api.post('/auth/login', { email, password }),
  recoverPassword: ({ email }) =>
    api.post('/users/recovery-password-email', { email }),
  verifyPassword: ({ password, passwordConfirmation }) =>
    api.post('/users/verify-password', { password, passwordConfirmation }),
  updatePassword: ({ password, passwordConfirmation, token }) =>
    api.patch(`users/recovery-password/${token}`, {
      password,
      passwordConfirmation,
    }),
  refreshToken: () => api.get('/auth/refresh'),
};

export const getUserByToken = async (token) => {
  return await api.get(`/users/recovery-password/${token}`);
};
