/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCall } from 'queries/call';
import React, { useMemo, useState } from 'react';
import { v4 } from 'uuid';

import Notification from 'components/Calls/Alerts/Notification';
import { Container } from './styles';
import { Pagination, usePaginationData } from 'components/Pagination';

interface CallAlertsTabProps {
  callId: any;
}

export const CallAlertsTab: React.FC<CallAlertsTabProps> = ({ callId }) => {
  const { call, refetch } = useCall(callId);
  const [currentPage, setCurrentPage] = useState(1);

  const alerts = useMemo(() => {
    return call.notifications.map((notification) => ({
      id: notification.id,
      title: notification.title,
      description: notification.description,
      date: new Date(notification.createdAt),
      technicianId: notification.technicianId,
      companyId: notification.companyId,
      possibleActions: notification.possibleActions,
    }));
  }, []);

  const dataPagination = usePaginationData({
    objectData: alerts,
    currentPage: currentPage,
  });

  return (
    <Container>
      {dataPagination.map((alert) => (
        <Notification
          key={v4()}
          title={alert.title}
          description={alert.description}
          time={alert.date}
          actions={alert.possibleActions}
          callId={call.id}
          modelId={alert.technicianId}
          companyId={alert.companyId}
          refetch={refetch}
        />
      ))}
      {/* <CallAlertItem
        date={new Date()}
        title="Nova mensagem"
        description="Guilherme Assis enviou uma nova mensagem."
        type="message"
      />
      <CallAlertItem
        date={new Date()}
        title="Não existem profissionais disponíveis para o chamado"
        description="Não foi encontrado nenhum profissional disponível. Será necessário vincular um novo profissional."
        type="find"
      /> */}
      <Pagination
        currentPage={currentPage}
        totalCount={alerts?.length}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </Container>
  );
};
